import React, { useState, useEffect } from 'react';
import LoginHeader from '../Common/LoginHeader/LoginHeader';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import AllProducts from '../../Assets/img/Science/AllProducts.jpg';
import './Science.css';

const Science = () => {
    const [showModal, setShowModal] = useState(false);

    function goEnroll() {
        window.location.replace(
            'https://myoola.oolalife.com/corporphan/enrollment'
        );
    }

    const ScienceR = (
        <>
            <LoginHeader
                goEnroll={goEnroll}
                isDarkLogo
                enrollButtonText="ENROLL"
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="science__container">
                <div className="science__header">
                    <svg viewBox="0 0 400 150" className="svgBannerScienceDesk">
                        <foreignObject width="400" height="400">
                            <img className="svgBannerScienceDeskBack" />
                        </foreignObject>
                        <g transform="translate(80 55)">
                            <g className="science__headerSVGTextContainer">
                                <text
                                    transform="translate(0 0)"
                                    fill="#78ccf6"
                                    fontSize={15}
                                >
                                    THE SCIENCE
                                </text>
                                <text
                                    transform="translate(65 0)"
                                    fill="#2c414c"
                                    fontSize={15}
                                >
                                    BEHIND OOLA
                                </text>{' '}
                                <text
                                    transform="translate(0 15)"
                                    fill="#2c414c"
                                    fontSize={15}
                                >
                                    {'MIND & BODY PRODUCTS '}
                                </text>
                                <line
                                    stroke="#78ccf6"
                                    x2="35"
                                    transform="translate(0 20)"
                                    fill="none"
                                    strokeWidth="1"
                                />
                                <text
                                    transform="translate(0 30)"
                                    fill="#2c414c"
                                    fontSize={5}
                                    className="ScienceHeaderTextlvl2"
                                >
                                    {
                                        'MENTAL AND PHYSICAL HEALTH IS AN INSIDE JOB '
                                    }
                                </text>
                            </g>
                        </g>
                    </svg>
                    <svg viewBox="0 0 400 400" className="svgBannerScienceMob">
                        <foreignObject width="400" height="400">
                            <img className="svgBannerScienceMobBack" />
                        </foreignObject>
                        <g transform="translate(-60 -10)">
                            <g className="science__headerSVGTextContainer">
                                <text
                                    transform="translate(90 50)"
                                    fill="#78ccf6"
                                    fontSize={30}
                                >
                                    THE SCIENCE
                                </text>
                                <text
                                    transform="translate(220 50)"
                                    fill="#2c414c"
                                    fontSize={30}
                                >
                                    BEHIND
                                </text>{' '}
                                <text
                                    transform="translate(110 80)"
                                    fill="#2c414c"
                                    fontSize={30}
                                >
                                    {' OOLA MIND AND  '}
                                </text>
                                <text
                                    transform="translate(100 110)"
                                    fill="#2c414c"
                                    fontSize={30}
                                >
                                    {' BODY PRODUCTS '}
                                </text>
                                <text
                                    transform="translate(70 130)"
                                    fill="#2c414c"
                                    fontSize={10}
                                    className="ScienceHeaderTextlvl2"
                                >
                                    {
                                        'MENTAL AND PHYSICAL HEALTH IS AN INSIDE JOB '
                                    }
                                </text>
                            </g>
                        </g>
                    </svg>
                    {/* <h1>
                            <span>The Science</span>{' '}
                            BEHINDE OOLA <br/>MIND {'& BODY PRODUCTS'}
                        </h1>
                        <hr />
                        <h2> MENTAL AND PHYSICAL HEALTH IS AN INSIDE JOB</h2> */}
                </div>
                <div className="science_CombatStress">
                    <div className="science_CombatStressHeader">
                        <h1>
                            Combat Stress and Correct Imbalances to Optimize
                            Your <span>Mental and Physical Health </span>
                        </h1>
                        <p>
                            Our crazy busy modern lifestyle and toxic
                            environment overwhelm us with{' '}
                            <b>physical, chemical, and emotional stress</b>{' '}
                            which creates imbalances in our mind and body
                            leading to sluggish metabolism, weight gain, energy
                            slumps, lack of focus, foggy memory, anxiety, mood
                            swings, discomfort, gut distress, and poor sleep.
                        </p>
                        <p>
                            The body was designed to protect, rebalance, and
                            heal itself but it’s never been bombarded with as
                            many stressors as it is today. In short, it needs
                            some help.
                        </p>
                        <p>
                            When we get all the right vitamins and minerals,
                            nootropics, adaptogens, and probiotics our body
                            eliminates impurities, calms and sharpens our mind,
                            stabilizes our mood, restores our gut,
                            re-synchronizes our biorhythms, and maximizes our
                            performance. When all our systems are working in
                            tune and on time we perform at peak capacity
                            mentally and physically and every area of our life
                            improves.*
                        </p>
                    </div>
                </div>
                <div className="science_ExpertsMind">
                    <div className="science_ExpertsMindText">
                        <h1>
                            Experts in Mind
                            <br /> and Body <span>Wellness</span>
                        </h1>
                        <p>
                            Most foods and supplements on the market today are
                            nutritionally deficient. Many are even compromised
                            with potentially harmful ingredients. As a result,
                            they’re unable to effectively combat physical,
                            mental, and chemical stress and their resulting mind
                            and body imbalances.
                        </p>
                        <p>
                            At Oola, our team of world class scientists have
                            designed a suite of natural mind and body products
                            formulated with maximum nutrient density, optimal
                            bioavailability, and peak absorption. You can’t get
                            comprehensive nutritional support like this anywhere
                            else. Our unique combination of unmatched
                            nootropics, adaptogens, and probiotics contain
                            everything you need to feel amazing again so you can
                            perform like a champ from sunrise to sunset.*
                        </p>
                    </div>
                    <div className="science_ExpertsMindImage">
                        <hr className="horizontal" />
                        <div></div>
                        <article />
                        <hr className="vertical" />
                    </div>
                </div>
                <div className="science_TargetedMulti">
                    <div className="layer">
                        <h1>
                            Targeted Multilayer <span>Technology</span>
                        </h1>
                        <p>
                            Our multilayered supplements are designed to combat
                            mind and body imbalances by delivering essential
                            nutrients with prolonged release technology that
                            enhances absorption and reduces interaction with
                            other ingredients. This ensures a steady supply of
                            vitamins and minerals are delivered to your mind and
                            body over an extended period of time to optimize
                            their absorption, effectiveness, and rebalancing
                            benefits.
                        </p>
                    </div>
                </div>
                <div className="TheBlue__Container TheBlue__Container2 science_notropic__Container">
                    <div className="TheBlue__imgContainer">
                        <div className="TheBlue__img">
                            <img src={AllProducts} />
                        </div>
                        <div className="TheBlue__Desc science_notropic ">
                            <div>
                                <h1>
                                    NOOTROPIC AND <br />
                                    ADAPTOGENIC <span>BLENDS</span>
                                </h1>
                            </div>
                            <p>
                                Our team of scientists develop products uniquely
                                formulated to deliver key mind and body
                                balancing nutrients (potent nootropic and
                                adaptogenic blends) targeted to relieve stress,
                                support brain function, enhance mood, reduce
                                mild anxiety, and more.*
                            </p>
                        </div>
                    </div>
                </div>
                <div className="science_Matrix">
                    <div className="science_MatrixText">
                        <h1>
                            <span>Nootropic 7 Matrix </span>
                            <br />
                            Proprietary Blend
                        </h1>
                        <h2>
                            Rebalance your mind with a powerful proprietary
                            blend of potent phytonutrients designed to enhance
                            cognitive function such as memory, focus, and
                            overall mental performance.
                            <br />
                            <span>
                                <b> Key ingredients include:</b> Bacopa, Green
                                Tea Extract, Lion’s Mane Powder, Ginkgo, 5-HTP,
                                Rhodiola, L-alpha.
                            </span>
                        </h2>
                    </div>
                    <div className="science_MatrixImg toLeft">
                        <img className="img1" />
                    </div>
                    <div className="science_MatrixHr">
                        <hr />
                    </div>
                    <div className="science_MatrixImg showDesktop">
                        <img className="img2" />
                    </div>

                    <div className="science_MatrixText toLeft">
                        <div>
                            <h1>
                                <span>Adaptogenic 7 Matrix </span>
                                <br /> Proprietary Blend
                            </h1>
                            <h2>
                                Rebalance your body with a powerful proprietary
                                blend of potent phytonutrients designed to help
                                your body adapt and appropriately respond to
                                physical and mental stress. <br />
                                <span>
                                    <b>Key ingredients include:</b> Ashwagandha,
                                    Curcumin, Holy Basil, Maca Root Powder,
                                    Chaga Mushroom, Quercetin Dihydrate,
                                    Pterostilbene.
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="science_MatrixImg showMobile">
                        <img className="img2" />
                    </div>
                </div>
                <div className="science_Probiotic">
                    <img className="showDesktop" />
                    <div>
                        <h1>
                            <span>Probiotic</span> 12 Proprietary Blend
                        </h1>
                        <h2>
                            With our powerful proprietary blend of 10 Billion
                            CFU of 12 different probiotic bacterial strains,
                            Oola Connection™ is uniquely formulated to unlock
                            the power of the gut-brain axis to relieve
                            occasional stress, reduce mild anxiety, promote
                            positive mood and emotional well-being, while
                            achieving a state of relaxation. It is conveniently
                            packaged in single-serve packets, contains zero
                            sugar, and is easy to consume on-the-go to ensure
                            you’re always taking care of your gut, overall
                            health, and well-being.
                        </h2>
                    </div>
                    <img className="showMobile" />
                </div>
                <div className="science_LabCertified">
                    <img />
                    <br />
                    <br />
                    <p>
                        {`All products are formulated and tested in our Mind &
                        Body Lab™ in Salt Lake City, Utah before they are
                        approved by our team of scientists with decades of
                        experience. They undergo a five-step process before
                        arriving at your doorstep: research, formulation, best-in-class quality control
                        processes, and third-party verification.`}
                        <br />
                        <br />
                        {`Independent third-party testing is done to verify the products
                        contain the highest quality ingredients in the exact
                        doses listed on the label – nothing more nothing less.`}
                    </p>
                    <div className="science_LabCertifiedProps">
                        <div className="itemLabCertifiedProps__container PURITY">
                            <div className="itemLabCertifiedProps PURITY">
                                <img />
                                <h1>PURITY</h1>
                                <p>
                                    This ensures all of our products are free
                                    from deterioration, heavy metals,
                                    pesticides, and other forms of
                                    contamination.
                                </p>
                            </div>
                        </div>
                        <div className="itemLabCertifiedProps__container">
                            <div className="itemLabCertifiedProps IDENTITY">
                                <img />
                                <h1>IDENTITY</h1>
                                <p>
                                    This ensures all of our products are
                                    inspected, sampled, and analytically tested
                                    to ensure premium quality.
                                </p>
                            </div>
                        </div>
                        <div className="itemLabCertifiedProps__container">
                            <div className="itemLabCertifiedProps STRENGTH">
                                <img />
                                <h1>STRENGTH</h1>
                                <p>
                                    This ensures all products are proven to have
                                    the strength guaranteed on the labels. What
                                    you see on the label is exactly what you
                                    get.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="science_LabCertifiedProps2">
                        <div className="itemLabCertifiedProps__container">
                            <div className="itemLabCertifiedProps SAFETY">
                                <img />
                                <h1>SAFETY</h1>
                                <p>
                                    This ensures third party testing and reviews
                                    for effectiveness and quality.
                                </p>
                            </div>
                        </div>
                        <div className="itemLabCertifiedProps__container">
                            <div className="itemLabCertifiedProps COMPOSITION">
                                <img />
                                <h1>COMPOSITION</h1>
                                <p>
                                    This ensures the delivery of the right
                                    nutrients to your mind and body through a
                                    multi-faceted approach of testing,
                                    recording, analyzing, and documenting
                                    weight, disintegration, and stability.
                                </p>
                            </div>
                        </div>{' '}
                    </div>
                    <h1 className="science_medicalWarning">
                        *These statements have not been evaluated by the Food
                        and Drug Administration. This product is not intended to
                        diagnose, treat, cure, or prevent any disease.
                    </h1>
                </div>
            </div>

            <FooterSection />
        </>
    );

    return ScienceR;
};

export default Science;
