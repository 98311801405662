import React, { useState, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import LockSVG from '../../../Assets/img/LockIconFullWhite.svg';
import AppContext from '../../../Context/AppContext';
import './MyProgress.css';

const MyProgress = (props) => {
    const [showPopover, setShowPopover] = useState(false);
    const { changeModalWarningUpgrade } = useContext(AppContext);
    const popover = (
        <Popover className="MyProgressPoper__container">
            <Popover.Content
                className="MyProgressPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span
                            onClick={() => {
                                changeModalWarningUpgrade(true);
                            }}
                        >
                            {' '}
                            Oola Lifestyle Framework
                        </span>{' '}
                        to unlock this feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <article className="myProgress">
            <div className="TitleDashboardContent">
                <p className="heading-5">MY PROGRESS</p>
            </div>
            <div className="myProgress__container">
                {props.myProgressLoading && (
                    <section className="myProgress__loaderContainer">
                        <div className="lds-roller">
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </section>
                )}
                <div>
                    <h3 className="display-2">
                        {props.AllState.userProgress.stepsTaken}
                    </h3>
                    <h6 className="heading-6">
                        <span>ACTION</span>
                        <br />
                        STEPS
                        <br />
                        TAKEN
                    </h6>
                </div>
                <div>
                    <h3 className="display-2">
                        {props.AllState.userProgress.currentStreak}
                    </h3>
                    <h6 className="heading-6">
                        <span>DAYS</span>
                        <br />
                        CURRENT
                        <br />
                        STREAK
                    </h6>
                </div>
                <div>
                    <h3 className="display-2">
                        {props.AllState.userProgress.longestStreak}
                    </h3>
                    <h6 className="heading-6">
                        <span>DAYS</span>
                        <br />
                        LONGEST
                        <br />
                        STREAK
                    </h6>
                </div>

                <OverlayTrigger
                    placement="top"
                    overlay={popover}
                    show={showPopover}
                    rootClose
                    delay={{ show: 0, hide: 5000 }}
                >
                    <div
                        className="blockSectionMyProgress"
                        onMouseEnter={() => {
                            setShowPopover(true);
                        }}
                        onMouseLeave={() => {
                            setShowPopover(false);
                        }}
                    >
                        <h3 className="display-2 MyProgressNonSubscriber">
                            0
                            <img
                                className="imgLock"
                                alt="imgLock"
                                src={LockSVG}
                            />
                        </h3>
                        <h6 className="heading-6">
                            <span>MY</span>
                            <br />
                            GOALS
                            <br />
                            COMPLETED
                        </h6>
                    </div>
                </OverlayTrigger>
            </div>
        </article>
    );
};
export default MyProgress;
