export const initialState = {
    infoSection: {
        title: 'Welcome #nameUser# <br />  to Design my life <br /> blueprint',
        subTitle: '<span>DESIGN MY LIFE</span> BLUEPRINT',
        description: 'What To Expect:',
        stepsInfo: [
            {
                title: '<span>Step 1:</span> Where you are now',
                text: '70 questions to help pinpoint your growth areas',
            },
            {
                title: '<span>Step 2:</span> Where you want to go',
                text: 'Establishing your goals',
            },
            {
                title: '<span>Step 3:</span> How to get there',
                text: 'Actions to help move you closer to your goals.',
            },
        ],
    },
    questionsSection: {
        title: 'To begin to customize your experience, <br /> please answer the following three questions:',
        buttonText: 'GET STARTED',
        questions: [
            {
                id: 2,
                text: 'What is your current age?',
                index: 1,
                options: [
                    {
                        id: 2,
                        text: '14-18',
                    },
                    {
                        id: 3,
                        text: '19-29',
                    },
                    {
                        id: 4,
                        text: '30-39',
                    },
                    {
                        id: 5,
                        text: '40-59',
                    },
                    {
                        id: 6,
                        text: '60+',
                    },
                ],
            },
            {
                id: 3,
                text: 'Which best describes you?',
                index: 2,
                options: [
                    {
                        id: 7,
                        text: 'Student',
                    },
                    {
                        id: 8,
                        text: 'Employed',
                    },
                    {
                        id: 9,
                        text: 'Self-Employed/Entrepreneur',
                    },
                    {
                        id: 10,
                        text: 'Seeking Employment',
                    },
                    {
                        id: 11,
                        text: 'Stay-at-home parent',
                    },
                    {
                        id: 12,
                        text: 'Retired',
                    },
                ],
            },
            {
                id: 4,
                text: 'I am currently',
                index: 3,
                options: [
                    {
                        id: 13,
                        text: 'Single',
                    },
                    {
                        id: 14,
                        text: 'Committed',
                    },
                    {
                        id: 15,
                        text: 'Married',
                    },
                    {
                        id: 16,
                        text: 'Separated',
                    },
                    {
                        id: 17,
                        text: 'Divorced',
                    },
                    {
                        id: 18,
                        text: 'Widowed',
                    },
                ],
            },
        ],
    },
};

export const initialFormAnswers = {
    userId: 1,
    CustomizeQuestions: [
        {
            questionId: 2,
            OptionId: 0,
        },
        {
            questionId: 3,
            OptionId: 0,
        },
        {
            questionId: 4,
            OptionId: 0,
        },
    ],
};
