import React, { useEffect } from 'react';
import LoginHeader from '../Common/LoginHeaderWhite/LoginHeaderWhite';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import './TheOpportunity.css';
import { Link } from 'react-router-dom';
import YourOpportunityHeader from '../../Assets/img/YourOpportunity_header .jpg';
import ThepportunityChecked from '../../Assets/img/TheOpportunity/TheOpportunity_checked.svg';
import GUARANTEE_STICKER from '../../Assets/img/TheOpportunity/GUARANTEE_STICKER.png';
import AMBASSADOR_STICKER from '../../Assets/img/TheOpportunity/AMBASSADOR_STICKER.png';
import OolaGlobalCompPlanPDF from '../../Assets/PDF/OolaGlobalCompPlan.pdf';
import configData from '../../config.json';

const TheOpportunity = () => {
    // https://1152-frontdev.vm.epicservers.com//media/images/common/TheOpportunityPlans.png
    const PlanImg = `${configData.SERVER_URL_IMG}/media/images/common/TheOpportunityPlans.png`;
    useEffect(async () => {
        // window.scrollTo(0, 0);
    }, []);
    const arrBenefits = [
        { text: 'Design My Life Blueprint' },
        { text: 'My Breakthrough Experience' },
        { text: 'My Personalized Path' },
        { text: 'My Daily Tracker' },
        { text: 'Oola Support Groups' },
        { text: '1 meal/month to a child in need' },
        { text: '30-day money back guarantee' },
        { text: 'Personalized Website' },
        { text: 'Business App' },
        { text: 'Business Back-office Dashboard' },
        { text: 'Ability to earn income' },
        { text: 'Annual Membership' },
    ];
    function DownloadCompesationPlan() {
        window.open(
            'https://myoola.oolalife.com/www/ambassadorenrollment',
            '_blank'
        );
    }
    const TheOpportunityR = (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <div className="content__TheOpportunity">
                <svg viewBox="0 0 100 38">
                    <image
                        className="imgTheOpportunitySVG"
                        href={YourOpportunityHeader}
                        y="-13"
                    />
                    <rect className="rectSVGIMGFilter" />

                    <g className="imgTheOpportunitySVGText_content">
                        <g className="imgTheOpportunitySVGText_Desktop">
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="57"
                                y="32"
                            >
                                The opportunity
                            </text>
                        </g>
                        <g className="imgTheOpportunitySVGText_Mobile">
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="81"
                                y="28"
                            >
                                THE
                            </text>
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="64"
                                y="33"
                            >
                                OPPORTUNITY
                            </text>
                        </g>
                        <line
                            x1="88.5"
                            x2="88.5"
                            y1="24"
                            y2="35.2"
                            stroke="#78ccf6"
                            strokeWidth=".4px"
                        />
                        <line
                            x1="78"
                            x2="88.5"
                            y1="35"
                            y2="35"
                            stroke="#78ccf6"
                            strokeWidth=".4px"
                        />
                    </g>
                </svg>
            </div>
            <section className="content__YourOpportunity">
                <div className="TheOpportunity__FounderDescription">
                    <h1 className="TheOpportunity__FounderTitle">
                        YOUR OPPORTUNITY
                    </h1>

                    <p className="content__YourOpportunityText">
                        <span style={{ fontFamily: 'HarmoniaBold' }}>
                            It’s good to be first! &nbsp;
                        </span>
                        Become an Ambassador in a ground floor Network Marketing
                        opportunity with a global brand, established leadership,
                        a proven product and an industry leading compensation
                        plan. This disruptive digital model ensures you are
                        never out of stock, not repeatedly selling your family
                        and friends, and receiving the highest compensation as
                        you change lives for a living.
                    </p>
                </div>
            </section>
            <section className="content__TheOpportunityPlan">
                <div className="TheOpportunity__FounderDescription">
                    <h1 className="TheOpportunity__PlanTitle">
                        The Oola Compensation Plan is simple, easy, and uniquely
                        generous.
                    </h1>

                    <img
                        className="TheOpportunity__PlanIMG"
                        src={PlanImg}
                        alt="The Opportunity Plants"
                    />
                    <div className="TheOpportunityPlanMobile__content">
                        <svg
                            className="TheOpportunitySVGPrice"
                            viewBox="0 0 180 150"
                        >
                            <rect
                                fill="#78ccf6"
                                height="150"
                                width="150"
                                x="0"
                                y="0"
                            />
                            <text
                                className="TheOpportunityTextPrice"
                                fontSize="20px"
                                y={38}
                                x={38}
                            >
                                $
                            </text>
                            <text
                                className="TheOpportunityTextPrice"
                                fontSize="70px"
                                y={70}
                                x={50}
                            >
                                47
                            </text>
                            <text
                                className="TheOpportunityTextPrice"
                                fontSize="20px"
                                y={100}
                                x={40}
                            >
                                PER MONTH
                            </text>

                            <text
                                className="TheOpportunityTextPriceHarmonia"
                                fontSize="9px"
                                y={118}
                                x={22}
                            >
                                after initial enrollment of $77
                            </text>
                            <text
                                className="TheOpportunityTextPriceHarmoniaBold"
                                fontSize="8px"
                                y={130}
                                x={40}
                            >
                                CANCEL ANYTIME
                            </text>
                            <image
                                x="120"
                                y="30"
                                height="60"
                                width="60"
                                href={GUARANTEE_STICKER}
                                alt="my image"
                            />
                        </svg>
                        <div className="TheOpportunityBenefits__content">
                            <section className="imgAmbassador__content">
                                <img
                                    className="imgAmbassador"
                                    alt="imgAmbassor"
                                    src={AMBASSADOR_STICKER}
                                />
                                &nbsp; &nbsp; AMBASSADOR
                            </section>

                            {arrBenefits.map(item => (
                                <div className="TheOpportunityBenefitsDiv__content">
                                    <section className="checkAmbassador__container">
                                        <img
                                            src={ThepportunityChecked}
                                            alt="CheckedBenefit"
                                        />
                                    </section>
                                    <p>{item.text}</p>
                                </div>
                            ))}
                            <section className="PriceAmbassador">
                                <br />
                                $50.00
                                <br />
                                <button
                                    type="button"
                                    className="btnDownloadCompensation"
                                >
                                    PRE-ENROLL TODAY
                                </button>
                            </section>
                        </div>
                    </div>
                    <div className="TheOpportunity__ContentTitlePlanPointsText">
                        <h1 className="TheOpportunity__PlanTitleText">
                            Here are the 7 ways to earn money as an Oola
                            Ambassador:
                        </h1>
                        <div className="TheOpportunity__ContentPlanPointsText">
                            <h2 className="TheOpportunity__PlanPointsText">
                                • Daily pay to enrolled upon new subscriber’s
                                initial purchase
                                <br />
                                • Enroller’s enrollee received bonus for each
                                new subscriber’s initial purchase
                                <br />
                                • Monthly uni-level compensation paying 7 levels
                                <br />
                                • Monthly uni-level compensation paying
                                infinitely deep at highest ranks
                                <br />
                                • Rank advancement bonuses
                                <br />
                                • Guaranteed monthly bonuses for maintaining
                                rank qualifications
                                <br />• Monthly commissions on sales to
                                customers
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content__TheOpportunityTheOpportunity">
                <a
                    href={OolaGlobalCompPlanPDF}
                    download
                    onClick={()=>DownloadCompesationPlan()}
                >
                    <button className="btnDownloadCompensation" type="button">
                        download compensation plan
                    </button>
                </a>
            </section>

            <FooterSection />
        </>
    );

    return TheOpportunityR;
};

export default TheOpportunity;
