import { PinDropSharp } from '@material-ui/icons';
import React from 'react';
import TheBlueImg from '../../../../Assets/img/Home/TheBlue.png';
import { useParams } from 'react-router-dom';
import './TheBlue.css';

const TheBlue = ({ goFreeTest }) => {
    let { webAlias } = useParams();

    return (
        <div className="TheBlue__Container">
            <div className="TheBlue__imgContainer">
                <div className="TheBlue__Desc Mobile">
                    <div>
                        <h1>
                        Your Total Rebalancing System
                            <hr />
                        </h1>
                    </div>
                    <p>
                        Replace your old vitamins and supplements with Oola Mind
                        and Body nutrition for 30 days. If you don't experience
                        a boost in your health and wellness with your new
                        simplified routine, you'll get your money back - no
                        questions asked!
                    </p>
                    <h5>FIND A BALANCED LIFE TODAY</h5>
                    <button
                        className="button-full-mid"
                        onClick={() =>
                            window.open(
                                'https://myoola.oolalife.com/corporphan/productDetail/805'
                            )
                        }
                    >
                        GET STARTED TODAY
                    </button>
                </div>
                <div className="TheBlue__img">
                    <img src={TheBlueImg} />
                </div>
                <div className="TheBlue__Desc Desktop">
                    <div>
                        <h1>
                            OUT WITH THE OLD, IN WITH <span>THE BLUE</span>
                            <hr />
                        </h1>
                    </div>
                    <p>
                        Replace your old vitamins and supplements with Oola Mind
                        and Body nutrition for 30 days. If you don't experience
                        a boost in your health and wellness with your new
                        simplified routine, you'll get your money back - no
                        questions asked!
                    </p>
                    {/* <h5>FIND A BALANCED LIFE TODAY</h5> */}
                    <button
                        className="button-full-mid"
                        onClick={() =>
                            window.open(
                                'https://myoola.oolalife.com/corporphan/productDetail/805'
                            )
                        }
                    >
                        GET STARTED TODAY
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TheBlue;
