import React, { useContext, useEffect } from 'react';
import AppContext from '../../Context/AppContext';
import useScroll from '../../hooks/useScroll';
import './LoadingSection.css';

import oolaLogo from '../../Assets/oola_white_logo.svg';

const loadingSection = () => {
    const { showLoading } = useContext(AppContext);
    const [blockScroll, allowScroll] = useScroll();

    useEffect(() => {
        if (showLoading) blockScroll();
        else allowScroll();
    }, [showLoading]);

    return (
        showLoading && (
            <section className="loadingSection">
                <div className="loadingSection__body">
                    <img src={oolaLogo} alt="Oola Logo" />
                    <p className="paragraph-3 LoadingAnimation">Loading...</p>
                </div>
            </section>
        )
    );
};

export default loadingSection;
