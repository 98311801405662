import React from 'react';
import './CertificationQuizItem.css';

export default ({ item, i, setAnswer, questionAnswers }) => (
    <div className="certificationQuizItem_container" key={`QA${item.id}`}>
        <div className="certificationQuizItem_title">
            {i + 1}. {item.text}
        </div>
        <div className="certificationQuizItem_questions">
            {item.options.map((itemx, ix) => (
                <div style={{ paddingTop: '1rem' }} key={`QAO${itemx.id}`}>
                    <label className="certificationQuizItem_questions-option containerOption">
                        {itemx.text}
                        <input
                            type="radio"
                            name={`Question${i}`}
                            id={`Question${i}${ix}`}
                            onClick={() => setAnswer(item.id, itemx.id)}
                        />
                        <span className="checkmark" />
                    </label>
                </div>
            ))}
        </div>
    </div>
);
