import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import CoachMyBio from './Components/CoachMyBio/CoachMyBio';
import CoachMyProfile from './Components/CoachMyProfile/CoachMyProfile';
import './CoachInformationModal.css';
import CoachQuestionary from './Components/CoachQuestionary/CoachQuestionary';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';

const CoachInformationModal = () => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [showModal, setShowModal] = useState(false);
    const [modalStep, setModalStep] = useState(1);
    const [modalFormName, setModalFormName] = useState('');
    const [saveQuestions, setSaveQuestions] = useState(false);
    const [profileInformation, setProfileInformation] = useState();
    const modalMaxStep = 3;

    function changeModalStep(next) {
        if (next) {
            setModalStep(modalStep + 1);
        } else {
            setModalStep(modalStep - 1);
        }
    }

    const getCoachInformation = async () => {
        changeLoading(true);
        const { error, data } = await requestAxios({
            url: `Coach/getProfileInfo/${userId}`,
            token,
        });
        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            setProfileInformation(data);
            if(data.displayCoachingModal){
                setShowModal(true)
            }

        }
    };

    useEffect(() => {
        getCoachInformation();
    }, []);

    function modalStepComponent() {
        switch (modalStep) {
            case 1:
                return (
                    <CoachMyProfile
                        profileInformation={profileInformation}
                        getCoachInformation={getCoachInformation}
                        changeModalStep={changeModalStep}
                        setModalFormName={setModalFormName}
                    />
                );
            case 2:
                return (
                    <CoachMyBio
                        profileInformation={profileInformation}
                        getCoachInformation={getCoachInformation}
                        changeModalStep={changeModalStep}
                        setModalFormName={setModalFormName}
                    />
                );
            case 3:
                return (
                    <CoachQuestionary
                        setShowModal={setShowModal}
                        saveQuestions={saveQuestions}
                    />
                );

            default:
                return <div />;
        }
    }

    return (
        <>
            <Modal
                show={showModal}
                aria-labelledby="example-custom-modal-styling-title"
                centered
                size="xl"
                style={{ width: '100%', minHeight: '100%' }}
                scrollable
            >
                <Modal.Title className="modal-title">
                    <h1
                        className="heading-2"
                        style={{ textAlign: 'center', lineHeight: '50px' }}
                    >
                        WELCOME TO YOUR OOLA LIFE <br /> COACHING PORTAL
                    </h1>
                </Modal.Title>
                <Modal.Body className="body-container OolaScroll">
                    {modalStepComponent()}
                </Modal.Body>
                <Modal.Footer className="footer-container">
                    {modalStep > 1 && (
                        <button
                            className="button-empty-small"
                            style={{ borderRadius: '5px' }}
                            type="button"
                            onClick={() => changeModalStep(false)}
                        >
                            {'< PREVIOUS'}
                        </button>
                    )}
                    <button
                        className="button-full-small"
                        style={{ borderRadius: '5px' }}
                        form={modalFormName}
                        type="submit"
                        onClick={() =>
                            modalStep === modalMaxStep && setSaveQuestions(true)
                        }
                    >
                        {modalStep === modalMaxStep ? 'SUBMIT' : 'NEXT'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default CoachInformationModal;
