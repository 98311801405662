import React from 'react';
import './WhatsIncludedSection.css';

import happyFriends from '../../../Assets/img/LearnMoreHappyFriends.png';

const WhatsIncludedSection = (props) => (
    <section className="whatsIncluded">
        <figure>
            <img src={happyFriends} alt="Whats Included" />
        </figure>
        <div className="whatsIncluded__textContainer">
            <article className="textContainer__box">
                <h2 className="heading-2">What’s included</h2>
                <p className="paragraph-2">
                    PHASE 1: Design My Life Blueprint (self-paced)
                </p>
                <p className="paragraph-2">
                    PHASE 2: Breakthrough Experience (1x/week for 12 weeks)
                </p>
                <p className="paragraph-2">
                    PHASE 3: My Personalized Path (1x/month personalized
                    coaching)
                </p>
                <p className="paragraph-2">My Daily Tracker</p>
                <p className="paragraph-2">
                    Unlimited support from your private Inner Circle
                </p>
                <p className="paragraph-2">
                    Bonus personal development w/ Quarterly Summits (Live or
                    Virtual)
                </p>
                <p className="paragraph-2">
                    Priority Access to OolaPalooza annual LIVE event
                </p>
                <p className="paragraph-2">30-day money back guarantee</p>
                <p className="paragraph-2">
                    Opportunity to earn income as an Oola Ambassador
                </p>

                <button
                    className="button-full-big"
                    onClick={() => props.goEnroll()}
                    type="button"
                >
                    ENROLL NOW
                </button>
            </article>
        </div>
    </section>
);

export default WhatsIncludedSection;
