import React from 'react';

const SlicesStep2 = (props) => {
    // function sum(total) {
    //     total = (total / 20) + .6
    //     return String(isNaN(total) ? 0 : total);
    // }
    // function addZeroes(num, places) {
    //     const dec = String(num).split('.')[1]
    //     const len = dec && dec.length > places ? dec.length : places
    //     return Number(num).toFixed(len)
    // }
    function sum(total) {
        total =
            total === 0 || total == null
                ? 0
                : total * 0.005 + (total >= 70 ? 0.55 : 0.44);
        return String(isNaN(total) ? 0 : total);
    }
    function addZeroes(num, places) {
        const dec = String(num).split('.')[1];
        const len = dec && dec.length > places ? dec.length : places;
        return Number(num).toFixed(len);
    }
    const positionLables = [
        { x: '25%', y: '58%' },
        { x: '-30%', y: '57%' },
        { x: '-64%', y: '14%' },
        { x: '-50%', y: '-40%' },
        { x: '-1%', y: '-61%' },
        { x: '47%', y: '-36%' },
        { x: '59%', y: '17%' },
    ];
    const slices = (
        <g
            key={props.Fs.id}
            transform={
                props.Fs.id === 1
                    ? 'rotate(81.42,220,220)'
                    : `rotate(${132.84 + 51.42 * (props.index - 1)},220,220)`
            }
        >
            <g mask="url(#wedge-mask)">
                <circle
                    className="Slice "
                    cx="220"
                    cy="220"
                    r="170"
                    fill={props.Fs.color}
                    style={{ transform: `scale(${sum(props.defValues>0?props.defValues:props.Fs.score)})` }}
                >
                </circle>

                <text
                    className="titleQuestion"
                    x={positionLables[props.Fs.id - 1].x}
                    y={positionLables[props.Fs.id - 1].y}
                    fill="white"
                    fontSize="15"
                    strokeWidth="1px"
                    style={{
                        transform:
                            props.Fs.id === 1
                                ? 'rotate(-30deg)'
                                : `rotate(${329.42 - 51.42 * props.index}deg)`,
                    }}
                >
                    {props.defValues>0?"":props.Fs.score != null
                        ? addZeroes(props.Fs.score / 10, 1)
                        : ''}
                </text>
            </g>
        </g>
    );
    return slices;
};
export default SlicesStep2;
