import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import LoginHeader from '../Common/LoginHeader/LoginHeader';
import FooterSection from '../LearnMore/FooterSection/FooterSection';

import mapImage from '../../Assets/img/OurCause/map.png';
import photo1 from '../../Assets/img/OurCause/photo-1.jpg';
import photo2 from '../../Assets/img/OurCause/photo-2.jpg';
import photo3 from '../../Assets/img/OurCause/photo-3.jpg';
import photo4 from '../../Assets/img/OurCause/photo-4.jpg';
import photo5 from '../../Assets/img/OurCause/photo-5.jpg';
import photo6 from '../../Assets/img/OurCause/photo-6.png';

import './OurCause.css';

export default () => {
    useEffect(async () => {
        window.scrollTo(0, 0);
    }, []);

    const images = [
        {
            image: photo1,
        },
        {
            image: photo2,
        },
        {
            image: photo3,
        },
        {
            image: photo4,
        },
        {
            image: photo5,
        },
        {
            image: photo6,
        },
    ];

    const imageItems = images.map((item) => (
        <figure className="ourCause__imagesMobile_container">
            <img src={item.image} alt="testimonial" />
        </figure>
    ));

    return (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <section className="ourCause__hero">
                <div className="hero_image">
                    <h1>OUR CAUSE</h1>
                </div>
                <div className="hero_info">
                    <article>
                        <h3>298,466</h3>
                        <p>Meals Provided</p>
                    </article>
                    <article>
                        <h3>IN 8</h3>
                        <p>Countries</p>
                    </article>
                </div>
            </section>
            <section className="ourCause__video">
                <h2>
                    BE A SMALL PART OF <br /> SOMETHING <span>BIG</span>
                </h2>
                <p>
                    Through our partnership with Rice Bowls, we are partnered
                    with loving, grass-roots children’s homes around the world
                    to cover 100% of their delicious, locally sourced meals. You
                    can feel good knowing that your purchase provides meals to
                    children in need. Thanks to you, we are able to be a small
                    part of something really big in the lives of these amazing
                    children.
                </p>
                <ReactPlayer
                    url="https://vimeo.com/856939592"
                    width="100%"
                    controls
                />
            </section>
            <figure className="ourCause__mapContainer">
                <img src={mapImage} alt="map" />
            </figure>
            <section className="ourCause__images">
                <figure className="photo1">
                    <img src={photo1} alt="photo1" />
                </figure>
                <figure className="photo2">
                    <img src={photo2} alt="photo2" />
                </figure>
                <figure className="photo3">
                    <img src={photo3} alt="photo3" />
                </figure>
                <figure className="photo4">
                    <img src={photo4} alt="photo4" />
                </figure>
                <figure className="photo5">
                    <img src={photo5} alt="photo5" />
                </figure>
                <figure className="photo6">
                    <img src={photo6} alt="photo6" />
                </figure>
                <div className="title">
                    <h3>
                        LET'S FEED SOME <span>AMAZING</span> KIDS
                    </h3>
                </div>
            </section>
            <section className="ourCause__imagesMobile">
                <AliceCarousel
                    mouseTracking
                    items={imageItems}
                    // responsive={responsive}
                    controlsStrategy="alternate"
                    // autoPlay
                    infinite
                    autoPlayInterval={5000}
                    animationDuration={1000}
                    renderPrevButton={() => <div />}
                    renderNextButton={() => <div />}
                />
            </section>
            <FooterSection />
        </>
    );
};
