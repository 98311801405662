import React from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../../config.json'
import TheBlueImg from '../../../../Assets/img/Home/TheBlue.png';
import './TheBlue2.css';

const TheBlue = ({ goFreeTest, refScroll }) => {
    let { webAlias } = useParams();

    return (
        <div className="TheBlue__Container TheBlue__Container2" ref={refScroll}>
            <div className="TheBlue__imgContainer">
                <div className="TheBlue__Desc Mobile">
                    <div>
                    <h1>
                        YOUR TOTAL REBALANCING SYSTEM 
                            <hr />
                        </h1>
                    </div>
                    <p>
                    How would it feel to sleep soundly? To wake up feeling totally refreshed? To have the energy to attack all your daily tasks with vigor? To kick your afternoon slump to the curb? And to feel amazing in your gut and body 24/7?
                    </p>

                    <p>
                    By using our brain-boosting nootropics, energy-recharging polyphenols, body-balancing adaptogens, gut-restoring probiotics, and deficiency-eliminating micronutrients in our simple ‘Sunrise to Sunset Regimen’ you can rebalance your mind, body, and life with ease!
                    </p>

                    <p>
                    But that’s not all! You’ll gain instant access to our trademarked <a className="links-oola" href={config.OOLALIFEFRAMEWORKLINK}>Oola Lifestyle Framework</a> your personalized path to rebalancing the 7 key areas of life!
                    </p>
                    {/* <h5>FIND A BALANCED LIFE TODAY</h5> */}
                    <button
                        className="button-full-mid"
                        onClick={() =>
                            window.open(
                                'https://myoola.oolalife.com/corporphan/productDetail/811'
                            )
                        }
                    >
                        GET STARTED TODAY
                    </button>
                </div>
                <div className="TheBlue__img">
                    <img src={TheBlueImg} />
                </div>
                <div className="TheBlue__Desc Desktop">
                    <div>
                        <h1>
                        YOUR TOTAL REBALANCING SYSTEM 
                            <hr />
                        </h1>
                    </div>
                    <p>
                    How would it feel to sleep soundly? To wake up feeling totally refreshed? To have the energy to attack all your daily tasks with vigor? To kick your afternoon slump to the curb? And to feel amazing in your gut and body 24/7?
                    </p>

                    <p>
                    By using our brain-boosting nootropics, energy-recharging polyphenols, body-balancing adaptogens, gut-restoring probiotics, and deficiency-eliminating micronutrients in our simple ‘Sunrise to Sunset Regimen’ you can rebalance your mind, body, and life with ease!
                    </p>
                    {/* <h5>FIND A BALANCED LIFE TODAY</h5> */}
                    <button
                        className="button-full-mid"
                        onClick={() =>
                            window.open(
                                'https://myoola.oolalife.com/corporphan/productDetail/811'
                            )
                        }
                    >
                        GET STARTED TODAY
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TheBlue;
