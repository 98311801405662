import React, { useState, useEffect } from 'react';
import DashboardCarousel from '../../../Common/DashboardCarousel/DashboardCarousel';
import requestAxios from '../../../../util/requestAxios';
import './OolaServices2.css';

const Products = () => {
    const [listServices, setListServices] = useState([]);

    useEffect(() => {
        getInfo();
    }, []);
    const getInfo = async () => {
        const url = `Home/GetOolaProducts/1`;
        const { error, data } = await requestAxios({
            url: url,
        });
        if (error) {
            console.log(error);
        } else {
            setListServices(data);
        }
    };
    return (
        <div className="HomePageOolaServices__Container">
            <DashboardCarousel listServices={listServices}/>
        </div>
    );
};

export default Products;
