import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import ModalAddGoal from './Modal/ModalAddGoal';
import configData from '../../../config.json';
import Goal from './TemplateGoals/Goal';
import AddGoal from './TemplateGoals/AddGoal';
import './MyGoals.css';

const MyGoals = (props) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [showMyGoalLoading, setShowMyGoalLoading] = useState(false);
    const [show, SetShow] = useState(false);

    const [data, changeData] = useState({
        CurrentTab: 'inProgress',
        Goals: {
            inProgress: [],
            completed: [],
            pending: [],
        },
        goalsDict: [],
        subTitleGoals: 'MY TOP 7',
        addGoal: {
            id: '0',
            userId,
            int: '1',
            areaId: '1',
            text: '',
            completedByDate: new Date(),
            color: '',
            edit: 0,
        },
    });

    function getUserGoals() {
        setShowMyGoalLoading(true)
        const url = `${configData.SERVER_URL}goals/GetUserGoalsGroupedByStatus/${userId}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                const AllProps = { ...data };
                AllProps.Goals = result.data;
                AllProps.Goals.inProgress = AllProps.Goals.inProgress.sort(
                    (firstItem, secondItem) =>
                        (firstItem.isOneGoal ? 0 : 1) -
                        (secondItem.isOneGoal ? 0 : 1)
                );
                AllProps.changeData = changeData;
                changeData(AllProps);
                setShowMyGoalLoading(false)
            })
            .catch((error) => {
                setShowMyGoalLoading(false)
                console.log(error);
            });
    }

    useEffect(() => {
        getUserGoals();
    }, []);

    function setShowModal(visible) {
        SetShow(visible);
    }

    function SaveGoalsChange() {
        const date = new Date(data.addGoal.completedByDate);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
            date
        );
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
            date
        );
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
            date
        );
        const dateFormat = `${mo}/${da}/${ye}`;

        if (data.addGoal.edit === 1) {
            const url = `${configData.SERVER_URL}Goals/UpdateDashboardGoal`;
            const PostObj = {
                userId: data.addGoal.userId,
                id: data.addGoal.id,
                text: data.addGoal.text,
                completedByDate: dateFormat,
            };

            axios({
                method: 'put',
                url,
                headers: { Authorization: `Bearer ${token}` },
                data: PostObj,
            })
                .then((res) => {
                    SetShow(false);
                    getUserGoals();
                })
                .catch((err) => {
                    console.log(err);
                    alert(err);
                });
        } else {
            const url = `${configData.SERVER_URL}Goals/AddDashboardGoal`;
            const PostObj = {
                userId: data.addGoal.userId,
                int: '1',
                areaId: data.addGoal.areaId,
                text: data.addGoal.text,
                completedByDate: dateFormat,
            };

            axios({
                method: 'post',
                url,
                headers: { Authorization: `Bearer ${token}` },
                data: PostObj,
            })
                .then((res) => {
                    SetShow(false);
                    getUserGoals();
                })
                .catch((err) => {
                    console.log(err);
                    alert(err);
                });
        }
    }
    function selectTab(tab, subtitle) {
        const AllState = { ...data };
        AllState.CurrentTab = tab;
        AllState.subTitleGoals = subtitle;
        data.changeData(AllState);
    }

    function clickButtonAddGoal() {
        setShowModal(true);
    }

    function setGoal(goal) {
        const AllState = { ...data };
        AllState.addGoal = goal;
        data.changeData(AllState);
    }

    let CurrentGoals = [];
    if (data.CurrentTab === 'inProgress') {
        CurrentGoals = data.Goals.inProgress;
    } else if (data.CurrentTab === 'completed') {
        CurrentGoals = data.Goals.completed;
    } else if (data.CurrentTab === 'pending') {
        CurrentGoals = data.Goals.pending;
    }

    return (
        <article className="myGoals">
            <div className="TitleDashboardContent ">
                <p className="heading-5 BlueDarkText">MY GOALS</p>
            </div>
            <div className="myGoals__container ">
             {showMyGoalLoading&&   <section className="myGoals__loaderContainer">
                    <div className="lds-roller">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </section>}
                <ul className="myGoals__menu TitleMyGoal">
                    <li>
                        <button
                            className={` firstTabMyGoal ${
                                data.CurrentTab === 'inProgress' && 'activeTab'
                            }`}
                            onClick={() => selectTab('inProgress', 'MY TOP 7')}
                            type="button"
                        >
                            MY TOP 7
                        </button>
                    </li>
                    <li>
                        <button
                            className={`${
                                data.CurrentTab === 'completed' && 'activeTab'
                            }`}
                            onClick={() =>
                                selectTab('completed', 'GOALS ACHIEVED')
                            }
                            type="button"
                        >
                            ACHIEVED
                        </button>
                    </li>
                    <li>
                        <button
                            className={`${
                                data.CurrentTab === 'pending' && 'activeTab'
                            }`}
                            onClick={() =>
                                selectTab('pending', 'ADDITIONAL GOALS')
                            }
                            type="button"
                        >
                            ADDITIONAL
                        </button>
                    </li>
                </ul>

                {data.CurrentTab === 'pending' ? (
                    <div className="MyGoalDefined__container">
                        {CurrentGoals.map((itemSection,i) => (
                            <div key={itemSection.id}>
                                <h3
                                    className="heading-4 headerFSection"
                                    style={{
                                        color: itemSection.color,
                                        lineHeight: '25px',
                                         marginTop: i>0?'30px':"0px",
                                    }}
                                >
                                    {itemSection.areaName.toUpperCase()}
                                </h3>
                                {itemSection.goals.map((item, index) => (
                                    <Goal
                                        key={item.id}
                                        setGoal={setGoal}
                                        addGoal={data.addGoal}
                                        clickButtonAddGoal={clickButtonAddGoal}
                                        item={item}
                                        index={index}
                                        CurrentTab={data.CurrentTab}
                                        getUserGoals={getUserGoals}
                                        getMyProgress={props.getMyProgress}
                                        areaName={itemSection.areaName}
                                        areaId={itemSection.areaId}
                                        color={itemSection.color}
                                        TotaCompletelGoals={
                                            data.Goals.inProgress.length
                                        }
                                        getMyAchiements={props.getMyAchiements}
                                    />
                                ))}
                                {itemSection.goals.length < 7 && (
                                    <AddGoal
                                        CurrentTab={data.CurrentTab}
                                        GoalIndex=""
                                        SetShow={SetShow}
                                        areaId={itemSection.areaId}
                                        setGoal={setGoal}
                                        addGoal={data.addGoal}
                                        clickButtonAddGoal={clickButtonAddGoal}
                                        color={itemSection.color}
                                        selectTab={selectTab}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="MyGoalDefined__container">
                        {CurrentGoals.map((item, index) => (
                            <Goal
                                key={item.id}
                                setGoal={setGoal}
                                addGoal={data.addGoal}
                                clickButtonAddGoal={clickButtonAddGoal}
                                item={item}
                                index={index}
                                CurrentTab={data.CurrentTab}
                                getUserGoals={getUserGoals}
                                getMyProgress={props.getMyProgress}
                                TotaCompletelGoals={
                                    data.Goals.inProgress.length
                                }
                                getMyAchiements={props.getMyAchiements}
                                mobile={props.mobile}
                            />
                        ))}
                        {data.CurrentTab === 'inProgress' &&
                            CurrentGoals.length < 7 && (
                                <AddGoal
                                    CurrentTab={data.CurrentTab}
                                    GoalIndex={CurrentGoals.length + 1}
                                    SetShow={SetShow}
                                    color=""
                                    selectTab={selectTab}
                                />
                            )}
                    </div>
                )}
            </div>
            <ModalAddGoal
                setGoal={setGoal}
                show={show}
                setShowError={setShowModal}
                AllState={props.AllState}
                AllStateChange={props.AllStateChange}
                changeData={data.changeData}
                SaveGoalsChange={SaveGoalsChange}
                addGoal={data.addGoal}
            />
        </article>
    );
};

export default MyGoals;
