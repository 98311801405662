import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Goals.css"
import "./PickedGoals.css"

const SelectedOneGoalConfirm = (props) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const SelectedOneGoalConfirm =
        <div key={props.indexF} onClick={() => props.selectOolaOneGoal(props.indexF, props.itemF)}   className={`GoalArrangeOne ${props.itemF.selectedOne===true?"PickedGoalOne ":"NoPickedGoalOne"}`} style={{ backgroundColor: props.Fs.color+(props.itemF.selectedOne===true?"":"80"), marginLeft: "0px", marginRight: "0px", marginTop: "10px" }}>
            <div className={props.itemF.selectedOne===true?"GoalNumberOne":"GoalNumberOneNoSelected"} xs={1} style={{ height:"100%" }}>
                {props.indexF + 1}
            </div>
            <div className="GoalOneCol" >
                <div style={{ height: "100%", display:'grid', alignContent:'center'}}>
                    <input readOnly className={props.itemF.selectedOne===true?`GoalSelectedOne`:`GoalSelectedOneNoSelected`} placeholder="Define your  goal" value={props.itemF.text}  disabled/>
                </div>

                <div className="GoalOneCol2" >
                    <div style={{ height: "100%", paddingTop: " calc(.8vw)", }}>
                        <DatePicker
                            dateFormat="MMMM dd, yyyy"
                            // onChange={date => setStartDate(date)}
                            withPortal 
                            className={props.itemF.selectedOne===true?"datePickerGoalOne":"datePickerGoalOneNoSelected"}
                            placeholderText="Complete by: mm/dd/yyyy"
                            minDate={new Date()}
                            selected={props.itemF.date}
                            readOnly
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                            }) => (
                                <div>
                                    <div
                                        className="calendarHeader"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}>
                                        COMPLETE BY:
                                </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            paddingTop: "10px"
                                        }}
                                    >
                                        <button className="btnCalendar" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                            {"<"}
                                        </button>
                                        <div className="lblMonth">
                                            {months[new Date(date).getMonth()]}  {new Date(date).getFullYear()}
                                        </div>
                                        <button className="btnCalendar" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                            {">"}
                                        </button>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    
                </div>

            </div>
        </div>
    return SelectedOneGoalConfirm;
}
export default SelectedOneGoalConfirm;
