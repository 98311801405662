import React, { useState, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import './ActionStep.css';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import DeleteIcon from '../../../../Assets/img/DELETEICON.svg';
import configData from '../../../../config.json';
import requestAxios from '../../../../util/requestAxios';

const SelectedAction = (props) => {
    const { checkUserSession, setNewData, getData } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const { repeatAction, dayAddFindDailyActions } = getData();
    const [text, setText] = useState(props.actionStep.text);
    const [currenIdComplete, setCurrenIdComplete] = useState(0);
    const [currenIdDelete, setCurrenIdDelete] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    const [textModal, setTextModal] = useState({
        title: 'Complete',
        desc: 'Are you ending this action?',
    });
    function createMarkup(value) {
        return { __html: value };
    }
    const ActionTextChange = (e) => {
        if (props.actionStep.canBeRemoved) {
            let objChange;
            if (props.actionStep.id == null) {
                objChange = props.AllState.Actions.find(
                    (x) => x.index === props.actionStep.index
                );
            } else {
                objChange = props.AllState.Actions.find(
                    (x) => x.id === props.actionStep.id
                );
            }
            objChange.text = e;
            props.AllStateChange(props.AllState);
            setText(objChange.text);
        }
        return true;
    };
    function completeActionStep(id) {
        setCurrenIdComplete(id);
        props.AllState.ActionIdComplete = id;
        props.AllState.ActionIdDelete = null;
        props.AllStateChange(props.AllState);
        if (props.AllState.userProgress.stepsTaken >= 7) {
            ModifyStateAction();
        } else {
            setShowModal(true);

            setTextModal({
                title: 'ARE YOU SURE?',
                desc: 'Do you really want complete this action?',
            });
            props.forceUpdate();
        }
    }
    function deleteActionStep(id, index) {
        if (id != null) {
            props.AllState.ActionIdDelete = id;
            props.AllState.ActionIdComplete = null;
            props.AllStateChange(props.AllState);
            setShowModal(true);
            setTextModal({
                title: 'ARE YOU SURE?',
                desc: 'Do you really want delete this action?',
            });
            props.forceUpdate();
        } else {
            props.AllState.Actions = props.AllState.Actions.filter(
                (x) => x.index !== index
            );
            props.AllStateChange(props.AllState);
            props.forceUpdate();
        }
    }
    async function cancelRepeatActions() {
        props.setSaving(true);
        const url = `Actions/CancelRepeatActions/${props.AllState.ActionIdDelete}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
            method: 'put',
        });
        if (error) {
            props.setSaving(false);
            props.AllState.ActionIdDelete = null;
        } else {
            setShowModal(false);
            props.setSaving(false);
            props.getMyProgress();
            props.getCurrentDailyActions();
            props.AllState.ActionIdDelete = null;
            props.setCurrentTrackerTab(0);
        }
    }
    function ModifyStateAction() {
        props.setSaving(true);
        if (props.AllState.ActionIdComplete != null) {
            const url = `${configData.SERVER_URL}Actions/AsyncCompleteAction`;
            const PostObj = {
                userId,
                id: props.AllState.ActionIdComplete,
            };

            axios({
                method: 'put',
                url,
                headers: { Authorization: `Bearer ${token}` },
                data: PostObj,
            })
                .then((result) => {
                    setShowModal(false);
                    setNewData({
                        dayAddFindDailyActions: { days: 0 },
                    });
                    props.setCurrentTrackerTab(0);
                    props.AllState.ActionIdComplete = null;
                    props.setSaving(false);
                    props.getMyProgress();
                    props.getCurrentDailyActions();
                    props.forceUpdate();
                })
                .catch((error) => {
                    props.setSaving(false);
                    props.AllState.ActionIdComplete = null;
                });
        } else {
            const url = `${configData.SERVER_URL}Actions/${props.AllState.ActionIdDelete}`;

            axios
                .delete(url, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((result) => {
                    setShowModal(false);
                    props.setSaving(false);
                    props.getMyProgress();
                    props.getCurrentDailyActions();
                    props.AllState.ActionIdDelete = null;
                    props.setCurrentTrackerTab(0);

                    props.forceUpdate();
                })
                .catch((error) => {
                    props.setSaving(false);
                    props.AllState.ActionIdDelete = null;
                });
        }
    }
    function getDays() {
        let days = [];
        if (props.actionStep.actionRepeating.monday == true)
            days.push('Monday');
        if (props.actionStep.actionRepeating.tuesday == true)
            days.push('Tuesday');
        if (props.actionStep.actionRepeating.wednesday == true)
            days.push('Wednesday');
        if (props.actionStep.actionRepeating.thursday == true)
            days.push('Thursday');
        if (props.actionStep.actionRepeating.friday == true)
            days.push('Friday');
        if (props.actionStep.actionRepeating.saturday == true)
            days.push('Saturday');
        if (props.actionStep.actionRepeating.sunday == true)
            days.push('Sunday');
        return days.join(', ');
    }
    function convertDate(datestring) {
        const newDate = new Date(datestring);
        return newDate;
    }
    const popover = (
        <Popover>
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                <div className="popupActionStep__container">
                    <span>{props.actionStep.extraText}</span> {text}
                    {props.actionStep.actionRepeating && (
                        <h1 className="repeatingLabelMessage">
                            Start at:{' '}
                            <b>
                                {convertDate(
                                    props.actionStep.actionRepeating.startDate
                                ).toLocaleDateString('en-US')}
                            </b>
                            {props.actionStep.actionRepeating.endDate && (
                                <>
                                    {' '}
                                    Ends at:{' '}
                                    <b>
                                        {convertDate(
                                            props.actionStep.actionRepeating
                                                .endDate
                                        ).toLocaleDateString('en-US')}
                                    </b>
                                </>
                            )}{' '}
                            <br />
                            On Days: <b>{getDays()}</b>
                        </h1>
                    )}
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={popover}
            rootClose
            delay={{ show: 0, hide: 400 }}
            show={showPopUp}
        >
            <article
                className={`actionSteps ${props.index===0&&"firstItem"} `}
                onMouseEnter={() => setShowPopUp(true)}
                onMouseLeave={() => setShowPopUp(false)}
            >
                <div className="actionSteps__number">
                    <h3 className="heading-3">{props.index + 1}</h3>
                </div>
                <div className="actionSteps__input">
                    {props.actionStep.canBeRemoved ? (
                        <input
                            placeholder="Write your action step"
                            onChange={(e) => ActionTextChange(e.target.value)}
                            value={text}
                            rows="3"
                            onFocus={() => setShowPopUp(true)}
                            onBlur={() => setShowPopUp(false)}
                            disabled={!props.actionStep.canBeRemoved}
                        />
                    ) : (
                        <div
                            className="actionSteps__input_disable"
                            dangerouslySetInnerHTML={createMarkup(
                                `<span>${props.actionStep.extraText}</span> ${text}`
                            )}
                        />
                    )}
                </div>
                <div className="actionSteps__radio actionSteps__button">
                    
                    {props.AllState.DailySteps > 14 ||
                    dayAddFindDailyActions?.days > 0
                        ? ''
                        : props.actionStep.id != null && (
                              <button
                                  onClick={() => {
                                      completeActionStep(props.actionStep.id);
                                  }}
                              >
                                  <input type="radio" value={false} />
                              </button>
                          )}
                </div>
                <div className="actionSteps__button edit">
                    {props.actionStep.id != null && (
                        <button
                            onClick={() => {
                                if (props.actionStep.actionRepeating) {
                                    const newObj = {
                                        ...props.actionStep.actionRepeating,
                                        StartDate: new Date(
                                            props.actionStep.actionRepeating.startDate
                                        ),
                                        EndDate:
                                            props.actionStep.actionRepeating
                                                .endDate &&
                                            new Date(
                                                props.actionStep.actionRepeating.endDate
                                            ),
                                        ActionId: props.actionStep.id,
                                    };
                                    setNewData({
                                        repeatAction: newObj,
                                    });
                                } else {
                                    setNewData({
                                        currentAction: {
                                            ...props.actionStep,
                                            Id: props.actionStep.id,
                                            text: props.actionStep.text,
                                        },
                                        repeatAction: null,
                                    });
                                }
                                props.setShowModalEdit(true);
                            }}
                            type="button"
                        >
                            <a className="fa fa-repeat repeatIcon" />
                        </button>
                    )}
                </div>
                <div className="actionSteps__button remove">
                    {props.actionStep.canBeRemoved && (
                        <button
                            onClick={() => {
                                deleteActionStep(
                                    props.actionStep.id,
                                    props.actionStep.index
                                );
                            }}
                            type="button"
                        >
                            <img src={DeleteIcon} alt="delete" />
                        </button>
                    )}
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    aria-labelledby="example-custom-modal-styling-title"
                    size="xs"
                    centered
                    dialogClassName="warningDeleteActionModalDialog"
                >
                    <Modal.Header className="modalRepetAction__header">
                        <button
                            className="modalAddGoal__exit"
                            onClick={() => setShowModal(false)}
                            type="button"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                            >
                                <defs>
                                    <clipPath id="b">
                                        <rect width="40" height="40" />
                                    </clipPath>
                                </defs>
                                <g id="a" clipPath="url(#b)">
                                    <g transform="translate(-1269.135 820.365) rotate(-45)">
                                        <g transform="translate(-16803 -3223)">
                                            <line
                                                x2="20"
                                                transform="translate(18270.5 3568.5)"
                                                fill="none"
                                                stroke="#2c414c"
                                                strokeWidth="3"
                                            />
                                            <line
                                                y1="20"
                                                transform="translate(18280.5 3558.5)"
                                                fill="none"
                                                stroke="#2c414c"
                                                strokeWidth="3"
                                            />
                                        </g>
                                        <g
                                            transform="translate(1462 330)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        >
                                            <circle
                                                cx="15.5"
                                                cy="15.5"
                                                r="15.5"
                                                stroke="none"
                                            />
                                            <circle
                                                cx="15.5"
                                                cy="15.5"
                                                r="14"
                                                fill="none"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="modalActionCompleteConfirmation">
                            <h2 className="heading-2">{textModal.title}</h2>
                            <h6 className="heading-6">{textModal.desc}</h6>
                            <div>
                                <button
                                    onClick={() => ModifyStateAction()}
                                    className={`${
                                        !props.AllState.ActionIdComplete
                                            ? 'button-empty-mid'
                                            : 'button-full-mid'
                                    } cancelRepeatActions`}
                                    type="button"
                                >
                                    {!props.AllState.ActionIdComplete
                                        ? 'YEAH, CANCEL THIS ACTION'
                                        : 'YEAH, COMPLETE THIS ACTION'}
                                </button>
                                {props.actionStep.actionRepeating &&
                                    !props.AllState.ActionIdComplete && (
                                        <button
                                            onClick={() =>
                                                cancelRepeatActions()
                                            }
                                            className="cancelRepeatActions button-full-mid"
                                            type="button"
                                        >
                                            YEAH AND CANCEL REPEAT ACTIONS
                                        </button>
                                    )}
                            </div>
                        </section>
                    </Modal.Body>
                </Modal>
            </article>
        </OverlayTrigger>
    );
};
export default SelectedAction;
