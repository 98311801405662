import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import configData from '../../../../config.json';
import requestAxios from '../../../../util/requestAxios';
import './PurchaseSession.css';
import PurchaseFailed from './componets/PurchaseFailed';

export default ({ selectedCoach }) => {
    const { checkUserSession, setNewData, getData, changeLoading } =
        useContext(AppContext);
    const { ProfileCoach } = getData();
    const { token, userId } = checkUserSession();
    const [nSession, setNSession] = useState(0);
    const [imgHash] = useState(Math.random());
    const [purchaseFailed, setPurchaseFailed] = useState(false);

    const buySession = async () => {
        if (nSession > 0) {
            changeLoading(true);
            const PostData = {
                CoachId: selectedCoach.userId,
                UserId: userId,
                Quantity: nSession,
            };

            const { error } = await requestAxios({
                url: `Coach/PurchaseSession`,
                token,
                method: 'Post',
                data: PostData,
            });
            if (error) {
                changeLoading(false);
                setPurchaseFailed(true);
                // window.alert(error);
            } else {
                alert('Session purchase successful');
                window.location.reload();
            }
        } else {
            alert(
                'Please select "How many sessions would you like to purchase?"'
            );
        }
    };
    return (
        <Modal
            show={ProfileCoach.showModalPurchaseSession}
            aria-labelledby="PurchaseSession__container"
            centered
            className="PurchaseSession__container OolaScroll"
            dialogClassName="PurchaseSessionDialog__container"
            onHide={() =>
                setNewData({
                    ProfileCoach: {
                        showModalPurchaseSession: false,
                    },
                })
            }
        >
            <Modal.Body>
                <button
                    className="btnexit PurchaseSession"
                    onClick={() =>
                        setNewData({
                            ProfileCoach: {
                                showModalPurchaseSession: false,
                            },
                        })
                    }
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                        className="btnCloseModalSVG"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                        className="btnCloseModalSVG"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="3"
                                    className="btnCloseModalSVG"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
                <div className="PurchaseSessionModalContent__container">
                    <div className="PurchaseSessionModalContentCoachInfo__container">
                        <div className="CoachInfo PurchaseSession container">
                            <div
                                alt="Profile"
                                className="image__SelectedCoach CoachInfo PurchaseSession"
                                style={{
                                    '--imgProfileSelectedCoachUrl': `url(${
                                        selectedCoach.profilePictureFileName !=
                                            '' &&
                                        selectedCoach.profilePictureFileName !=
                                            null &&
                                        `${configData.SERVER_URL_IMG}/media/profile/${selectedCoach.profilePictureFileName}?${imgHash}`
                                    })`,
                                }}
                            />
                            <div className="CoachInfoText PurchaseSession">
                                <h1>{`${selectedCoach.firstName} ${selectedCoach.lastName}`}</h1>
                                <h2>Certified Oola Life Coach</h2>
                                <h3>{selectedCoach.currentSessionRateText}</h3>
                                <h4>(30-minute video session)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="PurchaseSessionModalContentPriceInfo__container">
                        {purchaseFailed ? (
                            <PurchaseFailed
                                setPurchaseFailed={setPurchaseFailed}
                            />
                        ) : (
                            <div className="PurchaseSessionModalContentPriceInfoContent__container">
                                <h1>
                                    How many sessions would you like to
                                    purchase?
                                </h1>
                                <div className="nSession__container">
                                    <button
                                        onClick={() =>
                                            nSession > 0 &&
                                            setNSession(nSession - 1)
                                        }
                                        className="left"
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        className=" "
                                        onChange={(input) =>
                                            setNSession(input.target.value)
                                        }
                                        value={nSession}
                                    />
                                    <button
                                        onClick={() =>
                                            setNSession(nSession + 1)
                                        }
                                        className="right"
                                    >
                                        +
                                    </button>
                                </div>
                                <h2>Subtotal:</h2>
                                <h3>
                                    $
                                    {nSession *
                                        selectedCoach.currentSessionRate}
                                </h3>
                                <h4>
                                    Pay with card ending in
                                    {selectedCoach.creditCardOnFile}
                                </h4>
                                <button
                                    className="button-full-small purchaseButton PurchaseSession"
                                    type="button"
                                    onClick={() => buySession()}
                                >
                                    PURCHASE
                                </button>
                                <h5
                                    onClick={() =>
                                        window.open(
                                            configData.UPDATEPAYMENTLINK
                                        )
                                    }
                                >
                                    Update payment information
                                </h5>
                                <p style={{ color: 'white', fontSize: '12px', marginTop: '10px' }}>
                                    Sessions are transferable, but
                                    non-refundable.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
