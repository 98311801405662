import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import configData from '../../../../config.json';
import iconUser from '../../../../Assets/img/userIcon.jpg';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import CoachingRoomNoteBook from './CoachingRoomNoteBook/CoachingRoomNoteBook';
import CoachVideoCall from './CoachVideoCall/CoachVideoCall';
import OolaChat from './OolaChat/OolaChat';
import './CoachingRoomModalCoach.css';
import CoachingRoomInfoComponentCoach from './CoachingRoomInfoComponent/CoachingRoomInfoComponentCoach';

function CoachingRoomModalCoach() {
    const {
        checkUserSession,
        setNewData,
        changeLoading,
        getData,
        changeReloadClients,
    } = useContext(AppContext);
    const { ProfileCoach, ChatMessage, currentClient } = getData();
    const [profileCoachFinal, setProfileCoachFinal] = useState({});
    const [imageHash, imageHashChange] = useState(Date.now());
    const [showVideoCall, setShowVideoCall] = useState(false);
    const [showChat, setShowChat] = useState(false);
    useEffect(() => {
        getDataCoach();
    }, []);

    const getDataCoach = async () => {
        changeLoading(true);
        const us = checkUserSession();
        const url = `Coach/GetDirectoryListingInfo/${us.userId}`;
        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            setProfileCoachFinal(data);
            changeLoading(false);
        }
    };

    const completeSession = async () => {
        changeLoading(true);

        const us = checkUserSession();
        const url = `Coach/CompleteSession`;

        const sessionData = {
            CoachId: us.userId,
            UserId: currentClient?.userId,
            MeetingCode: ProfileCoach?.meetingData?.meetingCode,
        };

        const { error } = await requestAxios({
            url,
            method: 'post',
            token: us.token,
            data: sessionData,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            window.alert('The session has been completed');
            window.location.reload();
        }
    };
    return (
        <Modal
            show={ProfileCoach?.showModalCoachingRoomCoach}
            aria-labelledby="CoachingRoomModal__container"
            centered
            className="CoachingRoomModal__container OolaScroll"
            dialogClassName="CoachingRoomModalDialog__container coach"
            onHide={() => {
                setNewData({
                    ProfileCoach: { showModalCoachingRoomCoach: false },
                });
                changeReloadClients(false);
            }}
        >
            <Modal.Header>
                <button
                    className="exit"
                    onClick={() =>
                        setNewData({
                            ProfileCoach: { showModalCoachingRoomCoach: false },
                        })
                    }
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body className="CoachingRoomModalBody__container coach">
                <div className="CoachingRoomModalHeader__container">
                    <h1>COACHING ROOM</h1>
                    <div className="CoachProfileFinal__container ">
                        <CoachingRoomInfoComponentCoach />
                        <div className="CoachProfileFinalInteractions__container ">
                            <div
                                className={`CoachProfileFinalJoinSession__container ${
                                    showVideoCall && 'videoActive'
                                }`}
                            >
                                {showVideoCall ? (
                                    <CoachVideoCall
                                        currentClient={currentClient}
                                    />
                                ) : (
                                    <div>
                                        <button
                                            className={`btnJoinSessionCoachingRoom ${
                                                !(
                                                    currentClient?.sessionsRemaining >
                                                    0
                                                ) && 'disabled'
                                            }`}
                                            type="button"
                                            onClick={() =>
                                                currentClient?.sessionsRemaining >
                                                    0 && setShowVideoCall(true)
                                            }
                                        >
                                            <i
                                                className="fa fa-video-camera"
                                                aria-hidden="true"
                                            />
                                            <span>START SESSION</span>
                                        </button>
                                        <p>
                                            To join a meeting please launch the
                                            meeting on a Chrome or Firefox
                                            browser.
                                        </p>
                                    </div>
                                )}
                            </div>
                            

                        </div>
                       
                        <div className="OolaChatCommentsHeader__container client">
                            <div className={`OolaChatbtn__container  `}>
                                <button
                                    className={`btnJoinSessionCoachingRoom ${
                                        !ProfileCoach?.meetingData
                                            ?.meetingCode &&
                                        !showVideoCall &&
                                        'disabled'
                                    }`}
                                    type="button"
                                    onClick={() => {
                                        completeSession();
                                        setShowVideoCall(!showVideoCall);
                                    }}
                                >
                                    <i
                                        className="fa fa-video-camera"
                                        aria-hidden="true"
                                    />
                                    <span>COMPLETE SESSION</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="ChatNotebookTabButtons__container">
                        <button
                            className={`NotebookCoachingButton ${
                                !showChat && 'active'
                            }`}
                            onClick={() => {
                                setShowChat(false);
                            }}
                        >
                            NOTEBOOK
                        </button>
                        <button
                            className={`ChatCoachingButton ${
                                showChat && 'active'
                            }`}
                            onClick={() => {
                                var newData = {
                                    currentClient: { ...currentClient },
                                };
                                newData.currentClient.pendingMessages = 0;
                                setNewData(newData);
                                setShowChat(true);
                            }}
                        >
                            {currentClient?.pendingMessages > 0 && (
                                <div className="iconPendingMessage">{`${currentClient.pendingMessages}`}</div>
                            )}
                            CHAT
                        </button>
                    </div>

                    {showChat
                        ? ChatMessage?.GroupId && (
                              <OolaChat setShowVideoCall={setShowVideoCall} />
                          )
                        : ChatMessage?.GroupId && (
                              <CoachingRoomNoteBook
                                  setShowVideoCall={setShowVideoCall}
                              />
                          )}
                    <div className="CoachingRoomModalCurrentInfoClient__container">
                        <div>
                            <h1>
                                Current Oola score:{' '}
                                {currentClient?.latestOolaScore}
                            </h1>
                            <h2>Date: {currentClient?.latestOolaDate} </h2>
                        </div>
                        <div>
                            <h1>
                                current e-learning:{' '}
                                {currentClient?.currentLessonName}
                            </h1>
                            <h2>{currentClient?.currentPhase}</h2>
                        </div>
                        <div className="last">
                            <h1>
                                Sessions completed:{' '}
                                {currentClient?.sessionsCompleted} <br />
                                sessions remaining:{' '}
                                {currentClient?.sessionsRemaining}
                            </h1>
                        </div>
                       
                            
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CoachingRoomModalCoach;
