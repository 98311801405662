import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Goals.css';
import './RecommendationGoal.css';

const RecomendationGoal = (props) => {
    const [GoalText, GoalTextChangeO] = useState(props.item.text);
    const [Selected, SelectedChange] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const currStep = props.AllState.Fs[props.AllState.CurrentF];
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    function GoalTextChange(text) {
        const ntext = String(text).trimLeft().replace('  ', ' ');
        if (ntext.length <= 300) {
            GoalTextChangeO(ntext);
        }
    }
    const currRecomendation = () => {
        const lRecomendation = props.AllState.SelectedGoals.find(
            (x) =>
                x.areaId === props.AllState.CurrentF &&
                x.recomendationIndex === props.AllState.recomendationIndex - 1
        );
        return lRecomendation == null ? { date: startDate,text:GoalText } : lRecomendation;
    };

    function addRecommendation() {
        if (startDate === null) {
            props.setErrorObj({
                ModalErrorTitle: 'You are almost there!',
                ModalErrorDesc: 'Select a date.',
            });
            props.setShowError(true);
        } else {
            const AllState = { ...props.AllState };
            if (
                AllState.SelectedGoals.filter(
                    (x) => x.areaId === props.AllState.CurrentF
                ).length < 3
            ) {
                SelectedChange(1);
                const newGoal = {
                    recomendationIndex: AllState.SelectedGoals.filter(
                        (x) => x.areaId === props.AllState.CurrentF
                    ).length,
                    text: String(GoalText).trimLeft().replace('  ', ' '),
                    areaId: props.AllState.CurrentF,
                    date: startDate,
                    Id: AllState.SelectedGoals.length,
                };
                AllState.SelectedGoals.push(newGoal);
                // props.showModalEvent(false)
                props.AllState.changeData(AllState);
            } else {
                // props.setErrorObj
                props.setErrorObj({
                    ModalErrorTitle: 'You are done!',
                    ModalErrorDesc: 'You can only select 3 goals.',
                });
                props.setShowError(true);
            }
        }
    }
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className={`datePickerGoal ${String(value).length !== 0 ? 'btnDatePickerGoalUnselect' : 'btnDatePickerGoal'}`}
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));
    return (
        <div
            className="recommendationGoalModal__container"
            style={{
                backgroundColor: currStep.color,
            }}
        >
            <div className="recommendationGoalModal__input">
                <input
                    className="GoalSelected"
                    placeholder={
                        currRecomendation().text == null
                            ? `Define your ${currStep.name} goal`
                            : currRecomendation().text
                    }
                    onClick={() => props.showModalEvent(true)}
                    value={GoalText}
                    onChange={(e) => GoalTextChange(e.target.value)}
                />
            </div>
            <div className="recommendationGoalModal__date">
                <DatePicker
                    dateFormat="MMMM dd, yyyy"
                    selected={currRecomendation().date}
                    onChange={(date) => setStartDate(date)}
                    withPortal 
                    className="datePickerGoal"
                    minDate={new Date()}
                    placeholderText="Complete by: mm/dd/yyyy"
                    customInput={<CustomInput />}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div>
                            <div
                                className="calendarHeader"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                COMPLETE BY:
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingTop: '10px',
                                }}
                            >
                                <button
                                    type="button"
                                    className="btnCalendar"
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                >
                                    {'<'}
                                </button>
                                <div className="lblMonth">
                                    {months[new Date(date).getMonth()]}  {new Date(date).getFullYear()}
                                </div>
                                <button
                                    type="button"
                                    className="btnCalendar"
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                >
                                    {'>'}
                                </button>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="recommendationGoalModal__buttons">
                {Selected === 0 ? (
                    <button
                        type="button"
                        onClick={() => props.AddRecomendation==null?addRecommendation(): props.AddRecomendation(currRecomendation())}
                        style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40 40"
                            style={{
                                transform: 'rotate(45deg)',
                                height: '40px',
                                width: '40px',
                            }}
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="buttonText">ADD</div>
                    </button>
                ) : (
                    <div>
                        <svg
                            viewBox="0 0 440 440"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ height: '40px', width: '30px' }}
                        >
                            <path
                                cx="220"
                                cy="220"
                                fill="white"
                                d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
                            />
                        </svg>
                        <div className="buttonText">ADDED</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RecomendationGoal;
