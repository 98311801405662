import React, { useContext, useState, useEffect } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../Context/AppContext';
import Tracker from './Dashboard/Tracker/Tracker';
import UserInfo from './Dashboard/UserInfo/UserInfo';
import ELearnigBoard from './Dashboard/ELearningBoard/ELearningBoard';
import HeaderDashboard from './Dashboard/Header/HeaderDashboard';
import MyGoals from './Dashboard/MyGoals/MyGoals';
import MyProgress from './Dashboard/MyProgress/MyProgress';
import MyOolaScore from './Dashboard/MyOolaScore/MyOolaScore';
import ModalBadget from './Dashboard/UserInfo/Modals/ModalBadget';
import Network from './Network/Network';
import configData from '../config.json';
import currentUserStep from '../util/currentUserStep';
import FooterCopyright from './Common/FooterCopyright/FooterCopyright';
import Tutorial from './Dashboard/Tutorial/Tutorial';
import requestAxios from '../util/requestAxios';
import MyCoachLock from '../Assets/img/Dashboard/MyCoachLock.png';
import WeeklyInspirationLock from '../Assets/img/Dashboard/WeeklyInspirationLock.png';
import './Dashboard.css';
import Sales from './Dashboard/Sales/Sales';
import MyOolaCoach from './Dashboard/MyOolaCoach/MyOolaCoach';

const Dashboard = () => {
    const { checkUserSession, updateUserStepId, changeLoading, setNewData } =
        useContext(AppContext);
    const { token, userId, isShopify } = checkUserSession();
    const [dashboardTutorial, setDashboardTutorial] = useState(false);
    const [init, setInit] = useState(false);
    const [refreshCourses, setRefreshCourses] = useState(1);
    const [myProgressLoading, setMyProgressLoading] = useState(true);
    const [achievementsLoading, setAchievementsLoading] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(async () => {
        if (token == '') history.push('/');
        changeLoading(true);
        const userStepCheck = await updateUserStepId();
        const { userStepId } = userStepCheck;
        const userInfo = checkUserSession();

        if (userInfo.userTypeId === 4) {
            const params = new Proxy(
                new URLSearchParams(window.location.search),
                {
                    get: (searchParams, prop) => searchParams.get(prop),
                }
            );
            const urlRedirect = `/nonSubscriber${
                params.showActionsSteps == 1 ? '?showActionsSteps=1' : ''
            }`;
            window.location.replace(urlRedirect);
            return;
        }
        if (userStepId < 4 || userStepId >= 5) {
            history.push(currentUserStep(userStepId >= 5 ? 1 : userStepCheck));
        }
        getAllInfo();
    }, []);

    const [currBadget, currBadgetChange] = useState(null);

    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 760);
        setWindowWidth(window.innerWidth);
        setNewData({ isMobile: { value: window.innerWidth <= 760 } });
    });

    const [state, setState] = useState({
        AllSteps: 0,
        DailySteps: 0,
        Actions: [],
        userProgress: {
            stepsTaken: 0,
            currentStreak: 0,
            longestStreak: 0,
            goalsCompleted: 0,
        },
        profile: {
            achievements: {
                badges: [],
                milestones: [],
                streaks: [],
            },
            latestAchievements: [],
        },
    });

    async function getCustomerProfileInformation() {
        const us = checkUserSession();
        const url = `Customer/GetCustomerProfileInfo/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            const today = new Date();
            data.customerLocalTime = new Date(
                data.customerLocalTime ? data.customerLocalTime : today
            );
            state.profile = data;

            setInit(true);
            setState(state);
            changeLoading(false);
            setNewData({
                ProfileCoach: { currentClient: data },
                CustomerProfileInfo: { ...data },
            });
            forceUpdate();
        }
    }

    async function getMyAchievements() {
        setAchievementsLoading(true);
        const url = `Customer/GetMyAchiements/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            setAchievementsLoading(false);
        } else {
            state.profile.latestAchievements = data;
            setState(state);
            const bagdet = state.profile.latestAchievements.filter(
                (x) => x.achieved === true && x.displayed === false
            );
            if (bagdet.length > 0) {
                bagdet[0].showModal = true;
                currBadgetChange(bagdet[0]);
            }
            forceUpdate();
            setAchievementsLoading(false);
        }
    }

    async function getMyProgress() {
        setMyProgressLoading(true);
        const url = `Dashboard/GetUserProgress/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            setMyProgressLoading(false);
        } else {
            state.userProgress = data;
            setState(state);
            forceUpdate();
            setMyProgressLoading(false);
        }
    }

    async function getMyOolaScore() {
        const url = `Dashboard/GetOolaScores`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
        } else {
            state.OolaScore = data;
            setState(state);
            forceUpdate();
        }
    }
    async function getAllInfo() {
        await getCustomerProfileInformation();
        await getMyProgress();
        await getMyAchievements();
        await getMyOolaScore();
    }

    function hideModalBadget(badgetInfo) {
        const url = `${configData.SERVER_URL}Customer/UpdateDisplayedBadge`;
        const objPost = {
            userid: userId,
            achievementid: badgetInfo.id,
        };
        axios
            .put(url, objPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(() => {
                currBadgetChange(null);
                getMyAchievements();
                getAllInfo();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div
            className={`Dashboard__container ${
                isShopify && 'DashboardShopify__container'
            }`}
        >
            <HeaderDashboard profile={state.profile} />
            <section className="dashboard">
                <div className="DashboardSection1">
                    <div className="DashboardSection11">
                        <UserInfo
                            AllState={state}
                            AllStateChange={setState}
                            getCustomerProfileInformation={getAllInfo}
                            achievementsLoading={achievementsLoading}
                        />
                        <MyProgress
                            AllState={state}
                            AllStateChange={setState}
                            myProgressLoading={myProgressLoading}
                        />
                    </div>
                    <div className="DashboardSection12">
                        {state.profile.enableMyCoachSection ? (
                            <MyOolaCoach
                                AllState={state}
                                getCustomerProfileInformation={getAllInfo}
                            />
                        ) : (
                            <div className="LockSection MyCoachLock space">
                                <p className="heading-5 BlueDarkText">
                                    MY OOLA COACH
                                </p>
                                <img src={MyCoachLock} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="DashboardSection2">
                    <div className="DashboardSection21">
                        <Tracker
                            AllState={state}
                            AllStateChange={setState}
                            getMyProgress={getAllInfo}
                            setRefreshCourses={setRefreshCourses}
                        />
                        <MyGoals
                            AllState={state}
                            AllStateChange={setState}
                            getMyProgress={getAllInfo}
                            getMyAchiements={getMyAchievements}
                            mobile={mobile}
                        />
                        <div className="MyOolaScoreCell__container">
                            <MyOolaScore
                                AllState={state}
                                AllStateChange={setState}
                                currBadget={currBadget}
                            />
                        </div>
                    </div>
                    <div className="DashboardSection22 ">
                        {init && refreshCourses > 0 && (
                            <ELearnigBoard AllState={state} />
                        )}
                    </div>
                </div>
                <div className="DashboardSection3">
                    <h1 className="BlueDarkText">MY RECOMMENDATIONS</h1>
                    <Sales />
                </div>
            </section>
            <FooterCopyright />

            {currBadget !== null && (
                <ModalBadget
                    currBadget={currBadget}
                    hideModalBadget={hideModalBadget}
                    setDashboardTutorial={setDashboardTutorial}
                />
            )}

            {dashboardTutorial && (
                <Tutorial setDashboardTutorial={setDashboardTutorial} />
            )}

            <Network />
        </div>
    );
};

export default Dashboard;
