import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import LoginHeader from '../Common/LoginHeader/LoginHeader';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import ArrowSvg from '../../Assets/icons/ArrowNoCircle.svg';
import configData from '../../config.json';
import AliceCarousel from 'react-alice-carousel';
import Collapsable from '../LearnMore/FrequentQuestion/NewCollapsable';
import './TRS.css';
import SwipeableViews from 'react-swipeable-views';

export default () => {
    const carousel = useRef();
    const carouselBig = useRef();
    const [showModal, setShowModal] = useState(false);
    const [showModalImg, setShowModalImg] = useState(false);
    const [imgIngredientsStepV, SetImgIngredientsStepVx] = useState('');
    // const [imgIndex, setImgIndex] = useState(1);

    function goEnroll() {
        window.location.replace(
            'https://myoola.oolalife.com/corporphan/enrollment'
        );
    }
    function goTRS() {
        window.location.replace(configData.TRSLINK);
    }
    const items = [1, 2, 3, 4, 5, 6].map((item) => (
        <img
            className={`productTRSimgPreview productTRSimg${item}`}
            onMouseEnter={() => setImgIndex(item)}
        />
    ));
    const renderNextButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                style={{ transform: 'rotate(180deg)' }}
                className="ArrowTRS Right"
                onClick={() => {
                    setArrowIndex(1);
                }}
            />
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                className="ArrowTRS Left"
                onClick={() => setArrowIndex(-1)}
            />
        );
    };
    const responsive = {
        0: { items: 4 },
    };

    const SetImgIngredientsStepV = (classn) => {
        setShowModalImg(true);
        SetImgIngredientsStepVx(classn);
    };
    const setImgIndex = (index) => {
        carousel?.current?.slideTo(index - 1);
        carouselBig?.current?.slideTo(index - 1);
    };
    const setArrowIndex = (index) => {
        if (index == 1) {
            carouselBig?.current?.slideNext();
        } else {
            carouselBig?.current?.slidePrev();
        }
    };
    const renderSlideInfo = ({ item, itemsCount }) => {
        carousel?.current?.slideTo(item - 1);
    };
    return (
        <>
            <LoginHeader
                goEnroll={goEnroll}
                isDarkLogo
                enrollButtonText="ENROLL"
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="TRS__container">
                <div className="TRS__header">
                    <h1>
                        TOTAL
                        <br /> REBALANCING
                        <br /> SYSTEM
                    </h1>
                    <hr />
                    <h2>
                        {'COMPLETE MIND & BODY'}
                        <br /> REBALANCING FROM THE INSIDE OUT
                    </h2>
                </div>
                <div className="TRS__Product">
                    <div className="TRS__ProductImg__container">
                        <AliceCarousel
                            key="carousel"
                            mouseTracking
                            items={[1, 2, 3, 4, 5, 6].map((item) => (
                                <div
                                    className="productTRSimg__contanier"
                                    key={`productTRSimg__contanier${item}`}
                                >
                                    <img
                                        className={`productTRSimg productTRSimg${item}`}
                                    />
                                </div>
                            ))}
                            controlsStrategy="alternate"
                            infinite
                            autoPlayInterval={3000}
                            animationDuration={1000}
                            renderPrevButton={() => <div />}
                            renderNextButton={() => <div />}
                            renderDotsItem={() => {
                                <div />;
                            }}
                            ref={carouselBig}
                        />

                        <AliceCarousel
                            key="carouselSildeBar"
                            mouseTracking
                            className="productTRSimgPreview__carousel"
                            items={[1, 2, 3, 4, 5, 6].map((item) => (
                                <img
                                    className={`productTRSimgPreview productTRSimg${item}`}
                                    onClick={() => setImgIndex(item)}
                                />
                            ))}
                            responsive={{
                                0: { items: 4 },
                            }}
                            controlsStrategy="alternate"
                            infinite
                            autoPlayInterval={3000}
                            animationDuration={1000}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            ref={carousel}
                        />
                    </div>
                    <div className="TRS__ProductDescription__container">
                        <h1>TOTAL REBELANCING SYSTEM</h1>
                        <h2>Retail price: $234.95</h2>

                        <h3> $213.95 </h3>
                        <h4> with free shipping</h4>
                        <button
                            className="button-full-mid"
                            type="button"
                            onClick={() => goTRS()}
                        >
                            ADD TO CART
                        </button>
                        <h5> What’s Included:</h5>
                        <p>
                            Experience the power of total mind and body
                            rebalancing by using all six of our mind and body
                            products in a simple sunrise-to-sunset protocol. The
                            Total Rebalancing System (TRS) includes our Mind and
                            Body dietary supplements, Rise and Rest nutritional
                            gummies, Smart Energy Oola Shots, and Connection
                            probiotics. With the TRS, you also get access to the
                            Oola Lifestyle Framework – our digital personal
                            development program (think of it as a life coach in
                            your pocket!).
                        </p>
                    </div>
                </div>
                <div className="TRS__Experience">
                    <hr />
                    <h1>
                        Experience the power of
                        <br /> Total mind and body <span>rebalancing</span>
                    </h1>
                    <p>
                        Our crazy busy modern lifestyle and toxic environment
                        overwhelm us with physical, chemical, and emotional
                        stress that breaks us down and creates imbalances in our
                        mind and body leading to sluggish metabolism, weight
                        gain, energy slumps, lack of focus, foggy memory,
                        anxiety, mood swings, discomfort, gut distress, and poor
                        sleep.
                    </p>
                    <p>
                        <b>
                            {' '}
                            The body was designed to protect, rebalance, and
                            heal itself but it’s never been bombarded with as
                            many stressors as it is today. In short, it needs
                            some help. The Earth contains everything we need to
                            thrive.
                        </b>
                    </p>
                    <p>
                        Unfortunately, most foods and supplements are deficient
                        in the micronutrients, herbs, and botanicals needed to
                        effectively and thoroughly combat physical, mental, and
                        chemical stress. No single silver bullet ingredient is
                        enough to resolve them all.
                    </p>
                    <p>
                        When we get all the right vitamins and minerals, herbs,
                        nootropics, adaptogens, and probiotics our body
                        eliminates impurities, calms and sharpens our mind,
                        stabilizes our mood, restores our gut, re-synchronizes
                        our biorhythms, and maximizes our performance. When all
                        our systems are working in tune and on time we perform
                        at peak capacity mentally and physically and every area
                        of our life improves.
                    </p>
                </div>
                <div className="science_ExpertsMind">
                    <div className="science_ExpertsMindText TRS">
                        <h1>
                            Experience Mind
                            <br /> and Body <span>Wellness</span>
                        </h1>
                        <p>
                            At Oola, our team of world class scientists have
                            designed a comprehensive suite of natural mind and
                            body products with everything you need to start
                            feeling amazing again so you can perform like a
                            champ from sunrise to sunset.
                        </p>
                        <p>
                            We call it our Total Rebalancing System. It includes
                            6 mind and body rebalancing products to use in a
                            simple ‘sunrise to sunset’ protocol. The products
                            are easy to use, taste amazing, and fit seamlessly
                            into your crazy busy life. Each product is effective
                            on its own, but they work best when used together in
                            the Total Rebalancing System.
                        </p>
                        <button
                            className="button-full-mid"
                            type="button"
                            onClick={() => goTRS()}
                        >
                            ADD TO CART
                        </button>
                    </div>
                    <div className="science_ExpertsMindImage TRS">
                        <ReactPlayer
                            url={'https://vimeo.com/872007588'}
                            width="100%"
                            controls
                        />
                    </div>
                </div>
                <div className="TRS__Research">
                    <h1>
                        Research it by <span>trying it</span>
                    </h1>
                    <h2>
                        Put it to the test on yourself for 30 days to see what
                        it can do. If you aren’t getting the mind and body
                        results you desire, get your money back with our 30-day
                        money back guarantee.
                    </h2>
                    <hr/>
                    <h1 style={{ marginTop: '50px' }}>
                        The sunrise to sunset <span>system</span>
                    </h1>
                    <h2>
                        We believe in making your rebalancing journey as simple,
                        effective, and fun as possible. Here’s a breakdown of
                        how to incorporate the Total Rebalancing System into
                        your everyday life… from sunrise to sunset.
                    </h2>
                </div>
                <div className="TRS__Steps">
                    {/*---------------- RiseGummy--------------- */}
                    <div className="TRS__StepsItemImg showMobile">
                        <img className="RiseGummy" />
                        <hr />
                    </div>{' '}
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 1: <span>Rise gummies</span>
                        </h1>
                        <h2>
                            Start your day off right with these delicious
                            gummies. Take 2 gummies upon waking to kickstart
                            your morning and provide your body with essential
                            nutrients.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>Mental alertness and clarity*</li>
                                        <li>Healthy energy levels* </li>
                                        <li>Supports metabolism* </li>
                                        <li>Balanced dose of caffeine* </li>
                                        <li>Supports lean body mass*</li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep1"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep1'
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="TRS__StepsItemImg showDesktop">
                        <img className="RiseGummy" />
                        <hr />
                    </div>
                    {/*---------------- MindGummy--------------- */}
                    <div className="TRS__StepsItemImg">
                        <img className="Mind" />
                    </div>
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 2: <span>MIND</span>
                        </h1>
                        <h2>
                            Start your day off right with these delicious
                            gummies. Take 2 gummies upon waking to kickstart
                            your morning and provide your body with essential
                            nutrients.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>Contains 7 Nootropics </li>
                                        <li>
                                            Supports neurons + brain cells*{' '}
                                        </li>
                                        <li>
                                            Supports healthy mood + memory*{' '}
                                        </li>
                                        <li>
                                            Covers major vitamin+mineral
                                            deficiencies*{' '}
                                        </li>
                                        <li>
                                            Helps protect against oxidative
                                            stress*{' '}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep2"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep2'
                                        )
                                    }
                                />
                            )}
                        />
                        <hr />
                    </div>
                    {/*---------------- OolaShot--------------- */}
                    <div className="TRS__StepsItemImg showMobile">
                        <img className="OolaShot" />
                        <hr />
                    </div>
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 3: <span>OOLA SHOTS</span>
                        </h1>
                        <h2>
                            Combat the afternoon lull with an energizing Oola
                            Shot – taken as necessary. Convenient and easy to
                            take, this powerful boost will help you stay
                            productive and alert.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>Long-lasting energy* </li>
                                        <li>
                                            Supports mood and brain function*
                                        </li>
                                        <li>Alertness and clarity* </li>
                                        <li>Goal-crushing performance* </li>
                                        <li>Nitric oxide production* </li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep3"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep3'
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="TRS__StepsItemImg showDesktop">
                        <img className="OolaShot" />
                        <hr />
                    </div>
                    {/*---------------- Connection--------------- */}
                    <div className="TRS__StepsItemImg">
                        <img className="Connection" />
                    </div>
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 4: <span>Connection</span>
                        </h1>
                        <h2>
                            Prioritize gut health and your gut-brain axis with
                            Oola Connection probiotic. Take one every 1 to 2
                            days and experience the benefits of reduced mild
                            anxiety and improved overall well-being throughout
                            the day.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>
                                            Mental and emotional well-being*{' '}
                                        </li>
                                        <li>
                                            12 powerful strains - 10 billion CFU{' '}
                                        </li>
                                        <li>Supports mild anxiety* </li>
                                        <li>Healthy gut microbiome* </li>
                                        <li>Supports gut-brain axis* </li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep4"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep4'
                                        )
                                    }
                                />
                            )}
                        />
                        <hr />
                    </div>
                    {/*---------------- BODY--------------- */}
                    <div className="TRS__StepsItemImg showMobile">
                        <img className="Body" />
                        <hr />
                    </div>
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 5: <span>BODY</span>
                        </h1>
                        <h2>
                            With your last meal, take 2 Oola Body supplements.
                            Our comprehensive 3-layer technology and adaptogenic
                            7 matrix proprietary blend is designed to provide
                            your body with powerful phytonutrients designed to
                            help your body adapt to and appropriately respond to
                            physical and mental stress.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>Contains 7 adaptogens</li>
                                        <li>
                                            Supports proper collagen synthesis*{' '}
                                        </li>
                                        <li>
                                            Helps sustain healthy immune system*{' '}
                                        </li>
                                        <li>
                                            Maintains healthy veins and
                                            arteries*{' '}
                                        </li>
                                        <li>
                                            Powerful antioxidants to support
                                            well-being*
                                        </li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep5"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep5'
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="TRS__StepsItemImg showDesktop">
                        <img className="Body" />
                        <hr />
                    </div>
                    {/*---------------- RestGummy--------------- */}
                    <div className="TRS__StepsItemImg ">
                        <img className="Rest" />
                    </div>
                    <div className="TRS__StepsItem">
                        <h1>
                            Step 6: <span>REST</span>
                        </h1>
                        <h2>
                            Wind down and prepare for a restful night’s sleep.
                            Take 2 gummies 30 minutes before bed to promote
                            relaxation and rejuvenation.
                        </h2>
                        <Collapsable
                            Title={'Benefits'}
                            Description={() => (
                                <div>
                                    <ul>
                                        <li>Supports restorative sleep* </li>
                                        <li>Promotes a sense of calm* </li>
                                        <li>Supports healthy sleep cycles* </li>
                                        <li>
                                            Supports healthy brain nerve
                                            activity*
                                        </li>
                                        <li>Helps with stress relief* </li>
                                    </ul>
                                </div>
                            )}
                        />
                        <Collapsable
                            Title={'Ingredients'}
                            Description={() => (
                                <img
                                    className="imgIngredientsStep imgIngredientsStep6"
                                    onClick={() =>
                                        SetImgIngredientsStepV(
                                            'imgIngredientsStep6'
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="TRS__AddCart">
                    <h2>
                        By following this simple sunrise to sunset mind and body
                        rebalancing regimen and completing your digital Oola
                        Lifestyle Framework, you will begin to experience the
                        many life-changing benefits of living the Oola Life.
                    </h2>
                    <button
                        className="button-full-mid"
                        type="button"
                        onClick={() => goTRS()}
                    >
                        ORDER TODAY
                    </button>
                </div>
                <div className="affiliate_WhatOtherSaying">
                    <div className="affiliate_WhatOtherSayingHeader">
                        <svg
                            viewBox="0 0 90 45"
                            className="svgBanneraffiliateDesk"
                        >
                            <g transform="translate(0 30)">
                                <g className="affiliate__headerSVGTextContainer">
                                    <text
                                        transform="translate(0 20)"
                                        fill="#cbeeff"
                                        fontSize={60}
                                    >
                                        “
                                    </text>

                                    <text
                                        transform="translate(10 0)"
                                        fill="#2C414C "
                                        fontSize={14}
                                    >
                                        {'WHAT OTHERS '}
                                    </text>

                                    <text
                                        transform="translate(15 12)"
                                        fill="#2C414C "
                                        fontSize={14}
                                        className="affiliateHeaderTextlvl2"
                                    >
                                        {'ARE'}
                                    </text>
                                    <text
                                        transform="translate(36 12)"
                                        fill="#78CCF6 "
                                        fontSize={14}
                                        className="affiliateHeaderTextlvl2"
                                    >
                                        {'SAYING'}
                                    </text>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="affiliate_WhatOtherSayingDetail">
                        <div className="affiliate_WhatOtherSayingPeopleItem__container">
                            <div className="affiliate_WhatOtherSayingPeopleItem TRS">
                                <div className="affiliate_WhatOtherSayingPeopleItemImg__container P3">
                                    <img className="affiliate_WhatOtherSayingPeopleItemImg" />
                                </div>
                                <h1>ERICA RASMUSSEN</h1>
                                <h2>
                                    What I love about using TRS daily is that
                                    it’s simple, easy and effective! My focus
                                    and productivity are stronger. I have the
                                    energy to get after big goals. And, my body
                                    is able to recover better than ever!
                                </h2>
                            </div>
                            <div className="affiliate_WhatOtherSayingPeopleItem TRS">
                                <div className="affiliate_WhatOtherSayingPeopleItemImg__container P4">
                                    <img />
                                </div>
                                <h1>GARON ATENCIO</h1>
                                <h2>
                                    The Total Rebalancing System has not only
                                    revitalized the enjoyment of taking my daily
                                    supplements, but it has also enabled me to
                                    maintain unwavering consistency.
                                </h2>
                            </div>
                        </div>
                        <div className="affiliate_WhatOtherSayingBecomeAffiliate__container">
                            <button
                                className="button-full-mid"
                                type="button"
                                onClick={() => goTRS()}
                            >
                                ORDER TODAY
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
            <Modal
                show={showModalImg}
                onHide={() => setShowModalImg(false)}
                aria-labelledby="example-custom-modal-styling-title"
                id="modalTRSSUPPIMG"
                className=""
                size="xl"
                centered
            >
                <Modal.Header className="modalAddTaskHeader__container">
                    <button
                        className="modalAddGoal__exit"
                        onClick={() => setShowModalImg(false)}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="ModalImgSUPP modalTRSSUPPIMG">
                        <img
                            className={`imgIngredientsStep ModalTRS ${imgIngredientsStepV}`}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
