import React, {useState} from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { Container, Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './Watch.css';

const Watch = (props) => {
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const onWindowResize = useGlobalEvent('resize');


    const Section = props.AllState.ModuleInfo.sections.find(
        (x) => x.name === 'Watch'
    );

    function createMarkup() {
        return { __html: Section.contentText };
    }

    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 760);
        setWindowWidth(window.innerWidth);
        // setNewData({ isMobile: { value: window.innerWidth <= 760 } });
    });

    const WatchR = (
        <Container fluid className="watch_container">
            {Section == null ? (
                ''
            ) : (
                <div className="watchHeader_container">
                    <div className="WatchTitle">
                        <h5>
                            <label> {Section.title}</label><br/>
                            {" "}PROGRESS{' '}
                            <span style={{ color: '#78ccf6' }}>
                                1/{props.lenghtModules}
                            </span>
                        </h5>
                    </div>
                    <div className="watchHeader_wrapper">
                        <div className="watch__modules">
                            <div className="watchTitle__modules">
                                <div
                                    className="WatchDesc"
                                    dangerouslySetInnerHTML={createMarkup()}
                                />
                            </div>
                            <div style={{ paddingTop: '1rem', width: '100%' }}>
                                <ReactPlayer
                                    url={
                                        Section.videoUrl.search('http') === -1
                                            ? +props.configData.SERVER_URL_IMG +
                                              Section.videoUrl
                                            : Section.videoUrl
                                    }
                                    width="100%"
                                    height={mobile ? "25vh" : "50vh"}
                                    controls
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="lineBottomWatch" />
        </Container>
    );
    return WatchR;
};
export default Watch;
