import Area1 from './AreaFitness.svg';
import Area2 from './AreaFinance.svg';
import Area3 from './AreaFamily.svg';
import Area4 from './AreaField.svg';
import Area5 from './AreaFaith.svg';
import Area6 from './AreaFriend.svg';
import Area7 from './AreaFun.svg';

const AreasIcons = { Area1, Area2, Area3, Area4, Area5, Area6, Area7 };

export { AreasIcons };
