import React from 'react';
import './Programs2.css';

const Programs = ({ goEnroll }) => {
    return (
        <div className="HomePagePrograms__Container">
            <h1>
                Programs <hr />
            </h1>
            <div className="HomePagePrograms__Description">
                <p className="paragraph-2">
                    A revolutionary personal development system combining a
                    proven lifestyle framework, fully-customized online courses,
                    and a global network of Certified Coaches to help you along
                    your journey.
                </p>
            </div>
        </div>
    );
};

export default Programs;
