import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import requestAxios from '../../../util/requestAxios';
import AppContext from '../../../Context/AppContext';
import './ModalTimeZone.css';
import configData from '../../../config.json';

const ModalTimeZone = ({ showModalTimezone, setshowModalTimezone, update }) => {

    const { checkUserSession, updateUserStepId } = useContext(AppContext);
    const [arrTimeZones, setarrTimeZones] = useState([{ value: 0, text: '' }]);
    const [timeZoneUser, settimeZoneUser] = useState(null);
    const [originalTimeZoneUser, setOriginalTimeZoneUser] = useState(null);
    const [timeZoneSelected, settimeZoneSelected] = useState(null);

    const [val, setVal] = useState({});

    useEffect(async () => {
        const userState = await updateUserStepId();
        const userInfo = checkUserSession();
        setOriginalTimeZoneUser(userState.definedTimeZoneId)
        const { error, data } = await requestAxios({
            url: `customer/GetDefinedTimeZones`,
            token: userInfo.token,
        });
        if (error === '') {
            // eslint-disable-next-line prefer-const
            let timeZones = [];
            if (userState.definedTimeZoneId == null) {
                setshowModalTimezone(true);
            }
            data.forEach((item) => {
                const time = new Date(item.currentTime);

                timeZones.push({
                    value: `${item.id}|${time.toLocaleTimeString('en-US')}`,
                    text: item.displayName,
                });
            });

            const selTimeZone = timeZones.find(
                (x) =>
                    Number(x.value.split('|')[0]) ===
                    userState.definedTimeZoneId
            );
            settimeZoneUser(userState.definedTimeZoneId);
            setarrTimeZones(timeZones);
            if (selTimeZone != null) {
                setVal(selTimeZone);
                settimeZoneSelected(selTimeZone.value.split('|')[1]);
            }
        }
    }, []);

    function setValueTimezone(input, values) {
        if (values != null) {
            settimeZoneUser(values.value.split('|')[0]);
            settimeZoneSelected(values.value.split('|')[1]);
            const selTimeZone = arrTimeZones.find(
                (x) => x.value === values.value
            );
            setVal(selTimeZone);
        } else {
            settimeZoneUser(null);
            settimeZoneSelected(null);
        }
    }

    function saveTimeZone() {
        if (timeZoneUser) {
            const userInfo = checkUserSession();

            const PostObj = {
                userId: userInfo.userId,
                definedTimeZoneId: timeZoneUser,
            };

            axios({
                method: 'put',
                url: `${configData.SERVER_URL}customer/UpdateUserDefinedTimeZone`,
                headers: { Authorization: `Bearer ${userInfo.token}` },
                data: PostObj,
            })
                .then(() => {
                    const text =
                        update == true
                            ? 'Time zone updated!'
                            : 'Thanks and Welcome to Oola!';
                    alert(text);

                    setshowModalTimezone(false);
                    window.location.reload();
                })
                .catch((err) => {
                    window.alert(err);
                });
        } else {
            alert('The Time Zone is required');
        }
    }

    const ModalTimeZoneR = (
        <Modal
            show={showModalTimezone}
            aria-labelledby="example-custom-modal-styling-title"
            centered
            className="shareOptionsModal TimeZoneModal"
            size="lg"
        >
            <Modal.Header className="shareOptionsModal__header">
                {update == true && (
                    <button
                        type="button"
                        onClick={() => {
                            if (originalTimeZoneUser) setshowModalTimezone(false);
                            else alert('The timezone is requires');
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="shareOptionsModal__body">
                    <div className="shareOptionsModal__bodyTitle">
                        <h3 className="heading-3">SELECT YOUR TIME ZONE</h3>
                        <br />
                        <br />
                        <p className="paragraph-3 TimeZoneContainer">
                            <Autocomplete
                                id="combo-box-demo"
                                className="inputTimeZone"
                                options={arrTimeZones}
                                getOptionLabel={(option) => option.text}
                                style={{ width: 300 }}
                                onChange={(input, value) =>
                                    setValueTimezone(input, value)
                                }
                                value={val}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select  your Timezone"
                                        variant="outlined"
                                    />
                                )}
                            />
                            <br />
                            {timeZoneSelected != null && timeZoneSelected}
                            <br />

                            <br />
                            <button
                                className="button-full-mid"
                                onClick={() => saveTimeZone()}
                                type="button"
                            >
                                SAVE
                            </button>
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
    return ModalTimeZoneR;
};

export default ModalTimeZone;
