import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useWindowSize } from '@react-hook/window-size';
import { Row, Col } from 'react-bootstrap';
import configData from '../../../config.json';
import Confetti from '../../Step1/Confetti/Confetti';
import ShareOptionsModal from '../../Share/ShareOptionsModal/ShareOptionsModal';
import getUserDate from '../../../util/getUserDate';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import './SelectFinishSection.css';
import './RecomendationSection.css';

const SelectFinishSection = (props) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const [activeShare, setActiveShare] = useState(false);
    const [loadingShare, setLoadingShare] = useState(false);
    const [shareData, setShareData] = useState({});

    const currUser = checkUserSession();
    const [showSide, ChangeShowSide] = useState(0);    
    const currSelect = props.AllState.Fs.find(
        (x) => x.areaId === props.AllState.SelectedOolaOne.areaId + 1
    );

    const [width, height] = useWindowSize();

    const [loading, setLoading] = useState(false);
    const handleShare = async () => {
        setLoadingShare(true);
        const { error, data } = await requestAxios({
            url: `Customer/GetShareContent/2`,
            token: currUser.token,
        });

        if (error)
            window.alert(
                'Sorry there was an error on sharing, please try again'
            );
        else {
            setShareData(data);
            setActiveShare(true);
            setLoadingShare(false);
        }
    };

    function getFormatDate(itemDate) {
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
            itemDate
        );
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
            itemDate
        );
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
            itemDate
        );
        return `${mo}/${da}/${ye}`;
    }

    
    const btnSection = (
        <section className="btnFinishSectionStep2">
            <button
                className="btnShareStep2 btnStep1 btnFinishSection"
                onClick={handleShare}
                type="button"
                style={{
                    display:'block'
                }}
            >
                {loading ? 'Loading...' : 'SHARE!'}
            </button>
            <button
                className="btnStep1 btnFinishSection "
                style={{
                    fontWeight: '500',
                    borderStyle: 'solid',
                    borderColor: '#D86B59',
                }}
                type="button"
                onClick={() =>
                    props.handleChangeindexStep(3)
                }
            >
                START STEP 3
            </button>    
        </section>
    );
    const ContentSide = (
        <section className="Step3SideLeft__content ">
            <div className="sideTitleStep3Finish Step3SideLeft__content1">
                <span style={{ color: '#78ccf6' }}>STEP 2</span> COMPLETE
            </div>
            <div className=" Step3SideLeft__content2">
                <div>
                    <h1 className="TitleNameEndStep3">GREAT WORK</h1>
                    <h1 className="TitleNameEndStep3">
                        {`${currUser.firstName}`}!
                    </h1>
                </div>
                <h6 className="DescriptionEnd">
                    You completed section two! take
                    a moment to share your OolaOne
                    on your social. 
                </h6>
                {(props.mobile === false) && btnSection}

            </div>
        </section>
    );

    const Full = (
        <section
            className={
                'Step3Tracker__container'
            }
        >
            <Confetti props={1} mobile={props.mobile} />
            {ContentSide}
            <section
                className={`Step3SideRight__content Step3SideRight__contentAnimation`}
            >
                <div
                    style={{ padding: '2rem' }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="250.268"
                        // height="250.271"
                        viewBox="0 0 628.268 628.271"
                        style={{ width: '80%' }}
                    >
                        <circle cx="320" cy="320" fill="white" r="300" />
                        <path
                            fill={currSelect.color}
                            d="M4220.066 4359.2h.52l-4.222-16.4a313.735 313.735 0 0 0-195.717-216.273c-.377-.133-.755-.278-1.134-.413q-6.5-2.365-13.121-4.452c-.446-.14-.883-.292-1.333-.433q-14.156-4.378-28.81-7.435c-1.291-.272-2.6-.5-3.893-.745-3.435-.669-6.878-1.3-10.342-1.855-1.751-.28-3.506-.529-5.264-.78q-4.685-.666-9.41-1.2c-1.825-.2-3.65-.405-5.485-.577-3.316-.313-6.655-.554-10-.766-1.605-.1-3.206-.228-4.826-.307-4.948-.229-9.913-.374-14.91-.374s-9.956.145-14.9.374c-1.619.08-3.22.205-4.831.307-3.338.211-6.675.453-9.992.766-1.837.172-3.66.373-5.484.577q-4.727.531-9.411 1.2c-1.759.251-3.513.5-5.263.78-3.465.555-6.909 1.186-10.342 1.855-1.3.249-2.6.473-3.895.745q-14.646 3.038-28.809 7.435c-.45.142-.887.293-1.333.433q-6.621 2.08-13.121 4.452c-.379.135-.757.28-1.132.413a313.753 313.753 0 0 0-195.722 216.273l-4.217 16.4h.521a314.293 314.293 0 0 0-6.2 62.127c0 173.213 140.922 314.136 314.132 314.136s314.136-140.922 314.136-314.136a314.476 314.476 0 0 0-6.19-62.127zm-307.943 350.015c-158.74 0-287.883-129.146-287.883-287.887a287.641 287.641 0 0 1 6.825-62.127h562.12a287.58 287.58 0 0 1 6.826 62.127c.001 158.738-129.14 287.885-287.888 287.885z"
                            transform="translate(-3597.991 -4107.19)"
                        />

                        <switch>
                            <foreignObject
                                x={0}
                                y={100}
                                width="625"
                                height="200"
                                fill="white"
                            >
                                <p
                                    xmlns="http://www.w3.org/1999/xhtml"
                                    className="StickerTitleStep2"
                                >
                                    {' '}
                                    {currSelect.name}
                                </p>
                            </foreignObject>
                        </switch>
                        <switch>
                            <foreignObject
                                x={60}
                                y={350}
                                width="500"
                                height="200"
                                fill="white"
                            >
                                <p
                                    xmlns="http://www.w3.org/1999/xhtml"
                                    className="FinishStickerStep2"
                                >
                                    {' '}
                                    {props.AllState.SelectedOolaOne.text}
                                </p>
                            </foreignObject>
                        </switch>
                    </svg>
                </div>
                {(props.mobile === true) && btnSection}
                
            </section>
            {activeShare && (
                <ShareOptionsModal
                    setActiveShare={setActiveShare}
                    text={`My Oola Actions ${getUserDate()} - `}
                />
            )}
        </section>
    );

    return Full;


   /* 
    return (
        <div
            className="section lastSectionstep2"
            style={{ width: '100%', height: '100%' }}
        >
            <Confetti props={1} mobile={props.mobile} delay={1000} />

            <Row
                key={99}
                style={{
                    width: '100%',
                    height: height * 0.9,
                    paddingLeft: '0',
                    color: '#FFF',
                    marginLeft: '0px',
                    marginRight: '0px',
                }}
            >
                <Col
                    xs={12}
                    md={6}
                    style={{
                        width: '100%',
                        height: '100%',
                        paddingLeft: '0',
                        color: '#FFF',
                        marginLeft: '0px',
                        marginRight: '0px',
                    }}
                >
                    <Row
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingLeft: '0',
                            color: '#FFF',
                            marginLeft: '0px',
                            marginRight: '0px',
                        }}
                    >
                        <Col xs={2} style={{ backgroundColor: '#2c414c' }}>
                            <div
                                className="sideTitle"
                                style={{
                                    position: 'absolute',
                                    zIndex: '1',
                                    top: height * 0.5,
                                    width: '258px',
                                    left: height * -0.05,
                                }}
                            >
                                {' '}
                                <span style={{ color: '#78ccf6' }}>
                                    STEP 2
                                </span>{' '}
                                COMPLETE
                            </div>
                        </Col>
                        <Col xs={10} style={{ backgroundColor: '#2c414c' }}>
                            <Row key={99}>
                                <Col xs={10} md={10}>
                                    <center style={{ marginTop: height * 0.2 }}>
                                        <h1 className="TitleNameEnd">
                                            GREAT WORK
                                        </h1>
                                        <h1 className="TitleNameEnd">
                                            {`${currUser.firstName} `}!
                                        </h1>
                                    </center>
                                    <h6
                                        className="DescriptionEnd"
                                        style={{ ali: 'left' }}
                                    >
                                        You completed step two! Take a moment to
                                        share your OolaOne on your social.
                                    </h6>
                                    <Row
                                        className="btnFinishSection"
                                        style={{ paddingTop: '30px' }}
                                    >
                                        <Col xs={4} md={4}>
                                            <button
                                                type="button"
                                                className="btnStep1 btnFinishSection  "
                                                onClick={handleShare}
                                                style={{
                                                    backgroundColor: '#2C414C',
                                                    borderColor: '#D86B59',
                                                    borderStyle: 'solid',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {loadingShare
                                                    ? 'Loading...'
                                                    : 'SHARE!'}
                                            </button>
                                        </Col>
                                        <Col xs={4} md={4}>
                                            <button
                                                type="button"
                                                className="btnStep1 btnFinishSection "
                                                onClick={() =>
                                                    props.handleChangeindexStep(
                                                        3
                                                    )
                                                }
                                                style={{
                                                    fontWeight: '500',
                                                    borderStyle: 'solid',
                                                    borderColor: '#D86B59',
                                                    marginLeft: '80px',
                                                }}
                                            >
                                                START STEP 3
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={12}
                    md={6}
                    style={{
                        width: '100%',
                        textAlign: 'Left',
                        zIndex: '1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="250.268"
                        // height="250.271"
                        viewBox="0 0 628.268 628.271"
                        style={{ width: '80%' }}
                    >
                        <circle cx="320" cy="320" fill="white" r="300" />
                        <path
                            fill={currSelect.color}
                            d="M4220.066 4359.2h.52l-4.222-16.4a313.735 313.735 0 0 0-195.717-216.273c-.377-.133-.755-.278-1.134-.413q-6.5-2.365-13.121-4.452c-.446-.14-.883-.292-1.333-.433q-14.156-4.378-28.81-7.435c-1.291-.272-2.6-.5-3.893-.745-3.435-.669-6.878-1.3-10.342-1.855-1.751-.28-3.506-.529-5.264-.78q-4.685-.666-9.41-1.2c-1.825-.2-3.65-.405-5.485-.577-3.316-.313-6.655-.554-10-.766-1.605-.1-3.206-.228-4.826-.307-4.948-.229-9.913-.374-14.91-.374s-9.956.145-14.9.374c-1.619.08-3.22.205-4.831.307-3.338.211-6.675.453-9.992.766-1.837.172-3.66.373-5.484.577q-4.727.531-9.411 1.2c-1.759.251-3.513.5-5.263.78-3.465.555-6.909 1.186-10.342 1.855-1.3.249-2.6.473-3.895.745q-14.646 3.038-28.809 7.435c-.45.142-.887.293-1.333.433q-6.621 2.08-13.121 4.452c-.379.135-.757.28-1.132.413a313.753 313.753 0 0 0-195.722 216.273l-4.217 16.4h.521a314.293 314.293 0 0 0-6.2 62.127c0 173.213 140.922 314.136 314.132 314.136s314.136-140.922 314.136-314.136a314.476 314.476 0 0 0-6.19-62.127zm-307.943 350.015c-158.74 0-287.883-129.146-287.883-287.887a287.641 287.641 0 0 1 6.825-62.127h562.12a287.58 287.58 0 0 1 6.826 62.127c.001 158.738-129.14 287.885-287.888 287.885z"
                            transform="translate(-3597.991 -4107.19)"
                        />

                        <switch>
                            <foreignObject
                                x={0}
                                y={100}
                                width="625"
                                height="200"
                                fill="black"
                            >
                                <p
                                    xmlns="http://www.w3.org/1999/xhtml"
                                    className="StickerTitle"
                                >
                                    {' '}
                                    {currSelect.name}
                                </p>
                            </foreignObject>
                        </switch>
                        <switch>
                            <foreignObject
                                x={60}
                                y={350}
                                width="500"
                                height="200"
                                fill="black"
                            >
                                <p
                                    xmlns="http://www.w3.org/1999/xhtml"
                                    className="FinishSticker"
                                >
                                    {' '}
                                    {props.AllState.SelectedOolaOne.text}
                                </p>
                            </foreignObject>
                        </switch>
                    </svg>
                </Col>
            </Row>
            {activeShare && (
                <ShareOptionsModal
                    webAlias={shareData.webAlias}
                    setActiveShare={setActiveShare}
                    imageUrl={shareData.socialMediaImageUrl}
                    text={`${shareData.socialMediaTitle.replace(
                        'date',
                        getUserDate()
                    )}. ${shareData.socialMediaMessage}`}
                />
            )}
        </div>
    );
    */
};

export default SelectFinishSection;
