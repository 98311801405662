import React, { useState, useEffect, useContext } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider, Typography, Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';

import AppContext from '../../../../Context/AppContext';
import HeaderDashboard from '../../Header/HeaderDashboard';
import QuestionAssesment from './QuestionAssesmentNewModule';
import Network from '../../../Network/Network';
import configData from '../../../../config.json';
import './AssesmentNewModule.css';
import ModalVideo from '../../../Common/ModalVideo/ModalVideo';

const Assesment = () => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [_index, ChangeIndex] = useState(0);
    const [disableAll, setDisableAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videoInfo, setVideoInfo] = useState({});
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const courseId = Number(urlParams.get('courseId'));
    const useStyles = makeStyles({
        root: {
            height: 300,
        },
    });

    const [Assesments, ChangeAssesments] = useState({
        htmlContent: '',
        htmlTitle: '',
        numberOfModules: '',
        questions: [],
    });

    const classes = useStyles();
    const [rangeValues, setrangeValues] = useState({
        begin: Assesments.questions.length,
        end: Assesments.questions.length,
    });
    const [OptionSelected, ChangeOptionSelected] = useState([]);
    const CurrQ = OptionSelected[_index];
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    useEffect(() => {
        const queryString = window.location.search;
        const url = `${configData.SERVER_URL}AdditionalCourse/GetAssessmentQuestions/${courseId}`;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                ChangeAssesments(result.data);
                setrangeValues({
                    begin: result.data.questions.length,
                    end: result.data.questions.length,
                });
                if (result.data.introVideoUrl) {
                    setVideoInfo({
                        Title: result.data.introTitle,
                        Description: result.data.introText,
                        VideoUrl: result.data.introVideoUrl,
                    });
                    setShowModal(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [1]);

    const PrettoSlider = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'vertical',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'vertical',
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);

    const marksMyPersonalizedPath = () => {
        const marks = [];

        Assesments.questions.forEach((element, index) => {
            const qLength = Assesments.questions.length;
            const item = {
                value: index + 1,
                label: (
                    <div
                        data-index={index}
                        className={
                            OptionSelected[qLength - index] != null ||
                            qLength - index - 1 <= OptionSelected.length
                                ? 'lblEnableAssesment'
                                : ''
                        }
                    >
                        Q
                        {qLength - index < 10
                            ? `0${qLength - index}`
                            : qLength - index}
                    </div>
                ),
            };
            marks.push(item);
        });

        return marks;
    };

    function changeIndexExt(value) {
        if (value <= Assesments.questions.length - 1) {
            setrangeValues({
                begin: Assesments.questions.length,
                end: Assesments.questions.length - Number(value),
            });
            const indexx = Number(value);
            setDisableAll(true)
            setTimeout(() => {
                ChangeIndex(indexx);
            setDisableAll(false)
        }, 500);

            forceUpdate();
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        ChangeIndex(newValue);
    };
    function createMarkup(value) {
        return {
            __html: `${Assesments[value]?Assesments[value]:""}`,
        };
    }
    function selectQuestion(value) {
        if (value.target.dataset.index != null) {
            setrangeValues({
                begin: Assesments.questions.length,
                end: Number(value.target.dataset.index) + 1,
            });
            const indexx =
                Assesments.questions.length -
                1 -
                Number(value.target.dataset.index);
            ChangeIndex(indexx);
        } else {
            setTimeout(() => {
                const parent =
                    document.getElementsByClassName('MuiSlider-root')[0];
                const valuex = parent
                    .querySelectorAll("input[type='hidden']")[0]
                    .value.split(',')[0];

                setrangeValues({
                    begin: Assesments.questions.length,
                    end: Number(valuex),
                });
                ChangeIndex(Assesments.questions.length - Number(valuex));
            }, 50);
        }
    }
    const AssesmentR = (
        <div
            style={{
                minHeight: '99vh',
                backgroundColor: '#f5f5f5',
                pointerEvents: disableAll ? 'none' : '',
            }}
        >
            <div style={{ backgroundColor: 'white' }}>
                <div xs={12}>
                    <HeaderDashboard />
                </div>
            </div>
            <section className="assesment__container ">
                <div className="assesment__wrapper">
                    <div style={{ paddingBottom: '30px' }}>
                        <Link to="/dashboard">
                            <button type="button" className="btnBackDashboard">
                                BACK TO DASHBOARD
                            </button>
                        </Link>
                    </div>
                    <div
                        className="AssesmentHeader"
                        dangerouslySetInnerHTML={createMarkup('htmlTitle')}
                    />
                    <div
                        className="AssesmenDescription"
                        dangerouslySetInnerHTML={createMarkup('htmlContent')}
                    />
                </div>
            </section>
            <section className="assesment__container">
                <div className="assesment__wrapper_questions hiddenMobileQuestion">
                    <div className="hiddenMobileQuestion">
                        <>
                            <Typography id="vertical-slider" gutterBottom />
                            <div className={classes.root}>
                                <PrettoSlider
                                    orientation="vertical"
                                    aria-labelledby="vertical-slider"
                                    marks={marksMyPersonalizedPath()}
                                    step={1}
                                    min={1}
                                    max={Assesments.questions.length}
                                    value={[rangeValues.begin, rangeValues.end]}
                                    onChange={(input) => selectQuestion(input)}
                                    style={{ height: '60vh', width: '0.5rem' }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="  hiddenMobileQuestion">
                        <QuestionAssesment
                            _index={_index}
                            Change_Index={changeIndexExt}
                            OptionSelected={OptionSelected}
                            ChangeOptionSelected={ChangeOptionSelected}
                            CurrQ={CurrQ}
                            forceUpdate={forceUpdate}
                            Assesments={Assesments}
                            ChangeAssesments={ChangeAssesments}
                        />
                    </div>
                </div>
                {
                    // ------------------------------------------------Mobile ------------------------------------------
                }
                <div className={`visibleMobileQuestion`}>
                    <div>
                        <>
                            <Typography
                                id="horizontal-slider"
                                gutterBottom
                                style={{
                                    height: '10px',
                                    width: window.innerWidth * 0.8,
                                    marginLeft: window.innerWidth * 0.05,
                                }}
                            />
                            <div className="TabSlider___container">
                                <Tabs
                                    value={_index}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {Assesments.questions.map((item, i) => (
                                        <Tab
                                            key={`tab${i + 1}`}
                                            className={
                                                OptionSelected.length + 1 <= i
                                                    ? 'questionTitleMobileDisable__text'
                                                    : 'questionTitleMobile__text'
                                            }
                                            label={`Q${
                                                i + 1 < 10 ? `0${i + 1}` : i + 1
                                            }`}
                                            disabled={
                                                OptionSelected.length + 2 <=
                                                i + 1
                                                    ? true
                                                    : false
                                            }
                                            {...a11yProps(i + 1)}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </>
                        <div className="assesment__wrapper_questionsMobile">
                            <QuestionAssesment
                                _index={_index}
                                Change_Index={changeIndexExt}
                                OptionSelected={OptionSelected}
                                ChangeOptionSelected={ChangeOptionSelected}
                                CurrQ={CurrQ}
                                forceUpdate={forceUpdate}
                                Assesments={Assesments}
                                ChangeAssesments={ChangeAssesments}
                                mobile={mobile}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Network />
            <ModalVideo
                show={showModal}
                setShow={setShowModal}
                url={videoInfo.VideoUrl}
                title={videoInfo.Title}
                description={videoInfo.Description}
            />
        </div>
    );
    return AssesmentR;
};

export default Assesment;
