import React from 'react';
import { Link } from 'react-router-dom';
import './FooterSection.css';

import oolaLogo from '../../../Assets/oola_w.svg';
import youtube from '../../../Assets/icons/icon_youtube.svg';
import facebook from '../../../Assets/icons/icon_facebook.svg';
import twitter from '../../../Assets/icons/icon_twitter.svg';
import instagram from '../../../Assets/icons/icon_instagram.svg';
import pinterest from '../../../Assets/icons/icon_pinterest.svg';
import ConfigData from '../../../config.json';

const FooterSection = ({goEnroll}) => (
    <footer className="footer__container">
        <div className="footer__wrap">
            <div className="logo__container">
                <figure>
                    <img src={oolaLogo} alt="logo Oola Life" />
                </figure>
                <div className="socials__container">
                    <a
                        href="https://www.youtube.com/user/OolaLife"
                        aria-label="youtube"
                    >
                        <img src={youtube} alt="youtube" />
                    </a>
                    <a
                        href="https://www.facebook.com/OolaLife"
                        aria-label="facebook"
                    >
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="https://twitter.com/OolaLife" aria-label="twitter">
                        <img src={twitter} alt="youtube" />
                    </a>
                    <a
                        href="https://www.instagram.com/oolalife"
                        aria-label="instagram"
                    >
                        <img src={instagram} alt="youtube" />
                    </a>
                    <a
                        href="https://www.pinterest.com.mx/OolaLife"
                        aria-label="pinterest"
                    >
                        <img src={pinterest} alt="youtube" />
                    </a>
                </div>
            </div>

            <div className="linksFooter__container">
                <div className="linksFooter__title">
                    <p className="paragraph-3">
                        About <span className="paragraph-3">Oola</span>
                    </p>
                </div>
                <div className="linksFooter__links">
                    <Link to="/OurStory" className="heading-5">
                        OUR STORY
                    </Link>
                    <Link to="/OurLeadership" className="heading-5">
                        OUR LEADERSHIP
                    </Link>
                    <Link
                        className="heading-5"
                        to="/partner"
                        aria-label="partner"
                    >
                        PARTNER WITH OOLA
                    </Link>
                    <Link to="/ourCause" className="heading-5">
                        OUR CAUSE
                    </Link>
                </div>
            </div>

            <div className="linksFooter__container">
                <div className="linksFooter__title">
                    <p className="paragraph-3">
                        More <span className="paragraph-3">Oola</span>
                    </p>
                </div>
                <div className="linksFooter__links">
                    <a
                        className="heading-5"
                        aria-label="find a coach"
                        href={ConfigData.ADDITIONALCOURSESSERVER}
                    >
                        PROGRAMS + PRODUCTS
                    </a>
                    <a
                        className="heading-5"
                        href={ConfigData.ProductsPrograms}
                        aria-label="find a coach"
                    >
                        BECOME A COACH
                    </a>
                    <Link to="/coachDirectory" className="heading-5">
                    FIND A COACH
                    </Link>
                    {/* <a
                        className="heading-5"
                        href="https://directory.oolalifecoach.com"
                        aria-label="find a coach"
                    >
                        FIND A COACH
                    </a> */}
                    <a
                        className="heading-5"
                        href=" https://oolalifecoaching.com"
                        aria-label="coach log in"
                    >
                        COACH LOG IN
                    </a>
                </div>
            </div>

            <div className="linksFooter__container">
                <div className="linksFooter__title">
                    <p className="paragraph-3">
                        More <span className="paragraph-3">Information</span>
                    </p>
                </div>
                <div className="linksFooter__links">
                    <a
                        className="heading-5"
                        href="https://shop.oolalife.com/"
                        aria-label="Shop"
                    >
                        MERCH
                    </a>
                    <a
                        className="heading-5"
                        href="https://www.oolalifestore.com/blogs/blog"
                        aria-label="blog"
                    >
                        BLOG
                    </a>
                    <Link to="/learnmore/faq" className="heading-5">
                        FAQ
                    </Link>
                    <Link
                        className="heading-5"
                        to="/contact"
                        aria-label="contact"
                    >
                        CONTACT
                    </Link>
                </div>
            </div>

            <div className="copy__container">
                <p className="paragraph-3">Copyright Oola 2022</p>
            </div>
        </div>
        <div className="footer__terms">
            <Link to="/termsOfUse" className="paragraph-3">
                Terms of Use
            </Link>
            <Link to="/privacyPolicy" className="paragraph-3">
                Privacy Policy
            </Link>
        </div>
    </footer>
);

export default FooterSection;
