import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import * as SignalR from '@microsoft/signalr';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import './OolaButton.css';
import configData from '../../../config.json';

const circlePath = `M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831`;

const Wheel = ({ setShowModal, AllState, setShowModalFromButton, saving }) => {
    const { checkUserSession } = useContext(AppContext);
    const { token } = checkUserSession();
    const [stepsTaken, setStepsTaken] = useState(0);
    const [rButton, changerButton] = useState(51);

    useEffect(() => {
        const url = `${configData.SERVER_URL}Actions/GetGlobalActionStepsTaken`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                setStepsTaken(result.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [1]);

    useEffect(() => {
        const hubConnection = new SignalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(`${configData.SERVER_URL}actionhub`)
            .build();

        hubConnection
            .start()
            .then(() => {
                console.log('Connected!');
            })
            .catch((err) => {
                console.log(err);
            });

        hubConnection.on('GetGlobalStepsTaken', (steps) => {
            setStepsTaken(steps);
        });
    }, []);

    const indexDone = AllState.DailySteps;
    function clickButton() {
        setShowModalFromButton(true);
    }
    function clickButtonActionSteps() {
        setShowModal(true);
    }
    const props3 = useSpring({
        Stroke1: indexDone > 0 ? '31, 100' : '0,100',
        Stroke2: indexDone > 1 ? '32, 100' : '0,100',
        Stroke3: indexDone > 2 ? '33, 100' : '0,100',
    });

    function onMouseEnter() {
        changerButton(57);
    }
    function onMouseLeave() {
        changerButton(51);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <article className="oolaButton">
            <div className="oolaButton__svgButton">
                <svg
                    id="ChartPieChunk noselect"
                    viewBox="22 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g transform="scale(.9) translate(10 -10)">
                        <g transform="translate(43,24)">
                            <g
                                transform=""
                                style={{ transform: 'rotate(2deg) scale(5) ' }}
                            >
                                <animated.path
                                    className="circleInactive"
                                    strokeDasharray="31,100"
                                    d={circlePath}
                                    strokeWidth="100px"
                                />
                                <animated.path
                                    className="circle"
                                    strokeDasharray={props3.Stroke1}
                                    d={circlePath}
                                    strokeWidth="100px"
                                />
                            </g>
                        </g>
                        <g transform="translate(249,88)">
                            <g
                                transform=""
                                style={{
                                    transform: 'rotate(120deg) scale(5) ',
                                }}
                            >
                                <animated.path
                                    className="circleInactive"
                                    strokeDasharray="32,100"
                                    d={circlePath}
                                />
                                <animated.path
                                    className="circle"
                                    strokeDasharray={props3.Stroke2}
                                    d={circlePath}
                                />
                            </g>
                        </g>
                        <g transform="translate(90,240)">
                            <g
                                transform=""
                                style={{
                                    transform: 'rotate(240deg) scale(5) ',
                                }}
                            >
                                <animated.path
                                    className="circleInactive"
                                    strokeDasharray="33,100"
                                    d={circlePath}
                                />
                                <animated.path
                                    className="circle"
                                    strokeDasharray={props3.Stroke3}
                                    d={circlePath}
                                />
                            </g>
                        </g>
                    </g>
                    <circle
                        fill="transparent"
                        r="88"
                        cx="123"
                        cy="97"
                        stroke="white"
                        strokeWidth="4px"
                    />

                    <rect
                        x={115}
                        y={10}
                        height="29px"
                        width="15px"
                        fill="white"
                    />
                    <g style={{ transform: 'rotate(30deg)' }}>
                        <rect
                            x={213}
                            y={23}
                            height="15px"
                            width="29px"
                            fill="white"
                            style={{ transform: 'rotate(-3deg)' }}
                        />
                        <rect
                            x={140}
                            y={15}
                            height="29px"
                            width="15px"
                            fill="white"
                            style={{ transform: 'rotate(25deg)' }}
                        />
                    </g>
                    <g
                        transform="translate(-97,-122)"
                        className={saving ? 'disableButtonTracker ' : ''}
                    >
                        <circle
                            className="btnTracker shadow"
                            onClick={() => {
                                clickButton();
                            }}
                            cx="220"
                            cy="219.5"
                            fill=" #d86b59"
                            style={{ r: rButton }}
                            onMouseEnter={() => onMouseEnter()}
                            onMouseLeave={() => onMouseLeave()}
                        />
                        {indexDone === 0 ? (
                            <g className="newTextTracker">
                                <text
                                    className=" numberTextTacker noselect"
                                    x={188}
                                    y={208}
                                    fill="white"
                                    fontSize="18"
                                    strokeWidth="1px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        clickButton();
                                    }}
                                    onMouseEnter={() => onMouseEnter()}
                                    onMouseLeave={() => onMouseLeave()}
                                >
                                    PUSH HERE
                                </text>
                                <text
                                    className=" numberTextTacker noselect"
                                    x={196}
                                    y={228}
                                    fill="white"
                                    fontSize="9"
                                    strokeWidth="1px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        clickButton();
                                    }}
                                    onMouseEnter={() => onMouseEnter()}
                                    onMouseLeave={() => onMouseLeave()}
                                >
                                    TO MARK YOUR
                                </text>
                                <text
                                    className=" numberTextTacker noselect"
                                    x={193}
                                    y={241}
                                    fill="white"
                                    fontSize="9"
                                    strokeWidth="1px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        clickButton();
                                    }}
                                    onMouseEnter={() => onMouseEnter()}
                                    onMouseLeave={() => onMouseLeave()}
                                >
                                    ACTION STEPS AS
                                </text>
                                <text
                                    className=" numberTextTacker noselect"
                                    x={202}
                                    y={253}
                                    fill="white"
                                    fontSize="9"
                                    strokeWidth="1px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        clickButton();
                                    }}
                                    onMouseEnter={() => onMouseEnter()}
                                    onMouseLeave={() => onMouseLeave()}
                                >
                                    COMPLETE
                                </text>
                            </g>
                        ) : (
                            <text
                                className="titleQuestion numberTacker noselect"
                                x={indexDone < 10 ? 210 : 195}
                                y={236.5}
                                fill="white"
                                fontSize="60"
                                strokeWidth="1px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    clickButton();
                                }}
                                onMouseEnter={() => onMouseEnter()}
                                onMouseLeave={() => onMouseLeave()}
                            >
                                {indexDone}
                            </text>
                        )}
                    </g>
                </svg>
            </div>

            <div className="oolaButton__text">
                <h4 className="heading-4">{numberWithCommas(stepsTaken)}</h4>
                <p>
                    Action steps taken in the <br /> global community
                </p>
                {/* <button
                    type="button"
                    className="button-full-big"
                    onClick={() => clickButtonActionSteps(true)}
                >
                    DAILY ACTION STEPS
                </button> */}
            </div>
        </article>
    );
};
export default Wheel;
