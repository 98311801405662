export default {
    FsJourneySelect: 0,
    FsJour: [
        { id: 1, name: 'FITNESS', color: '#15c285' },
        { id: 2, name: 'FINANCE', color: '#abd86d' },
        { id: 3, name: 'FAMILY', color: '#fbbf53' },
        { id: 4, name: 'FIELD', color: '#f26636' },
        { id: 5, name: 'FAITH', color: '#ec0f47' },
        { id: 6, name: 'FRIENDS', color: '#700460' },
        { id: 7, name: 'FAITH', color: '#022b7a' },
    ],
    FsJourney: [
        {
            id: 0,
            name: (
                <section className="StartYourJourney__TitleContainer">
                    <h5 className="StartYourJourney__subtitle">
                        <span>LIVE</span> DIFFERENTLY.
                    </h5>
                </section>
            ),
            text: (
                <>
                    <b>
                        Living your Oola Life means working towards a life that
                        seeks balance and growth in the 7 key areas of health
                        and wellness.
                    </b>{' '}
                    Gain the energy to find joy in each and every day. Feel
                    secure in your finances and never fight about money again.
                    Do the work you love. Feel deep connections with friends and
                    family. Confidently find your purpose on this planet.{' '}
                    <b>That's Oola.</b>
                </>
            ),
            color: '#2c414c',
            svg:true
        },
        {
            id: 1,
            name: 'FITNESS',
            text:
                'How did our lives get so unhealthy? Obesity, diabetes and heart disease is on the rise. Stress, anxiety, depression and mental health disorders are climbing. And worst of all, our kids are suffering, too. But, it doesn’t have to be this way. Countless people have used the Oola to lose weight, feel better, look better and live better and you can too.',
            color: '#15c285',
        },
        {
            id: 2,
            name: 'FINANCE',
            text:
                'Paycheck to paycheck: that’s how 63% of households live. Nearly 7 in 10 Americans can’t handle a $1,000 crisis and debt has handcuffed the majority of people from reaching their dreams. Through the Oola Framework, you will learn proven tactics to become debt-free, earn extra income, save for emergencies, give more generously and put together a solid financial plan for your future.',
            color: '#abd86d',
        },
        {
            id: 3,
            name: 'FAMILY',
            text:
                '53% of marriages end in divorce. Toxic relatives, unhealthy boundaries, and even addiction is putting a heavy strain on our families. And regarding our kids, peer pressure seems to get there first. Today’s families feel less connected and more discouraged.  Oola will teach you how to grow and build the family life that you’ve always wanted no matter where you are today.',
            color: '#fbbf53',
        },
        {
            id: 4,
            name: 'FIELD',
            text:
                'Spending a third of our day at a job we hate . . . building someone else’s future instead of our own . . . missing out on what we’re truly passionate about. It’s no surprise that 70% of Americans are disillusioned with their careers. Whether you want to be a well-balanced stay at home parent, a massive entrepreneur, or both, we have the process to help you build your perfect career path.',
            color: '#f26636',
        },
        {
            id: 5,
            name: 'FAITH',
            text:
                'There’s a unique calling on your life. And while Oola will never tell you what to believe, one thing is true: you can’t have a fulfilling life without a higher purpose that only faith will reveal. Like many others, you can use the Oola Framework as a stepping stone to grow your faith walk no matter where you are today; finding peace and assurance along your journey.',
            color: '#ec0f47',
        },
        {
            id: 6,
            name: 'FRIENDS',
            text:
                'It’s crazy we know, but all too often, friendships lead to stress. In fact, 80% of people say they endure toxic friendships . . . but why? Mean girls, jealous coworkers, online bullying, office politics . . . those aren’t friends and that’s not Oola. A life of less drama, deep connections and lasting good memories with your friends is a click away.',
            color: '#700460',
        },
        {
            id: 7,
            name: 'FUN',
            text:
                'In a culture infatuated with “busy,” what used to bring us happiness somehow got replaced with deadlines, stress and overwhelm. Good times get kicked down the road so often that we often lose sight of what brings us joy. This it’s what is all about at the end…the fun you had and the memories you created with the people you love. It’s time to live your best life.',
            color: '#022b7a',
        },
    ],
};
