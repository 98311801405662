import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../ArrangeYourGoal.css'

const SelectedOneGoal = (props) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const SelectedOneGoal =
        <div className="GoalArrange" style={{ backgroundColor: props.Fs.color, marginLeft: "0px", marginRight: "0px", marginTop: "10px" }}>
            <div className="GoalNumberArrange" xs={1} style={{ height: "100%" }}>
                {props.indexF + 1}
            </div>
            <div className="GoalArrangeCol" >
                <div className={`GoalSelectedArrange`} placeholder="Define your  goal">{props.itemF.text}</div>
                <div className="GoalArrangeCol2" >
                    <div style={{ height: "100%" }}>
                        <DatePicker
                            dateFormat="MMMM dd, yyyy"
                            // onChange={date => setStartDate(date)}
                            withPortal 
                            className="datePickerGoal7"
                            placeholderText="Complete by: mm/dd/yyyy"
                            minDate={new Date()}
                            selected={props.itemF.date}
                            readOnly
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                            }) => (
                                <div>
                                    <div
                                        className="calendarHeader"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}>
                                        COMPLETE BY:
                                </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            paddingTop: "10px"
                                        }}
                                    >
                                        <button className="btnCalendar" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                            {"<"}
                                        </button>
                                        <div className="lblMonth">
                                            {months[new Date(date).getMonth()]}  {new Date(date).getFullYear()}
                                        </div>
                                        <button className="btnCalendar" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                            {">"}
                                        </button>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className="GoalArrangeIcon" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="40" viewBox="0 0 22 48">
                            <defs>
                                <style>
                                    {".cls-1{fill:#fff}}"}
                                </style>
                            </defs>
                            <g id="Group_209" transform="translate(-1587 -541)">
                                <circle id="Ellipse_132" cx="3" cy="3" r="3" className="cls-1" transform="translate(1587 541)" />
                                <circle id="Ellipse_132-2" cx="3" cy="3" r="3" className="cls-1" transform="translate(1603 541)" />
                                <circle id="Ellipse_132-3" cx="3" cy="3" r="3" className="cls-1" transform="translate(1587 562)" />
                                <circle id="Ellipse_132-4" cx="3" cy="3" r="3" className="cls-1" transform="translate(1603 562)" />
                                <circle id="Ellipse_132-5" cx="3" cy="3" r="3" className="cls-1" transform="translate(1587 583)" />
                                <circle id="Ellipse_132-6" cx="3" cy="3" r="3" className="cls-1" transform="translate(1603 583)" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    return SelectedOneGoal;
}
export default SelectedOneGoal;
