import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import configData from '../../../../config.json';
import AppContext from '../../../../Context/AppContext';
import DeleteWhiteIcon from '../../../../Assets/img/DeleteWhiteIcon.svg';
import ModalEditGoal from '../../../Dashboard/MyGoals/Modal/ModalEditGoal';
import ModalAddGoalStep6 from '../../../Dashboard/MyGoals/Modal/ModalAddGoalStep6';
import ModalOolaReviewReviseReaffirm from './ModalOolaReviewReviseReaffirm';
import ModalRecomendation from '../TemplateGoals/ModalRecomendation';
import ModalError from '../TemplateGoals/ModalError';

const OolaReviewReviseReaffirmGoal = props => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const [show, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalRecomendation, setShowModalRecomendation] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [currentGoalx, setCurrentGoal] = useState({});
    const [showError, setShowError] = useState(false);
    const [errorObj, setErrorObj] = useState({
        ModalErrorTitle: 'You are almost there',
        ModalErrorDesc: 'You need to add 3 goals to continue',
    });
    const currentF = props.AllState.SelectedGoals.filter(
        x => x.areaId == props.FInfo.areaId - 1
    );
    const currentGoal =
        currentF.length === 0
            ? {}
            : !currentF[props.indexF]
            ? {}
            : currentF[props.indexF];
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const setStartDate = e => {
        currentGoal.date = e;
        editGoal.completedByDate = e;
        setCurrentGoal(goal);
        return true;
    };

    function setGoal(goal) {
        goal.date = goal.completedByDate;
        setCurrentGoal(goal);
    }
    function openModalRecomendation() {
        let newProps = { ...props.AllState };
        newProps.CurrentF = props.FInfo.areaId - 1;
        props.AllState.changeData(newProps);
        setShowModalAdd(true);
    }
    function SaveGoalsChange(currentGoal) {
        changeLoading(true);
        const currUser = checkUserSession();
        const url = `${configData.SERVER_URL}Goals/UpdateDashboardGoal`;
        const PostObj = {
            userId: currUser.userId,
            id: currentGoal.GoalId,
            text: currentGoal.text,
            completedByDate: currentGoal.date,
        };

        axios({
            method: 'put',
            url,
            headers: { Authorization: `Bearer ${currUser.token}` },
            data: PostObj,
        })
            .then(res => {
                setShowModalEdit(false);
                changeLoading(false);
            })
            .catch(err => {
                console.log(err);
                alert(err);
                changeLoading(false);
            });
        setShowModal(false);
    }
    function RemoveGoal() {
        const currUser = checkUserSession();

        const url = `${configData.SERVER_URL}Goals/${currentGoal.GoalId}`;
        axios
            .delete(url, {
                headers: { Authorization: `Bearer ${currUser.token}` },
            })
            .then(() => {
                let newObj = { ...props.AllState };
                newObj.SelectedGoals = newObj.SelectedGoals.filter(
                    x => x.GoalId != currentGoal.GoalId
                );
                props.AllState.changeData(newObj);
                setShowConfirm(false);
            })
            .catch(error => {
                console.log(error);
                props.AllState.ActionIdComplete = 0;
            });
    }

    function AddGoal(item) {
        const currentUser = checkUserSession();
        if (item.date === null) {
            setErrorObj({
                ModalErrorTitle: 'You are almost there!',
                ModalErrorDesc: 'Select a date.',
            });
            setShowError(true);
            return false;
        } else {
            changeLoading(true);
            const url = `${configData.SERVER_URL}Goals/AddDashboardGoal`;
            const PostObj = {
                userId: currentUser.userId,
                int: '1',
                areaId: props.FInfo.areaId,
                text: item.text,
                completedByDate: item.date,
            };

            axios({
                method: 'post',
                url,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: PostObj,
            })
                .then(res => {
                    setShowModalAdd(false);
                    setShowModalRecomendation(false);
                    changeLoading(false);
                    props.getData();
                })
                .catch(err => {
                    console.log(err);
                    changeLoading(false);
                    alert(err);
                });
        }
    }
    let OolaReviewReviseReaffirmGoalR = (
        <>
            <div
                className={`GoalLevel2  OolaReviewReviseReaffirmGoals_ItemContainerAll ${
                    currentGoal.text
                        ? 'OolaReviewReviseReaffirmGoals_ItemContainer'
                        : 'OolaReviewReviseReaffirmGoals_ItemContainerEmpty'
                }`}
                style={{
                    backgroundColor: props.FInfo.color,
                    marginLeft: '0px',
                    marginRight: '0px',
                    width: '100%',
                    marginTop: '3px',
                }}
            >
                {!currentGoal.text ? (
                    <div
                        className="OolaReviewReviseReaffirmGoals_AddButton"
                        onClick={() => openModalRecomendation()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <g>
                                <path
                                    fill="#2c414c"
                                    d="M16.184-6.836v-1.955h-6.008v-6.049H8.165v6.049H2.184v1.955h5.981v6h2.012v-6z"
                                    transform="translate(-721 -559) translate(723.816 578.84)"
                                />
                                <g
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="2px"
                                    transform="translate(-721 -559) translate(721 559)"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        stroke="none"
                                    />
                                    <circle cx="12" cy="12" r="11" />
                                </g>
                            </g>
                        </svg>
                    </div>
                ) : (
                    <>
                        <div
                            className={`${
                                !currentGoal.text
                                    ? 'GoalNumberTop7'
                                    : 'GoalNumberSelectedTop7'
                            }`}
                            style={{
                                height: '100%',
                                paddingTop: '1.4rem',
                                alignItems: 'flex-start',
                                fontWeight: 'bold',
                            }}
                        >
                            {props.indexF + 1}
                        </div>
                        <div>
                            <p className="OolaReviewReviseReaffirmGoals_ItemText">
                                {currentGoal.text}
                            </p>
                        </div>
                        <div
                            style={{
                                height: '100%',
                                paddingTop: ' calc(.8vw)',
                            }}
                        >
                            <DatePicker
                                dateFormat="MMMM dd, yyyy"
                                onChange={date => setStartDate(date)}
                                withPortal
                                className={`datePickerGoalStep6 ${
                                    currentGoal.text === null
                                        ? 'NoPickedGoalTop'
                                        : ''
                                }`}
                                placeholderText="Complete by: mm/dd/yyyy"
                                minDate={new Date()}
                                selected={currentGoal.date}
                                readOnly
                                renderCustomHeader={({
                                    date,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div>
                                        <div
                                            className="calendarHeaderTop7"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            COMPLETE BY:
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btnCalendar"
                                                onClick={decreaseMonth}
                                                disabled={
                                                    prevMonthButtonDisabled
                                                }
                                            >
                                                {'<'}
                                            </button>
                                            <div
                                                className="lblMonth"
                                                style={{ fontSize: '2rem' }}
                                            >
                                                <input
                                                    readOnly
                                                    className={`${
                                                        currentGoal.selected ===
                                                        false
                                                            ? 'GoalNoSelected7'
                                                            : 'GoalSelected7'
                                                    }`}
                                                    placeholder="Define your  goal"
                                                    value={
                                                        months[
                                                            new Date(
                                                                date
                                                            ).getMonth()
                                                        ]
                                                    }
                                                    style={{ fontSize: '2rem' }}
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btnCalendar"
                                                onClick={increaseMonth}
                                                disabled={
                                                    nextMonthButtonDisabled
                                                }
                                            >
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            style={{
                                height: '100%',
                                paddingTop: ' calc(.8vw)',
                            }}
                        >
                            <i
                                className="fa fa-pencil ReviewRevisteReaffirm__PencilIcon"
                                aria-hidden="true"
                                onClick={() => setShowModalEdit(true)}
                            />
                            <figure
                                onClick={() => {
                                    setShowConfirm(true);
                                }}
                            >
                                <img
                                    className="ReviewRevisteReaffirm__DeleteIcon"
                                    src={DeleteWhiteIcon}
                                    alt="delete"
                                />
                            </figure>
                        </div>
                    </>
                )}
            </div>
            {showModalAdd && (
                <ModalAddGoalStep6
                    show={showModalAdd}
                    setShow={setShowModalAdd}
                    setShowModalRecomendation={setShowModalRecomendation}
                    currItem={props.FInfo}
                    AllState={props.AllState}
                    setShowError={setShowError}
                    setErrorObj={setErrorObj}
                    AddRecomendation={AddGoal}
                />
            )}
            <ModalRecomendation
                show={showModalRecomendation}
                setShow={setShowModalRecomendation}
                currItem={props.FInfo}
                AllState={props.AllState}
                setShowError={setShowError}
                setErrorObj={setErrorObj}
                AddRecomendation={AddGoal}
            />
            <ModalOolaReviewReviseReaffirm
                setShow={setShowConfirm}
                Show={showConfirm}
                ModalConfirmTitle={
                    <div style={{ textAlign: 'center' }}>
                        ARE YOU SURE YOU WANT <br />
                        TO DELETE THIS GOAL?
                    </div>
                }
                ModalConfirmDesc=""
                ConfirmEvent={RemoveGoal}
                ConfirmText="YES! CONTINUE"
                CancelText="NO, TAKE ME BACK"
            />
            <ModalEditGoal
                setGoal={setGoal}
                setShow={setShowModalEdit}
                show={showModalEdit}
                FInfo={props.FInfo}
                currentGoal={currentGoal}
                AllState={props.AllState}
                SaveGoalsChange={SaveGoalsChange}
            />
            <ModalError
                show={showError}
                setShowError={setShowError}
                ModalErrorTitle={errorObj.ModalErrorTitle}
                ModalErrorDesc={errorObj.ModalErrorDesc}
            />
        </>
    );

    return OolaReviewReviseReaffirmGoalR;
};
export default OolaReviewReviseReaffirmGoal;
