import React from 'react';
import ListFs from '../ListFs/ListFs';
import './Fnav.css';

export default ({ state }) => {
    return (
        <ul className={`elementSideFs FreeTest elementSideFs${state?.index + 1}`}>
            {state?.Fs.map((item, i) => (
                <ListFs item={item} i={i} key={'FsFree' + item.id} index={state.index}   lastF={state.lastF}/>
            ))}
        </ul>
    );
};
