import { PinDropSharp } from '@material-ui/icons';
import React from 'react';
import Oola_lifestyle_framework_image from '../../../../Assets/img/Dashboard/Free_Balance_Cover.jpg';
import { useParams } from 'react-router-dom';
import './FreeTest2.css';

const Programs = ({ goFreeTest }) => {
    let { webAlias } = useParams();

    return (
        <div className="HomeFreeTest__Container">
            <div className="HomeFreeTest__imgContainer">
                <div className="HomeFreeTest__Desc Mobile">
                    <h1>
                        <span>FREE</span> LIFE BALANCE <br />
                        ASSESSMENT
                        <hr />
                    </h1>
                    <p>
                        Take the first step towards creating the life of your
                        dreams with our free two-minute assessment. Discover
                        what products and programs are perfect for you.
                    </p>
                    <h5>FIND A BALANCED LIFE TODAY</h5>
                    <button
                        className="button-full-mid"
                        onClick={() =>
                            (window.location.href =
                                '/FreeTest' + (webAlias ? '/' + webAlias : ''))
                        }
                    >
                        START ASSESSMENT
                    </button>
                </div>
                <div className="HomeFreeTest__img">
                    <img src={Oola_lifestyle_framework_image} />
                </div>
                <div className="HomeFreeTest__Desc Desktop">
                    <h1>
                        <span>FREE</span> LIFE BALANCE <br />
                        ASSESSMENT
                        <hr />
                    </h1>
                    <p>
                        Take the first step towards creating the life of your
                        dreams with our free two-minute assessment. Discover
                        what products and programs are perfect for you.
                    </p>
                    <h5>FIND A BALANCED LIFE TODAY</h5> 
                    <button
                        className="button-full-mid"
                        onClick={() => goFreeTest()}
                    >
                        START ASSESSMENT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Programs;
