import React, { useEffect, useContext, useState } from 'react';
import HeaderDashboard from '../Dashboard/Header/HeaderDashboard';
import Main from './Main/Main';
import requestAxios from '../../util/requestAxios';
import AppContext from '../../Context/AppContext';
import CoachProfileFinal from './CoachProfileFinal/CoachProfileFinal';
import CoachEditProfileModal from './CoachEditProfile/CoachEditProfileModal';
import { CoachingRoomModalCoach } from './CoachingRoom/Components';
import ModalBadget from '../Dashboard/UserInfo/Modals/ModalBadget';

export default () => {
    const { checkUserSession, setNewData, getData } = useContext(AppContext);
    const [currBadget, currBadgetChange] = useState(null);
    const { ProfileCoach } = getData();
    const { userId, token } = checkUserSession();

    useEffect(() => {
        getProfileCoachInfo();
        getMyAchievements();
    }, []);

    const getProfileCoachInfo = async () => {
        const url = `Coach/GetCoachByUserId/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token: token,
        });

        if (error == '') {
            setNewData({ ProfileCoach: data });
        } else {
            window.location.replace = './Dashboard';
        }
    };

    async function getMyAchievements() {
        const url = `Coach/GetMyAchievements/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
        } else {
            const bagdet = data.filter(
                (x) => x.achieved === true && x.displayed === false
            );
            if (bagdet.length > 0) {
                bagdet[0].showModal = true;
                currBadgetChange(bagdet[0]);
            }
        }
    }

    async function hideModalBadget(badgetInfo) {
        const url = `Coach/UpdateDisplayedBadge`;
        const objPost = {
            userid: userId,
            achievementid: badgetInfo.id,
        };
        const { error, data } = await requestAxios({
            url: url,
            token,
            method:"put",
            data:objPost
        });
        if (error) {
            console.log(error);
        } else {
            currBadgetChange(null);
            getMyAchievements();
            getProfileCoachInfo()
        }
         
    }
    return (
        <div className="DashboardCoach__container ">
            <HeaderDashboard />
            <Main getProfileCoachInfo={getProfileCoachInfo} />
            {ProfileCoach?.modalDirectiryListingShow && <CoachProfileFinal />}
            {ProfileCoach?.modalEditProfileShow && <CoachEditProfileModal />}
            {ProfileCoach?.showModalCoachingRoomCoach && (
                <CoachingRoomModalCoach />
            )}
            {currBadget !== null && (
                <ModalBadget
                    currBadget={currBadget}
                    hideModalBadget={hideModalBadget}
                    setDashboardTutorial={null}
                />
            )}
        </div>
    );
};
