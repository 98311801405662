import { useContext, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import './MyStats.css';
export default () => {
    const [statsInfo, setstatsInfo] = useState({});
    const { checkUserSession } = useContext(AppContext);
    useEffect(() => {
        getInfo();
    }, []);
    async function getInfo() {
        const { token, userId } = checkUserSession();
        let url = `Coach/GetMyStats/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token: token,
        });
        if (error) {
            console.log(error);
        } else {
            setstatsInfo(data);
        }
    }
    return (
        <div className="coachDashboardMyStats">
            <h1>
                MY STATS
                <hr />
            </h1>
            <div className="coachDashboardMyStatsValues__container">
                <div className="coachDashboardMyStatsValuesItem__container">
                    <h1>
                        <CountUp
                            className="countContainer__counter"
                            end={
                                statsInfo.activeClients
                                    ? statsInfo.activeClients
                                    : 0
                            }
                            duration={4}
                        />
                    </h1>
                    <h2>
                        ACTIVE <br />
                        CLIENTS
                    </h2>
                </div>
                <div className="coachDashboardMyStatsValuesItem__container">
                    <h1>
                        <CountUp
                            className="countContainer__counter"
                            end={
                                statsInfo.lifeTimeClients
                                    ? statsInfo.lifeTimeClients
                                    : 0
                            }
                            duration={4}
                        />
                    </h1>
                    <h2>
                        LIFETIME <br />
                        CLIENTS
                    </h2>
                </div>
                <div className="coachDashboardMyStatsValuesItem__container">
                    <h1>
                        {' '}
                        <CountUp
                            className="countContainer__counter"
                            end={
                                statsInfo.clientActionSteps
                                    ? statsInfo.clientActionSteps
                                    : 0
                            }
                            duration={4}
                        />
                    </h1>
                    <h2>
                        CLIENTS <br />
                        ACTIONS STEPS
                    </h2>
                </div>
                <div className="coachDashboardMyStatsValuesItem__container">
                    <h1>
                        {' '}
                        <CountUp
                            className="countContainer__counter"
                            end={
                                statsInfo.clientGoalsAchieved
                                    ? statsInfo.clientGoalsAchieved
                                    : 0
                            }
                            duration={4}
                        />
                    </h1>
                    <h2>
                        CLIENT <br />
                        GOALS ACHIEVED
                    </h2>
                </div>
            </div>
            <h2>Copyright Oola 2023</h2>
        </div>
    );
};
