import React, { useState, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import My_goals_blur from '../../../Assets/img/Dashboard/My_goals_blur.jpg';
import LockSVG from '../../../Assets/img/LockIconFullWhite.svg';
import AppContext from '../../../Context/AppContext';
import './MyGoals.css';
const MyGoals = () => {
    const [showPopover, setShowPopover] = useState(false);
    const { changeModalWarningUpgrade } = useContext(AppContext);

    const popover = (
        <Popover className="MyGoalsPoper__container">
            <Popover.Content
                className="MyProgressPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span
                            onClick={() => {
                                changeModalWarningUpgrade(true);
                            }}
                        >
                            {' '}
                            Oola Lifestyle Framework
                        </span>{' '}
                        to unlock this feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            placement="top"
            overlay={popover}
            show={showPopover}
            rootClose
            delay={{ show: 0, hide: 5000 }}
        >
            <article
                className="myGoals"
                onMouseEnter={() => {
                    setShowPopover(true);
                }}
                onMouseLeave={() => {
                    setShowPopover(false);
                }}
            >
                <div className="TitleDashboardContent ">
                    <p className="heading-5">
                        MY GOALS
                        <img
                            className="imgLockMyGoals"
                            alt="imgLock"
                            src={LockSVG}
                        />
                    </p>
                </div>
                <div className="myGoals__container ">
                    <ul className="myGoals__menu TitleMyGoal">
                        <li>
                            <button
                                className={` firstTabMyGoal ${'activeTab'}`}
                                type="button"
                            >
                                MY TOP 7
                            </button>
                        </li>
                        <li>
                            <button type="button">ACHIEVED</button>
                        </li>
                        <li>
                            <button className={'pending'} type="button">
                                ADDITIONAL
                            </button>
                        </li>
                    </ul>
                    <div className="Mygoalsblur__container OolaScroll">
                        <img src={My_goals_blur} alt="My_goals_blur" />
                    </div>
                </div>
            </article>
        </OverlayTrigger>
    );
};

export default MyGoals;
