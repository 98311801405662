import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import './CollapsableSection.css';

import arrowIcon from '../../../../../../../../Assets/icons/ArrowNoCircleDark.svg';

export default ({ moduleItem }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    function goELearning(item) {
        localStorage.setItem('ModuleId', item.moduleId);
        localStorage.setItem('isCertificationModule', 1);
        history.push('/ELearning');
    }

    const moduleStatus = (status) => {
        if (status === 0) return 'locked';
        if (status === 1) return 'active';
        if (status === 2) return 'completed';

        return '';
    };

    return (
        <article className="collapsableSection__container">
            <button onClick={() => setOpen(!open)} type="button">
                <img
                    src={arrowIcon}
                    alt="arrow icon"
                    className={open && 'openModule'}
                />
                <p style={{ textAlign: 'left' }}>
                    <strong>{moduleItem.title}:</strong> {moduleItem.subtitle}
                </p>
            </button>
            <Collapse in={open}>
                <div className="collapsableSection__content">
                    {moduleItem?.modules.map((module) => (
                        <div className="collapsableSection__content-module" key={module.moduleNumber}>
                            <div className={moduleStatus(module.status)} />
                            <button
                                type="button"
                                className="collapsableSection__content-module-button"
                                disabled={
                                    moduleStatus(module.status) === 'locked'
                                }
                                onClick={() => goELearning(module)}
                            >
                                {module.moduleNumber}: {module.title}
                            </button>
                        </div>
                    ))}
                </div>
            </Collapse>
            <p className="collapsableSection__percentage">
                {moduleItem.progress}% complete
            </p>
        </article>
    );
};
