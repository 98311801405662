import React, { useContext, useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import iconUser from '../../../Assets/img/userIcon.jpg';
import DefaultPicture from '../../../Assets/img/DashboardCoach/Bus.png';
import requestAxios from '../../../util/requestAxios';
import './CoachProfileFinal.css';
import ImageIcon from '../../Dashboard/UserInfo/Modals/ImageIcon';

export default ({ userId, publicUser }) => {
    const [imageHash, imageHashChange] = useState(Date.now());
    const { checkUserSession, setNewData, changeLoading, getData } =
        useContext(AppContext);
    const { ProfileCoach } = getData();
    const [profileCoachFinal, setProfileCoachFinal] = useState({});
    useEffect(() => {
        getDataCoach();
    }, []);
    const getDataCoach = async () => {
        changeLoading(true);
        const us = checkUserSession();
        let url;
        if (publicUser)
            url = `Coach/GetDirectoryListingInfoPublic/${ProfileCoach?.selectedCoach}`;
        else url = `Coach/GetDirectoryListingInfo/${userId || us.userId}`;

        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            setProfileCoachFinal(data);
            changeLoading(false);
        }
    };
    const goSite = (base, user) => {
        window.open(`${base}${user}`);
    };
    const tooltip = (
        <Tooltip id="tooltip">{` ${profileCoachFinal?.emailAddress}`}</Tooltip>
    );
    const coachDetailC = (position) => (
        <div className={`CoachProfileDetails ${position}`}>
            <h3>
                <span>Location:</span>
                {` ${profileCoachFinal?.location}`}
            </h3>

            {ProfileCoach?.selectedCoach ? (
                <button
                    className="button-full-mid"
                    type="button"
                    onClick={() => {
                        if (profileCoachFinal?.calendlyUserName)
                            window.open(
                                profileCoachFinal?.calendlyUserName,
                                '_blank'
                            );
                        else {
                            window.alert(
                                'Sorry, this coach has no schedule available'
                            );
                        }
                    }}
                >
                    Free Discovery Call
                </button>
            ) : (
                <>
                    <OverlayTrigger placement="left" overlay={tooltip}>
                        <h3 className="email">
                            <span>Email:</span>
                            {` ${profileCoachFinal?.emailAddress}`}
                        </h3>
                    </OverlayTrigger>

                    <h3>
                        <span>Phone:</span>{' '}
                        <InputMask
                            className="inputNumber "
                            mask="999-999-9999"
                            value={profileCoachFinal?.phoneNumber}
                            disabled
                        />
                    </h3>
                </>
            )}
        </div>
    );
    const closeModal = () => {
        setNewData({
            ProfileCoach: {
                modalDirectiryListingShow: false,
                selectedCoach: null,
            },
            FindCoach: { showModal: false },
        });
        if (userId > 0) {
            window.location.reload();
        }
    };
    return (
        <Modal
            show={ProfileCoach?.modalDirectiryListingShow}
            onHide={() => closeModal()}
            dialogClassName="modalCoachProfileModal-90w"
        >
            <Modal.Body className="CoachProfileFinalModal OolaScroll">
                <div
                    className="CoachProfileFinalCover__container"
                    style={{
                        backgroundImage: `url(${
                            profileCoachFinal?.coverFileName != '' &&
                            profileCoachFinal?.coverFileName != null
                                ? `${configData.SERVER_URL_IMG}/media/profile/${profileCoachFinal?.coverFileName}?${imageHash}`
                                : DefaultPicture
                        })`,
                    }}
                >
                    <button
                        className="button-full-mid"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        CLOSE
                    </button>
                </div>
                <div className="CoachProfileFinal__container ">
                    <article className="userInfo ">
                        <div className="userInfo__image CoachProfileFinal">
                            <div
                                alt="Profile"
                                className="image__profile CoachProfileFinal"
                                style={{
                                    '--imgProfileUrl': `url(${
                                        ProfileCoach
                                            ? `${configData.SERVER_URL_IMG}/media/profile/${profileCoachFinal?.profilePictureFileName}?${imageHash}`
                                            : iconUser
                                    })`,
                                }}
                            />
                        </div>
                        <div className="userInfo__info coach CoachProfileFinalModal ">
                            <h5 className="heading-5 WhiteText ">
                                {profileCoachFinal?.customerType}
                            </h5>
                            <div className="info__name CoachProfileFinalModal">
                                <h1 className="heading-1 WhiteText">
                                    {profileCoachFinal?.firstName}{' '}
                                    {profileCoachFinal?.lastName}
                                </h1>
                                <br />
                            </div>
                            <h5 className=" WhiteText CertifiedCoach">
                                Oola Life Coach
                            </h5>
                        </div>
                        {coachDetailC('desktop')}
                    </article>
                </div>
                <div className="CoachProfileFinalAboutCoach__container ">
                    <h1>{profileCoachFinal?.listingPageHeader}</h1>
                    <hr />
                    <h2>
                        <span>With me as your coach, you will</span>{' '}
                        {profileCoachFinal?.myOffer}
                    </h2>
                    <h2>
                        <span>About me: </span>
                        {profileCoachFinal?.aboutMe}
                    </h2>
                    {coachDetailC('mobile')}
                </div>
                <div className="CoachProfileFinalBadgets_container ">
                    {profileCoachFinal.badges?.length > 0 &&
                        profileCoachFinal.badges.map((item, index) => (
                            <div
                                key={`CoachProfileFinalBadgetsItem_container${item.id}${index}`}
                                className="CoachProfileFinalBadgetsItem_container"
                            >
                                <ImageIcon
                                    index={index}
                                    name={item.id}
                                    type="achievements"
                                    value={0}
                                    item={item}
                                />
                                <h1>{item.achievementName}</h1>
                            </div>
                        ))}
                </div>
                <div className="CoachProfileFinalClientStories_container ">
                    <h1>
                        CLIENT <span>SUCCESS</span> STORY
                    </h1>
                    <h2>
                        <span>"{profileCoachFinal.testimonial?.text}"</span>
                        <br />
                        {profileCoachFinal?.testimonial?.fullName} |{' '}
                        {profileCoachFinal?.testimonial?.location}
                    </h2>
                    <div className="CoachProfileFinalSocialMedia_container ">
                        <div className="CoachProfileFinalSocialMediaButton_container ">
                            {/* {profileCoachFinal.calendlyUrl && (
                                <button
                                    type="button"
                                    className="fa fa-calendar"
                                    onClick={() =>
                                        goSite(
                                            'https://calendly.com/',
                                            profileCoachFinal.calendlyUrl
                                        )
                                    }
                                />
                            )} */}
                            {profileCoachFinal.facebookUserName != '' && (
                                <button
                                    type="button"
                                    className="fa fa-facebook"
                                    onClick={() =>
                                        goSite(
                                            'https://fb.com/',
                                            profileCoachFinal.facebookUserName
                                        )
                                    }
                                />
                            )}
                            {profileCoachFinal.instagramUserName != '' && (
                                <button
                                    type="button"
                                    className="fa fa-instagram"
                                    onClick={() =>
                                        goSite(
                                            'https://instagram.com/',
                                            profileCoachFinal.instagramUserName
                                        )
                                    }
                                />
                            )}
                            {profileCoachFinal.linkedInUserName != '' && (
                                <button
                                    type="button"
                                    className="fa fa-linkedin"
                                    onClick={() =>
                                        goSite(
                                            'https://www.linkedin.com/in/',
                                            profileCoachFinal.linkedInUserName
                                        )
                                    }
                                />
                            )}
                            {profileCoachFinal.twitterUserName != '' && (
                                <button
                                    type="button"
                                    className="fa fa-twitter"
                                    onClick={() =>
                                        goSite(
                                            'https://twitter.com/',
                                            profileCoachFinal.twitterUserName
                                        )
                                    }
                                />
                            )}
                            {profileCoachFinal.tikTokUserName != '' && (
                                <button
                                    type="button"
                                    className="tikTokbutton"
                                    onClick={() =>
                                        goSite(
                                            'https://www.tiktok.com/@',
                                            profileCoachFinal.tikTokUserName
                                        )
                                    }
                                />
                            )}
                            {profileCoachFinal.websiteUrl != '' && (
                                <button
                                    type="button"
                                    className="wwwbutton"
                                    onClick={() =>
                                        goSite('', profileCoachFinal.websiteUrl)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
