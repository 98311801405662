import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useWindowSize } from '@react-hook/window-size';
import AppContext from '../../Context/AppContext';
import StepHeader from '../Step1/StepHeader';
import EstablishActionSection from './EstablishAction/EstablishActionSection';
import FinishActions from './EstablishAction/FinishActions';
import configData from '../../config.json';

const SelectGoalsStep2 = (props) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [width, height] = useWindowSize();

    const [data, changeData] = useState({
        CurrentF: 0,
        CurrentStep3: 0,
        Action: [{ text: '' }, { text: '' }, { text: '' }],
        My7: [
            {
                areaId: 1,
                name: 'Fitness',
                score: 0,
                color: '#15C285',
                recommendedGoals: [],
            },
            {
                areaId: 2,
                name: 'Finance',
                score: 0,
                color: '#ABD86D',
                recommendedGoals: [],
            },
            {
                areaId: 3,
                name: 'Family',
                score: 0,
                color: '#FBBF53',
                recommendedGoals: [],
            },
            {
                areaId: 4,
                name: 'Faith',
                score: 0,
                color: '#F26636',
                recommendedGoals: [],
            },
            {
                areaId: 5,
                name: 'Field',
                score: 0,
                color: '#EC0F47',
                recommendedGoals: [],
            },
            {
                areaId: 6,
                name: 'Friends',
                score: 0,
                color: '#700460',
                recommendedGoals: [],
            },
            {
                areaId: 7,
                name: 'Fun',
                score: 0,
                color: '#022B7A',
                recommendedGoals: [],
            },
        ],
    });

    function updateIndexStep3(i) {
        const AllProps = { ...data };

        AllProps.CurrentStep3 += i;
        changeData(AllProps);
    }
    function getURL() {
        return configData.SERVER_URL;
    }
    function changePage() {
        updateIndexStep3(1);
    }
    useEffect(() => {
        props.handleChangeHeader(1);
        const url = `${getURL()}goals/GetUserTopSevenGoals/${userId}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                const AllProps = { ...data };
                const My7 = [];
                result.data.topGoals.forEach((item) => {
                    item.completedByDate = new Date(item.completedByDate);
                    My7.push(item);
                });
                AllProps.My7 = My7;
                AllProps.changeData = changeData;
                AllProps.Action = [{ text: '' }, { text: '' }, { text: '' }];
                result.data.actions.map((item, i) => {
                    if (AllProps.Action[i] == null) {
                        AllProps.Action.push(item);
                    } else {
                        AllProps.Action[i].text = item.text;
                    }
                });
                changeData(AllProps);
                // if (result.data.actions.length > 2) {
                //     updateIndexStep3(2);
                // }
            })
            .catch((error) => {
                const AllProps = { ...data };
                const result = {};
                result.data = [
                    {
                        id: 125,
                        color: '#F16636',
                        order: 1,
                        text:
                            'Read a faith based book to discover what faith walk I would like for myself',
                        completedByDate: '2021-03-06T14:00:00.000Z',
                    },
                    {
                        id: 116,
                        color: '#F16636',
                        order: 2,
                        text:
                            'Set aside <desired minutes> per day for prayer/mediation',
                        completedByDate: '2021-03-20T14:00:00.000Z',
                    },
                    {
                        id: 107,
                        color: '#022B7A',
                        order: 3,
                        text: 'Join a <activity you are passionate about>',
                        completedByDate: '2021-03-19T14:00:00.000Z',
                    },
                    {
                        id: 113,
                        color: '#EB0F47',
                        order: 4,
                        text: 'Get a new job',
                        completedByDate: '2021-03-26T14:00:00.000Z',
                    },
                    {
                        id: 112,
                        color: '#EB0F47',
                        order: 5,
                        text: 'Get a second job to pay the bills',
                        completedByDate: '2021-03-20T14:00:00.000Z',
                    },
                    {
                        id: 110,
                        color: '#700460',
                        order: 6,
                        text: 'Reconnect with <old friend>',
                        completedByDate: '2021-03-20T14:00:00.000Z',
                    },
                    {
                        id: 106,
                        color: '#15C184',
                        order: 7,
                        text:
                            'Schedule an appointment with a health professional to improve my overall health',
                        completedByDate: '2021-03-10T14:00:00.000Z',
                    },
                ];
                const My7 = [];
                result.data.forEach((item) => {
                    item.completedByDate = new Date(item.completedByDate);
                    My7.push(item);
                });
                AllProps.My7 = My7;
                AllProps.Action = [{ text: '' }, { text: '' }, { text: '' }];
                AllProps.changeData = changeData;
            });
    }, [1]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const obj = (
        <SwipeableViews
            slideClassName="step3Section"
            style={{ width: '100%', height: '100%' }}
            AllState={data}
            index={data.CurrentStep3}
            disabled
        >
                <section
                    className="step1__container"
                    style={{ height: '80vh' }}
                >
                    <div className={`stepHeader__container  `}>
                        <StepHeader
                            url="https://player.vimeo.com/video/541811073"
                            title="STEP 3: ESTABLISH 3 ACTIONS"
                            description={
                                'We are on this planet to help you FIND BALANCE \n and a live of less stress and greater propuse - the OOLALIFE'
                            }
                            height="100%"
                            changePage={changePage}
                        />
                    </div>
                </section>
          {data.CurrentStep3===1?<div className="section">
                <EstablishActionSection
                    isFinishAction={0}
                    AllState={data}
                    updateIndexStep3={updateIndexStep3}
                    mobile={props.mobile}
                />
            </div>:<div/>}
            {data.CurrentStep3===2? <div
                className="section"
                style={{
                    width: '100%',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                }}
            >
                <FinishActions
                    isFinishAction={1}
                    AllState={data}
                    updateIndexStep3={updateIndexStep3}
                    handleChangeHeader={props.handleChangeHeader}
                    mobile={props.mobile}
                />
            </div>:<div/>}
        </SwipeableViews>
    );
    return obj;
};

export default SelectGoalsStep2;
