import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import './CStepperLevel2.css';

const useQontoStepIconStyles = makeStyles({
    active: {
        color: '#784af4',
    },

    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 27,
    },
    active: {
        '& $line': {
            backgroundImage: 'red',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#78ccf6',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#78ccf6',
        borderRadius: 1,
        marginTop: '30px',
        marginLeft: '-8px',
    },
})(StepConnector);

const ColorlibConnectorMobile = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage: 'red',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#78ccf6',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#78ccf6',
        borderRadius: 1,
        marginTop: '4px',
        marginLeft: '-8px',
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        marginTop: '40px',
        zIndex: 1,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        borderBlockColor: 'transparent',
    },
    active: {
        transform: 'scale(1.2)',
    },
    completed: {
        backgroundColor: 'transparent',
    },
});

const useColorlibStepIconStylesMobile = makeStyles({
    root: {
        marginTop: '12px',
        zIndex: 1,
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        borderBlockColor: 'transparent',
    },
    active: {
        transform: 'scale(1.2)',
    },
    completed: {
        backgroundColor: 'transparent',
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps(props) {
    if (props.mobile) {
        return [
            <div style={{ color: props.indexStep === 0 && 'white' }}>
                <div className="titleStepperMobile">STEP 1</div>
            </div>,
            <div style={{ color: props.indexStep === 1 && 'white' }}>
                <div className="titleStepperMobile">STEP 2</div>
            </div>,
        ];
    }
    return [
        <div style={{ color: props.indexStep === 0 && 'white' }}>
            <div className="titleStepper">STEP 1</div>
            <div className="descStepper">Where you are now</div>
        </div>,
        <div style={{ color: props.indexStep === 1 && 'white' }}>
            <div className="titleStepper">STEP 2</div>
            <div className="descStepper">Where you want to go</div>
        </div>,
    ];
}

const CStepperLevel2 = props => {
    const currStep = Number(JSON.stringify(JSON.parse(props.indexStep)));
    const classes = useStyles();
    const steps = getSteps(props);
    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;
        const icons = {
            1: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 0 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 0 ? 6 : 7,
                        }}
                    />
                    {currStep >= 0 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 0 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
            2: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 1 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 1 ? 6 : 7,
                        }}
                    />
                    {currStep >= 1 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 1 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
            3: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 2 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 2 ? 6 : 7,
                        }}
                    />
                    {currStep >= 2 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 2 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    function ColorlibStepIconMobile(props) {
        const classes = useColorlibStepIconStylesMobile();
        const { active, completed } = props;
        const icons = {
            1: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 0 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 0 ? 6 : 7,
                        }}
                    />
                    {currStep >= 0 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 0 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
            2: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 1 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 1 ? 6 : 7,
                        }}
                    />
                    {currStep >= 1 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 1 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
            3: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    viewBox="0 0 30 30"
                >
                    <circle
                        cx="13"
                        cy="15"
                        fill={currStep === 2 ? 'white' : '#78ccf6'}
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: 8,
                        }}
                    />
                    <circle
                        cx="13"
                        cy="15"
                        fill="#2c414c"
                        style={{
                            strokeWidth: '0px',
                            stroke: 'transparent',
                            r: currStep === 2 ? 6 : 7,
                        }}
                    />
                    {currStep >= 2 ? (
                        <circle
                            cx="13"
                            cy="15"
                            fill={currStep === 2 ? 'white' : '#78ccf6'}
                            style={{
                                strokeWidth: '0px',
                                stroke: 'transparent',
                                r: 4,
                            }}
                        />
                    ) : (
                        ''
                    )}
                </svg>
            ),
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    let cStepper = (
        <div
            className="CStepperLevel2__ContainerHeader"
            style={{ zIndex: '10000' }}
        >
            <h1 className="CStepperLevel2__TitleHeader">
                {' '}
                REVIEW. REVISE. REAFFIRM
            </h1>
            <Stepper
                className="StepperContent"
                alternativeLabel
                style={{ height: '80px' }}
                activeStep={props.indexStep}
                connector={<ColorlibConnector />}
            >
                {steps.map((label, i) => (
                    <Step key={i}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );

    if (props.mobile) {
        cStepper = (
            <div className={classes.root} style={{ zIndex: '10000' }}>
                <h1 className="CStepperLevel2__TitleHeader">
                    {' '}
                    REVIEW. REVISE. REAFFIRM
                </h1>
                <Stepper
                    alternativeLabel
                    style={{ backgroundColor: '#2c414c', height: '70px' }}
                    activeStep={props.indexStep}
                    connector={<ColorlibConnectorMobile />}
                >
                    {steps.map((label, i) => (
                        <Step key={i}>
                            <StepLabel
                                StepIconComponent={ColorlibStepIconMobile}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        );
    }
    return cStepper;
};

export default CStepperLevel2;
