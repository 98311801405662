import { useEffect, useContext, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import AppContext from '../../../../../../Context/AppContext';
import requestAxios from '../../../../../../util/requestAxios';
import ArrowSvg from '../../../../../../Assets/icons/Arrow.svg';
import './OurTopPick.css';
import CoachInfoCard from '../CoachInfoCard/CoachInfoCard';
export default () => {
    const responsive = {
        0: { items: 1 },
    };
    const { checkUserSession, getData, setNewData, changeLoading } =
        useContext(AppContext);
    const { userId, token } = checkUserSession();
    const { CoachFound, isMobile } = getData();
    const [selectedCoach, setSelectedCoach] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        getDataFindCoachQuestionary();
    }, []);

    const getDataFindCoachQuestionary = async () => {
        changeLoading(true);
        const url = `FindCoach/GetCoachPicks`;
        const { error, data } = await requestAxios({
            url,
            token: token,
        });
        if (error === '') {
            setNewData({ CoachFound: [...data], isMobile: { value: window.innerWidth <= 760 }});
        }
        changeLoading(false);
    };

    const renderNextButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                style={{ transform: 'rotate(180deg)' }}
                className={`ArrowHomepageCarousel Right`}
                onClick={() => changeIndex(currentIndex + 1)}
            />
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                className={`ArrowHomepageCarousel Left`}
                onClick={() => changeIndex(currentIndex - 1)}
            />
        );
    };

    const changeIndex = (index) => {
        const maxIndex = isMobile?.value ? newItemsMobile().length : newItems().length;
        if (index >= 0 && index < maxIndex) {
            setCurrentIndex(index);
        }
    };

    const saveSelectCoach = () => {
        setNewData({ FindCoach: { way: 3 }, selectedCoach });
    };

    const onSelectCoach = (coach) => {
        setTimeout(() => {
            setSelectedCoach(coach);
        }, 100);
    };
    const newItems = () => {
        const data = CoachFound;
        let arr = [];
        for (var i = 0, l = data.length; i < l; i++) {
            const item1 = data[i];
            const item2 = data[++i];
            const item3 = data[++i];

            arr.push(
                <div
                    className="OurTopPickCardItemGroup__container "
                    key={`OurTopPickCardItemGroup__container${i}`}
                >
                    {item1 && (
                        <div
                            className={`OurTopPickCardItem__container ${
                                item1.id == selectedCoach?.id && 'selected'
                            }`}
                        >
                            <CoachInfoCard
                                data={item1}
                                key={`CoachInfoCard${item1.id}`}
                                onSelect={() => onSelectCoach(item1)}
                            />
                        </div>
                    )}
                    {item2 && (
                        <div
                            className={`OurTopPickCardItem__container ${
                                item2.id == selectedCoach?.id && 'selected'
                            }`}
                        >
                            <CoachInfoCard
                                data={item2}
                                key={`CoachInfoCard${item2.id}`}
                                onSelect={() => onSelectCoach(item2)}
                            />
                        </div>
                    )}
                    {item3 && (
                        <div
                            className={`OurTopPickCardItem__container ${
                                item3.id == selectedCoach?.id && 'selected'
                            }`}
                        >
                            <CoachInfoCard
                                data={item3}
                                key={`CoachInfoCard${item3.id}`}
                                onSelect={() => onSelectCoach(item3)}
                            />
                        </div>
                    )}
                </div>
            );
        }
        return arr;
    };
    const newItemsMobile = () => {
        const data = CoachFound;
        let arr = [];
        for (var i = 0, l = data.length; i < l; i++) {
            const item1 = data[i];

            arr.push(
                <div
                    className="OurTopPickCardItemGroupMobile__container  "
                    key={`OurTopPickCardItemGroup__container${i}`}
                >
                    {item1 && (
                        <div
                            className={`OurTopPickCardItem__container ${
                                item1.id == selectedCoach?.id && 'selected'
                            }`}
                        >
                            <CoachInfoCard
                                data={item1}
                                key={`CoachInfoCard${item1.id}`}
                                onSelect={() => onSelectCoach(item1)}
                            />
                        </div>
                    )}
                </div>
            );
        }
        return arr;
    };
    return (
        <div className="OutTopPick__container">
            <h1>
                OUR TOP PICKS <hr />
            </h1>
            <div className="OutTopPickFoundCouches__container">
                {CoachFound && (
                    <AliceCarousel
                        mouseTracking={false}
                        touchTracking={false}
                        activeIndex={currentIndex}
                        items={isMobile?.value ? newItemsMobile() : newItems()}
                        controlsStrategy="alternate"
                        renderPrevButton={renderPrevButton}
                        renderNextButton={renderNextButton}
                        touchMoveDefaultEvents={false}
                        responsive={responsive}
                    />
                )}
            </div>
            <div className="btnConfirmProceedFindCoach__container">
                {selectedCoach?.id > 0 && (
                    <button
                        className="btnConfirmProceedFindCoach button-full-mid "
                        onClick={() => saveSelectCoach()}
                    >
                        CONFIRM AND PROCEED
                    </button>
                )}
            </div>
        </div>
    );
};
