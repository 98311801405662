import React, { useState, useRef } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import StartLivingOola from './HomePage2/StartLivingOola2/StartLivingOola2';
import GetStarted from './HomePage2/GetStarted2/GetStarted2';
import FooterSection from './LearnMore/FooterSection/FooterSection';
import LoginHeader from './Common/LoginHeader/LoginHeader';
import ExigoHeader from './Common/ExigoHeader/ExigoHeader';
import TestimonialsSection from './LearnMore/TestimonialsSection/TestimonialsSection';
import ChangingWorldSection from './LearnMore/ChangingWorldSection/ChangingWorldSection';
import StartYourJourneySection2 from './HomePage2/StartYourJourneySection2/StartYourJourneySection2';
import StartYourJourney from './HomePage2/StartYourJourney2/StartYourJourney2';
import PublicProfile from './HomePage2/PublicProfile2/PublicProfile2';
import Products from './HomePage2/V2/Products2/Products2';
import Programs from './HomePage2/V2/Programs2/Programs2';
import OolaServices from './HomePage2/V2/OolaServices2/OolaServices2';
import FreeTest from './HomePage2/V2/FreeTest2/FreeTest2';
import config from '../config.json';
import TheBlue from './HomePage2/V2/TheBlue2/TheBlue2';

const HomePage2 = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 768);
        setWindowWidth(window.innerWidth);
    });
    const history = useHistory();
    const { webAlias } = useParams();
    const refScroll = useRef(null);

    function goEnroll() {
        if (webAlias) history.push(`/learnmore/myoola/${webAlias}`);
        else history.push('/learnmore');
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isExigo = params.get('exigo');

    function goEnrollment() {
        if (webAlias)
            window.location.href = `${config.enrollmentURL}/${webAlias}/enrollment`;
        else
            window.location.href = `${config.enrollmentURL}/corporphan/enrollment`;
    }
    function goCourse(course) {
        window.location.href = `${config.ADDITIONALCOURSESERVER}/${course}`;
    }
    function goProduct(product) {
        window.location.href = `${config.OOLAPRODUCTS}/${product}`;
    }
    function goProducts() {
        window.location.href = `${config.ADDITIONALCOURSESSERVER}`;
    }
    function goFreeTest() {
        window.location.href = webAlias
            ? `${config.SERVER_URL_BASE}FreeTest/${webAlias}`
            : `${config.SERVER_URL_BASE}FreeTest`;
    }
    function goLifeStyleFrameWork() {
        window.location.href = `${config.OOLALIFEFRAMEWORKLINK}`;
    }
    return (
        <div className='HomePage__container'>
            {isExigo === '1' ? (
                <div className='exigoHeaderSpacing'/>
            ) : (
                <LoginHeader
                    goEnroll={goEnrollment}
                    isDarkLogo
                    enrollButtonText="ENROLL"
                    setMobile={setMobile}
                    mobile={mobile}
                />
            )}

            <StartYourJourneySection2
                goEnroll={goEnroll}
                enrollButtonText="JOIN US"
                refScroll={refScroll}
            />
            {webAlias && <PublicProfile webAlias={webAlias} />}
            <StartYourJourney goEnroll={goEnroll} enrollButtonText="JOIN US" />
            <TheBlue goFreeTest={goFreeTest} refScroll={refScroll} />


            <Products goProduct={goProduct} />
            <Programs goEnroll={goEnroll} />
            <OolaServices
                goEnroll={goEnroll}
                goCourse={goCourse}
                goLifeStyleFrameWork={goLifeStyleFrameWork}
            />
            <StartLivingOola
                goEnroll={goEnroll}
                goProducts={goProducts}
                mobile={mobile}
            />
            <FreeTest goFreeTest={goFreeTest} />
            <GetStarted goEnroll={goEnroll} />

            <TestimonialsSection optionApi={0} webAlias={webAlias} />
            <ChangingWorldSection
                goEnroll={goEnroll}
                enrollButtonText="JOIN US"
                webAlias={webAlias}
            /> 
            <FooterSection goEnroll={goEnroll} />
            
        </div>
    );
};

export default HomePage2;
