import React, { useContext, useState } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useWindowSize } from '@react-hook/window-size';
import Header from '../../Dashboard/Header/HeaderFreeTest';
import FreeStep from '../../FreeStep/FreeStep';
import { Link } from 'react-router-dom';
import AppContext from '../../../Context/AppContext';
import './FreeTest.css';
import { useEffect } from 'react';
const FreeTest = () => {
    const { checkFreeUserSession, setNewData } = useContext(AppContext);
    const FreeTestData = checkFreeUserSession();
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const [, setWindowWidth] = useState(window.innerWidth);
    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 768);
        setWindowWidth(window.innerWidth);
    });

    useEffect(() => {
        setNewData({ Shopify: { isShopify: true } });
    }, []);
    return (
        <div className="steps__container shopify">
            <FreeStep mobile={mobile} />
        </div>
    );
};

export default FreeTest;
