import React, { useEffect } from 'react';
import './CoachQuestionaryItem.css';

export default ({ item, i, setQuestionAnswers }) => (
    <div
        className="QuestionContainer scrollMyGoals CoachQuestionaryItem"
        key={`QA${item.id}`}
    >
        <div className="CoachQuestionaryItemTitle">
            {i + 1}. {item.text}
        </div>
        <div className="question_wrapper">
            {item.options.map((itemx, ix) => (
                <div style={{ paddingTop: '1rem' }} key={`QAO${itemx.id}`}>
                    <label className="containerOption CoachQuestionaryItemText">
                        {itemx.text}
                        <input
                            type="radio"
                            name={`Question${i}`}
                            id={`Question${i}${ix}`}
                            onClick={() =>
                                setQuestionAnswers({
                                    questionId: item.id,
                                    optionId: itemx.id,
                                })
                            }
                            defaultChecked={itemx.selected}
                        />
                        <span className="checkmark" />
                    </label>
                </div>
            ))}
        </div>
    </div>
);
