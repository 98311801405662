import React, { useState, useContext, useEffect } from 'react';
import { CoachCalendar, CoachClient } from './CoachTabs';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import './DashboardCoachTabs.css';

export default () => {
    const { checkUserSession,reloadClients } = useContext(AppContext);
    const [arrClients, setArrClients] = useState([]);
    useEffect(() => {
    getClients();
    }, [reloadClients]);
    const getClients = async () => {
        const us = checkUserSession();
        const url = `Coach/GetMyClients/${us.userId}`;
        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });
        if (error == '') {
            setArrClients(data);
        } else {
            window.location.replace = './Dashboard';
        }
    };
    const [activeTab, setActiveTab] = useState(1);
    return (
        <div className="DashboardTabs__container dashboard">
            <div className="coachDashboardSections">
                <div className="coachDashboardSectionsSelector__container">
                    <div className='coachDashboardSectionsSelectorTabs__container'>
                        <div
                            className={`coachDashboardSectionsSelectorItem__container ${
                                activeTab === 1 && 'active'
                            }`}
                            onClick={() => setActiveTab(1)}
                        >
                            MY ACTIVE CLIENTS
                        </div>

                        <div
                            className={`coachDashboardSectionsSelectorItem__container ${
                                activeTab === 2 && 'active'
                            }`}
                            onClick={() => setActiveTab(2)}
                        >
                            MY PAST CLIENTS
                        </div>
                    </div>

                    <button
                        className="GoToCalendlybtn"
                        type="button"
                        onClick={() =>
                            window.open(
                                'https://calendly.com/app/login',
                                '_blank'
                            )
                        }
                    >
                        Manage Schedule
                    </button>
                </div>
                <div className="DashboardCoachTabsContent OolaScroll">
                    {activeTab === 1 && (
                        <CoachClient
                            arrClients={arrClients.filter(
                                (x) => x.isActiveCustomer === true
                            )}
                        />
                    )}
                    {activeTab === 2 && (
                        <CoachClient
                            arrClients={arrClients.filter(
                                (x) => x.isActiveCustomer === false
                            )}
                            past
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
