import React, { useState, useEffect } from 'react';
import LoginHeader from '../Common/LoginHeader/LoginHeader';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import configData from '../../config.json';
import './Affiliate.css';
import ReactPlayer from 'react-player';

const Affiliate = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(async () => {
        window.scrollTo(0, 0);
    }, []);

    function goEnroll() {
        window.location.replace(
            'https://myoola.oolalife.com/corporphan/enrollment'
        );
    }

    const AffiliateR = (
        <>
            <LoginHeader
                goEnroll={goEnroll}
                isDarkLogo
                enrollButtonText="ENROLL"
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="affiliate__container">
                <div className="affiliate__header">
                    <svg
                        viewBox="0 0 400 400"
                        className="svgBanneraffiliateDesk"
                    >
                        <g transform="translate(0 150)">
                            <g className="affiliate__headerSVGTextContainer">
                                <text
                                    transform="translate(0 50)"
                                    fill="white"
                                    fontSize={60}
                                >
                                    JOIN AS AN OOLA
                                </text>

                                <text
                                    transform="translate(0 110)"
                                    fill="wHITE"
                                    fontSize={60}
                                >
                                    {'Affiliate Today! '}
                                </text>
                                <switch>
                                    <foreignObject
                                        x={0}
                                        y={130}
                                        width="625"
                                        height="200"
                                        fill="white"
                                    >
                                        <button
                                            className="AffiliateJoinButton button-full-mid"
                                            onClick={() =>
                                                window.open(
                                                    configData.AFFILIATELINK,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            JOIN TODAY
                                        </button>
                                    </foreignObject>
                                </switch>
                            </g>
                        </g>
                    </svg>
                    <svg
                        viewBox="0 0 300 250"
                        className="svgBanneraffiliateMob"
                    >
                        <g transform="translate(-40 30)">
                            <g className="affiliate__headerSVGTextContainer">
                                <text
                                    transform="translate(90 50)"
                                    fill="White"
                                    fontSize={35}
                                >
                                    JOIN AS AN OOLA
                                </text>
                                <text
                                    transform="translate(90 80)"
                                    fill="White"
                                    fontSize={35}
                                >
                                    {' Affiliate Today! '}
                                </text>
                                <g transform="translate(80 -50)">
                                    <switch>
                                        <foreignObject
                                            x={-205}
                                            //170
                                            y={170}
                                            width="625"
                                            height="200"
                                            fill="white"
                                        >
                                            <button
                                                className="AffiliateJoinButton button-full-mid"
                                                onClick={() =>
                                                    window.open(
                                                        configData.AFFILIATELINK,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                JOIN TODAY
                                            </button>
                                        </foreignObject>
                                    </switch>{' '}
                                    x={0}
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="affiliate_Perks">
                    <div className="affiliate_PerksHeader">
                        <h1>
                            Perks of being an <br />
                            <span>Oola Affiliate </span>
                        </h1>
                        <p>
                            As an Affiliate of Oola, you have the opportunity to
                            share our life-changing products and programs and
                            rightfully be rewarded for doing so. Earn referral
                            bonuses, plus up to 30% commissions, on all customer
                            orders.
                        </p>
                    </div>
                </div>
                <div className="affiliate_OolaRewardVideo">
                    <ReactPlayer
                        url={'https://vimeo.com/849595093'}
                        width="100%"
                        controls
                    />

                    <button
                        className="button-full-mid"
                        type="button"
                        onClick={() =>
                            window.open(configData.AFFILIATELINK, '_blank')
                        }
                    >
                        JOIN TODAY
                    </button>
                </div>
                <hr className="affiliate_separator" />
                <div className="affiliate_HowItWorks">
                    <h1>
                        HOW IT <span>WORKS</span>
                    </h1>
                    <div className="affiliate_HowItWorksStep">
                        <div>
                            <svg viewBox="0 0 20 20">
                                <circle r="10" fill="#78ccf6" cx="10" cy="10" />
                                <text
                                    x="7.5"
                                    y="14"
                                    fontSize={12}
                                    fontFamily="US101"
                                    fill="white"
                                >
                                    1
                                </text>
                            </svg>
                        </div>
                        <h1>CREATE YOUR AFFILIATE ACCOUNT TODAY!</h1>
                        <h2>
                            Join Oola as an Affiliate today and open the door to
                            an additional stream of income by sharing our
                            transformational products and programs.
                        </h2>
                    </div>
                    <div className="affiliate_HowItWorksStep">
                        <div>
                            <svg viewBox="0 0 20 20">
                                <circle r="10" fill="#78ccf6" cx="10" cy="10" />
                                <text
                                    x="7.5"
                                    y="14"
                                    fontSize={12}
                                    fontFamily="US101"
                                    fill="white"
                                >
                                    2
                                </text>
                            </svg>
                        </div>
                        <h1>SHARE OUR LIFE-CHANGING PRODUCTS AND PROGRAMS</h1>
                        <h2>
                            Once your Affiliate account is created, you will
                            receive a personal link that you can share so others
                            can get started. Earn $50 anytime you help someone
                            get started on our Total Rebalancing System, $37 on
                            the Oola Lifestyle Framework, and up to 30%
                            commissions on all orders purchased by customers you
                            refer with your Affiliate link. Watch the video
                            above for more information regarding your earning
                            potential.
                        </h2>
                    </div>
                    <div className="affiliate_HowItWorksStep">
                        <div>
                            <svg viewBox="0 0 20 20">
                                <circle r="10" fill="#78ccf6" cx="10" cy="10" />
                                <text
                                    x="7.5"
                                    y="14"
                                    fontSize={12}
                                    fontFamily="US101"
                                    fill="white"
                                >
                                    3
                                </text>
                            </svg>
                        </div>
                        <h1>MAKE AN IMPACT</h1>
                        <h2>
                            Your Affiliate partnership with Oola has a lasting
                            impact in the world that stretches far beyond your
                            customers. Oola gives a percentage of every purchase
                            to Rice Bowls, a faith-based non-profit organization
                            that provides healthy food for orphaned children.
                            Your Affiliate partnership with Oola provides
                            additional direct resources to Rice Bowls so they
                            can pro-actively feed the hungry. Thank you!
                        </h2>
                    </div>
                    <button
                        className="button-full-mid"
                        type="button"
                        onClick={() =>
                            window.open(configData.AFFILIATELINK, '_blank')
                        }
                    >
                        BECOME AN AFFILIATE
                    </button>
                </div>
                <hr className="affiliate_separator" />

                <div className="affiliate_WhatOtherSaying">
                    <div className="affiliate_WhatOtherSayingHeader">
                        <svg
                            viewBox="0 0 90 45"
                            className="svgBanneraffiliateDesk"
                        >
                            <g transform="translate(0 30)">
                                <g className="affiliate__headerSVGTextContainer">
                                    <text
                                        transform="translate(0 20)"
                                        fill="#cbeeff"
                                        fontSize={60}
                                    >
                                        “
                                    </text>

                                    <text
                                        transform="translate(10 0)"
                                        fill="#2C414C "
                                        fontSize={14}
                                    >
                                        {'WHAT OTHERS '}
                                    </text>

                                    <text
                                        transform="translate(15 12)"
                                        fill="#2C414C "
                                        fontSize={14}
                                        className="affiliateHeaderTextlvl2"
                                    >
                                        {'ARE'}
                                    </text>
                                    <text
                                        transform="translate(36 12)"
                                        fill="#78CCF6 "
                                        fontSize={14}
                                        className="affiliateHeaderTextlvl2"
                                    >
                                        {'SAYING'}
                                    </text>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="affiliate_WhatOtherSayingDetail">
                        <div className="affiliate_WhatOtherSayingPeopleItem__container">
                            <div className="affiliate_WhatOtherSayingPeopleItem">
                                <div className="affiliate_WhatOtherSayingPeopleItemImg__container P1">
                                    <img className="affiliate_WhatOtherSayingPeopleItemImg" />
                                </div>
                                <h1>Nicole Morrison</h1>
                                <h2>
                                    In a year when we had a lot of extra
                                    expenses, we were able to breathe a lot
                                    easier due to our income from Oola. It
                                    allowed us the ability to focus on the
                                    exciting things happening around us rather
                                    than how we were going to afford them.{' '}
                                </h2>
                            </div>
                            <div className="affiliate_WhatOtherSayingPeopleItem">
                                <div className="affiliate_WhatOtherSayingPeopleItemImg__container P2">
                                    <img
                                        style={{
                                            backgroundImage:
                                                'url("https://1152-frontdev.vm.epicservers.com//media/profile/User_987.jpeg?1693786444081")',
                                        }}
                                    />
                                </div>
                                <h1>Aimee Baudier</h1>
                                <h2>
                                    {' '}
                                    My added Oola income has opened up new
                                    opportunities for personal growth and
                                    self-improvement while helping others do the
                                    same. I am building an additional route to
                                    financial freedom so I have options and a
                                    greater sense of fulfillment and
                                    satisfaction.
                                </h2>
                            </div>
                        </div>
                        <div className="affiliate_WhatOtherSayingBecomeAffiliate__container">
                            <h1>
                                Become an Oola <br />
                                Affiliate today
                            </h1>
                            <h2>
                                Get started today by simply purchasing any
                                product or program.
                            </h2>
                            <button
                                className="button-full-mid"
                                type="button"
                                onClick={() =>
                                    window.open(
                                        configData.AFFILIATELINK,
                                        '_blank'
                                    )
                                }
                            >
                                SIGN ME UP!
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <FooterSection />
        </>
    );

    return AffiliateR;
};

export default Affiliate;
