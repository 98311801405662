import React from 'react';
import configData from '../../../../../../config.json';
import './CoachInfoCard.css';

export default ({ data, onSelect }) => (
    <article className="coachInfoCard_container">
        <div className="coachInfoCard-header_container">
            <div className="coachInfoCard-header_left">
                <div
                    className="coachInfoCard-header_avatar"
                    style={{
                        backgroundImage: `url(${configData.SERVER_URL_IMG}media/profile/${data?.profilePictureFileName})`,
                    }}
                />
            </div>
            <div className="coachInfoCard-header_right">
                <h2>
                    {data?.firstName}{' '}
                    {data?.lastName}
                </h2>
                <p>{data?.location}</p>
            </div>
        </div>
        <div className="coachInfoCard-body_container OolaScroll">
            <p>
                <strong>With me as your coach, you will:</strong>
                <br />
                {data?.myOffer}
            </p>
            <p>
                <strong>About Me:</strong>
                <br />
                {data?.aboutMe}
            </p>
        </div>
        <div className="coachInfoCard-footer_container">
            <button
                className="button-full-small"
                type="button"
                onClick={onSelect}
            >
                SELECT COACH
            </button>
        </div>
    </article>
);
