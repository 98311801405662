import React, { useState, useEffect, useContext } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';

import HeaderDashboard from '../Header/HeaderDashboard';
import ELearningHeader from './Sections/ELearningHeader';
import Network from '../../Network/Network';
import Watch from './Sections/Watch';
import Read from './Sections/Read';
import Do from './Sections/Do';
import ExtraSection from './Sections/ExtraSection';
import ModalQuestion from './Sections/ModalQuestion';
import configData from '../../../config.json';
import './Sections/ExtraSection.css';
import requestAxios from '../../../util/requestAxios';
const ELearning = () => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [, updateState] = React.useState();
    const history = useHistory();

    const forceUpdate = React.useCallback(() => updateState({}), []);
    const ModuleId = localStorage.getItem('ModuleId');
    const [showModalQuestion, setShowModalQuestion] = useState(false);
    const [lenghtModules, setLenghtModules] = useState(3);
    const [isModuleCertification, setIsModuleCertification] = useState(false);
    const [AllState, AllStateChange] = useState({
        ModuleInfo: {
            sections: [],
        },
    });
    function getMyProgress() {
        changeLoading(true);
        let url = `${configData.SERVER_URL}elearning/GetELearningModuleDetail/${ModuleId}`;
        if (localStorage.getItem('isAditionalCourse') === "1") {
            url = `${configData.SERVER_URL}AdditionalCourse/GetELearningModuleDetail/${ModuleId}`;
        }
        if (localStorage.getItem('isCertificationModule') === "1") {
            url = `${configData.SERVER_URL}AdditionalCourse/GetCertificationModuleDetail/${ModuleId}`;
            setIsModuleCertification(true);
        }

        // debugger;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                AllState.ModuleInfo = result.data;
                if (AllState.ModuleInfo.questions?.length > 0) {
                    setShowModalQuestion(true);
                }
                const doObj = AllState.ModuleInfo.sections.find(
                    (x) => x.name === 'Do'
                );
                if (doObj == null || doObj?.contentText == '') {
                    setLenghtModules(lenghtModules - 1);
                }
                AllStateChange(AllState);
                forceUpdate();
                changeLoading(false);
            })
            .catch((error) => {
                console.log(error);
                history.push('/Dashboard');
                changeLoading(false);
            });
    }

    function startNewSteps() {
        let url = `${configData.SERVER_URL}Customer/SetMidYearReviewStep`;

        if (AllState.ModuleInfo.nextStepId === 6)
            url = `${configData.SERVER_URL}Customer/SetYearInReviewStep`;
        changeLoading(true);
        axios
            .put(
                url,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((result) => {
                changeLoading(false);
                if (AllState.ModuleInfo.nextStepId === 5)
                    window.location.replace('/MidYearReviewWelcome');
                if (AllState.ModuleInfo.nextStepId === 6)
                    window.location.replace('/Steps');
            })
            .catch((error) => {
                changeLoading(false);
                console.log(error.toJSON());
            });
    }
    async function CreateActionSteps() {
        changeLoading(true);
        const url = `AdditionalCourse/AddActionSteps/${ModuleId}`;
        const { error, data } = await requestAxios({
            method: 'post',
            url: url,
            token,
        });
        if (error == '') {
            history.push('/Dashboard?showActionsSteps=1');
        }
        changeLoading(false);
    }
    useEffect(() => {
        getMyProgress();
    }, [1]);

    function CompleteModule() {
        const objSend = {
            Id: ModuleId,
            userId,
        };
        let url = `${configData.SERVER_URL}Elearning/CompleteELearningModule`;
        if (localStorage.getItem('isAditionalCourse') == 1) {
            url = `${configData.SERVER_URL}AdditionalCourse/CompleteELearningModule`;
        }
        axios({
            method: 'put',
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: objSend,
        })
            .then(() => {
                history.push('/Dashboard');
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const ELearningR = (
        <div style={{ height: '100%', backgroundColor: '#f5f5f5' }}>
            <div style={{ backgroundColor: 'white' }}>
                <div>
                    <HeaderDashboard />
                </div>
            </div>
            <Container fluid>
                <ELearningHeader AllState={AllState} configData={configData} isModuleCertification={isModuleCertification} />
                <Watch
                    AllState={AllState}
                    configData={configData}
                    lenghtModules={lenghtModules}
                />
                <Read
                    AllState={AllState}
                    configData={configData}
                    lenghtModules={lenghtModules}
                />
                <Do
                    AllState={AllState}
                    CompleteModule={CompleteModule}
                    configData={configData}
                    startNewSteps={startNewSteps}
                    CreateActionSteps={CreateActionSteps}
                    lenghtModules={lenghtModules}
                    isModuleCertification={isModuleCertification}
                />
            </Container>
            <Network />
            {AllState.ModuleInfo.questions && (
                <ModalQuestion
                    showModalQuestion={showModalQuestion}
                    setShowModalQuestion={setShowModalQuestion}
                    questions={AllState.ModuleInfo.questions}
                    getMyProgress={getMyProgress}
                />
            )}
        </div>
    );
    return ELearningR;
};

export default ELearning;
