import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider, Typography, Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';

import HeaderDashboard from '../../Header/HeaderDashboard';
import QuestionAssesment from './QuestionAssesment';
import Network from '../../../Network/Network';
import './Assesment.css';

const Assesment = () => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [_index, ChangeIndex] = useState(0);
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    const NQuestiosn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    const useStyles = makeStyles({
        root: {
            height: 300,
        },
    });
    const useStylesHor = makeStyles({
        root: {
            height: 100,
        },
        markLabel: {
            transform: 'rotate(-90deg)',
            marginLeft: '100px',
        },
    });

    function valuetext(value) {
        return `${value}°C`;
    }

    const PrettoSlider = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'vertical',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'vertical',
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);
    const PrettoSliderHor = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'horizontal',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'horizontal',
        },
        active: {},
        markLabel: {
            transform: 'rotate(-90deg)',
        },
        track: {
            height: 2,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);

    const classes = useStyles();
    const classesHor = useStylesHor();

    const [rangeValues, setrangeValues] = useState({ begin: 14, end: 14 });
    const [rangeValuesHor, setrangeValuesHor] = useState({ begin: 1, end: 14 });
    const [OptionSelected, ChangeOptionSelected] = useState([]);
    const CurrQ = OptionSelected.find((x) => x.questionId === _index + 1);
    const marks = [
        {
            value: 1,
            label: (
                <div
                    data-index="0"
                    className={
                        OptionSelected[12] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 14
                </div>
            ),
        },
        {
            value: 2,
            label: (
                <div
                    data-index="1"
                    className={
                        OptionSelected[11] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 13
                </div>
            ),
        },
        {
            value: 3,
            label: (
                <div
                    data-index="2"
                    className={
                        OptionSelected[10] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 12
                </div>
            ),
        },
        {
            value: 4,
            label: (
                <div
                    data-index="3"
                    className={
                        OptionSelected[9] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 11
                </div>
            ),
        },
        {
            value: 5,
            label: (
                <div
                    data-index="4"
                    className={
                        OptionSelected[8] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 10
                </div>
            ),
        },
        {
            value: 6,
            label: (
                <div
                    data-index="5"
                    className={
                        OptionSelected[7] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 09
                </div>
            ),
        },
        {
            value: 7,
            label: (
                <div
                    data-index="6"
                    className={
                        OptionSelected[6] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 08
                </div>
            ),
        },
        {
            value: 8,
            label: (
                <div
                    data-index="7"
                    className={
                        OptionSelected[5] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 07
                </div>
            ),
        },
        {
            value: 9,
            label: (
                <div
                    data-index="8"
                    className={
                        OptionSelected[4] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 06
                </div>
            ),
        },
        {
            value: 10,
            label: (
                <div
                    data-index="9"
                    className={
                        OptionSelected[3] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 05
                </div>
            ),
        },
        {
            value: 11,
            label: (
                <div
                    data-index="10"
                    className={
                        OptionSelected[2] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 04
                </div>
            ),
        },
        {
            value: 12,
            label: (
                <div
                    data-index="11"
                    className={
                        OptionSelected[1] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 03
                </div>
            ),
        },
        {
            value: 13,
            label: (
                <div
                    data-index="12"
                    className={
                        OptionSelected[0] == null ? '' : 'lblEnableAssesment'
                    }
                >
                    Q 02
                </div>
            ),
        },
        {
            value: 14,
            label: (
                <div data-index="13" className="lblEnableAssesment">
                    Q 01
                </div>
            ),
        },
    ];

  
    function selectQuestion(value) {
        if (value.target.dataset.index != null) {
            setrangeValues({
                begin: 14,
                end: Number(value.target.dataset.index) + 1,
            });
            const indexx = 13 - Number(value.target.dataset.index);
            ChangeIndex(indexx);
        } else {
            setTimeout(() => {
                const parent =
                    document.getElementsByClassName('MuiSlider-root')[0];
                const valuex = parent
                    .querySelectorAll("input[type='hidden']")[0]
                    .value.split(',')[0];

                setrangeValues({ begin: 14, end: Number(valuex) });
                ChangeIndex(14 - Number(valuex));
            }, 0);
        }
    }

    function selectQuestionHor(value) {
        if (value.target.dataset.index != null) {
            setrangeValuesHor({
                begin: 1,
                end: Number(value.target.dataset.index) + 1,
            });
            const indexx = Number(value.target.dataset.index);
            //const indexx = 13 - Number(value.target.dataset.index);
            ChangeIndex(indexx);
        } else {
            setTimeout(() => {
                const parent =
                    document.getElementsByClassName('MuiSlider-root')[0];
                const valuex = parent
                    .querySelectorAll("input[type='hidden']")[0]
                    .value.split(',')[0];

                setrangeValuesHor({ begin: 1, end: Number(valuex) });
                ChangeIndex(Number(valuex));
            }, 50);
        }
    }

    function changeIndexExt(value) {
        if (value <= 13) {
            setrangeValues({ begin: 14, end: 14 - Number(value) });
            const indexx = Number(value);
            ChangeIndex(indexx);
            forceUpdate();
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        ChangeIndex(newValue);
    };
    const AssesmentR = (
        <div style={{ minHeight: '99vh', backgroundColor: '#f5f5f5' }}>
            <div style={{ backgroundColor: 'white' }}>
                <div xs={12}>
                    <HeaderDashboard />
                </div>
            </div>
            <section className="assesment__container">
                <div className="assesment__wrapper">
                    <div style={{  paddingBottom: '30px' }}>
                        <Link to="/dashboard">
                            <button type="button" className="btnBackDashboard">
                                BACK TO DASHBOARD
                            </button>
                        </Link>
                    </div>
                    <div className="AssesmentHeader">Assessment One</div>
                    <div className="AssesmenDescription">
                        In the Design My Life Blueprint, we learned a lot about
                        you. We learned which areas of life you are strong, and
                        which could benefit from more balance. Now, as we enter
                        your Breakthrough Experience, we want to learn more. By
                        taking the time to honestly answer these 14 questions we
                        are able to provide the personalized content you need to
                        overcome what has likely been getting in the way of you
                        achieving your goals in the past.
                    </div>
                </div>
            </section>
            <section
                className="assesment__container"
            >
                <div className="assesment__wrapper_questions hiddenMobileQuestion">
                    <div className="hiddenMobileQuestion">
                        <>
                            <Typography id="vertical-slider" gutterBottom />
                            <div className={classes.root}>
                                <PrettoSlider
                                    orientation="vertical"
                                    aria-labelledby="vertical-slider"
                                    getAriaValueText={valuetext}
                                    marks={marks}
                                    step={1}
                                    min={1}
                                    max={14}
                                    value={[rangeValues.begin, rangeValues.end]}
                                    onChange={(input) => selectQuestion(input)}
                                    style={{ height: '60vh', width: '0.5rem' }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="  hiddenMobileQuestion">
                            <QuestionAssesment
                                _index={_index}
                                Change_Index={changeIndexExt}
                                OptionSelected={OptionSelected}
                                ChangeOptionSelected={ChangeOptionSelected}
                                CurrQ={CurrQ}
                                forceUpdate={forceUpdate}
                            />
                    </div>
                </div>
                {
                    // ------------------------------------------------Mobile ------------------------------------------
                }
                <div className={`visibleMobileQuestion`}>
                    <div>
                        <>
                            <Typography
                                id="horizontal-slider"
                                gutterBottom
                                style={{
                                    height: '10px',
                                    width: window.innerWidth * 0.8,
                                    marginLeft: window.innerWidth * 0.05,
                                }}
                            />
                            <div className="TabSlider___container">
                                <Tabs
                                    value={_index}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {NQuestiosn.map((item) => (
                                        <Tab
                                        key={`tab${item}`}
                                            className={
                                                OptionSelected.length + 2 <=
                                                item
                                                    ? 'questionTitleMobileDisable__text'
                                                    : 'questionTitleMobile__text'
                                            }
                                            label={`Q${
                                                item < 10 ? `0${item}` : item
                                            }`}
                                            disabled={
                                                OptionSelected.length + 2 <=
                                                item
                                                    ? true
                                                    : false
                                            }
                                            {...a11yProps(item)}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </>
                    </div>
                    <div className="assesment__wrapper_questionsMobile">
                        <div>
                            <QuestionAssesment
                                _index={_index}
                                Change_Index={changeIndexExt}
                                OptionSelected={OptionSelected}
                                ChangeOptionSelected={ChangeOptionSelected}
                                CurrQ={CurrQ}
                                forceUpdate={forceUpdate}
                                mobile={mobile}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Network />
        </div>
    );
    return AssesmentR;
};

export default Assesment;
