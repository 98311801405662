import React, { useState } from 'react';

const Wheel = (props) => {
    const [indexDone, ChangeIndexDone] = useState(0);
    function clickButton() {
        if (indexDone < 3) {
            ChangeIndexDone(indexDone + 1);
            if (indexDone + 1 === 3) {
                props.ChangeShowSide(1);
                props.handleChangeHeader(0);
            }
        }
    }
    const WheelR = (
        <div className="ButtonTracker__container">
            {(!props.mobile || props.showSide === 0) && (
                <>
                    <p
                        className={`ButtonTracker__Title ${
                            props.showSide === 1 && `HideTextStep3`
                        }`}
                    >
                        Click the Tracker 3 times:
                    </p>
                    <p
                        className={`ButtonTracker__Desc ${
                            props.showSide === 1 && `HideTextStep3`
                        }`}
                    >
                        Every time you click the tracker, it indicates that you
                        accomplished one of your daily actions. When you hit 3
                        actions you’ve accomplished your daily streak. 
                    </p>
                </>
            )}
            <svg
                id="ChartPieChunk "
                className={`ButtonTrackerSVG ${
                    props.mobile && 'ButtonTrackerSVGMarginTop'
                }`}
                width="440px"
                height="440px"
                viewBox="0 0 440 220"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g className="ButtonTrackerSVG__container" transform="translate(0,-120)">
                    {indexDone < 3 && (
                        <g>
                            <circle
                                className="btnOolaAnimation"
                                cx="220"
                                cy="220"
                                r="100"
                                fill="#78ccf6"
                                strokeWidth="1"
                            />
                            <circle
                                className="btnOolaAnimationStroke"
                                cx="220"
                                cy="220"
                                r="90"
                                fill="white"
                                strokeWidth="1"
                            />
                        </g>
                    )}
                    <mask id="wedge-mask" fill="white">
                        <path
                            transform="translate(20, 23)"
                            d="M 93 7.241 a 200.006 200.006 0 0 1 336 143.759 L 200.004 200 L 112.33 50.241 z"
                            fillRule="nonzero"
                        />
                    </mask>

                    <g className="circle1" transform="rotate(35,220,220)">
                        <g mask="url(#wedge-mask)">
                            <circle
                                className="Slice "
                                cx="220"
                                cy="220"
                                r="80"
                                fill={indexDone > 0 ? '#78ccf6' : '#C9C9C9'}
                                style={{ transform: 'scale(1)' }}
                            >
                                {' '}
                            </circle>
                            <text
                                className="titleQuestion"
                                x={110}
                                y={280}
                                fill={indexDone > 0 ? 'white' : '#8e8e8e'}
                                fontSize="15"
                                strokeWidth="1px"
                                style={{ transform: 'rotate(-35deg) ' }}
                            >
                                1
                            </text>
                        </g>
                    </g>

                    <g transform="rotate(155,220,220)">
                        <g mask="url(#wedge-mask)">
                            <circle
                                className="Slice "
                                cx="220"
                                cy="220"
                                r="80"
                                fill={indexDone > 1 ? '#78ccf6' : '#C9C9C9'}
                                style={{ transform: 'scale(1)' }}
                            >
                                {' '}
                            </circle>
                            <text
                                className="titleQuestion"
                                x={-300}
                                y={-30}
                                fill={indexDone > 1 ? 'white' : '#8e8e8e'}
                                fontSize="15"
                                strokeWidth="1px"
                                style={{ transform: 'rotate(205deg)' }}
                            >
                                2
                            </text>
                        </g>
                    </g>
                    <g transform="rotate(275,220,220)">
                        <g mask="url(#wedge-mask)">
                            <circle
                                className="Slice "
                                cx="220"
                                cy="220"
                                r="80"
                                fill={indexDone > 2 ? '#78ccf6' : '#C9C9C9'}
                                style={{ transform: 'scale(1)' }}
                            >
                                {' '}
                            </circle>
                            <text
                                className="titleQuestion"
                                x={170}
                                y={-220}
                                fill={indexDone > 2 ? 'white' : '#8e8e8e'}
                                fontSize="15"
                                strokeWidth="1px"
                                style={{ transform: 'rotate(85deg)' }}
                            >
                                3
                            </text>
                        </g>
                    </g>
                    <circle
                        cx="220"
                        cy="220"
                        r="60"
                        fill="white"
                        strokeWidth="1"
                    />
                    <rect
                        width="30"
                        height="12"
                        fill="white"
                        x="139"
                        y="-225"
                        style={{ transform: 'rotate(90deg)' }}
                    />
                    <rect
                        width="50"
                        height="12"
                        fill="white"
                        x="331"
                        y="74"
                        style={{ transform: 'rotate(30deg)' }}
                    />
                    <rect
                        width="25"
                        height="12"
                        fill="white"
                        x="0"
                        y="-306"
                        style={{ transform: 'rotate(145deg)' }}
                    />

                    <circle
                        cx="220"
                        cy="220"
                        r="40"
                        fill="#FFF"
                        strokeWidth="1"
                    />
                    <circle
                        className="btnOola "
                        onClick={() => {
                            clickButton();
                        }}
                        cx="220"
                        cy="220"
                        r="50"
                        fill=" #d86b59"
                        stroke="#2c414c"
                        strokeWidth="3"
                    />

                    <path
                        className="OolaName"
                        onClick={() => {
                            clickButton();
                        }}
                        d="M467.672,165.336a9.529,9.529,0,0,1-3.559-3.548,9.428,9.428,0,0,1-1.3-4.812,9.623,9.623,0,0,1,1.3-4.866,9.686,9.686,0,1,1,16.784,9.674,9.623,9.623,0,0,1-3.551,3.548,9.593,9.593,0,0,1-9.674,0Zm.671-15.652a8.147,8.147,0,0,0-3.071,3.075,8.349,8.349,0,0,0-1.117,4.2,8.391,8.391,0,0,0,8.375,8.359,8.085,8.085,0,0,0,4.145-1.128,8.4,8.4,0,0,0,0-14.509,8.031,8.031,0,0,0-4.145-1.124A8.244,8.244,0,0,0,468.343,149.683Zm-32.549,16.959V108.108c0-15.1-7.975-28.734-20.809-35.581a40.413,40.413,0,0,0-59.3,32.976A39.593,39.593,0,0,0,366.4,135.462,42.032,42.032,0,0,0,397,148.609H422.27v18.014L396,166.642a58.451,58.451,0,0,1-58.4-60.806,58.453,58.453,0,0,1,88.2-47.915c17.269,10.259,27.993,29.509,28,50.237v58.484Zm-129.758,0V0h18.013V166.642ZM175.645,108.2a58.441,58.441,0,1,1,58.445,58.441A58.506,58.506,0,0,1,175.645,108.2Zm18.033,0A40.408,40.408,0,1,0,234.09,67.793,40.458,40.458,0,0,0,193.678,108.2ZM0,83.321a83.321,83.321,0,1,1,83.321,83.321A83.414,83.414,0,0,1,0,83.321Zm18.033,0A65.288,65.288,0,1,0,83.321,18.033,65.36,65.36,0,0,0,18.033,83.321ZM475.57,162.447a8.528,8.528,0,0,1-.271-1.648l-.093-1.373a2.349,2.349,0,0,0-.237-1.058,1.069,1.069,0,0,0-.543-.45,4.679,4.679,0,0,0-1.4-.132h-2.792v4.66h-1.764V151.285h4.653a6.182,6.182,0,0,1,2.342.322,2.657,2.657,0,0,1,1.163,1.07,3.264,3.264,0,0,1,.454,1.733,3.192,3.192,0,0,1-.318,1.466,3.254,3.254,0,0,1-1.078,1,2.18,2.18,0,0,1,1.012.857,3.915,3.915,0,0,1,.26,1.679v1.256a2.611,2.611,0,0,0,.4,1.481l.186.3Zm-5.331-6.254h2.737a3.4,3.4,0,0,0,1.687-.287,1.484,1.484,0,0,0,.655-1.376,1.558,1.558,0,0,0-.465-1.252,2.878,2.878,0,0,0-1.764-.392h-2.85Z"
                        fill="white"
                        stroke="rgba(0,0,0,0)"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                </g>
            </svg>
        </div>
    );
   

    return WheelR;
};
export default Wheel;
