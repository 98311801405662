import React, { useContext, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import AppContext from '../../../../../../Context/AppContext';
import requestAxios from '../../../../../../util/requestAxios';
import './FindCoachQuestionary.css';
import FindCoachQuestionaryItem from './CoachQuestionaryItem/FindCoachQuestionaryItem';

export default () => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const { checkUserSession, getData, setNewData, changeLoading } =
        useContext(AppContext);
    const us = checkUserSession();
    const { FindCoach } = getData();

    useEffect(() => {
        getDataFindCoachQuestionary();
    }, []);

    const getDataFindCoachQuestionary = async () => {
        changeLoading(true);
        const url = `FindCoach/GetAssessmentQuestions`;
        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });
        if (error === '') {
            const newData = { ...FindCoach };
            newData.findCoachAssessment = [...data];
            setNewData({ FindCoach: { ...newData } });
        }
        changeLoading(false);
    };

    const addAnswers = (answer) => {
        const newQuestionAnswers = [...questionAnswers];
        const index = newQuestionAnswers.findIndex(
            (item) => item.questionId === answer.questionId
        );
        if (index >= 0) {
            newQuestionAnswers[index] = answer;
        } else {
            newQuestionAnswers.push(answer);
        }
        setQuestionAnswers(newQuestionAnswers);
    };

    const saveQuestionary = async () => {
        if (questionAnswers.length === FindCoach?.findCoachAssessment.length) {
            const postObj = {
                userId: us.userId,
                details: [...questionAnswers],
            };
            const url = `FindCoach/SaveAssessment`;
            const { error } = await requestAxios({
                url,
                token: us.token,
                method: 'POST',
                data: postObj,
            });

            if (!error) {
                setNewData({ FindCoach: { step: 2 } });
            }
        } else {
            window.alert('Please answer all the questions');
        }
        changeLoading(false);
    };

    return (
        <div className="FindCoachQuestionary__container">
            <h1>
                Let’s find you <br />
                The <span>perfect</span> coach
            </h1>
            <hr />
            <div className="FindCoachQuestionaryItems__container ">
                <SwipeableViews index={questionIndex} disabled>
                    {FindCoach?.findCoachAssessment != null &&
                        FindCoach.findCoachAssessment.map((item, i) => (
                            <FindCoachQuestionaryItem
                                item={item}
                                i={i}
                                setQuestionAnswers={addAnswers}
                                setInitialQuestionAnswers={setQuestionAnswers}
                                questionAnswers={questionAnswers}
                            />
                        ))}
                </SwipeableViews>
                {FindCoach?.findCoachAssessment != null && (
                    <div className="FindCoachModalNextButton__container">
                        {questionIndex >= 1 && (
                            <button
                                className="button-empty-small"
                                style={{
                                    borderRadius: '5px',
                                }}
                                type="button"
                                onClick={() =>
                                    setQuestionIndex(questionIndex - 1)
                                }
                            >
                                {'< PREVIOUS'}
                            </button>
                        )}

                        <button
                            className={`button-full-small FindCoachModalNextButton ${
                                questionAnswers[questionIndex] == null &&
                                'disabled'
                            }`}
                            style={{ borderRadius: '5px' }}
                            type="submit"
                            onClick={() => {
                                if (
                                    FindCoach.findCoachAssessment.length - 1 ===
                                    questionIndex
                                ) {
                                    saveQuestionary();
                                } else {
                                    setQuestionIndex(questionIndex + 1);
                                }
                            }}
                        >
                            {FindCoach.findCoachAssessment.length - 1 ===
                            questionIndex
                                ? 'SEARCH'
                                : 'NEXT'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
