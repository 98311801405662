import React from 'react';
import { Modal } from 'react-bootstrap';

import CloseButton from '../../../General/CloseButton/CloseButton';
import './MyOolaCoachInfoModal.css';

const MyOolaCoachInfoModal = ({ setMyOolaCoachInfo }) => (
    <Modal
        show
        aria-labelledby="example-custom-modal-styling-title"
        className="FindCoachModal__container"
        centered
        size="xl"
        style={{ width: '100%', minHeight: '100%' }}
        scrollable
    >
        <Modal.Header style={{ border: 'none' }}>
            <CloseButton onClick={() => setMyOolaCoachInfo(false)} />
        </Modal.Header>
        <Modal.Body>
            <div className="coachInfoModal_container">
                <h5>WANT TO GET RESULTS FASTER?</h5>
                <p>
                    Do you know what most successful people have in common? They
                    have a coach! If you&apos;re seeking an opportunity to take
                    your success to the next level, then this is for you! Get
                    one-on-one accountability coaching with a Certified Oola
                    Life coach for just $50/month. UPGRADE TODAY and a coach
                    will start working with you right away inside your very own
                    personal coaching room. This chat feature provides you with
                    the guidance, mentorship, and accountability to fulfill all
                    your goals! Go get your Oola Life with the support of a
                    Certified Oola Life Coach! And no stress, you can cancel
                    anytime.
                </p>
                <h5>What Do You Get with One-on-one Chat Coaching?</h5>
                <p>
                    REAL RESULTS: Certified Oola Coaches are experts in the Oola
                    Lifestyle Framework and are trained to help you get the
                    results you seek to achieve. <br />
                    ACCOUNTABILITY: Certified Oola Coaches will be beside you
                    every step of the way to ensure you are on track towards the
                    achievement of your goals. <br />
                    MENTORSHIP: Certified Oola Coaches will mentor you as you
                    work through your framework so you can fully grasp the
                    principles, concepts, and methods that make this system one
                    of the best in the world for achieving your goals.
                </p>
                <h5>What Don&apos;t You Get?</h5>
                <p>
                    Counseling, Therapy, or Medical advice. <br />
                    Phone, video chat or in-person consultations are available
                    upon request for an additional price.
                </p>
            </div>
        </Modal.Body>
    </Modal>
);
export default MyOolaCoachInfoModal;
