import React, { useState, useContext } from 'react';
import useFitText from 'use-fit-text';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import Achievements from '../../Dashboard/UserInfo/Modals/Achievements';
import ImageIcon from '../../Dashboard/UserInfo/Modals/ImageIcon';
import iconUser from '../../../Assets/img/userIcon.jpg';
import './UserInfo.css';

const UserInfo = (props) => {
    const { checkUserSession, changeModalWarningUpgrade } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [show, SetShow] = useState(false);
    const [showAchievents, SetShowAchievents] = useState(false);
    const [badgeSelected, setBadge] = useState('');
    const { fontSize, ref } = useFitText();
    const [imageHash, imageHashChange] = useState(Date.now());

    const [data, changeData] = useState({
        file: null,
    });

    function setShowModal(visible) {
        SetShow(visible);
    }
    function SetShowAchieventsModal(visible) {
        SetShowAchievents(visible);
    }

    function setBadgeModal(section) {
        setBadge(section);
    }

    function clickButtonAchievements() {
        setShowModal(true);
    }

    const changeHandler = (event) => {
        const AllProps = { ...data };
        AllProps.file = event.target.files[0];
        changeData(AllProps);

        // Create an object of formData
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('image', AllProps.file);

        const url = `${configData.SERVER_URL_IMGUpload}`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .post(url, formData, config)
            .then((response) => {
                if (response.status === 201) {
                    props.getCustomerProfileInformation();
                    const AllProps = { ...data };
                    AllProps.file = null;
                    changeData(AllProps);
                    imageHashChange(Date.now());

                    alert('Image uploaded correctly');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <article className="userInfo">
            <div className="userInfo__image">
                <div
                    alt="Profile"
                    className="image__profile"
                    style={{
                        '--imgProfileUrl': `url(${
                            props.AllState.profile.pictureFileName
                                ? `${configData.SERVER_URL_IMG}/media/profile/${props.AllState.profile.pictureFileName}?${imageHash}`
                                : iconUser
                        })`,
                    }}
                >
                    {props.PublicProfile !== 1 && (
                        <div className="image__input">
                            <input
                                type="file"
                                size="35"
                                onChange={changeHandler}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="userInfo__info">
                {props.PublicProfile !== 1 && (
                    <h5 className="heading-5">Hello</h5>
                )}
                <div className="info__name">
                    <h1 ref={ref} className="heading-1">
                        {props.AllState.profile.firstName}{' '}
                        {props.AllState.profile.lastName}
                    </h1>
                </div>
                <div className="info__level">
                    <button
                        className="button-full-mid btnUpgradeButton"
                        onClick={() => {
                            
                            window.open(configData.UPDGRADEACCOUNTLINK, '_blank');

                        }}
                    >
                        UPGRADE ACCOUNT
                    </button>
                </div>
                <div className="info__achievements">
                    {props.achievementsLoading ? (
                        <div className="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    ) : (
                        props.AllState.profile.latestAchievements?.length > 0 &&
                        props.AllState.profile.latestAchievements.map(
                            (item, index) => (
                                <ImageIcon
                                    key={`BadgetSVG${item.id}`}
                                    index={index}
                                    name={item.id}
                                    type="achievements"
                                    value={
                                        props.AllState.userProgress
                                            .goalsCompleted
                                    }
                                    filename={
                                        props.AllState.userProgress.fileName
                                    }
                                    item={item}
                                />
                            )
                        )
                    )}
                    <button
                        onClick={() => clickButtonAchievements()}
                        type="button"
                    >
                        My achievements
                    </button>
                </div>
            </div>

            {props.NonSubscriber !== true && show && (
                <Achievements
                    show={show}
                    showAchievents={showAchievents}
                    setShowError={setShowModal}
                    AllState={props.AllState}
                    AllStateChange={props.AllStateChange}
                    SetShow={SetShow}
                    SetShowAchieventsModal={SetShowAchieventsModal}
                    setBadgeModal={setBadgeModal}
                    badgeSelected={badgeSelected}
                    setShowModal={setShowModal}
                />
            )}
        </article>
    );
};

export default UserInfo;
