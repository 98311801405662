// import { useLocalStorage } from 'beautiful-react-hooks';
import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import ListFs from '../../../../Common/ListFs/ListFs';

import './Fnav.css';

export default ({ state, handleChange, handleChangeState }) =>  (
        <ul className={`elementSideFs elementSideFs${state.index + 1}`}>
            <ListFs
                AllState={state}
                handleChange={handleChange}
                mChange={handleChangeState}
            />
        </ul>
    );

