import React from 'react';
import { Collapse } from 'react-bootstrap';
import './Collapsable.css';

const Collapsable = (props) => {
    function createMarkup(value) {
        return { __html: value };
    }
    function setOpen() {
        props.AllState.forEach((item) => {
            if (item.id !== props.id) {
                item.open = false;
            }
        });
        props.AllState.find((x) => x.id === props.id).open =
            !props.AllState.find((x) => x.id === props.id).open;
        props.ChangeState(props.AllState);
        props.forceUpdate();
    }
    const hasOpen = props.AllState.filter((x) => x.open === true).length > 0;

    return (
        <article className="faqQuestions">
            <button
                onClick={() => setOpen()}
                type="button"
                style={{
                    color:
                        props.open === true || hasOpen === false
                            ? '#78ccf6'
                            : '#888686',
                }}
            >
                <span>{props.open === true ? '-' : '+'}</span>
                {props.Title}
            </button>
            <Collapse in={props.open} className="faqQuestions__collapse">
                <div
                    className="collapse__container"
                    dangerouslySetInnerHTML={createMarkup(props.Description)}
                />
            </Collapse>
        </article>
    );
};

export default Collapsable;
