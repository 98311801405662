import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AppContext from '../Context/AppContext';

const AppRoute = ({
    component: Component,
    path,
    isPrivate,
    exact,
    ...rest
}) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, isShopify } = checkUserSession();
    return isPrivate ? (
        <Route
            exact={exact}
            path={path}
            render={(props) =>
                token != '' && token != null ? (
                    <Component {...props} />
                ) : (
                    
                   !isShopify? <Redirect to={{ pathname: '/login' }} />:<Redirect to={{ pathname: '/Shopify/FreeTestShopify' }} />
                )
            }
            {...rest}
        />
    ) : (
        <Route
            exact={exact}
            path={path}
            render={(props) => <Component {...props} />}
            {...rest}
        />
    );
};

export default AppRoute;
