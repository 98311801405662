import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../Context/AppContext';
import NewModules from './Board/NewModules/NewModules';
import MyBreakthrough from './Board/MyBreakthrough/MyBreakthrough';
import MyPath from './Board/MyPath/MyPath';
import MyPathLevel from './Board/MyPathLevel/MyPathLevel';
import configData from '../../../config.json';
import './ELearningBoard.css';

const App = ({ AllState }) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [modules, ChangeModules] = useState({
        myBreakthroughExperience: [],
        myPersonalizedPath: [],
        moduleVip: [],
    });
    const level =
        AllState.profile.customerLevel == null
            ? 1
            : Number(AllState.profile.customerLevel.replace('Level ', ''));
    const ELearningTabSelected = Number(
        localStorage.getItem('ELearningTabSelected')
    );
    const [loaded, Changeloaded] = useState(false);
    const [typeModules, ChangeTypeModules] = useState(
        ELearningTabSelected ? ELearningTabSelected : level === 1 ? 1 : 2
    );
    const [currentLevel, setCurrentLevelx] = useState(level);
    const [windowHeigth] = useState(window.innerHeight);
    const history = useHistory();
    const completeLength = modules[
        typeModules === 2 ? 'myPersonalizedPath' : 'myBreakthroughExperience'
    ].filter((x) => x.isCompleted === true).length;
    const uncompleteLength = modules.myBreakthroughExperience.filter(
        (x) => x.isCompleted === false
    ).length;
    const unlockedLength = modules.myBreakthroughExperience.filter(
        (x) => x.isUnlocked === true
    ).length;

    const height = windowHeigth / (modules.myBreakthroughExperience.length / 2);
    function setCurrentLevel(levelx) {
        getModules(levelx);
        setCurrentLevelx(levelx);
    }
    useEffect(() => {
        getModules(level);
    }, []);
    function getModules(levelx) {
            const url = `${configData.SERVER_URL}Elearning/GetELearningModules/${userId}/${levelx}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((result) => {
                    Changeloaded(true);
                    const newObj = {
                        ...modules,
                        ...result.data,
                    };
                    ChangeModules(newObj);
                })
                .catch((error) => {
                    Changeloaded(true);
                    console.log(error);
                });
        
    }
    return (
        <article className="eLearningBoardModules">
            <div className="TitleDashboardContent">
                <section className="heading-5 BlueDarkText">
                    MY E-LEARNING
                </section>
                <div
                    className={`LevelContainer ${
                        currentLevel === 1 && 'Active'
                    }`}
                    onClick={() => setCurrentLevel(1)}
                >
                    <b> L1</b>
                </div>
                <div
                    className={`LevelContainer ${
                        currentLevel === 2 && 'Active'
                    } ${level < 2 && 'Disable'}`}
                    onClick={() => {
                        setCurrentLevel(2);
                        ChangeTypeModules(2);
                    }}
                >
                    <b> L2</b>
                </div>
                <div
                    className={`LevelContainer ${
                        currentLevel === 3 && 'Active'
                    } ${level < 3 && 'Disable'}`}
                    onClick={() => level >= 3 && setCurrentLevel(3)}
                >
                    <b> L3</b>
                </div>
                <div
                    className={`LevelContainer ${
                        currentLevel === 4 && 'Active'
                    } ${level < 4 && 'Disable'}`}
                    onClick={() => level >= 4 && setCurrentLevel(4)}
                >
                    <b> L4</b>
                </div>            </div>
            <div className="ELearningModules__Container ">
                {currentLevel === 1 ? (
                    <section className="ELearning__Menu">
                        <button
                            type="button"
                            className={
                                typeModules === 1
                                    ? 'ModuleTypeActive '
                                    : 'btnModuleType'
                            }
                            onClick={() => {
                                ChangeTypeModules(1);
                                localStorage.setItem('ELearningTabSelected', 1);
                            }}
                        >
                            MY BREAKTHROUGH
                        </button>
                        <button
                            type="button"
                            className={
                                typeModules === 2
                                    ? 'ModuleTypeActive '
                                    : 'btnModuleType'
                            }
                            onClick={() => {
                                // if (modules.myBreakthroughExperience.length > 0)
                                ChangeTypeModules(2);
                                localStorage.setItem('ELearningTabSelected', 2);
                            }}
                        >
                            MY PATH
                        </button>
                        <button
                            type="button"
                            className={
                                typeModules === 3
                                    ? 'ModuleTypeActive '
                                    : 'btnModuleType'
                            }
                            onClick={() => {
                                ChangeTypeModules(3);
                                localStorage.setItem('ELearningTabSelected', 3);
                            }}
                        >
                            ADDITIONAL PROGRAMS
                        </button>
                    </section>
                ) : (
                    <section className="ELearning__HigerLevelMenu">
                        <button
                            type="button"
                            className={
                                typeModules === 2
                                    ? 'ModuleTypeActive '
                                    : 'btnModuleType'
                            }
                            onClick={() => {
                                // if (modules.myBreakthroughExperience.length > 0)
                                ChangeTypeModules(2);
                                localStorage.setItem('ELearningTabSelected', 2);
                            }}
                        >
                            MY PATH
                        </button>
                        <button
                            type="button"
                            className={
                                typeModules === 3
                                    ? 'ModuleTypeActive '
                                    : 'btnModuleType'
                            }
                            onClick={() => {
                                ChangeTypeModules(3);
                                localStorage.setItem('ELearningTabSelected', 3);
                            }}
                        >
                            ADDITIONAL PROGRAMS
                        </button>
                    </section>
                )}
                <div className="ModuleContent__container OolaScroll">
                    {typeModules === 1 && (
                        <MyBreakthrough
                            loaded={loaded}
                            completeLength={completeLength}
                            uncompleteLength={uncompleteLength}
                            unlockedLength={unlockedLength}
                            height={height}
                            modules={modules}
                            typeModules={typeModules}
                            configData={configData}
                        />
                    )}
                    {typeModules === 2 && currentLevel === 1 && (
                        <MyPath
                            loaded={loaded}
                            completeLength={completeLength}
                            uncompleteLength={uncompleteLength}
                            unlockedLength={unlockedLength}
                            height={height}
                            modules={modules}
                            typeModules={typeModules}
                            configData={configData}
                            level={currentLevel}
                        />
                    )}
                    {typeModules === 2 && currentLevel !== 1 && (
                        <MyPathLevel
                            loaded={loaded}
                            completeLength={completeLength}
                            uncompleteLength={uncompleteLength}
                            unlockedLength={unlockedLength}
                            height={height}
                            modules={modules}
                            typeModules={typeModules}
                            configData={configData}
                            level={currentLevel}
                        />
                    )}

                    {typeModules === 3 && <NewModules />}
                </div>
            </div>
        </article>
    );
};

export default App;
