import React, { useContext } from 'react';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import './MyClients.css';

export default ({ item, past }) => {
    const { setNewData, checkUserSession, NumbertoWords } = useContext(AppContext);
    const us = checkUserSession();
    return (
        <div className="MyClientsItem__container">
            <div className="MyClients__container">
                <div className="MyClientProfile__container">
                    <article
                        className="MyClientProfilePicture__container"
                        style={{
                            backgroundImage: `url(${configData.SERVER_URL_IMG}media/profile/${item?.profilePictureFileName})`,
                        }}
                    />
                    <h1>
                        {item?.firstName} {item?.lastName}
                    </h1>
                    <h2>LEVEL {String(NumbertoWords(item?.level)).toUpperCase()}</h2>
                </div>
                <div className="MyClientProgress__container">
                    <div className="MyClientProgressScore__container">
                        <div>
                            <h3 className="display-2">{item?.stepsTaken}</h3>
                            <h6 className="heading-6">
                                <span>ACTION</span>
                                <br />
                                STEPS
                                <br />
                                TAKEN
                            </h6>
                        </div>
                        <div>
                            <h3 className="display-2">{item?.currentStreak}</h3>
                            <h6 className="heading-6">
                                <span>DAYS</span>
                                <br />
                                CURRENT
                                <br />
                                STREAK
                            </h6>
                        </div>
                        <div>
                            <h3 className="display-2">{item?.longestStreak}</h3>
                            <h6 className="heading-6">
                                <span>DAYS</span>
                                <br />
                                LONGEST
                                <br />
                                STREAK
                            </h6>
                        </div>
                        <div>
                            <h3 className="display-2">
                                {item?.goalsCompleted}
                            </h3>
                            <h6 className="heading-6">
                                <span>MY</span>
                                <br />
                                GOALS
                                <br />
                                COMPLETED
                            </h6>
                        </div>
                    </div>
                    <div className="MyClientProgressText__container">
                        <h1>
                            CURRENT OOLA SCORE: {item?.latestOolaScore}
                            <br />
                            <span>Date: {item?.latestOolaDate}</span>
                        </h1>
                        <h2>|</h2>
                        {item?.currentPhase!="" && (
                            <h1>
                                CURRENT E-LEARNING: {item?.currentLessonName}
                                <br />
                                <span>
                                    {item?.currentPhase}:{' '}
                                    <span
                                        onClick={() => {
                                            window
                                                .open(
                                                    `${configData.SERVER_URL_IMG}media/cheatSheet/${item?.cheatSheetLink}`,
                                                    '_blank'
                                                )
                                                .focus();
                                        }}
                                    >
                                        {item?.moduleNumber}
                                    </span>
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    onClick={() => {
                                        window
                                            .open(
                                                `${configData.SERVER_URL_IMG}media/cheatSheet/${item?.cheatSheetLink}`,
                                                '_blank'
                                            )
                                            .focus();
                                    }}
                                >
                                    <g
                                        id="Group_1783"
                                        data-name="Group 1783"
                                        transform="translate(-400 -2732)"
                                    >
                                        <g
                                            id="direct-download"
                                            transform="translate(402 2738)"
                                        >
                                            <path
                                                id="Path_2198"
                                                data-name="Path 2198"
                                                d="M11.867,15.69a.732.732,0,0,1-.53-.227L6.2,10.085a.734.734,0,0,1,.53-1.24H9.423V3.222A1.224,1.224,0,0,1,10.645,2H13.09a1.224,1.224,0,0,1,1.222,1.222V8.845H17a.734.734,0,0,1,.53,1.24L12.4,15.463A.732.732,0,0,1,11.867,15.69Z"
                                                transform="translate(-0.133)"
                                                fill="#2c414c"
                                            />
                                            <path
                                                id="Path_2199"
                                                data-name="Path 2199"
                                                d="M21.758,21.912H1.711A1.713,1.713,0,0,1,0,20.2v-.489A1.713,1.713,0,0,1,1.711,18H21.758a1.713,1.713,0,0,1,1.711,1.711V20.2A1.713,1.713,0,0,1,21.758,21.912Z"
                                                transform="translate(0 -0.354)"
                                                fill="#d86b59"
                                            />
                                        </g>
                                        <rect
                                            id="Rectangle_827"
                                            data-name="Rectangle 827"
                                            width="28"
                                            height="28"
                                            transform="translate(400 2736)"
                                            fill="none"
                                        />
                                    </g>
                                </svg>
                            </h1>
                        )}
                    </div>
                </div>
                <div className="MyClientActions__container">
                    {!past && (
                        <button
                            className="button-full-mid"
                            type="button"
                            onClick={() => {
                                setNewData({
                                    ProfileCoach: {
                                        showModalCoachingRoomCoach: true,
                                    },
                                    currentClient: item,
                                    ChatMessage: {
                                        GroupId: `${us.userId}-${item.userId}`,
                                    },
                                });
                            }}
                        >
                            {item?.pendingMessages > 0 && (
                                <div className="PendingMessage CoachNot">
                                    {item.pendingMessages > 99
                                        ? '+99'
                                        : item.pendingMessages}
                                </div>
                            )}
                            COACHING ROOM
                        </button>
                    )}
                    <div className="videosesions__container">
                        <p className="videosesions">
                            SESSIONS COMPLETED:{' '}
                            <span>{item?.sessionsCompleted}</span>
                        </p>
                        <p className="videosesions">
                            SESSIONS REMAINING:{' '}
                            <span>{item?.sessionsRemaining}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
