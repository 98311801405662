import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import './GetStarted.css';
import StartedImage from '../../../Assets/img/Dashboard/Join_the_movement2.jpg';

const StartLivingOola = (props) => {
    const ComponentToTrack = ({ isVisible }) => (
        <div className="join__countContainer">
            <div className="countContainer__people">
                <p className="paragraph-1">Over</p>
                <CountUp
                    className="countContainer__counter"
                    separator=","
                    end={isVisible ? 1000000 : 0}
                    duration={4}
                />
                <p className="paragraph-1">People</p>
            </div>
            <div className="countContainer__countries">
                <p className="paragraph-1">In</p>
                <CountUp
                    className="countContainer__counter"
                    end={isVisible ? 43 : 0}
                    duration={4}
                />
                <p className="paragraph-1">Countries</p>
            </div>
        </div>
    );

    return (
        <section className="join__container">
            <div className="join__textContainer">
                <div className="textContainer__image">
                    <img src={StartedImage} alt="join the global movement" />
                </div>
                <div className="textContainer__description">
                    <h3 className="heading-3">Partner with Oola </h3>
                    <p className="paragraph-2">
                        Do you have the desire to help others and be part of a
                        greater mission?
                        <br />
                        <br />
                        Become an Oola Ambassador or a Certified Oola Life Coach
                        and join the global movement. By partnering with us, you
                        can be part of a greater mission to empower people to
                        lead more fulfilling lives. Take your first step in
                        making a positive impact in the world today!
                        <br />
                    </p>
                    <button
                        className="button-full-big"
                        onClick={() =>
                            (window.location.href =
                                'https://myoola.oolalife.com/corporphan/enrollment/enrollmentconfigurationambassador')
                        }
                        type="button"
                    >
                        BECOME AN AMBASSADOR
                    </button>
                    <button
                        className="button-full-big two"
                        onClick={() =>
                            (window.location.href =
                                'https://myoola.oolalife.com/corporphan/product/355')
                        }
                        type="button"
                    >
                        BECOME A COACH
                    </button>
                </div>
            </div>
            <TrackVisibility offset={50}>
                <ComponentToTrack />
            </TrackVisibility>
        </section>
    );
};

export default StartLivingOola;
