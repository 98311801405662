import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../../../Context/AppContext';
import requestAxios from '../../../../../util/requestAxios';

import './CoachingRoomNoteBook.css';

function CoachingRoomNoteBook({ Coach, setShowVideoCall }) {
    const notes = [];
    const [newMessage, setNewMessage] = useState('');
    const elementRef = useRef();

    const { checkUserSession, getData, changeLoading, TimeToF12 } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();
    const { ProfileCoach, ChatMessage, currentClient } = getData();
    const [conversation, setConversation] = useState([]);
    useEffect(() => {
        if (ChatMessage) getChat();
    }, []);

    async function getChat() {
        let url = `SessionComments/getSessionComments/${ProfileCoach?.currentCoach?.userId}/${userId}`;

        if (ProfileCoach?.currentCoach == null)
            url = `SessionComments/getSessionComments/${userId}/${currentClient?.userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token: token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            const allConversation = data.conversation.map((item, i) => ({
                Date: item.date,
                Time: TimeToF12(item.time),
                Text: item.text,
                MyMessage: item.fromUserId == userId,
            }));
            setConversation(allConversation);
        }
    }
    async function sendMessage() {
        if (newMessage != '') {
            changeLoading(true);
            const objNewMessage = {
                CoachId: Number(ChatMessage.GroupId.split('-')[0]),
                ClientID: Number(ChatMessage.GroupId.split('-')[1]),
                Comment: newMessage,
            };

            let url = `SessionComments/InsertComment`;
            const { error, data } = await requestAxios({
                url: url,
                token: token,
                method: 'post',
                data: objNewMessage,
            });
            if (error) {
                console.log(error);
                changeLoading(false);
            } else {
                setNewMessage('');
                await getChat();
                changeLoading(false);
            }
        }
    }
    let pastDate = '';

    return (
        <div className="CoachingRoomNotebook__container">
            <div
                className={`CoachingRoomNotebookNotes__container OolaScroll ${
                    Coach && 'coach'
                }`}
            >
                {conversation.map((item, i) => {
                    let titleDate = '';
                    if (pastDate != item.Date) {
                        titleDate = item.Date;
                        pastDate = item.Date;
                    }
                    return (
                        <div
                            key={`CoachingRoomNotebookNoteItem__container${i}`}
                        >
                            {titleDate && (
                                <div className="TitleDateChat__container">
                                    <h1 className="TitleDateChat">
                                        {item.Date}
                                    </h1>
                                </div>
                            )}
                            <div
                                className="CoachingRoomNotebookNoteItem__container"
                                key={`CoachingRoomNotebookNoteItem__containerCoach${i}`}
                                ref={elementRef}
                            >
                                <h1>
                                    <span>{item.Date}</span> at {item.Time}
                                </h1>
                                <h2>{item.Text}</h2>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="OolaChatFootter__container ">
                <textarea
                    placeholder="Write a new session note…"
                    value={newMessage}
                    onChange={(input) => setNewMessage(input.target.value)}
                />
                <button onClick={() => sendMessage()}>
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
}

export default CoachingRoomNoteBook;
