import React from 'react';
import PropTypes from 'prop-types';

import './FormQuestions.css';

const FormQuestions = ({
    formSubmit,
    questionsSection,
    onValueChange,
    disabled,
}) => (
    <form className="customizeForm" onSubmit={formSubmit}>
        {questionsSection.questions?.map((question, index) => (
            <React.Fragment key={question.id}>
                <p className="subtitle-1">{`${index + 1}. ${question.text.toUpperCase()}`}</p>
                <div className="customizeForm__options">
                    {question.options?.map((answer) => (
                        <label
                            className="paragraph-3"
                            htmlFor={`${answer.id}`}
                            key={answer.id}
                        >
                            <input
                                type="radio"
                                id={`${answer.id}`}
                                name={`${question.id}`}
                                value={answer.id}
                                onChange={(e) => onValueChange(e, question.id)}
                            />
                            {answer.text}
                        </label>
                    ))}
                </div>
            </React.Fragment>
        ))}
        <div className="customizeForm__button">
            <button
                className="button-full-mid"
                type="submit"
                disabled={disabled}
            >
                {questionsSection.buttonText}
            </button>
        </div>
    </form>
);

FormQuestions.propTypes = {
    formSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    questionsSection: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default FormQuestions;
