import React, { useContext } from 'react';
import { AreasIcons } from '../../../../util/AreaSVG/index';
import AppContext from '../../../../Context/AppContext';
import SliceStep from './SliceStep';

const Wheel = (state) => {
    const { changeLoading, updateFreeTestData, checkFreeUserSession } =
        useContext(AppContext);

    const FreeTestData = checkFreeUserSession();
    const positionLabelIcons = [
        { x: '1px', y: '-5px' },
        { x: '1px', y: '-5px' },
        { x: '3px', y: '42px' },
        { x: '8px', y: '43px' },
        { x: '6px', y: '42px' },
        { x: '0px', y: '43px' },
        { x: '8px', y: '-3px' },
    ];
    const positionIcons = [
        { x: '69%', y: '8.45%' },
        { x: '91%', y: '39%' },
        { x: '80%', y: '77%' },
        { x: '46%', y: '90%' },
        { x: '12%', y: '71%' },
        { x: '6%', y: '35%' },
        { x: '34%', y: '6%' },
    ];
    function addZeroes(num, places) {
        const dec = String(num).split('.')[1];
        const len = dec && dec.length > places ? dec.length : places;
        return Number(num).toFixed(len);
    }
    const WheelR = (
        <div
            className="selectGoalWheel__container"
            style={{ height: '100%', marginTop: '-5%' }}
        >
            <svg
                className="selectGoalWheel__wheelStep2"
                viewBox="0 0 440 440"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g className="FreeWheel FreeTest">
                    <defs>
                        <g id="overlay">
                            <rect
                                x="219"
                                y="43"
                                height="200"
                                width="10"
                                fill="#F8F8F8"
                            />
                        </g>
                    </defs>
                    <mask id="wedge-maskFreeTest" fill="#F8F8F8">
                        <path
                            transform="translate(20, 20)"
                            d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                            fillRule="nonzero"
                        />
                    </mask>

                    {FreeTestData.Fs.map((item, i) => (
                        <SliceStep
                            key={i}
                            index={i}
                            Fs={item}
                            FullState={state.AllState}
                            allColors={1}
                        />
                    ))}
                    <circle
                        cx="220"
                        cy="220"
                        r="48"
                        fill="#F8F8F8"
                        strokeWidth="1"
                    />
                    <circle
                        cx="220"
                        cy="220"
                        r="40"
                        fill="#C9C9C9"
                        strokeWidth="1"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(56, 222, 220)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(107.13, 221, 221)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(159.55, 222, 221)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(209.97, 221, 222)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(262, 220, 222)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(310.91, 218, 223)"
                    />
                    <use
                        xlinkHref="#overlay"
                        transform="rotate(364.23, 245, 210)"
                    />
                    <circle
                        cx="220"
                        cy="220"
                        r="30"
                        fill="#FFF"
                        strokeWidth="1"
                    />

                    <text
                        className="titleQuestion"
                        // style={{
                        //     animation: `nospin 2.5s linear `,
                        // }}
                        x={'210px'}
                        y="48%"
                        fill="black"
                        fontSize="10"
                        transform="rotate(51.02, 220, 220)"
                    >
                        {`${'Total'} `}
                    </text>
                    <text
                        className="titleQuestion"
                        // style={{
                        //     animation: `nospin  2.5s linear `,
                        // }}
                        x="47.5%"
                        y="50%"
                        fill="black"
                        fontSize="10"
                        transform="rotate(51.02, 220, 220)"
                    >
                        Score
                    </text>
                    <text
                        className="titleQuestion"
                        // style={{
                        //     animation: `nospin 2.5s linear `,
                        // }}
                        x={
                            Math.round(FreeTestData.oolaScore, 0) < 100
                                ? '48.8%'
                                : '48.4%'
                        }
                        y="53%"
                        fill="black"
                        fontSize="20"
                        transform="rotate(51.02, 220, 220)"
                    >
                        {Math.round(FreeTestData.oolaScore, 0)}
                    </text>
                </g>
                <g className="FreeWheelIcons FreeTest">
                    {FreeTestData.Fs.map((item, i) => {
                        return (
                            <g
                                className={`FWIcons FWPosition${i}`}
                                key={'FIconWheelFreeTest' + item.id}
                                style={{
                                    transform: `translate(${positionIcons[i].x}, ${positionIcons[i].y}) `,
                                }}
                            >
                                <text
                                    className="FWIcons"
                                    fill="#B2B2B2"
                                    fontSize="10"
                                    strokeWidth="1px"
                                    x={positionLabelIcons[i].x}
                                    y={positionLabelIcons[i].y}
                                >
                                    {FreeTestData.Fs[i].name}
                                </text>
                                <foreignObject width="50" height="50">
                                    <img
                                        className="imgIconWheelF"
                                        src={AreasIcons['Area' + (i + 1)]}
                                    />
                                </foreignObject>
                            </g>
                        );
                    })}
                </g>
                <mask id="wedge-maskFW" fill="#F8F8F8">
                    <path
                        transform="translate(20, 20)"
                        d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                        fillRule="nonzero"
                    />
                </mask>
            </svg>
        </div>
    );

    return WheelR;
};
export default Wheel;
