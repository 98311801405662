import React, { useEffect } from 'react';
import { useNetwork } from '../../hooks/useNetwork';
import useScroll from '../../hooks/useScroll';
import './Network.css';

import oolaLogo from '../../Assets/oola_white_logo.svg';

const Network = () => {
    const [blockScroll, allowScroll] = useScroll();
    const connected = useNetwork();

    useEffect(() => {
        if (!connected) blockScroll();
        else allowScroll();
    }, [connected]);

    return (
        !connected && (
            <section className="network__container">
                <div className="network__wrapper">
                    <p className="paragraph-1">
                        We have detected you don&apos;t have internet
                        connection.
                    </p>
                    <p className="paragraph-3">Trying to reconnect...</p>
                </div>
            </section>
        )
    );
};

export default Network;
