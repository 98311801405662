import React from 'react';
import { keyframes } from 'styled-components';

import '../Question/Question.css';

const Slices = (props) => {
    function sum(total) {
        total =
            total === 0 || total == null
                ? 0
                : total * 0.005 + (total >= 70 ? 0.55 : 0.44);
        return String(isNaN(total) ? 0 : total);
    }
    function addZeroes(num, places) {
        const dec = String(num).split('.')[1];
        const len = dec && dec.length > places ? dec.length : places;
        return Number(num).toFixed(len);
    }

    const positionLables = [
        { x: '49%', y: '38%' },
        { x: '58%', y: '-16%' },
        { x: '22%', y: '-56.5%' },
        { x: '-32%', y: '-53%' },
        { x: '-63.5%', y: '-9%' },
        { x: '-48%', y: '42.5%' },
        { x: '1.5%', y: '63%' },
    ];

    const currF = props.currSlice === 8 ? 0 : props.currSlice - 1;

    let indexTemp =
        currF - props.index < 0
            ? 7 + (currF - props.index)
            : currF - props.index;
    indexTemp = indexTemp == null || Number.isNaN(indexTemp) ? 0 : indexTemp;
    const spin = 
    props.finalWheel?
    keyframes`
    0% { r:0; }
    33% { r:0; }
    66% { r:0; }
    100% { r: ${sum(props.FullState.Fs[indexTemp].TotalScore) * 170}; }
`:
    keyframes`
    0% { r:0; }
    100% { r: ${sum(props.FullState.Fs[indexTemp].TotalScore) * 170}; }
`;

    var styles = {
        animation: `${spin} ${props.finalWheel?"3s":"1s"}`,
    };
    const slices = (
        <g
            key={`sliceFinal${props.Fs.id}`}
            transform={
                props.Fs.id === 1
                    ? 'rotate(51.42,220,220)'
                    : `rotate(${-51.42 * (props.index - 1)},220,220)`
            }
            style={{
                opacity:
                    props.index === 0 || props.currSlice === 8 ? '1' : '0.4',
            }}
        >
            <g mask="url(#wedge-mask)">
                <circle
                    className="Slice "
                    cx="220"
                    cy="220"
                    r="170"
                    fill="transparent"
                >
                </circle>
                <circle
                    className="Slice "
                    cx="220"
                    cy="220"
                    r={sum(props.FullState.Fs[indexTemp].TotalScore) * 170}
                    fill={
                        props.Fs.TotalScore != null
                            ? props.FullState.Fs[indexTemp].color
                            : 'white'
                    }
                    style={styles}
                >
                    {' '}
                </circle>
                <text
                    className="titleQuestion"
                    x={positionLables[props.Fs.id - 1].x}
                    y={positionLables[props.Fs.id - 1].y}
                    fill="white"
                    fontSize="15"
                    strokeWidth="1px"
                    style={{
                        transform:
                            props.Fs.id === 1
                                ? ''
                                : `rotate(${0 + 51.42 * props.index}deg)`,
                    }}
                >
                    {props.Fs.TotalScore != null
                        ? addZeroes(
                              props.FullState.Fs[indexTemp].TotalScore / 10,
                              1
                          )
                        : ''}
                </text>
            </g>
        </g>
    );
    return slices;
};
export default Slices;
