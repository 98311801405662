import React from 'react';
import SliceStep2 from './SliceStep2';
import './Wheel.css';

const Wheel = state => {
    const lblPos = [
        '46.5%',
        '46.5%',
        '46.5%',
        '47.1%',
        '47.5%',
        '47.7%',
        '46.8%',
        '48%',
        '46.5%',
    ];
    function addZeroes(num, places) {
        const dec = String(num).split('.')[1];
        const len = dec && dec.length > places ? dec.length : places;
        return Number(num).toFixed(len);
    }
    const WheelR = (
        <div
            className="selectGoalWheel__container"
            style={{ height: '100%', marginTop: '-5%' }}
        >
            <svg
                className="selectGoalWheel__wheelStep2"
                viewBox="0 0 440 440"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    transform: `rotate(${String(-51.42)}deg)`,
                    animation: `spin ${state.confetti * 2.5}s linear `,
                }}
            >
                <defs>
                    <g id="overlay">
                        <rect
                            x="219"
                            y="20"
                            height="200"
                            width="10"
                            fill="#FFF"
                        />
                    </g>
                </defs>
                <mask id="wedge-mask" fill="white">
                    <path
                        transform="translate(20, 20)"
                        d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                        fillRule="nonzero"
                    />
                </mask>
                {state.AllState.Fs.map((item, i) => (
                    <SliceStep2
                        key={i}
                        index={i}
                        Fs={item}
                        FullState={state.AllState}
                        allColors={state.allColors}
                    />
                ))}
                <circle cx="220" cy="220" r="48" fill="white" strokeWidth="1" />
                <circle
                    cx="220"
                    cy="220"
                    r="40"
                    fill="#C9C9C9"
                    strokeWidth="1"
                />
                <use xlinkHref="#overlay" transform="rotate(56, 222, 220)" />
                <use
                    xlinkHref="#overlay"
                    transform="rotate(107.13, 220, 220)"
                />
                <use
                    xlinkHref="#overlay"
                    transform="rotate(159.55, 222, 220)"
                />
                <use
                    xlinkHref="#overlay"
                    transform="rotate(209.97, 220, 223)"
                />
                <use xlinkHref="#overlay" transform="rotate(262, 220, 222)" />
                <use
                    xlinkHref="#overlay"
                    transform="rotate(310.91, 218, 223)"
                />
                <use
                    xlinkHref="#overlay"
                    transform="rotate(364.23, 227, 208)"
                />
                <circle cx="220" cy="220" r="30" fill="#FFF" strokeWidth="1" />

                <text
                    className="titleQuestion"
                    style={{
                        animation: `nospin ${state.confetti * 2.5}s linear `,
                    }}
                    x={
                        state.AllState.CurrentF == -1 ||
                        state.allColors === true
                            ? '210px'
                            : lblPos[state.AllState.CurrentF + 1]
                    }
                    y="48%"
                    fill="black"
                    fontSize="10"
                    transform="rotate(51.02, 220, 220)"
                >
                    {`${
                        state.AllState.CurrentF == -1 ||
                        state.allColors === true
                            ? 'Total'
                            : state.AllState.Fs[state.AllState.CurrentF].name
                    } `}
                </text>
                <text
                    className="titleQuestion"
                    style={{
                        animation: `nospin ${state.confetti * 2.5}s linear `,
                    }}
                    x="47.5%"
                    y="50%"
                    fill="black"
                    fontSize="10"
                    transform="rotate(51.02, 220, 220)"
                >
                    Score
                </text>
                <text
                    className="titleQuestion"
                    style={{
                        animation: `nospin ${state.confetti * 2.5}s linear `,
                    }}
                    x="48%"
                    y="53%"
                    fill="black"
                    fontSize="20"
                    transform="rotate(51.02, 220, 220)"
                >
                    {state.AllState.CurrentF == -1 || state.allColors === true
                        ? Math.round(state.AllState.latestAssessmentScore, 0)
                        : addZeroes(
                              state.AllState.Fs[state.AllState.CurrentF].score,
                              1
                          )}
                </text>
            </svg>
        </div>
    );

    return WheelR;
};
export default Wheel;
