import React, { useEffect, useContext, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import FindCoachModal from './FindCoachModal/FindCoachModal';
import MyOolaCoachInfoModal from './MyOolaCoachInfoModal/MyOolaCoachInfoModal';
import InformationButton from '../../General/InformationButton/InformationButton';
import SelectedCoach from './SelectedCoach/SelectedCoach';
import './MyOolaCoach.css';

import LockSVG from '../../../Assets/img/LockIconFullWhite.svg';

export default (props) => {
    const { checkUserSession, changeLoading, changeModalWarningUpgrade } =
        useContext(AppContext);
    const [selectedCoach, setSelectedCoach] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [myOolaCoachInfo, setMyOolaCoachInfo] = useState(false);
    const [showPopover, setShowPopover] = useState(false);

    const us = checkUserSession();

    useEffect(() => {
        getDataCoach();
    }, [props.AllState.profile.coachId]);

    const getDataCoach = async () => {
        if (props.AllState.profile.hasCoach) {
            changeLoading(true);
            let url = `Coach/GetDirectoryListingInfo/${props.AllState.profile.coachId}`;
            // if (coachId) url = `FindCoach/GetCoachByUser`;
            const { error, data } = await requestAxios({
                url: url,
                token: us.token,
            });
            if (error) {
                console.log(error);
                changeLoading(false);
            } else {
                data.creditCardOnFile = props.AllState.profile.creditCardOnFile;
                setSelectedCoach(data);
                changeLoading(false);
            }
        }
    };

    const popover = (
        <Popover className="MyOolaCoachPoper__container">
            <Popover.Content
                className="MyOolaCoachPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span
                            onClick={() => {
                                changeModalWarningUpgrade(true);
                            }}
                        >
                            {' '}
                            Oola Lifestyle Framework
                        </span>{' '}
                        to unlock this one-on-one accountability coaching
                        feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <div
            className={`MyOolaCoach__Container ${
                !props.AllState.profile.coachId > 0 && 'selected'
            }`}
        >
            <p className="heading-5 BlueDarkText MyCoachDashboardTitle">
                MY OOLA COACH
            </p>
            {props.AllState.profile.coachId > 0 ? (
                <SelectedCoach selectedCoach={selectedCoach} />
            ) : (
                <>
                    <div className="MyCoachDashboard">
                        <h2>
                            WANT TO GET RESULTS FASTER?
                            {us.userTypeId === 4 ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={popover}
                                    show={showPopover}
                                    rootClose
                                    delay={{ show: 0, hide: 5000 }}
                                >
                                    <div
                                        onMouseEnter={() => {
                                            setShowPopover(true);
                                        }}
                                        onMouseLeave={() => {
                                            setShowPopover(false);
                                        }}
                                    >
                                        <img
                                            className="imgLockMyGoals"
                                            alt="imgLock"
                                            src={LockSVG}
                                        />
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <InformationButton
                                    onClick={() => setMyOolaCoachInfo(true)}
                                    size="16px"
                                    className="MyCoachDashboard-questionButton"
                                    color="#D86B59"
                                    popoverText="Click for more info"
                                />
                            )}
                        </h2>
                        <p>
                            Do you know what most successful people have in
                            common? They have a coach! If you&apos;re seeking an
                            opportunity to take your success to the next level,
                            then this is for you! Get one-on-one accountability
                            coaching with a Certified Oola Life coach.
                        </p>
                        <button
                            className={`btnFindCoach button-full-mid ${
                                us?.userTypeId === 4 && 'disabledButton'
                            }`}
                            onClick={() => setShowModal(true)}
                            disabled={us?.userTypeId === 4}
                            type="button"
                        >
                            FIND A COACH
                        </button>
                    </div>
                </>
            )}
            {showModal && (
                <FindCoachModal
                    show={showModal}
                    setShowModal={setShowModal}
                    coachId={props.AllState.profile.coachId}
                />
            )}
            {myOolaCoachInfo && (
                <MyOolaCoachInfoModal
                    setMyOolaCoachInfo={setMyOolaCoachInfo}
                    selectedCoach={selectedCoach}
                />
            )}
        </div>
    );
};
