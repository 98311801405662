import React, { useEffect, useState, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import configData from '../../../config.json';
import Collapsable from './Collapsable';
import OolaGlobalCompPlanPDF from '../../../Assets/PDF/OolaGlobalCompPlan.pdf';
import './FrequentQuestion.css';

const FrequentQuestion = () => {
    const match = useRouteMatch('/learnmore/faq');
    const ref = useRef(null);
    const [freqQuestion, ChangeFreqQuestion] = useState([
        { question: '', answer: '' },
    ]);

    function getURL() {
        return configData.SERVER_URL;
    }

    useEffect(() => {
        if (match?.isExact) window.scrollTo(0, ref.current.offsetTop);
    }, []);

    useEffect(() => {
        const url = `${getURL()}home/GetFrequentQuestions`;
        axios
            .get(url)
            .then((result) => {
                const tempFreqQuestion = [];
                result.data.forEach((itemX) => {
                    const item = itemX;
                    item.open = false;
                    item.answer = item.answer.replace(
                        '#PDFTheOpportunity#',
                        ` ${OolaGlobalCompPlanPDF}" download target="blank`
                    );
                    tempFreqQuestion.push(item);
                });

                ChangeFreqQuestion(tempFreqQuestion);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    return (
        <section ref={ref} className="faq">
            <div className="faq__wrapper">
                <h3 className="heading-1">FAQ&apos;s</h3>
                {freqQuestion.map((item) => (
                    <Collapsable
                        Title={item.question}
                        Description={item.answer}
                        key={`Colap${item.id}`}
                        id={item.id}
                        open={item.open}
                        AllState={freqQuestion}
                        ChangeState={ChangeFreqQuestion}
                        forceUpdate={forceUpdate}
                    />
                ))}
            </div>
        </section>
    );
};

export default FrequentQuestion;
