export default {
    FsJourneySelect: 0,
    FsJour: [
        { id: 1, name: 'FITNESS', color: '#15c285' },
        { id: 2, name: 'FINANCE', color: '#abd86d' },
        { id: 3, name: 'FAMILY', color: '#fbbf53' },
        { id: 4, name: 'FIELD', color: '#f26636' },
        { id: 5, name: 'FAITH', color: '#ec0f47' },
        { id: 6, name: 'FRIENDS', color: '#700460' },
        { id: 7, name: 'FAITH', color: '#022b7a' },
    ],
    FsJourney: [
        {
            id: 0,
            name: (
                <section className="StartYourJourney__TitleContainer">
                    <h5 className="StartYourJourney__subtitle">
                        <span>LIVE</span> DIFFERENTLY.
                    </h5>
                </section>
            ),
            text: (
                <>
                    <b>
                        Living your Oola Life means working towards a life that
                        seeks balance and growth in the 7 key areas of health
                        and wellness.
                    </b>{' '}
                    Gain the energy to find joy in each and every day. Feel
                    secure in your finances and never fight about money again.
                    Do the work you love. Feel deep connections with friends and
                    family. Confidently find your purpose on this planet.{' '}
                    <b>That's Oola.</b>
                </>
            ),
            color: '#2c414c',
        },
        {
            id: 1,
            name: 'FITNESS',
            text: 'How did our lives get so unhealthy? Obesity, diabetes and heart disease is on the rise. Stress, anxiety, depression and mental health disorders are climbing. And worst of all, our kids are suffering, too. But, it doesn’t have to be this way. Countless people have used the Oola to lose weight, feel better, look better and live better and you can too.',
            color: '#15c285',
        },
        {
            id: 2,
            name: 'FINANCE',
            text: 'Paycheck to paycheck: that’s how 63% of households live. Nearly 7 in 10 Americans can’t handle a $1,000 crisis and debt has handcuffed the majority of people from reaching their dreams. Through the Oola Framework, you will learn proven tactics to become debt-free, earn extra income, save for emergencies, give more generously and put together a solid financial plan for your future.',
            color: '#abd86d',
        },
        {
            id: 3,
            name: 'FAMILY',
            text: '53% of marriages end in divorce. Toxic relatives, unhealthy boundaries, and even addiction is putting a heavy strain on our families. And regarding our kids, peer pressure seems to get there first. Today’s families feel less connected and more discouraged.  Oola will teach you how to grow and build the family life that you’ve always wanted no matter where you are today.',
            color: '#fbbf53',
        },
        {
            id: 4,
            name: 'FIELD',
            text: 'Spending a third of our day at a job we hate . . . building someone else’s future instead of our own . . . missing out on what we’re truly passionate about. It’s no surprise that 70% of Americans are disillusioned with their careers. Whether you want to be a well-balanced stay at home parent, a massive entrepreneur, or both, we have the process to help you build your perfect career path.',
            color: '#f26636',
        },
        {
            id: 5,
            name: 'FAITH',
            text: 'There’s a unique calling on your life. And while Oola will never tell you what to believe, one thing is true: you can’t have a fulfilling life without a higher purpose that only faith will reveal. Like many others, you can use the Oola Framework as a stepping stone to grow your faith walk no matter where you are today; finding peace and assurance along your journey.',
            color: '#ec0f47',
        },
        {
            id: 6,
            name: 'FRIENDS',
            text: 'It’s crazy we know, but all too often, friendships lead to stress. In fact, 80% of people say they endure toxic friendships . . . but why? Mean girls, jealous coworkers, online bullying, office politics . . . those aren’t friends and that’s not Oola. A life of less drama, deep connections and lasting good memories with your friends is a click away.',
            color: '#700460',
        },
        {
            id: 7,
            name: 'FUN',
            text: 'In a culture infatuated with “busy,” what used to bring us happiness somehow got replaced with deadlines, stress and overwhelm. Good times get kicked down the road so often that we often lose sight of what brings us joy. This it’s what is all about at the end…the fun you had and the memories you created with the people you love. It’s time to live your best life.',
            color: '#022b7a',
        },
    ],
    FsJourneySVG: [
        {
            id: 0,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1_00000161589499714717724960000009347580444654558627_">
                        <path
                            className="st0Ben0"
                            d="M1606.2,470.2c0-2.2,1.2-3.7,2.8-4.4V-9H-6.3C-6.7,9.8-8.1,28.6-9,47.4V1359h1618V585
		C1598.3,547.8,1606.1,508.1,1606.2,470.2z"
                        />
                    </g>
                    <path
                        className="st1Ben0"
                        d="M-6.2-10.6c-0.3,19-1.8,37.9-2.7,56.9c32.7,28.8,75.5,46.3,122.4,46.3V52.5C63.8,52.5,19.9,27.5-6.2-10.6z"
                    />
                    <path
                        className="st1Ben0"
                        d="M310,244.1v40.2c71.8,0,130.2-58.4,130.2-130.2H400C400,203.7,359.6,244.1,310,244.1z"
                    />
                    <path
                        className="st1Ben0"
                        d="M454.9,426.9c-81.4,0-147.7,66.3-147.7,147.7c0,81.4,66.3,147.7,147.7,147.7c81.4,0,147.7-66.3,147.7-147.7
	C602.6,493.2,536.4,426.9,454.9,426.9z M570.7,574.6c0,63.8-51.9,115.7-115.7,115.7c-63.8,0-115.7-51.9-115.7-115.7
	c0-63.8,51.9-115.7,115.7-115.7C518.8,458.9,570.7,510.8,570.7,574.6z"
                    />
                    <path
                        className="st1Ben0"
                        d="M800,41.1v37.5c46.3,0,84,37.7,84,84h37.5C921.5,95.7,867,41.1,800,41.1z"
                    />
                    <path
                        className="st1Ben0"
                        d="M1136.1,246.7c-46.3,0-84-37.7-84-84v0h-37.5v0c0,67,54.5,121.5,121.5,121.5V246.7z"
                    />
                    <path
                        className="st1Ben0"
                        d="M1606,482.4c-34.5-12.2-59.3-45.2-59.3-83.9v0h-39.7v0c0,59.9,41.2,110.4,96.8,124.6
	C1604.3,509.6,1605.4,495.9,1606,482.4z"
                    />
                    <g id="Layer_2_00000065039233622863314740000013685454702957444021_">
                        <text
                            transform="matrix(1 0 0 1 288.1443 646.5176)"
                            className="st2Ben0 st3Ben0 st4Ben0"
                        >
                            why{' '}
                        </text>
                        <text
                            transform="matrix(1 0 0 1 634.9343 646.5176)"
                            className="st5Ben0 st3Ben0 st4Ben0"
                        >
                            mind
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1027.4866 646.5176)"
                            className="st2Ben0 st3Ben0 st4Ben0"
                        >
                            {' '}
                            and
                        </text>
                        <text
                            transform="matrix(1 0 0 1 239.3083 838.5693)"
                            className="st2Ben0 st3Ben0 st4Ben0"
                        >
                            body nutrition
                        </text>
                    </g>
                </svg>
            ),
        },
        {
            id: 1,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1_00000163074388004684736230000015367122768771462309_">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben1"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2_000001227a00559300533652040000002401275652185042318_">
                        <text
                            transform="matrix(1 0 0 1 157.3 236.394)"
                            className="st1Ben1 st2Ben1 st3Ben1"
                        >
                            cover your{' '}
                        </text>
                        <text
                            transform="matrix(1 0 0 1 792.3 236.394)"
                            className="st4Ben1 st2Ben1 st3Ben1"
                        >
                            deficiencies
                        </text>
                        <rect
                            x="163.3"
                            y="321.6"
                            className="st5Ben1"
                            width="1276.7"
                            height="188.8"
                        />
                        <text
                            transform="matrix(1 0 0 1 183.3317 357.5988)"
                            className="st1Ben1 st6Ben1 st7Ben1"
                        >
                            Oola Mind and Body contains high-potency levels of
                            all{' '}
                        </text>
                        <text
                            transform="matrix(1 0 0 1 365.3561 427.5988)"
                            className="st1Ben1 st6Ben1 st7Ben1"
                        >
                            these essential nutrients—enough said.
                        </text>
                        <rect
                            x="161.6"
                            y="479.9"
                            className="st1Ben1"
                            width="1276.7"
                            height="445.7"
                        />
                        <rect
                            x="161.6"
                            y="478.4"
                            className="st4Ben1"
                            width="1276.7"
                            height="60.5"
                        />
                        <rect
                            x="161.6"
                            y="609"
                            className="st8Ben1"
                            width="1276.7"
                            height="60.5"
                        />
                        <rect
                            x="161.6"
                            y="738.6"
                            className="st8Ben1"
                            width="1276.7"
                            height="60.5"
                        />
                        <rect
                            x="161.6"
                            y="870.7"
                            className="st8Ben1"
                            width="1276.7"
                            height="60.5"
                        />
                        <text
                            transform="matrix(1 0 0 1 240.763 521.6664)"
                            className="st1Ben1 st9Ben1 st10Ben1"
                        >
                            Men (19+)
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 586.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            47%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 651.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            53%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 716.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            94%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 781.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            71%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 846.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            41%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 298.163 911.6664)"
                            className="st6Ben1 st10Ben1"
                        >
                            19%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 725.1821 522.3467)"
                            className="st1Ben1 st9Ben1 st10Ben1"
                        >
                            Nutrient
                        </text>
                        <text
                            transform="matrix(1 0 0 1 714.6821 587.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Vitamin A
                        </text>
                        <text
                            transform="matrix(1 0 0 1 712.0822 652.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Vitamin C
                        </text>
                        <text
                            transform="matrix(1 0 0 1 713.2821 717.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Vitamin D
                        </text>
                        <text
                            transform="matrix(1 0 0 1 716.9821 782.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Vitamin E
                        </text>
                        <text
                            transform="matrix(1 0 0 1 715.4821 847.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Vitamin K
                        </text>
                        <text
                            transform="matrix(1 0 0 1 761.5822 912.3467)"
                            className="st6Ben1 st10Ben1"
                        >
                            Zinc
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1139.9354 521.6665)"
                            className="st1Ben1 st9Ben1 st10Ben1"
                        >
                            Women (19+)
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 586.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            39%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 651.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            45%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 716.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            97%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 781.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            85%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 846.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            62%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 1227.1354 911.6665)"
                            className="st6Ben1 st10Ben1"
                        >
                            18%
                        </text>
                        <text
                            transform="matrix(1 0 0 1 158.6736 983.3271)"
                            className="st1Ben1 st9Ben1 st11Ben1"
                        >
                            (Deficiencies in North America by the USDA)
                        </text>
                    </g>
                </svg>
            ),
        },
        {
            id: 2,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben2"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2">
                        <text transform="matrix(1 0 0 1 270.9627 478.394)">
                            <tspan x="0" y="0" className="st1Ben2 st2Ben2 st3Ben2">
                                Feed Your Mind
                            </tspan>
                            <tspan
                                x="805.4"
                                y="0"
                                className="st4Ben2 st2Ben2 st3Ben2"
                            >
                                {' '}
                                with
                            </tspan>
                            <tspan
                                x="-146.8"
                                y="140"
                                className="st4Ben2 st2Ben2 st3Ben2"
                            >
                                the Seven Top Nootropics
                            </tspan>
                        </text>
                        <rect
                            x="137.8"
                            y="702.6"
                            className="st5Ben2"
                            width="1324.4"
                            height="319"
                        />
                        <text transform="matrix(1 0 0 1 178.8818 738.5983)">
                            <tspan x="0" y="0" className="st4Ben2 st6Ben2 st7Ben2">
                                Oola Mind features the seven top
                                nootropics—cognitive{' '}
                            </tspan>
                            <tspan
                                x="-30.7"
                                y="70"
                                className="st4Ben2 st6Ben2 st7Ben2"
                            >
                                enhancers that can help improve focus and
                                concentration.{' '}
                            </tspan>
                            <tspan
                                x="20.8"
                                y="140"
                                className="st4Ben2 st6Ben2 st7Ben2"
                            >
                                This blend is formulated to help support mental
                                clarity,{' '}
                            </tspan>
                            <tspan
                                x="166.6"
                                y="210"
                                className="st4Ben2 st6Ben2 st7Ben2"
                            >
                                alertness, and overall cognitive function.*
                            </tspan>
                        </text>
                        <circle
                            className="st8Ben2"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <path
                            className="st9Ben2"
                            d="M846.4,279.7v-24.2c0-10.7,3.6-21.1,10.1-29.5c8.8-11.4,14-25.6,14-41.1c0-37.2-30.1-67.3-67.3-67.3
		c-34.4,0-62.8,25.8-66.8,59.1c-0.5,3.8-1.4,7.5-2.9,11l-14.3,34.3c-0.9,2.1-0.6,4.6,0.7,6.5c1.3,1.9,3.5,3.1,5.8,3l10.2-0.1v27.6
		c0,3.8,3.1,6.9,6.9,6.9h20.7c1.8,0,3.6,0.7,4.9,2s2,3.1,2,4.9l-0.1,6.8"
                        />
                        <path
                            className="st9Ben2"
                            d="M770.7,217.9c14,16,42.8,18.9,59.3,2.2c13.2-13.4,12.8-36.4-0.4-49.8c-10.6-10.7-29.7-10.8-38.5-1
		c-9,9.9-8.4,24.2,0.3,32"
                        />
                        <path
                            className="st9Ben2"
                            d="M836.1,158.6c-14-16-42.8-18.9-59.3-2.2c-13.2,13.4-12.8,36.4,0.4,49.8c10.6,10.7,29.7,10.8,38.5,1
		c9-9.9,8.4-24.2-0.3-32"
                        />
                    </g>
                </svg>
            ),
        },
        {
            id: 3,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1_00000079446397663077706650000002252388164841744050_">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben3"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2_00000101103311126759570040000013829679491950863748_">
                        <text
                            transform="matrix(1 0 0 1 292.3494 478.394)"
                            className="st1Ben3 st2Ben3 st3Ben3"
                        >
                            Balance Your Body
                        </text>
                        <text
                            transform="matrix(1 0 0 1 348.7494 618.394)"
                            className="st4Ben3 st2Ben3 st3Ben3"
                        >
                            with Adaptogens
                        </text>
                        <rect
                            x="137.8"
                            y="702.6"
                            className="st5Ben3"
                            width="1324.4"
                            height="319"
                        />
                        <text
                            transform="matrix(1 0 0 1 150.1317 738.598)"
                            className="st6Ben3"
                        >
                            <tspan x="0" y="0" className="st4Ben3 st7Ben3 st8Ben3">
                                Oola Body contains the seven top-performing
                                adaptogens
                            </tspan>
                            <tspan
                                x="-13"
                                y="60"
                                className="st4Ben3 st7Ben3 st8Ben3"
                            >
                                that are known for their powerful antioxidant
                                and balancing
                            </tspan>
                            <tspan
                                x="82"
                                y="120"
                                className="st4Ben3 st7Ben3 st8Ben3"
                            >
                                properties, which can help the body adapt to
                                stress
                            </tspan>
                            <tspan
                                x="338.2"
                                y="180"
                                className="st4Ben3 st7Ben3 st8Ben3"
                            >
                                and improve overall health.*
                            </tspan>
                        </text>
                        <circle
                            className="st9Ben3"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <rect
                            x="2513.4"
                            y="689.5"
                            className="st5Ben3"
                            width="0"
                            height="3.9"
                        />
                        <path
                            className="st10Ben3"
                            d="M779,251.5l67.2-12.3c0.7-0.1,1.5-0.2,2.3-0.2c6.9,0,12.4,5.6,12.4,12.4s-5.6,12.4-12.4,12.4h-68.4
		c-3.4,0-6.2-2.8-6.2-6.2C773.9,254.6,776.1,252,779,251.5z"
                        />
                        <path
                            className="st10Ben3"
                            d="M808.1,142.6c0,6.9-5.6,12.4-12.4,12.4s-12.4-5.6-12.4-12.4v-9.3c0-6.9,5.6-12.4,12.4-12.4
		s12.4,5.6,12.4,12.4V142.6z"
                        />
                        <path
                            className="st10Ben3"
                            d="M860.9,226.5h-12.4l-17.8-12.7c-2.4-1.7-3.9-4.6-3.9-7.6v-24.6c0-5.8-4.3-10.9-10.1-12
		c-13.9-2.7-28.2-2.7-42.1,0c-5.8,1.1-10,6.2-10,12v24.6c0,3-1.5,5.8-3.9,7.6l-17.8,12.7h-12.4"
                        />
                        <path
                            className="st10Ben3"
                            d="M795.7,248.4l-50.6-9.3c-0.7-0.1-1.5-0.2-2.3-0.2c-6.9,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4h37.3"
                        />
                        <path
                            className="st10Ben3"
                            d="M814.3,186.1v19.3c0,7.8,1.1,15.6,3.1,23.2l4.1,15.1"
                        />
                        <path
                            className="st10Ben3"
                            d="M777,186.1v19.3c0,7.8-1.1,15.6-3.1,23.2l-4.1,15.1"
                        />
                    </g>
                </svg>
            ),
        },
        {
            id: 4,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben4"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2">
                        <text transform="matrix(1 0 0 1 167.2884 478.394)">
                            <tspan x="0" y="0" className="st1Ben4 st2Ben4 st3Ben4">
                                Simplify{' '}
                            </tspan>
                            <tspan
                                x="478.6"
                                y="0"
                                className="st4Ben4 st2Ben4 st3Ben4"
                            >
                                Your Nutrition
                            </tspan>
                        </text>
                        <rect
                            x="137.8"
                            y="558.1"
                            className="st5Ben4"
                            width="1324.4"
                            height="452.6"
                        />
                        <text transform="matrix(1 0 0 1 235.0815 594.0978)">
                            <tspan x="0" y="0" className="st4Ben4 st6Ben4 st7Ben4">
                                When it comes to your body’s nutritional needs,
                                you{' '}
                            </tspan>
                            <tspan
                                x="-66.9"
                                y="70"
                                className="st4Ben4 st6Ben4 st7Ben4"
                            >
                                shouldn’t have to guess! Our Oola Mind and Body
                                combo{' '}
                            </tspan>
                            <tspan
                                x="-88.1"
                                y="140"
                                className="st4Ben4 st6Ben4 st7Ben4"
                            >
                                simplifies your nutritional protocol and takes
                                the guesswork{' '}
                            </tspan>
                            <tspan
                                x="24.8"
                                y="210"
                                className="st4Ben4 st6Ben4 st7Ben4"
                            >
                                out of nutrition. We provide high-quality
                                essential{' '}
                            </tspan>
                            <tspan
                                x="-43.2"
                                y="280"
                                className="st4Ben4 st6Ben4 st7Ben4"
                            >
                                ingredients so you can trust that you&apos;re
                                getting the best{' '}
                            </tspan>
                            <tspan
                                x="315.5"
                                y="350"
                                className="st4Ben4 st6Ben4 st7Ben4"
                            >
                                nutrition for your body.
                            </tspan>
                        </text>
                        <circle
                            className="st8Ben4"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <g id="health_care_00000065791361252071696040000001672151042377406381_">
                            <path
                                className="st9Ben4"
                                d="M742.8,187l0-21.5c0-3.6-2.9-6.5-6.5-6.5l0,0c-3.6,0-6.5,2.9-6.5,6.5v59.2c0,2.1,0.8,4.1,2.2,5.7l28,36.3
			h32.3c0-6.5,0,0,0-6.5c0-19.6-4.3-28-21.5-36.6L755,199.4c-1.5-3.2-5.4-4.6-8.6-3.1l0,0c-3.2,1.5-4.6,5.4-3.1,8.6l16.7,36"
                            />
                            <path
                                className="st9Ben4"
                                d="M850.4,187l0-21.5c0-3.6,2.9-6.5,6.5-6.5l0,0c3.6,0,6.5,2.9,6.5,6.5v59.2c0,2.1-0.8,4.1-2.2,5.7l-28,36.3
			h-32.3c0-6.5,0,0,0-6.5c0-19.6,4.3-28,21.5-36.6l15.7-24.2c1.5-3.2,5.4-4.6,8.6-3.1l0,0c3.2,1.5,4.6,5.4,3.1,8.6l-16.7,36"
                            />
                            <path
                                className="st9Ben4"
                                d="M796.6,145.5c1-2,2.3-3.8,3.8-5.5c4.1-4.4,9.5-6.8,15.4-6.8c5.8,0,11.3,2.4,15.4,6.8
			c4.1,4.4,6.4,10.2,6.4,16.4c0,6.2-2.4,13.9-5.3,19.2c-6.6,12.2-26.4,32.2-35.6,39.4c-9.2-7.2-29-27.2-35.6-39.4
			c-2.9-5.4-5.3-13-5.3-19.2c0-6.2,2.3-12,6.4-16.4c4.1-4.4,9.6-6.8,15.4-6.8c5.8,0,11.3,2.4,15.4,6.8
			C794.3,141.6,795.6,143.5,796.6,145.5z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            id: 5,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben5"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2">
                        <text transform="matrix(1 0 0 1 335.9285 478.394)">
                            <tspan x="0" y="0" className="st1Ben5 st2Ben5 st3Ben5">
                                Trust{' '}
                            </tspan>
                            <tspan
                                x="333.4"
                                y="0"
                                className="st4Ben5 st2Ben5 st3Ben5"
                            >
                                the Quality
                            </tspan>
                        </text>
                        <rect
                            x="137.8"
                            y="558.1"
                            className="st5Ben5"
                            width="1324.4"
                            height="452.6"
                        />
                        <text transform="matrix(1 0 0 1 148.1069 594.0978)">
                            <tspan x="0" y="0" className="st4Ben5 st6Ben5 st7Ben5">
                                You can be sure that the ingredients used in
                                Oola products{' '}
                            </tspan>
                            <tspan
                                x="29.3"
                                y="70"
                                className="st4Ben5 st6Ben5 st7Ben5"
                            >
                                have been formulated and tested for quality by
                                the Mind{' '}
                            </tspan>
                            <tspan
                                x="55.3"
                                y="140"
                                className="st4Ben5 st6Ben5 st7Ben5"
                            >
                                and Body Lab and verified by independent
                                third-party{' '}
                            </tspan>
                            <tspan
                                x="171"
                                y="210"
                                className="st4Ben5 st6Ben5 st7Ben5"
                            >
                                testing.{' '}
                            </tspan>
                            <tspan
                                x="344.4"
                                y="210"
                                className="st4Ben5 st8Ben5 st7Ben5"
                            >
                                What you read on the label is what
                            </tspan>
                            <tspan
                                x="391.9"
                                y="280"
                                className="st4Ben5 st8Ben5 st7Ben5"
                            >
                                you get— guaranteed.
                            </tspan>
                        </text>
                        <circle
                            className="st9Ben5Ben5"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <polygon
                            className="st1Ben5"
                            points="780.1,131.2 755.9,200.3 731.8,131.2 714.9,131.2 714.9,242.1 731.4,242.1 731.4,177.6 755.7,242.1 
		780.1,176.7 780.1,176.9 796.7,176.9 796.7,131.2 	"
                        />
                        <rect
                            x="780.1"
                            y="192.9"
                            className="st1Ben5"
                            width="16.5"
                            height="49.1"
                        />
                        <rect
                            x="780.1"
                            y="241.8"
                            className="st1Ben5"
                            width="16.5"
                            height="1.8"
                        />
                        <rect
                            x="714.9"
                            y="241"
                            className="st1Ben5"
                            width="16.5"
                            height="2.5"
                        />
                        <text
                            transform="matrix(1 0 0 1 713.0368 271.1646)"
                            className="st4Ben5 st8Ben5 st10Ben5"
                        >
                            lab certified
                        </text>
                        <path
                            className="st4Ben5"
                            d="M866.6,191.1c-3-2.6-7.4-4.8-13.2-6.5c5.3-1.6,9.4-3.9,12.1-6.9c4.3-4.6,6.4-10.5,6.4-17.9
		c0-9.5-3.2-16.8-9.5-21.8c-5.7-4.5-13.5-6.7-23.4-6.7h-26v45.7h17.4v-29.6h6.7c6,0,10.3,1.2,12.8,3.5c2.9,2.6,4.3,6,4.3,10.3
		c0,4.7-1.5,8.4-4.6,11.4c-3.1,3-7.4,4.4-12.8,4.4h-1.6v16h1.6c6.4,0,11.3,1.9,14.7,5.6c3.3,3.5,5,7.9,5,13.2
		c0,4.7-1.5,8.5-4.4,11.4c-2.9,2.9-7.2,4.4-13,4.4h-8.7V193h-17.4v50.6h28.2c10.2,0,18.2-2.7,23.9-8.1c6.3-5.9,9.4-13.8,9.4-23.8
		C874.5,202.7,871.8,195.9,866.6,191.1z"
                        />
                    </g>
                </svg>
            ),
        },
        {
            id: 6,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben6"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2">
                        <text transform="matrix(1 0 0 1 416.8245 478.394)">
                            <tspan x="0" y="0" className="st1Ben6 st2Ben6 st3Ben6">
                                No{' '}
                            </tspan>
                            <tspan
                                x="161.5"
                                y="0"
                                className="st4Ben6 st2Ben6 st3Ben6"
                            >
                                “Pixie Dust”
                            </tspan>
                        </text>
                        <rect
                            x="137.8"
                            y="558.1"
                            className="st5Ben6"
                            width="1324.4"
                            height="452.6"
                        />
                        <text transform="matrix(1 0 0 1 192.6079 594.0978)">
                            <tspan x="0" y="0" className="st4Ben6 st6Ben6 st7Ben6">
                                At Oola, we don&apos;t “pixie dust” our
                                products. Instead, we{' '}
                            </tspan>
                            <tspan
                                x="68.2"
                                y="70"
                                className="st4Ben6 st6Ben6 st7Ben6"
                            >
                                formulate Oola Mind and Body supplements with{' '}
                            </tspan>
                            <tspan
                                x="-50"
                                y="140"
                                className="st4Ben6 st6Ben6 st7Ben6"
                            >
                                high-potency ingredients to ensure optimal
                                absorption and{' '}
                            </tspan>
                            <tspan
                                x="36.1"
                                y="210"
                                className="st4Ben6 st6Ben6 st7Ben6"
                            >
                                superior bioavailability, giving you maximum
                                results.
                            </tspan>
                        </text>
                        <circle
                            className="st8Ben6"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <g>
                            <path
                                className="st9Ben6"
                                d="M817.8,129.5c1.2,2.4,3.1,4.2,5.3,5.2l-11.4,33.4c-4.7-1.9-10-2.4-15.2-1.3l-5.6-16.4
			c1.3-0.8,2.4-1.9,3.2-3.4c2-4,0.3-8.9-3.7-10.9c-4-2-8.9-0.3-10.9,3.7c-2,4-0.3,8.9,3.7,10.9c1.1,0.5,2.2,0.8,3.3,0.8l5.7,16.6
			c-0.6,0.2-1.1,0.5-1.7,0.8c-3.7,1.8-6.7,4.4-9.1,7.4l-15.1-5.1c1.6-7.9-2.9-16-10.8-18.7c-8.5-2.9-17.8,1.7-20.7,10.2
			c-2.9,8.5,1.7,17.8,10.2,20.7c7.7,2.6,16.1-0.9,19.7-7.9l14.1,4.8c-3.3,6.4-4.1,13.9-1.3,21.3l-40.8,20.1
			c-4.4-6.6-13.4-9.3-20.8-5.6c-8.1,4-11.4,13.7-7.4,21.8c4,8.1,13.8,11.5,21.9,7.5c7.3-3.6,10.8-12,8.4-19.5l40.8-20.1
			c3.3,5.6,9.1,10,15.4,11.8l-7.5,22.1c-1.7-0.1-3.5,0.1-5.1,1c-4.9,2.4-6.9,8.3-4.5,13.1c2.4,4.9,8.3,6.8,13.1,4.5
			c4.9-2.4,6.8-8.3,4.5-13.1c-0.8-1.7-2.1-3.1-3.7-4l7.7-22.7c4.7,0.5,9.5-0.3,14.1-2.6c1.2-0.6,2.3-1.2,3.3-2l16.4,17.2
			c-5.1,6.3-4.8,15.5,0.9,21.5c6.2,6.5,16.6,6.8,23.1,0.5c6.5-6.2,6.8-16.6,0.5-23.1c-5.7-5.9-14.7-6.7-21.2-2.1l-16.2-17
			c5.7-5.6,8.4-13.6,7.5-21.5l12.4-2.2c0.6,1.4,1.5,2.7,2.9,3.7c3.7,2.6,8.8,1.7,11.4-2c2.6-3.7,1.7-8.8-2-11.4
			c-3.7-2.6-8.8-1.7-11.4,2c-0.7,1-1.1,2.1-1.3,3.2l-12.9,2.3c-0.4-1.4-0.9-2.8-1.6-4.1c-2.2-4.5-5.6-8.1-9.6-10.6l11.7-34.4
			c1.9,0.1,3.8-0.3,5.6-1.2c5.7-2.8,8-9.6,5.2-15.3c-2.8-5.7-9.6-8-15.3-5.2C817.4,117,815,123.8,817.8,129.5z M783.6,141.8
			c0.9-1.8,3-2.5,4.8-1.6c1.8,0.9,2.5,3,1.6,4.8c-0.9,1.8-3,2.5-4.8,1.6C783.4,145.8,782.7,143.6,783.6,141.8z M746.5,179.2
			c-6.1-2.1-9.4-8.8-7.3-14.9c2.1-6.1,8.8-9.4,14.9-7.3c6.1,2.1,9.4,8.8,7.3,14.9C759.3,178,752.6,181.3,746.5,179.2z M791.4,247.1
			c1.3,2.6,0.2,5.7-2.4,7c-2.6,1.3-5.7,0.2-7-2.4c-1.3-2.6-0.2-5.7,2.4-7C786.9,243.5,790.1,244.5,791.4,247.1z M854.5,233.1
			c4.5,4.7,4.3,12.1-0.4,16.6c-4.7,4.5-12.1,4.3-16.6-0.4c-4.5-4.7-4.3-12.1,0.4-16.6C842.6,228.3,850,228.4,854.5,233.1z
			 M850,181.3c1.6,1.1,2,3.4,0.9,5c-1.1,1.6-3.4,2-5,0.9c-1.6-1.1-2-3.4-0.9-5C846.2,180.5,848.4,180.1,850,181.3z M728.4,241.2
			c-5.8,2.9-12.9,0.5-15.7-5.3c-2.9-5.8-0.5-12.9,5.3-15.7s12.9-0.5,15.7,5.3C736.6,231.3,734.2,238.4,728.4,241.2z M821.4,182.9
			c5.3,10.7,0.9,23.6-9.8,28.9c-10.7,5.3-23.6,0.9-28.9-9.8c-5.3-10.7-0.9-23.6,9.8-28.9C803.2,167.8,816.1,172.2,821.4,182.9z
			 M834.2,121.4c1.7,3.4,0.3,7.5-3.1,9.2s-7.5,0.3-9.2-3.1c-1.7-3.4-0.3-7.5,3.1-9.2C828.4,116.6,832.5,118,834.2,121.4z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            id: 7,
            svg: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1350">
                    <g id="Layer_1">
                        <rect
                            x="-9"
                            y="-9"
                            className="st0Ben7"
                            width="1618"
                            height="1368"
                        />
                    </g>
                    <g id="Layer_2">
                        <text transform="matrix(1 0 0 1 616.263 478.394)">
                            <tspan x="0" y="0" className="st1Ben7 st2Ben7 st3Ben7">
                                No{' '}
                            </tspan>
                            <tspan
                                x="161.5"
                                y="0"
                                className="st4Ben7 st2Ben7 st3Ben7"
                            >
                                risk
                            </tspan>
                        </text>
                        <rect
                            x="137.8"
                            y="558.1"
                            className="st5Ben7"
                            width="1324.4"
                            height="452.6"
                        />
                        <text transform="matrix(1 0 0 1 156.6323 594.0978)">
                            <tspan x="0" y="0" className="st4Ben7 st6Ben7 st7Ben7">
                                We’re confident in the outstanding quality of
                                our products;{' '}
                            </tspan>
                            <tspan
                                x="25.2"
                                y="70"
                                className="st4Ben7 st6Ben7 st7Ben7"
                            >
                                so, we want to give you a chance to try the
                                &quot;Out with the{' '}
                            </tspan>
                            <tspan
                                x="23.7"
                                y="140"
                                className="st4Ben7 st6Ben7 st7Ben7"
                            >
                                old, in with the BLUE” experience. Substitute
                                your regular{' '}
                            </tspan>
                            <tspan
                                x="-16.2"
                                y="210"
                                className="st4Ben7 st6Ben7 st7Ben7"
                            >
                                vitamins with Oola Mind and Body for 30 days—if
                                you&apos;re not{' '}
                            </tspan>
                            <tspan
                                x="55.3"
                                y="280"
                                className="st4Ben7 st6Ben7 st7Ben7"
                            >
                                completely blown away, we will offer you a full
                                refund.
                            </tspan>
                            <tspan
                                x="421.9"
                                y="350"
                                className="st4Ben7 st6Ben7 st7Ben7"
                            >
                                No questions asked.
                            </tspan>
                        </text>
                        <circle
                            className="st8Ben7"
                            cx="793.7"
                            cy="199.9"
                            r="125.1"
                        />
                        <text transform="matrix(1 0 0 1 719.5576 189.2583)">
                            <tspan x="0" y="0" className="st1Ben7 st9Ben7 st10Ben7">
                                RISK
                            </tspan>
                            <tspan
                                x="-1.7"
                                y="64.3"
                                className="st1Ben7 st9Ben7 st11Ben7"
                            >
                                FREE
                            </tspan>
                        </text>
                    </g>
                </svg>
            ),
        },
    ],
};
