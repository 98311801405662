import AliceCarousel from 'react-alice-carousel';
import OolaChat from '../../CoachingRoom/Components/OolaChat/OolaChat';
import MyClients from '../../MyClients/MyClients';

export default ({ arrClients, past }) => {
    return arrClients.length > 0 ? (
        <>
            <div className="DashboardCoachTabsContentMobile">
                <AliceCarousel
                    mouseTracking
                    items={arrClients.map((item, i) => (
                        <MyClients key={`MyClientsMobile${i}`} item={item} past={past} />
                    ))}
                    // responsive={responsive}
                    controlsStrategy="alternate"
                    renderPrevButton={() => <div />}
                    renderNextButton={() => <div />}
                />
            </div>
            <div className="DashboardCoachTabsContentNoMobile">
                {arrClients.map((item, i) => (
                    <MyClients key={`MyClients${i}`} item={item} past={past}/>
                ))}
            </div>
        </>
    ) : (
        <div className="DashboardCoachTabsEmpty__container">
            <div>
                <h2>YOUR {past&&"PAST"} CLIENTS WILL LOAD HERE</h2>
            </div>
        </div>
    );
};
