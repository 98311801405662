import React,  { useContext } from 'react';
import configData from '../../../../config.json';
import iconUser from '../../../../Assets/img/userIcon.jpg';
import PurchaseSession from '../PurchaseSession/PurchaseSession';
import AppContext from '../../../../Context/AppContext';
import { CoachingRoomModalClient } from '../../../DashboardCoach/CoachingRoom/Components';
import './SelectedCoach.css';

export default ({ selectedCoach }) => {
    const { setNewData, getData } = useContext(AppContext);
    const { ProfileCoach } = getData();
    return (
        <>
            <div className="SelectedCoach__container">
                <div className="CoachInfo">
                    <div
                        alt="Profile"
                        className="image__SelectedCoach CoachInfo"
                        style={{
                            '--imgProfileSelectedCoachUrl': `url(${
                                selectedCoach.profilePictureFileName != '' &&
                                selectedCoach.profilePictureFileName != null
                                    ? `${
                                          configData.SERVER_URL_IMG
                                      }/media/profile/${
                                          selectedCoach.profilePictureFileName
                                      }?${Math.random()}`
                                    : iconUser
                            })`,
                        }}
                    />
                    <div className="CoachInfoText">
                        <h1>{`${selectedCoach.firstName} ${selectedCoach.lastName}`}</h1>
                        <h2>Certified Oola Life Coach</h2>
                    </div>
                    <div className="CoachInfoText">
                        <button
                            className="btnGoToCoachingRoom"
                            onClick={() =>
                                setNewData({
                                    ProfileCoach: {
                                        showModalCoachingRoomClient: true,
                                        currentCoach: selectedCoach,
                                    },
                                })
                            }
                            type="button"
                        >
                            {selectedCoach?.pendingMessages > 0 && (
                                <div className="PendingMessage">
                                    {selectedCoach.pendingMessages > 99
                                        ? '+99'
                                        : selectedCoach.pendingMessages}
                                </div>
                            )}
                            ENTER COACHING ROOM
                        </button>
                    </div>
                </div>
                <div className="SelectedCoachSessionInfo__container">
                    <div className="SelectedCoachSessionInfo__item">
                        <h1>{ProfileCoach?.currentClient?.sessionsCompleted}</h1>
                        <h2>
                            <span> SESSIONS</span> <br /> COMPLETED
                        </h2>
                    </div>
                    <div className="SelectedCoachSessionInfo__item">
                        <h1>{ProfileCoach?.currentClient?.sessionsRemaining}</h1>
                        <h2>
                            <span> SESSIONS</span> <br />
                            REMAINING
                        </h2>
                    </div>
                    <button
                        className="btnFindCoach button-full-mid"
                        onClick={() =>
                            setNewData({
                                ProfileCoach: {
                                    showModalPurchaseSession: true,
                                },
                            })
                        }
                        type="button"
                    >
                        ADD MORE SESSIONS
                    </button>
                </div>
            </div>
            <CoachingRoomModalClient />
            {ProfileCoach?.showModalPurchaseSession && (
                <PurchaseSession selectedCoach={selectedCoach} />
            )}
        </>
    );
};
