import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import SelectedGoal7 from './TemplateAction/SelectedGoal7';
import SelectedAction from './TemplateAction/SelectedAction';
import configData from '../../../config.json';
import ModalError from '../../Step2/SelectGoals/TemplateGoals/ModalError';
import './EstablishActionSection.css';

const EstablishActionSection = (props) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [showError, setShowError] = useState(false);
    const [showMy7Top, setshowMy7Top] = useState(false);
    const [errorObj, setErrorObj] = useState({
        ModalErrorTitle: 'You are almost there',
        ModalErrorDesc: 'You can add only 15 actions.',
    });
    function addAction() {
        const AllState = { ...props.AllState };
        if (AllState.Action.length < 15) {
            if (AllState.Action[AllState.Action.lenght - 1] !== '') {
                AllState.Action.push({ text: '' });
                props.AllState.changeData(AllState);
            }
        } else {
            setErrorObj({
                ModalErrorTitle: 'You are almost there',
                ModalErrorDesc: 'You can add only 15 actions.',
            });
            setShowError(true);
        }
        return true;
    }
    const Actions =
        props.AllState == null
            ? []
            : props.AllState.Action.filter((x) => x.text !== '');

    function SetActions() {
        const AllState = { ...props.AllState };
 
        if (Actions.length > 2) {
            const objPost = {
                userId,
                actions: Actions,
            };
            const url = `${configData.SERVER_URL}Actions`;
            axios({
                method: 'post',
                url,
                headers: { Authorization: `Bearer ${token}` },
                data: objPost,
            })
                .then((res) => {
                    props.updateIndexStep3(1);
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    alert(err);
                });
        }
    }
    const MyTop7Section = (
        <section className="EstablishActionMyTop7__content">
            <div className="TitleActionSteps">MY TOP 7:</div>
            <h6 className="DescriptionActionSteps">These are your top 7 goals </h6>
            {props.AllState.My7.map((item, index) => (
                <SelectedGoal7 key={`SelectedGoal7_${item.id}`} goal={item} GoalIndex={index} mobile={props.mobile}/>
            ))}
        </section>
    );
    const obj = (
        <section className="EstablishAction__content">
            {!props.mobile && MyTop7Section}
            <section className="EstablishDailyAction__content">
                <div className="TitleActionSteps">
                    ESTABLISH 3 DAILY ACTION STEPS
                </div>
                <h6 className="DescriptionActionSteps">
                    Define 3 or more action steps that you will take in the next
                    24 hours towards your Top 7 goals
                </h6>
              <h6 className="LinkActionSteps" onClick={()=>setshowMy7Top(true)}>
                    THESE ARE YOUR TOP 7 GOALS
                </h6>
                {props.AllState.Action.map((item, index) => (
                    <SelectedAction
                    key={`SelectedAction${index}`}
                        Action={item}
                        ActionIndex={index}
                        AllState={props.AllState}
                        mobile={props.mobile}
                    />
                ))}
                <div className="divAdd" onClick={() => addAction()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        style={{ transform: 'rotate(45deg)' }}
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                    ADD MORE
                </div>
                <div
                    className="d-flex flex-row-reverse"
                    style={{ width: '100%', paddingTop: `50px` }}
                >
                    <div className="p-2">
                        <button
                            className="btnStep1"
                            style={{
                                backgroundColor: Actions.length < 3 && 'gray',
                            }}
                            onClick={() => SetActions()}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </section>
            <ModalError
                show={showError}
                setShowError={setShowError}
                ModalErrorTitle={errorObj.ModalErrorTitle}
                ModalErrorDesc={errorObj.ModalErrorDesc}
            />
            <Modal
                show={showMy7Top}
                onHide={() => setshowMy7Top(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
                size="lg"
                centered
            >
                <Modal.Header><button type="button" className="backMyTop7btn" onClick={()=>setshowMy7Top(false)}>{"< BACK"}</button></Modal.Header>

                <Modal.Body>{MyTop7Section}</Modal.Body>
            </Modal>
        </section>
    );

    return obj;
};

export default EstablishActionSection;
