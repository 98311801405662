import React, { useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import FrequentQuestion from './FrequentQuestion/FrequentQuestion';
import FooterSection from './FooterSection/FooterSection';
import LoginHeader from '../Common/LoginHeader/LoginHeader';
import config from '../../config.json';
import YourOpportunityHeader from '../../Assets/img/YourOpportunity_header .jpg';

const App = () => {
    const { webAlias } = useParams();
    const match = useRouteMatch('/learnmore/faq');

    useEffect(() => {
        if (!match?.isExact) window.scrollTo(0, 0);
    }, []);

    function goExigo() {
        if (webAlias)
            window.location.href = `${config.enrollmentURL}/${webAlias}/enrollment`;
        else
            window.location.href = `${config.enrollmentURL}/corporphan/enrollment`;
    }

    return (
        <>
            <LoginHeader
                isDarkLogo={false}
                enrollButtonText="ENROLL NOW"
                goEnroll={goExigo}
                webAlias={webAlias}
            />
            <div className="content__TheOpportunity">
                <svg viewBox="0 0 100 38">
                    <image
                        className="imgTheOpportunitySVG"
                        href={YourOpportunityHeader}
                        y="-13"
                    />
                    <rect className="rectSVGIMGFilter" />

                    <g className="imgTheOpportunitySVGText_content">
                        <g className="imgTheOpportunitySVGText_Desktop">
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="35"
                                y="32"
                            >
                                Frequently Asked Questions
                            </text>
                        </g>
                        <g className="imgTheOpportunitySVGText_Mobile">
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="62"
                                y="24"
                            >
                                Frequently
                            </text>
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="62"
                                y="29"
                            >
                                Asked
                            </text>
                            <text
                                fill="white"
                                className="TheOpportunity__imgTextVan"
                                x="62"
                                y="34"
                            >
                                Questions
                            </text>
                        </g>
                        <line
                            x1="88.5"
                            x2="88.5"
                            y1="24"
                            y2="35.2"
                            stroke="#78ccf6"
                            strokeWidth=".4px"
                        />
                        <line
                            x1="78"
                            x2="88.5"
                            y1="35"
                            y2="35"
                            stroke="#78ccf6"
                            strokeWidth=".4px"
                        />
                    </g>
                </svg>
            </div>
            <br></br>
            <br></br>
            <FrequentQuestion />
            <br></br>
            <br></br>
            <FooterSection />
        </>
    );
};

export default App;
