import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ShareOptionsModal from '../../../Share/ShareOptionsModal/ShareOptionsModal';
import './ModalOolaWheel.css';

const Achievements = ({
    AllState,
    showModalWheel,
    setShowModalWheel,
    svgWheel,
}) => {
    const [activeShare, setActiveShare] = useState(false);
    const [showModal, setShowModal] = useState(showModalWheel);

    function hideModal() {
        // setShowModal(false);
        setShowModalWheel(false);
    }
    const AchievementsR = (
        <div>
            <Modal
                show={showModalWheel}
                onHide={() => hideModal()}
                aria-labelledby="example-custom-modal-styling-title"
                centered
                id="modalBadget"
                className="modalBadget"
                style={{ width: '100%' }}
            >
                <Modal.Header className="HeaderModalOolaWheel">
                    <button type="button" style={{ visibility: 'hidden' }}>
                        &times;
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            hideModal();
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            cursor: 'pointer',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body className="bodyModalOolaWheel">
                    <div
                        className="BadgetTitle TitleWheelModal"
                        style={{
                            width: '100%',
                            fontWeight: 'normal',
                            paddingLeft: '0px',
                        }}
                    >
                        Oola Wheel
                    </div>

                    <div
                        className="BadgeDate"
                        style={{
                            width: '100%',
                            fontWeight: 'normal',
                            paddingLeft: '0px',
                        }}
                    >
                        {AllState.AllState.completionDate}
                    </div>
                    <section className="svgWheelModal__container">
                        {svgWheel}
                    </section>
                    <div
                        className="  ModalScore"
                        style={{
                            width: '100%',
                            fontWeight: 'normal',
                            paddingLeft: '0px',
                        }}
                    >
                        SCORE
                        <br />
                        <span className="ModalScore_score">
                            {AllState.AllState.score}
                        </span>
                    </div>

                    <div className="btnShareWheel__container">
                        <button
                            type="button"
                            className="btnBadgetShare"
                            onClick={() => setActiveShare(true)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.823px"
                                height="21.14px"
                                viewBox="0 0 19.823 21.145"
                            >
                                <g
                                    id="Group_991"
                                    transform="translate(-1275 -322.142)"
                                >
                                    <g
                                        id="Group_990"
                                        transform="translate(-400 148.307)"
                                    >
                                        <g
                                            id="upload"
                                            transform="translate(1675 173.835)"
                                        >
                                            <g
                                                id="Group_987"
                                                transform="translate(4.625)"
                                            >
                                                <g id="Group_986">
                                                    <path
                                                        id="Path_520"
                                                        d="M138.407 5.512L133.781.226a.659.659 0 0 0-.994 0l-4.625 5.286a.66.66 0 0 0 .5 1.1h2.638V15.2a.661.661 0 0 0 .661.661h2.643a.661.661 0 0 0 .661-.661V6.608h2.643a.66.66 0 0 0 .5-1.1z"
                                                        className="cls-1"
                                                        fill="white"
                                                        transform="translate(-127.998)"
                                                    />
                                                </g>
                                            </g>
                                            <g
                                                id="Group_989"
                                                transform="translate(0 14.537)"
                                            >
                                                <g id="Group_988">
                                                    <path
                                                        id="Path_521"
                                                        d="M33.18 352v3.965H18.643V352H16v5.286a1.321 1.321 0 0 0 1.322 1.322H34.5a1.32 1.32 0 0 0 1.322-1.322V352z"
                                                        fill="white"
                                                        className="cls-1"
                                                        transform="translate(-16 -352)"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Share
                        </button>
                        <div className="divBadgetOolaimg Black-Logo" />
                    </div>
                </Modal.Body>
            </Modal>
            {activeShare && (
                <ShareOptionsModal
                    webAlias=""
                    setActiveShare={setActiveShare}
                    imageUrl={AllState.AllState.socialMediaImageUrl}
                    text={AllState.AllState.socialMediaMessage}
                />
            )}
        </div>
    );
    return AchievementsR;
};
export default Achievements;
