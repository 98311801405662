import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookieStorage } from './useCookieStorage';
import requestAxios from '../util/requestAxios';
import getUserInfo from '../util/getUserInfo';
import config from '../config.json';

const useGlobalState = () => {
    const history = useHistory();
    const [activeCount, setActiveCount] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [reloadClients, setReloadClients] = useState(false);
    const [dataAll, setData] = useState({});
    const [showModalWarningUpgrade, setShowModalWarningUpgrade] =
        useState(false);
    const [showCoachingPayment, setShowCoachingPayment] = useState(false);

    const [
        userSession,
        setUserSession,
        updateCookieStorage,
        deleteCookieStorage,
    ] = useCookieStorage('userSession', {});
    const [freeUserSession, setFreeUserSession] = useState(
        JSON.parse(localStorage.getItem('freeUser'))
    );

    // LOGIN With Token
    const loginWithToken = async (token) => {
        const { error, data } = await requestAxios({
            url: 'Login/LoginShopifyCustomer',
            method: 'post',
            data: {
                CustomerToken: token,
            },
        });
        // debugger;
        if (error) {
            return error.response;
        }
        const { Shopify } = getData();
        if (Shopify?.isShopify) {
            dataAll.isShopify = Shopify.isShopify;
        }
        setUserSession(data);
        // debugger;
        return data.userTypeId === 4 ? -1 : data.userStepId;
    };

      // LOGIN
    const login = async (phone, password, loginType) => {
        const userInfo = getUserInfo();
        console.log('UserInfo====', userInfo);
        console.log('userAgent====', window.navigator.userAgent);

        const { error, data } = await requestAxios({
            url: 'Login',
            method: 'post',
            data: {
                userId: phone,
                password,
                loginType,
                ...userInfo,
            },
        });
        if (error) {
            return error.response;
        }
        const { Shopify } = getData();
        if (Shopify?.isShopify) {
            dataAll.isShopify = Shopify.isShopify;
        }
        setUserSession(data);
        return data.userTypeId === 4 ? -1 : data.userStepId;
    };

    // LogOut
    const logOut = () => {
        deleteCookieStorage();
        if(!userSession.isShopify)
        history.push('/login');
        else
        history.push('/Shopify/ShopifyLogin');

    };

    const upgradeAccountRedirect = () => {
        window.open(config.UPGRADE_URL, '_blank');
    };

    //  Check User Session
    const checkUserSession = () => {
        if (userSession.token) {
            return userSession;
        }
        if (userSession.PublicProfile != null) {
            return { PublicProfile: userSession.PublicProfile };
        }
        return false;
    };
    const checkFreeUserSession = () => freeUserSession;

    //  Update userStepId
    const updateUserStepId = async () => {
        const { error, data, status } = await requestAxios({
            url: `customer/GetCurrentUserStep/${userSession.userId}`,
            token: userSession.token,
        });
        if (error) {
            if (status === 401) {
                logOut();
            } else {
                window.alert('Error with the server, we are fixing it');
            }
        } else {
            if (data.displayCoachingPayment)
                setShowCoachingPayment(data.displayCoachingPayment);
            
            updateCookieStorage({
                ...userSession,
                displayCoachCertificationTutorial: data.displayCoachCertificationTutorial,
                userStepId: data.userStepId,
                assessmentId: data.assessmentId,
                level: data.level,
                browser: getUserInfo().browser,
            });
        }

        return data;
    };
    const updateAdditionalCourse = async (additionalCourse) => {
        updateCookieStorage({
            ...userSession,
            additionalCourse: additionalCourse,
        });
    };
    const updateActivePrograms = async (ActivePrograms) => {
        updateCookieStorage({
            ...userSession,
            ActivePrograms: ActivePrograms,
        });
    };
    //  Update userStepId
    const updatePublicProfile = async (data) => {
        updateCookieStorage({
            ...userSession,
            PublicProfile: data,
        });

        return true;
    };
    const changeReloadClients = (loading) => setReloadClients(loading);
    const changeLoading = (loading) => setShowLoading(loading);
    const changeModalWarningUpgrade = (show) => {
        setShowModalWarningUpgrade(show);
        if (show) window.open(config.UPDGRADEACCOUNTLINK, '_blank');
    };

    //FreeTest Store
    const updateFreeTestData = (data) => {
        localStorage.setItem('freeUser', JSON.stringify(data));
        setFreeUserSession(data);
    };
    const setNewData = (newData) => {
        const newobj = { ...dataAll };
        for (const property in newData) {
            if (Array.isArray(newData[property])) {
                newobj[property] = [...newData[property]];
            } else {
                newobj[property] = { ...dataAll[property], ...newData[property] };
                if (newData[property] == null) {
                    delete newobj[property];
                }
            }
        }
        setData(newobj);
    };

    const getData = () => ({ ...dataAll });

    const TimeToF12 = (time) => {
        let timeArr = String(time).split(':');
        let hour = Number(timeArr[0]);
        let timeText = timeArr[1];
        let newTime = '';
        if (hour > 12) {
            newTime = `${hour - 12}:${timeText} p.m.`;
        } else {
            newTime = `${hour}:${timeText} a.m.`;
        }
        return newTime;
    };

    var dg = [
        'zero',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
    ];
    var tn = [
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
    ];
    var tw = [
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety',
    ];

    function NumbertoWords(s) {
        s = s.toString();
        s = s.replace(/[\, ]/g, '');
        if (s != parseFloat(s)) return 'not a number';
        var x = s.indexOf('.');
        if (x == -1) x = s.length;
        if (x > 15) return 'too big';
        var n = s.split('');
        var str = '';
        var sk = 0;
        for (var i = 0; i < x; i++) {
            if ((x - i) % 3 == 2) {
                if (n[i] == '1') {
                    str += tn[Number(n[i + 1])] + ' ';
                    i++;
                    sk = 1;
                } else if (n[i] != 0) {
                    str += tw[n[i] - 2] + ' ';
                    sk = 1;
                }
            } else if (n[i] != 0) {
                str += dg[n[i]] + ' ';
                if ((x - i) % 3 == 0) str += 'hundred ';
                sk = 1;
            }
        }
        if (x != s.length) {
            var y = s.length;
            str += 'point ';
            for (var i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
        }
        return str.replace(/\s+/g, ' ');
    }
    const requestAPI = async (params) => {
        var result = await requestAxios(params);
        const { error } = result;
        if (error != '') {
            if (error.response.status === 409) {
                setNewData({
                    modalError: {
                        show: true,
                        Desc: error.response.data,
                        Title: 'Error',
                    },
                });
            }
        }
        return result;
    };
    return {
        login,
        loginWithToken,
        logOut,
        requestAPI,
        checkUserSession,
        checkFreeUserSession,
        updateUserStepId,
        activeCount,
        setActiveCount,
        changeLoading,
        showLoading,
        updatePublicProfile,
        updateAdditionalCourse,
        updateFreeTestData,
        upgradeAccountRedirect,
        changeModalWarningUpgrade,
        showModalWarningUpgrade,
        updateActivePrograms,
        setNewData,
        getData,
        TimeToF12,
        NumbertoWords,
        reloadClients,
        changeReloadClients,
        showCoachingPayment,
    };
};

export default useGlobalState;
