import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Box } from '@material-ui/core';
import RecommendedCourses from './sections/RecommendedCourses/RecommendedCourses';
import PurchasedCourses from './sections/PurchasedCourses/PurchasedCourses';
import AppContext from '../../../../../Context/AppContext';
import configData from '../../../../../config.json';
import './NewModules.css';

const NewModules = (props) => {
    const { checkUserSession, updateAdditionalCourse, changeLoading } =
        useContext(AppContext);
    const [listNewCourse, setListNewCourse] = useState([]);
    const [listActiveCourse, setListActiveCourse] = useState([]);
    const [activeCourse, setActiveCourse] = useState({});
    const [init, setInit] = useState(false);

    useEffect(() => {
        changeLoading(true);
        const url = `${configData.SERVER_URL}AdditionalCourse/GetCourses`;
        const { token, additionalCourse, displayCoachCertificationTutorial } =
            checkUserSession();
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                const arrItems = result.data;

                if (arrItems.filter((x) => x.purchased === true).length === 1)
                    setActiveCourse(
                        arrItems.filter((x) => x.purchased === true)[0]
                    );
                setListActiveCourse(
                    arrItems.filter((x) => x.purchased === true)
                );
                if (additionalCourse != null) {
                    setActiveCourse(additionalCourse);
                }
                if (displayCoachCertificationTutorial) {
                    setActiveCourse(arrItems.filter((x) => x.id === 1009)[0]);
                }
                changeLoading(false);
                setInit(true);
            })
            .catch((error) => {
                changeLoading(false);
                setInit(false);
                console.log(error);
            });
    }, []);

    if (!init) {
        return <div className="init_loading">Loading...</div>;
    }

    return (
        <div className="NewModules__Container">
            <section className="paragraph-3 ModuleDescription">
                <p className="TitleContentElearning">Additional Programs</p>
                Accelerate your journey to your Oola life with these additional
                courses.
            </section>
            {listActiveCourse.length > 0 && (
                <h1 className="NewModules__title">PURCHASED</h1>
            )}
            {listActiveCourse.length > 1 && (
                <Autocomplete
                    disablePortal
                    options={listActiveCourse}
                    getOptionLabel={(option) => option.title}
                    inputValue={activeCourse.title ? activeCourse.title : ''}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                        updateAdditionalCourse(newValue != null && newValue);
                        setActiveCourse(newValue != null && newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select your program"
                            value={activeCourse.title}
                            onChange={(input) =>
                                setActiveCourse({
                                    ...activeCourse,
                                    title: input.target.value,
                                })
                            }
                        />
                    )}
                    renderOption={(propsx) => (
                        <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...propsx}
                        >
                            {propsx.isCoachCertification && (
                                <h5>Certifications</h5>
                            )}
                            <img
                                loading="lazy"
                                width="80"
                                height="40"
                                src={`${configData.SERVER_URL_IMG}${propsx.imageUrl}`}
                                srcSet={`${configData.SERVER_URL_IMG}${propsx.imageUrl}`}
                                alt="Module"
                            />
                            &nbsp;&nbsp;
                            {propsx.title}
                        </Box>
                    )}
                />
            )}
            <br />
            {listActiveCourse
                .filter((x) => x.id === activeCourse.id)
                .map((item) => (
                    <PurchasedCourses
                        key={`RecommendedCoursesId${item.id}`}
                        item={item}
                    />
                ))}
        </div>
    );
};

export default NewModules;
