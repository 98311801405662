/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './CoachMyBioEdit.css';

const CoachMyBioEdit = ({ profileInformation }) => {
    const [testimonial, setTestimonial] = useState(true);
    const [sessionCost, setSessionCost] = useState(1);
    const [bioData, setBioData] = useState();

    useEffect(() => {
        setBioData(profileInformation);
    }, [profileInformation]);

    return (
        <>
            <div className="myBioEdit-title-container">
                <h3 className="heading-5">MY BIO</h3>
            </div>

            <div style={{ width: '100%' }}>
                <div className="myBioEdit-form">
                    <Form.Group controlId="listingPageHeader">
                        <Form.Label>
                            Client welcome phrase (max 150 characters)
                        </Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="textarea"
                            rows={2}
                            required
                            defaultValue={bioData?.listingPageHeader}
                            maxLength="150"
                        />
                    </Form.Group>
                    <Form.Group controlId="myOffer">
                        <Form.Label>
                            With me as your coach, you will... (complete this
                            sentence below - max 300 characters)
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                className="form-input-1"
                                as="textarea"
                                rows={3}
                                required
                                defaultValue={bioData?.myOffer}
                                maxLength="300"
                                placeholder='With me as your coach, you will...'
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="aboutMe">
                        <Form.Label>About Me: (max 750 characters)</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="textarea"
                            rows={5}
                            required
                            defaultValue={bioData?.aboutMe}
                            maxLength="750"
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="sessionCost"
                        style={{ marginBottom: '20px' }}
                    >
                        <Form.Label style={{ marginBottom: '0' }}>
                            {bioData?.rateQuestion}
                        </Form.Label>
                        <p style={{ fontSize: '12px', marginBottom: '10px' }}>
                            {bioData?.rateQuestionDisclaimer}
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '30px',
                            }}
                        >
                            {bioData?.sessionRates?.map((sessionData) => (
                                <div
                                    className="myBioEdit-check-input"
                                    key={sessionData.itemCode}
                                >
                                    <label
                                        className="containerOption"
                                        htmlFor={sessionData.itemCode}
                                    >
                                        {sessionData.description}
                                        <input
                                            type="radio"
                                            name="sessionCost"
                                            id={sessionData.itemCode}
                                            value={sessionData.itemCode}
                                            defaultChecked={
                                                sessionData.selected
                                            }
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Group>
                    {testimonial && (
                        <div className="myBioEdit-testimonial">
                            <Form.Group
                                controlId="text"
                                className="myBioEdit-testimonial-left"
                            >
                                <Form.Label>Client testimonial:</Form.Label>
                                <Form.Control
                                    className="form-area-1"
                                    as="textarea"
                                    rows={4}
                                    defaultValue={bioData?.testimonial?.text}
                                />
                            </Form.Group>
                            <div className="myBioEdit-testimonial-right">
                                <Form.Group controlId="fullName">
                                    <Form.Label>Client name:</Form.Label>
                                    <Form.Control
                                        className="form-input-1"
                                        type="text"
                                        defaultValue={
                                            bioData?.testimonial?.fullName
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="location">
                                    <Form.Label>Client City/State:</Form.Label>
                                    <Form.Control
                                        className="form-input-1"
                                        type="text"
                                        defaultValue={
                                            bioData?.testimonial?.location
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    )}
                    <div className="myBioEdit-check-input">
                        <label
                            className="containerOption"
                            htmlFor="testimonial"
                        >
                            I have written permission from this client to use
                            their testimonial.
                            <input
                                type="radio"
                                name="clientPermission"
                                id="testimonial"
                                value
                                defaultChecked
                                onClick={() => setTestimonial(true)}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                    <div
                        className="myBioEdit-check-input"
                        style={{ paddingTop: '5px' }}
                    >
                        <label
                            className="containerOption"
                            htmlFor="noTestimonial"
                        >
                            I do not have a testimonial at this time.
                            <input
                                type="radio"
                                name="clientPermission"
                                id="noTestimonial"
                                value={false}
                                onClick={() => setTestimonial(false)}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CoachMyBioEdit;
