import React from 'react';
import { useHistory } from 'react-router-dom';
import configData from '../../../../../../../config.json';
import LockSVG from '../../../../../../../Assets/img/LockIconFullWhite.svg';

const RecommendedProducts = ({ item }) => {
    
    const url = configData.SERVER_URL_IMG;
    return (
        <section
            className="NewModulesCard__Container"
            aria-hidden="true"
            onClick={() => {
                window.open(item.urlProduct, '_blank');
            }}
        >
            <div className="NewModulesCard__image CarruselProducts">
                <img
                    alt="imgProduct"
                    src={`${item.dashboardImageUrl.replace(
                        '${config.SERVER_URL_IMG}',
                        url
                    )}`}
                />
            </div>
        </section>
    );
};

export default RecommendedProducts;
