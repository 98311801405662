import Steps from '../Components/Steps';
import HomePage from '../Components/HomePage';
import HomePage2 from '../Components/HomePage2';
import LearnMore from '../Components/LearnMore';
import FrequentlyAskedQuestions from '../Components/LearnMore/FrequentlyAskedQuestions';
import Dashboard from '../Components/Dashboard';
import DashboardCoach from '../Components/DashboardCoach/DashboardCoach';
import NonDashboard from '../Components/NonDashboard';
import FreeTest from '../Components/FreeTest';
import Assessment from '../Components/Dashboard/ELearningBoard/Assesment/Assesment';
import AssessmentMyPP from '../Components/Dashboard/ELearningBoard/AssesmentMyPP/AssesmentMyPP';
import AssesmentNewModules from '../Components/Dashboard/ELearningBoard/AssesmentNewModule/AssesmentNewModule';
import Phase2Welcome from '../Components/Phase2Welcome/Phase2Welcome';
import Phase3Welcome from '../Components/Phase2Welcome/Phase3Welcome';
import Level2Welcome from '../Components/Phase2Welcome/Level2Welcome';
import NewModuleWelcome from '../Components/Phase2Welcome/NewModuleWelcome';
import MidYearReviewWelcome from '../Components/Phase2Welcome/MidYearReviewWelcome';
import ELearning from '../Components/Dashboard/ELearning/ELearning';
import ModuleVip from '../Components/Dashboard/ModuleVip/ModuleVip';
import NotFound from '../Components/NotFound/NotFound';
import CustomizeQuestions from '../Components/CustomizeQuestions/CustomizeQuestions';
import OurLeadership from '../Components/OurLeadership/OurLeadership';
import OurStory from '../Components/OurStory/OurStory';
import OurCause from '../Components/OurCause/OurCause';
import TheOpportunity from '../Components/TheOpportunity/TheOpportunity';
import Contact from '../Components/Contact/Contact';
import TermsOfUse from '../Components/TermsOfUse/TermsOfUse';
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy';
import CoachDirectory from '../Components/CoachDirectory/CoachDirectory';
import Science from '../Components/Science/Science';
import Affiliate from '../Components/Affiliate/Affiliate';
import TRS from '../Components/TRS/TRS';
import PartnerPage from '../Components/PartnerPage/PartnerPage';
import FreeTestShopify from '../Components/Shopify/FreeTest/FreeTest';
import ShopifyLogin from '../Components/Shopify/Login/ShopifyLogin';
import ProductTestEnd from '../Components/Shopify/MindBodyWellnessAssessment/Components/WellnessAssessmentResults/WellnessAssessmentResults';
import MindBodyWellnessAssessment from '../Components/Shopify/MindBodyWellnessAssessment/MindBodyWellnessAssessment';

const currentDashboard = window.currentDashboard;

const routes = [
    {
        path: '/home',
        component: currentDashboard == 2 ? HomePage2 : HomePage,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/login',
        component: currentDashboard == 2 ? HomePage2 : HomePage,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/learnMore',
        component: LearnMore,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/learnMore/faq',
        component: FrequentlyAskedQuestions,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/Shopify/FreeTestShopify',
        component: FreeTestShopify,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/Shopify/ShopifyLogin/:token',
        component: ShopifyLogin,
        isPrivate: false,
        isExact: false,
    },
    {
        // path: '/Shopify/ProductTest',
        // component: ProductTestEnd,
        path: '/Shopify/MindBodyWellnessAssessment',
        component: MindBodyWellnessAssessment,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/privacyPolicy',
        component: PrivacyPolicy,
        isPrivate: false,
    },
    {
        path: '/termsOfUse',
        component: TermsOfUse,
        isPrivate: false,
    },
    {
        path: '/customizeQuestions',
        component: CustomizeQuestions,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/steps',
        component: Steps,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/DashboardCoach',
        component: DashboardCoach,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/NonSubscriber',
        component: NonDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/assessment',
        component: Assessment,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/assessmentMyPP',
        component: AssessmentMyPP,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/AssesmentNewModules',
        component: AssesmentNewModules,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/phase2welcome',
        component: Phase2Welcome,
        isPrivate: true,
    },
    {
        path: '/phase3welcome',
        component: Phase3Welcome,
        isPrivate: true,
    },
    {
        path: '/NewModuleWelcome',
        component: NewModuleWelcome,
        isPrivate: true,
    },
    {
        path: '/MidYearReviewWelcome',
        component: MidYearReviewWelcome,
        isPrivate: true,
    },
    {
        path: '/Level2Welcome',
        component: Level2Welcome,
        isPrivate: true,
    },
    {
        path: '/eLearning',
        component: ELearning,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/ModuleVip',
        component: ModuleVip,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/ourLeadership',
        component: OurLeadership,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/ourStory',
        component: OurStory,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/ourCause',
        component: OurCause,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/theOpportunity',
        component: TheOpportunity,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/Science',
        component: Science,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/Affiliate',
        component: Affiliate,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/Partner',
        component: PartnerPage,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/TRS',
        component: TRS,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/contact',
        component: Contact,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/coachDirectory',
        component: CoachDirectory,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/myoola/:webAlias',
        component: currentDashboard == 2 ? HomePage2 : HomePage,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/learnMore/myoola/:webAlias',
        component: LearnMore,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/ourLeadership/myoola/:webAlias',
        component: OurLeadership,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/ourStory/myoola/:webAlias',
        component: OurStory,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/ourCause/myoola/:webAlias',
        component: OurCause,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/theOpportunity/myoola/:webAlias',
        component: TheOpportunity,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/Science/myoola/:webAlias',
        component: Science,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/Affiliate/myoola/:webAlias',
        component: Affiliate,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/TRS/myoola/:webAlias',
        component: TRS,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/contact/myoola/:webAlias',
        component: Contact,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/FreeTest/:AmbassadorsName?',
        component: FreeTest,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/*:id',
        component: NotFound,
        isPrivate: false,
        isExact: false,
    },
];
export default routes;
