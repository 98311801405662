/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useContext, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from '../../../../../Context/AppContext';
import requestAxios from '../../../../../util/requestAxios';
import './CoachMyBio.css';

const CoachMyBio = ({
    profileInformation,
    getCoachInformation,
    setModalFormName,
    changeModalStep,
}) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [testimonial, setTestimonial] = useState(true);
    const [bioData, setBioData] = useState();

    const sendCoachInformation = async (formData) => {
        changeLoading(true);

        const objData = {
            userId,
            myOffer: formData.myOffer?.value || '',
            aboutMe: formData.aboutMe?.value || '',
            listingPageHeader: formData.listingPageHeader?.value || '',
            RateItemCode: formData.sessionCost?.value || '',
            testimonial: {
                fullName: formData.fullName?.value || '',
                text: formData.text?.value || '',
                location: formData.location?.value || '',
                clientPermission: formData.clientPermission?.value || true,
            },
        };

        const { error } = await requestAxios({
            url: 'Coach/SaveMyBio',
            method: 'post',
            token,
            data: objData,
        });

        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            changeModalStep(true);
        }
    };

    useEffect(() => {
        setModalFormName('coach-my-bio');
        getCoachInformation();
    }, []);

    useEffect(() => {
        setBioData(profileInformation);
    }, [profileInformation]);

    function onSubmit(event) {
        event.preventDefault();
        sendCoachInformation(event.target.elements);
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            DO: Write in the first person.. “I” Use informal writing, be
            conversational. Show your personality, charm. Give the readers
            reason to contact you.
            <br /> DON’T: Write in the third person “we or they” pronouns Brag
            Make it all about you Use jargon that makes zero sense
        </Tooltip>
    );

    return (
        <>
            <div className="myBio-info-container" style={{ marginTop: '10px' }}>
                <p className="paragraph-3">
                    Complete the About Me portion of your bio below. This
                    information will be part of your directory listing and will
                    be seen by all potential clients. Remember, you will also be
                    able to edit this information at any time in your coaching
                    dashboard.
                </p>
            </div>
            <div className="myBio-title-container">
                <h3 className="heading-4">
                    MY BIO{' '}
                    <svg>
                        <g transform="translate(40 40)">
                            <g
                                className="pulseAnimation"
                                style={{ opacity: '0.7' }}
                            >
                                <circle r="20" fill="#d86b59" />
                                <circle r="18" fill="white" />
                                <circle r="13" fill="#d86b59" />
                            </g>
                        </g>
                    </svg>
                </h3>
            </div>

            <Form
                id="coach-my-bio"
                style={{ width: '100%' }}
                onSubmit={onSubmit}
            >
                <div className="myBio-form">
                    <Form.Group controlId="listingPageHeader">
                        <Form.Label>Client welcome phrase (max 150 characters)</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="textarea"
                            rows={2}
                            required
                            maxLength='150'
                            defaultValue={
                                bioData?.listingPageHeader
                                    ? bioData?.listingPageHeader
                                    : `HI I'M ${bioData?.firstName}! I LOOK FORWARD TO WORKING WITH YOU`
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="myOffer">
                        <Form.Label>
                            With me as your coach, you will... (complete this sentence below - max 300 characters)
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip}
                            >
                                <svg
                                    width="17px"
                                    height="17px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginBottom: '3px' }}
                                >
                                    <g clipPath="url(#clip0_429_11160)">
                                        <circle
                                            cx="12"
                                            cy="11.9999"
                                            r="9"
                                            stroke="#D86B59"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            x="12"
                                            y="8"
                                            width="0.01"
                                            height="0.01"
                                            stroke="#D86B59"
                                            strokeWidth="3.75"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12 12V16"
                                            stroke="#D86B59"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_429_11160">
                                            <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="textarea"
                            rows={3}
                            required
                            defaultValue={bioData?.myOffer}
                            maxLength='300'
                            placeholder='With me as your coach, you will...'
                        />
                    </Form.Group>
                    <Form.Group controlId="aboutMe">
                        <Form.Label>About Me: (max 750 characters)</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="textarea"
                            rows={5}
                            required
                            defaultValue={bioData?.aboutMe}
                            maxLength='750'
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="sessionCost"
                        style={{ marginBottom: '20px' }}
                    >
                        <Form.Label style={{ marginBottom: '0' }}>
                            {bioData?.rateQuestion}
                        </Form.Label>
                        <p style={{ fontSize: '12px', marginBottom: '10px' }}>
                            {bioData?.rateQuestionDisclaimer}
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '30px',
                            }}
                        >
                            {bioData?.sessionRates?.map((sessionData) => (
                                <div
                                    className="myBioEdit-check-input"
                                    key={sessionData.itemCode}
                                >
                                    <label
                                        className="containerOption"
                                        htmlFor={sessionData.itemCode}
                                    >
                                        {sessionData.description}
                                        <input
                                            type="radio"
                                            name="sessionCost"
                                            id={sessionData.itemCode}
                                            value={sessionData.itemCode}
                                            defaultChecked={
                                                sessionData.selected
                                            }
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Group>
                    {testimonial && (
                        <div className="myBio-testimonial">
                            <Form.Group
                                controlId="text"
                                className="myBio-testimonial-left"
                            >
                                <Form.Label>Client testimonial:</Form.Label>
                                <Form.Control
                                    className="form-area-1"
                                    as="textarea"
                                    rows={4}
                                    defaultValue={bioData?.testimonial?.text}
                                />
                            </Form.Group>
                            <div className="myBio-testimonial-right">
                                <Form.Group controlId="fullName">
                                    <Form.Label>Client name:</Form.Label>
                                    <Form.Control
                                        className="form-input-1"
                                        type="text"
                                        defaultValue={
                                            bioData?.testimonial?.fullName
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="location">
                                    <Form.Label>Client City/State:</Form.Label>
                                    <Form.Control
                                        className="form-input-1"
                                        type="text"
                                        defaultValue={
                                            bioData?.testimonial?.location
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    )}
                    <div className="myBio-check-input">
                        <label
                            className="containerOption"
                            htmlFor="testimonial"
                        >
                            I have written permission from this client to use
                            their testimonial.
                            <input
                                type="radio"
                                name="clientPermission"
                                id="testimonial"
                                value
                                defaultChecked
                                onClick={() => setTestimonial(true)}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                    <div
                        className="myBio-check-input"
                        style={{ paddingTop: '5px' }}
                    >
                        <label
                            className="containerOption"
                            htmlFor="noTestimonial"
                        >
                            I do not have a testimonial at this time.
                            <input
                                type="radio"
                                name="clientPermission"
                                id="noTestimonial"
                                value={false}
                                onClick={() => setTestimonial(false)}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default CoachMyBio;
