import React,{useEffect} from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectedOneGoal from './TemplateGoals/SelectedOneGoal';
import './ArrangeYourGoal.css'

const SelectOneSection = (props) => {
    const [, height] = useWindowSize();
 
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, []);
    function handleOnDragEnd(context) {
        if (!context.destination) return;

        const AllState = { ...props.AllState };
        const arr = AllState.SelectedGoalsSeven;
        const newIndex = context.destination.index;
        const oldIndex = context.source.index;
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            // eslint-disable-next-line no-plusplus
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    }
    
    const SelectOneSectionR = (
        <div className="arrangeYouGoal__container">
            <div className="RecomendationTitleArrange hiddenMobile">Arrange your 7 Goals:</div>
            <div className="RecomendationTitleArrange visibleMobile">Arrange your</div>
            <div className="RecomendationTitleArrange visibleMobile">7 Goals:</div>
            <div className="RecomendationDescArrange">
                <div style={{width:'80%', textAlign:'left'}}>
                    Arrange the 7 goals that bring your life more in balance and
                    feel is most connected to your purpose
                </div>
            </div>
            <DragDropContext onDragEnd={(x) => handleOnDragEnd(x)}>
                <Droppable droppableId="DropSelectOne" className="DropSelectOne">
                    {(providedP) => (
                        <ul
                            {...providedP.droppableProps}
                            ref={providedP.innerRef}
                            style={{ listStyle: 'none', marginLeft: '-40px' }}
                        >
                            {props.AllState.SelectedGoalsSeven.map(
                                (itemF, indexF) => (
                                    <Draggable
                                        key={indexF}
                                        draggableId={`areaID${indexF}`}
                                        index={indexF}
                                    >
                                        {(provided) => (
                                            <li
                                                style={{ display: 'flex',top:`${height*.55 }px`}}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                className={props.currUser.userStepId===2?"ilDragElement":"ilDragElementLevel2"}
                                            >
                                                <SelectedOneGoal
                                                    updateIndexStep2={props.updateIndexStep2}
                                                    changeData={props.changeData}
                                                    AllState={props.AllState}
                                                    itemF={itemF}
                                                    indexF={indexF}
                                                    hideNav={false}
                                                    Fs={
                                                        props.AllState.Fs[
                                                            itemF.areaId
                                                        ]
                                                    }
                                                />
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            )}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
    return SelectOneSectionR;
};

export default SelectOneSection;
