import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import AppContext from '../../../Context/AppContext';
import currentUserStep from '../../../util/currentUserStep';
import useScroll from '../../../hooks/useScroll';
import './ShopifyLogin.css';

const ShopifyLogin = () => {
    const {
        login,
        loginWithToken,
        checkUserSession,
        activeCount,
        changeLoading,
        showLoading,
        setNewData,
    } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [typeLogin, setTypeLogin] = useState(1);
    const [textUserId, setTextUserId] = useState('');
    const [errorLogin, seterrorLogin] = useState({
        severity: 0,
        visible: 0,
        message: '',
    });
    const match = useRouteMatch('/login');
    const form = useRef(null);
    const [blockScroll, allowScroll] = useScroll();
    const history = useHistory();
    const { token } = useParams();

    const handleLoginWithToken = async () => {
        const userStep = await loginWithToken(token);
        // debugger;
        if (userStep?.data == null) {
            if (userStep || userStep === 0) {
                allowScroll();
                changeLoading(false);
                if (userStep === -1) {
                    history.push('/nonSubscriber');
                } else {
                    history.push(currentUserStep(userStep));
                }
            }
        } else {
            // changeLoading(false);
            seterrorLogin({
                severity: userStep.data.severity,
                visible: 1,
                message: userStep.data.message,
            });
            if (userStep.data.severity === 3) {
                setTextUserId('');
                setTypeLogin(2);
            }
        }
    };

    useEffect(() => {
        setNewData({ Shopify: { isShopify: true } });

        changeLoading(true);

        if (token) handleLoginWithToken();

        // const { token } = checkUserSession();

        // if (match?.isExact) {
        //     if (checkUserSession().token) {
        //         history.push(currentUserStep(checkUserSession().userStepId));
        //     } else {
        //         setShowModal(true);
        //         blockScroll();
        //     }
        // }
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();
        changeLoading(true);
        const formData = new FormData(form.current);
        const phone = formData.get('phone');
        const password = formData.get('password');

        const userStep = await login(phone, password, typeLogin);
        const infoUser = checkUserSession();

        if (userStep.data == null) {
            if (userStep || userStep === 0) {
                allowScroll();
                if (userStep === -1) {
                    history.push('/nonSubscriber');
                } else {
                    history.push(currentUserStep(userStep));
                }
            }
        } else {
            changeLoading(false);
            seterrorLogin({
                severity: userStep.data.severity,
                visible: 1,
                message: userStep.data.message,
            });
            if (userStep.data.severity === 3) {
                setTextUserId('');
                setTypeLogin(2);
            }
        }
    };

    return (
        <>
            <section className="login__container shopify">
                {!showLoading && (
                    <>
                        <div className="login__container shopifyFilter" />
                        <div className="login__modal">
                            <div className="modal__exit" />
                            <div className="modal__form">
                                <h1 className="heading-1">LOG IN</h1>
                                <section className="navLogin__content">
                                    <button
                                        type="button"
                                        className={
                                            typeLogin === 1
                                                ? 'navLoginActive'
                                                : 'navLogin'
                                        }
                                        onClick={() => {
                                            setTextUserId('');
                                            setTypeLogin(1);
                                        }}
                                    >
                                        <i
                                            className="fa fa-mobile"
                                            aria-hidden="true"
                                        />{' '}
                                        Phone Number
                                    </button>
                                    <button
                                        type="button"
                                        className={
                                            typeLogin === 3
                                                ? 'navLoginActive'
                                                : 'navLogin'
                                        }
                                        onClick={() => {
                                            setTextUserId('');
                                            setTypeLogin(3);
                                        }}
                                    >
                                        <i
                                            className="fa fa-at"
                                            aria-hidden="true"
                                        />{' '}
                                        Email
                                    </button>
                                    {/* <button
                                    type="button"
                                    className={
                                        typeLogin === 2
                                            ? 'navLoginActive'
                                            : 'navLogin'
                                    }
                                    onClick={() => {
                                        setTextUserId('');
                                        setTypeLogin(2);
                                    }}
                                >
                                    Customer Id
                                </button> */}
                                </section>
                                {errorLogin.visible === 1 && (
                                    <section
                                        className={`modalMessageLogin modalMessageLoginServerity modalMessageLoginServerity${errorLogin.severity}`}
                                    >
                                        <div className="btnCloseMessage__Message">
                                            {errorLogin.message}
                                        </div>
                                        <div
                                            aria-hidden="true"
                                            aria-label="Close Modal"
                                            className="btnClosMessage__container "
                                            onClick={() =>
                                                seterrorLogin({ visible: 0 })
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="84.853"
                                                height="84.853"
                                                viewBox="0 0 84.853 84.853"
                                                className="btnCloseMessage"
                                                aria-label="Close Modal"
                                            >
                                                <g
                                                    id="Group_52"
                                                    transform="rotate(-45 384.027 1951.387)"
                                                >
                                                    <g
                                                        id="Group_69"
                                                        transform="translate(1472.934 340.616)"
                                                    >
                                                        <path
                                                            id="Line_12"
                                                            d="M38.957 0L0 0"
                                                            fill="none"
                                                            strokeWidth="4px"
                                                            stroke="#363535"
                                                            transform="translate(0 19.479)"
                                                        />
                                                        <path
                                                            id="Line_13"
                                                            d="M0 38.957L0 0"
                                                            fill="none"
                                                            strokeWidth="4px"
                                                            stroke="#363535"
                                                            transform="translate(19.479)"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Ellipse_53"
                                                        fill="none"
                                                        strokeWidth="4px"
                                                        stroke="#363535"
                                                        transform="translate(1462.318 330)"
                                                    >
                                                        <circle
                                                            cx="30"
                                                            cy="30"
                                                            r="30"
                                                        />
                                                        <circle
                                                            cx="30"
                                                            cy="30"
                                                            r="28"
                                                            fill="none"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </section>
                                )}
                                <form ref={form} onSubmit={handleLogin}>
                                    <input
                                        type={typeLogin === 3 ? 'email' : 'tel'}
                                        name="phone"
                                        placeholder={
                                            typeLogin === 1
                                                ? 'Cell Phone Number'
                                                : typeLogin === 3
                                                ? 'Email'
                                                : 'Customer Id'
                                        }
                                        pattern={
                                            typeLogin === 1
                                                ? '([0-9]{3}-[0-9]{3}-[0-9]{4})|([0-9]{10})'
                                                : null
                                        }
                                        title="111-111-1111"
                                        value={textUserId}
                                        onChange={(input) =>
                                            setTextUserId(input.value)
                                        }
                                        required
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required
                                    />
                                    <button
                                        className="button-empty-big"
                                        type="submit"
                                    >
                                        LET’S GO!
                                    </button>
                                </form>
                            </div>
                            <div className="modal__footer">
                                <div>Problem with your log in?</div>
                                <a
                                    href="https://mybusiness.oolalife.com/forgotpassword"
                                    aria-label="reset my password"
                                >
                                    Reset my Password
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </section>
        </>
    );
};

export default ShopifyLogin;
