import React, { useContext, useState } from 'react';
import AppContext from '../../../../../Context/AppContext';
import configData from '../../../../../config.json';
import iconUser from '../../../../../Assets/img/userIcon.jpg';
import './CoachingRoomInfoComponentClient.css';

export default () => {
    const { setNewData, getData } = useContext(AppContext);
    const { ProfileCoach } = getData();
    const [imageHash] = useState(Date.now());

    return (
         <>
         <article className="CoachingRoomInforComponentClient__container">
            <div className="CoachingRoomInforComponentClient-image__container">
                <div
                    alt="Profile"
                    className="CoachingRoomInforComponentClient-image__image"
                    style={{
                        backgroundImage: `url(${
                            ProfileCoach
                                ? `${configData.SERVER_URL_IMG}/media/profile/${ProfileCoach?.currentCoach?.profilePictureFileName}?${imageHash}`
                                : iconUser
                        })`,
                    }}
                />
            </div>

            <div className="CoachingRoomInforComponentClient-name__container">
                <h3 className="heading-5 WhiteText">
                    {ProfileCoach?.currentCoach?.firstName}{' '}
                    {ProfileCoach?.currentCoach?.lastName}
                </h3>
                <p className="paragraph-3 WhiteText">
                    Certified Oola Life Coach
                </p>
            </div>
            <div className="CoachingRoomInforComponentClient-box__container">
                <div>
                    <h5>{ProfileCoach?.currentClient?.sessionsCompleted}</h5>
                    <h6>
                        {ProfileCoach?.currentClient?.sessionsCompleted === 1
                            ? 'Session'
                            : 'Sessions'}
                        <br />
                        <span>COMPLETED</span>
                    </h6>
                </div>
                <div>
                    <h5>{ProfileCoach?.currentClient?.sessionsRemaining}</h5>
                    <h6>
                        {ProfileCoach?.currentClient?.sessionsRemaining === 1
                            ? 'Session'
                            : 'Sessions'}
                        <br />
                        <span>REMAINING</span>
                    </h6>
                </div>
                <div className="btnContainerAddmoresession">
                    <button
                        onClick={() =>
                            setNewData({
                                ProfileCoach: {
                                    showModalPurchaseSession: true,
                                },
                            })
                        }
                        type="button"
                    >
                        ADD MORE SESSIONS
                    </button>
                </div>

            </div>

            
        </article>
       
        <p className='CoachProfileFinalInteractions__h6'>To cancel your monthly coaching, email support@oolalife.com or call 208-877-OOLA (6652)</p>
        
         </>

        

    );
};
