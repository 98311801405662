import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../../../Context/AppContext';
import configData from '../../../../../../config.json';
import './ConfirmPayment.css';
import CloseButton from '../../../../../General/CloseButton/CloseButton';
import requestAxios from '../../../../../../util/requestAxios';
import PurchaseFailed from '../../../PurchaseSession/componets/PurchaseFailed';

export default ({ setShowModal }) => {
    const { checkUserSession, changeLoading, getData, setNewData } =
        useContext(AppContext);
    const { token, displayCoachingPayment } = checkUserSession();
    const { selectedCoach, ProfileCoach } = getData();

    const [purchaseFailed, setPurchaseFailed] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const purchaseButtonClick = async () => {
        changeLoading(true);
        const { error } = await requestAxios({
            url: `FindCoach/SelectCoach/${selectedCoach?.id}`,
            method: 'post',
            token,
        });

        if (error) {
            changeLoading(false);
            setPurchaseFailed(true);
            // setNewData({ modalError: { show: true ,Title:"Your payment has been declined", Desc:<div><a href={configData.UPDATEPAYMENTLINK}>Click here</a> to update your card on file</div>} });
            setShowPaymentModal(true);
            setNewData({ FindCoach: { showModal: false } });
        } else {
            changeLoading(false);
            setNewData({ FindCoach: { way: 4 } });
        }
        changeLoading(false);
    };

    useEffect(() => {
        
        if (displayCoachingPayment) {
            setShowPaymentModal(true);
        } else {
            setShowPaymentModal(false);
            purchaseButtonClick();
        }
    }, []);

    return showPaymentModal ? (
        <section className="confirmPayment_container">
            <div className="confirmPayment-close_button">
                <CloseButton
                    onClick={() => {
                        setNewData({
                            FindCoach: {
                                way: 0,
                                step: 0,
                                showModal: false,
                            },
                        });
                        setShowModal(false);
                    }}
                    color="#FFFFFF"
                />
            </div>
            <div className="confirmPayment_leftContainer">
                <h4 className="heading-4 only-desktop">
                    FINAL STEP: <br /> CONFIRM PAYMENT <br /> INFORMATION
                </h4>
                <h4 className="heading-4 only-mobile">
                    FINAL STEP: CONFIRM PAYMENT INFORMATION
                </h4>
            </div>
            <div className="confirmPayment_rightContainer">
                {purchaseFailed ? (
                    <PurchaseFailed setPurchaseFailed={setPurchaseFailed} />
                ) : (
                    <>
                        <div className="rightContainer-avatar">
                            <div
                                className="avatar"
                                style={{
                                    backgroundImage: `url(${configData.SERVER_URL_IMG}media/profile/${selectedCoach?.profilePictureFileName})`,
                                }}
                            />
                            <p className="paragraph-3">
                                Oola life coaching with Coach{' '}
                                {selectedCoach?.firstName}.
                            </p>
                        </div>
                        <div className="rightContainer-price">
                            <h5>$50/MONTH</h5>
                            <p>cancel anytime</p>
                        </div>
                        <div className="rightContainer-action">
                            <p>
                                Pay with card ending in{' '}
                                {ProfileCoach?.currentClient?.creditCardOnFile}
                            </p>
                            <button
                                className="button-full-small purchaseButton"
                                type="button"
                                onClick={purchaseButtonClick}
                            >
                                PURCHASE
                            </button>
                            <button
                                className="button-empty-small updateButton"
                                type="button"
                                onClick={() =>
                                    window.open(configData.UPDATEPAYMENTLINK)
                                }
                            >
                                Update payment information
                            </button>
                            <button
                                className="button-empty-small goBackButton"
                                type="button"
                                onClick={() => {
                                    setNewData({
                                        FindCoach: {
                                            way: 0,
                                            step: 0,
                                        },
                                    });
                                }}
                            >
                                Go Back
                            </button>
                        </div>
                    </>
                )}
            </div>
        </section>
    ) : (
        <div />
    );
};
