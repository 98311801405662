import React, { useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import './ModalQuestion.css';

const ModalQuestion = ({
    showModalQuestion,
    setShowModalQuestion,
    questions,
    getMyProgress
}) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const [questionAswer, setAswerQuestion] = useState([]);
    async function saveCustomeAssessment() {
        if(questionAswer.length!==questions.length){
            alert("Please Answer all the questions")
            return false
        }
        changeLoading(true);
        const userInfo = checkUserSession();
        const postObj = {
            ModuleId: localStorage.getItem('ModuleId'),
            AssesmentDetails: questionAswer,
        };

        const { error, data } = await requestAxios({
            url: `ELearning/SaveCustomizeModuleAssessment`,
            token: userInfo.token,
            method: 'post',
            data: postObj
        });

        if (error === '') {
            getMyProgress()
            setShowModalQuestion(false)
        }
    }
    function setAnswer(questionId, optionId) {
        const questionIndex = questionAswer.findIndex(
            (x) => x.questionId == questionId && x.optionId == x.optionId
        );
        let newquestionAswer = questionAswer;
        if (questionIndex == -1) {
            newquestionAswer.push({
                questionId: questionId,
                OptionId: optionId,
            });
        } else {
            newquestionAswer[questionIndex].OptionId = optionId;
        }
        setAswerQuestion(newquestionAswer);
    }
    return (
        <Modal
            show={showModalQuestion}
            dialogClassName="modalQuestionModule-90w"
        >
            <Modal.Header>
                <Modal.Title>Quick Question:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {questions.map((item, i) => (
                    <Form
                        className="QuestionModuleContainer"
                        key={`QuestionModuleContainer${item.id}`}
                    >
                        <h1>
                            {item.text}
                        </h1>
                        {item.options.map((itemx) => (
                            <Form.Check
                                className="ModuleQuestion"
                                type="radio"
                                id={`default-${itemx.id}`}
                                label={<div className='ModuleQuestion_text'>{itemx.text}</div>}
                                key={`RadioContainer${item.id}${itemx.id}`}
                                name={`Question${item.id}`}
                                onClick={() => setAnswer(item.id, itemx.id)}
                            />
                        ))}
                    </Form>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <button
                    variant="primary"
                    className="button-full-mid"
                    onClick={() => saveCustomeAssessment()}
                >
                    Finish
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalQuestion;
