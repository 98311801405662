import React, { useState, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { RadioButton } from '../../../../Common/Control/Controls';
import AppContext from '../../../../../Context/AppContext';
import Form from 'react-bootstrap/Form';
import './ModalRepeatAction.css';

export default ({
    show,
    setShow,
    getCurrentDailyActions,
    setShowParentModal,
}) => {
    const { setNewData, getData } = useContext(AppContext);
    const { repeatAction, dayAddFindDailyActions, CustomerProfileInfo } =
        getData();
    const [initDateValue, setInitDateValue] = useState(new Date());
    const [lastDateValue, setLastDateValue] = useState(null);
    const [weekDays, setWeekDays] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [showPerio, setShowPerio] = useState(false);
    const today = CustomerProfileInfo.customerLocalTime;
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className="btnRepeatInputDate"
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));
    function addAction() {
        if (
            weekDays.monday != true &&
            weekDays.tuesday != true &&
            weekDays.thursday != true &&
            weekDays.friday != true &&
            weekDays.saturday != true &&
            weekDays.sunday != true &&
            weekDays.wednesday != true
        ) {
            setNewData({
                modalError: {
                    show: true,
                    Title: 'Error',
                    Desc: 'Select at least one day of the week',
                },
            });
        } else {
            const newRepeatAction = {
                ...weekDays,
                StartDate: initDateValue,
                EndDate: showPerio ? lastDateValue : null,
            };
            setNewData({ repeatAction: newRepeatAction });
            setShow(false);
        }
    }
    const closeModal = () => {
        if (repeatAction?.id > 0) {
            setShowParentModal(false);
        } else {
            setShow(false);
        }
        setNewData({ repeatAction: null });
    };
    const selectDaily = (input) => {
        if (input.checked) {
            setWeekDays({
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
            });
        } else {
            setWeekDays({
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            });
        }
    };
    useEffect(() => {
        if (repeatAction) {
            setInitDateValue(repeatAction.StartDate);
            if (repeatAction.EndDate) {
                setLastDateValue(repeatAction.EndDate);
                setShowPerio(true);
            }
            setWeekDays({ ...repeatAction });
        } else {
            if (dayAddFindDailyActions?.days > 0) {
                var result = initDateValue.setDate(
                    initDateValue.getDate() + dayAddFindDailyActions.days
                );
                setLastDateValue(result);
            }
        }
    }, []);
    return (
        <Modal
            show={show}
            onHide={() => {
                closeModal();
                setShow(false);
            }}
            aria-labelledby="modalNotificationActionRepeat__Fade"
            id="modalNotificationAction"
            dialogClassName="modalNotificationActionRepeat"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
            size="xl"
            centered
        >
            <Modal.Header className="modalRepetAction__header">
                <button
                    className="modalAddGoal__exit"
                    onClick={() => {
                        if (repeatAction?.id > 0) {
                            setShowParentModal(false);
                        }
                        setShow(false);
                    }}
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <section>
                    <h6>Repeats every</h6>
                    {weekDays && (
                        <div className="repeatDayButtons__container">
                            <div
                                className={weekDays.sunday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        sunday: !weekDays.sunday,
                                    })
                                }
                            >
                                S
                            </div>
                            <div
                                className={weekDays.monday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        monday: !weekDays.monday,
                                    })
                                }
                            >
                                M
                            </div>
                            <div
                                className={weekDays.tuesday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        tuesday: !weekDays.tuesday,
                                    })
                                }
                            >
                                T
                            </div>
                            <div
                                className={weekDays.wednesday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        wednesday: !weekDays.wednesday,
                                    })
                                }
                            >
                                W
                            </div>
                            <div
                                className={weekDays.thursday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        thursday: !weekDays.thursday,
                                    })
                                }
                            >
                                T
                            </div>
                            <div
                                className={weekDays.friday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        friday: !weekDays.friday,
                                    })
                                }
                            >
                                F
                            </div>
                            <div
                                className={weekDays.saturday && 'selected'}
                                onClick={() =>
                                    setWeekDays({
                                        ...weekDays,
                                        saturday: !weekDays.saturday,
                                    })
                                }
                            >
                                S
                            </div>
                        </div>
                    )}
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="Oola-switch"
                        label="Repeats Daily"
                        className="Oola-switch"
                        onChange={(input) => selectDaily(input.target)}
                    />
                    <br />
                    <h6>Starts</h6>
                    <DatePicker
                        dateFormat="MMMM dd, yyyy"
                        selected={initDateValue}
                        onChange={(completedByDate) => {
                            setInitDateValue(completedByDate);
                            if (completedByDate > lastDateValue) {
                                setLastDateValue(null);
                            }
                        }}
                        customInput={<CustomInput />}
                        withPortal
                        className="datePickerTask"
                        placeholderText="Complete by: mm/dd/yyyy"
                        minDate={today}
                        disabled=""
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div>
                                <div
                                    className="calendarHeader"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    START AT:
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        paddingTop: '10px',
                                    }}
                                >
                                    <button
                                        className="btnCalendar"
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                        type="button"
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {'<'}
                                    </button>
                                    <div className="lblMonth">
                                        {months[new Date(date).getMonth()]}{' '}
                                        {new Date(date).getFullYear()}
                                    </div>
                                    <button
                                        className="btnCalendar"
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                        type="button"
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {'>'}
                                    </button>
                                </div>
                            </div>
                        )}
                    >
                        <section className="modalAddTaskRepeat__section__container">
                            {/* <section className="modalAddTaskRepeat__section">
                                <button
                                    onClick={() => console.log('Done')}
                                    className="button-full-small modalAddTaskRepeat__doneButton"
                                    type="button"
                                >
                                    DONE
                                </button>
                            </section> */}
                        </section>
                    </DatePicker>
                    <br />
                    <h6>Ends</h6>
                    <RadioButton
                        text="Never"
                        name="periodicity"
                        className=""
                        checked={!showPerio}
                        onClick={() => setShowPerio(false)}
                    />
                    <div className="periodicity__container">
                        <RadioButton
                            text="On"
                            name="periodicity"
                            onClick={() => setShowPerio(true)}
                            checked={showPerio}
                        />
                        {showPerio && (
                            <DatePicker
                                dateFormat="MMMM dd, yyyy"
                                selected={lastDateValue}
                                onChange={(completedByDate) =>
                                    setLastDateValue(completedByDate)
                                }
                                customInput={<CustomInput />}
                                withPortal
                                className="datePickerTask"
                                placeholderText="Complete by: mm/dd/yyyy"
                                minDate={initDateValue}
                                showTimeSelect={false}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div>
                                        <div
                                            className="calendarHeader"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                width: '100%',
                                            }}
                                        >
                                            ENDS AT:
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <button
                                                className="btnCalendar"
                                                onClick={decreaseMonth}
                                                disabled={
                                                    prevMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'<'}
                                            </button>
                                            <div className="lblMonth">
                                                {
                                                    months[
                                                        new Date(
                                                            date
                                                        ).getMonth()
                                                    ]
                                                }{' '}
                                                {new Date(date).getFullYear()}
                                            </div>
                                            <button
                                                className="btnCalendar"
                                                onClick={increaseMonth}
                                                disabled={
                                                    nextMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            >
                                <section className="modalAddTaskRepeat__section__container">
                                    <section className="modalAddTaskRepeat__section">
                                        {/* <button
                                            onClick={() => console.log('Done')}
                                            className="button-full-small modalAddTaskRepeat__doneButton"
                                            type="button"
                                        >
                                            DONE
                                        </button> */}
                                    </section>
                                </section>
                            </DatePicker>
                        )}
                    </div>
                    <br />
                    <div className="modalAddTaskRepeat_line" />
                    <div className="modalRepeatAction_buttons">
                        <button
                            onClick={() => closeModal()}
                            className="button-empty-small modalAddTaskRepeat__doneButton"
                            type="button"
                        >
                            CANCEL
                        </button>
                        <button
                            onClick={() => addAction()}
                            className="button-full-small modalAddTaskRepeat__doneButton"
                            type="button"
                        >
                            DONE
                        </button>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
};
