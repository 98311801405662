import React from 'react';
import OolaReviewReviseReaffirmGoal from './OolaReviewReviseReaffirmGoal';
import './OolaReviewReviseReaffirmGoals.css';

const OolaReviewReviseReaffirmGoals = (props) => {
    function selectGoal(indexF, area, selected, idL) {
        let curSel = selected;
        try {
            curSel = props.AllState.SelectedGoalsGroup[area].selected.find(
                (x) => x.id === idL
            ).selected;
        } catch (e) {
            curSel = false;
        }
        if (props.AllState.SelectedGoalsSeven.length > 6 && !curSel) {
            props.setShowSevenConfirm(true);
            return;
        }

        if (!curSel) {
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].idL = `${area}${indexF}`;
            props.AllState.SelectedGoalsSeven.push(
                props.AllState.SelectedGoalsGroup[area].selected[indexF]
            );
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].selected = true;
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].id = `${area}${indexF}`;
            props.AllState.changeData(props.AllState);
            props.forceUpdate();
        } else {
            const selIndex = props.AllState.SelectedGoalsSeven.findIndex(
                (x) => x.idL === idL
            );
            props.AllState.SelectedGoalsSeven.find(
                (x) => x.id === idL
            ).selected = false;
            props.AllState.SelectedGoalsSeven.splice(selIndex, 1);
            props.AllState.changeData(props.AllState);
            props.forceUpdate();
        }
    }
    let OolaReviewReviseReaffirmGoalsR = (
        <div className="OolaReviewReviseReaffirmGoals__container">
            <div className="OolaReviewReviseReaffirmGoalsTitle_Container">
                <div>
                    <div className="OolaReviewReviseReaffirmGoalsTitle">
                        REVIEW. REVISE. REAFFIRM.
                    </div>
                </div>
                <div>
                    <button
                        className="button-full-mid OolaReviewReviseReaffirmGoals_btnCleanSlate"
                        onClick={() => props.setShowConfirm(true)}
                    >
                        CLEAN SLATE
                    </button>
                </div>
            </div>
            <p>
                Review, revise, and reaffirm your goals below that you are going
                to commit to for the next year.
                <br />
                Add goals where it's needed to complete your list of 21 goals.
                To start from scratch, simply click the <i>Clean Slate</i>{' '}
                button and all of your goals will be deleted
            </p>
            
            <div className="selectGoalTop7__container">
                {props.AllState.Fs.map((item) => (
                    <div
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px',
                            width: '100%',
                        }}
                        key={`SelectSevenSectionRRR${item.areaId}`}
                    >
                        <div
                            className=""
                            xs={1}
                            style={{
                                height: '100%',
                                left: 20,
                                marginLeft: '0px',
                                marginRight: '0px',
                            }}
                        >
                            <div
                                className=" "
                                xs={1}
                                style={{
                                    height: '100%',
                                    paddingTop: ' calc(.8vw)',
                                }}
                            >
                                <div
                                    className="titleGroup"
                                    style={{
                                        textAlign: 'initial',
                                        color: item.color,
                                    }}
                                >
                                    {String(item.name).toUpperCase()}
                                    {` GOALS`}
                                </div>
                            </div>
                        </div>
                        <OolaReviewReviseReaffirmGoal
                            selectGoal={selectGoal}
                            AllState={props.AllState}
                            itemF={{}}
                            FInfo={item}
                            indexF={0}
                            hideNav={false}
                            key={`OolaReviewReviseReaffirmGoal${
                                item.areaId
                            }${0}`}
                            getData={props.getData}
                        />
                        <OolaReviewReviseReaffirmGoal
                            selectGoal={selectGoal}
                            AllState={props.AllState}
                            itemF={{}}
                            FInfo={item}
                            indexF={1}
                            hideNav={false}
                            key={`OolaReviewReviseReaffirmGoal${
                                item.areaId
                            }${1}`}
                            getData={props.getData}
                        />
                        <OolaReviewReviseReaffirmGoal
                            selectGoal={selectGoal}
                            AllState={props.AllState}
                            itemF={{}}
                            FInfo={item}
                            indexF={2}
                            hideNav={false}
                            key={`OolaReviewReviseReaffirmGoal${
                                item.areaId
                            }${2}`}
                            getData={props.getData}
                        />
                    </div>
                ))}
            </div>
        </div>
    );

    return OolaReviewReviseReaffirmGoalsR;
};

export default OolaReviewReviseReaffirmGoals;
