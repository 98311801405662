import React from 'react';
import './RecomendationSection.css';
import SelectedSevenGoal from './TemplateGoals/SelectedSevenGoal';
import './OolaSevenSection.css';
import './SelectSevenSection.css';

const SelectSevenSection = (props) => {
    const elements = [0, 1, 2, 3, 4, 5, 6];

    function selectGoal(indexF, area, selected, idL) {
        let curSel = selected;
        try {
            curSel = props.AllState.SelectedGoalsGroup[area].selected.find(
                (x) => x.id === idL
            ).selected;
        } catch (e) {
            curSel = false;
        }
        if (props.AllState.SelectedGoalsSeven.length > 6 && !curSel) {
            props.setShowSevenConfirm(true);
            return;
        }

        if (!curSel) {
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].idL = `${area}${indexF}`;
            props.AllState.SelectedGoalsSeven.push(
                props.AllState.SelectedGoalsGroup[area].selected[indexF]
            );
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].selected = true;
            props.AllState.SelectedGoalsGroup[area].selected[
                indexF
            ].id = `${area}${indexF}`;
            props.AllState.changeData(props.AllState);
            props.forceUpdate();
        } else {
            const selIndex = props.AllState.SelectedGoalsSeven.findIndex(
                (x) => x.idL === idL
            );
            props.AllState.SelectedGoalsSeven.find(
                (x) => x.id === idL
            ).selected = false;
            props.AllState.SelectedGoalsSeven.splice(selIndex, 1);
            props.AllState.changeData(props.AllState);
            props.forceUpdate();
        }
    }
    let SelectSevenSectionR = (
        <div className="SelectSevenSection__container"  >
            <div className="hiddenMobile">
                <div className="numberGoalsTop7">
                    {props.AllState.SelectedGoalsSeven.length} of 7 GOALS
                </div>
                <div className="RecomendationTitleTop7">PICK 7 GOALS:</div>
                <div className="RecomendationDescTop7" style={{ width: '73%' }}>
                    Click and Select the 7 goals that bring your life more in
                    balance and you feel is most connected to your purpose.
                </div>
            </div>

            <div className="recommendation__titles visibleMobile" >
                <div className="titles__texts">
                    <h3 className="RecomendationTitleTop7">
                        PICK 7 GOALS:
                    </h3>
                    <p className="RecomendationDescTop7">
                        Click and Select the 7 goals that bring your life more in
                        balance and you feel is most connected to your purpose.
                    </p>
                </div>
                <div className="numberGoalsTop7">
                    <h5 className="numberGoalsTop7">{props.AllState.SelectedGoalsSeven.length} of 7 GOALS</h5>
                </div>
            </div>
            
            <div className="selectGoalTop7__container">
                {elements.map((number) => (
                    <div
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px',
                            width: '100%',
                        }}
                        key={`selectGoalTop7__container${number}`}
                    >
                        <div
                            className=""
                            xs={1}
                            style={{
                                height: '100%',
                                left: 20,
                                marginLeft: '0px',
                                marginRight: '0px',
                            }}
                        >
                            <div
                                className=" "
                                xs={1}
                                style={{
                                    height: '100%',
                                    paddingTop: ' calc(.8vw)',
                                }}
                            >
                                <div
                                    className="titleGroup"
                                    style={{
                                        textAlign:'initial',
                                        color:
                                        props.AllState.SelectedGoalsGroup[number]!=null&&  
                                        props.AllState.SelectedGoalsGroup[
                                                number
                                            ].Fs.color,
                                    }}
                                >
                                    {String(
                                        props.AllState.SelectedGoalsGroup[number]!=null&&  
                                        props.AllState.SelectedGoalsGroup[
                                            number
                                        ].Fs.name
                                    ).toUpperCase()}
                                    {` GOALS`}
                                </div>
                            </div>
                        </div>

                        {
                                        props.AllState.SelectedGoalsGroup[number]!=null&&  
                                        props.AllState.SelectedGoalsGroup[number].selected.map(
                            (itemF, indexF) => (
                                <SelectedSevenGoal
                                    selectGoal={selectGoal}
                                    AllState={props.AllState}
                                    itemF={itemF}
                                    number={number}
                                    indexF={indexF}
                                    hideNav={false}
                                    key={`SelectSevenSectionR${itemF.Id==null?itemF.GoalId:itemF.Id}`}
                                />
                            )
                        )}

                    </div>
                ))}
            </div>
        </div>
    );
    
    return SelectSevenSectionR;
};

export default SelectSevenSection;
