import React, { useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    OverlayTrigger,
    Popover,
    Navbar,
    Nav,
    NavDropdown,
} from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import './OolaHeader.css';

import oolaHeaderLogo from '../../../../Assets/oola_header_logo.png';

export default ({ setShowModal, blockScroll }) => {
    const { checkUserSession } = useContext(AppContext);
    const [showShopPopover, setShowShopPopover] = useState(false);
    const [showDropDownPopover, setShowDropDownPopover] = useState(false);
    const [showDropDownLoginPopover, setShowDropDownLoginPopover] =
        useState(false);
    const { webAlias } = useParams();

    const popover = (
        <Popover
            onMouseEnter={() => setShowShopPopover(true)}
            onMouseLeave={() => setShowShopPopover(false)}
            id="popover-positioned-bottom"
            className="OolaHeader-popover_container"
        >
            <Popover.Content>
                <section className="OolaHeader-shop_container">
                    <div>
                        <h5>Products</h5>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/703">
                            Mind + Nootropics
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/704">
                            Body + Adaptogens
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/701">
                            Rise Gummies
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/702">
                            Rest Gummies
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/700">
                            Smart Energy Shot
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/705">
                            Connection Probiotic
                        </a>
                    </div>
                    <div>
                        <h5>Bundles - Best Value</h5>
                        <a href="./TRS">Oola Total Rebalancing System (TRS)</a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/808">
                            Oola Mind and Body Connection Bundle
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/productDetail/805">
                            Oola Mind and Body Bundle
                        </a>
                    </div>
                    <div>
                        <h5>Programs</h5>
                        <a href="https://myoola.oolalife.com/corporphan/enrollment/enrollmentmember">
                            Oola Lifestyle Framework
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/product/400">
                            Green Gap
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/product/402">
                            Miracle Mindset
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/product/401">
                            Body by Design
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/product/403">
                            23 Strong
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/product/404">
                            Who Are You?
                        </a>
                    </div>
                    <div>
                        <h5>Live Events</h5>
                        <a href="https://shop.oolalife.com/products/oolau">
                            Oola U Business Mastermind
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/oolapalooza">
                            OolaPalooza 2024
                        </a>
                        <a href="https://myoola.oolalife.com/corporphan/OolaLifeConvention">
                            Oola Convention 2024
                        </a>
                        <a href="https://shop.oolalife.com/collections/life-rebalanced-workshops">
                            Life Rebalanced Workshops
                        </a>
                    </div>
                    <div>
                        <h5>
                            {' '}
                            <a href="https://shop.oolalife.com">Merch</a>
                        </h5>
                    </div>
                </section>
            </Popover.Content>
        </Popover>
    );

    const dropDownShopMobile = (
        <NavDropdown
            className="OolaHeader-shop_mobile"
            title="Shop"
            id="basic-nav-dropdown"
        >
            <NavDropdown
                className="OolaHeader-shop_dopdown"
                title="Products"
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/703">
                    Mind + Nootropics
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/704">
                    Body + Adaptogens
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/701">
                    Rise Gummies
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/702">
                    Rest Gummies
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/700">
                    Smart Energy Shot
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/705">
                    Connection Probiotic
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
                className="OolaHeader-shop_dopdown"
                title="Bundles - Best Value"
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item href="./TRS">
                    Oola Total Rebalancing System (TRS)
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/808">
                    Oola Mind and Body Connection Bundle
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/productDetail/805">
                    Oola Mind and Body Bundle
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
                className="OolaHeader-shop_dopdown"
                title="Programs"
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/enrollment/enrollmentmember">
                    Oola Lifestyle Framework
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/400">
                    Green Gap
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/402">
                    Miracle Mindset
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/401">
                    Body by Design
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/403">
                    23 Strong
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/404">
                    Who Are You?
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
                className="OolaHeader-shop_dopdown"
                title="Live Events"
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item href="https://shop.oolalife.com/products/oolau">
                    Oola U Business Mastermind
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/oolapalooza">
                    OolaPalooza 2024
                </NavDropdown.Item>
                <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/OolaLifeConvention">
                    Oola Convention 2024
                </NavDropdown.Item>
                <NavDropdown.Item href="https://shop.oolalife.com/collections/life-rebalanced-workshops">
                    Life Rebalanced Workshops
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown.Item href="https://shop.oolalife.com/">
                Merch
            </NavDropdown.Item>
        </NavDropdown>
    );

    return (
        <Navbar bg="light" expand="lg" className="oolaHeader_container">
            <Navbar.Brand href="#home">
                <Link to={webAlias ? `/myoola/${webAlias}` : '/home'}>
                    <img src={oolaHeaderLogo} alt="Logo Oola Life" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="oola-nav" />
            <Navbar.Collapse
                id="oola-nav"
                className="OolaHeader-collapse_container"
            >
                <Nav className="ml-auto OolaHeader-nav_container">
                    <Nav.Link href="/OurStory">About</Nav.Link>
                    <Nav.Link href="/Science">Science</Nav.Link>
                    {/* <Nav.Link href="#">Nootropics & Adaptogens</Nav.Link> */}
                    <NavDropdown
                        show={showDropDownPopover}
                        title="Partner with Oola"
                        id="basic-nav-dropdown"
                        onMouseEnter={() => setShowDropDownPopover(true)}
                        onMouseLeave={() => setShowDropDownPopover(false)}
                        onClick={() =>
                            setShowDropDownPopover(!showDropDownPopover)
                        }
                    >
                        <NavDropdown.Item href="/Affiliate">
                            Become an Affiliate
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/enrollment/enrollmentconfigurationambassador">
                            Become an Ambassador
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/product/355">
                            Become a Coach
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/learnmore/faq">FAQs</Nav.Link>
                    <OverlayTrigger
                        show={showShopPopover}
                        trigger={['click', 'focus', 'hover']}
                        placement="bottom"
                        overlay={popover}
                    >
                        <button
                            onMouseEnter={() => setShowShopPopover(true)}
                            type="button"
                            className="OolaHeader-shop"
                            onClick={() => {
                                window.location.href =
                                    'https://myoola.oolalife.com/corporphan/products';
                            }}
                        >
                            Shop
                        </button>
                    </OverlayTrigger>

                    {dropDownShopMobile}

                    {checkUserSession().token ? (
                        <Nav.Link
                            href="/dashboard"
                            className="OolaHeader-login"
                        >
                            Dashboard
                        </Nav.Link>
                    ) : (
                        <NavDropdown
                            show={showDropDownLoginPopover}
                            title="Login"
                            id="header-login-dropdown"
                            alignRight
                            onMouseEnter={() =>
                                setShowDropDownLoginPopover(true)
                            }
                            onMouseLeave={() =>
                                setShowDropDownLoginPopover(false)
                            }
                            onClick={() =>
                                setShowDropDownLoginPopover(
                                    !showDropDownLoginPopover
                                )
                            }
                        >
                            <NavDropdown.Item href="https://myoola.oolalife.com/corporphan/account/login">
                                Access My Products
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => {
                                    if (webAlias) {
                                        window.location.href = `https://myoola.oolalife.com/${webAlias}/account/login`;
                                    } else {
                                        setShowModal(true);
                                        blockScroll();
                                    }
                                }}
                            >
                                Access My Programs
                            </NavDropdown.Item>
                        </NavDropdown>
                        // <button
                        //     className="OolaHeader-login_button"
                        //     onClick={() => {
                        //         if (webAlias) {
                        //             window.location.href = `https://myoola.oolalife.com/${webAlias}/account/login`;
                        //         } else {
                        //             setShowModal(true);
                        //             blockScroll();
                        //         }
                        //     }}
                        //     type="button"
                        // >
                        //     Login
                        // </button>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
