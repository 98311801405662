import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import ModalTimeZone from '../../CustomizeQuestions/ModalTimeZone/ModalTimeZone';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import './HeaderDashboard.css';
import SideBar from '../../Common/sidebar';
import configData from '../../../config.json';

const HeaderDashboard = (props) => {
    const { logOut, checkUserSession } = useContext(AppContext);
    const { userId, token, isShopify } = checkUserSession();
    const [show, setShow] = useState(false);
    const [showModalTimezone, setshowModalTimezone] = useState(false);
    const path = window.location.pathname;
    const myBusinessHandler = async () => {
        const { error, data } = await requestAxios({
            url: `Login/GetSilentToken/${userId}`,
            token,
        });
        if (error)
            window.alert('Sorry we are unable to access your business page');
        else {
            window.open(
                `${configData.ExigoSilentToken}sso?token=${encodeURIComponent(
                    data.silentToken
                )}`,
                '_blank'
            );
        }
    };

    const logOutHandler = () => {
        logOut();
    };

    const popover2 = (
        <Popover
            id="popover-basic"
            style={{
                height: 'auto',
                width: '120px',
                maxWidth: '100%',
                borderRadius: '0.5rem',
                zIndex: '10000',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                <div className="SubTitleMenu">
                    Inaugural Oola Summit will be September 18th. Stay tuned for
                    details.
                </div>
            </Popover.Content>
        </Popover>
    );
    const popover = (
        <Popover
            id="popover-basic"
            style={{
                height: '17.15rem',
                width: '10.68rem',
                maxWidth: '100%',
                borderRadius: '0.5rem',
                zIndex: '10000',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                <div className="TitleFinish titleQuestion TitlePop"> </div>
                <div className="descriptionQuestion DescriptionPop">
                    <div className="ContentbtnModalMenu">
                        <Row className="btnModalMenu">
                            <button onClick={myBusinessHandler} type="button">
                                MY BACKOFFICE
                            </button>
                        </Row>
                    </div>
                    {(props.profile?.isActiveCoach ||
                        path == '/DashboardCoach') && (
                        <div className="ContentbtnModalMenu">
                            <Row className="btnModalMenu">
                                <button
                                    onClick={() =>
                                        (window.location.href =
                                            path == '/DashboardCoach'
                                                ? './Dashboard'
                                                : './DashboardCoach')
                                    }
                                    type="button"
                                >
                                    {path == '/DashboardCoach'
                                        ? 'MY OOLA'
                                        : 'MY COACHING PORTAL'}
                                </button>
                            </Row>
                        </div>
                    )}
                    <div className="ContentbtnModalMenu">
                        <Row className="btnModalMenu">
                            <button
                                type="button"
                                onClick={() => (window.location.href = '/')}
                            >
                                OOLA HOMEPAGE
                            </button>
                        </Row>
                    </div>
                    <div className="ContentbtnModalMenu">
                        <Row className="btnModalMenu">
                            <a href={configData.FINDACOACHLINK}>
                                <button type="button">FIND A COACH</button>
                            </a>
                        </Row>
                    </div>
                    <div className="ContentbtnModalMenu">
                        <Row className="btnModalMenu">
                            <a
                                href="https://www.oolalifecoach.com/"
                                aria-label="become a coach"
                            >
                                <button type="button">BECOME A COACH</button>
                            </a>
                        </Row>
                    </div>
                    <div className="ContentbtnModalMenu">
                        <a
                            aria-label="become a coach"
                            href="https://oolalifestore.com"
                        >
                            <Row className="btnModalMenu">
                                <button type="button">THE STORE</button>
                            </Row>
                        </a>
                    </div>
                    <div className="ContentbtnModalMenu">
                        <a aria-label="Community ">
                            <Row className="btnModalMenu">
                                <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    rootClose
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={popover2}
                                >
                                    <button
                                        type="button"
                                        aria-hidden="true"
                                        onClick={() =>
                                            (window.location.href =
                                                'https://www.facebook.com/OolaLife/')
                                        }
                                    >
                                        COMMUNITY
                                    </button>
                                </OverlayTrigger>
                            </Row>
                        </a>
                    </div>

                    <div className="ContentbtnModalMenu">
                        <Row className="btnModalMenu">
                            <button onClick={logOutHandler} type="button">
                                LOG OUT
                            </button>
                        </Row>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    const HeaderDashboardR = (
        <Row
            className="LoginRow"
            style={{ backgroundColor: 'white' /* paddingTop: '5px'*/ }}
        >
            <Col
                xs={6}
                style={
                    {
                        /*paddingTop: '10px', paddingBottom: '10px'*/
                    }
                }
            >
                {!isShopify && (
                    <Link
                        to={
                            props.nonSubscriber == 1
                                ? '/nonSubscriber'
                                : props.steps == null
                                ? '/dashboard'
                                : '/steps'
                        }
                    >
                        <div
                            className="Oola-Logo Black-Logo "
                            style={{ cursor: 'pointer' }}
                        />
                    </Link>
                )}
            </Col>

            <Col
                xs={6}
                style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingRight: '2%',
                    textAlign: '-webkit-right',
                }}
                onClick={() => setShow(!show)}
            >
                {(props.profile?.isActiveCoach ||
                    path == '/DashboardCoach') && (
                    <button
                        onClick={() =>
                            (window.location.href =
                                path == '/DashboardCoach'
                                    ? './Dashboard'
                                    : './DashboardCoach')
                        }
                        type="button"
                        className={"btnMyCoachingHeader "+ (isShopify && "shopify")}
                    >
                        {path == '/DashboardCoach'
                            ? 'MY OOLA'
                            : 'MY COACHING PORTAL'}
                    </button>
                )}

                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    rootClose
                    delay={{ show: 0, hide: 400 }}
                >
                    <button
                        type="button"
                        className="fa fa-bars fa-3 menuDesk"
                        style={{
                            fontSize: '2rem',
                            cursor: 'pointer',
                            backgroundColor: '#FFF',
                            border: '0px',
                        }}
                        aria-hidden="true"
                    />
                </OverlayTrigger>

                <i
                    className="fa fa-globe fa-2x btnChangeTimeZone"
                    aria-hidden="true"
                    onClick={() => setshowModalTimezone(true)}
                />

                <div
                    //className="fa fa-bars fa-3"
                    className="menuMobile"
                    style={{
                        cursor: 'pointer',
                        backgroundColor: '#FFF',
                        border: '0px',
                    }}
                    aria-hidden="true"
                >
                    <SideBar
                        {...props}
                        myBusinessHandler={myBusinessHandler}
                        logOutHandler={logOutHandler}
                    />
                </div>
            </Col>
            {props.disableTimeModal !== false && (
                <ModalTimeZone
                    showModalTimezone={showModalTimezone}
                    setshowModalTimezone={setshowModalTimezone}
                    update={true}
                />
            )}
        </Row>
    );

    return HeaderDashboardR;
};
export default HeaderDashboard;
