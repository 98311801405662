import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalAddTask.css';
import configData from '../../../../../config.json';

const ModalAddGoal = (props) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className="datePickerTask btnDatePickerGoal btnDateDasboard"
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));
    const [modalVisible, setModalVisible] = useState(false);
    const [textValue, setTextValue] = useState(props.currItem.text);
    const [dateValue, setDateValue] = useState(
        props.currItem.createdDate
            ? new Date(props.currItem.createdDate)
            : new Date(props.currItem.date)
    );
    const [textModal, setTextModal] = useState({
        title: 'Complete',
        desc: 'Are you sure you want to save this task?',
        visible: false,
    });
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 6);

    function saveTask() {
        if (String(textValue).trim().length > 0) {
            setTextModal({
                title: `${props.modalType === 1 ? 'Add' : 'Edit'} task`,
                desc: 'Are you sure you want to save this task?',
                visible: true,
            });
        } else {
            alert('Please define your task');
        }
    }

    function addTask() {
        props.changeLoading(true);
        const url = `${configData.SERVER_URL}ToDoList/AddTask`;
        const parseDate = new Date(
            dateValue.getFullYear(),
            dateValue.getMonth(),
            dateValue.getDate()
        );

        const parseOriginalDate = new Date(
            props.currItem.originalDate.getFullYear(),
            props.currItem.originalDate.getMonth(),
            props.currItem.originalDate.getDate()
        );

        const fecha1 = moment(parseOriginalDate);
        const fecha2 = moment(parseDate);
        const diffDays=fecha2.diff(fecha1, 'days')

        const PostObj = {
            text: textValue,
            DayNumber:diffDays
            // CreatedDate: parseDate,
        };

        axios({
            method: 'post',
            url,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: PostObj,
        })
            .then((result) => {
                props.changeLoading(false);
                props.getToDoList();
                props.setShow(false);
            })
            .catch((error) => {
                props.changeLoading(false);
                console.log(error.toJSON());
            });
    }

    function editTask() {
        props.changeLoading(true);
        const url = `${configData.SERVER_URL}ToDoList/EditTask`;
        const parseDate = new Date(
            dateValue.getFullYear(),
            dateValue.getMonth(),
            dateValue.getDate()
        );

        const parseOriginalDate = new Date(
            props.currItem.originalDate.getFullYear(),
            props.currItem.originalDate.getMonth(),
            props.currItem.originalDate.getDate()
        );

        const fecha1 = moment(parseOriginalDate);
        const fecha2 = moment(parseDate);
        const diffDays=fecha2.diff(fecha1, 'days')

        const PostObj = {
            id:props.currItem.id,
            text: textValue,
            DayNumber:diffDays
            // CreatedDate: parseDate,
        };
        axios({
            method: 'put',
            url,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: PostObj,
        })
            .then((result) => {
                props.changeLoading(false);
                props.getToDoList();
                props.setShow(false);
            })
            .catch((error) => {
                props.changeLoading(false);
                console.log(error.toJSON());
            });
    }

    function hideNotification() {
        setTextModal({
            ...setTextModal,
            visible: false,
        });
    }
    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                centered
            >
                <Modal.Header className="modalAddTaskHeader__container">
                    <button
                        className="modalAddGoal__exit"
                        onClick={() => props.setShow(false)}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <section className="modalAddGoal__section">
                        <h3 className="heading-3">
                            {props.modalType === 1 ? 'ADD TASK' : 'EDIT'}
                        </h3>
                        <div className="modalAddGoal__goalContainer">
                            <input
                                value={textValue}
                                className="ModalTaskInputDesc"
                                placeholder="Define your task"
                                onChange={(e) => setTextValue(e.target.value)}
                            />

                            <DatePicker
                                dateFormat="MMMM dd, yyyy"
                                selected={dateValue}
                                onChange={(completedByDate) =>
                                    setDateValue(completedByDate)
                                }
                                customInput={<CustomInput />}
                                withPortal
                                className="datePickerTask"
                                placeholderText="Complete by: mm/dd/yyyy"
                                minDate={today}
                                maxDate={maxDate}
                                disabled=""
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div>
                                        <div
                                            className="calendarHeader"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                width: '100%',
                                            }}
                                        >
                                            COMPLETE BY:
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <button
                                                className="btnCalendar"
                                                onClick={decreaseMonth}
                                                disabled={
                                                    prevMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'<'}
                                            </button>
                                            <div className="lblMonth">
                                                {
                                                    months[
                                                        new Date(
                                                            date
                                                        ).getMonth()
                                                    ]
                                                }
                                                {new Date(date).getFullYear()}
                                            </div>
                                            <button
                                                className="btnCalendar"
                                                onClick={increaseMonth}
                                                disabled={
                                                    nextMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        <div>
                            <button
                                onClick={() => saveTask()}
                                className="button-full-mid"
                                type="button"
                            >
                                Save
                            </button>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>

            <Modal
                show={textModal.visible}
                onHide={() => hideNotification()}
                aria-labelledby="example-custom-modal-styling-title"
                id="modalNotificationAction"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
                size="xl"
                centered
            >
                <Modal.Body>
                    <div
                        className="NotificacionActionStepTitle"
                        style={{ paddingTop: '60px' }}
                    >
                        {textModal.title}
                    </div>
                    <div className="NotificacionActionStepDesc">
                        {textModal.desc}
                    </div>
                    <center style={{ textAlign: 'right' }}>
                        <button
                            onClick={() => hideNotification()}
                            className="btnCancelActionStep"
                            style={{}}
                        >
                            CANCEL
                        </button>
                        <button
                            onClick={() =>
                                props.modalType === 1 ? addTask() : editTask()
                            }
                            className="btnYeahActionStep"
                            style={{}}
                        >
                            YEAH, I'M SURE
                        </button>
                    </center>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ModalAddGoal;
