import React, { useState, useContext } from 'react';
import useFitText from 'use-fit-text';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import AchievementsCoach from './Modals/AchievementsCoach';
import ImageIcon from './Modals/ImageIcon';
import iconUser from '../../../Assets/img/userIcon.jpg';
import './UserInfoCoach.css';

const UserInfoCoach = (props) => {
    const { checkUserSession, getData, setNewData, changeLoading } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();

    const [show, SetShow] = useState(false);
    const [showAchievents, SetShowAchievents] = useState(false);
    const [badgeSelected, setBadge] = useState('');
    const { fontSize, ref } = useFitText();
    const [imageHash, imageHashChange] = useState(Date.now());
    const { ProfileCoach } = getData();
    const [data, changeData] = useState({
        file: null,
    });

    function setShowModal(visible) {
        SetShow(visible);
    }
    function SetShowAchieventsModal(visible) {
        SetShowAchievents(visible);
    }

    function setBadgeModal(section) {
        setBadge(section);
    }

    function clickButtonAchievements() {
        setShowModal(true);
    }

    const changeHandler = (event) => {
        changeLoading(true);
        const AllProps = { ...data };
        AllProps.file = event.target.files[0];
        changeData(AllProps);

        // Create an object of formData
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('image', AllProps.file);

        const url = `${configData.SERVER_URL}Coach/UploadProfilePicture`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .post(url, formData, config)
            .then((response) => {
                if (response.status === 201) {
                    props.getProfileCoachInfo();
                    imageHashChange(Date.now());
                    alert('Image uploaded correctly');
                }
                changeLoading(false);
            })
            .catch((error) => {
                changeLoading(false);
                alert(
                    'The file image exceeds the maximum allowed size (3 MB).'
                );
                console.log(error);
            });
    }
    return (
        <>
            <article className="userInfo ">
                <div className="userInfo__image">
                    <div
                        alt="Profile"
                        className="image__profile"
                        style={{
                            '--imgProfileUrl': `url(${
                                ProfileCoach?.profilePictureFileName
                                    ? `${configData.SERVER_URL_IMG}/media/profile/${ProfileCoach?.profilePictureFileName}?${imageHash}`
                                    : iconUser
                            })`,
                        }}
                    >
                        <div className="image__input">
                            <input
                                type="file"
                                size="35"
                                onChange={changeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="userInfo__info coach">
                    <h5 className="heading-5 BlueDarkText">
                        COACH{/* {ProfileCoach?.customerType} */}
                    </h5>
                    <div className="info__name">
                        <h1 ref={ref} className="heading-1 BlueDarkText">
                            {ProfileCoach?.firstName} {ProfileCoach?.lastName}
                        </h1>
                        <br />
                    </div>
                    <h5 className="heading-5 BlueDarkText Certified">
                        CERTIFIED OOLA LIFE COACH
                    </h5>
                    <div className="info__achievements coach">
                        {ProfileCoach?.badges?.length > 0 &&
                            ProfileCoach.badges.map((item, index) => (
                                <ImageIcon
                                    key={`BadgetSVG${item.id}`}
                                    index={index}
                                    name={item.id}
                                    type="achievements"
                                    value={0}
                                    item={item}
                                />
                            ))}
                        <button
                            onClick={() => clickButtonAchievements()}
                            type="button"
                        >
                            My achievements
                        </button>
                    </div>
                </div>

               {show && (
                    <AchievementsCoach
                        show={show}
                        showAchievents={showAchievents}
                        setShowError={setShowModal}
                        AllState={props.AllState}
                        AllStateChange={props.AllStateChange}
                        SetShow={SetShow}
                        SetShowAchieventsModal={SetShowAchieventsModal}
                        setBadgeModal={setBadgeModal}
                        badgeSelected={badgeSelected}
                        setShowModal={setShowModal}
                        coachBadgets={true}
                    />
                )}
            </article>
            <div className="UserInfoCoach__buttonsContainer">
                <button
                    className="seeDirectoryList"
                    onClick={() =>
                        setNewData({
                            ProfileCoach: { modalDirectiryListingShow: true },
                        })
                    }
                >
                    See Directory Listing
                </button>
                <button
                    className="editProfile"
                    onClick={() => {
                        setNewData({
                            ProfileCoach: { modalEditProfileShow: true },
                        });
                    }}
                >
                   
                    <a className='fa fa-pencil'/> {' '}
                    Edit profile
                </button>
            </div>
        </>
    );
};

export default UserInfoCoach;
