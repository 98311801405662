import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import DeleteIcon from '../../../../../Assets/img/DELETEICON.svg';

const ToDoList = ({
    itemx,
    item,
    showDeleteModal,
    showCompleteModal,
    EditTask,
}) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const [showGoalTextpop, changeShowGoalTextpop] = useState(false);
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('-');
    }
    const popoverTaskText = (label) => (
        <Popover
            id="popover-basicTaskOverlay"
            style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '100%',
                borderRadius: '0.5rem',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="descriptionQuestion DescriptionPopTaskDashboard"
                    style={{
                        display: 'flex',
                    }}
                >
                    {label}
                </div>
            </Popover.Content>
        </Popover>
    );
    
    const ToDoListR = (
        <section
            className={`ToDoList__ItemContainer ${
                today > new Date(itemx.createdDate) &&
                'ToDoList__ItemContainerError'
            }`}
        >
            <label className="lbl__CompleteTask">
                <input
                    type="radio"
                    className="radio__CompleteTask"
                    name="radio-button"
                    value="css"
                    onClick={() => {
                        showCompleteModal(itemx.id);
                    }}
                />

                <span className="ToDoList__NameItem"></span>
            </label>
            <div className="ToDoList__textTask">
                <span
                    className="ToDoList__NameItem"
                    onMouseEnter={() => changeShowGoalTextpop(true)}
                    onTouchStart={() => changeShowGoalTextpop(true)}
                    onMouseLeave={() => changeShowGoalTextpop(false)}
                >
                    <OverlayTrigger
                        trigger={'click'}
                        placement="bottom"
                        roorootClose
                        overlay={popoverTaskText(itemx.text)}
                        show={showGoalTextpop}
                    >
                        <label className="ToDoList__NameItem">
                            {itemx.text}
                        </label>
                    </OverlayTrigger>
                    {today > new Date(itemx.createdDate) && (
                        <p>{formatDate(itemx.createdDate)}</p>
                    )}
                </span>
            </div>
            <div className="ToDoList__trashIcon">
                <i
                    className="fa fa-pencil ToDoList__PencilIcon"
                    aria-hidden="true"
                    onClick={() =>
                        EditTask({
                            ...itemx,
                            date: item.date,
                        })
                    }
                />
                <figure
                    onClick={() => {
                        showDeleteModal(itemx.id);
                    }}
                >
                    <img src={DeleteIcon} alt="delete" />
                </figure>
            </div>
        </section>
    );

    return ToDoListR;
};
export default ToDoList;
