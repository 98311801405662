import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, useRouteMatch, Link, useParams } from 'react-router-dom';
import AppContext from '../../../Context/AppContext';
import currentUserStep from '../../../util/currentUserStep';
import useScroll from '../../../hooks/useScroll';
import config from '../../../config.json';
import './LoginHeaderWhite.css';

import logoBlack from '../../../Assets/oola_black_logo.svg';
import logoWhite from '../../../Assets/oola_white_logo.svg';

const LoginHeaderWhite = (props) => {
    const { login, checkUserSession, activeCount } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const match = useRouteMatch('/login');
    const form = useRef(null);
    const { webAlias } = useParams();
    const [blockScroll, allowScroll] = useScroll();
    const [typeLogin, setTypeLogin] = useState(1);
    const [textUserId, setTextUserId] = useState('');
    const [errorLogin, seterrorLogin] = useState({
        severity: 0,
        visible: 0,
        message: '',
    });
    const ShowModalExt = props.showModal == null ? showModal : props.showModal;

    function goEnrollment() {
        if (webAlias)
            window.location.href = `${config.enrollmentURL}/${webAlias}/enrollment`;
        else
            window.location.href = `${config.enrollmentURL}/corporphan/enrollment`;
    }

    useEffect(() => {
        if (match?.isExact) {
            if (checkUserSession().token) {
                history.push(currentUserStep(checkUserSession().userStepId));
            } else {
                setShowModal(true);
                blockScroll();
            }
        }
    }, []);

    function modalVisbility(visibility) {
        if (visibility) {
            window.scrollTo(0, 0);
        }
        if (props.showModal == null) setShowModal(visibility);
        else props.setShowModal(visibility);
    }
    const handleLogin = async (event) => {
        event.preventDefault();

        const formData = new FormData(form.current);
        const phone = formData.get('phone');
        const password = formData.get('password');

        const userStep = await login(phone, password);
        if (userStep) {
            allowScroll();
            if (userStep === -1) {
                history.push('/nonSubscriber');
            } else {
                history.push(currentUserStep(userStep));
            }
        }
    };

    return (
        <>
            <header className="LoginHeaderWhite__container">
                <div className="LoginHeaderWhite__logo">
                    <Link to={webAlias ? `/myoola/${webAlias}` : '/home'}>
                        <img
                            className="imgOolaHeaderWhite"
                            src={props.isDarkLogo ? logoBlack : logoWhite}
                            alt="Logo Oola Life"
                        />
                    </Link>
                </div>
                <div className="loginWhiteHeaderH">
                    <div className="loginWhiteHeader">
                        <div className="LoginHeaderWhite__login">
                            {checkUserSession().token && webAlias ? (
                                <Link
                                    to={`${
                                        checkUserSession().userTypeId === 4
                                            ? '/nonSubscriber'
                                            : currentUserStep(
                                                  checkUserSession().userStepId
                                              )
                                    }`}
                                >
                                    <button
                                        className={`btnLoginHeaderWhite ${
                                            !props.isDarkLogo && 'white-Color'
                                        }`}
                                        type="button"
                                    >
                                        DASHBOARD
                                    </button>
                                </Link>
                            ) : (
                                <button
                                    className={`btnLoginHeaderWhite ${
                                        !props?.isDarkLogo && 'white-Color'
                                    }`}
                                    onClick={() => {
                                        if (webAlias)
                                            window.location.href = `https://myoola.oolalife.com/${props.webAlias}/account/login`;
                                        else {
                                            modalVisbility(true);
                                            blockScroll();
                                        }
                                    }}
                                    type="button"
                                >
                                    LOG IN
                                </button>
                            )}
                        </div>
                        <div className="SepatatorLoginButtons">|</div>
                        <div className="LoginHeaderWhite__login EnrollbtnContainer">
                            <button
                                className={`btnEnrollHeaderWhite ${
                                    !props?.isDarkLogo && 'white-Color'
                                }`}
                                onClick={() => {
                                    goEnrollment();
                                }}
                                type="button"
                            >
                                ENROLL NOW
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {ShowModalExt && (
                <section className="login__container">
                    <div className="login__modal">
                        <div className="modal__exit">
                            <button
                                onClick={() => {
                                    if (activeCount) history.push('/');
                                    else {
                                        setShowModal(false);
                                        allowScroll();
                                    }
                                }}
                                type="button"
                                aria-label="Close Modal"
                            />
                        </div>
                        <div className="modal__form">
                            <h1 className="heading-1">LOG IN</h1>
                            <section className="navLogin__content">
                                <button
                                    type="button"
                                    className={
                                        typeLogin === 1
                                            ? 'navLoginActive'
                                            : 'navLogin'
                                    }
                                    onClick={() => {
                                        setTextUserId('');
                                        setTypeLogin(1);
                                    }}
                                >
                                    Phone Number
                                </button>
                                <button
                                    type="button"
                                    className={
                                        typeLogin === 2
                                            ? 'navLoginActive'
                                            : 'navLogin'
                                    }
                                    onClick={() => {
                                        setTextUserId('');
                                        setTypeLogin(2);
                                    }}
                                >
                                    Customer Id
                                </button>
                            </section>
                            {errorLogin.visible === 1 && (
                                <section
                                    className={`modalMessageLogin modalMessageLoginServerity${errorLogin.severity}`}
                                >
                                    <div className="btnCloseMessage__Message">
                                        {errorLogin.message}
                                    </div>
                                    <div
                                        aria-hidden="true"
                                        aria-label="Close Modal"
                                        className="btnClosMessage__container "
                                        onClick={() =>
                                            seterrorLogin({ visible: 0 })
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="84.853"
                                            height="84.853"
                                            viewBox="0 0 84.853 84.853"
                                            className="btnCloseMessage"
                                            aria-label="Close Modal"
                                        >
                                            <g
                                                id="Group_52"
                                                transform="rotate(-45 384.027 1951.387)"
                                            >
                                                <g
                                                    id="Group_69"
                                                    transform="translate(1472.934 340.616)"
                                                >
                                                    <path
                                                        id="Line_12"
                                                        d="M38.957 0L0 0"
                                                        fill="none"
                                                        strokeWidth="4px"
                                                        stroke="#363535"
                                                        transform="translate(0 19.479)"
                                                    />
                                                    <path
                                                        id="Line_13"
                                                        d="M0 38.957L0 0"
                                                        fill="none"
                                                        strokeWidth="4px"
                                                        stroke="#363535"
                                                        transform="translate(19.479)"
                                                    />
                                                </g>
                                                <g
                                                    id="Ellipse_53"
                                                    fill="none"
                                                    strokeWidth="4px"
                                                    stroke="#363535"
                                                    transform="translate(1462.318 330)"
                                                >
                                                    <circle
                                                        cx="30"
                                                        cy="30"
                                                        r="30"
                                                    />
                                                    <circle
                                                        cx="30"
                                                        cy="30"
                                                        r="28"
                                                        fill="none"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </section>
                            )}
                            <form ref={form} onSubmit={handleLogin}>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder={
                                        typeLogin === 1
                                            ? 'Cell Phone Number'
                                            : 'Customer Id'
                                    }
                                    pattern={
                                        typeLogin === 1
                                            ? '([0-9]{3}-[0-9]{3}-[0-9]{4})|([0-9]{10})'
                                            : null
                                    }
                                    title="111-111-1111"
                                    value={textUserId}
                                    onChange={(input) =>
                                        setTextUserId(input.value)
                                    }
                                    required
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    required
                                />
                                <button
                                    className="button-empty-big"
                                    type="submit"
                                >
                                    LET’S GO!
                                </button>
                            </form>
                        </div>
                        <div className="modal__footer">
                            <div>Problem with your log in?</div>
                            <a
                                href="https://mybusiness.oolalife.com/forgotpassword"
                                aria-label="reset my password"
                            >
                                Reset my Password
                            </a>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default LoginHeaderWhite;
