import React, { useState, useContext } from 'react';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import SelectedOneGoalConfirm from './TemplateGoals/SelectedOneGoalConfirm';
import './OolaOneSectionSelect.css';
import configData from '../../../config.json';

const SelectOneSectionConfirm = (props) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const currUser = checkUserSession();
    const [selectedOolaOne, ChangeselectedOolaOne] = useState(0);

    function selectOolaOneGoal(indexF, itemF) {
        for (let i = 0; i < props.AllState.SelectedGoalsSeven.length; i++) {
            props.AllState.SelectedGoalsSeven[i].selectedOne = false;
        }
        props.AllState.SelectedGoalsSeven[indexF].selectedOne = true;
        if (currUser.userStepId != 7) props.handleChangeHeader(1);

        props.AllState.SelectedOolaOne = itemF;
        props.AllState.changeData(props.AllState);
        props.forceUpdate();
        ChangeselectedOolaOne(1);
    }

    function finishSelectOolaOne() {
        if (selectedOolaOne === 1) {
            const OolaOneItem = props.AllState.SelectedGoalsSeven.find(
                (x) => x.selectedOne === true
            );
            changeLoading(true);
            const url = `${configData.SERVER_URL}Goals/SetOolaOne/${OolaOneItem.GoalId}`;
            axios
                .put(url,{}, {
                    headers: {
                        Authorization: `Bearer ${currUser.token}`,
                    },
                })
                .then(() => {

                    changeLoading(false);
                    props.changeStep();
                })
                .catch((error) => {
                    console.log(error);
                    changeLoading(false);
                });
        } else {
            alert('Select your Oola one');
        }
    }

    const SelectOneSectionConfirmR = (
        <div className="selectOolaOne__container">
            <div className="RecomendationTitleOne">
                SELECT YOUR OOLA ONE GOAL:
            </div>
            <div className="RecomendationDescOne">
                Select the single goal you most want to archive in the next 365
                days
            </div>
            <ul style={{ listStyle: 'none', marginLeft: '-40px' }}>
                {props.AllState.SelectedGoalsSeven.map((itemF, indexF) => (
                    <li key={indexF} style={{ display: 'flex' }}>
                        <SelectedOneGoalConfirm
                            selectOolaOneGoal={selectOolaOneGoal}
                            updateIndexStep2={props.updateIndexStep2}
                            changeData={props.changeData}
                            AllState={props.AllState}
                            itemF={itemF}
                            indexF={indexF}
                            hideNav={false}
                            Fs={props.AllState.Fs[itemF.areaId]}
                        />
                    </li>
                ))}
            </ul>
            <div style={{ flex: 1, display: 'flex', width: '98%' }}>
                <div
                    aria-hidden="true"
                    className="btnNextStep2One"
                    style={{ color: '#D86B59', marginLeft: 'auto' }}
                    onClick={() => finishSelectOolaOne()}
                >
                    NEXT {'>'}
                </div>
            </div>
        </div>
    );
    return SelectOneSectionConfirmR;
};

export default SelectOneSectionConfirm;
