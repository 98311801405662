/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { throttle } from 'throttle-debounce';
import AppContext from '../../../../../../Context/AppContext';
import requestAxios from '../../../../../../util/requestAxios';
import CoachInfoCard from '../CoachInfoCard/CoachInfoCard';
import './FindMyCoach.css';

const FindMyCoach = ({ setNewData }) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token } = checkUserSession();

    const [searchResult, setSearchResult] = useState();
    const [selectedCoach, setSelectedCoach] = useState(0);

    const findCoach = throttle(1000, async (term) => {
        const { error, data } = await requestAxios({
            url: `FindCoach/GetCoachBySearchTerm/${term}`,
            token,
        });

        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            setSearchResult(data);
        }
    });

    const onSearchChange = (event) => {
        if (event.target.value) {
            findCoach(event.target.value);
        }
    };

    const onSelectCoach = () => {
        setNewData({
            FindCoach: { way: 3 },
            selectedCoach,
        });
    };

    return (
        <>
            <Form style={{ width: '100%', height: '100%' }}>
                <div className="findMyCoach-form">
                    <div className="findMyCoach-form-header">
                        <div className="findMyCoach-title_container">
                            <h3 className="heading-4">FIND MY COACH</h3>
                            {selectedCoach?.id > 0 && (
                                <button
                                    className="button-full-small SelectConfirmFindTextCoachBtn Desktop"
                                    type="button"
                                    onClick={onSelectCoach}
                                >
                                    CONFIRM AND PROCEED
                                </button>
                            )}
                        </div>
                        <Form.Group controlId="name">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                className="form-input-1 "
                                type="text"
                                onChange={onSearchChange}
                            />
                        </Form.Group>
                        <div className="findMyCoach-text_button__container">
                            <button
                                className={`findMyCoach-text_button ${
                                    searchResult && 'searchTerm'
                                }`}
                                type="submit"
                                onClick={() =>
                                    setNewData({
                                        FindCoach: { way: 2, step: 1 },
                                    })
                                }
                            >
                                I want Oola to help me find a coach
                            </button>
                        </div>
                    </div>
                </div>
                {selectedCoach?.id > 0 && (
                    <button
                        className="button-full-small SelectConfirmFindTextCoachBtn Mobile"
                        type="button"
                        onClick={onSelectCoach}
                    >
                        CONFIRM AND PROCEED
                    </button>
                )}
            </Form>

            {searchResult && (
                <section className="findMyCoach-cards_container">
                    {searchResult?.map((result) => (
                        <div
                            className={`OurTopPickCardItem__container ${
                                result?.id === selectedCoach?.id && 'selected'
                            }`}
                            key={result?.id}
                        >
                            <CoachInfoCard
                                data={result}
                                onSelect={() => setSelectedCoach(result)}
                            />
                        </div>
                    ))}
                </section>
            )}
        </>
    );
};

export default FindMyCoach;
