import React, {  useEffect } from 'react';
import { Link, useParams,useHistory  } from 'react-router-dom';
import axios from 'axios';
import Header from '../Common/Header/Header';
import './NotFound.css';
import configData from '../../config.json';

const NotFound = () => {
    

    useEffect(() => {
        const history = useHistory();
        const id = useParams();
        const url = `${
            configData.SERVER_URL
        }Customer/GetAmbassadorProfileInformation/${id[0] + id.id}`;
        axios
            .get(url, {})
            .then((result) => {
                history.push(`myoola/${id[0] + id.id}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <Header />
            <section className="notFound__container">
                <h2>Sorry this page doesn&rsquo;t exist!</h2>
                <Link to="/">
                    <button type="button">GO BACK</button>
                </Link>
            </section>
        </>
    );
};

export default NotFound;
