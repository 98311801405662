import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import './CoachCalendar.css';
export default ({ urlUserName, closeEvent }) => {
    const { getData, checkUserSession, changeLoading } = useContext(AppContext);
    const { ProfileCoach } = getData();

    useCalendlyEventListener({
        onProfilePageViewed: () => getCustomerProfileInformation(),
        onDateAndTimeSelected: (e, x) => getCustomerProfileInformation(),
        onEventTypeViewed: () => getCustomerProfileInformation(),
        onEventScheduled: (e, x) => {
            getCustomerProfileInformation();
        },
    });
    useEffect(() => {
        getCustomerProfileInformation();
    }, []);
    async function getCustomerProfileInformation() {
        changeLoading(true);
        const us = checkUserSession();
        const url = `Customer/GetCustomerProfileInfo/${us.userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token: us.token,
        });
        if (error) {
            console.log(error);
            window.location.reload();
        } else {
            if (data.sessionsRemaining == 0) window.location.reload();
        }
        changeLoading(false);
    }

    return (
        <div className="CoachCalendar__container">
            <button
                className="exit"
                onClick={() => closeEvent(false)}
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                >
                    <defs>
                        <clipPath id="b">
                            <rect width="40" height="40" />
                        </clipPath>
                    </defs>
                    <g id="a" clipPath="url(#b)">
                        <g transform="translate(-1269.135 820.365) rotate(-45)">
                            <g transform="translate(-16803 -3223)">
                                <line
                                    x2="20"
                                    transform="translate(18270.5 3568.5)"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="3"
                                />
                                <line
                                    y1="20"
                                    transform="translate(18280.5 3558.5)"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="3"
                                />
                            </g>
                            <g
                                transform="translate(1462 330)"
                                fill="none"
                                stroke="white"
                                strokeWidth="3"
                            >
                                <circle
                                    cx="15.5"
                                    cy="15.5"
                                    r="15.5"
                                    stroke="none"
                                />
                                <circle
                                    cx="15.5"
                                    cy="15.5"
                                    r="14"
                                    fill="none"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <InlineWidget
                url={`https://calendly.com/${urlUserName}`}
                apiKey="eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc5NTMyNTMxLCJqdGkiOiJmZTgzZDk1Ni1mMGVjLTQyZTQtOTk0Ny03ZmMxZGUwNTE2YTEiLCJ1c2VyX3V1aWQiOiI2OTQxOGU1Ny1kMzgyLTQzNGUtOGQ4Zi1lZjQxNTQ4ZDMwMjUifQ.aBYEcbsKQVCr3Ob17FABUdzWd5w_NN7rDDoTCXumgSsLFGtpP0_jCslrQztjoHJSxGVN-jRQn7MMHDZDXOEGAA"
                prefill={{
                    name: `${ProfileCoach.currentClient.firstName} ${ProfileCoach.currentClient.lastName}`,
                    customAnswers: {
                        a1: 'answer1',
                        a2: 'answer2',
                    },
                }}
            />
        </div>
    );
};
