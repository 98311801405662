import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import configData from '../../../../../../../config.json';
import AppContext from '../../../../../../../Context/AppContext';
import ELearningCard from '../../../../Board/ELearningCard';
import LinearProgressWithLabel from '../../../../../../Common/LinearProgressWithLabel/LinearProgressWithLabel.jsx';
import './PurchasedCourses.css';
import CollapsableSection from './Components/CollapsableSection.jsx';
import CertificationQuiz from '../../../../../ELearning/Sections/CertificationQuiz.jsx';

const PurchasedCourses = ({ item }) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token, userId } = checkUserSession();

    const history = useHistory();

    const [modules, setModules] = useState([]);
    const [certificationModule, setCertificationModule] = useState([]);
    const [courseProgress, setCourseProgress] = useState(null);
    const [init, setInit] = useState(false);
    function goToNewCourse(id) {
        history.push(`AssesmentNewModules?courseId=${id}`);
        item.AssesmentComplete;
    }

    function removeElearning() {
        localStorage.removeItem('ModuleId');
        localStorage.removeItem('isAditionalCourse');
        localStorage.removeItem('isCertificationModule');
    }

    useEffect(() => {
        removeElearning();

        if (item.isCoachCertification) {
            changeLoading(true);
            const url = `${configData.SERVER_URL}AdditionalCourse/GetCertificationModules/${item.id}`;

            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((result) => {
                    setCertificationModule(result.data.sections);
                    setInit(true);
                    changeLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setCertificationModule([]);
                    changeLoading(false);
                    setInit(false);
                });
        } else {
            const url = `${configData.SERVER_URL}AdditionalCourse/GetELearningModules/${item.id}`;

            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((result) => {
                    setModules(result.data.modules);
                    if (
                        result.data.courseProgress != null &&
                        result.data.courseProgress?.courseDays > 0
                    ) {
                        const obj = {
                            text: `Day ${result.data.courseProgress.currentDay} of ${result.data.courseProgress.courseDays}`,
                            value:
                                (result.data.courseProgress.currentDay /
                                    result.data.courseProgress.courseDays) *
                                100,
                        };
                        setCourseProgress(obj);
                    } else {
                        setCourseProgress(null);
                    }

                    setInit(true);
                })
                .catch((error) => {
                    console.log(error);
                    setModules([]);
                    setCourseProgress(null);
                    setInit(false);
                });
        }
    }, []);

    const getItemStepsActive = (index) => {
        if (index === 0 && item.steps[index].completed === false) return true;

        if (
            item.steps[index].completed === false &&
            item.steps[index - 1].completed === true
        )
            return true;

        return false;
    };

    function goELearning(stepId) {
        localStorage.setItem('ModuleId', stepId);
        // localStorage.removeItem('isAditionalCourse');
        localStorage.setItem('isAditionalCourse', 1);
        history.push('/ELearning');
    }
    // if (!init) {
    //     return <div className='init_loading'>Loading...</div>;
    // }

    const renderModule = (moduleTitle) => {
        switch (moduleTitle) {
            case 'Mind & Body Coach Certification':
                return (
                    <div>
                        {/* <CertificationQuiz /> */}
                        {certificationModule.map((moduleItem) => (
                            <CollapsableSection
                                moduleItem={moduleItem}
                                key={moduleItem.title}
                            />
                        ))}
                    </div>
                );
            case 'Who Are You?':
                return (
                    <div>
                        {item?.steps?.map((step, index) => (
                            <article
                                key={step.lessonId}
                                className={`purchasedCourses_whoAreYou__button ${
                                    !getItemStepsActive(index) && 'disabled'
                                }`}
                                onClick={() =>
                                    getItemStepsActive(index) &&
                                    goELearning(step.lessonId)
                                }
                                type="button"
                            >
                                <div className="purchasedCourses_whoAreYou__buttonText">
                                    {step.title}
                                </div>
                                {getItemStepsActive(index) && (
                                    <div className="purchasedCourses_whoAreYou__buttonPulse">
                                        <svg>
                                            <g transform="translate(40 40)">
                                                <g
                                                    className="pulseAnimation"
                                                    style={{ opacity: '0.5' }}
                                                >
                                                    <circle
                                                        r="25"
                                                        fill="white"
                                                    />
                                                    <circle
                                                        r="23"
                                                        fill="#d86b59"
                                                    />
                                                    <circle
                                                        r="17"
                                                        fill="white"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                )}
                            </article>
                        ))}
                    </div>
                );
            default:
                return (
                    <section
                        className="NewModulesCard__Container NewModulesCard__GetStarted"
                        onClick={() => goToNewCourse(item.id)}
                    >
                        <div className="NewModulesCard__image__container">
                            <div
                                className="NewModulesCard__image"
                                style={{
                                    background: `url("${configData.SERVER_URL_IMG}${item.imageUrl}")`,
                                    backgroundSize: 'cover',
                                }}
                            />
                        </div>
                        <div className="PurchasedCoursesModulesCard__textContainer">
                            {modules.length === 0 && (
                                <svg style={{ width: '200px', height: '50px' }}>
                                    <g transform="translate(160 25)">
                                        <text
                                            className="PurchasedCourses_GetStartedText"
                                            transform="translate(-160 10)"
                                        >
                                            Get Started
                                        </text>
                                        <g className="pulseAnimation">
                                            <circle r="20" fill=" #d86b59" />
                                            <circle r="17" fill="white" />
                                            <circle r="13" fill=" #d86b59" />
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </section>
                );
        }
    };

    return modules?.length === 0 ? (
        renderModule(item.title)
    ) : (
        // renderModule('Mind and Body Coach Certification')
        <div className="PurchasedCoursesModules__container">
            {courseProgress && (
                <div className="ProgressBarPurchase__Container">
                    <LinearProgressWithLabel
                        value={courseProgress.value}
                        text={courseProgress.text}
                    />
                </div>
            )}
            <div className="eLearningBoard__modules OolaScroll PurchasedCourses">
                {modules.map((itemx, i) => (
                    <ELearningCard
                        index={i}
                        item={itemx}
                        configData={configData}
                        showLeftBorder={i % 2}
                        key={'eLearningBoard__modules' + itemx.id}
                        isAditionalCourse={1}
                    />
                ))}
            </div>
        </div>
    );
};

export default PurchasedCourses;
