import React, { useState } from 'react';

const GoalBadge = (props) => {
    const GoalBadgeR = (        
        <svg xmlns="http://www.w3.org/2000/svg" width="100%"  height="100%" viewBox="0 0 498 498" className={props.className}>
            
            <defs>
                <clipPath id="clip-goal_6">
                <rect width="498" height="498"/>
                </clipPath>
            </defs>
            <g id="goal_6" data-name="goal 6" clipPath="url(#clip-goal_6)">
                <g id="Group_743" data-name="Group 743" transform="translate(4003 -1924)">
                <path id="Path_1694" data-name="Path 1694" d="M507.4,260.25c0-136.5-110.65-247.15-247.15-247.15S13.1,123.75,13.1,260.25,123.75,507.4,260.25,507.4,507.4,396.749,507.4,260.25Z" transform="translate(-4014.1 1912.9)" fill="#f5f5f5"/>
                <g id="goal_6-2" data-name="goal 6" transform="translate(-3997 1931)">
                    <g id="Layer_2_1_" transform="translate(0 0)">
                    <g id="Group_170" data-name="Group 170">
                        <path id="Path_339" data-name="Path 339" d="M487.259,250.18c0-130.938-106.142-237.08-237.08-237.08S13.1,119.242,13.1,250.18s106.142,237.08,237.08,237.08S487.259,381.117,487.259,250.18Z" transform="translate(-7.612 -7.612)" fill="#fff"/>
                        <g id="Group_168" data-name="Group 168" transform="translate(13.917 13.917)">
                        <path id="Path_340" data-name="Path 340" d="M250.351,479A228.557,228.557,0,0,1,88.737,88.737a228.522,228.522,0,1,1,323.228,323.13A226.53,226.53,0,0,1,250.351,479Zm0-444.757c-119.177,0-216.106,96.929-216.106,216.106s96.929,216.106,216.106,216.106,216.106-96.929,216.106-216.106S369.528,34.245,250.351,34.245Z" transform="translate(-21.7 -21.7)" fill="#2c414c"/>
                        </g>
                        <g id="Group_169" data-name="Group 169">
                        <path id="Path_341" data-name="Path 341" d="M250.068,492.636A242.556,242.556,0,0,1,78.555,78.555,242.556,242.556,0,1,1,421.581,421.581,241.264,241.264,0,0,1,250.068,492.636Zm0-479.158c-130.448,0-236.59,106.142-236.59,236.59s106.142,236.59,236.59,236.59,236.59-106.142,236.59-236.59S380.516,13.478,250.068,13.478Z" transform="translate(-7.5 -7.5)" fill="#f5f5f5"/>
                        </g>
                    </g>
                    </g>
                    <g id="Layer_1_3_" transform="translate(49.378 46.896)">
                    <g id="Group_171" data-name="Group 171">
                        <path id="Path_342" data-name="Path 342" d="M234.8,61.377a22.654,22.654,0,0,1,30.774,0c8.527,8.037,24.11,10.781,34.793,6.174a22.432,22.432,0,0,1,28.814,10.487c5.194,10.487,18.915,18.425,30.578,17.641a22.47,22.47,0,0,1,23.522,19.7c1.372,11.565,11.467,23.816,22.738,27.05a22.556,22.556,0,0,1,15.387,26.56c-2.744,11.369,2.646,26.266,12.055,33.126a22.578,22.578,0,0,1,5.39,30.284c-6.468,9.7-6.468,25.58,0,35.283a22.5,22.5,0,0,1-5.39,30.284c-9.409,6.959-14.9,21.856-12.055,33.126a22.664,22.664,0,0,1-15.387,26.658c-11.173,3.332-21.464,15.485-22.738,27.148a22.47,22.47,0,0,1-23.522,19.7c-11.663-.784-25.482,7.155-30.578,17.641a22.432,22.432,0,0,1-28.814,10.487c-10.683-4.7-26.266-1.96-34.793,6.174a22.654,22.654,0,0,1-30.774,0c-8.527-8.037-24.11-10.781-34.793-6.174A22.4,22.4,0,0,1,171.2,422.24C166,411.754,152.281,403.815,140.618,404.6A22.47,22.47,0,0,1,117.1,384.9c-1.274-11.565-11.467-23.816-22.738-27.148a22.664,22.664,0,0,1-15.387-26.658c2.744-11.369-2.646-26.266-12.055-33.126a22.578,22.578,0,0,1-5.39-30.284c6.468-9.7,6.468-25.58,0-35.283a22.5,22.5,0,0,1,5.39-30.284c9.409-6.959,14.8-21.856,12.055-33.126a22.664,22.664,0,0,1,15.387-26.658c11.173-3.332,21.464-15.485,22.738-27.05a22.47,22.47,0,0,1,23.522-19.7c11.663.784,25.482-7.155,30.578-17.641A22.432,22.432,0,0,1,200.01,67.454C210.693,72.158,226.374,69.414,234.8,61.377Z" transform="translate(-57.882 -55.35)" fill="#2c414c"/>
                    </g>
                    </g>
                    <g id="Group_172" data-name="Group 172" transform="translate(232.081 144.463)">
                        <text fontFamily="US101" textAnchor="middle" fontSize="60" letterSpacing="-.05em" x="7" y="43" fill="#fff">{props.value}</text>
                    </g>
                    <g id="Group_173" data-name="Group 173" transform="translate(162.692 89.971)">
                    <path id="Path_344" data-name="Path 344" d="M309.167,222.845H197.732a3.3,3.3,0,0,1-3.332-3.332v-84.58a3.3,3.3,0,0,1,3.332-3.332H309.167a3.3,3.3,0,0,1,3.332,3.332v84.58A3.3,3.3,0,0,1,309.167,222.845Zm-108.1-6.665H305.932V138.166H201.065V216.18Z" transform="translate(-173.917 -99.944)" fill="#fff"/>
                    <path id="Path_345" data-name="Path 345" d="M197.316,222.842a4.92,4.92,0,0,1-1.176-.2l-20.484-7.841a3.406,3.406,0,0,1-2.156-3.136v-69a3.406,3.406,0,0,1,2.156-3.136L196.14,131.7a3.094,3.094,0,0,1,3.038.392,3.391,3.391,0,0,1,1.47,2.744v84.58a3.155,3.155,0,0,1-1.47,2.744A2.587,2.587,0,0,1,197.316,222.842Zm-17.151-13.427,13.819,5.292V139.732l-13.819,5.292Z" transform="translate(-173.5 -99.941)" fill="#fff"/>
                    <path id="Path_346" data-name="Path 346" d="M311.432,222.845a3.227,3.227,0,0,1-1.862-.588,3.391,3.391,0,0,1-1.47-2.744v-84.58a3.155,3.155,0,0,1,1.47-2.744,3.362,3.362,0,0,1,3.038-.392l20.484,7.841a3.406,3.406,0,0,1,2.156,3.136v69a3.406,3.406,0,0,1-2.156,3.136l-20.484,7.841C312.314,222.747,311.824,222.845,311.432,222.845Zm3.332-83.11V214.71l13.819-5.292V145.027Z" transform="translate(-176.182 -99.943)" fill="#fff"/>
                    <path id="Path_347" data-name="Path 347" d="M267.976,122.626H240.632a3.3,3.3,0,0,1-3.332-3.332v-3.038A16.961,16.961,0,0,1,254.255,99.3h.2a16.961,16.961,0,0,1,16.955,16.955v3.038A3.453,3.453,0,0,1,267.976,122.626Zm-24.012-6.566h20.778a10.361,10.361,0,0,0-10.291-10h-.2A10.294,10.294,0,0,0,243.965,116.059Z" transform="translate(-174.771 -99.3)" fill="#fff"/>
                    <path id="Path_348" data-name="Path 348" d="M276.428,137.962h-44.5a3.3,3.3,0,0,1-3.332-3.332V124.143a7.759,7.759,0,0,1,7.743-7.743h35.479a7.759,7.759,0,0,1,7.743,7.743v10.487A3.145,3.145,0,0,1,276.428,137.962ZM235.264,131.3H273.1v-7.155a1.132,1.132,0,0,0-1.176-1.176H236.441a1.132,1.132,0,0,0-1.176,1.176Z" transform="translate(-174.598 -99.641)" fill="#fff"/>
                    <path id="Path_349" data-name="Path 349" d="M254.451,399.222h-.2A16.961,16.961,0,0,1,237.3,382.267V236.432a3.3,3.3,0,0,1,3.332-3.332h27.344a3.3,3.3,0,0,1,3.332,3.332V382.267A16.94,16.94,0,0,1,254.451,399.222ZM243.965,239.764v142.5a10.29,10.29,0,0,0,10.291,10.291h.2a10.289,10.289,0,0,0,10.291-10.291v-142.5Z" transform="translate(-174.771 -101.966)" fill="#fff"/>
                    <path id="Path_350" data-name="Path 350" d="M271.921,239.462H236.443a7.759,7.759,0,0,1-7.743-7.743V221.232a3.3,3.3,0,0,1,3.332-3.332h44.5a3.3,3.3,0,0,1,3.332,3.332v10.487A7.994,7.994,0,0,1,271.921,239.462Zm-36.655-14.9v7.155a1.132,1.132,0,0,0,1.176,1.176h35.479a1.132,1.132,0,0,0,1.176-1.176v-7.155Z" transform="translate(-174.6 -101.663)" fill="#fff"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    );
    return GoalBadgeR;
};
export default GoalBadge;