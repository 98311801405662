import React, { useState, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import ModalError from '../../../Components/Step2/SelectGoals/TemplateGoals/ModalError';
import configData from '../../../config.json';
import Slice from '../Wheel/Slices';
import AppContext from '../../../Context/AppContext';

const Questions = (props) => {
    const { checkUserSession, updateUserStepId, changeLoading } =
        useContext(AppContext);
    const currUser = checkUserSession();
    const currentQ =
        props.Fs.FData.questions[props.Fs.AllState.currentQuestion] == null
            ? {}
            : props.Fs.FData.questions[props.Fs.AllState.currentQuestion];
    const _index =
        props.Fs.FData.questions.filter((x) => x.score == null).length !== 10
            ? props.Fs.AllState.currentQuestion
            : 0;
    const [activeInput, ChangeActiveInput] = useState(1);
    const [saving, savingChange] = useState(0);
    const [showModalError, showModalErrorChange] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const isSafari = currUser.browser === 'Apple Safari';

    function saveQuestionF() {
        savingChange(1);
        changeLoading(true);

        const fullState = { ...props.Fs.AllState };
        const UserState = checkUserSession();
        const PostObj = {
            userId: currUser.userId,
            assessmentId: UserState.assessmentId,
            AssessmentTypeId:
                currUser.userStepId === 6
                    ? 3
                    : currUser.userStepId === 5
                    ? 2
                    : 1,
            scores: fullState.Fs[fullState.index].questions.map((item) => ({
                questionId: item.id,
                score: item.score,
            })),
        };

        const url = `${configData.SERVER_URL}Scores/ScorePartialSave`;
        return new Promise((resolve, reject) => {
            axios
                .post(url, PostObj, {
                    headers: {
                        Authorization: `Bearer ${currUser.token}`,
                    },
                })
                .then(() => {
                    updateUserStepId();

                    fullState.Fs[fullState.index].questions.forEach((itemx) => {
                        itemx.edited = null;
                    });
                    props.Fs.mChange(fullState);
                    savingChange(0);
                    changeLoading(false);

                    console.log(`Step 1 F${fullState.index + 1} Saved`);
                    resolve(true);
                })
                .catch((error) => {
                    savingChange(0);
                    console.log(error.toJSON());
                    showModalErrorChange(true);
                    changeLoading(false);
                    resolve(false);
                });
        });
    }

    async function changeIndex(
        propsL,
        i,
        input,
        fId,
        isNavButtonClick,
        fromNextF
    ) {
        const score = Number(input);
        const fullState = { ...propsL.Fs.AllState };
        if ((score === 0 || input == null) && fId != null) {
            return false;
        }
        if (fromNextF === true) {
            let msjError = '';
            fullState.Fs[fullState.index].questions.forEach((item, i) => {
                if (item.score === 0) msjError = msjError + `${i + 1}, `;
            });
            msjError = msjError.substr(0, msjError.length - 2);
            if (msjError !== '') {
                alert(
                    `Error when trying save the questions, please verify the questions ${msjError}`
                );
                fullState.currentQuestion = Number(msjError.split(',')[0]) - 1;
                propsL.Fs.mChange(fullState);
                return false;
            }
        }
        ChangeActiveInput(0);
        forceUpdate();
        setTimeout(() => {
            setTimeout(() => {
                ChangeActiveInput(1);
            }, 1000);
            forceUpdate();
        }, 500);
        if (input != null) {
            propsL.selectScore(fId, Number(input));
        }

        let questionDelay = 500;
        if (
            isNavButtonClick ||
            propsL.Fs.AllState.currentQuestion + i === 11 ||
            i < 0
        ) {
            questionDelay = 0;
        }

        let result = true;
        if (propsL.Fs.AllState.currentQuestion + i === 11) {
            result = await saveQuestionF(propsL.Fs.AllState);
        }
        if (result) {
            if (propsL.Fs.AllState.currentQuestion + i === 10) {
                setTimeout(() => {
                    propsL.Fs.AllState.Fs.forEach((item, ix) => {
                        if (propsL.Fs.AllState.index >= ix) {
                            for (let c = 0; c < item.questions.length; c += 1) {
                                if (propsL.Fs.AllState.index - 1 < ix) {
                                    const question = item.questions[c];
                                    question.score =
                                        question.score == null
                                            ? 1
                                            : question.score;
                                }
                            }
                        }

                        const sum = item.questions.reduce(
                            (pv, cv) => pv + (cv.score != null ? cv.score : 0),
                            0
                        );
                        item.TotalScore = sum === 0 ? null : sum;
                        fullState.index =
                            fullState.currentQuestion + i < 0
                                ? propsL.Fs.AllState.index - 1
                                : propsL.Fs.AllState.index;
                        fullState.currentQuestion =
                            propsL.Fs.AllState.currentQuestion + i < 0 ? 0 : 10;
                        propsL.Fs.mChange(propsL.Fs.AllState);
                    });
                    propsL.Fs.mChange(fullState);
                }, 300 + questionDelay);
            } else {
                propsL.Fs.AllState.Fs.forEach((item, i) => {
                    item.TotalScore = null;
                });

                if (
                    propsL.Fs.AllState.currentQuestion + i === 11 &&
                    fullState.index == 6 &&
                    fullState.User.userStepId === 6
                ) {
                    props.Fs.AllState.changeStepIndex(7);
                } else {
                    propsL.Fs.mChange(fullState);
                }
            }

            setTimeout(() => {
                if (i === 1) {
                    if (propsL.Fs.AllState.currentQuestion !== 10) {
                        propsL.Fs.AllState.currentQuestion =
                            propsL.Fs.AllState.currentQuestion === 10
                                ? 10
                                : propsL.Fs.AllState.currentQuestion + i;
                        propsL.Fs.mChange(propsL.Fs.AllState);
                    } else if (propsL.Fs.AllState.index !== 10) {
                        propsL.Fs.AllState.index = propsL.Fs.AllState.index + 1;
                        propsL.Fs.AllState.lastF =
                            propsL.Fs.AllState.lastF <
                            propsL.Fs.AllState.index + 1
                                ? propsL.Fs.AllState.index + 1
                                : propsL.Fs.AllState.lastF;
                        propsL.Fs.AllState.currentQuestion = 0;
                        propsL.Fs.mChange(propsL.Fs.AllState);
                    }
                } else if (propsL.Fs.AllState.currentQuestion !== 0) {
                    propsL.Fs.AllState.currentQuestion =
                        propsL.Fs.AllState.currentQuestion === 0
                            ? 0
                            : propsL.Fs.AllState.currentQuestion + i;
                    propsL.Fs.mChange(propsL.Fs.AllState);
                } else if (propsL.Fs.AllState.index !== 0) {
                    propsL.Fs.iChange(propsL.Fs.AllState.index - 1);
                    propsL.Fs.AllState.index -= 1;
                    propsL.Fs.AllState.currentQuestion = 9;
                    propsL.Fs.mChange(propsL.Fs.AllState);
                }
                if (propsL.Fs.AllState.index === 7) {
                    propsL.Fs.AllState.confetti = 1;
                    propsL.Fs.AllState.Fs.forEach((item, i) => {
                        item.TotalScore = null;
                    });
                    propsL.Fs.mChange(propsL.Fs.AllState);
                    propsL.Fs.iChange(7);
                    setTimeout(() => {
                        propsL.Fs.AllState.confetti = 1;
                        propsL.Fs.AllState.index = 7;
                        propsL.Fs.AllState.Fs.forEach((item, i) => {
                            const sum = item.questions.reduce(
                                (pv, cv) =>
                                    pv + (cv.score != null ? cv.score : 0),
                                0
                            );

                            item.TotalScore = sum === 0 ? null : sum;
                        });
                        propsL.Fs.mChange(propsL.Fs.AllState);
                    }, 500);
                    setTimeout(() => {
                        propsL.Fs.AllState.confetti = 0;
                        propsL.Fs.mChange(propsL.Fs.AllState);
                    }, 3000);
                }
            }, questionDelay);
        }
    }

    function getNumberrange(n, item) {
        const elements = [];
        for (let i = 1; i <= 10; i += 1) {
            elements.push(
                <p
                    aria-hidden="true"
                    onClick={() => changeIndex(props, 1, i, item.id)}
                    key={`NumberValueRange${item.id}${i}`}
                    style={{
                        fontSize:
                            props.hideNav === false ? '' : '15px !important',
                        cursor: 'pointer',
                        color: `${
                            item.score === i ? props.Fs.FData.color : ''
                        }`,
                        opacity: `${
                            item.score === i ||
                            item.score === 0 ||
                            item.score == null
                                ? ''
                                : '.6'
                        }`,
                    }}
                    className={`tickmarks__numbers rangeNum  `}
                >
                    {i}
                </p>
            );
        }
        return elements;
    }
    return (
        <div className="question__container">
            {props.Fs.AllState.currentQuestion + 1 < 11 && (
                <div className="questionNumber__container">
                    <h5
                        className="heading-6"
                        style={{ color: props.Fs.FData.color }}
                    >
                        QUESTION {props.Fs.AllState.currentQuestion + 1}
                        /10
                    </h5>
                    <button
                        className="btnSavePartial"
                        onClick={() => saveQuestionF()}
                        type="button"
                    >
                        {saving === 0 ? 'SAVE AND CONTINUE LATER' : 'SAVING'}
                    </button>
                </div>
            )}
            <SwipeableViews
                index={_index}
                disabled
                className='questionData__containerSwipeable'
                style={{
                    height:
                        props.Fs.AllState.currentQuestion !== 10
                            ? '70%'
                            : '100%',
                }}
            >
                {props.Fs.FData.questions.map((item, i) => (
                    <div
                        key={`questionData__container${item.id}${i}`}
                        className="questionData__container"
                        style={{
                            pointerEvents:
                                activeInput === 1 ? 'initial' : 'none',
                        }}
                    >
                        <div className="questionData__texts">
                            <h2> {item.title} </h2>
                            <p>{item.text} </p>
                        </div>

                        <div className="line__container" key={item.id}>
                            <input
                                id={`range${item.id}Q${props.Fs.FData.id}`}
                                type="range"
                                min="1"
                                max="10"
                                className="rangeInput"
                                disabled={!activeInput}
                                onChange={(input) => {
                                    if (activeInput === 1) {
                                        props.selectScore(
                                            item.id,
                                            input.target.value
                                        );
                                    }
                                }}
                                onClick={(input) => {
                                    if (activeInput === 1) {
                                        ChangeActiveInput(0);

                                        setTimeout(() => {
                                            changeIndex(
                                                props,
                                                1,
                                                input.target.value,
                                                item.id
                                            );
                                            forceUpdate();
                                        }, 500);
                                    }
                                }}
                                onTouchEnd={(input) => {
                                    if (activeInput === 1 && !isSafari) {
                                        ChangeActiveInput(0);
                                        setTimeout(() => {
                                            changeIndex(
                                                props,
                                                1,
                                                input.target.value,
                                                item.id
                                            );
                                            forceUpdate();
                                        }, 500);
                                    }
                                }}
                                // onClick={(input) => {
                                //     ChangeActiveInput(0);
                                //     setTimeout(() => {
                                //         changeIndex(
                                //             props,
                                //             1,
                                //             input.target.value,
                                //             item.id
                                //         );
                                //     }, 500);
                                // }}
                                style={{
                                    background: '#78CCF6',
                                }}
                                value={item.score == null ? 1 : item.score}
                                list="tickmarks"
                            />
                            <div id="tickmarks">{getNumberrange(i, item)}</div>
                        </div>
                    </div>
                ))}

                <div className="complete__container">
                    <div className="completeText__container">
                        <h2>
                            {String(props.Fs.FData.name).toUpperCase()}{' '}
                            Complete!
                        </h2>
                        <h4>{props.Fs.FData.congratulationsMsg}</h4>
                        <button
                            className={`btnNextF ${
                                props.Fs.AllState.index > 5
                                    ? 'btnStep1 btnFinishSection btnCompleteStep1'
                                    : ' botons btnNext'
                            }`}
                            disabled={
                                currentQ.score == null &&
                                props.Fs.AllState.currentQuestion !== 10
                                    ? 'disabled'
                                    : ''
                            }
                            onClick={() => {
                                changeIndex(props, 1, null, null, null, true);
                            }}
                            type="button"
                        >
                            {props.Fs.AllState.currentQuestion !== 10 ? (
                                <h6 style={{ marginTop: '3rem' }}>
                                    {'NEXT >'}
                                </h6>
                            ) : props.Fs.AllState.index > 5 ? (
                                currUser.userStepId === 5 ? (
                                    'COMPLETE REVIEW'
                                ) : (
                                    'COMPLETE STEP 1'
                                )
                            ) : (
                                <h6 style={{ marginTop: '3rem' }}>
                                    {'NEXT >'}
                                </h6>
                            )}
                        </button>
                    </div>
                    {props.Fs.AllState.currentQuestion === 10 && (
                        <div className="completeChart__container">
                            <svg
                                className="ChartPieChunkBig"
                                viewBox="0 0 440 440"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    transform: 'rotate( -51.42deg)',
                                }}
                            >
                                <defs>
                                    <g id="overlayQ">
                                        <rect
                                            x="219"
                                            y="20"
                                            height="180"
                                            width="10"
                                            fill="#FFF"
                                        />
                                    </g>
                                </defs>
                                <mask id="wedge-maskQ" fill="white">
                                    <path
                                        transform="translate(20, 20)"
                                        d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                                        fillRule="nonzero"
                                    />
                                </mask>

                                {props.Fs.AllState.Fs.map((item, i) => (
                                    <Slice
                                        key={`Slice${props.Fs.FData.id}${item.id}${i}`}
                                        index={i}
                                        Fs={item}
                                        FullState={props.Fs.AllState}
                                        currSlice={props.Fs.AllState.index + 1}
                                        currQuestion={
                                            props.Fs.AllState.currentQuestion
                                        }
                                    />
                                ))}
                                <circle
                                    cx="220"
                                    cy="220"
                                    r="38"
                                    fill="white"
                                    strokeWidth="1"
                                />
                                <circle
                                    cx="220"
                                    cy="220"
                                    r="30"
                                    fill="#C9C9C9"
                                    strokeWidth="1"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(25.5, 226, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(76.8, 220, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(129, 221, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(180, 220, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(231.5, 220, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(282, 218, 220)"
                                />
                                <use
                                    xlinkHref="#overlayQ"
                                    transform=" rotate(334, 218, 220)"
                                />
                                <circle
                                    cx="220"
                                    cy="220"
                                    r="24"
                                    fill="white"
                                    strokeWidth="1"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </SwipeableViews>
            {props.Fs.AllState.currentQuestion + 1 !== 11 && (
                <div className="qbuttons__container">
                    <div className="previousButton__container">
                        {props.Fs.AllState.currentQuestion === 0 &&
                        props.Fs.AllState.index === 0 ? (
                            <div />
                        ) : (
                            <button
                                className="botons btnPrevious"
                                onClick={() => {
                                    changeIndex(props, -1);
                                }}
                                type="button"
                            >
                                <h6>&lt; PREVIOUS</h6>
                            </button>
                        )}
                    </div>
                    <div className="nextButton__container">
                        <button
                            className="botons btnNext"
                            disabled={
                                (currentQ.score == null ||
                                    currentQ.score === 0) &&
                                props.Fs.AllState.currentQuestion !== 10
                                    ? 'disabled'
                                    : ''
                            }
                            onClick={() => {
                                changeIndex(props, 1, null, null, true);
                            }}
                            type="button"
                        >
                            <h6>
                                {props.Fs.AllState.currentQuestion !== 10 &&
                                    'NEXT >'}
                            </h6>
                        </button>
                    </div>
                </div>
            )}
            <ModalError
                ModalErrorTitle={'Error connection'}
                ModalErrorDesc={
                    'Please verify your internet access, please try again'
                }
                setShowError={showModalErrorChange}
                show={showModalError}
            />
        </div>
    );
};

export default Questions;
