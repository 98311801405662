import React from 'react';
import './HeaderFreeTest.css';

const HeaderDashboard = () => (
    <div className="HeaderFreeTest__contianer">
        <h1>
            <span>FREE</span> LIFE BALANCE TEST
        </h1>
    </div>
);
export default HeaderDashboard;
