import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useWindowSize } from '@react-hook/window-size';
import QuestionaryStep1 from './Step1/QuestionaryStep1';
import SelectGoalsStep2 from './Step2/SelectGoalsStep2';
import Step2Level2 from './Step2/Step2Level2';
import EstablishActionStep3 from './Step3/EstablishActionStep3';
import Step5MidYearReview from './Step1/Step5MidYearReview';
import Step12 from './Step1/Step12/Step12';

import AppContext from '../Context/AppContext';
import Header from './Dashboard/Header/HeaderDashboard';
import Network from './Network/Network';
import configData from '../config.json';
import CStepper from './Common/Header/CStepper';
import CStepperLevel2 from './Common/Header/CStepperLevel2';
import './Steps.css';
import '../App.css';

const App = () => {
    const { updateUserStepId, checkUserSession,changeLoading } = useContext(AppContext);
    const [, setWindowWidth] = useState(window.innerWidth);
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const [userStatus, setUserStatus] = useState({});
    const history = useHistory();
    const [, height] = useWindowSize();
    // const [currentStep,changeCurrentStep]=useState(-1);
    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 768);
        setWindowWidth(window.innerWidth);
    });

    const [indexStep, handleChangeindexStep] = useState(0);
    const [indexLevelStep, changeIndexLevelStep] = useState(0);
    const [headerActive, handleChangeHeader] = useState(1);

    function change(i) {
        handleChangeindexStep(i);
    }

    const handleChangeDirect = () => {};

    useEffect(async () => {
        const userStepCheck = await updateUserStepId();
        setUserStatus(userStepCheck);
        const { userStepId } = userStepCheck;
        const userInfo = checkUserSession();

        if (userInfo.userTypeId === 4) history.push('/nonSubscriber');

        if (userStepId < 4 && userStepId >= 1) {
            handleChangeindexStep(userStepId);
        } else if (userStepId === 5) {
            handleChangeindexStep(5);
            handleChangeHeader(0);
        } else if (userStepId === 6) {
            handleChangeindexStep(6);
            handleChangeHeader(2);
            changeIndexLevelStep(0);
        } else if (userStepId === 7) {
            handleChangeindexStep(7);
            handleChangeHeader(2);
            changeIndexLevelStep(1);
        } else if (userStepId === 0) {
            history.push('/customizeQuestions');
        } else if (userStepId > 3) {
            history.push('/dashboard');
        }
    }, []);

    return (
        <div className="steps__container">
            <Header mobile={mobile} steps="1" />
            {headerActive === 1 && (
                <CStepper indexStep={indexStep - 1} mobile={mobile} />
            )}
            {headerActive === 2 && (
                <CStepperLevel2 indexStep={indexLevelStep} mobile={mobile} />
            )}
            {indexStep === 1 && (
                <QuestionaryStep1
                    indexStepChange={change}
                    mobile={mobile}
                    handleChangeHeader={handleChangeHeader}
                    userStatus={userStatus}
                    headerActive={headerActive}
                />
            )}
            {indexStep === 2 && (
                <SelectGoalsStep2
                    handleChangeindexStep={handleChangeindexStep}
                    handleChangeHeader={handleChangeHeader}
                    mobile={mobile}
                />
            )}
            {indexStep === 3 && (
                <EstablishActionStep3
                    handleChangeHeader={handleChangeHeader}
                    mobile={mobile}
                />
            )}
            {indexStep === 5 && (
                <Step5MidYearReview
                    indexStepChange={change}
                    mobile={mobile}
                    handleChangeHeader={handleChangeHeader}
                    userStatus={userStatus}
                />
            )}
            {indexStep === 6 && (
                <section className="step1__container">
                    <div className={'step12__container '}>
                        <Step12
                            changeStepIndex={change}
                            configData={configData}
                            mobile={mobile}
                            height={height}
                            handleChangeHeader={handleChangeHeader}
                            userStatus={userStatus}
                            handleChange={handleChangeDirect}
                            changeIndexLevelStep={changeIndexLevelStep}
                        />
                    </div>
                </section>
            )}
            {indexStep === 7 && (
                <Step2Level2
                    handleChangeindexStep={handleChangeindexStep}
                    handleChangeHeader={handleChangeHeader}
                    mobile={mobile}
                />
            )}
            <Network />
        </div>
    );
};

export default App;
