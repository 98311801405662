import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import ModalAddTask from './Modal/ModalAddTask';
import ToDoListItem from './Components/ToDoListItem';
import './ToDoList.css';
import configData from '../../../../config.json';
import requestAxios from '../../../../util/requestAxios';

const ToDoList = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [removeTaskId, setRemoveTaskId] = useState(0);
    const [completeTaskId, setCompleteTaskId] = useState(0);
    const [toDoListData, setToDoListData] = useState([]);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [currItem, setCurrItem] = useState({});
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [userState, setUserState] = useState([]);
    const [textModal, setTextModal] = useState({
        title: 'DELETE TASK',
        desc: 'Are you sure you want to delete this task?',
    });
    const { checkUserSession, changeLoading, updateUserStepId } =
        useContext(AppContext);
    const { token } = checkUserSession();
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    async function getToDoList() {
        const url = `${configData.SERVER_URL}ToDoList/GetTasks`;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                setToDoListData(result.data);
            })
            .catch((error) => {
                console.log(error);
            });
        const { data } = await requestAxios({
            url: `customer/GetDefinedTimeZones`,
            token,
        });
        setUserState(await updateUserStepId());
        setTimeZoneList(data);
    }
    function removeTask() {
        const url = `${configData.SERVER_URL}ToDoList/DeleteTask/${removeTaskId}`;
        changeLoading(true);

        axios
            .delete(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                changeLoading(false);
                setToDoListData(result.data);
                setModalVisible(false);
            })
            .catch((error) => {
                changeLoading(false);
                console.log(error);
            });
    }
    function completeTask() {
        const url = `${configData.SERVER_URL}ToDoList/CompleteTask/${completeTaskId}`;
        changeLoading(true);

        axios({
            method: 'put',
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                changeLoading(false);
                setToDoListData(result.data);
                setModalVisible(false);
            })
            .catch((error) => {
                changeLoading(false);
                console.log(error.toJSON());
            });
    }
    function showDeleteModal(id) {
        setTextModal({
            title: 'DELETE TASK',
            desc: 'Are you sure you want to  delete this task?',
        });
        setRemoveTaskId(id);
        setCompleteTaskId(0);
        setModalVisible(true);
    }
    function showCompleteModal(id) {
        setTextModal({
            title: 'COMPLETE TASK',
            desc: 'Are you sure you want to  complete this task?',
        });
        setRemoveTaskId(0);
        setCompleteTaskId(id);
        setModalVisible(true);
    }
    async function AddTask(dayNumber) {
        const currTimeZone = timeZoneList.find(
            (x) => x.id === userState.definedTimeZoneId
        );
        let result = new Date();
        if (currTimeZone != null) {
            result = new Date(currTimeZone.currentTime);
        }
        const originalDate=new Date(result);
        result.setDate(result.getDate() + dayNumber);

        setCurrItem({ text: '', date: result, id: null,originalDate,definedTimeZoneId:userState.definedTimeZoneId});
        setShowModalEdit(true);
        setModalType(1);
    }
    function EditTask(item) {
        const currTimeZone = timeZoneList.find(
            (x) => x.id === userState.definedTimeZoneId
        );
        let result = new Date();
        if (currTimeZone != null) {
            result = new Date(currTimeZone.currentTime);
        }
        const originalDate=new Date(result);
        item.originalDate=originalDate;
        setCurrItem(item);
        setShowModalEdit(true);
        setModalType(2);
    }

    useEffect(() => {
        getToDoList();
    }, [0]);

    return (
        toDoListData.length !== 0 && (
            <section className="modalTasks__section DailyActions__container">
                {toDoListData.map((item, i) => (
                    <div className="ToDoList__container">
                        <section className="ToDoList__GroupTitle">
                            {String(item.day).toUpperCase()}
                            <br />
                            <span className="ToDoList__GroupTitleDate">
                                {item.date}
                            </span>
                        </section>
                        {item.tasks.map((itemx) => (
                            <ToDoListItem
                                itemx={itemx}
                                item={item}
                                showCompleteModal={showCompleteModal}
                                showDeleteModal={showDeleteModal}
                                EditTask={EditTask}
                            />
                        ))}
                        <section className="addToDo__container">
                            <button
                                className=" AddMoreToDo"
                                onClick={() => AddTask(i)}
                                type="button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="110"
                                    height="60"
                                    viewBox="0 0 100 80"
                                >
                                    <g id="a">
                                        <g transform="translate(-1480 -320)  ">
                                            <g transform="translate(-16803 -3223)">
                                                <line
                                                    x2="20"
                                                    transform="translate(18270.5 3568.5)"
                                                    fill="none"
                                                    stroke="#2c414c"
                                                    strokeWidth="4"
                                                />
                                                <line
                                                    y1="20"
                                                    transform="translate(18280.5 3558.5)"
                                                    fill="none"
                                                    stroke="#2c414c"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                            <g
                                                transform="translate(1462 330)"
                                                fill="none"
                                                stroke="#2c414c"
                                                strokeWidth="4"
                                            >
                                                <circle
                                                    cx="15.5"
                                                    cy="15.5"
                                                    r="15.5"
                                                    stroke="none"
                                                />
                                                <circle
                                                    cx="15.5"
                                                    cy="15.5"
                                                    r="14"
                                                    fill="none"
                                                />
                                            </g>
                                            <text
                                                className="AddMoreToDo "
                                                x="1500"
                                                y="354"
                                            >
                                                ADD MORE
                                            </text>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </section>
                    </div>
                ))}
                {showModalEdit && (
                    <ModalAddTask
                        show={showModalEdit}
                        setShow={setShowModalEdit}
                        modalType={modalType}
                        currItem={currItem}
                        getToDoList={getToDoList}
                        token={token}
                        changeLoading={changeLoading}
                    />
                )}
                <Modal
                    show={modalVisible}
                    onHide={() => setModalVisible(false)}
                    aria-labelledby="example-custom-modal-styling-title"
                    id="modalNotificationAction"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                    size="xl"
                    centered
                >
                    <Modal.Body>
                        <div
                            className="NotificacionActionStepTitle"
                            style={{ paddingTop: '60px' }}
                        >
                            {textModal.title}
                        </div>
                        <div className="NotificacionActionStepDesc">
                            {textModal.desc}
                        </div>
                        <center style={{ textAlign: 'right' }}>
                            <button
                                onClick={() => setModalVisible(false)}
                                className="btnCancelActionStep"
                                style={{}}
                                type="button"
                            >
                                CANCEL
                            </button>
                            <button
                                onClick={() =>
                                    removeTaskId === 0
                                        ? completeTask()
                                        : removeTask()
                                }
                                className="btnYeahActionStep"
                                style={{}}
                                type="button"
                            >
                                YEAH, I&apos;M SURE
                            </button>
                        </center>
                    </Modal.Body>
                </Modal>
            </section>
        )
    );
};

export default ToDoList;
