export default {
    index: 0,
    indexSteps: 0,
    currentQuestion: 0,
    lastF: 1,
    confetti: 0,
    hideNav: false,
    changeStepIndex() {},
    User: {
        Name: 'Osvaldo',
    },
    Fs: [
        {
            id: 1,
            name: 'Fitness',
            color: '#15C285',
            weight: 0.3,
            questions: [],
            TotalScore:0
        },
        {
            id: 2,
            name: 'Finance',
            color: '#ABD86D',
            weight: 0.2,
            questions: [],
            TotalScore:0
        },
        {
            id: 3,
            name: 'Family',
            color: '#FBBF53',
            weight: 0.1,
            questions: [],
            TotalScore:0
        },
        {
            id: 4,
            name: 'Faith',
            color: '#F26636',
            weight: 0,
            questions: [],
            TotalScore:0
        },
        {
            id: 5,
            name: 'Field',
            color: '#EC0F47',
            weight: -0.1,
            questions: [],
            TotalScore:0
        },
        {
            id: 6,
            name: 'Friends',
            color: '#700460',
            weight: -0.2,
            questions: [],
            TotalScore:0
        },
        {
            id: 7,
            name: 'Fun',
            color: '#022B7A',
            weight: -0.3,
            questions: [],
            TotalScore:0
        },
    ],
};
