import React from 'react';
import Wheel from './Wheel'
import SelectOneSectionConfirm from "./SelectOneSectionConfirm"
import './OolaOneSectionSelect.css'

const OolaSevenSectionSelect = (props) => {
// Recorremos el arreglo 
    function changeStep(){
     
        props.updateIndexStep2(1);
        props.handleChangeHeader(0);
    }
    
    const obj =
        <section className="selectOolaOneConfirm__container">
            <div className="selectOolaOneConfirm__wrapper">
                <div style={{ height: '70%'}}>
                    <Wheel AllState={props.AllState} hideNav={false} allColors />
                </div>
                <div>
                    <SelectOneSectionConfirm changeStep={changeStep} forceUpdate={props.forceUpdate}  AllState={props.AllState} changeData={props.changeData}  hideNav={false} updateIndexStep2={props.updateIndexStep2} handleChangeHeader={props.handleChangeHeader} />
                </div>
            </div>

        </section>
    return obj;
}

export default OolaSevenSectionSelect;