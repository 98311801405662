import React, { useContext } from 'react';
import axios from 'axios';
import configData from '../../../config.json';
import Wheel from './Wheel';
import SelectOneSection from './SelectOneSection';
import './ArrangeYourGoal.css';
import AppContext from '../../../Context/AppContext';

const OolaSevenSection = (props) => {
    const { changeLoading, checkUserSession } = useContext(AppContext);
    const currUser = checkUserSession();

    function changeStep() {
        changeLoading(true);
        const PostObj = {
            Goals: props.AllState.SelectedGoalsSeven.map((item, i) => ({
                GoalId: item.GoalId,
                SevenGoalOrder: i+1,
            })),
        };

        const url = `${configData.SERVER_URL}Goals/SetSevenGoalsOrder`;

        axios
            .put(url, PostObj, {
                headers: {
                    Authorization: `Bearer ${currUser.token}`,
                },
            })
            .then(() => {
                props.AllState.SelectedOolaOne = props.AllState.SelectedGoalsSeven[0];
                props.AllState.changeData(props.AllState);
                if (currUser.userStepId != 7) props.handleChangeHeader(1);
                props.AllState.changeData(props.AllState);
                props.updateIndexStep2(1);
                changeLoading(false);
            })
            .catch((error) => {
                console.log(error);
                changeLoading(false);
            });
    }
    const obj = (
        <section className="arrangeYouGoal__container">
            <div className="arrangeYouGoal__wrapper">
                <div
                    className="arrangeYouGoal__wheel"
                >
                    <Wheel
                        AllState={props.AllState}
                        hideNav={false}
                        allColors
                    />
                </div>
                <div>
                    <SelectOneSection
                        updateIndexStep2={props.updateIndexStep2}
                        forceUpdate={props.forceUpdate}
                        AllState={props.AllState}
                        changeData={props.changeData}
                        hideNav={false}
                        currUser={currUser}
                    />
                    <div style={{ flex: 1, display: 'flex', width: '100%' }}>
                        <div
                            className="btnNextStep2"
                            style={{
                                fontSize: 30,
                                color: '#D86B59',
                                marginLeft: 'auto',
                                pointerEvents:
                                    props.AllState.SelectedGoalsSeven.length ===
                                    7
                                        ? ''
                                        : 'none',
                            }}
                            onClick={() => changeStep()}
                        >
                            NEXT {'>'}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    return obj;
};

export default OolaSevenSection;
