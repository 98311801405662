import React from 'react';
import './MyProgress.css';

const MyProgress = (props) => (
    <article className="myProgress">
        <div className="TitleDashboardContent">
            <p className="heading-5 BlueDarkText">MY PROGRESS</p>
        </div>
        <div className="myProgress__container">
            {props.myProgressLoading && (
                <section className="myProgress__loaderContainer">
                    <div className="lds-roller">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </section>
            )}
            <div>
                <h3 className="display-2">
                    {props.AllState.userProgress.stepsTaken}
                </h3>
                <h6 className="heading-6">
                    <span>ACTION</span>
                    <br />
                    STEPS
                    <br />
                    TAKEN
                </h6>
            </div>
            <div>
                <h3 className="display-2">
                    {props.AllState.userProgress.currentStreak}
                </h3>
                <h6 className="heading-6">
                    <span>DAYS</span>
                    <br />
                    CURRENT
                    <br />
                    STREAK
                </h6>
            </div>
            <div>
                <h3 className="display-2">
                    {props.AllState.userProgress.longestStreak}
                </h3>
                <h6 className="heading-6">
                    <span>DAYS</span>
                    <br />
                    LONGEST
                    <br />
                    STREAK
                </h6>
            </div>
            <div>
                <h3 className="display-2">
                    {props.AllState.userProgress.goalsCompleted}
                </h3>
                <h6 className="heading-6">
                    <span>MY</span>
                    <br />
                    GOALS
                    <br />
                    COMPLETED
                </h6>
            </div>
        </div>
    </article>
);

export default MyProgress;
