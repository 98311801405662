import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Confetti from '../../Step1/Confetti/Confetti';
import ShareOptionsModal from '../../Share/ShareOptionsModal/ShareOptionsModal';
import getUserDate from '../../../util/getUserDate';
import OolaButton from './TemplateAction/OolaButton';
import AppContext from '../../../Context/AppContext';
import './SelectFinishSection.css';
import './FinishActions.css';

const SelectFinishSection = (props) => {
    const { checkUserSession } = useContext(AppContext);
    const [activeShare, setActiveShare] = useState(false);
    const currUser = checkUserSession();
    const [showSide, ChangeShowSide] = useState(0);

    const btnSection = (
        <section className="btnGoDashboard__content">
            <Link to="/dashboard">
                <button
                    className="btnStep1 btnFinishSection "
                    style={{
                        fontWeight: '500',
                        borderStyle: 'solid',
                        borderColor: '#D86B59',
                    }}
                    type="button"
                >
                    GO TO DASHBOARD
                </button>
            </Link>
        </section>
    );
    const ContentSide = (
        <section className="Step3SideLeft__content ">
            <div className="sideTitleStep3Finish Step3SideLeft__content1">
                <span style={{ color: '#78ccf6' }}>STEP 3</span> COMPLETE
            </div>
            <div className=" Step3SideLeft__content2">
                <div>
                    <h1 className="TitleNameEndStep3">GREAT WORK</h1>
                    <h1 className="TitleNameEndStep3">
                        {`${currUser.firstName}`}!
                    </h1>
                </div>
                <h6 className="DescriptionEnd">
                    Congratulations, You completed step three!
                </h6>
                {(showSide === 1 && props.mobile === false) && btnSection}

            </div>
        </section>
    );

    const Full = (
        <section
            className={
                showSide === 1
                    ? 'Step3Tracker__container'
                    : 'Step3Tracker__containerInitial'
            }
        >
            {showSide === 1 ? <Confetti props={1} mobile={props.mobile} /> : ''}

            {showSide === 1 ? ContentSide : ''}
            <section
                className={`Step3SideRight__content ${
                    showSide === 1 && 'Step3SideRight__contentAnimation'
                }`}
            >
                <OolaButton
                    isFinishAction={props.isFinishAction}
                    ChangeShowSide={ChangeShowSide}
                    handleChangeHeader={props.handleChangeHeader}
                    showSide={showSide}
                    mobile={props.mobile}
                />
                {(showSide === 1 && props.mobile === true) && btnSection}
            </section>
            {activeShare && (
                <ShareOptionsModal
                    setActiveShare={setActiveShare}
                    text={`My Oola Actions ${getUserDate()} - `}
                />
            )}
        </section>
    );

    return Full;
};

export default SelectFinishSection;
