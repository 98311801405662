import React, { useState } from 'react';
import { InputNumeric, RadioButton } from '../../../Common/Control/Controls';
import './OptionNewModules.css';

const OptionNewModule = ({ SelectOption, item, itemx, i, ix,setSubQuestionComplete }) => {
    return (
        <div style={{ paddingTop: '1rem' }} key={`QAO${ix}`}>
            <label className="containerOption">
                {itemx.text}
                <input
                    type="radio"
                    name={`Question${i}`}
                    id={`Question${i}${ix}`}
                    onClick={() => {setSubQuestionComplete(false);SelectOption(item.id, itemx.id)}}
                />
                <span className="checkmark" />
            </label>
        </div>
    );
};
const CheckBoxNewModule = ({ SelectOption, item, itemx, i, ix,setSubQuestionComplete }) => {
     const [checked, setChecked] = useState(false);
    return (
        <div style={{ paddingTop: '1rem' }} key={`QAO${ix}`}>
            <label className="containerCheckbox">
                {itemx.text}
                <input
                    type="checkbox"
                    name={`Question${i}`}
                    id={`Question${i}${ix}`}
                    checked={checked}
                    onChange={() => { 
                        setSubQuestionComplete(false);SelectOption(item.id, itemx.id,);setChecked(!checked)}}

                />
                <span className="checkmarkCheckbox" />
            </label>
        </div>
    );
};
const SubQuestion = ({ SelectOption, item, i }) => {
    return item.subQuestions.map((itemx, ix) =>
        itemx.options.length > 1 ? (
            itemx.options.map((option, ioption) => (
                <RadioButton
                    key={`RadioButton${item.id}${itemx.id}${option.id}`}
                    type="radio"
                    name={`Question${i}`}
                    id={`Question${i}${ix}`}
                    onClick={() =>
                        SelectOption(item.id, itemx.id, option.id, '1')
                    }
                    text={option.text}
                />
            ))
        ) : (
            <InputNumeric
                key={`InputNumeric${item.id}${itemx.id}`}
                text={itemx.text}
                className="InputNewModule"
                onChange={input => {
                    const [max,min]=[Number(input.target.max),Number(input.target.min)]
                    if(Number(input.target.value)>max){
                        input.target.value=max;
                    }
                    if(Number(input.target.value)<min){
                        input.target.value=min;
                    }
                    SelectOption(
                        item.id,
                        itemx.id,
                        item.options[0].id,
                        input.target.value
                    );
                }}
            />
        )
    );
};
export { OptionNewModule, SubQuestion,CheckBoxNewModule };
