import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import AppContext from '../../../../../Context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalAddAction.css';
import RepeatIcon from '../../../../../Assets/icons/repeat-icon.svg';
import ModalRepeatAction from './ModalRepeatAction';

const ModalAddAction = (props) => {
    const { getData, requestAPI, setNewData } = useContext(AppContext);
    const {
        repeatAction,
        currentAction,
        dayAddFindDailyActions,
        CustomerProfileInfo,
    } = getData();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className="datePickerTask btnDatePickerGoal btnDateDasboard btnAddDateDailyAction"
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));
    const [isOpen, setIsOpen] = useState(true);
    const [textValue, setTextValue] = useState('');
    const [dateValue, setDateValue] = useState(new Date());
    const [textModal, setTextModal] = useState({
        title: 'Complete',
        desc: 'Are you sure you want to save this task?',
        visible: false,
    });
    const [repeatActionModal, setRepeatActionModal] = useState(false);
    const today = CustomerProfileInfo.customerLocalTime;
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 6);

    function saveTask() {
        if (textValue == '' || textValue == null) {
            alert('Please define your task');
            return false;
        }
        if (String(textValue).trim().length > 0) {
            addAction();
        } else {
            alert('Please define your task');
        }
    }
    async function addAction() {
        props.changeLoading(true);
        const url = `Actions/UpdateInsertAction`;
        const parseDateValue = new Date(dateValue);
        let parseDate = new Date(
            parseDateValue.getFullYear(),
            parseDateValue.getMonth(),
            parseDateValue.getDate()
        );
        if (repeatAction != null) {
            parseDate = new Date(
                repeatAction.StartDate.getFullYear(),
                repeatAction.StartDate.getMonth(),
                repeatAction.StartDate.getDate()
            );
            repeatAction.StartDate = parseDate;
            delete repeatAction.startDate;
        }
        const PostObj = {
            Id: repeatAction?.ActionId
                ? repeatAction.ActionId
                : currentAction
                ? currentAction.Id
                : 0,
            Text: textValue,
            UnlockedDate: parseDate,
            ActionRepeating: repeatAction,
        };

        const { error, data } = await requestAPI({
            url: url,
            method: 'post',
            token: props.token,
            data: PostObj,
        });
        if (error == '') {
            props.changeLoading(false);
            setNewData({ repeatAction: null });
            props.getCurrentDailyActions();
            props.setShow(false);
        } else {
            props.changeLoading(false);
            console.log(error.toJSON());
        }
    }

    function getDays() {
        let days = [];
        if (repeatAction.monday == true) days.push('Monday');
        if (repeatAction.tuesday == true) days.push('Tuesday');
        if (repeatAction.wednesday == true) days.push('Wednesday');
        if (repeatAction.thursday == true) days.push('Thursday');
        if (repeatAction.friday == true) days.push('Friday');
        if (repeatAction.saturday == true) days.push('Saturday');
        if (repeatAction.sunday == true) days.push('Sunday');
        return days.join(', ');
    }

    useEffect(() => {
        if (repeatAction != null) {
            setTextValue(repeatAction.text);
            if (repeatAction.id > 0) {
                setRepeatActionModal(true);
            }
        }
        if (currentAction != null) {
            setTextValue(currentAction.text);
        }
        if (dayAddFindDailyActions?.days > 0) {
            var result = dateValue.setDate(
                dateValue.getDate() + dayAddFindDailyActions.days
            );
            setDateValue(result);
        }
    }, []);
    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                className={repeatActionModal && 'hide'}
                centered
            >
                <Modal.Header className="modalAddTaskHeader__container DailyAction">
                    <button
                        className="modalAddGoal__exit"
                        onClick={() => props.setShow(false)}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <section className="modalAddGoal__section DailyAction">
                        <h3 className="heading-3 DailyAction">
                            {props.modalType === 1
                                ? 'ADD ACTION'
                                : 'EDIT ACTION'}
                        </h3>
                        <div className="modalAddGoal__goalContainer ModalAddAction__container">
                            <input
                                value={textValue}
                                className="ModalTaskInputDesc btnAddDescDailyAction"
                                placeholder="Define your task"
                                onChange={(e) => setTextValue(e.target.value)}
                            />

                            {isOpen && (
                                <DatePicker
                                    dateFormat="MMMM dd, yyyy"
                                    selected={
                                        repeatAction
                                            ? repeatAction.StartDate
                                            : dateValue
                                    }
                                    onChange={(completedByDate) =>
                                        setDateValue(completedByDate)
                                    }
                                    customInput={<CustomInput />}
                                    withPortal
                                    className="datePickerTask"
                                    calendarClassName={
                                        repeatActionModal && 'hide'
                                    }
                                    placeholderText="Complete by: mm/dd/yyyy"
                                    minDate={today}
                                    maxDate={maxDate}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div>
                                            <div
                                                className="calendarHeader"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    width: '100%',
                                                }}
                                            >
                                                COMPLETE BY:
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    width: '100%',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                <button
                                                    className="btnCalendar"
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                        prevMonthButtonDisabled
                                                    }
                                                    type="button"
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {'<'}
                                                </button>
                                                <div className="lblMonth">
                                                    {
                                                        months[
                                                            new Date(
                                                                date
                                                            ).getMonth()
                                                        ]
                                                    }{' '}
                                                    {new Date(
                                                        date
                                                    ).getFullYear()}
                                                </div>
                                                <button
                                                    className="btnCalendar"
                                                    onClick={increaseMonth}
                                                    disabled={
                                                        nextMonthButtonDisabled
                                                    }
                                                    type="button"
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {'>'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <section className="modalAddTaskRepeat__section__container">
                                        <section className="modalAddTaskRepeat__section">
                                            <div className="modalAddTaskRepeat_line" />

                                            <button
                                                className="modalAddTaskRepeat_repeatButton"
                                                type="button"
                                                onClick={() => {
                                                    setRepeatActionModal(true);
                                                }}
                                            >
                                                <img
                                                    src={RepeatIcon}
                                                    alt="repeat"
                                                />
                                                REPEAT
                                            </button>
                                            <div className="modalAddTaskRepeat_line" />
                                            <button
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    setTimeout(() => {
                                                        setIsOpen(true);
                                                    }, 10);
                                                }}
                                                className="button-full-small modalAddTaskRepeat__doneButton"
                                                type="button"
                                            >
                                                DONE
                                            </button>
                                        </section>
                                    </section>
                                </DatePicker>
                            )}
                            <button
                                onClick={() => {
                                    setRepeatActionModal(true);
                                }}
                                type="button"
                                className="repeatIcon"
                            >
                                <a className="fa fa-repeat repeatIcon" />
                            </button>
                        </div>
                        {repeatAction && (
                            <h1 className="repeatingLabelMessage">
                                Start at:{' '}
                                <b>
                                    {repeatAction.StartDate.toLocaleDateString(
                                        'en-US'
                                    )}
                                </b>
                                {repeatAction.EndDate && (
                                    <>
                                        {' '}
                                        Ends at:{' '}
                                        <b>
                                            {repeatAction.EndDate.toLocaleDateString(
                                                'en-US'
                                            )}
                                        </b>
                                    </>
                                )}{' '}
                                <br className="showMobile" />
                                On Days: <b>{getDays()}</b>
                            </h1>
                        )}
                        <div>
                            <button
                                onClick={() => saveTask()}
                                className="button-full-mid"
                                type="button"
                            >
                                SAVE
                            </button>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
            {repeatActionModal && (
                <ModalRepeatAction
                    show={repeatActionModal}
                    setShow={setRepeatActionModal}
                    getCurrentDailyActions={props.getCurrentDailyActions}
                    setShowParentModal={props.setShow}
                />
            )}
        </>
    );
};
export default ModalAddAction;
