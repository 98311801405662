import React from 'react';
import PDFViewer from '../Common/PDFViewer/PDFViewer';
import LoginHeader from '../Common/LoginHeaderWhite/LoginHeaderWhite';
import config from '../../config.json';
import './PrivacyPolicy.css';

const privacyPolicyPDF = `${config.SERVER_URL_IMG}media/files/PrivacyPolicy.pdf`;

export default function TermsOfUse() {
    return (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <div className="terms-title">
                <h3 className="heading-3">Privacy Policy</h3>
            </div>
            <div className="all-page-container">
                <PDFViewer pdf={privacyPolicyPDF} />
            </div>
        </>
    );
}
