import React, { useState, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import LockSVG from '../../../Assets/img/LockIconFullWhite.svg';
import My_Breakthrough_Lock from '../../../Assets/img/Dashboard/My_Breakthrough_Lock.jpg';
import My_Path_Lock from '../../../Assets/img/Dashboard/My_Path_Lock.jpg';
import AppContext from '../../../Context/AppContext';
import './ELearningBoard.css';
import NewModules from '../../Dashboard/ELearningBoard/Board/NewModules/NewModules';

const App = () => {
    const [showPopover, setShowPopover] = useState(false);
    const [showPopover2, setShowPopover2] = useState(false);
    const [typeModules, ChangeTypeModules] = useState(3);
    const { changeModalWarningUpgrade } = useContext(AppContext);
    const popover = (
        <Popover className="MyGoalsPoper__container">
            <Popover.Content
                className="MyProgressPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span
                            onClick={() => {
                                changeModalWarningUpgrade(true);
                            }}
                        >
                            {' '}
                            Oola Lifestyle Framework
                        </span>{' '}
                        to unlock this feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );
    const popover2 = (
        <Popover className="MyGoalsPoper__container">
            <Popover.Content
                className="MyProgressPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover2(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover2(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span
                            onClick={() => {
                                changeModalWarningUpgrade(true);
                            }}
                        >
                            {' '}
                            Oola Lifestyle Framework{' '}
                        </span>
                        to unlock this feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <article className="eLearningBoardModules">
            <div className="TitleDashboardContent">
                <section className="heading-5">MY E-LEARNING</section>
            </div>
            <div className="ELearningModules__Container NonSubscriber">
                <section className="ELearning__Menu">
                    <OverlayTrigger
                        placement="top"
                        overlay={popover}
                        show={showPopover}
                        rootClose
                        delay={{ show: 0, hide: 5000 }}
                    >
                        <button
                            type="button"
                            className={'btnModuleType '}
                            onClick={() => ChangeTypeModules(1)}
                            onMouseEnter={() => {
                                setShowPopover(true);
                            }}
                            onMouseLeave={() => {
                                setShowPopover(false);
                            }}
                        >
                            <img
                                className="imgLockElarningBoard"
                                alt="imgLock"
                                src={LockSVG}
                            />
                            MY BREAKTHROUGH
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={popover2}
                        show={showPopover2}
                        rootClose
                        delay={{ show: 0, hide: 5000 }}
                    >
                        <button
                            type="button"
                            className={'btnModuleType'}
                            onClick={() => ChangeTypeModules(2)}
                            onMouseEnter={() => {
                                setShowPopover2(true);
                            }}
                            onMouseLeave={() => {
                                setShowPopover2(false);
                            }}
                        >
                            <img
                                className="imgLockElarningBoard"
                                alt="imgLock"
                                src={LockSVG}
                            />
                            MY PATH
                        </button>
                    </OverlayTrigger>
                    <button
                        type="button"
                        className={'ModuleTypeActive'}
                        onClick={() => ChangeTypeModules(3)}
                    >
                        ADDITIONAL PROGRAMS
                    </button>
                </section>
                <div className="ELearningModules__Container NonSubscriber">
                    <div className="NewModules__Container">
                        {typeModules === 1 && (
                            <img
                                alt="imgLock"
                                src={My_Breakthrough_Lock}
                                onClick={() => {
                                    changeModalWarningUpgrade(true);
                                }}
                            />
                        )}
                        {typeModules === 2 && (
                            <img
                                alt="imgLock"
                                src={My_Path_Lock}
                                onClick={() => {
                                    changeModalWarningUpgrade(true);
                                }}
                            />
                        )}
                    </div>
                    <div className='ELearningModulesPrograms__Container NonSubscriber OolaScroll tour_target'>
                    {typeModules === 3 && <NewModules />}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default App;
