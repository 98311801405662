import React from 'react';
import './FindBalanceSection.css';

import iconTools from '../../../Assets/icons/icon_tools.svg';
import iconCommunity from '../../../Assets/icons/icon_community.svg';
import iconELearning from '../../../Assets/icons/icon_elearning.svg';

const FindBalanceSection = (props) => (
    <section className="findBalance">
        <div className="findBalance__wrapper">
            <div className="findBalance__text">
                <h2 className="heading-2">FIND YOUR TRUE PURPOSE</h2>
                <p className="paragraph-3">
                    Oola helps people transform their lives for the better. With
                    our Year of Transformation digital program, you’ll find
                    total balance. Less stress. A dream career. Better finances.
                    A healthier body. Whatever you want for your life, Oola
                    helps you get it.
                </p>
                <p className="paragraph-2">
                    The path to self-improvement starts here
                </p>
            </div>
            <div className="findBalance__articles">
                <article>
                    <figure>
                        <img src={iconELearning} alt="eLearning" />
                    </figure>
                    <h6 className="paragraph-1">E-learning</h6>
                    <p className="paragraph-3">
                        Our innovative e-learning platform is built from a
                        proven lifestyle framework for personal growth. The
                        content is personalized to you and it’s self-paced --
                        you choose how and when to use it.
                    </p>
                </article>
                <article>
                    <figure>
                        <img src={iconCommunity} alt="Community" />
                    </figure>
                    <h6 className="paragraph-1">Community</h6>
                    <p className="paragraph-3">
                        The Oola community has already touched over 1 million
                        lives in 43 countries. They become friends and
                        accountability partners, supporting you on your journey
                        to a better life.
                    </p>
                </article>
                <article>
                    <figure>
                        <img src={iconTools} alt="Tools" />
                    </figure>
                    <h6 className="paragraph-1">Accountability Tools</h6>
                    <p className="paragraph-3">
                        You don’t have to go it alone. My Daily Tracker is your
                        virtual life coach, encouraging and helping you stay
                        focused. A simple click marks another step towards
                        achieving your personal goals.
                    </p>
                </article>
            </div>
            <button
                className="button-full-big"
                onClick={() => props.goEnroll()}
                type="button"
            >
                START YOUR JOURNEY
            </button>
        </div>
    </section>
);

export default FindBalanceSection;
