import React  from 'react';
import './SelectedAction.css';

const SelectedAction = (props) => {
    const ActionTextChange = (e) => {
        const AllState = { ...props.AllState };
        if (e.trimLeft().replace('  ', ' ').length <= 300) {
            AllState.Action[props.ActionIndex].text = e
                .trimLeft()
                .replace('  ', ' ');
            props.AllState.changeData(AllState);
        }
        return true;
    };

    const SelectedGoal = (
        <section
            className={`SelectAction__content ${
                props.Action.text !== '' ? 'ActionFill' : 'Action'
            }`}
        >
            <div className="SelectActionNumber__content">
                <div className={`SelectActionNumber ${props.Action.text !== ''&& 'SelectActionNumberFill'}`}>{props.ActionIndex + 1}</div>
            </div>
            
                <div className="inputDailyAction__content">
                    <textarea
                        className={`inputDailyAction
                            ${props.Action.text !== ''
                                ? 'InputActionFill'
                                : 'InputAction'}`
                        }
                        placeholder={`Write your action step `}
                        onChange={(e) => ActionTextChange(e.target.value)}
                        value={props.Action.text}
                    />
                    <hr
                        className={`secondlineActionGoalV
                            ${props.Action.text !== ''
                                ? 'secondlineActionGoalFill'
                                : 'secondlineActionGoal'}`
                        }
                    />
                </div>
            
        </section>
    );
    return SelectedGoal;
};
export default SelectedAction;
