import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import AppContext from '../../Context/AppContext';
import StepHeader from '../Step1/StepHeader';
import Header from './Header/Header';
import SelectGoalSection from './SelectGoals/SelectGoalSection';
import configData from '../../config.json';
import OolaSevenSection from './SelectGoals/OolaSevenSection';
import OolaOneSection from './SelectGoals/OolaOneSection';
import OolaFinishSection from './SelectGoals/OolaFinishSection';
import OolaOneSectionSelect from './SelectGoals/OolaOneSectionSelect';
import './SelectGoalsStep2.css';
import SelectFinishSection from './SelectGoals/SelectFinishSection';

const SelectGoalsStep2 = (props) => {
    const { checkUserSession, updateUserStepId, changeLoading } =
        useContext(AppContext);
    const currUser = checkUserSession();
    const [show, setShow] = useState(false);

    const [saving, savingChange] = useState(0);

    const [data, changeData] = useState({
        CurrentF: 0,
        CurrentStep2: 0,
        LastReachedF: 0,
        latestAssessmentScore: 0,
        Fs: [
            {
                areaId: 1,
                name: 'Fitness',
                score: 0,
                color: '#15C285',
                recommendedGoals: [],
            },
            {
                areaId: 2,
                name: 'Finance',
                score: 0,
                color: '#ABD86D',
                recommendedGoals: [],
            },
            {
                areaId: 3,
                name: 'Family',
                score: 0,
                color: '#FBBF53',
                recommendedGoals: [],
            },
            {
                areaId: 4,
                name: 'Faith',
                score: 0,
                color: '#F26636',
                recommendedGoals: [],
            },
            {
                areaId: 5,
                name: 'Field',
                score: 0,
                color: '#EC0F47',
                recommendedGoals: [],
            },
            {
                areaId: 6,
                name: 'Friends',
                score: 0,
                color: '#700460',
                recommendedGoals: [],
            },
            {
                areaId: 7,
                name: 'Fun',
                score: 0,
                color: '#022B7A',
                recommendedGoals: [],
            },
        ],
        SelectedGoals: [],
        SelectedGoalsSeven: [],
        SelectedOolaOne: {},
    });

    const dataLoad = () =>
        data.Fs.map((item) => ({
            id: item.areaId,
            name: item.name,
            tabColor: item.color,
        }));

    function updateIndexStep2(i) {
        const AllProps = { ...data };

        AllProps.CurrentStep2 += i;
        AllProps.changeData(AllProps);
        window.scrollTo(0, 0)

    }

    function getURL() {
        return configData.SERVER_URL;
    }

    useEffect(() => {
        const url = `${getURL()}goals/${currUser.userId}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${currUser.token}` },
            })
            .then((result) => {
                const AllProps = { ...data };
                const Fs = [];
                result.data.forEach((item) => {
                    Fs.push(item);
                });
                AllProps.Fs = Fs;
                AllProps.changeData = changeData;
                changeData(AllProps);

                const urlGoals = `${getURL()}goals/GetUserGoals`;
                axios
                    .get(urlGoals, {
                        headers: { Authorization: `Bearer ${currUser.token}` },
                    })
                    .then((result2) => {
                        
                        const newAllProps = { ...data };
                        let index = 0;
                        newAllProps.SelectedGoals = [];
                        result2.data.userGoals.forEach((item) => {
                            if (index === 3) index = 0;
                            newAllProps.SelectedGoals.push({
                                recomendationIndex: index,
                                text: item.text,
                                areaId: item.areaId - 1,
                                date: new Date(item.completedByDate),
                                GoalId: item.goalId,
                            });
                            index += 1;
                        });
                        newAllProps.Fs = Fs;
                        newAllProps.latestAssessmentScore = result2.data.latestAssessmentScore;
                        newAllProps.changeData = changeData;
                        newAllProps.CurrentF = Math.floor(
                            newAllProps.SelectedGoals.length / 3
                        );
                        newAllProps.LastReachedF = newAllProps.CurrentF;

                        if (newAllProps.CurrentF > 0) {
                            if (newAllProps.SelectedGoals.length === 21) {
                                if (
                                    result2.data.userGoals.filter(
                                        (x) => x.sevenGoalOrder === 0
                                    ).length === 7
                                ) {
                                    result2.data.userGoals
                                        .filter((x) => x.sevenGoalOrder === 0)
                                        .forEach((item, i) => {
                                            const x =
                                                newAllProps.SelectedGoals.find(
                                                    (xx) =>
                                                        xx.GoalId ===
                                                        item.goalId
                                                );

                                            newAllProps.SelectedGoalsSeven.push(
                                                {
                                                    areaId: x.areaId,
                                                    date: x.date,
                                                    recomendationIndex:
                                                        x.recomendationIndex,
                                                    text: x.text,
                                                    selected: true,
                                                    Id: i,
                                                    GoalId: x.GoalId,
                                                }
                                            );
                                        });
                                    newAllProps.CurrentStep2 = 4;
                                } else if (
                                    result2.data.userGoals.filter(
                                        (x) => x.sevenGoalOrder > 0
                                    ).length === 7
                                ) {
                                    result2.data.userGoals
                                        .filter((x) => x.sevenGoalOrder > 0)
                                        .sort(
                                            (a, b) =>
                                                a.sevenGoalOrder -
                                                b.sevenGoalOrder
                                        )
                                        .forEach((item, i) => {
                                            const x =
                                                newAllProps.SelectedGoals.find(
                                                    (xx) =>
                                                        xx.GoalId ===
                                                        item.goalId
                                                );

                                            newAllProps.SelectedGoalsSeven.push(
                                                {
                                                    areaId: x.areaId,
                                                    date: x.date,
                                                    recomendationIndex:
                                                        x.recomendationIndex,
                                                    text: x.text,
                                                    selected: true,
                                                    Id: i,
                                                    GoalId: x.GoalId,
                                                }
                                            );
                                        });
                                    newAllProps.CurrentStep2 = 5;
                                } else {
                                    newAllProps.CurrentStep2 = 2;
                                }

                                newAllProps.CurrentF = 0;
                            } else {
                                newAllProps.CurrentStep2 = 1;
                            }
                        }
                        changeData(newAllProps);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    function changePage() {
        updateIndexStep2(1);
    }
    function saveRecomendedGoals() {
        savingChange(1);
        changeLoading(true);
        const PostObj = {
            userId: currUser.userId,
            Goals: data.SelectedGoals.filter(
                (x) => x.areaId === data.CurrentF
            ).map((item) => ({
                GoalId: item.GoalId,
                AreaId: item.areaId + 1,
                Text: item.text,
                CompletedByDate: item.date,
            })),
        };

        const url = `${configData.SERVER_URL}Goals/PartialSaveGoals`;
        return new Promise((resolve, reject) => {
            axios
                .post(url, PostObj, {
                    headers: {
                        Authorization: `Bearer ${currUser.token}`,
                    },
                })
                .then((result) => {
                    data.SelectedGoals.filter(
                        (x) => x.areaId === data.CurrentF
                    ).forEach((item, i) => {
                        if (item.GoalId == null) {
                            item.GoalId = result.data[i].id;
                        }
                    });
                    savingChange(0);
                    changeLoading(false);
                    changeData(data);
                    resolve(true);
                })
                .catch((error) => {
                    savingChange(0);
                    console.log(error);
                    changeLoading(false);
                    resolve(false);
                });
        });
    }
    const obj = (
        <>
            {data.CurrentStep2 === 1 &&
                data.LastReachedF > data.CurrentF &&
                props.mobile === false && (
                    <button
                        className="btnPartialSaveStep2 "
                        type="button"
                        onClick={() => saveRecomendedGoals()}
                    >
                        SAVE
                    </button>
                )}
            <SwipeableViews
                className="step2__container"
                index={data.CurrentStep2}
                disabled
            >
                <section
                    className="step1__container"
                    style={{ height: '100%' }}
                >
                    <div className={`stepHeader__container  `}>
                        <StepHeader
                            url="https://player.vimeo.com/video/539418550"
                            title="STEP 2: WHERE YOU WANT TO GO"
                            description={
                                'We are on this planet to help you FIND BALANCE \n and a live of less stress and greater propuse - the OOLALIFE'
                            }
                            changePage={changePage}
                        />
                    </div>
                </section>
                <div className="section" style={{ height: 'auto' }}>
                    {data.CurrentStep2 !== 1 ? (
                        ''
                    ) : (
                        <Header
                            data={dataLoad()}
                            AllState={data}
                            changeData={changeData}
                            mobile={props.mobile}
                            saveRecomendedGoals={saveRecomendedGoals}
                        />
                    )}
                    <SwipeableViews
                        AllState={data}
                        index={data.CurrentF}
                        disabled
                        style={{
                            '--marginCalendar': `${
                                window.innerWidth * data.CurrentF
                            }px`,
                        }}
                        className="step2EstablishGoal__container"
                    >
                        {dataLoad().map((item) => (
                            <SelectGoalSection
                                key={item.id}
                                mobile={props.mobile}
                                FData={item}
                                AllState={data}
                                saveRecomendedGoals={saveRecomendedGoals}
                            />
                        ))}
                    </SwipeableViews>
                    {data.CurrentStep2 === 1 &&
                        data.LastReachedF > data.CurrentF &&
                        props.mobile === true && (
                            <button
                                className="btnPartialSaveStep2Mobile "
                                type="button"
                                onClick={() => saveRecomendedGoals()}
                            >
                                SAVE
                            </button>
                        )}
                </div>
                <section
                    className="step1__container"
                    style={{ height: '100%' }}
                >
                    <div className={`stepHeader__container  `}>
                        <StepHeader
                            url="https://player.vimeo.com/video/539436782"
                            title="STEP 2: TOP 7 GOALS"
                            description={
                                'We are on this planet to help you FIND BALANCE \n and a live of less stress and greater propuse - the OOLALIFE'
                            }
                            height="100%"
                            changePage={changePage}
                        />
                    </div>
                </section>
                <div className="section">
                    {data.CurrentStep2 !== 3 ? (
                        ''
                    ) : (
                        <div>
                            <OolaSevenSection
                                handleChangeHeader={props.handleChangeHeader}
                                forceUpdate={forceUpdate}
                                updateIndexStep2={updateIndexStep2}
                                AllState={data}
                                changeData={changeData}
                            />
                        </div>
                    )}
                </div>

                <div className="section">
                    {data.CurrentStep2 !== 4 ? (
                        ''
                    ) : (
                        <div style={{ overflowY: 'scroll' }}>
                            <OolaOneSection
                                handleChangeHeader={props.handleChangeHeader}
                                AllState={data}
                                changeData={changeData}
                                forceUpdate={forceUpdate}
                                updateIndexStep2={updateIndexStep2}
                            />
                        </div>
                    )}
                </div>
                <section
                    className="step1__container"
                    style={{ height: '100%' }}
                >
                    <div className={`stepHeader__container  `}>
                        <StepHeader
                            url="https://player.vimeo.com/video/539438606"
                            title="STEP 2: MY OOLA ONE"
                            description={
                                'We are on this planet to help you FIND BALANCE \n and a live of less stress and greater propuse - the OOLALIFE'
                            }
                            height="100%"
                            changePage={changePage}
                        />
                    </div>
                </section>
                <div className="section">
                    {data.CurrentStep2 !== 6 ? (
                        ''
                    ) : (
                        <div style={{ minHeight: '130vh' }}>
                            <OolaOneSectionSelect
                                handleChangeHeader={props.handleChangeHeader}
                                AllState={data}
                                changeData={changeData}
                                forceUpdate={forceUpdate}
                                updateIndexStep2={updateIndexStep2}
                            />
                        </div>
                    )}
                </div>
            <div
                className="section"
                style={{
                    width: '100%',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                }}
            >
                {data.CurrentStep2 === 7 ? (
                <SelectFinishSection
                    isFinishAction={1}
                    AllState={data}
                    handleChangeHeader={props.handleChangeHeader}
                    mobile={props.mobile}

                    changeData={changeData}
                    forceUpdate={forceUpdate}
                    updateIndexStep2={updateIndexStep2}
                    handleChangeindexStep={props.handleChangeindexStep}

                    hideNav={false} 
                    
                />
                ) : (
                    ''
                )}
            </div>
            </SwipeableViews>
        </>
    );
    return obj;
};

export default SelectGoalsStep2;
