import React, { useState, useContext } from 'react';
import { Container, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import axios from 'axios';
import AppContext from '../../../../Context/AppContext';
import completeImage from '../../../../Assets/icons/ic_complete.svg';
import './Goal.css';

import configData from '../../../../config.json';

const Goal = (props) => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [actionToModify, ChangeActionToModify] = useState({
        state: -1,
        id: -1,
    });
    const [textModal, changeTextModal] = useState({ title: '', desc: '' });
    const [modalNotificationVisible, changeModalNotificationVisible] =
        useState(false);
    const [showGoalTextpop, changeShowGoalTextpop] = useState(false);

    const popover = (
        <Popover
            id="popover-basic"
            style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '100%',
                borderRadius: '0.5rem',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                {/* <div className="TitleFinish titleQuestion TitlePop"> </div> */}
                <div
                    className="descriptionQuestion DescriptionPop"
                    style={{
                        display: 'flex',
                    }}
                >
                    <a
                        className="btnModal"
                        style={{ marginLeft: '0' }}
                        onClick={() => clickChangeStatus(1)}
                    >
                        Add TO MY TOP 7
                    </a>
                    <a
                        className="btnModal"
                        onClick={() => clickChangeStatus(2)}
                    >
                        COMPLETE
                    </a>
                    <a
                        className="btnModal"
                        onClick={() => clickChangeStatus(3)}
                    >
                        EDIT
                    </a>
                    <a
                        className="btnModal"
                        onClick={() => clickChangeStatus(4)}
                    >
                        REMOVE
                    </a>
                </div>
            </Popover.Content>
        </Popover>
    );
    const popoverInProgress = (
        <Popover
            id="popover-basic"
            style={{
                height: '2.8rem',
                width: '20rem',
                maxWidth: '100%',
                borderRadius: '0.5rem',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                <div className="TitleFinish titleQuestion TitlePop"> </div>
                <div className="descriptionQuestion DescriptionPop">
                    <a
                        className="btnModal"
                        style={{ marginLeft: '0' }}
                        onClick={() => clickChangeStatus(2)}
                    >
                        COMPLETE
                    </a>
                    <a
                        className="btnModal"
                        onClick={() => clickChangeStatus(3)}
                    >
                        EDIT
                    </a>
                    <a
                        className="btnModal"
                        onClick={() => clickChangeStatus(4)}
                    >
                        REMOVE
                    </a>
                </div>
            </Popover.Content>
        </Popover>
    );
    const popoverGoalText = (label) => (
        <Popover
            id="popover-basicGoalDashboard"
            style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '100%',
                borderRadius: '0.5rem',
            }}
        >
            <Popover.Content
                className="descriptionQuestion"
                style={{ textAlign: 'center' }}
            >
                {/* <div className="TitleFinish titleQuestion TitlePop"> </div> */}
                <div
                    className="descriptionQuestion DescriptionPopGoalDashboard"
                    style={{
                        display: 'flex',
                    }}
                >
                    {label}
                </div>
            </Popover.Content>
        </Popover>
    );
    function clickChangeStatus(type) {
        ChangeActionToModify({ state: type, id: props.item.id });

        switch (type) {
            case 1:
                if (props.TotaCompletelGoals >= 7) {
                    alert(
                        'YOU ALREADY HAVE 7 GOALS IN PROGRESS, IT IS NOT POSSIBLE TO ADD MORE.'
                    );
                    return;
                }
                changeTextModal({
                    title: 'ARE YOU SURE?',
                    desc: 'Do you want to add this goal to your My Top 7?',
                });
                break;
            case 2:
                changeTextModal({
                    title: 'ARE YOU SURE?',
                    desc: 'Did you complete this goal?',
                });
                break;
            case 3:
                const addGoal = { ...props.addGoal };
                addGoal.areaId = props.areaId;
                addGoal.areaName = props.areaName;

                addGoal.text = props.item.text;
                addGoal.id = props.item.id;
                addGoal.completedByDate = new Date(props.item.completedByDate);

                addGoal.edit = 1;
                if (props.CurrentTab == 'pending') {
                    addGoal.color = props.color;
                } else {
                    addGoal.color = props.item.color;
                }
                props.setGoal(addGoal);
                props.clickButtonAddGoal();

                return;
            case 4:
                changeTextModal({
                    title: 'ARE YOU SURE?',
                    desc: 'Do you really want delete this goal?',
                });
                break;
            default:
                changeTextModal({ title: '?', desc: '' });
        }
        changeModalNotificationVisible(!modalNotificationVisible);
    }

    function DoChanges() {
        let url = '';
        let PostObj = {};
        switch (actionToModify.state) {
            case 1:
                url = `${configData.SERVER_URL}Goals/SetGoalInProgress`;
                PostObj = {
                    userId,
                    id: actionToModify.id,
                };
                axios({
                    method: 'put',
                    url,
                    headers: { Authorization: `Bearer ${token}` },
                    data: PostObj,
                })
                    .then((result) => {
                        console.log(result);
                        changeModalNotificationVisible(
                            !modalNotificationVisible
                        );
                        props.getUserGoals();
                    })
                    .catch((error) => {
                        console.log(error);
                        // props.AllState.ActionIdComplete = 0;
                    });
                break;
            case 2:
                url = `${configData.SERVER_URL}Goals/CompleteDashboardGoal`;
                PostObj = {
                    userId,
                    id: actionToModify.id,
                };
                axios({
                    method: 'put',
                    url,
                    headers: { Authorization: `Bearer ${token}` },
                    data: PostObj,
                })
                    .then(() => {
                        changeModalNotificationVisible(
                            !modalNotificationVisible
                        );
                        props.getMyProgress();
                        props.getUserGoals();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                break;
            case 4:
                url = `${configData.SERVER_URL}Goals/${actionToModify.id}`;
                axios
                    .delete(url, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(() => {
                        changeModalNotificationVisible(
                            !modalNotificationVisible
                        );
                        props.getUserGoals();
                    })
                    .catch((error) => {
                        console.log(error);
                        props.AllState.ActionIdComplete = 0;
                    });
                break;
            default:
                break;
        }
    }

    let date = new Date(props.item.completedByDate);
    if (props.CurrentTab === 'completed') {
        date = new Date(props.item.completionDate);
    }

    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const dateFormat = `${mo}/${da}/${ye}`;

    return (
        <div>
            {props.item.isOneGoal && <div className="titleMyOolaOne">MY OOLA ONE</div>}
            <article
                className="goal"
                style={{
                    backgroundColor: props.item.color,
                    color: 'white',
                    border: props.item.isOneGoal
                        ? 'solid 3px var(--blue-light)'
                        : `solid 2px ${props.item.color}`,
                        marginBottom: props.item.isOneGoal
                        ? '23px'
                        : `10px`,
                        marginTop: props.item.isOneGoal
                        ? '0px'
                        : `10px`,
                }}
            >
                <div
                    className={props.item.isOneGoal ?"goal__FNameOolaOne":"goal__FName"}
                    style={{
                        color: props.item.isOneGoal
                            ? 'transparent'
                            : props.item.color,
                    }}
                >
                    {props.item.isOneGoal ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.891"
                            height="33"
                            viewBox="0 0 10.891 33"
                        >
                            <path
                                fill={props.item.color}
                                stroke="rgba(0,0,0,0)"
                                strokeMiterlimit="10"
                                d="M0 4.945A4.877 4.877 0 0 1 4.794 0a4.877 4.877 0 0 1 4.793 4.945 4.877 4.877 0 0 1-4.793 4.946A4.877 4.877 0 0 1 0 4.945zm1.037 0A3.821 3.821 0 0 0 4.794 8.82 3.821 3.821 0 0 0 8.55 4.945a3.821 3.821 0 0 0-3.756-3.874 3.821 3.821 0 0 0-3.757 3.874zm25.87 4.867a.546.546 0 0 1-.2-.21.569.569 0 0 1-.075-.285.584.584 0 0 1 .075-.289.549.549 0 0 1 .2-.211.54.54 0 0 1 .556 0 .561.561 0 0 1 .2.211.583.583 0 0 1 .076.287.575.575 0 0 1-.075.288.56.56 0 0 1-.2.21.537.537 0 0 1-.556 0zm.038-.929a.477.477 0 0 0-.176.183.5.5 0 0 0-.065.25.5.5 0 0 0 .065.248.476.476 0 0 0 .177.182.465.465 0 0 0 .241.066.447.447 0 0 0 .238-.067.486.486 0 0 0 .176-.181.516.516 0 0 0 0-.5.491.491 0 0 0-.176-.183.455.455 0 0 0-.238-.066.469.469 0 0 0-.242.068zM25.072 9.89V6.417a2.386 2.386 0 0 0-1.2-2.112 2.272 2.272 0 0 0-1.092-.281 2.361 2.361 0 0 0-2.32 2.239 2.389 2.389 0 0 0 2.381 2.557h1.453v1.069h-1.511a3.288 3.288 0 0 1-2.424-1.065 3.5 3.5 0 0 1-.936-2.543 3.426 3.426 0 0 1 3.241-3.326 3.272 3.272 0 0 1 1.833.482 3.5 3.5 0 0 1 1.612 2.983v3.47zm-7.465 0V0h1.036v9.89zm-7.5-3.468a3.364 3.364 0 1 1 6.724 0 3.364 3.364 0 1 1-6.724 0zm1.037 0a2.326 2.326 0 1 0 4.65 0 2.326 2.326 0 1 0-4.65 0zm16.218 3.22a.593.593 0 0 1-.016-.1V9.46a.142.142 0 0 0-.014-.063.056.056 0 0 0-.031-.027.235.235 0 0 0-.08-.008h-.16v.276h-.1v-.659h.267a.336.336 0 0 1 .135.02.16.16 0 0 1 .068.063.206.206 0 0 1 .026.1.184.184 0 0 1-.019.087.18.18 0 0 1-.062.06.128.128 0 0 1 .059.05.243.243 0 0 1 .015.1v.075a.163.163 0 0 0 .023.087l.011.018zm-.306-.372h.156a.186.186 0 0 0 .1-.017.088.088 0 0 0 .038-.082.1.1 0 0 0-.03-.071.17.17 0 0 0-.1-.023h-.163zm3.678.371v-6.69h-1.268v-.8a6.257 6.257 0 0 0 .954-.519A4.362 4.362 0 0 0 31.251.9H32v8.74z"
                                transform="rotate(-90 16.5 16)"
                            />
                        </svg>
                    ) : (
                        <h6 className="heading-6">
                            {props.item.areaName.toUpperCase()}
                        </h6>
                    )}
                </div>

                <div className="goal__number">
                    <h3 className="heading-3 ">
                        {props.CurrentTab === 'inProgress'
                            ? props.index + 1
                            : ''}
                    </h3>
                </div>

                <div
                    className="goal__text"
                    onMouseEnter={() => changeShowGoalTextpop(true)}
                    onMouseLeave={() => changeShowGoalTextpop(false)}
                >
                    <OverlayTrigger
                        trigger={'click'}
                        placement="bottom"
                        rootClose
                        overlay={popoverGoalText(props.item.text)}
                        show={showGoalTextpop}
                    >
                        <p className="paragraph-2">
                            {props.item.text}
                        </p>
                    </OverlayTrigger>
                </div>
                <div className="goal__date">
                    <p className="paragraph-3">{dateFormat}</p>
                </div>
                <div className="goal__button">
                    {props.CurrentTab !== 'completed' ? (
                        <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose
                            overlay={
                                props.CurrentTab !== 'inProgress'
                                    ? popover
                                    : popoverInProgress
                            }
                            delay={{ show: 0, hide: 400 }}
                        >
                            <button type="button" className="btnEditMyGoal">
                                ...
                            </button>
                        </OverlayTrigger>
                    ) : (
                        <img src={completeImage} alt="complete" />
                    )}
                </div>
                <Modal
                    show={modalNotificationVisible}
                    onHide={() =>
                        changeModalNotificationVisible(
                            !modalNotificationVisible
                        )
                    }
                    aria-labelledby="example-custom-modal-styling-title"
                    id="modalNotificationAction"
                    size="xs"
                    centered
                >
                    <Modal.Body>
                        <section
                            style={{
                                textAlign: 'center',
                                padding: '10px',
                            }}
                        >
                            <h2
                                className="heading-2"
                                style={{ paddingTop: '2rem', color: 'black' }}
                            >
                                {textModal.title}
                            </h2>
                            <p
                                className="paragraph-2"
                                style={{ color: 'black', fontWeight: 'bold' }}
                            >
                                {textModal.desc}
                            </p>
                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '20px 0',
                                }}
                            >
                                <button
                                    onClick={() =>
                                        changeModalNotificationVisible(
                                            !modalNotificationVisible
                                        )
                                    }
                                    className="button-empty-mid"
                                    type="button"
                                    style={{
                                        margin: '5px',
                                    }}
                                >
                                    CANCEL
                                </button>
                                <button
                                    onClick={() => DoChanges()}
                                    className="button-full-mid"
                                    type="button"
                                    style={{
                                        margin: '5px',
                                    }}
                                >
                                    YEAH, I&apos;M SURE
                                </button>
                            </div>
                        </section>
                    </Modal.Body>
                </Modal>
            </article>
        </div>
    );
};
export default Goal;
