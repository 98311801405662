import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import './Watch.css';
// import photo from "../../../../Assets/img/ELearningBoardImg/1.png"
const Read = (props) => {
    const Section = props.AllState.ModuleInfo.sections.find(
        (x) => x.name === 'Read'
    );
    function createMarkup(value) {
        if (localStorage.getItem('isCertificationModule') === "1") {
            return {
                __html: String(
                    value === 'contentText'
                        ? `<p>
                            ${Section[value]}
                            </p>`
                        : `${Section[value]}`
                ).replaceAll('className', 'class'),
            };
        }

        return {
            __html: String(
                value === 'contentText'
                    ? `<p>
                        <img style="float: right; margin: 0px 0px 15px 55px;" src="${props.configData.SERVER_URL_IMG}${Section.imageUrl}" width="40%" />
                        ${Section[value]}
                        </p>`
                    : `${Section[value]}`
            ).replaceAll('className', 'class'),
        };
    }
    const ReadR = (
        <div>
            <Container fluid className="watch_container">
                <div className="watchHeader_container">
                    <div className="watchHeader_wrapper">
                        <div className="WatchTitle">
                            <h5>
                                <label>
                                    {' '}
                                    {Section != null && Section.title}
                                </label><br/>
                               {" "} PROGRESS{' '}
                                <span style={{ color: '#78ccf6' }}>
                                    2/{props.lenghtModules}
                                </span>
                            </h5>
                        </div>
                        <div className="watch__modules">
                            <div className="watchTitle__modules">
                                {Section != null && (
                                    <div
                                        className="WatchDesc"
                                        dangerouslySetInnerHTML={createMarkup(
                                            'introText'
                                        )}
                                    />
                                )}
                            </div>
                            <div
                                className="watchHeader_container"
                                style={{
                                    paddingLeft: '0px',
                                }}
                            >
                                <div className="watch__modules">
                                    {Section != null && (
                                        <div
                                            className="WatchDesc"
                                            dangerouslySetInnerHTML={createMarkup(
                                                'contentText'
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );

    return ReadR;
};
export default Read;
