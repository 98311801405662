// sidebar.js
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import configData from '../../config.json';

const SideBar = ({ myBusinessHandler, logOutHandler,profile }) => {
    const path = window.location.pathname;
    const styles = {
        bmBurgerButton: {
            position: 'absolute',
            width: '36px',
            height: '30px',
            right: '55px',
            top: '13px',
        },
        bmBurgerBars: {
            background: '#000',
        },
        bmBurgerBarsHover: {
            background: '#000',
        },
        bmCrossButton: {
            height: '44px',
            width: '44px',
        },
        bmCross: {
            background: '#000',
        },
        bmMenuWrap: {
            height: '100%',
            zIndex: 10000000,
            width: '300px',
        },
        bmMenu: {
            background: '#FFF',
            padding: '0',
            fontSize: '1.15em',
        },
        bmMorphShape: {
            fill: '#373a47',
        },
        bmItemList: {
            color: '#b8b7ad',
            marginLeft: '20%',
            textAlign: 'justify',
            marginTop: '50px',
        },
        bmItem: {
            display: 'block',
            fontFamily: 'var(--us101-font)',
            fontSize: '1.2rem',
            color: '#2c414c',
            margin: '5px 10px',
            '&:active': {
                color: 'var(--blue-light)',
            },
            '&:hover': {
                color: 'var(--blue-light)',
            },
        },
        bmOverlay: {
            background: '#000',
        },
    };
    const SideBarR = (
        <Menu right styles={styles} className="menuSidebar">
            <a
                className="menu-item"
                onClick={myBusinessHandler}
                aria-label="MY BACKOFFICE
"
            >
                MY BACKOFFICE

            </a>
            {(profile?.isActiveCoach || path == '/DashboardCoach') &&
                <a
                className="menu-item"
                    onClick={() =>
                        (window.location.href =
                            path == '/DashboardCoach'
                                ? './Dashboard'
                                : './DashboardCoach')
                    }
                    aria-label="MY COACHING-Oola"
                >
                    {path == '/DashboardCoach' ? 'MY OOLA' : 'MY COACHING PORTAL'}
                </a>}
            <a className="menu-item" href="/" aria-label="Oola Homepage">
                OOLA HOMEPAGE
            </a>
            <a
                className="menu-item"
                href={configData.FINDACOACHLINK}
                aria-label="Find a Coach"
            >
                FIND A COACH
            </a>
            <a
                className="menu-item"
                href="https://www.oolalifecoach.com/"
                aria-label="Become a Coach"
            >
                BECOME A COACH
            </a>
            <a
                className="menu-item"
                aria-label="The Store"
                href="https://oolalifestore.com"
            >
                THE STORE
            </a>
            <a className="menu-item" href="https://www.facebook.com/OolaLife" aria-label="Community">
                COMMUNITY
            </a>
            <a
                className="menu-item"
                onClick={logOutHandler}
                aria-label="My Business"
            >
                LOG OUT
            </a>
        </Menu>
    );

    return SideBarR;
};
export default SideBar;
