import React, { useState, useEffect, useContext } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider, Typography, Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../../../../Context/AppContext';
import HeaderDashboard from '../../Header/HeaderDashboard';
import QuestionAssesment from './QuestionAssesmentMyPP';
import Network from '../../../Network/Network';
import configData from '../../../../config.json';
import './AssesmentMyPP.css';

const Assesment = () => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [_index, ChangeIndex] = useState(0);
    const NQuestiosn = [1, 2, 3, 4, 5, 6, 7];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const AssessmentType = Number(urlParams.get('AssessmentType'));
    const [Assesments, ChangeAssesments] = useState({
        AssesmentsQuestions: [],
    });
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const url = `${
            configData.SERVER_URL
        }ELearning/GetAssessmentQuestions/${urlParams.get('AssessmentType')}`;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                ChangeAssesments({
                    AssesmentsQuestions: result.data.questions,
                    ...result.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [1]);
    const useStyles = makeStyles({
        root: {
            height: 300,
        },
    });

    function valuetext(value) {
        return `${value}°C`;
    }

    const PrettoSlider = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'vertical',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'vertical',
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);

    const classes = useStyles();

    const [rangeValues, setrangeValues] = useState({ begin: 7, end: 7 });
    const [OptionSelected, ChangeOptionSelected] = useState([]);
    const CurrQ = OptionSelected.find((x) => x.questionId - 14 === _index + 1);
    const marksMyPersonalizedPath = () => {
        const marks = [];
        Assesments.AssesmentsQuestions.forEach((element, index) => {
            const qLength = Assesments.AssesmentsQuestions.length;
            const item = {
                value: index + 1,
                label: (
                    <div
                        data-index={index}
                        className={
                            OptionSelected[qLength - index] != null ||
                            qLength - index - 1 <= OptionSelected.length
                                ? 'lblEnableAssesment'
                                : ''
                        }
                    >
                        Q
                        {qLength - index < 10
                            ? `0${qLength - index}`
                            : qLength - index}
                    </div>
                ),
            };
            marks.push(item);
        });

        return marks;
    };

    function selectQuestion(value) {
        if (value.target.dataset.index != null) {
            setrangeValues({
                begin: 7,
                end: Number(value.target.dataset.index) + 1,
            });
            const indexx = 6 - Number(value.target.dataset.index);
            ChangeIndex(indexx);
        } else {
            setTimeout(() => {
                const parent =
                    document.getElementsByClassName('MuiSlider-root')[0];
                const valuex = parent
                    .querySelectorAll("input[type='hidden']")[0]
                    .value.split(',')[0];

                setrangeValues({ begin: 7, end: Number(valuex) });
                ChangeIndex(7 - Number(valuex));
            }, 50);
        }
    }

    function changeIndexExt(value) {
        if (value <= 6) {
            setrangeValues({ begin: 7, end: 7 - Number(value) });
            const indexx = Number(value);
            ChangeIndex(indexx);
            forceUpdate();
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        ChangeIndex(newValue);
    };
    function createMarkup(value) {
        return {
            __html: value
        };
    }
    const AssesmentR = (
        <div style={{ minHeight: '99vh', backgroundColor: '#f5f5f5' }}>
            <div style={{ backgroundColor: 'white' }}>
                <div xs={12}>
                    <HeaderDashboard />
                </div>
            </div>
            <section className="assesment__container">
                <div className="assesment__wrapper">
                    <div style={{ paddingBottom: '30px' }}>
                        <Link to="/dashboard">
                            <button type="button" className="btnBackDashboard">
                                BACK TO DASHBOARD
                            </button>
                        </Link>
                    </div>
                    <div className="AssesmentHeader" dangerouslySetInnerHTML={createMarkup(Assesments.title)}/>
                    <div className="AssesmenDescription" dangerouslySetInnerHTML={createMarkup(Assesments.congratsMessage)}/>
                </div>
            </section>
            <section className="assesment__container">
                <div className="assesment__wrapper_questions hiddenMobileQuestion">
                    <div className="hiddenMobileQuestion">
                        <>
                            <Typography id="vertical-slider" gutterBottom />
                            <div className={classes.root}>
                                <PrettoSlider
                                    orientation="vertical"
                                    aria-labelledby="vertical-slider"
                                    getAriaValueText={valuetext}
                                    marks={marksMyPersonalizedPath()}
                                    step={1}
                                    min={1}
                                    max={AssessmentType === 1 ? 14 : 7}
                                    value={[rangeValues.begin, rangeValues.end]}
                                    onChange={(input) => selectQuestion(input)}
                                    style={{ height: '60vh', width: '0.5rem' }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="  hiddenMobileQuestion">
                        <QuestionAssesment
                            _index={_index}
                            Change_Index={changeIndexExt}
                            OptionSelected={OptionSelected}
                            ChangeOptionSelected={ChangeOptionSelected}
                            CurrQ={CurrQ}
                            forceUpdate={forceUpdate}
                            Assesments={Assesments}
                            ChangeAssesments={ChangeAssesments}
                            OptionSelected={OptionSelected}
                            ChangeOptionSelected={ChangeOptionSelected}
                        />
                    </div>
                </div>
                {
                    // ------------------------------------------------Mobile ------------------------------------------
                }
                <div className={`visibleMobileQuestion`}>
                    <div>
                        <>
                            <Typography
                                id="horizontal-slider"
                                gutterBottom
                                style={{
                                    height: '10px',
                                    width: window.innerWidth * 0.8,
                                    marginLeft: window.innerWidth * 0.05,
                                }}
                            />
                            <div className="TabSlider___container">
                                <Tabs
                                    value={_index}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {NQuestiosn.map((item) => (
                                        <Tab
                                            key={`tab${item}`}
                                            className={
                                                OptionSelected.length + 2 <=
                                                item
                                                    ? 'questionTitleMobileDisable__text'
                                                    : 'questionTitleMobile__text'
                                            }
                                            label={`Q${
                                                item < 10 ? `0${item}` : item
                                            }`}
                                            disabled={
                                                OptionSelected.length + 2 <=
                                                item
                                                    ? true
                                                    : false
                                            }
                                            {...a11yProps(item)}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </>
                    </div>
                    <div className="assesment__wrapper_questionsMobile">
                        <div>
                            <QuestionAssesment
                                _index={_index}
                                Change_Index={changeIndexExt}
                                OptionSelected={OptionSelected}
                                ChangeOptionSelected={ChangeOptionSelected}
                                CurrQ={CurrQ}
                                forceUpdate={forceUpdate}
                                Assesments={Assesments}
                                ChangeAssesments={ChangeAssesments}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Network />
        </div>
    );
    return AssesmentR;
};

export default Assesment;
