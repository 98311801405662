import React from 'react';
import CoachInformationModal from '../CoachInformationModal/CoachInformationModal';
import DashboardCoachTabs from '../DashboardCoachTabs/DashboardCoachTabs';
import MyStats from '../MyStats/MyStats';
import ProfilePicture from '../ProfilePicture/ProfilePicture';

export default ({ getProfileCoachInfo }) => (
    <div className="DashboardCoachMain__container">
        <ProfilePicture getProfileCoachInfo={getProfileCoachInfo} />
        <DashboardCoachTabs />
        <MyStats />
        <CoachInformationModal />
    </div>
);
