import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import parse from 'html-react-parser';
import configData from '../../../config.json';
import './TestimonialsSection.css';

const TestimonialsSection = (props) => {
    const [data, changeData] = useState({
        index: 0,
        testimonials: [
            {
                id: 5,
                name: 'Kelley O.',
                text: 'Thanks to Oola, my husband and I found the discipline and motivation to pay off over $300,000 in student loans, all while still being able to enjoy family time and have fun along the way! The financial freedom we feel now is 100% thanks to Oola.',
                location: 'Carlsbad, CA',
                photoUrl:
                    'https://1152-frontdev.vm.epicservers.com/media/images/testimonials/Kelley O.png',
            },
        ],
    });

    function getURL() {
        return configData.SERVER_URL;
    }

    useEffect(() => {
        const url = `${getURL()}home/GetHomeTestimonials/${props.optionApi}`;
        axios
            .get(url)
            .then((result) => {
                const AllProps = { ...data };
                const Fs = [];
                result.data.forEach((item) => {
                    Fs.push(item);
                });

                AllProps.testimonials = Fs;
                AllProps.changeData = changeData;
                changeData(AllProps);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    function handleChangeIndex(index) {
        const AllProps = { ...data };

        AllProps.index = index;
        AllProps.changeData = changeData;
        changeData(AllProps);
    }

    function nextSlide() {
        let { index } = data;
        if (index === data.testimonials.length - 1) {
            index = 0;
        } else {
            index += 1;
        }
        handleChangeIndex(index);
    }
    function previousSlide() {
        let { index } = data;
        if (index === 0) {
            index = data.testimonials.length - 1;
        } else {
            index -= 1;
        }
        handleChangeIndex(index);
    }

    return (
        <section className="testimonials__container">
            <SwipeableViews
                className="testimonials_swipeable"
                index={data.index}
                onChangeIndex={handleChangeIndex}
            >
                {data.testimonials.map((item, index) => (
                    <div
                        className="testimonials__swipe"
                        key={item.id}
                        index={index}
                    >
                        <div className="testimonials__text">
                            <h4>“</h4>
                            <h3 className="heading-2">{parse(item.text)}</h3>
                        </div>
                        <div className="testimonials__person">
                            <h5>
                                <span>{item.name}</span>
                                <br />
                                {item.location}
                            </h5>
                            <img src={item.photoUrl} alt="person" />
                        </div>
                    </div>
                ))}
            </SwipeableViews>
            <div className="testimonials__buttons">
                <button
                    className="button__before"
                    onClick={previousSlide}
                    type="button"
                    aria-label="Button left"
                />
                <button
                    onClick={nextSlide}
                    className="button__after"
                    type="button"
                    aria-label="Button right"
                />
            </div>
            <div className="testimonials__pagination">
                {data.testimonials.map((item, index) => (
                    <button
                        key={item.id}
                        onClick={() => handleChangeIndex(index)}
                        className={`pagination__button ${
                            index === data.index && 'active-dot'
                        }`}
                        type="button"
                        aria-label="Button right"
                    />
                ))}
            </div>
        </section>
    );
};

export default TestimonialsSection;
