import React from 'react';
import './CloseButton.css';

export default ({ onClick, color }) => (
    <button className="closeButton" onClick={onClick} type="button">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="40"
            // height="40"
            viewBox="0 0 40 40"
        >
            <defs>
                <clipPath id="b">
                    <rect width="40" height="40" />
                </clipPath>
            </defs>
            <g id="a" clipPath="url(#b)">
                <g transform="translate(-1269.135 820.365) rotate(-45)">
                    <g transform="translate(-16803 -3223)">
                        <line
                            x2="20"
                            transform="translate(18270.5 3568.5)"
                            fill="none"
                            stroke={color || '#9B9B9B'}
                            strokeWidth="3"
                        />
                        <line
                            y1="20"
                            transform="translate(18280.5 3558.5)"
                            fill="none"
                            stroke={color || '#9B9B9B'}
                            strokeWidth="3"
                        />
                    </g>
                    <g
                        transform="translate(1462 330)"
                        fill="none"
                        stroke={color || '#9B9B9B'}
                        strokeWidth="3"
                    >
                        <circle cx="15.5" cy="15.5" r="15.5" stroke="none" />
                        <circle cx="15.5" cy="15.5" r="14" fill="none" />
                    </g>
                </g>
            </g>
        </svg>
    </button>
);
