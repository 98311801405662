import React, { useState, useEffect } from 'react';

export default ({ ChangeAssesments, Assesments, submitAssessment }) => {
    const [infoUser, setInfoUser] = useState({});
    useEffect(() => {
        ChangeAssesments({ ...Assesments, infoUser: infoUser });
    }, [infoUser]);
    return (
        <div className="MindBodyWellnessAssessmentSubmit__container">
            <h2>GREAT WORK!</h2>
            <p>
                Awesome job finishing your free Mind and Body Assessment. Please
                take a moment to plug in your information below. We will email
                you your report and reveal your results as soon as the
                calculations are complete.
            </p>
            <div className="MindBodyWellnessAssessmentSubmitFields__container">
                <div>
                    <label>First Name:</label>
                    <br />
                    <input
                        className="inputFreeTest form-control"
                        value={infoUser.FirstName}
                        onChange={(input) =>
                            setInfoUser({
                                ...infoUser,
                                FirstName: input.target.value,
                            })
                        }
                    />
                </div>

                <div>
                    <label>Last Name:</label>
                    <br />
                    <input
                        className="inputFreeTest form-control"
                        value={infoUser.LastName}
                        onChange={(input) =>
                            setInfoUser({
                                ...infoUser,
                                LastName: input.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label>Email Name:</label>
                    <br />
                    <input
                        type="email"
                        className="inputFreeTest form-control"
                        value={infoUser.EmailAddress}
                        onChange={(input) =>
                            setInfoUser({
                                ...infoUser,
                                EmailAddress: input.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label>Phone(optional):</label>
                    <br />
                    <input
                        className="inputFreeTest form-control"
                        value={infoUser.PhoneNumber}
                        onChange={(input) =>
                            setInfoUser({
                                ...infoUser,
                                PhoneNumber: input.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <br />
            <button
                className="button-full-mid"
                onClick={() => {
                    if (
                        infoUser.FirstName &&
                        infoUser.LastName &&
                        infoUser.EmailAddress
                    )
                        submitAssessment();
                    else alert('Please fill all the fields require');
                }}
            >
                {'FINISH'}
            </button>
        </div>
    );
};
