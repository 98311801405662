import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

function LinearProgressWithLabel({ value,text,lineProgressBarClass="linearProgressBar" }) {
    return (
        <div className='LinearProgresBar__Container'>
            <h1>{`${text}`}</h1>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress className={lineProgressBarClass} variant="determinate" value={value} color="secondary" />
            </Box>
        </div>
    );
}
export default LinearProgressWithLabel;
