import React, { useEffect  } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContext from './Context/AppContext';
import useGlobalState from './hooks/useGlobalState';
import AppRoute from './Routes/AppRoute';
import routes from './Routes/routes';
import HomePage from './Components/HomePage';
import HomePage2 from './Components/HomePage2';
import LoadingSection from './Components/LoadingSection/LoadingSection';
import NotificationsContainer from './Components/Common/Notifications/NotificationsContainer';
import ModalGenericError from './Components/Common/ModalGenericError/ModalGenericError';

import './App.css';
import './styles/generalVariables.css';
import './styles/stylesDesktop.css';
import './styles/stylesMobile.css';

const App = () => {
    const globalState = useGlobalState();

    useEffect(() => {

        document.documentElement.style.setProperty(
            '--vh',
            `${window.innerHeight / 100}px`
        );
        if (window.location.origin == 'https://oolalife.com') {
            window.location.replace(
                'https://www.oolalife.com' + window.location.pathname
            );
        }
    }, []);
    const currentDashboard = window.currentDashboard;

    return (
        <div className="App">
            <AppContext.Provider value={globalState}>
                <NotificationsContainer />
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={currentDashboard == 2 ? HomePage2 : HomePage}
                    />
                    <Route exact path="/globalconfig.js" />

                    {routes.map((route, index) => (
                        <AppRoute
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            isPrivate={route.isPrivate}
                            exact={route.isExact}
                            index={index}
                        />
                    ))}
                </Switch>
                <LoadingSection />
                <ModalGenericError/>
            </AppContext.Provider>
        </div>
    );
};

export default App;
