import React from 'react';
import './FooterCopyright.css';

const FooterCopyright = () => (
    <footer className="footerCopyright">
        <p className="paragraph-3">{`Copyright Oola ${new Date().getFullYear()}`}</p>
    </footer>
);

export default FooterCopyright;
