import React, { useEffect, useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import familyHomeImage from '../../../Assets/img/familyHome.png';
import useScrollProgress from '../../../hooks/useScrollProgress';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import initialState from './initialState';
import { initialStateSVG } from './initialStateSVG';
import './StartYourJourney2.css';

const StartYourJourney = () => {
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const { scrollYProgress } = useViewportScroll();

    const [refLine, startLine, endLine] = useScrollProgress(0.055);
    const [refCircle, startCircle, endCircle] = useScrollProgress(0.055);
    const [data, changeData] = useState(initialState);

    const scrollLine = useTransform(
        scrollYProgress,
        [startLine, endLine],
        [0, 1.2]
    );
    const scrollCircle = useTransform(
        scrollYProgress,
        [startCircle, endCircle],
        [0, 3]
    );

    function getURL() {
        return configData.SERVER_URL;
    }

    function selectColor(index) {
        const AllProps = { ...data };

        AllProps.FsJourneySelect = index;
        AllProps.changeData = changeData;
        changeData(AllProps);
    }

    return (
        <section className="startYourJourney__container">
            <div className="startYourJourney__wrapper">
                <div className="startYourJourney__image startYourJourney__image2">
                    {/* <img src={familyHomeImage} alt="Start your journey" /> */}
                    {/* <VideoPop
                        className="divx"
                        Src={Scene}
                        autoplay={true}
                        mute={true}
                        root="video-root"
                        ratio={{ w: 16, h: 9 }}
                    /> */}
                    <ReactPlayer
                        url={'https://vimeo.com/749312267'}
                        controls
                        playing={true}
                        muted={true}
                        loop={true}
                    />
                </div>
                <div
                    className={`startYourJourney__description startYourJourney__description2`}
                >
                    <div
                        className="description__container description__container2"
                        style={{
                            backgroundColor:
                                data.FsJourney[data.FsJourneySelect].color,
                        }}
                    >
                        {data.FsJourney[data.FsJourneySelect].svg ? (
                            <>
                                <h2 className="heading-2"></h2>
                                <div className='BenefitsSVGContainer'>{initialStateSVG[data.FsJourneySelect].svg}</div>
                            </>
                        ) : (
                            <>
                                <h2 className="heading-2">
                                    {data.FsJourney[data.FsJourneySelect].name}
                                </h2>
                                <p className="paragraph-2">
                                    {data.FsJourney[data.FsJourneySelect].text}
                                </p>
                            </>
                        )}

                        <div className="description__buttons description__buttons2">
                            {data.FsJour.map((item) => (
                                <button
                                    key={item.id}
                                    className={`${
                                        data?.FsJourneySelect === item?.id &&
                                        'selected'
                                    }`}
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                    onMouseEnter={() => selectColor(item.id)}
                                    onClick={() => selectColor(item.id)}
                                    type="button"
                                    aria-label="Change Color and F type"
                                />
                            ))}
                            <div className="buttons__journey buttons__journey2">
                                <svg
                                    ref={refLine}
                                    viewBox="0 0 942 991"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="LinesJourney "
                                        clipPath="url(#clip0)"
                                    >
                                        <path
                                            id="Union"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M435.091 0V37.281V40.281H438.091H685.008V133.693H691.008V40.281H938.476H941.476V37.281V0H935.476V34.281H441.091V0H435.091Z"
                                            fill="white"
                                        />
                                        <motion.path
                                            id="stroke-anim"
                                            className={'stroke-anim2'}
                                            d="M688 133V811.5H2V986"
                                            style={{ pathLength: scrollLine }}
                                            stroke="#78CCF6"
                                            strokeWidth="6"
                                        />
                                        <path
                                            id="circle_border"
                                            d="M467 445.192C467 323.228 566.237 224 688.2 224C810.164 224 909.4 323.22 909.4 445.192C909.4 567.164 810.168 666.392 688.2 666.392C566.232 666.392 467 567.156 467 445.192ZM688.197 623.875C786.72 623.875 866.869 543.726 866.869 445.2C866.869 346.672 786.723 266.528 688.197 266.528C589.671 266.528 509.522 346.674 509.522 445.2C509.522 543.726 589.674 623.875 688.197 623.875Z"
                                            fill="white"
                                            stroke="#78CCF6"
                                            strokeWidth="6"
                                        />
                                        <g id="circleFill-Mask">
                                            <mask
                                                id="mask0"
                                                mask-type="alpha"
                                                maskUnits="userSpaceOnUse"
                                                x="464"
                                                y="221"
                                                width="448"
                                                height="448"
                                            >
                                                <circle
                                                    id="circle-fill"
                                                    cx="688"
                                                    cy="445"
                                                    r="224"
                                                    fill="#78CCF6"
                                                />
                                            </mask>
                                            <g mask="url(#mask0)">
                                                <motion.path
                                                    ref={refCircle}
                                                    id="fill-anim"
                                                    d="M688 206V699"
                                                    style={{
                                                        pathLength:
                                                            scrollCircle,
                                                    }}
                                                    stroke="#78CCF6"
                                                    strokeWidth="455"
                                                />
                                            </g>
                                        </g>
                                        <circle
                                            id="mid-white"
                                            cx="688"
                                            cy="445"
                                            r="176"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect
                                                width="941.477"
                                                height="990.505"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                    <text x="615" y="400">
                                        START
                                    </text>
                                    <text x="525" y="470">
                                        REBALANCING
                                    </text>
                                    <text x="615" y="540">
                                        TODAY
                                    </text>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StartYourJourney;
