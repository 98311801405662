import React from 'react';
import './DoButton.css';

const PrevModuleButton = ({ data, nextOrBackModule }) => (
    <div className="btnGoStep1_Elearning__container">
        <button
            type="button"
            className="prevModuleButton btnGoStep1_Elearning"
            onClick={() =>
                data?.isExternalLink
                    ? window.open(data?.link, '_blank')
                    : nextOrBackModule(data?.value)
            }
        >
            {data?.text}
        </button>
    </div>
);
const NextModuleButton = ({ data, nextOrBackModule }) => (
    <div className="btnGoStep1_Elearning__container">
        <button
            type="button"
            className="prevModuleButton btnGoStep1_Elearning"
            onClick={() =>
                data?.isExternalLink
                    ? window.open(data?.link, '_blank')
                    : nextOrBackModule(data?.value)
            }
        >
            {data?.text}
        </button>
    </div>
);

const TakeQuizButton = ({ data, setShowCretificationQuiz }) => (
    <div className="btnGoStep1_Elearning__container">
        <button
            type="button"
            className="takeQuizButton btnGoStep1_Elearning"
            onClick={() => setShowCretificationQuiz(true)}
        >
            {data?.text}
        </button>
    </div>
);

const CompleteCertificationButton = ({ data, completeCertificationModule }) => (
    <div className="btnGoStep1_Elearning__container">
        <button
            type="button"
            className="takeQuizButton btnGoStep1_Elearning"
            onClick={completeCertificationModule}
        >
            {data?.text}
        </button>
    </div>
);

const OolaWheelButton = (props) => {
    return props.AllState.ModuleInfo.isCompleted === false ? (
        <div className="btnGoStep1_Elearning__container">
            <button
                type="button"
                className="btnCompleteModule btnGoStep1_Elearning"
                onClick={() => props.startNewSteps()}
            >
                START MY OOLAWHEEL
            </button>
        </div>
    ) : (
        <div className="btnGoStep1_Elearning__container">
            <button
                type="button"
                className="btnCompleteModule btnGoStep1_Elearning btnCompleteModuleDisable"
            >
                START MY OOLAWHEEL
            </button>
        </div>
    );
};

const CompleteButton = (props) => {
    return (
        <div style={{ textAlign: 'end' }}>
            {props.AllState.ModuleInfo.isCompleted === false ? (
                <button
                    type="button"
                    className="btnCompleteModule"
                    onClick={() =>
                        props.AllState?.ModuleInfo?.goToNextLevelDesc
                            ? props.startNewSteps()
                            : props.CompleteModule()
                    }
                >
                    {props.AllState?.ModuleInfo?.goToNextLevelDesc
                        ? props.AllState.ModuleInfo.goToNextLevelDesc
                        : 'COMPLETE MODULE'}
                </button>
            ) : (
                <button type="button" className="btnCompleteModuleDisable">
                    {props.AllState?.ModuleInfo?.goToNextLevelDesc
                        ? props.AllState.ModuleInfo.goToNextLevelDesc
                        : 'COMPLETE MODULE'}
                </button>
            )}
        </div>
    );
};

const ProscanButton = ({ doButton, history }) => (
    <div className="btnGoStep1_Elearning__container">
        <button
            type="button"
            className="btnCompleteModuleProscan btnGoStep1_Elearning"
            onClick={() =>
                doButton.isExternalLink
                    ? window.open(doButton.link, '_blank')
                    : history.push(doButton.link)
            }
        >
            {doButton.text}
        </button>
    </div>
);

const Level2Button = (props) => {
    return props.AllState.ModuleInfo.isCompleted === false ? (
        <div className="btnGoStep1_Elearning__container">
            <button
                type="button"
                className="btnCompleteModule btnGoStep1_Elearning"
                onClick={() => props.startNewSteps()}
            >
                GO TO LEVEL 2 !
            </button>
        </div>
    ) : (
        <div className="btnGoStep1_Elearning__container">
            <button
                type="button"
                className="btnCompleteModule btnGoStep1_Elearning btnCompleteModuleDisable"
            >
                GO TO LEVEL 2 !
            </button>
        </div>
    );
};

const DownloadBookButton = ({ Section, props }) => {
    return (
        <div className="DoDesc btnGoStep1_Elearning__container">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
            >
                <g
                    id="Group_1783"
                    data-name="Group 1783"
                    transform="translate(-400 -2732)"
                >
                    <g id="direct-download" transform="translate(402 2738)">
                        <path
                            id="Path_2198"
                            data-name="Path 2198"
                            d="M11.867,15.69a.732.732,0,0,1-.53-.227L6.2,10.085a.734.734,0,0,1,.53-1.24H9.423V3.222A1.224,1.224,0,0,1,10.645,2H13.09a1.224,1.224,0,0,1,1.222,1.222V8.845H17a.734.734,0,0,1,.53,1.24L12.4,15.463A.732.732,0,0,1,11.867,15.69Z"
                            transform="translate(-0.133)"
                            fill="#2c414c"
                        />
                        <path
                            id="Path_2199"
                            data-name="Path 2199"
                            d="M21.758,21.912H1.711A1.713,1.713,0,0,1,0,20.2v-.489A1.713,1.713,0,0,1,1.711,18H21.758a1.713,1.713,0,0,1,1.711,1.711V20.2A1.713,1.713,0,0,1,21.758,21.912Z"
                            transform="translate(0 -0.354)"
                            fill="#d86b59"
                        />
                    </g>
                    <rect
                        id="Rectangle_827"
                        data-name="Rectangle 827"
                        width="28"
                        height="28"
                        transform="translate(400 2736)"
                        fill="none"
                    />
                </g>
            </svg>

            <a
                className="DoDownloadLink"
                href={
                    Section != null &&
                    `javascript:window.open("${
                        props.configData.SERVER_URL_IMG + Section.pdfUrl
                    }", '_blank').focus();`
                }
            >
                DOWNLOAD WORKBOOK
            </a>
        </div>
    );
};

const CreateActionSteps = (props) => {
    return (
        <div className="btnGoStep1_Elearning__container">
            <button
                type="button"
                className={`btnCompleteModule btnGoStep1_Elearning ${
                    !props.Section.isActionStepsButtonEnabled &&
                    'btnCompleteModuleDisable'
                }`}
                onClick={() => {
                    if (
                        props.Section.isActionStepsButtonEnable ||
                        props.Section.isActionStepsButtonEnable == null
                    )
                        props.CreateActionSteps();
                }}
            >
                {String(
                    props.Section.textButtonActionSteps == null
                        ? 'Activate action step'
                        : props.Section.textButtonActionSteps
                ).toUpperCase()}
            </button>
        </div>
    );
};
export {
    PrevModuleButton,
    OolaWheelButton,
    CompleteButton,
    Level2Button,
    ProscanButton,
    DownloadBookButton,
    CreateActionSteps,
    TakeQuizButton,
    NextModuleButton,
    CompleteCertificationButton,
};
