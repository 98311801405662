import React, { useState }  from 'react';
import "./ELearningHeader.css"

const Watch = (props) => {
  
  const WatchR =
       <div>
          <div>
            <div className="AssesmenDescription ModuleTitleSVG__container">
             <svg viewBox="0 0 100 20" xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: "transparent"  }}>
                 
               {
                  !props.isModuleCertification && 
                  <>
                  <g transform="translate(25.3 1.7)" className='LockModuleSVG'>
                     <g style={{transform:"scale(0.1)"}} >
                        <path fill="#d86c5a" d="M8.912 13.443H1.244A1.265 1.265 0 0 1 0 12.162V7.133a1.265 1.265 0 0 1 1.244-1.281h.031v-.026h1.71v.026h4.35V4.037A2.221 2.221 0 0 0 5.16 1.778a2.126 2.126 0 0 0-1.426.555H1.638A3.9 3.9 0 0 1 5.161 0a3.968 3.968 0 0 1 3.885 4.037v1.822a1.267 1.267 0 0 1 1.111 1.274v5.028a1.265 1.265 0 0 1-1.245 1.282zm-3.834-5.6a.835.835 0 0 0-.821.846.859.859 0 0 0 .357.7l.112.078-.426 2.178h1.554l-.423-2.181.112-.078a.859.859 0 0 0 .357-.7.835.835 0 0 0-.822-.845z" transform="translate(-.082 -.248) translate(2.33 .583)"/>
                     </g>
                  </g>
                     <text
                        className="TitleModuleElearnig"
                        y="3"
                        x="18"
                        fill="#d86b59"
                        fontSize=".09rem"
                        >
                           UNLOCKED ON
                     </text> 
                     <text
                     className="TitleDateElearnig"
                     stroke="#d86b59"
                     strokeWidth=".1px"
                     y="12"
                     x="50"
                     fill="transparent"
                     fontSize=".7rem"
                     textAnchor="middle"
                     >
                        {props.AllState.ModuleInfo==null?"":props.AllState.ModuleInfo.unlockedDateText}
                     </text> 
                  </>
               }
                {/* <text
                className="TitleDescElearnig"
                y="14"
                x="52"
                fill="#2c414c"
                fontSize=".22rem"
                textAnchor="middle" 
                >
                     {props.AllState.ModuleInfo==null?"":props.AllState.ModuleInfo.title}
                </text>  */}
                <foreignObject width="100%" height="20">
                  <div className={props.isModuleCertification ? 'TitleDescElearnigContainerCertification' : 'TitleDescElearnigContainer'}>
                     <p className="TitleDescElearnig" >{props.AllState.ModuleInfo==null?"":props.AllState.ModuleInfo.title}</p>
                  </div>
                  </foreignObject>
                <text
                className="TitleModuleElearnig"
                y="18"
                x="52"
                fill="#d86b59"
                fontSize=".18rem"
                textAnchor="middle"
                >
                   {props.AllState.ModuleInfo==null?"":props.AllState.ModuleInfo.caption}
                </text> 
             </svg>
        </div>
      </div>
   </div>
      
  return WatchR;
}

export default Watch;