import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import './NotificationsContainer.css';

const NotificationsItem = ({ item }) => {
    const [show, setShow] = useState(true);
    function createMarkup(value) {
        return { __html: value };
    }
    return (
        <div className="ItemNotifications__container">
            <Toast
                className="d-inline-block m-1"
                bg={'info'}
                show={show}
                onClose={() => setShow(false)}
            >
                <Toast.Header>
                    <h1
                        className="me-auto NotificationTitle"
                        dangerouslySetInnerHTML={createMarkup(item.title)}
                    />
                </Toast.Header>
                <Toast.Body className={'info'}>
                    <div
                        className="NotificationMessage"
                        dangerouslySetInnerHTML={createMarkup(item.message)}
                    ></div>
                </Toast.Body>
            </Toast>
        </div>
    );
};
export default NotificationsItem;
