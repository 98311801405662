import React, { useState, useEffect, useContext, useRef } from 'react';
import * as SignalR from '@microsoft/signalr';
import { Toast } from 'react-bootstrap';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import requestAxios from '../../../util/requestAxios';
import './NotificationsContainer.css';
import NotificationsItem from './NotificationsItem';
const Notifications = () => {
    const { checkUserSession } = useContext(AppContext);
    const { userId, token } = checkUserSession();
    const [currentNotificatrions, setCurrentNotificatrions] = useState([]);

    useEffect(() => {
        if (userId > 0) {
            getNotifications();
            const hubConnection = new SignalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(`${configData.SERVER_URL}notificationshub`)
                .build();
            hubConnection.start().then(function () {
                hubConnection
                    .invoke('AddToGroup', String(`UserNotifications${userId}`))
                    .catch((err) => console.log(err.toString()));
            });
            hubConnection.on('GetNotifications', function (x, y) {
                setCurrentNotificatrions([]);

                setTimeout(() => {
                    getNotifications();
                }, 1000);
            });
        }
    }, []);

    async function getNotifications() {
        let url = `Notification/GetNotification`;
        const { error, data } = await requestAxios({
            url: url,
            token: token,
        });
        if (error) {
            console.log(error);
        } else {
            setCurrentNotificatrions(data);
        }
    }

    return (
        <div className="AllNotifications__container">
            {userId > 0 &&
                currentNotificatrions.map((item, i) => (
                    <NotificationsItem
                        key={`NotificationItem${i}`}
                        item={item}
                    />
                ))}
        </div>
    );
};
export default Notifications;
