import React, { useState, useContext } from 'react';
import axios from 'axios';
import configData from '../../../../config.json';
import Wheel from '../Wheel';
import OolaReviewReviseReaffirmGoals from './OolaReviewReviseReaffirmGoals';
import ModalOolaReviewReviseReaffirm from './ModalOolaReviewReviseReaffirm';
import ModalError from '../TemplateGoals/ModalError';
import AppContext from '../../../../Context/AppContext';
import './OolaReviewReviseReaffirm.css';

const OolaReviewReviseReaffirm = (props) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const currUser = checkUserSession();
    const AllProps = { ...props.AllState };
    AllProps.CurrentF = 0;
    const [showConfirm, setShowConfirm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorObj, setErrorObj] = useState({
        ModalErrorTitle: 'You are almost there',
        ModalErrorDesc: 'You need to add 21 goals to continue',
    });
    function confirmGoals() {
        changeLoading(true);
        const url = `${configData.SERVER_URL}goals/RemoveAssessmentGoals`;
        axios
            .delete(url, {
                headers: { Authorization: `Bearer ${currUser.token}` },
            })
            .then((result) => {
                props.getData(1);
            })
            .catch((error) => {
                console.log(error);
                changeLoading(false);
            });
    }

    function NextStep() {
         
        if (props.AllState.SelectedGoals.length === 21) {
            window.scrollTo(-2000, -2000);
            props.updateIndexStep2(2);
        }
        else{
            setShowError(true)
        }
    }
    const newObject = {};
    if (typeof AllProps.SelectedGoalsGroup !== 'undefined') {
    } else {
        AllProps.SelectedGoals.forEach((x) => {
            if (!newObject.hasOwnProperty(x.areaId)) {
                newObject[x.areaId] = {
                    Fs: AllProps.Fs[x.areaId],
                    selected: [],
                };
            }
            newObject[x.areaId].selected.push({
                areaId: x.areaId,
                date: x.date,
                recomendationIndex: x.recomendationIndex,
                text: x.text,
                selected: !!x.selected,
                Id: x.Id,
                GoalId: x.GoalId,
            });
        });

        props.AllState.SelectedGoalsGroup = newObject;

        props.changeData(props.AllState);
    }
    const obj = (
        <section className="OolaReviewReviseReaffirm__Container">
            <div className="OolaReviewReviseReaffirm__WheelContainer">
                <Wheel AllState={props.AllState} hideNav={false} allColors />
                <h1>Use the tracker to help determine your 7 goals</h1>
            </div>
            <div>
                <OolaReviewReviseReaffirmGoals
                    updateIndexStep2={props.updateIndexStep2}
                    forceUpdate={props.forceUpdate}
                    AllState={props.AllState}
                    changeData={props.changeData}
                    hideNav={false}
                    confirmGoals={confirmGoals}
                    setShowConfirm={setShowConfirm}
                    getData={props.getData}
                />
                <div style={{ flex: 1, display: 'flex', width: '80%' }}>
                    <div
                        className="btnNextStep2"
                        aria-hidden="true"
                        style={{
                            color:
                                props.AllState.SelectedGoals.length >= 21
                                    ? '#D86B59'
                                    : 'gray',
                            marginLeft: 'auto',
                        }}
                        onClick={() => NextStep()}
                    >
                        NEXT {'>'}
                    </div>
                </div>
            </div>
            <ModalOolaReviewReviseReaffirm
                setShow={setShowConfirm}
                Show={showConfirm}
                ModalConfirmTitle={
                    <div style={{ textAlign: 'center' }}>
                        ARE YOU SURE YOU WANT <br />
                        TO DELETE ALL 21 GOALS
                    </div>
                }
                ModalConfirmDesc=""
                ConfirmEvent={confirmGoals}
                ConfirmText="YES! CONTINUE"
                CancelText="NO, TAKE ME BACK"
            />
             <ModalError
                show={showError}
                setShowError={setShowError}
                ModalErrorTitle={errorObj.ModalErrorTitle}
                ModalErrorDesc={errorObj.ModalErrorDesc}
            />
        </section>
    );

    return obj;
};

export default OolaReviewReviseReaffirm;
