import { Form } from 'react-bootstrap';

const RadioButton = props => (
    <label className="containerOption">
        {props.text}
        <input type="radio"{...props} />
        <span className="checkmark" />
    </label>
);
const InputNumeric = props => {
    return (
        <div className={props.className}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{props.text}</Form.Label>
                <Form.Control
                    type="number"
                    min={0}
                    max={1000}
                    step="0.01"
                    onChange={props.onChange}
                />
            </Form.Group>
        </div>
    );
};

export { InputNumeric, RadioButton };
