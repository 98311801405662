import React, { useContext, useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import Confetti from '../../../Step1/Confetti/Confetti';
import Wheel from '../Wheel/Wheel';
import config from '../../../../config.json';
import './SubmitQuestionary.css';

const SubmitQuestionary = ({ mobile }) => {
    const { changeLoading, updateFreeTestData, checkFreeUserSession, getData } =
        useContext(AppContext);

    const FreeTestData = checkFreeUserSession();
    const { Shopify } = getData();
    const [validated, setValidated] = useState(false);
    const [done, setDone] = useState(false);
    const [visibleConfetti, setVisibleConfetti] = useState(true);
    const [refName, setRefName] = useState(null);
    const [infoUser, setInfoUser] = useState({});
    let { AmbassadorsName } = useParams();
    useEffect(() => {
        console.log('== Ambassador == ', AmbassadorsName);
        if (!AmbassadorsName) {
            const queryParameters = new URLSearchParams(window.location.search);
            setRefName(queryParameters.get('ref'));
        } else {
            setRefName(AmbassadorsName);
        }
    }, []);

    useEffect(() => {
        console.log('== Ref == ', refName);
    }, [refName]);

    function createMarkup(text) {
        return { __html: text };
    }
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        const url = 'Scores/FreeTestSave';
        let scoresList = [];
        FreeTestData.Fs.forEach((x) =>
            x.questions.forEach((xx) =>
                scoresList.push({ questionId: xx.id, score: xx.score })
            )
        );
        const postObj = {
            user: {
                FirstName: infoUser.firstName,
                LastName: infoUser.lastName,
                EmailAddress: infoUser.email,
                AmbassadorsName: refName || '',
                PhoneNumber: infoUser.phone,
            },
            scores: scoresList,
        };
        changeLoading(true);
        const { error, data } = await requestAxios({
            url: url,
            method: 'post',
            data: postObj,
        });
        if (error === '') {
            let newData = { ...FreeTestData };
            newData.oolaScore = data.oolaScore;
            newData.results = data.results;
            newData.recommendations = data.recommendations;
            let olData = JSON.parse(JSON.stringify({ ...FreeTestData }));
            olData.oolaScore = data.oolaScore;
            olData.results = data.results;
            olData.recommendations = data.recommendations;
            data.areas.forEach((item, i) => {
                olData.Fs[i].score = 0;
                newData.Fs[i].score = Number(item.score) / 10;
            });

            updateFreeTestData(olData);
            setTimeout(() => {
                updateFreeTestData(newData);
                localStorage.clear();
            }, 3000);
            setTimeout(() => {
                setVisibleConfetti(false);
            }, 4500);
            setValidated(true);
            setDone(true);
            localStorage.clear();
        }

        changeLoading(false);
    };
    return (
        <>
            {done && <Confetti delay={3000} mobile={mobile} />}

            <div
                className={`SubmitQuestionaryForm__container ${
                    done && 'doneFormFreeTest'
                }`}
            >
                <Form
                    className={`downarrowdiv SubmitQuestionaryFormUser__container `}
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <h1>
                        GET YOUR OOLA <br />
                        WHEEL <span>RESULT</span>
                    </h1>
                    <Row className="mb-3">
                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="inputFreeTest"
                        >
                            <Form.Label>FIRST NAME</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                className="inputFreeTest"
                                pattern="^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]*$"
                                value={infoUser.firstName}
                                onChange={(input) => {
                                    if (
                                        input.target.validity.valid ||
                                        input.target.value == ''
                                    ) {
                                        setInfoUser({
                                            ...infoUser,
                                            firstName: input.target.value,
                                        });
                                        setValidated(true);
                                    } else {
                                        if (infoUser.firstName == null) {
                                            setInfoUser({
                                                ...infoUser,
                                                firstName: '',
                                            });
                                        }
                                        setValidated(true);
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                            className="inputFreeTest"
                        >
                            <Form.Label>LAST NAME</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                pattern="^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]*$"
                                className="inputFreeTest"
                                value={infoUser.lastName}
                                onChange={(input) => {
                                    if (
                                        input.target.validity.valid ||
                                        (input.target.value == '' &&
                                            infoUser.lastName == null)
                                    ) {
                                        setInfoUser({
                                            ...infoUser,
                                            lastName: input.target.value,
                                        });
                                        setValidated(true);
                                    } else {
                                        if (infoUser.lastName == null) {
                                            setInfoUser({
                                                ...infoUser,
                                                lastName: '',
                                            });
                                        }
                                        setValidated(true);
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                            className="inputFreeTest"
                        >
                            <Form.Label>EMAIL</Form.Label>
                            <InputGroup className="inputFreeTest">
                                <Form.Control
                                    type="email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    onChange={(input) =>
                                        setInfoUser({
                                            ...infoUser,
                                            email: input.target.value,
                                        })
                                    }
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom02"
                            className="inputFreeTest"
                        >
                            <Form.Label>PHONE (OPTIONAL)</Form.Label>
                            <Form.Control
                                type="text"
                                pattern="[0-9]*"
                                value={infoUser.phone}
                                maxLength={18}
                                onChange={(input) => {
                                    if (input.target.validity.valid)
                                        setInfoUser({
                                            ...infoUser,
                                            phone: input.target.value,
                                        });
                                    else setValidated(true);
                                }}
                            />
                        </Form.Group>
                        <button
                            type="submit"
                            className="button-full-mid submitQuestionaryInfoUser"
                        >
                            SUBMIT
                        </button>
                    </Row>
                </Form>
                <hr className="submitQuestionaryInfoUser__BlueLine" />
            </div>
            <div className="SubmitQuestionary__container">
                <div
                    className={`SubmitQuestionaryWheel__container Mobile ${
                        done && 'doneFormFreeTest'
                    }`}
                >
                    {done && mobile && <Wheel />}
                </div>
                <div
                    className={`SubmitQuestionaryCongrats__container ${
                        done && 'doneFormFreeTest'
                    }`}
                >
                    <div className="titleFs__container NoMobile">
                        <h3 className="titleFs__title FreeTest">
                            <span style={{ color: '#78ccf6' }}>LIVE</span>{' '}
                            DIFFERENTLY
                        </h3>
                    </div>
                    <div
                        className={`dataFs__container FreeTest ${
                            done && 'done'
                        } `}
                    >
                        <div className="dataFs__description FreeTest">
                            <h4>GREAT WORK!</h4>
                            <h5>
                                {`Awesome job on finishing your free Life Balance
                                Test! Please take a moment to plug in your
                                information in the form. We will email you your
                                report and reveal your results as soon as the
                                calculations are complete.`}
                            </h5>
                        </div>
                    </div>
                    <div className={`dataFs__container ${done && 'done'} `}>
                        <div className="dataFs__description FreeTest">
                            <h4>GREAT WORK</h4>
                            <h4>
                                {`${infoUser.firstName} ${infoUser.lastName}`}!
                            </h4>
                            <br />
                            <h5>RESULTS</h5>
                            <div
                                className="FreeTestResults__container"
                                dangerouslySetInnerHTML={createMarkup(
                                    FreeTestData.results
                                )}
                            />
                            <br />
                            {/*<h5>RECOMMENDATIONS</h5>
                            <div
                                className="FreeTestRecommendations__container"
                                dangerouslySetInnerHTML={createMarkup(
                                    FreeTestData.recommendations
                                )}
                            />
                            */}
                            <h7>
                                *Please check spam box for your Life Balance
                                report
                            </h7>
                        </div>

                        <div className="dataFs__buttonsFreeTest">
                            <button
                                className="dataFs__btnStep1"
                                style={{
                                    color: 'white',
                                }}
                                onClick={() => {
                                    if (Shopify?.isShopify) {
                                        window.top.location.href =
                                            config.SHOPIFYHOMEPAGE;
                                    } else {
                                        window.top.location.hrefwindow.location.replace(
                                            '/'
                                        );
                                    }
                                }}
                                type="button"
                            >
                                GO TO HOME PAGE
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`SubmitQuestionaryWheel__container NoMobile ${
                        done && 'doneFormFreeTest'
                    }`}
                >
                    {done && !mobile && <Wheel />}
                </div>
            </div>
        </>
    );
};

export default SubmitQuestionary;
