import React from 'react';
import benefits from './initialState';
import './Benefits.css';

import GuaranteeSVG from '../../../Assets/img/Guarantee.svg';

const HomeHeader = (props) => (
    <section className="benefits">
        <article className="benefits__price">
            <h2 className="display-3">
                <span>$</span>47
            </h2>
            <h3 className="display-2">PER MONTH</h3>
            <p className="paragraph-1">
                After initial Start-Up of $77 for
                <br />
                 your first month. No Enrollment Fee
                <br />
                CANCEL ANYTIME
            </p>
            <figure>
                <img className="Guarantee" src={GuaranteeSVG} alt="guarantee" />
            </figure>
        </article>
        <article className="benefits__allBenefits">
            <div className="allBenefits__text">
                {benefits.map((data) => (
                    <p className="paragraph-1" key={data.id}>
                        <span className="check-icon" />
                        {data.Text} <span>{data.TextItalic}</span>
                    </p>
                ))}
            </div>
            <div className="allBenefits__button">
                <button
                    className="button-full-big"
                    onClick={() => props.goEnroll()}
                    type="button"
                >
                    ENROLL NOW
                </button>
            </div>
        </article>
    </section>
);

export default HomeHeader;
