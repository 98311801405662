import React, { useState, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import AppContext from '../../../../Context/AppContext';

const FreeQuestions = (props) => {
    const [activeInput, ChangeActiveInput] = useState(1);
    const { updateFreeTestData, checkFreeUserSession, changeLoading } =
        useContext(AppContext);
    const FreeTestData = checkFreeUserSession();

    const isSafari = navigator.userAgent.match('/safari/i');

    function setScore(value, index, noNext) {
        if (value != null) {
            const newData = { ...FreeTestData };
            newData.Fs[props.FIndesx].questions[index].score = value;
            /*NextQuestion*/
            if (!noNext) {
                if (newData.currentQuestion < 2) {
                    newData.currentQuestion += 1;
                } else {
                    newData.currentQuestion = 0;
                    newData.index += 1;
                }

                if (newData.lastF < newData.index) {
                    newData.lastF = newData.index;
                }

                setTimeout(() => {
                    ChangeActiveInput(1);
                }, 900);
                setTimeout(() => {
                    updateFreeTestData({
                        ...newData,
                    });
                }, 500);
            } else {
                updateFreeTestData({
                    ...newData,
                });
            }
        }
    }
    function setNextQuestion() {
        const newData = { ...FreeTestData };
        /*NextQuestion*/
        if (newData.currentQuestion < 2) {
            newData.currentQuestion += 1;
        } else {
            newData.currentQuestion = 0;
            newData.index += 1;
        }

        if (newData.lastF < newData.index) {
            newData.lastF = newData.index;
        }

        setTimeout(() => {
            ChangeActiveInput(1);
        }, 900);
        setTimeout(() => {
            updateFreeTestData({
                ...newData,
            });
        }, 500);
    }

    function getNumberrange(n, item) {
        const elements = [];
        for (let i = 1; i <= 10; i += 1) {
            elements.push(
                <p
                    aria-hidden="true"
                    onClick={() => {
                        // changeIndex(props, 1, i, item.id)
                        if (activeInput === 1) {
                            ChangeActiveInput(0);
                            setScore(i, n);
                        }
                    }}
                    key={`NumberValueRange${item.id}${i}`}
                    style={{
                        fontSize:
                            props.hideNav === false ? '' : '15px !important',
                        cursor: 'pointer',
                        color: `${
                            Number(item.score) === i ? props.FData.color : ''
                        }`,
                        opacity: `${activeInput === 1 ? '' : '.6'}`,
                    }}
                    className={`tickmarks__numbers rangeNum  `}
                >
                    {i}
                </p>
            );
        }
        return elements;
    }
    return (
        <div className="question__container">
            <SwipeableViews
                index={FreeTestData.currentQuestion}
                disabled
                className="questionData__containerSwipeable"
                style={{
                    height: '100%',
                }}
            >
                {props.FData.questions.map((item, i) => (
                    <div
                        key={`questionData__container${item.id}${i}`}
                        className="questionData__container"
                        style={{
                            pointerEvents:
                                activeInput === 1 ? 'initial' : 'none',
                        }}
                    >
                        <div className="questionData__texts">
                            <h2> {item.title} </h2>
                            <p>{item.text} </p>
                        </div>

                        <div className="line__container" key={item.id}>
                            <input
                                id={`range${item.id}Q${props.FData.id}`}
                                type="range"
                                min="1"
                                max="10"
                                className="rangeInput"
                                disabled={!activeInput}
                                onClick={(input) => {
                                    setScore(input.target.value, i, true);
                                }}
                                onChange={(input) => {
                                    setScore(input.target.value, i, true);
                                }}
                                onTouchEndCapture={(input) => {
                                    if (
                                        activeInput === 1 &&
                                        input.target.value != null
                                    ) {
                                        setScore(input.target.value, i, true);
                                        setNextQuestion();
                                    }
                                }}
                                onMouseUpCapture={(input) => {
                                    if (activeInput === 1) {
                                        ChangeActiveInput(0);

                                        setScore(input.target.value, i);
                                    }
                                }}
                                style={{
                                    background: '#78CCF6',
                                }}
                                value={item.score == null ? 1 : item.score}
                                list="tickmarks"
                            />
                            <div id="tickmarks">{getNumberrange(i, item)}</div>
                        </div>
                    </div>
                ))}
            </SwipeableViews>
        </div>
    );
};

export default FreeQuestions;
