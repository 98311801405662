import React, { useState } from 'react';
import axios from 'axios';
 import configData from '../../../config.json';
import './PublicProfile.css';
import UserInfo from '../../Dashboard/UserInfo/UserInfo';

const PublicProfileC = ({webAlias}) => {
    const [PublicProfile, setPublicProfile] = useState(null);
     
    function loadPublicProfile() {
        let comp = '';
        if (webAlias) {
            if (PublicProfile == null) {
                const url = `${configData.SERVER_URL}Customer/GetAmbassadorProfileInformation/${webAlias}`;
                axios
                    .get(url, {})
                    .then((result) => {
                        setPublicProfile(result.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                comp = (
                    <div className="publicProfile__content">
                        <section className="publicProfile">
                            <UserInfo
                                AllState={{ profile: PublicProfile,userProgress: {goalsCompleted:PublicProfile.goalsCompleted}}}
                                PublicProfile={1}
                            />
                        </section>
                    </div>
                );
            }
        }
        return comp;
    }

    return loadPublicProfile();
};

export default PublicProfileC;
