import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
    OptionNewModule,
    SubQuestion,
    CheckBoxNewModule,
} from './OptionNewModules';
import AppContext from '../../../../Context/AppContext';
import configData from '../../../../config.json';

const Questions = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const history = useHistory();
    const [subQuestionComplete, setSubQuestionComplete] = useState(false);
    const Assesments_questionsLength =
        props.Assesments == null ? 0 : props.Assesments.questions.length;
    const selQuestion = props.Assesments.questions[props._index];
    const activeNext =
        props.OptionSelected?.filter((x) => x.questionId == selQuestion.id)
            .length > 0;
    function SelectOption(QuestionId, OptionId) {
        const selOption = props.OptionSelected.find(
            (x) => x.questionId === QuestionId
        );
        if (selOption == null) {
            props.OptionSelected.push({
                OptionId,
                questionId: QuestionId,
                index: Assesments_questionsLength - props.OptionSelected.length,
            });
        } else {
            selOption.OptionId = OptionId;
        }
        props.Change_Index(props._index + 1);
        props.ChangeOptionSelected(props.OptionSelected);
        forceUpdate();
    }
    function SelectRadio(QuestionId, OptionId) {
        const selOption = props.OptionSelected.findIndex(
            (x) => x.questionId === QuestionId && x.OptionId == OptionId
        );
        if (selOption == -1) {
            props.OptionSelected.push({
                OptionId,
                questionId: QuestionId,
                index: Assesments_questionsLength - props.OptionSelected.length,
            });
        } else {
            if (selOption > -1) {
                props.OptionSelected.splice(selOption, 1);
            }
        }
        props.ChangeOptionSelected(props.OptionSelected);
        forceUpdate();
    }
    function SelectSubQuestion(
        QuestionId,
        SubQuestionId,
        OptionId,
        OptionValue
    ) {
        let selQuestion = props.OptionSelected.find(
            (x) => x.questionId === QuestionId
        );
        if (selQuestion == null) {
            props.OptionSelected.push({
                OptionId,
                questionId: QuestionId,
                index: Assesments_questionsLength - props.OptionSelected.length,
                Subquestions: [
                    {
                        questionId: SubQuestionId,
                        OptionId: OptionId,
                        OptionValue: OptionValue,
                    },
                ],
            });
        } else {
            let selSubQuestionIndex = selQuestion.Subquestions.findIndex(
                (x) => x.questionId === SubQuestionId
            );
            if (selSubQuestionIndex == -1) {
                selQuestion.Subquestions.push({
                    questionId: SubQuestionId,
                    OptionId: OptionId,
                    OptionValue: OptionValue,
                });
            } else {
                selQuestion.Subquestions[selSubQuestionIndex] = {
                    questionId: SubQuestionId,
                    OptionId: OptionId,
                    OptionValue: OptionValue,
                };
            }
        }
        setSubQuestionComplete(true);

        if (
            props.OptionSelected[props._index].Subquestions.filter(
                (x) => Number(x.OptionValue == '' ? 0 : x.OptionValue) > 0
            ).length ===
            props.Assesments.questions[props._index].subQuestions.length
        ) {
            setSubQuestionComplete(true);
        } else {
            setSubQuestionComplete(false);
        }
        props.ChangeOptionSelected(props.OptionSelected);
        forceUpdate();
    }

    function completeAssesment() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const courseId = Number(urlParams.get('courseId'));
        const url = `${configData.SERVER_URL}AdditionalCourse/SaveAssessment`;
        const PostObj = {
            userId,
            Details: props.OptionSelected,
            CourseId: courseId,
        };
        axios({
            method: 'post',
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: PostObj,
        })
            .then(() => {
                history.push('/NewModuleWelcome?courseId=' + courseId);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function createMarkup(value) {
        return {
            __html: `${value}`,
        };
    }
    const QuestionsR = (
        <div
            style={{
                height: '100%',
                marginLeft: props.mobile ? window.innerWidth * 0.1 : '1rem',
            }}
        >
            <SwipeableViews disabled index={props._index}>
                {props.Assesments == null ? (
                    <div />
                ) : (
                    props.Assesments.questions.map((item, i) => (
                        <div
                            className={`QuestionContainerMyNewModule scrollMyGoals ${
                                props._index != i && 'hide'
                            }`}
                            key={`QA${i}`}
                        >
                            <div
                                className="QuestionAssesmentHeader"
                                dangerouslySetInnerHTML={createMarkup(
                                    item.text
                                )}
                            />
                            <div
                                style={{ paddingTop: '0.5rem' }}
                                className="question_wrapper"
                            >
                                {item.subQuestions.length === 0 ? (
                                    item.options.map((itemx, ix) =>
                                        ////////////Radio button
                                        item.questionTypeId == 1 ? (
                                            <OptionNewModule
                                                key={`OptionNewModule${item.id}${itemx.id}`}
                                                SelectOption={SelectOption}
                                                setSubQuestionComplete={
                                                    setSubQuestionComplete
                                                }
                                                item={item}
                                                itemx={itemx}
                                                i={i}
                                                ix={ix}
                                            />
                                        ) : //////////Checkbox
                                        item.questionTypeId == 2 ? (
                                            <CheckBoxNewModule
                                                key={`OptionNewModule${item.id}${itemx.id}`}
                                                SelectOption={SelectRadio}
                                                setSubQuestionComplete={
                                                    setSubQuestionComplete
                                                }
                                                item={item}
                                                itemx={itemx}
                                                i={i}
                                                ix={ix}
                                            />
                                        ) : (
                                            /////////////Default
                                            <OptionNewModule
                                                key={`OptionNewModule${item.id}${itemx.id}`}
                                                SelectOption={SelectOption}
                                                setSubQuestionComplete={
                                                    setSubQuestionComplete
                                                }
                                                item={item}
                                                itemx={itemx}
                                                i={i}
                                                ix={ix}
                                            />
                                        )
                                    )
                                ) : (
                                    <SubQuestion
                                        key={`SubQuestion${i}`}
                                        SelectOption={SelectSubQuestion}
                                        item={item}
                                        i={i}
                                        SubQuestion={item.subQuestions}
                                        setSubQuestionComplete={
                                            setSubQuestionComplete
                                        }
                                    />
                                )}
                            </div>
                            {item.imageUrl && (
                                <figure>
                                    <img
                                        src={`${configData.SERVER_URL_IMG}${item.imageUrl}`}
                                        alt="question reference"
                                    />
                                </figure>
                            )}
                        </div>
                    ))
                )}
            </SwipeableViews>
            <div className="btnChange BtnContainerAssesment">
                <button
                    type="button"
                    className={`btnAssesments botons btnPrevious d-flex  ${
                        props._index === 0 && 'hiddeKeepSpace'
                    }`}
                    onClick={() => {
                        if (props._index > 0) {
                            setSubQuestionComplete(true);
                            props.Change_Index(props._index - 1);
                        }
                    }}
                >
                    {'< PREVIOUS'}
                </button>

                {props.OptionSelected[Assesments_questionsLength - 1] != null &&
                props._index === Assesments_questionsLength - 1 ? (
                    <button
                        type="button"
                        className="btnCompleteAssesments"
                        onClick={() => completeAssesment()}
                        style={{
                            cursor: 'pointer',
                            marginLeft: window.innerWidth * 0.2,
                        }}
                    >
                        COMPLETE
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btnAssesments botons btnNextAssesment text-right "
                        onClick={() => {
                            if (subQuestionComplete || activeNext) {
                                if (
                                    props.Assesments.questions[props._index]
                                        .subQuestions.length > 0
                                ) {
                                    if (
                                        props.Assesments.questions[props._index]
                                            .subQuestions.length !=
                                        props.OptionSelected[props._index]
                                            .Subquestions.length
                                    ) {
                                        return false;
                                    }
                                }
                                props.Change_Index(props._index + 1);
                                setSubQuestionComplete(false);
                            }
                        }}
                        style={{
                            cursor:
                                subQuestionComplete || activeNext
                                    ? 'pointer'
                                    : 'initial',
                            marginLeft: window.innerWidth * 0.4,
                        }}
                    >
                        {'NEXT >'}
                    </button>
                )}
            </div>
        </div>
    );
    return QuestionsR;
};

export default Questions;
