import { useState, useEffect, useContext } from 'react';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import CarruselProduct from './CarruselProduct/CarruselProduct';
import './Sales.css';
const Sales = () => {
    const { checkUserSession, updateActivePrograms, changeLoading } =
        useContext(AppContext);
    const { token } = checkUserSession();
    const [saleType, setSaleType] = useState(1);
    const [additionalPrograms, setAdditionalPrograms] = useState([]);
    const [additionalProducts, setAdditionalProducts] = useState([]);
    useEffect(() => {
        getData();
        getDataProducts();
    }, []);
    const getData = async () => {
        const url = `AdditionalCourse/GetCourses`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            setAdditionalPrograms(data.filter((x) => x.purchased === false));
        }
    };
    const getDataProducts = async () => {
        const url = `Dashboard/GetOolaProducts/2`;
        const { error, data } = await requestAxios({
            url: url,
            token
        });
        if (error) {
            console.log(error);
        } else {
            setAdditionalProducts(data);
        }
    };
    return (
        <div className="Sales__container">
            <section className="ELearning__HigerLevelMenu">
                <button
                    type="button"
                    className={
                        saleType === 1 ? 'ModuleTypeActive ' : 'btnModuleType'
                    }
                    onClick={() => {
                        setSaleType(1);
                    }}
                >
                    RECOMMENDED PROGRAMS
                </button>
                <button
                    type="button"
                    className={
                        saleType === 2 ? 'ModuleTypeActive ' : 'btnModuleType'
                    }
                    onClick={() => {
                        setSaleType(2);
                    }}
                >
                    RECOMMENDED PRODUCTS
                </button>
            </section>
            <CarruselProduct
                items={saleType == 1 ? additionalPrograms : additionalProducts}
                saleType={saleType}
            />
        </div>
    );
};
export default Sales;
