import React, { useState, useContext, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './ModalVideo.css';

const ModalVideo = ({ show, setShow, url, title, description }) => {
    const [canClose, setCanClose] = useState(false);
    const [muted, setMuted] = useState(true);

    return (
        <Modal
            className="ModalAssesmentVideo"
            dialogClassName="ModalAssesmentVideoDialog"
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Header>
                <button
                    onClick={() => {
                        if (canClose) setShow(false);
                    }}
                >
                    X
                </button>
            </Modal.Header>
            <Modal.Body>
                <h1>{title}</h1>
                <h2>{description}</h2>
                <hr />
                <ReactPlayer
                    url={url}
                    className="video__player"
                    width="100%"
                    height="50vh"
                    controls={true}
                    onPlay={() => setCanClose(true)}
                    // playing={true}
                    // muted={muted}
                />
            </Modal.Body>
        </Modal>
    );
};

export default ModalVideo;
