import React from 'react';
import './Header.css';

const headerSetGoals = ({
    data,
    AllState,
    changeData,
    saveRecomendedGoals,
}) => (
        <div className="setGoalsNav__container">
            {data.map((item, index) => (
                <button
                    key={item.name}
                    className={`subtitle-1 setGoalsNav__tab ${
                        (AllState.CurrentF === index && 'selected__zoom') ||
                        (AllState.CurrentF < index &&
                            AllState.LastReachedF < index &&
                            'tab__grey')
                    }`}
                    onClick={async () => {
                        if (AllState.CurrentF !== (AllState.LastReachedF)) {
                            let result = true;
                            result = await saveRecomendedGoals();
                            if (!result) {
                               return false;
                            }
                        }
                        changeData({
                            ...AllState,
                            CurrentF: index,
                        });
                        
                    }}
                    type="button"
                    style={{ backgroundColor: item.tabColor }}
                >
                    <span>{item.name}</span>
                </button>
            ))}
        </div>
    );
 

export default headerSetGoals;
