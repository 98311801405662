import React, { useContext } from 'react';
import axios from 'axios';
import configData from '../../../config.json';
import AppContext from '../../../Context/AppContext';
import './ListFs.css';

function statIL(Actindex, i, obj) {
    // AD=Actual Done
    // AP=Actual In Progress
    // D=Done
    // P=Progress
    let status = '';
    if (Actindex === i) {
        const objcurr = obj[i].questions.find(
            (x) => x.score === 0 || x.score == null || x.score === undefined
        );
        if (objcurr == null || objcurr === undefined) {
            status = 'AD';
        } else {
            status = 'AP';
        }
    } else if (i < Actindex) {
        status = 'D';
    } else {
        const objcurr = obj[i].questions.find(
            (x) => x.score === 0 || x.score == null
        );
        if (objcurr == null) {
            status = 'D';
        } else {
            status = 'P';
        }
    }
    return status;
}
function localSlice(rotate, color) {
    return (
        <g transform={`rotate(${rotate},220,220)`}>
            <g mask="url(#wedge-mask)">
                <circle
                    className="Slice"
                    cx="220"
                    cy="220"
                    r="100"
                    fill={color}
                    style={{ transform: 'scale(1)' }}
                >
                    {' '}
                </circle>
            </g>
        </g>
    );
}

function getILStyle(state, item, status, wheelRef) {
    return (
        <svg
            // ref={wheelRef}
            className="ChartPieChunk"
            // width="440px"
            // height="440px"
            viewBox="70 70 300 300"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: 'rotate(-51.42deg) scale(.8)',
            }}
        >
            <defs>
                <g id="overlay">
                    <rect x="219" y="100" height="50" width="15" fill="#FFF" />
                </g>
            </defs>
            <mask id="wedge-mask" fill="white">
                <path
                    transform="translate(20, 20)"
                    d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                    fillRule="nonzero"
                />
            </mask>
            <circle cx="220" cy="220" r="101" fill="#8E8E8E" strokeWidth="1" />
            {status === 'AP' ? (
                <circle
                    cx="220"
                    cy="220"
                    r="101"
                    fill="#8E8E8E"
                    strokeWidth="1"
                />
            ) : (
                ''
            )}

            {(item.id === 1 && status !== 'AP') || item.id > 1
                ? localSlice('51.42', '#15C285')
                : ''}
            {(item.id === 2 && status !== 'AP') || item.id > 2
                ? localSlice('102.84', '#ABD86D')
                : ''}
            {(item.id === 3 && status !== 'AP') || item.id > 3
                ? localSlice('154.26', '#FBBF53')
                : ''}
            {(item.id === 4 && status !== 'AP') || item.id > 4
                ? localSlice('205.68', '#F26636')
                : ''}
            {(item.id === 5 && status !== 'AP') || item.id > 5
                ? localSlice('257.1', '#EC0F47')
                : ''}
            {(item.id === 6 && status !== 'AP') || item.id > 6
                ? localSlice('308.52', '#700460')
                : ''}
            {(item.id === 7 && status !== 'AP') || item.id > 7
                ? localSlice('359.94', '#022B7A')
                : ''}

            <use xlinkHref="#overlay" transform="rotate(25.71, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(77.13, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(128.55, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(179.97, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(231.39, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(282.81, 220, 220)" />
            <use xlinkHref="#overlay" transform="rotate(334.23, 220, 220)" />

            <circle cx="220" cy="220" r="80" fill="#FFF" strokeWidth="1" />
        </svg>
    );
}

const ListFs = (props) => {
    const { checkUserSession, updateUserStepId, changeLoading } =
        useContext(AppContext);
    const currUser = checkUserSession();

    function saveQuestionF() {
        changeLoading(true);

        const fullState = { ...props.AllState };
        const UserState = checkUserSession();

        const PostObj = {
            userId: currUser.userId,
            assessmentId: UserState.assessmentId,
            AssessmentTypeId:
                currUser.userStepId === 6
                    ? 3
                    : currUser.userStepId === 5
                        ? 2
                        : 1,
            scores: fullState.Fs[fullState.index].questions.map((item) => ({
                questionId: item.id,
                score: item.score,
            })),
        };
        const url = `${configData.SERVER_URL}Scores/ScorePartialSave`;
        return new Promise((resolve, reject) => {
            axios
                .post(url, PostObj, {
                    headers: {
                        Authorization: `Bearer ${currUser.token}`,
                    },
                })
                .then(() => {
                    updateUserStepId();

                    fullState.Fs[fullState.index].questions.forEach((itemx) => {
                        itemx.edited = null;
                    });
                    props.mChange(fullState);
                    changeLoading(false);

                    console.log(`Step 1 F${fullState.index + 1} Saved`);
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error);
                    changeLoading(false);
                    resolve(false);
                });
        });
    }
    async function changeIndex(index) {
       
        const result = await saveQuestionF(props.AllState);
        if (result) {
            props.AllState.index = index;
            props.AllState.currentQuestion = 0;
            props.mChange(props.AllState);
        }
    }

    const opacityElement = (i) => {
        let value = '';
        if (props.AllState.lastF >= i + 1) {
            value = '1';
        } else if (
            statIL(props.AllState.index, i, props.AllState.Fs) !== 'AP' &&
            statIL(props.AllState.index, i, props.AllState.Fs) !== 'AD'
        ) {
            value = '0.4';
        } else {
            value = '1';
        }
        return value;
    };

    const pointerElement = (i) => {
        let value = '';
        if (props.AllState.lastF >= i + 1) value = '';
        else if (statIL(props.AllState.index, i, props.AllState.Fs) === 'P')
            value = 'none';
        else value = '';
        return value;
    };

    // const wheelRef = useRef(null);
    // const scrollAuto = () => {
    //     wheelRef.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'start',
    //     });
    // };

    return props.AllState.Fs.map((item, i) => (
        <li
            // ref={i === 1 && i}
            key={i}
            className="element__list"
            xs={12}
            data-key={i}
            onClick={() => changeIndex(i)}
            // onTouchEndCapture={props.handleChange}
            style={{
                cursor: 'pointer',
                color: item.color,
                pointerEvents: pointerElement(i),
                opacity: opacityElement(i),
            }}
        >
            <div className="chartPieChunk__container">
                {(statIL(props.AllState.index, i, props.AllState.Fs) === 'AP' ||
                    statIL(props.AllState.index, i, props.AllState.Fs) ===
                        'AD') &&
                    getILStyle(
                        props.AllState,
                        item,
                        statIL(props.AllState.index, i, props.AllState.Fs)
                        // wheelRef
                    )}
            </div>

            {/* {i === props.AllState.index &&
                refs[i].scrollIntoView({ behavior: 'smooth' })} */}

            <div
                className={`listTitle__container ${
                    i === props.AllState.index &&
                    `borderBottom${props.AllState.index + 1}`
                }`}
            >
                <h3>{item.name.toUpperCase()}</h3>
            </div>
        </li>
    ));
};

export default ListFs;
