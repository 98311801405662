import React, { useState, useContext } from 'react';
import axios from 'axios';
import configData from '../../../config.json';
import Wheel from './Wheel';
import SelectSevenSection from './SelectSevenSection';
import ModalSevenConfirm from './TemplateGoals/ModalSevenConfirm';
import './OolaSevenSection.css';
import AppContext from '../../../Context/AppContext';

const OolaSevenSection = props => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const currUser = checkUserSession();

    const AllProps = { ...props.AllState };
    AllProps.CurrentF = 0;

    const [showSevenConfirm, setShowSevenConfirm] = useState(false);
    const [confirmObj, setConfirmObj] = useState({
        ModalConfirmTitle: 'You have selected your Top 7. Ready to continue?',
        ModalConfirmDesc: 'Before jump to the next step text...',
    });

    function confirmGoals() {
        changeLoading(true);
        const PostObj = {
            Goals: props.AllState.SelectedGoalsSeven.map(item => ({
                GoalId: item.GoalId,
                SevenGoalOrder: 0,
            })),
        };

        const url = `${configData.SERVER_URL}Goals/SetSevenGoalsOrder`;

        axios
            .put(url, PostObj, {
                headers: {
                    Authorization: `Bearer ${currUser.token}`,
                },
            })
            .then(result => {
                changeLoading(false);
                setShowSevenConfirm(false);
                if (currUser.userStepId != 7) props.handleChangeHeader(1);
                props.updateIndexStep2(1);
            })
            .catch(error => {
                console.log(error);
                changeLoading(false);
            });
    }

    const newObject = {};
    if (typeof AllProps.SelectedGoalsGroup !== 'undefined') {
    } else {
        AllProps.SelectedGoals.forEach(x => {
            if (!newObject.hasOwnProperty(x.areaId)) {
                newObject[x.areaId] = {
                    Fs: AllProps.Fs[x.areaId],
                    selected: [],
                };
            }
            newObject[x.areaId].selected.push({
                areaId: x.areaId,
                date: x.date,
                recomendationIndex: x.recomendationIndex,
                text: x.text,
                selected: !!x.selected,
                Id: x.Id,
                GoalId: x.GoalId,
            });
        });

        props.AllState.SelectedGoalsGroup = newObject;

        props.changeData(props.AllState);
    }

    const obj = (
        <section className="selectGoalTop7__container">
            <div className="selectGoalTop7__wrapper">
                <div className="selectGoalTop7__wrapperWheel">
                    <Wheel
                        AllState={props.AllState}
                        hideNav={false}
                        allColors
                    />
                </div>
                <div>
                    <SelectSevenSection
                        updateIndexStep2={props.updateIndexStep2}
                        forceUpdate={props.forceUpdate}
                        AllState={props.AllState}
                        changeData={props.changeData}
                        hideNav={false}
                        confirmGoals={confirmGoals}
                        setShowSevenConfirm={setShowSevenConfirm}
                    />
                    <div style={{ flex: 1, display: 'flex', width: '80%' }}>
                        <div
                            className="btnNextStep2"
                            aria-hidden="true"
                            style={{
                                color: '#D86B59',
                                marginLeft: 'auto',
                                pointerEvents:
                                    props.AllState.SelectedGoalsSeven.length ===
                                    7
                                        ? ''
                                        : 'none',
                            }}
                            onClick={() => confirmGoals()}
                        >
                            NEXT {'>'}
                        </div>
                    </div>
                </div>
            </div>
            <ModalSevenConfirm
                confirmGoals={confirmGoals}
                show={showSevenConfirm}
                setShowSevenConfirm={setShowSevenConfirm}
                ModalConfirmTitle={confirmObj.ModalConfirmTitle}
                ModalConfirmDesc={confirmObj.ModalConfirmDesc}
            />
        </section>
    );

    return obj;
};

export default OolaSevenSection;
