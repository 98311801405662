import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import UpsWarningBackground from '../../../../Assets/img/Dashboard/UpsWarningBackground.png';
import UpsWarningBackgroundMobile from '../../../../Assets/img/Dashboard/UpsWarningBackgroundMobile.png';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import './ModalWarningActionSteps.css';

const ModalWarningActionSteps = ({
    show,
    setShow,
    phaseNumber,
    courseId,
    getCurrentDailyActions,
    setCurrentTrackerTab,
    setRefreshCourses,
}) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const ResetActions = async (type) => {
        changeLoading(true);
        const { userId, token } = checkUserSession();
        const url = `AdditionalCourse/ReactivateActionSteps/${userId}/${courseId}/${type}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
            method: 'put',
        });
        if (error == '') {
            getCurrentDailyActions();
            setCurrentTrackerTab(1);
            setRefreshCourses(0);
            setShow(false);
            setTimeout(() => {
                setRefreshCourses(1);
                changeLoading(false);
            }, 500);
        }
    };
    return (
        <Modal
            centered
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="WarningAdditionalCourse__DialogContainer"
        >
            <Modal.Body className="WarningAdditionalCourse__Headercontainer">
                <div className="WarningAdditionalCourse__container">
                    <button onClick={() => setShow(false)}>X </button>
                    <div className="WarningAdditionalCourseInfo__container">
                        <div
                            className="WarningAdditionalCourseInfoImage__container Mobile"
                            style={{
                                backgroundImage: `url(${UpsWarningBackgroundMobile})`,
                            }}
                        />
                        <div className="WarningAdditionalCourseInfoText__container">
                            <h1>OH NO!</h1>
                            <h2>
                                Looks like you didn't complete yesterday's
                                actions step. this means you either did the work
                                and forgot mark it, or you missed a day. If you
                                really completed your actions step, the keep
                                going. If you didn't, the own it and start this
                                phase over.
                            </h2>
                            <Modal.Footer className="WarningAdditionalCourse__HeadeFootercontainer">
                                <button
                                    className="button-full-mid"
                                    onClick={() => ResetActions(true)}
                                >
                                    START OVER
                                </button>
                                <br />
                                <button
                                    className="button-full-mid-black"
                                    onClick={() => ResetActions(false)}
                                >
                                    KEEP GOING
                                </button>
                            </Modal.Footer>
                        </div>
                        <div
                            className="WarningAdditionalCourseInfoImage__container NoMobile"
                            style={{
                                backgroundImage: `url(${UpsWarningBackground})`,
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default ModalWarningActionSteps;
