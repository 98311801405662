import React, { useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import VideoSection from './LearnMore/VideoSection/VideoSection';
import FrequentQuestion from './LearnMore/FrequentQuestion/FrequentQuestion';
import Benefits from './LearnMore/Benefits/Benefits';
import FindBalanceSection from './LearnMore/FindBalanceSection/FindBalanceSection';
import WhatsIncludedSection from './LearnMore/WhatsIncludedSection/WhatsIncludedSection';
import FooterSection from './LearnMore/FooterSection/FooterSection';
import TestimonialsSection from './LearnMore/TestimonialsSection/TestimonialsSection';
import LoginHeader from './Common/LoginHeader/LoginHeader';
import config from '../config.json';

import ChangingWorldSection from './LearnMore/ChangingWorldSection/ChangingWorldSection';

const App = () => {
    const { webAlias } = useParams();
    const match = useRouteMatch('/learnmore/faq');

    useEffect(() => {
        if (!match?.isExact) window.scrollTo(0, 0);
    }, []);

    function goExigo() {
        if (webAlias)
            window.location.href = `${config.enrollmentURL}/${webAlias}/enrollment`;
        else
            window.location.href = `${config.enrollmentURL}/corporphan/enrollment`;
    }

    return (
        <>
            <LoginHeader
                isDarkLogo={false}
                enrollButtonText="ENROLL NOW"
                goEnroll={goExigo}
                webAlias={webAlias}
            />
            <VideoSection goExigo={goExigo} />
            <FindBalanceSection goEnroll={goExigo} />
            <WhatsIncludedSection goEnroll={goExigo} />
            <TestimonialsSection optionApi={1} />
            <Benefits goEnroll={goExigo} />
            <FrequentQuestion />
            <ChangingWorldSection
                goEnroll={goExigo}
                enrollButtonText="ENROLL NOW"
            />

            <FooterSection />
        </>
    );
};

export default App;
