import React, { useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import Countries from 'countries-list';

import FooterSection from '../LearnMore/FooterSection/FooterSection';
import AppContext from '../../Context/AppContext';
import requestAxios from '../../util/requestAxios';
import './CoachDirectory.css';
import CoachInfoCard from '../Dashboard/MyOolaCoach/FindCoachModal/Components/CoachInfoCard/CoachInfoCard';
import CoachProfileFinal from '../DashboardCoach/CoachProfileFinal/CoachProfileFinal';
import LoginHeader from '../Common/LoginHeader/LoginHeader';

export default () => {
    const { changeLoading, setNewData, getData } = useContext(AppContext);
    const { ProfileCoach } = getData();

    const [searchValue, setSearchValue] = useState({
        name: null,
        country: 'US',
        zipCode: null,
    });
    const [errorZipCodeValue, setErrorZipCodeValue] = useState(null);
    const [searchResult, setSearchResult] = useState();
    const [selectedCoach, setSelectedCoach] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const findCoach = async () => {
        changeLoading(true);

        const url = `FindCoach/GetCoachBySearchTermZipCode?${
            searchValue.name ? `searchTerm=${searchValue.name}` : ''
        }${
            searchValue.zipCode
                ? `&zipcode=${searchValue.zipCode}&country=${searchValue.country}`
                : ''
        }
        `;
        const { error, data } = await requestAxios({
            url,
        });

        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            setSearchResult(data);
        }
    };

    function handleChangeLocation(event) {
        setSearchValue({
            ...searchValue,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <>
            <LoginHeader />
            <section className="coachDirectory_container">
                <div className="">
                    <h1 className="heading-2">
                        CERTIFIED <span>OOLA</span> LIFE <br /> COACH DIRECTORY
                    </h1>
                    <div className="coachDirectory-line" />
                    <p className="paragraph-3">
                        Search the directory now to find the Oola Life Coach
                        <br /> who can help you achieve the life of your dreams.
                    </p>
                </div>

                <Form className="coachDirectory-form">
                    <div className="findMyCoach-form">
                        <div className="findMyCoach-form-header">
                            <Form.Group controlId="name">
                                <Form.Label>Search by name:</Form.Label>
                                <Form.Control
                                    className="form-input-1 "
                                    type="text"
                                    name="name"
                                    placeholder="First or last name"
                                    onChange={handleChangeLocation}
                                />
                            </Form.Group>
                            <Form.Group controlId="zipCode">
                                <Form.Label>Search by location:</Form.Label>
                                <Form.Control
                                    className="form-input-1"
                                    type="text"
                                    name="zipCode"
                                    placeholder="Zip Code"
                                    onChange={handleChangeLocation}
                                    isInvalid={errorZipCodeValue !== null}
                                />
                                {errorZipCodeValue && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errorZipCodeValue}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group controlId="country" className="">
                                <Form.Control
                                    className="form-input-1"
                                    as="select"
                                    name="country"
                                    onChange={handleChangeLocation}
                                >
                                    {Object.entries(Countries.countries).map(
                                        (country) => (
                                            <option
                                                key={country[1].name}
                                                value={country[0]}
                                                selected={
                                                    country[0] ===
                                                    searchValue.country
                                                }
                                            >
                                                {country[1].name}
                                            </option>
                                        )
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                    <button
                        className="button-full-small"
                        type="button"
                        onClick={findCoach}
                        disabled={!searchValue.name && !searchValue.zipCode}
                    >
                        Search
                    </button>
                </Form>

                {searchResult && (
                    <section className="coachDirectory-cards_container">
                        {searchResult?.map((result) => (
                            <div key={result?.id}>
                                <CoachInfoCard
                                    data={result}
                                    onSelect={() => {
                                        setNewData({
                                            ProfileCoach: {
                                                modalDirectiryListingShow: true,
                                                selectedCoach: result.id,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </section>
                )}
                {searchResult?.length === 0 && (
                    <div style={{ marginBottom: '60px' }}>No coaches found</div>
                )}
            </section>

            {ProfileCoach?.selectedCoach && <CoachProfileFinal publicUser={true}/>}
            <FooterSection />
        </>
    );
};
