import React, { useState, useEffect, useContext } from 'react';
import imgSourceGreen from '../../../../Assets/img/ModuleVip/imgGreenGap.png';
import './DescriptonModuleVip.css';
import configData from '../../../../config.json';

const DescriptonModuleVip = ({moduleInfo}) => {
    const ModuleInfo = moduleInfo[1] != null ? moduleInfo[1] : {};
    function createMarkup(value) {
        return {
            __html: `${ModuleInfo[value]==null?"":ModuleInfo[value]}`,
        };
    }
    const DescriptonModuleVipR = (
        <section className="DescriptionModuleVip_container">
            <hr className="DescriptionModuleVip_line" />
            <div className="DescriptionModuleVip_TitleSection" dangerouslySetInnerHTML={createMarkup('htmlTitle')}/>
            <div className="DescriptionModuleVip_TitleSection" dangerouslySetInnerHTML={createMarkup('htmlIntro')}/>
               
            <div className="DescriptionModuleVip_imagenSection">
                <center className="DescriptionModuleVip_text" dangerouslySetInnerHTML={createMarkup('htmlContent')}/>
                   
                <div className="DescriptionModuleVip_imagen">
                    <img src={configData.SERVER_URL_IMG+ ModuleInfo.imageUrl} />
                </div>
            </div>
            <hr className="DescriptionModuleVip_line" />
        </section>
    );
    return DescriptonModuleVipR;
};

export default DescriptonModuleVip;
