/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Popover } from 'react-bootstrap';
import Countries from 'countries-list';
import ReactInputMask from 'react-input-mask';
import requestZipCode from '../../../../../util/requestZipCode';
import './CoachMyProfileEdit.css';

import calendlyImage from '../../../../../Assets/img/DashboardCoach/calendly.png';

const CoachMyProfileEdit = ({ profileInformation }) => {
    const [locationValue, setLocationValue] = useState({
        country: 'US',
        zipCode: null,
    });

    const [stateValue, setStateValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [errorZipCodeValue, setErrorZipCodeValue] = useState();
    const [profileData, setProfileData] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');

    const [showCalendlyLink, setShowCalendlyLink] = useState(false);

    useEffect(() => {
        setProfileData(profileInformation);
        setPhoneNumber(profileInformation?.phoneNumber);
    }, [profileInformation]);

    async function getUserLocation() {
        const url = `search?apikey=${process.env.REACT_APP_ZIPCODEBASE_API_KEY}&codes=${locationValue.zipCode}&country=${locationValue.country}`;
        const { error, data } = await requestZipCode({
            url,
        });
        if (error) {
            console.log(error);
            setErrorZipCodeValue('Please enter a valid Zip Code and Country');
        } else {
            if (Object.values(data.results).length <= 0) {
                setErrorZipCodeValue('Please enter a valid Zip Code');
                return;
            }
            setErrorZipCodeValue(null);
            setStateValue(Object.values(data.results)[0][0].state);
            setCityValue(Object.values(data.results)[0][0].city);
        }
    }

    function handleChangeLocation(event) {
        setLocationValue({
            ...locationValue,
            [event.target.name]: event.target.value,
        });
    }

    useEffect(() => {
        if (
            locationValue.zipCode !== null &&
            locationValue.zipCode.length >= 4
        ) {
            getUserLocation();
        }
    }, [locationValue]);

    return (
        <>
            <div className="myProfileEdit-title-container">
                <h3 className="heading-5">MY PROFILE</h3>
            </div>
            <div style={{ width: '100%' }}>
                <div className="myProfileEdit-form">
                    <Form.Group
                        controlId="firstName"
                        className="myProfileEdit-form-left"
                    >
                        <Form.Label>First name:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="text"
                            required
                            defaultValue={profileData?.firstName}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="lastName"
                        className="myProfileEdit-form-right"
                    >
                        <Form.Label>Last Name:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="text"
                            required
                            defaultValue={profileData?.lastName}
                        />
                    </Form.Group>
                </div>
                <div className="myProfileEdit-form">
                    <Form.Group
                        controlId="zipCode"
                        className="myProfileEdit-form-left"
                        style={{ position: 'relative' }}
                    >
                        <Form.Label>Zip Code:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="text"
                            name="zipCode"
                            required
                            onChange={handleChangeLocation}
                            isInvalid={
                                errorZipCodeValue !== null &&
                                !profileData?.zipCode
                            }
                            defaultValue={profileData?.zipCode}
                        />
                        {errorZipCodeValue && (
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errorZipCodeValue}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group
                        controlId="country"
                        className="myProfileEdit-form-right"
                    >
                        <Form.Label>Country:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="select"
                            name="country"
                            onChange={handleChangeLocation}
                            required
                        >
                            {Object.entries(Countries.countries).map(
                                (country) => (
                                    <option
                                        key={country[1].name}
                                        value={country[0]}
                                        selected={
                                            profileData?.country
                                                ? country[0] ===
                                                  profileData?.country
                                                : country[0] ===
                                                  locationValue.country
                                        }
                                    >
                                        {country[1].name}
                                    </option>
                                )
                            )}
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className="myProfileEdit-form">
                    <Form.Group
                        controlId="primaryLanguage"
                        className="myProfileEdit-form-right"
                    >
                        <Form.Label>Primary Language:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            as="select"
                            required
                        >
                            {Object.entries(Countries.languages).map(
                                (language) => (
                                    <option
                                        key={language[1].name}
                                        value={language[0]}
                                        selected={
                                            profileData?.primaryLanguage
                                                ? language[0] ===
                                                  profileData?.primaryLanguage
                                                : language[1].name === 'English'
                                        }
                                    >
                                        {language[1].name}
                                    </option>
                                )
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group
                        controlId="state"
                        className="myProfileEdit-form-right"
                    >
                        <Form.Label>State:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="text"
                            required
                            disabled
                            value={stateValue || profileData?.state}
                        />
                    </Form.Group>
                </div>
                <div className="myProfileEdit-form">
                    <Form.Group
                        controlId="city"
                        className="myProfileEdit-form-left"
                    >
                        <Form.Label>City:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="text"
                            required
                            disabled
                            value={cityValue || profileData?.city}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="emailAddress"
                        className="myProfileEdit-form-right"
                    >
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="email"
                            required
                            defaultValue={profileData?.emailAddress}
                        />
                    </Form.Group>
                </div>
                <div className="myProfileEdit-form">
                    <Form.Group
                        controlId="phoneNumber"
                        className="myProfile-form-left"
                    >
                        <Form.Label>Phone:</Form.Label>
                        <ReactInputMask
                            name="phoneNumber"
                            className="inputNumber form-input-1 form-control "
                            mask="999-999-9999"
                            value={phoneNumber}
                            onChange={(input) =>
                                setPhoneNumber(input.target.value)
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="websiteUrl"
                        className="myProfile-form-right"
                    >
                        <Form.Label>Website:</Form.Label>
                        <Form.Control
                            className="form-input-1"
                            type="url"
                            defaultValue={profileData?.websiteUrl}
                        />
                    </Form.Group>
                </div>
                <div className="myProfile-form">
                    <Form.Group
                        controlId="calendlyUrl"
                        className="myProfile-form-left"
                    >
                        <Form.Label>
                            Calendly link:{' '}
                            <button
                                className="button-no-styles"
                                type="button"
                                onClick={() =>
                                    setShowCalendlyLink(!showCalendlyLink)
                                }
                                onMouseOver={() => setShowCalendlyLink(true)}
                                onMouseOut={() => setShowCalendlyLink(false)}
                                style={{ position: 'relative' }}
                            >
                                <svg
                                    width="17px"
                                    height="17px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginBottom: '3px' }}
                                >
                                    <g clipPath="url(#clip0_429_11160)">
                                        <circle
                                            cx="12"
                                            cy="11.9999"
                                            r="9"
                                            stroke="#D86B59"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            x="12"
                                            y="8"
                                            width="0.01"
                                            height="0.01"
                                            stroke="#D86B59"
                                            strokeWidth="3.75"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12 12V16"
                                            stroke="#D86B59"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_429_11160">
                                            <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                {showCalendlyLink && (
                                    <div className="form-calendly-image">
                                        <img
                                            src={calendlyImage}
                                            alt="calendly tooltip"
                                        />
                                    </div>
                                )}
                            </button>
                        </Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                required
                                defaultValue={profileData?.calendlyUrl}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group
                        controlId="facebookUserName"
                        className="myProfile-form-right"
                    >
                        <Form.Label>Facebook:</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="form-input-autofill">
                                www.facebook.com/
                            </InputGroup.Text>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                defaultValue={profileData?.facebookUserName}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="myProfile-form">
                    <Form.Group
                        controlId="instagramUserName"
                        className="myProfile-form-left"
                    >
                        <Form.Label>Instagram:</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="form-input-autofill">
                                www.instagram.com/
                            </InputGroup.Text>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                defaultValue={profileData?.instagramUserName}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group
                        controlId="twitterUserName"
                        className="myProfile-form-right"
                    >
                        <Form.Label>Twitter:</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="form-input-autofill">
                                www.twitter.com/
                            </InputGroup.Text>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                defaultValue={profileData?.twitterUserName}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="myProfile-form">
                    <Form.Group
                        controlId="linkedInUserName"
                        className="myProfile-form-left"
                    >
                        <Form.Label>Linkedin:</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="form-input-autofill">
                                www.linkedin.com/
                            </InputGroup.Text>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                defaultValue={profileData?.linkedInUserName}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group
                        controlId="tikTokUserName"
                        className="myProfile-form-right"
                    >
                        <Form.Label>TikTok:</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="form-input-autofill">
                                www.tiktok.com/
                            </InputGroup.Text>
                            <Form.Control
                                className="form-input-1"
                                type="text"
                                defaultValue={profileData?.tikTokUserName}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

export default CoachMyProfileEdit;
