import React, { useState } from 'react';
import SelectedGoal from './TemplateGoals/SelectedGoal';
import ModalRecomendation from './TemplateGoals/ModalRecomendation';
import ModalError from './TemplateGoals/ModalError';
import './RecomendationSection.css';

const RecomendationSection = (props) => {
    const currItem = props.AllState.Fs[props.AllState.CurrentF];
    const currStep = props.AllState.SelectedGoals.filter(
        (x) =>
            x.areaId === props.AllState.CurrentF &&
            String(x.text).trimStart().trimEnd() !== ''
    );
    const [showError, setShowError] = useState(false);
    const [errorObj, setErrorObj] = useState({
        ModalErrorTitle: 'You are almost there',
        ModalErrorDesc: 'You need to add 3 goals to continue',
    });
    const [show, setShow] = useState(false);
    function showModal(recomendationIndex) {
        const AllProps = { ...props.AllState };
        const currGoal = props.AllState.SelectedGoals.find(
            (x) =>
                x.areaId === props.AllState.CurrentF &&
                x.recomendationIndex === recomendationIndex - 1
        );
        if (currGoal == null) {
            AllProps.recomendationIndex = recomendationIndex;
            AllProps.changeData(AllProps);
            setShow(true);
        }
    }
    async function nextF() {
        if (currStep.length === 3) {
            const AllProps = { ...props.AllState };
            const result = await props.saveRecomendedGoals();

            if (result) {
                if (AllProps.CurrentF === 6) {
                    AllProps.CurrentStep2 += 1;
                    AllProps.changeData(AllProps);
                } else {
                    AllProps.CurrentF += 1;
                    AllProps.LastReachedF =
                        AllProps.CurrentF > AllProps.LastReachedF
                            ? AllProps.CurrentF
                            : AllProps.LastReachedF;
                    AllProps.changeData(AllProps);
                }
            }
        } else {
            setErrorObj({
                ModalErrorTitle: 'You are almost there',
                ModalErrorDesc: 'You need to add 3 goals to continue',
            });
            setShowError(1);
        }
    }
    const nextActive =
        props.AllState.SelectedGoals.filter(
            (x) =>
                x.areaId === props.AllState.CurrentF &&
                x.text !== '' &&
                x.date != null
        ).length === 3
            ? 1
            : 0;

    return (
        <div className="recommendation__container">
            <div className="recommendation__titles">
                <div className="titles__texts">
                    <h3 className="heading-3">
                        Establish 3 Goals:{" "}
                        <span style={{ color: currItem.color }}>
                            {currItem.name}
                        </span>
                    </h3>
                    <p className="paragraph-3">
                        Define 3 goals that you would like to work on to better
                        your {currItem.name}.
                    </p>

                    <button
                        className="subtitle-1"
                        onClick={() => showModal(-1)}
                        type="button"
                    >
                        CLICK HERE IF YOU WANT TO SEE RECOMMENDED GOALS
                    </button>
                </div>
                <div className="titles__counter">
                    <h5 className="heading-5">GOALS {currStep.length}/3</h5>
                </div>
            </div>

            <div className="recommendations__cards">
                <SelectedGoal
                    GoalIndex={0}
                    AllState={props.AllState}
                    mobile={props.mobile}
                    showModalEvent={() => showModal(1)}
                />
                <SelectedGoal
                    GoalIndex={1}
                    AllState={props.AllState}
                    mobile={props.mobile}
                    showModalEvent={() => showModal(2)}
                />
                <SelectedGoal
                    GoalIndex={2}
                    AllState={props.AllState}
                    mobile={props.mobile}
                    showModalEvent={() => showModal(3)}
                />
                <div className="cards__button">
                    <button
                        className="heading-5"
                        style={{
                            color: nextActive === 1 && '#d86b59',
                        }}
                        onClick={() => nextF()}
                        type="button"
                    >
                        NEXT {'>'}
                    </button>
                </div>
            </div>

            <ModalRecomendation
                show={show}
                setShow={setShow}
                currItem={currItem}
                AllState={props.AllState}
                setShowError={setShowError}
                setErrorObj={setErrorObj}
            />
            <ModalError
                show={showError}
                setShowError={setShowError}
                ModalErrorTitle={errorObj.ModalErrorTitle}
                ModalErrorDesc={errorObj.ModalErrorDesc}
            />
        </div>
    );
};

export default RecomendationSection;
