import React, { useContext,useState } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useWindowSize } from '@react-hook/window-size';
import Header from './Dashboard/Header/HeaderFreeTest';
import FreeStep from './FreeStep/FreeStep';
import { Link } from 'react-router-dom';
import AppContext from '../Context/AppContext';

const FreeTest = () => {
    const { checkFreeUserSession } = useContext(AppContext);
    const FreeTestData = checkFreeUserSession();
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const [, setWindowWidth] = useState(window.innerWidth);
    onWindowResize((event: React.SyntheticEvent) => {
        setMobile(window.innerWidth <= 768);
        setWindowWidth(window.innerWidth);
    });

    return (
        <div className="steps__container">
            <Link to={'/'}>
                <div
                    className="Oola-Logo Black-Logo "
                    style={{ cursor: 'pointer' }}
                />
            </Link>
            {FreeTestData?.index < 7 && <Header />}
            <FreeStep mobile={mobile}/>
        </div>
    );
};

export default FreeTest;
