import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import './Collapsable.css';

const NewCollapsable = ({ Description, Title }) => {
    const [open, setOpen] = useState(false);
    return (
        <article className="newCollapse__container">
            <button
                onClick={() => setOpen(!open)}
                type="button"
                className={`iconColapes${open ? 'open' : 'close'}`}
            >
                {Title}
                <span>{open === true ? '-' : '+'}</span>
            </button>
            <Collapse in={open} className="newCollapse__collapse">
                <div>
                    <Description />
                </div>
            </Collapse>
        </article>
    );
};

export default NewCollapsable;
