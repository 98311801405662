import React from 'react';
import { useHistory } from 'react-router-dom';
import configData from '../../../../../../../config.json';
import LockSVG from '../../../../../../../Assets/img/LockIconFullWhite.svg';

const RecommendedCourses = ({ item }) => {
    const history = useHistory();

    function goToNewCourse(item) {
        if (item.externalLink == null) history.push(`/moduleVip?id=${item.id}`);
        else window.location.href = item.externalLink;
    }
    return (
        <section
            className="NewModulesCard__Container"
            aria-hidden="true"
            onClick={() => item.isEnabled && goToNewCourse(item)}
        >
            <div
                className="NewModulesCard__image CarruselProducts"
            >
                <img
                    alt="imgProduct"
                    className='CarruselCourseImg'
                    src={`${configData.SERVER_URL_IMG}${item.imageUrl}`}
                />
                {!item.isEnabled && (
                    <div className="imgLockCourse__container">
                        <img
                            className="imgLockCourse"
                            alt="imgLockCourse"
                            src={LockSVG}
                        />
                    </div>
                )}
            </div>
            {/* <div className="NewModulesCard__textContainer">
                <div className="NewModulesCard__textLeft">
                    {item.title}
                    <br />
                    <section className="NewModulesCard__textSubtitle">
                        {item.subTitle}
                    </section>
                </div>
                <div className="NewModulesCard__textRigh">
                    ${item.price}
                    <br />
                    <p className="NewModulesCard__textSubtitle">
                        {item.creator}
                    </p>
                </div>
            </div> */}
        </section>
    );
};

export default RecommendedCourses;
