import React from 'react';
import './AddGoal.css';

const AddGoal = (props) => {
    function clickButtonAddGoal() {
        if (props.CurrentTab === 'inProgress') {
            props.selectTab('pending', 'ADDITIONAL GOALS');
        } else {
            const addGoal = { ...props.addGoal };
            addGoal.areaId = props.areaId;
            addGoal.color = props.color;
            addGoal.edit = 0;
            addGoal.text = '';
            props.setGoal(addGoal);
            props.clickButtonAddGoal();
        }
    }

    return (
        <button
            className="addGoal"
            onClick={() => clickButtonAddGoal()}
            type="button"
        >
            <div className="addGoal__container">
                <h5 className="heading-3">{props.GoalIndex}</h5>
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <g>
                            <path
                                fill="#2c414c"
                                d="M16.184-6.836v-1.955h-6.008v-6.049H8.165v6.049H2.184v1.955h5.981v6h2.012v-6z"
                                transform="translate(-721 -559) translate(723.816 578.84)"
                            />
                            <g
                                fill="none"
                                stroke="#2c414c"
                                strokeWidth="2px"
                                transform="translate(-721 -559) translate(721 559)"
                            >
                                <circle cx="12" cy="12" r="12" stroke="none" />
                                <circle cx="12" cy="12" r="11" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </button>
    );
};
export default AddGoal;
