import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, CloseButton } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider, Typography, Tab, Tabs } from '@material-ui/core';

import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import './CertificationQuiz.css';
import CertificationQuizItem from './components/CertificationQuizItem/CertificationQuizItem';

const CertificationQuiz = ({ setShowCretificationQuiz }) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const history = useHistory();

    const [quizQuestions, setQuizQuestions] = useState(null);
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [quizResult, setQuizResult] = useState(null);

    const restartQuiz = () => {
        changeLoading(true);
        setTimeout(() => {
            setQuestionAnswers([]);
            setQuestionIndex(0);
            setQuizResult(null);
            changeLoading(false);
        }, 1000);
    };

    const reviewModule = () => {
        changeLoading(true);
        setTimeout(() => {
            setShowCretificationQuiz(false);
            restartQuiz();
            window.scrollTo(0, 0);
            changeLoading(false);
        }, 1000);
    };

    const nextModule = (value) => {
        changeLoading(true);
        setTimeout(() => {
            localStorage.setItem('ModuleId', value);
            localStorage.setItem('isCertificationModule', 1);
            history.go(0);
            window.scrollTo(0, 0);
        }, 2000);
    };

    const getQuiz = async () => {
        changeLoading(true);

        if (localStorage.getItem('ModuleId')) {
            const moduleId = localStorage.getItem('ModuleId');
            const userInfo = checkUserSession();
            const { error, data } = await requestAxios({
                url: `AdditionalCourse/GetQuiz/${moduleId}`,
                token: userInfo.token,
                method: 'get',
            });

            if (data) {
                setQuizQuestions(data);
                changeLoading(false);
            } else {
                window.alert(
                    'Sorry we had an error at obtaining the quiz, please try to reload the page'
                );
            }
        }
    };

    useEffect(() => {
        if (!quizQuestions) {
            getQuiz();
        }
    }, []);
    useEffect(() => {
        console.log('QuestinoAnswers: ', questionAnswers);
    }, [questionAnswers]);

    const saveCertificationQuiz = async () => {
        if (questionAnswers.length !== quizQuestions?.questions?.length) {
            window.alert('Please Answer all the questions');
            return;
        }
        changeLoading(true);
        const userInfo = checkUserSession();
        const postObj = {
            quizId: quizQuestions.quizId,
            userId: userInfo.userId,
            Answers: questionAnswers,
        };

        const { error, data } = await requestAxios({
            url: `AdditionalCourse/SaveQuizResults`,
            token: userInfo.token,
            method: 'post',
            data: postObj,
        });

        if (error === '') {
            setQuizResult(data);
            changeLoading(false);
            console.log('Quiz result', data);
        } else {
            changeLoading(false);
            window.alert('Sorry, we had an error, please try again.');
        }
    };

    function setAnswer(QuestionId, SelectedOptionId) {
        const questionIndexAnswer = questionAnswers.findIndex(
            (x) => x.QuestionId === QuestionId
        );

        const newquestionAswer = questionAnswers;
        if (questionIndexAnswer === -1) {
            newquestionAswer.push({
                QuestionId,
                SelectedOptionId,
            });
        } else {
            newquestionAswer[questionIndexAnswer].SelectedOptionId =
                SelectedOptionId;
        }
        setQuestionAnswers(newquestionAswer);
        console.log('QuestionAnswers', questionAnswers);
    }

    const [OptionSelected, ChangeOptionSelected] = useState([]);
    const marksMyPersonalizedPath = () => {
        const marks = [];
        quizQuestions?.questions?.forEach((element, index) => {
            const qLength = quizQuestions?.questions?.length;
            const item = {
                value: index + 1,
                label: (
                    <div
                        data-index={index}
                        className={
                            OptionSelected[qLength - index] != null ||
                            qLength - index - 1 <= OptionSelected.length
                                ? 'lblEnableAssesment'
                                : ''
                        }
                    >
                        Q
                        {qLength - index < 10
                            ? `0${qLength - index}`
                            : qLength - index}
                    </div>
                ),
            };
            marks.push(item);
        });

        return marks;
    };

    const PrettoSlider = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'vertical',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'vertical',
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleChange = (event, newValue) => {
        setQuestionIndex(newValue);
    };

    return (
        <Modal
            show
            // show={showModalQuestion}
            dialogClassName="certificationQuiz-90w"
        >
            <Modal.Header>
                <CloseButton onClick={reviewModule} />
            </Modal.Header>
            <Modal.Body>
                <div className="certificationQuiz_container">
                    {quizResult ? (
                        <>
                            <h1 className="certificationQuiz-title">
                                {quizResult.title} <br /> {quizResult.subTitle}
                            </h1>
                            <h2 className="certificationQuiz-score">
                                {quizResult.score}%
                            </h2>
                            <p className="certificationQuiz-description">
                                {quizResult.message}
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {quizResult.buttonOne.displayButton && (
                                    // RetakeQuiz
                                    <button
                                        className="button-full-mid"
                                        onClick={() => restartQuiz()}
                                        type="button"
                                        style={{ marginRight: '20px' }}
                                    >
                                        {quizResult.buttonOne.text}
                                    </button>
                                )}
                                {quizResult.buttonTwo.displayButton && (
                                    // ReviewModule
                                    <button
                                        className="button-full-mid"
                                        onClick={() => reviewModule()}
                                        type="button"
                                        style={{ marginLeft: '20px' }}
                                    >
                                        {quizResult.buttonTwo.text}
                                    </button>
                                )}
                                {quizResult.buttonThree.displayButton && (
                                    // NextModule
                                    <button
                                        className="button-full-mid"
                                        onClick={() =>
                                            nextModule(
                                                quizResult.buttonThree.value
                                            )
                                        }
                                        type="button"
                                    >
                                        {quizResult.buttonThree.text}
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="certificationQuiz_title">
                                {quizQuestions?.title}
                            </h1>
                            <section className="certificationQuiz_assessment">
                                {!quizQuestions?.displayQuestionNumbers && (
                                    <div className="certificationQuiz_assessment-qNumbers">
                                        <h5>
                                            {questionIndex + 1}/
                                            {quizQuestions?.questions?.length}
                                        </h5>
                                    </div>
                                )}
                                {quizQuestions?.displayQuestionNumbers &&
                                    questionIndex < 14 && (
                                        <div className="certificationQuiz_assessment-slider">
                                            <>
                                                <Typography
                                                    id="vertical-slider"
                                                    gutterBottom
                                                />
                                                <div>
                                                    <PrettoSlider
                                                        orientation="vertical"
                                                        aria-labelledby="vertical-slider"
                                                        marks={marksMyPersonalizedPath()}
                                                        step={1}
                                                        min={1}
                                                        max={
                                                            marksMyPersonalizedPath()
                                                                .length
                                                        }
                                                        value={[
                                                            marksMyPersonalizedPath()
                                                                .length,
                                                            marksMyPersonalizedPath()
                                                                .length -
                                                                questionIndex,
                                                        ]}
                                                        // onChange={(input) =>
                                                        //     selectQuestion(input)
                                                        // }
                                                        style={{
                                                            height: '50vh',
                                                            width: '1rem',
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        </div>
                                    )}
                                {questionIndex < 14 && (
                                    <div className="visibleMobileQuestion">
                                        <div>
                                            <>
                                                <Typography
                                                    id="horizontal-slider"
                                                    gutterBottom
                                                    style={{
                                                        height: '10px',
                                                        width:
                                                            window.innerWidth *
                                                            0.8,
                                                        marginLeft:
                                                            window.innerWidth *
                                                            0.05,
                                                    }}
                                                />
                                                <div className="TabSlider___container MindBodyWellnessAssessmentQuestion">
                                                    <Tabs
                                                        value={questionIndex}
                                                        onChange={handleChange}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"
                                                    >
                                                        {quizQuestions?.questions?.map(
                                                            (item, i) => {
                                                                const newIndex =
                                                                    i + 1;
                                                                return (
                                                                    <Tab
                                                                        key={`tab${newIndex}`}
                                                                        className={
                                                                            item.score ||
                                                                            i ===
                                                                                0
                                                                                ? 'questionTitleMobile__text'
                                                                                : 'questionTitleMobileDisable__text'
                                                                        }
                                                                        label={`Q${
                                                                            newIndex <
                                                                            10
                                                                                ? `0${newIndex}`
                                                                                : newIndex
                                                                        }`}
                                                                        // disabled={
                                                                        //     newIndex.score
                                                                        // }
                                                                        {...a11yProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </Tabs>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                )}
                                <div className="certificationQuiz_assessment-questions ">
                                    {quizQuestions != null && (
                                        <SwipeableViews
                                            index={questionIndex}
                                            disabled
                                            style={{ width: '100%' }}
                                        >
                                            {quizQuestions?.questions?.map(
                                                (item, i) => (
                                                    <CertificationQuizItem
                                                        key={item.id}
                                                        item={item}
                                                        i={i}
                                                        setAnswer={setAnswer}
                                                        questionAnswers={
                                                            questionAnswers
                                                        }
                                                    />
                                                )
                                            )}
                                        </SwipeableViews>
                                    )}
                                    {quizQuestions != null && (
                                        <div className="certificationQuiz_assessment-buttons">
                                            {questionIndex >= 1 ? (
                                                <button
                                                    className="button-empty-small"
                                                    style={{
                                                        borderRadius: '5px',
                                                    }}
                                                    type="button"
                                                    onClick={() =>
                                                        setQuestionIndex(
                                                            questionIndex - 1
                                                        )
                                                    }
                                                >
                                                    {'< PREVIOUS'}
                                                </button>
                                            ) : (
                                                <div />
                                            )}

                                            <button
                                                className={`button-empty-small ${
                                                    questionAnswers[
                                                        questionIndex
                                                    ] == null && 'disabled'
                                                }`}
                                                style={{
                                                    borderRadius: '5px',
                                                    color: '#D86B59',
                                                }}
                                                type="submit"
                                                onClick={() => {
                                                    if (
                                                        quizQuestions?.questions
                                                            ?.length -
                                                            1 ===
                                                        questionIndex
                                                    ) {
                                                        saveCertificationQuiz();
                                                    } else {
                                                        setQuestionIndex(
                                                            questionIndex + 1
                                                        );
                                                    }
                                                }}
                                            >
                                                {quizQuestions?.questions
                                                    ?.length -
                                                    1 ===
                                                questionIndex
                                                    ? 'SAVE'
                                                    : 'NEXT >'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CertificationQuiz;
