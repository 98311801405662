import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../../../../Context/AppContext';
import CoachProfileFinal from '../../../../../DashboardCoach/CoachProfileFinal/CoachProfileFinal';
import requestAxios from '../../../../../../util/requestAxios';

export default () => {
    const { checkUserSession, setNewData, changeLoading, getData } =
        useContext(AppContext);
    const [profileCoachFinal, setProfileCoachFinal] = useState(null);
    useEffect(() => {
        getDataCoach();
    }, []);
    const getDataCoach = async () => {
        changeLoading(true);

        const us = checkUserSession();
        const url = `FindCoach/GetCoachByUser`;
        const { error, data } = await requestAxios({
            url: url,
            token: us.token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            setProfileCoachFinal(data);
            changeLoading(false);
            setNewData({ ProfileCoach: { modalDirectiryListingShow: true } });
        }
    };

    return profileCoachFinal&&<CoachProfileFinal userId={profileCoachFinal?.userId} />;
};
