import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import './PDFViewer.css';

export default function AllPages(props) {
    const [numPages, setNumPages] = useState(null);
    const [secondViewer, setSecondViewer] = useState(false);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const { pdf } = props;

    return (
        <>
            {secondViewer===false?<Document
                file={{url:pdf}}
                options={{ workerSrc: 'pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={()=>setSecondViewer(true)}
                loading="Loading..."
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
            :
            <iframe className="pdfViewweIframe__container" title="iframe PDF" src={pdf}/>
                }
        </>
    );
}
