import React, { useState, useContext, useEffect } from 'react';
import AppContext from '../../../../Context/AppContext';
import ActionStep from '../ActionSteps/ActionStep';
import ModalAddAction from './Modal/ModalAddAction';
import ArrowSvg from '../../../../Assets/icons/ArrowNoCircle.svg';
import './DailyActions.css';

const DailyActions = (props) => {
    const { checkUserSession, changeLoading, setNewData, getData } =
        useContext(AppContext);
    const { token } = checkUserSession();
    const { dayAddFindDailyActions } = getData();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [init,setInit ] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [currItem, setCurrItem] = useState({});
    const [dayName, setDayName] = useState('Today');
    function addActionStep() {
        setNewData({
            repeatAction: null,
            currentAction: null,
        });
        setModalType(1);
        setShowModalEdit(true);
    }

    const [modalVisible, setModalVisible] = useState(false);
    const [textModal, setTextModal] = useState({
        title: 'Complete',
        desc: 'Are you ending this action?',
    });
    function hideNotification() {
        setModalVisible(false);
        props.setShowModal(true);
    }

    function saveActions() {
        if (props.AllState.Actions.length !== 0) {
            props.SaveActionStepsChange();
            forceUpdate();
        } else {
            props.setShowModal(true);
        }
    }
    function getDayName(dateStr) {
        var date = new Date(dateStr);
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    }
    const AddDay = (add) => {
        const daysToAdd = dayAddFindDailyActions.days + add;
        if (daysToAdd > 6 || daysToAdd < 0) {
            setNewData({
                modalError: {
                    show: true,
                    Desc: 'Invalid Date',
                    Title: 'Error',
                },
            });

            return false;
        }
        switch (daysToAdd) {
            case 0:
                setDayName('Today');
                break;
            case 1:
                setDayName('Tomorrow');
                break;
            default:
                var date = new Date();
                date.setDate(date.getDate() + daysToAdd);
                setDayName(getDayName(date));
                break;
        }
        setNewData({
            dayAddFindDailyActions: { days: daysToAdd },
        });
    };
    useEffect(() => {
        setNewData({
            dayAddFindDailyActions: { days: 0 },
        });
        props.getCurrentDailyActions(0);
        setInit(true)
    }, []);
    useEffect(() => {
        if(init) props.getCurrentDailyActions();
    }, [dayAddFindDailyActions]);
    return (
        <section className="modalActions__section DailyActions__container">
            <div className="DailyActionsSelectedDay__container">
                <button
                    className={
                        dayAddFindDailyActions?.days > 0 ? '' : 'disabled'
                    }
                    onClick={() =>
                        dayAddFindDailyActions?.days > 0 && AddDay(-1)
                    }
                >
                    <img src={ArrowSvg} />
                </button>
                {dayName}
                <button
                    className={
                        dayAddFindDailyActions?.days < 6 ? '' : 'disabled'
                    }
                    onClick={() =>
                        dayAddFindDailyActions?.days < 6 && AddDay(1)
                    }
                >
                    <img src={ArrowSvg} className={`Right`} />
                </button>
            </div>
            <div className="modalActions__sectionComplete DailyActions">
                <div>
                    {dayAddFindDailyActions?.days > 0 ? (
                        ''
                    ) : (
                        <h6 className="heading-6">{'COMPLETE'}</h6>
                    )}
                </div>
                <div className="modalActions__sectionDelete"></div>
            </div>

            {!props.saving &&
                props.AllState.Actions.map((item, index) => (
                    <ActionStep
                        key={`actionItemKey${item.id}`}
                        actionStep={item}
                        AllState={props.AllState}
                        AllStateChange={props.AllStateChange}
                        index={index}
                        show={props.show}
                        setShowModal={props.setShowModal}
                        textModal={textModal}
                        setTextModal={setTextModal}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        hideNotification={hideNotification}
                        setCurrentTrackerTab={props.setCurrentTrackerTab}
                        getMyProgress={props.getMyProgress}
                        getCurrentDailyActions={props.getCurrentDailyActions}
                        forceUpdate={forceUpdate}
                        setSaving={props.setSaving}
                        setShowModalEdit={setShowModalEdit}
                    />
                ))}

            <div className="modalActions__addMore">
                {props.AllState.Actions.length < 14 && (
                    <button
                        className=" divAddActionStep"
                        onClick={() => addActionStep()}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="110"
                            height="60"
                            viewBox="0 0 100 80"
                        >
                            <g id="a">
                                <g transform="translate(-1480 -320)  ">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="4"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="4"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="4"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                    <text
                                        className="AddMoreButtonActionStep"
                                        x="1500"
                                        y="354"
                                    >
                                        ADD MORE
                                    </text>
                                </g>
                            </g>
                        </svg>
                    </button>
                )}
            </div>

            <div className="modalActions__button">
                <button
                    onClick={() => saveActions()}
                    className="button-full-mid"
                    type="button"
                >
                    SAVE
                </button>
            </div>

            {showModalEdit && (
                <ModalAddAction
                    show={showModalEdit}
                    setShow={setShowModalEdit}
                    modalType={modalType}
                    currItem={currItem}
                    getToDoList={() => console.log('=== GetTodoList ===')}
                    token={token}
                    changeLoading={changeLoading}
                    getCurrentDailyActions={props.getCurrentDailyActions}
                />
            )}
        </section>
    );
};

export default DailyActions;
