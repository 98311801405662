import React from 'react';
import './Enroll.css';

const Ernoll = ({ moduleInfo, buyCourse }) => {
    const ModuleInfo = moduleInfo[0] != null ? moduleInfo[0] : {};
    const ErnollR = (
        <>
            <section className="Enroll__container">
                <section className="EnrollGrid__container">
                    <div className="EnrollGridItem__container">
                        <i
                            className="fa fa-laptop fa-4 Enroll__icon"
                            aria-hidden="true"
                        />
                        <p className="Enroll__p EnrollModules__p">
                            <span className="Enroll__hours">
                                {ModuleInfo.numberOfModules}
                            </span>
                            <br />
                        </p>
                        <p className="Enroll__p EnrollModules__p">
                            <span className="Enroll__hours">
                                Customs Modules
                            </span>
                            <br />
                            <span className="Enroll__OfTraining">
                                of training
                            </span>
                        </p>
                    </div>
                    <div className="EnrollGridItem__container noBorders">
                        <i
                            className="fa  fa-shopping-cart fa-4 Enroll__icon"
                            aria-hidden="true"
                        />
                        <p className="Enroll__p Enroll__hours">
                            ${ModuleInfo.price}
                        </p>
                        <p className="Enroll__p">
                            <span className="Enroll__hours">
                            Subscribers
                            </span>
                        </p>
                    </div>
                    <button
                        className="button-full-mid btnEnrollModuleVip"
                        type="button"
                        onClick={() => buyCourse()}
                    >
                        ENROLL TODAY
                    </button>
                </section>
            </section>
            <div className="btnEnrollModuleVipMobile__container">
                <button
                    className="  btnEnrollModuleVipMobile"
                    type="button"
                    onClick={() => buyCourse()}
                >
                    ENROLL TODAY
                </button>
            </div>
        </>
    );
    return ErnollR;
};

export default Ernoll;
