import React from 'react';
import { Link } from 'react-router-dom';
import './ActionButtons.css';


export default () => (
    <div className="actionButtons_container">
        <Link to="/affiliate">
            <button
                type="button"
            >
                BECOME AN AFFILIATE
            </button>
        </Link>
        <button
            type="button"
            onClick={() =>
                window.open(
                    'https://myoola.oolalife.com/corporphan/enrollment/enrollmentconfigurationambassador',
                    '_blank'
                )
            }
        >
            BECOME AN AMBASSADOR
        </button>
        <button
            type="button"
            onClick={() =>
                window.open('https://myoola.oolalife.com/corporphan/product/355', '_blank')
            }
        >
            BECOME A COACH
        </button>
    </div>
);
