import React from 'react';
import { useHistory } from 'react-router-dom';
import ELearningCard from '../ELearningCard';

const MyPath = ({
    loaded,
    completeLength,
    uncompleteLength,
    unlockedLength,
    height,
    modules,
    typeModules,
    configData,
    level,
}) => {
    const history = useHistory();
    const MyPathR = (
        <>
            {modules.myBreakthroughExperience.filter(
                (x) => x.isUnlocked === true
            ).length === 12 ? (
                <section className="paragraph-3 ModuleDescription">
                    <p className="TitleContentElearning">
                        My Personalized Path: {completeLength}/
                        {modules.myPersonalizedPath.length}
                    </p>
                    <span>PHASE 3:</span> A new module unlocks every 30-days for
                    9 months
                </section>
            ) : (
                <section className="paragraph-3 ModuleDescription">
                    <p>
                        <span>
                            Complete all the My Breakthrough Experience modules
                            before you start My Personalized Path
                        </span>
                    </p>
                    <span>PHASE 3:</span> A new module unlocks every 30-days for
                    9 months.
                </section>
            )}
            {(completeLength === 0 &&
                typeModules === 2 &&
                modules.myBreakthroughExperience.filter(
                    (x) => x.isUnlocked === true
                ).length === 12 &&
                modules.myPersonalizedPath.length === 0) ||
            level == 2 ? (
                <button
                    className="eLearningBoard__createYourCourse"
                    onClick={() =>
                        history.push('/assessmentMyPP?AssessmentType=2')
                    }
                    type="button"
                >
                    START YOUR PATH!
                    <svg>
                        <g transform="translate(40 40)">
                            <g
                                className="pulseAnimation"
                                style={{ opacity: '0.5' }}
                            >
                                <circle r="30" fill="white" />
                                <circle r="28" fill="#d86b59" />
                                <circle r="22" fill="white" />
                            </g>
                        </g>
                    </svg>
                </button>
            ) : (
                ''
            )}
            {level != 2 && (
                <div className="eLearningBoard__modules OolaScroll MyPathhModules__container">
                    {modules.myPersonalizedPath.map((item, i) => (
                        <ELearningCard
                            index={i}
                            height={height}
                            item={item}
                            configData={configData}
                            showLeftBorder={i % 2}
                            key={item.id}
                        />
                    ))}
                </div>
            )}
        </>
    );

    return MyPathR;
};

export default MyPath;
