import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default ({ className, onClick, color, size, popoverText }) => (
    <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
            popoverText ? (
                <Popover id="popover-positioned-right">
                    <Popover.Content>{popoverText}</Popover.Content>
                </Popover>
            ) : (
                <div />
            )
        }
    >
        <button
            className={`${className} button-no-styles`}
            onClick={onClick}
            type="button"
        >
            <svg
                width={size || '10px'}
                height={size || '10px'}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_429_11160)">
                    <circle
                        cx="12"
                        cy="11.9999"
                        r="9"
                        stroke={color || '#292929'}
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <rect
                        x="12"
                        y="8"
                        width="0.01"
                        height="0.01"
                        stroke={color || '#292929'}
                        strokeWidth="3.75"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 12V16"
                        stroke={color || '#292929'}
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_429_11160">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    </OverlayTrigger>
);
