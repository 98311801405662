import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css';

export default function HorizontalLabelPositionBelowStepper() {
    return (
        <div className={{ width: '100%' }}>
            <Navbar
                bg="light"
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
            >
                <Link to="/">
                    <Navbar.Brand>
                        <img
                            style={{ maxWidth: '100%', maxHeight: '40px' }}
                            className="d-inline-block align-top Beta-Logo "
                            alt="Oola logo"
                            
                        />
                    </Navbar.Brand>
                </Link>
            </Navbar>
        </div>
    );
}
