import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginHeader from '../Common/LoginHeaderWhite/LoginHeaderWhite';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import './Contact.css';

import phoneIcon from '../../Assets/img/Contact/phone-icon.svg';
import emailIcon from '../../Assets/img/Contact/email-icon.svg';
import chatIcon from '../../Assets/img/Contact/chat-icon.svg';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <section className="contact">
                <div className="contact__hero">
                    <h1 className="display-1">GET IN TOUCH</h1>
                    <p className="paragraph-3">
                        <span>Need some help?</span> We’d love to hear from you.
                        Here’s how you can reach us…
                    </p>
                </div>
                <div className="contact__options">
                    <div className="options__cards">
                        <article>
                            <figure>
                                <img src={phoneIcon} alt="phone icon" />
                            </figure>
                            <h5 className="heading-5">GIVE US A CALL</h5>
                            <p className="paragraph-3">208-877-OOLA (6652)</p>
                        </article>
                        <article>
                            <figure>
                                <img src={emailIcon} alt="email icon" />
                            </figure>
                            <h5 className="heading-5">SEND US AN EMAIL</h5>
                            <p className="paragraph-3">support@oolalife.com</p>
                        </article>
                        <article>
                            <figure>
                                <img src={chatIcon} alt="chat icon" />
                            </figure>
                            <h5 className="heading-5">LIVE CHAT</h5>
                            <p className="paragraph-3">208-877-OOLA (6652)</p>
                        </article>
                    </div>
                    <div className="options__description">
                        <p className="paragraph-3">
                            Our support staff is currently available Monday
                            through Friday, between 8:00
                            <br /> am and 4:00 pm PT. Our typical turnaround
                            time for emails is within one
                            <br /> business day.
                        </p>
                        <div>
                            <Link to="/learnmore/faq" className="heading-5">
                                <button
                                    className="button-full-big"
                                    type="button"
                                >
                                    FREQUENTLY ASKED QUESTIONS
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </>
    );
};

export default Contact;
