export default [
    {
        id: 1,
        Text: 'Design My Life Blueprint',
        TextItalic: '(self-paced)',
    },
    {
        id: 2,
        Text: 'Breakthrough Experience',
        TextItalic: '(1x/week for 12 weeks)',
    },
    {
        id: 3,
        Text: 'My Personalized Path',
        TextItalic: '(1x/month personalized coaching)',
    },
    {
        id: 4,
        Text: 'My Daily Tracker',
        TextItalic: '(1x/month personalized coaching)',
    },
    {
        id: 5,
        Text: 'Unlimited support from your private Inner Circle',
        TextItalic: '',
    },
    {
        id: 6,
        Text: 'Bonus personal development with Quarterly Summits',
        TextItalic: '(Live or Virtual)',
    },
    {
        id: 7,
        Text: 'Priority Access to OolaPalooza annual LIVE event',
        TextItalic: '',
    },
];
