import React from 'react';
import Question from '../Question/Question';
import './Pie.css';

const Pie = (FData) => {
    const { hideNave } = FData;

    const selectScore = (id, value) => {
        
        
        const fullState = { ...FData.AllState };
        const newState = fullState.Fs.find((x) => x.id === FData.FData.id);
        newState.questions.find((x) => x.id === id).score = Number(value);
        newState.questions.find((x) => x.id === id).edited = 1;
        newState.CurrQuestion = FData.FData.id;

        FData.mChange(newState);
    };
    return <Question  Fs={FData} selectScore={selectScore} hideNav={hideNave} />;
};
export default Pie;
