/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import Pie from '../Wheel/Pie';
import Confetti from '../Confetti/Confetti';
import Wheel from '../Wheel/Wheel';
import ContentFinal from './components/FinalStep1/FinalStep1';
import Fnav from './components/Fnav/Fnav';
import initialState from './components/initialState';
import './Step12.css';

export default class Step12 extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    async componentDidMount() {
        const { checkUserSession, updateUserStepId,changeLoading } = this.context;
        const currUser = checkUserSession();
        const userState = await updateUserStepId();
        window.addEventListener('resize', this.resize.bind(this));
        // this.setState({changeStepIndex:this.props.changeStepIndex})
        const url = `${this.props.configData.SERVER_URL}areas/GetQuestions/${userState.assessmentId}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${currUser.token}` },
            })
            .then((result) => {
                const questions = [];
                result.data.forEach((quest) => {
                    quest.TotalScore = quest.questions.reduce(
                        (a, b) => a + Number(b.score),
                        0
                    );
                    questions.push(quest);
                });
                this.setState({
                    Fs: questions,
                    changeStepIndex: this.props.changeStepIndex,
                    hideNav: this.props.mobile,
                    User: currUser,
                    lastF: userState.areaId + 1,
                    index: 0 ,
                    currentQuestion: questions[
                        userState.areaId > 6 ? 6 : userState.areaId
                    ].questions.filter((x) => x.score > 0).length,
                });

                if (userState.assessmentId !== 0) {
                    this.props.handleChange();
                }
            })
            .catch((error) =>
                this.setState({
                    error,
                    isLoading: false,
                })
            );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize.bind(this));
    }

    // eslint-disable-next-line class-methods-use-this
    getEventTarget(e) {
        const ev = e || window.event;
        return ev.target || ev.srcElement;
    }

    handleChange = (e) => {
        const ev = e || window.event;
        const target = ev.target || ev.srcElement;
        const index = Number(target.parentElement.parentElement.dataset.key);
        if (!Number.isNaN(index)) {
            this.setState({
                index,
            });
        }
    };

    handleChangeMobile = (e) => {
        const ev = e || window.event;
        const target = ev.target || ev.srcElement;
        const index = Number(target.parentElement.dataset.index);
        if (!Number.isNaN(index)) {
            this.setState({
                index,
            });
        }
    };

    handleChangeIndex = (index) => {
        if (index === 7 && this.props.userStatus.userStepId != 6) {
            this.props.handleChangeHeader(0);
        }
        if (index === 7 && this.props.userStatus.userStepId === 6) {
            this.props.changeIndexLevelStep(1);
        }
        this.setState({
            index,
        });
    };

    handleChangeConfetti = (confetti) => {
        this.setState({
            confetti,
        });
    };

    handleChangeState = (st) => {
        this.setState(st);
    };

    endStep1 = (state) => {
        const { checkUserSession } = this.context;
        const { token, userId, userStepId } = checkUserSession();

        if (this.props.myr === 1) {
            window.location.replace('/dashboard');
        } else {
            const PostObj = { userId, scores: [] };
            state.Fs.forEach((f) => {
                f.questions.forEach((question) => {
                    PostObj.scores.push({
                        questionId: question.id,
                        score: question.score,
                    });
                });
            });

            this.props.handleChangeHeader(1);
            this.state.changeStepIndex(2);
    }
    };

    resize() {
        this.setState({ hideNav: window.innerWidth <= 760 });
    }
    getTotalAnswers() {
        let answers = 0;
        this.state.Fs.forEach((f) => {
            answers += f.questions.filter((x) => x.score > 0).length;
        });
        return answers;
    }
    render() {
        return (
            <>
                {this.state.User.userStepId === 5 && this.state.index !== 7 && (
                    <section className="midYearReview__headerContainer">
                        <h1>MID-YEAR REVIEW</h1>
                        <h4>QUESTION {this.getTotalAnswers()}/70</h4>
                    </section>
                )}
                {this.state.Fs !== null && (
                    <div className={`${this.state.index!==7?"s12__container":"s12__containerFinal"}`}>
                        {this.state.index < 7 ? (
                            <div className="fnav__container">
                                <Fnav
                                    state={this.state}
                                    handleChange={this.handleChange}
                                    handleChangeState={this.handleChangeState}
                                />
                            </div>
                        ) : (
                            <div />
                        )}
                        <div
                            className="fQuestions__container"
                            style={
                                this.state.index >= 7 ? { width: '100%' } : {}
                            }
                        >
                            <SwipeableViews
                                // style={{ height: '100%' }}
                                className="SwipeableViews_Step12"
                                index={this.state.index}
                                onChangeIndex={this.handleChangeIndex}
                                disabled
                            >
                                {this.state.Fs.map((item, i) =>
                                    this.state.index === i ? (
                                        <div
                                            className="questionsPie__container"
                                            key={`questionsPie__container${item.id}`}
                                        >
                                            <Pie
                                                hideNave={this.state.hideNav}
                                                FData={item}
                                                mChange={this.handleChangeState}
                                                iChange={this.handleChangeIndex}
                                                AllState={this.state}
                                                userStatus={this.userStatus}
                                            />
                                        </div>
                                    ) : (
                                        <div key={`questionsPie__container${item.id}`}/>
                                    )
                                )}
                                {this.state.index >= 7 ? (
                                    <div className="finalStep1__container">
                                        <Confetti
                                            props={1}
                                            key="9999"
                                            mobile={this.props.mobile}
                                        />
                                        <ContentFinal
                                            state={this.state}
                                            handleChangeIndex={
                                                this.handleChangeIndex
                                            }
                                            endStep1={this.endStep1}
                                            myr={this.props.myr}
                                            mobile={this.props.mobile}
                                        />
                                        
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </SwipeableViews>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
