import React, { useEffect } from 'react';
import LoginHeader from '../Common/LoginHeaderWhite/LoginHeaderWhite';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import './OurStory.css';

const OurStory = () => {
    useEffect(async () => {
        window.scrollTo(0, 0);
    }, []);

    const OurStoryR = (
        <>
            <LoginHeader
                // goEnroll={goEnrollment}
                isDarkLogo
                enrollButtonText="ENROLL"
            />
            <section className="content__OurStory">
                <figure className="ourStory__img">
                    <h1 className="ourStory__imgTextVan">OUR STORY</h1>
                </figure>
                <div className="ourStory__description">
                    <p className="ourStory__imgText">
                        Since the first copy of the original book, Oola: Find
                        balance in an Unbalanced World, rolled off the presses,
                        the Oola Lifestyle has become a literal phenomenon—an
                        organic movement. With their science-based nutritional
                        products, sold out live events, custom e-learning
                        programs, best selling book series, the Oola community
                        has reached countless cities across America and every
                        major city in the world via their 1,000,000+ fans on
                        social media and committed Oola Ambassadors and
                        Certified Coaches. The co-founders and work-life balance
                        experts, Dr. Dave Braun and Dr. Troy Amdahl, are often
                        found on the road, in their vintage VW surf bus,
                        collecting dreams. The Oola Bus is currently covered 31
                        layers in the hand-written dreams from people they have
                        met along their amazing adventure, inspiring those they
                        meet to pursue their dreams, and not to settle for
                        ordinary, because extraordinary is within them. They are
                        committed to changing the world with a word. #Oola
                    </p>
                    <div className="rect__OurStory" />
                </div>
            </section>
            <section className="content__OurStoryAboutUs">
                <figure className="ourStory__AboutUs">
                    <img className="ourStory__AboutUsImg ourStory__AboutUsImgDesktop" />
                </figure>
                <div className="ourStory__AboutUsdescription">
                    <h1 className="ourStory__AboutUsTitle">ABOUT US</h1>{' '}
                    <figure className="ourStory__AboutUs">
                        <img className="ourStory__AboutUsImg ourStory__AboutUsImgMobile" />
                    </figure>
                    <p className="ourStory__AboutUsImgText">
                        The OolaGuys, Dr. Dave and Dr. Troy, are the co-founders
                        and co-authors of Oola. Their mission is to make a
                        positive difference in the lives of 1 billion people in
                        the next 7 years using the power of #Oola. They believe
                        that a more fulfilled and balanced you will make a more
                        balanced family, community, and world. They are
                        passionate about their mission to spread the message of
                        Oola and make a lasting impact on the world.
                    </p>
                </div>
            </section>
            <FooterSection />
        </>
    );

    return OurStoryR;
};

export default OurStory;
