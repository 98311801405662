import { useState } from 'react';

export default ({ item, i, _index, setValueQuestion, ChangeIndex }) => {
    const [activeInput, setActiveInput] = useState(1);
    const isSafari = navigator.userAgent.match('/safari/i');
    function getNumberrange(n, item) {
        const elements = [];
        for (let i = 1; i <= 10; i += 1) {
            elements.push(
                <p
                    aria-hidden="true"
                    onClick={() => setValueQuestion(_index, i)}
                    key={`NumberValueRange${item.id}${i}`}
                    style={{
                        // fontSize:
                        //     props.hideNav === false ? '' : '15px !important',
                        cursor: 'pointer',
                        color: `${item.score == i ? '#78ccf6' : ''}`,
                        opacity: `${
                            item.score === i ||
                            item.score === 0 ||
                            item.score == null
                                ? ''
                                : '.6'
                        }`,
                    }}
                    className={`tickmarks__numbers rangeNum  `}
                >
                    {i}
                </p>
            );
        }
        return elements;
    }

    return (
        <div className="MindBodyWellnessAssessmentQuestionItem">
            <h1>{item.title}</h1>
            <h2>{item.text}</h2>
            <div className="line__container" key={item.id}>
                <input
                    id={`MindBodyWellnessAssessmentQuestionRange${item.id}`}
                    type="range"
                    min="1"
                    max="10"
                    className="rangeInput MindBodyWellnessAssessmentQuestionRange"
                    disabled={!activeInput}
                    onChange={(input) => {
                        if (activeInput === 1) {
                            setActiveInput(0);
                            setValueQuestion(i, input.target.value);
                            setTimeout(() => {
                                setActiveInput(1);
                            }, 500);
                        }
                    }}
                    onClick={(input) => {
                        if (activeInput === 1) {
                            setActiveInput(0);
                            setValueQuestion(i, input.target.value);
                            setTimeout(() => {
                                setActiveInput(1);
                            }, 500);
                        }
                    }}
                    onTouchEnd={(input) => {
                        if (activeInput === 1 && !isSafari) {
                            setActiveInput(0);
                            setValueQuestion(i, input.target.value);
                            setTimeout(() => {
                                setActiveInput(1);
                            }, 500);
                        }
                    }}
                    style={{
                        background: '#78CCF6',
                    }}
                    value={item.score == null ? 1 : item.score}
                    list="tickmarks"
                />
                <div id="tickmarks">{getNumberrange(i, item)}</div>
            </div>
            <div className="MindBodyWellnessAssessmentQuestionButtonContainer">
                <button
                    className="previous"
                    onClick={() => {
                        if (_index > 0) ChangeIndex(_index - 1);
                    }}
                    style={{
                        pointerEvents: _index == 0 && 'none',
                    }}
                >
                    {_index > 0 && '< Previous'}
                </button>
                {_index < 13 ? (
                    <button
                        className="next"
                        style={{
                            color: !item.score && 'gray',
                        }}
                        onClick={() => {
                            if (item.score) ChangeIndex(_index + 1);
                        }}
                    >
                        {'NEXT >'}
                    </button>
                ) : (
                    <div>
                        <button
                            className="button-full-mid"
                            onClick={() => {
                                if (item.score) ChangeIndex(_index + 1);
                            }}
                            style={{ opacity: item.score ? 1 : 0.4 }}
                        >
                            {'COMPLETE'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
