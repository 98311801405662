import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../Context/AppContext';
import "./ModalGenericError.css"
const ModalGenericError = () => {
    const { getData, setNewData } = useContext(AppContext);
    const { modalError } = getData();
    return (
        <Modal
            show={modalError?modalError.show:false}
            onHide={() => setNewData({ modalError: { show: false } })}
            className="fadeModalError"
            id="modalError"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
            size="lg"
            centered
        >
            <Modal.Header>
                <button style={{ visibility: 'hidden' }}>&times;</button>

                <button
                    onClick={() => setNewData({ modalError: { show: false } })}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        cursor: 'pointer',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="ModalErrorTitle" style={{ width: '100%' }}>
                        {modalError?.Title}
                    </div>
                    <div className="ModalErrorDesc">{modalError?.Desc} </div>
                </div>
                <center style={{ textAlign: 'right' }}>
                    <button
                        onClick={() =>
                            setNewData({ modalError: { show: false } })
                        }
                        className="btnCancelRec"
                        style={{ width: '20%' }}
                    >
                        OK
                    </button>
                </center>
            </Modal.Body>
        </Modal>
    );
};
export default ModalGenericError;
