import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';

import StepHeader from './StepHeader';
import Step12 from './Step12/Step12';
import configData from '../../config.json';
import './Step1Slices.css';
import './QuestionaryStep1.css';
import './Step5MidYearReview.css';

const Step5MidYearReview = ({
    mobile,
    indexStepChange,
    handleChangeHeader,
    userStatus,
}) => {
    const [change, setChange] = useState(false);
    const [transition, setTransition] = useState(false);
    const [, height] = useWindowSize();

    const handleChange = () => {
        setTransition(true);
        setTimeout(() => {
            setChange(true);
        }, 500);
    };
    const handleChangeDirect = () => {
        setChange(true);
    };

    return (
        <section className="midYearReview__FullContainer  headerStepperHidden">
             
                <div className= 'step12__container midYearReview__QuestionContainer'> 
                    <Step12
                        changeStepIndex={indexStepChange}
                        configData={configData}
                        mobile={mobile}
                        height={height}
                        handleChangeHeader={handleChangeHeader}
                        userStatus={userStatus}
                        handleChange={handleChangeDirect}
                        myr={1}
                    />
                </div>
        </section>
    );
};
export default Step5MidYearReview;
