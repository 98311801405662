/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import AppContext from '../../Context/AppContext';
import requestAxios from '../../util/requestAxios';
import Fnav from './Sections/Fnav/Fnav';
import FreeQuestion from './Sections/FreeQuestion/FreeQuestion';
import SubmitQuestionary from './Sections/SubmitQuestionary/SubmitQuestionary';
import './FreeStep.css';

const FreeStep = ({mobile}) => {
    const { changeLoading, updateFreeTestData, checkFreeUserSession } =
        useContext(AppContext);
    useEffect(() => {
        getData();
    }, []);
    const FreeTestData = checkFreeUserSession();

    const getData = async () => {
        changeLoading(true);
        const url = `areas/GetFreeTestQuestions`;
        if (FreeTestData == null) {
            const { data, error } = await requestAxios({ url });
            if (error == '') {
                updateFreeTestData({
                    Fs: data,
                    index: 0,
                    lastF: 0,
                    currentQuestion: 0,
                    oolaScore: 0,
                });
            }
        }
        changeLoading(false);
    };
    const changeIndex = (next) => {
        let newData = { ...FreeTestData };
        if (FreeTestData.currentQuestion + next > 2) {
            newData.index += next;
            newData.currentQuestion = 0;
        }
        if( FreeTestData.currentQuestion + next < 0 ){
            newData.index += next;
            newData.currentQuestion = 2;
        }
        else {
            newData.currentQuestion += next;
        }
        updateFreeTestData(newData);
    };
    return (
        <div className="FreeStep__container">
            {FreeTestData?.index < 7 && (
                <div className={`${'s12__container'}`}>
                    <div className="fnav__container FreeTest">
                        <Fnav state={FreeTestData} />
                    </div>
                </div>
            )}
            {FreeTestData && (
                <div
                    className={`fQuestions__container  ${
                        FreeTestData?.index < 7?'FreeTest': 'FinalSection'
                    }`}
                    style={FreeTestData?.index >= 7 ? { width: '100%' } : {}}
                >
                    {FreeTestData?.index < 7 && (
                        <div className="questionNumber__container FreeTest">
                            <h5 className="heading-6">
                                QUESTION{' '}
                                {FreeTestData?.currentQuestion +
                                    FreeTestData?.index * 3 +
                                    1}
                                /21
                            </h5>
                        </div>
                    )}
                    <SwipeableViews
                        // style={{ height: '100%' }}
                        className={`SwipeableViews_Step12 ${  FreeTestData?.index < 7?'FreeTest': 'FinalSection'} `}
                        index={FreeTestData?.index}
                        // onChangeIndex={this.handleChangeIndex}
                        disabled
                    >
                        {FreeTestData?.Fs.map((item, i) => (
                            <div
                                className="questionsPie__container"
                                key={`questionsPie__container${item.id}`}
                            >
                                <FreeQuestion FData={item} FIndesx={i} />
                            </div>
                        ))}
                        {FreeTestData?.index >= 7 ? (
                            <SubmitQuestionary mobile={mobile} />
                        ) : (
                            <div />
                        )}
                    </SwipeableViews>
                    {FreeTestData && FreeTestData.index < 7 && (
                        <div
                            className={`qbuttons__container ${
                                FreeTestData.index < 7 && 'FreeTest'
                            }`}
                        >
                            <div className="previousButton__container">
                                {FreeTestData.currentQuestion === 0 &&
                                FreeTestData.index === 0 ? (
                                    <div />
                                ) : (
                                    <button
                                        className="botons btnPrevious"
                                        onClick={() => {
                                            changeIndex(-1);
                                        }}
                                        type="button"
                                    >
                                        <h6>&lt; PREVIOUS</h6>
                                    </button>
                                )}
                            </div>
                            <div className="nextButton__container">
                                <button
                                    className="botons btnNext"
                                    disabled={
                                        (FreeTestData.Fs[FreeTestData.index]
                                            ?.questions[
                                            FreeTestData.currentQuestion
                                        ].score == null ||
                                            FreeTestData.Fs[FreeTestData.index]
                                                ?.questions[
                                                FreeTestData.currentQuestion
                                            ].score === 0) &&
                                        FreeTestData.currentQuestion !== 3
                                            ? 'disabled'
                                            : ''
                                    }
                                    onClick={() => {
                                        changeIndex(1);
                                    }}
                                    type="button"
                                >
                                    <h6>
                                        {FreeTestData.currentQuestion !== 10 &&
                                            'NEXT >'}
                                    </h6>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FreeStep;
