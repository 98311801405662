import React, { useState, useEffect, useContext } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSpring, animated, config } from 'react-spring';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import OolaButton from './OolaButton';
import './Tracker.css';
import LockSVG from '../../../Assets/img/LockIconFullWhite.svg';
import DailyActions from './DailyActions/DailyActions';
import ToDoList from './ToDoList/ToDoList';
import configData from '../../../config.json';
import ModalWarningActionSteps from './ModalWarningActionSteps/ModalWarningActionSteps';

const Tracker = (props) => {
    const { checkUserSession, changeModalWarningUpgrade, getData, setNewData } =
        useContext(AppContext);
    const { token, userId, userTypeId } = checkUserSession();
    const [active, set] = useState(false);
    const [saving, setSaving] = useState(true);
    const [show, SetShow] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentTrackerTab, setCurrentTrackerTab] = useState(0);
    const [showPopover, setShowPopover] = useState(false);
    const [showWarningAdditionalCourse, setShowWarningAdditionalCourse] =
        useState(false);

    const [textModal, setTextModal] = useState({
        title: 'YOU’VE REACHED YOUR ACTION STEP LIMIT OF 15',
        desc: 'If you want to add more action steps, edit or delete one that you already have on your list.',
    });
    const props3 = useSpring({
        stroke: active ? 100 : 0,
        otherStroke: active ? '100, 100' : '0,100',
    });
    const popover = (
        <Popover className="MyProgressPoper__container">
            <Popover.Content
                className="MyProgressPoper"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="NonSubscriberAdd_Popover"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={() => {
                        setShowPopover(false);
                    }}
                >
                    <h1>
                        Become a subscriber to the
                        <span onClick={() => changeModalWarningUpgrade(true)}>
                            {' '}
                            Oola Lifestyle Framework
                        </span>{' '}
                        to unlock this feature.
                    </h1>
                </div>
            </Popover.Content>
        </Popover>
    );

    async function getCurrentDailyActions(daysToAdd) {
        
        const { dayAddFindDailyActions } = getData();
        if (currentTrackerTab != 1) {
            setNewData({
                dayAddFindDailyActions: { days: 0 },
            });
        }
        setSaving(true);

        
        let addDays =
            daysToAdd != null
                ? daysToAdd
                : currentTrackerTab == 0
                ? 0
                : !dayAddFindDailyActions
                ? 0
                : dayAddFindDailyActions.days;
        const url = `${configData.SERVER_URL}Actions/GetUserNotCompletedActions/${userId}/${addDays}`;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                setTimeout(() => {
                    props.AllState.Actions = result.data.actions;
                    props.AllState.DailySteps = result.data.todayActionsCount;
                    props.AllStateChange(props.AllState);
                    setSaving(false);
                    setShowWarningAdditionalCourse({
                        displayFailModal: result.data.displayFailModal,
                        phaseNumber: result.data.phaseNumber,
                        courseId: result.data.courseId,
                    });
                }, 1000);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if (params.showActionsSteps == 1) {
            setCurrentTrackerTab(1);
        }
              if (currentTrackerTab != 1) {
            setNewData({
                dayAddFindDailyActions: { days: 0 },
            });
        }
        getCurrentDailyActions();

    }, [currentTrackerTab]);
    
    function hideWarningModal() {
        setSaving(false);
        setModalVisible(false);
        setTextModal({
            title: 'YOU’VE REACHED YOUR ACTION STEP LIMIT OF 15',
            desc: 'If you want to add more action steps, edit or delete one that you already have on your list.',
        });
    }
    function setShowModal(visible) {
        if (!visible) setSaving(false);
        const { dayAddFindDailyActions } = getData();

        const url = `${
            configData.SERVER_URL
        }Actions/GetUserNotCompletedActions/${userId}/${
            !dayAddFindDailyActions ? 0 : dayAddFindDailyActions.days
        }`;
        setSaving(true);
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                props.AllState.Actions = result.data.actions;
                if (props.AllState.Actions.length < 3) {
                    let i = 3 - props.AllState.Actions.length;
                    while (i !== 0) {
                        props.AllState.Actions.push({
                            text: '',
                            index: props.AllState.Actions.length,
                            canBeRemoved: true,
                        });
                        i--;
                    }
                }

                props.AllState.DailySteps = result.data.todayActionsCount;
                props.AllStateChange(props.AllState);
                props.AllState.FromButton = null;
                SetShow(visible);
                currentTrackerTab(2);
                props.getMyProgress();
                setSaving(false);
            })
            .catch((error) => {
                setSaving(false);
            });
    }
    function setShowModalFromButton(visible) {
        if (saving) return false;
        if (props.AllState.DailySteps !== 15) {
            if (visible == true) {
                setSaving(true);
                if (
                    props.AllState.Actions.filter((x) => x.text !== '')
                        .length === 0
                ) {
                    if (props.AllState.DailySteps < 15) {
                        const PostObj = {
                            userId,
                            text: '',
                        };
                        const url = `${configData.SERVER_URL}Actions/AsyncInsertEmptyCompletedAction`;

                        axios({
                            method: 'post',
                            url,
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            data: PostObj,
                        })
                            .then((res) => {
                                setSaving(false);
                                setShowModal(false);
                                setNewData({
                                    dayAddFindDailyActions: { days: 0 },
                                });
                                props.getMyProgress();
                            })
                            .catch((err) => {
                                setSaving(false);

                                alert(err);
                            });
                    }
                } else {
                    props.AllState.FromButton = true;
                    props.AllStateChange(props.AllState);
                    setCurrentTrackerTab(1);
                    getCurrentDailyActions();
                    setSaving(false);
                }
            } else {
                SetShow(visible);
            }
        } else {
            setModalVisible(true);
        }
    }
    function SaveActionStepsChange() {
        setSaving(true);
        const newArr = props.AllState.Actions.filter(
            (x) => String(x.text).trim() !== ''
        );
        if (newArr.length === 0) {
            setSaving(false);
            setModalVisible(true);
            setTextModal({
                title: 'YOU MUST DEFINE AT LEAST 1 ACTION',
                desc: 'Please write your actions.',
            });
            return false;
        }
        const PostObj = {
            userId,
            actions: newArr,
        };

        const url = `${configData.SERVER_URL}Actions/UpdateInsertActions`;
        axios({
            method: 'post',
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: PostObj,
        })
            .then((res) => {
                setCurrentTrackerTab(0);
                setSaving(false);
                SetShow(false);
                getCurrentDailyActions();
                props.getMyProgress();
            })
            .catch((err) => {
                setSaving(false);
                alert(err);
            });
    }

    return (
        <article className="tracker">
            <div className="TitleDashboardContent">
                <p className="heading-5 BlueDarkText">MY ACTION TRACKER</p>
            </div>

            <ul className="myActionTrackerTabsContainer TitleMyGoal">
                <li>
                    <button
                        className={` firstTabMyGoal ${
                            currentTrackerTab === 0 ? 'activeTabTacker' : ''
                        }`}
                        onClick={() => setCurrentTrackerTab(0)}
                        type="button"
                    >
                        TRACKER
                    </button>
                </li>
                <li>
                    <button
                        className={`${
                            currentTrackerTab === 1 ? 'activeTabTacker' : ''
                        }`}
                        onClick={() => {
                            setCurrentTrackerTab(1);
                        }}
                        type="button"
                    >
                        DAILY ACTIONS
                    </button>
                </li>
                <li>
                    {userTypeId == 4 ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={popover}
                            show={showPopover}
                            rootClose
                            delay={{ show: 0, hide: 5000 }}
                        >
                            <button
                                className={`${
                                    currentTrackerTab === 2
                                        ? 'activeTabTacker'
                                        : ''
                                }`}
                                onClick={() => {
                                    if (userTypeId != 4)
                                        setCurrentTrackerTab(2);
                                }}
                                onMouseEnter={() => {
                                    setShowPopover(true);
                                }}
                                onMouseLeave={() => {
                                    setShowPopover(false);
                                }}
                                type="button"
                            >
                                <img
                                    className="imgLockMyGoals TrackerButtonLock"
                                    alt="imgLock"
                                    src={LockSVG}
                                ></img>
                                TO-DO LIST
                            </button>
                        </OverlayTrigger>
                    ) : (
                        <button
                            className={`${
                                currentTrackerTab === 2 ? 'activeTabTacker' : ''
                            }`}
                            onClick={() => {
                                if (userTypeId != 4) setCurrentTrackerTab(2);
                            }}
                            type="button"
                        >
                            TO-DO LIST
                        </button>
                    )}
                </li>
            </ul>
            <section className="MyTrackerSection__Container">
                {saving && (
                    <section className="myTracker__loaderContainer">
                        <div className="lds-roller">
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </section>
                )}
                {currentTrackerTab === 0 && (
                    <div className="tracker__container">
                        <OolaButton
                            stroke={props3.stroke.interpolate((x) =>
                                Math.round(x)
                            )}
                            otherStroke={props3.otherStroke}
                            setShowModalFromButton={setShowModalFromButton}
                            setShowModal={setShowModal}
                            AllState={props.AllState}
                            saving={saving}
                        />
                    </div>
                )}

                {currentTrackerTab === 1 && (
                    <DailyActions
                        show={show}
                        setShowModal={setShowModal}
                        AllState={props.AllState}
                        AllStateChange={props.AllStateChange}
                        SaveActionStepsChange={SaveActionStepsChange}
                        setShowModalFromButton={setShowModalFromButton}
                        setSaving={setSaving}
                        setCurrentTrackerTab={setCurrentTrackerTab}
                        getMyProgress={props.getMyProgress}
                        getCurrentDailyActions={getCurrentDailyActions}
                        saving={saving}
                    />
                )}
                {currentTrackerTab === 2 && <ToDoList />}
            </section>

            <Modal
                show={modalVisible}
                onHide={() => hideWarningModal()}
                aria-labelledby="example-custom-modal-styling-title"
                id="modalNotificationAction"
                size="xl"
                centered
            >
                <Modal.Body>
                    <section style={{ padding: '0 10px' }}>
                        <h3
                            className="heading-2"
                            style={{ paddingTop: '30px' }}
                        >
                            {textModal.title}
                        </h3>
                        <p
                            className="paragraph-1"
                            style={{ paddingTop: '20px' }}
                        >
                            {textModal.desc}
                        </p>
                        <div
                            style={{
                                textAlign: 'center',
                                paddingTop: '30px',
                                paddingBottom: '30px',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => hideWarningModal()}
                                className="button-full-mid"
                                style={{}}
                            >
                                OK
                            </button>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
            {showWarningAdditionalCourse && (
                <ModalWarningActionSteps
                    show={showWarningAdditionalCourse.displayFailModal}
                    setShow={setShowWarningAdditionalCourse}
                    phaseNumber={showWarningAdditionalCourse.phaseNumber}
                    courseId={showWarningAdditionalCourse.courseId}
                    getCurrentDailyActions={getCurrentDailyActions}
                    setCurrentTrackerTab={setCurrentTrackerTab}
                    setRefreshCourses={props.setRefreshCourses}
                />
            )}
        </article>
    );
};

export default Tracker;
