import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './ChangingWorldSection.css';

const ChangingWorldSection = ({ enrollButtonText, goEnroll }) => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });

    const variants = {
        hidden: {
            opacity: 0,
            scale: 0.65,
            x: 400,
        },
        visible: {
            opacity: 1,
            scale: 1,
            x: 0,
        },
    };
    const buttonVariants = {
        hidden: {
            opacity: 0,
            scale: 0.65,
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1,
                delay: 1.5,
                ease: 'easeOut',
            },
        },
    };
    const circleVariants = {
        hidden: {
            opacity: 0,
            y: 300,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: 'easeOut',
            },
        },
    };

    return (
        <section className="changing__container">
            <div className="changing__clouds" />
            <motion.div
                className="changing__circle"
                alt="Oola Life Circle"
                animate={inView ? 'visible' : 'hidden'}
                variants={circleVariants}
            />
            <div className="changing__front" ref={ref}>
                <motion.div
                    className="changing__text"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 2, ease: 'easeOut' }}
                >
                    <h2 className="heading-1">
                        TOGETHER . . . <br />
                        CHANGING THE WORLD
                        <br />
                        WITH A WORD. #OOLA
                    </h2>
                    <motion.button
                        className="button-full-big"
                        onClick={()=>{window.location.href="https://myoola.oolalife.com/corporphan/enrollment/enrollmentconfigurationambassador"}}
                        type="button"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={buttonVariants}
                    >
                        {enrollButtonText}
                    </motion.button>
                </motion.div>
            </div>
        </section>
    );
};

export default ChangingWorldSection;
