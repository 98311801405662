import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../../Context/AppContext';
import requestAxios from '../../../../../util/requestAxios';
import './CoachQuestionary.css';
import CoachQuestionaryItem from './CoachQuestionaryItem/CoachQuestionaryItem';

export default ({ setShowModal, saveQuestions, showCoachingAssessment }) => {
    const [questions, setQuestions] = useState([]);
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const { checkUserSession, getData, setNewData, changeLoading } =
        useContext(AppContext);
    const us = checkUserSession();
    const { ProfileCoach } = getData();

    const getDataCoachQuestionary = async () => {
        changeLoading(true);
        const url = `Coach/GetAssessmentQuestions`;
        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });
        if (error === '') {
            setQuestions(data);
        }
        changeLoading(false);
    };

    useEffect(() => {
        getDataCoachQuestionary();
    }, []);

    const addAnswers = (answer) => {
        const newQuestionAnswers = [...questionAnswers];
        const index = newQuestionAnswers.findIndex(
            (item) => item.questionId === answer.questionId
        );
        if (index >= 0) {
            newQuestionAnswers[index] = answer;
        } else {
            newQuestionAnswers.push(answer);
        }
        setQuestionAnswers(newQuestionAnswers);
    };

    const saveQuestionary = async () => {
        const selectedAnswers = [...questionAnswers];

        if (selectedAnswers.length !== questions.length) {
            questions.forEach((item) =>
                item.options.forEach((itemx) => {
                    if (itemx.selected)
                        selectedAnswers.push({
                            questionId: item.id,
                            optionId: itemx.id,
                        });
                })
            );
        }

        if (selectedAnswers.length === questions.length) {
            const postObj = {
                userId: us.userId,
                details: [...selectedAnswers],
            };
            const url = `Coach/SaveAssessment`;
            const { error } = await requestAxios({
                url,
                token: us.token,
                method: 'POST',
                data: postObj,
            });

            if (!error) {
                window.alert('Your information has been saved');
            }
        } else {
            window.alert('Please answer all the questions');
        }
        changeLoading(false);
        if (setShowModal) setShowModal(false);
    };

    useEffect(() => {
        if (saveQuestions) {
            saveQuestionary();
        }
    }, [saveQuestions]);

    return (
        <>
            <div className="CoachQuestionary__container">
                {!showCoachingAssessment && (
                    <p className="paragraph-3">
                        Complete your Coaching Assessment below. Some of this
                        information will be part of your directory listing and
                        will be seen by potential clients. Remember, you will
                        also be able to edit this information at any time in
                        your coaching portal.
                    </p>
                )}

                <div className="CoachQuestionaryQuestionsTitle__container">
                    {!showCoachingAssessment && (
                        <h3 className="heading-4">
                            MY COACHING ASSESSMENT
                            <svg>
                                <g transform="translate(30 35)">
                                    <g
                                        className="pulseAnimation"
                                        style={{ opacity: '0.7' }}
                                    >
                                        <circle r="17" fill="#d86b59" />
                                        <circle r="15" fill="white" />
                                        <circle r="10" fill="#d86b59" />
                                    </g>
                                </g>
                            </svg>
                            <hr />
                        </h3>
                    )}
                    <div className="CoachQuestionaryQuestions__container">
                        {questions.map((item, i) => (
                            <CoachQuestionaryItem
                                key={`CoachQuestionaryQuestionsItem${i}`}
                                i={i}
                                item={item}
                                setQuestionAnswers={addAnswers}
                                setInitialQuestionAnswers={setQuestionAnswers}
                                questionAnswers={questionAnswers}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
