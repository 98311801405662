import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import useScroll from '../../../hooks/useScroll';
import './Tutorial.css';

const Tutorial = ({ setDashboardTutorial }) => {
    const [blockScroll, allowScroll] = useScroll();

    useEffect(() => {
        blockScroll();
    }, []);

    return (
        <section className="tutorial">
            <div className="tutorial__modal">
                <div className="tutorial__title">
                    <h4 className="heading-4">
                        <span>WELCOME TO YOUR</span> MY <br />
                        OOLA LIFE <span>DASHBOARD TUTORIAL</span>
                    </h4>
                    <p className="paragraph-3">
                        Watch this short video to learn more.
                    </p>
                </div>
                <div className="tutorial__video">
                    <ReactPlayer
                        url="https://vimeo.com/567670286/3fb50193f3"
                        className="react-player body__videoPlayer"
                        height="100%"
                        controls
                    />
                </div>
                <div className="tutorial__description">
                    <h4 className="heading-4">CONGRATULATIONS!</h4>
                    <p className="paragraph-3">
                        By completing Phase 1, you have unlocked two new
                        features:
                    </p>
                    <p className="paragraph-3">1. Your Customized Dashboard</p>
                    <p className="paragraph-3">2. Your Companion App</p>
                    <button
                        className="button-full-mid"
                        onClick={() => {
                            setDashboardTutorial(false);
                            allowScroll();
                        }}
                        type="button"
                    >
                        CONTINUE
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Tutorial;
