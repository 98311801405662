import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import configData from '../../../../config.json';
import iconUser from '../../../../Assets/img/userIcon.jpg';
import AppContext from '../../../../Context/AppContext';
import CoachingRoomNoteBook from './CoachingRoomNoteBook/CoachingRoomNoteBook';
import CoachVideoCall from './CoachVideoCall/CoachVideoCall';
import OolaChat from './OolaChat/OolaChat';
import { CoachCalendar } from '../../DashboardCoachTabs/CoachTabs';
import './CoachingRoomModalClient.css';
import CoachingRoomInfoComponentClient from './CoachingRoomInfoComponent/CoachingRoomInfoComponentClient';

function CoachingRoomModalCoach() {
    const { checkUserSession, setNewData, getData } = useContext(AppContext);
    const { ProfileCoach, ChatMessage } = getData();
    const [profileCoachFinal, setProfileCoachFinal] = useState({});
    const [showChat, setShowChat] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [imageHash] = useState(Date.now());
    const [showVideoCall, setShowVideoCall] = useState(false);

    const getDataCoach = async () => {
        const us = checkUserSession();
        if (ProfileCoach)
            setNewData({
                ChatMessage: {
                    GroupId: `${ProfileCoach?.currentCoach?.userId}-${us?.userId}`,
                },
            });
    };

    useEffect(() => {
        getDataCoach();
    }, [ProfileCoach]);
    return (
        <Modal
            show={ProfileCoach?.showModalCoachingRoomClient}
            aria-labelledby="CoachingRoomModal__container"
            centered
            className="CoachingRoomModal__container OolaScroll"
            dialogClassName="CoachingRoomModalDialog__container client"
            onHide={() =>
                setNewData({
                    ProfileCoach: { showModalCoachingRoomClient: false },
                })
            }
        >
            <Modal.Header>
                <button
                    className="exit"
                    onClick={() =>
                        setNewData({
                            ProfileCoach: {
                                showModalCoachingRoomClient: false,
                            },
                        })
                    }
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="CoachingRoomModalHeader__container">
                    <h1>COACHING ROOM</h1>
                    <div className="CoachProfileFinal__container client">
                        <CoachingRoomInfoComponentClient />

                        <div className="CoachProfileFinalInteractions__container ">
                            {showCalendar ? (
                                <CoachCalendar
                                    urlUserName={
                                        ProfileCoach.currentCoach
                                            .calendlyUserName
                                    }
                                    closeEvent={setShowCalendar}
                                />
                            ) : (
                                <div
                                    className={`CoachProfileFinalJoinSession__container ${
                                        showVideoCall && 'videoActive'
                                    }`}
                                >
                                    {showVideoCall ? (
                                        <CoachVideoCall
                                            currentCoach={
                                                ProfileCoach?.currentCoach
                                            }
                                        />
                                    ) : (
                                        <div>
                                            <button
                                                className={`btnJoinSessionCoachingRoom ${
                                                    !(
                                                        ProfileCoach
                                                            ?.currentClient
                                                            ?.sessionsRemaining >
                                                        0
                                                    ) && 'disabled'
                                                }`}
                                                type="button"
                                                onClick={() =>
                                                    ProfileCoach?.currentClient
                                                        ?.sessionsRemaining >
                                                        0 &&
                                                    setShowVideoCall(true)
                                                }
                                            >
                                                <i
                                                    className="fa fa-video-camera"
                                                    aria-hidden="true"
                                                />
                                                <span>JOIN SESSION</span>
                                            </button>
                                            <p>
                                                To join a meeting please launch
                                                the meeting on a Chrome or
                                                Firefox browser.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="OolaChatCommentsHeader__container client">
                        <div className={`OolaChatbtn__container  `}>
                            <button
                                className={`btnJoinSessionCoachingRoom ${
                                    !(
                                        ProfileCoach?.currentClient
                                            ?.sessionsRemaining > 0
                                    ) && 'disabled'
                                }`}
                                type="button"
                                onClick={() =>
                                    ProfileCoach?.currentClient
                                        ?.sessionsRemaining > 0 &&
                                    setShowCalendar(!showCalendar)
                                }
                            >
                                <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                />
                                <span>Schedule session</span>
                            </button>
                        </div>
                    </div>
                    <div className="ChatNotebookTabButtons__container">
                        <button
                            className={`NotebookCoachingButton ${
                                !showChat && 'active'
                            }`}
                            onClick={() => {
                                setShowChat(false);
                            }}
                            type="button"
                        >
                            NOTEBOOK
                        </button>
                        <button
                            className={`ChatCoachingButton ${
                                showChat && 'active'
                            }`}
                            onClick={() => {
                                var newData = { ...ProfileCoach };
                                newData.currentCoach.pendingMessages = 0;
                                setShowChat(true);
                                setNewData(newData);
                            }}
                            type="button"
                        >
                            {ProfileCoach?.currentCoach?.pendingMessages >
                                0 && (
                                <div className="iconPendingMessage">{`${ProfileCoach.currentCoach.pendingMessages}`}</div>
                            )}
                            CHAT
                        </button>
                    </div>

                    {showChat
                        ? ChatMessage?.GroupId && (
                              <OolaChat setShowVideoCall={setShowVideoCall} />
                          )
                        : ChatMessage?.GroupId && (
                              <CoachingRoomNoteBook
                                  setShowVideoCall={setShowVideoCall}
                              />
                          )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CoachingRoomModalCoach;
