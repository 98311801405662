import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider, Typography, Tab, Tabs } from '@material-ui/core';
import MindBodyWellnessAssessmentQuestion from './Components/MindBodyWellnessAssessmentQuestion';
import MindBodyWellnessAssessmentSubmit from './Components/MindBodyWellnessAssessmentSubmit';
import AppContext from '../../../Context/AppContext';
import Network from '../../Network/Network';
import requestAxios from '../../../util/requestAxios';
import WellnessAssessmentResults from './Components/WellnessAssessmentResults/WellnessAssessmentResults';
import './MindBodyWellnessAssessment.css';

const MindBodyWellnessAssessment = () => {
    const [_index, ChangeIndex] = useState(0);
    const NQuestiosn = [1, 2, 3, 4, 5, 6, 7];
    const [Assesments, ChangeAssesments] = useState({
        AssesmentsQuestions: [],
    });
    const [resultsDataScreen, setResultsDataScreen] = useState(null);
    const {  changeLoading } = useContext(AppContext);

    useEffect(async () => {
        changeLoading(true);
        const url = `FreeAssessment/GetQuestions/1`;
        const { error, data } = await requestAxios({
            url: url,
        });

        if (error == '') {
            ChangeAssesments({
                AssesmentsQuestions: data,
            });
        } else {
            console.log(error);
        }
        changeLoading(false);
    }, [1]);
    const useStyles = makeStyles({
        root: {
            height: 300,
        },
    });

    const PrettoSlider = withStyles({
        root: {
            color: '#78ccf6',
            height: 8,
            width: 90,
            orientation: 'vertical',
        },
        thumb: {
            height: 15,
            width: 15,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            orientation: 'vertical',
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
        rail: {
            height: 8,
            width: 8,
            borderRadius: 0,
        },
    })(Slider);

    const classes = useStyles();

    const [OptionSelected, ChangeOptionSelected] = useState([]);
    const marksMyPersonalizedPath = () => {
        const marks = [];
        Assesments.AssesmentsQuestions.forEach((element, index) => {
            const qLength = Assesments.AssesmentsQuestions.length;
            const item = {
                value: index + 1,
                label: (
                    <div
                        data-index={index}
                        className={
                            OptionSelected[qLength - index] != null ||
                            qLength - index - 1 <= OptionSelected.length
                                ? 'lblEnableAssesment'
                                : ''
                        }
                    >
                        Q
                        {qLength - index < 10
                            ? `0${qLength - index}`
                            : qLength - index}
                    </div>
                ),
            };
            marks.push(item);
        });

        return marks;
    };

    function selectQuestion(value) {
        if (value.target.dataset.index != null) {
            setrangeValues({
                begin: Assesments.questions.length,
                end: Number(value.target.dataset.index) + 1,
            });
            const indexx =
                Assesments.questions.length -
                1 -
                Number(value.target.dataset.index);
            ChangeIndex(indexx);
        } else {
            setTimeout(() => {
                const parent =
                    document.getElementsByClassName('MuiSlider-root')[0];
                const valuex = parent
                    .querySelectorAll("input[type='hidden']")[0]
                    .value.split(',')[0];

                setrangeValues({
                    begin: Assesments.questions.length,
                    end: Number(valuex),
                });
                ChangeIndex(Assesments.questions.length - Number(valuex));
            }, 50);
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        ChangeIndex(newValue);
    };
    function createMarkup(value) {
        return {
            __html: value,
        };
    }
    const setValueQuestion = (index, value) => {
        let assessmentAnswer = [...Assesments.AssesmentsQuestions];
        assessmentAnswer[index].score = value;
        ChangeAssesments({
            AssesmentsQuestions: assessmentAnswer,
        });
        if (_index < 13) {
            setTimeout(() => {
                ChangeIndex(_index + 1);
            }, 500);
        }
    };

    const submitAssessment = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ambassador = urlParams.get('ambassador');

        const PostData = {
            user: { ...Assesments.infoUser, AmbassadorsName: ambassador },
            questions: Assesments.AssesmentsQuestions.map((item) => ({
                questionId: item.id,
                score: item.score,
            })),
        };
        const url = `FreeAssessment/SaveResponse`;
        const { error, data } = await requestAxios({
            url,
            method: 'Post',
            data: PostData,
            // token,
        });
        if (data) {
            setResultsDataScreen(data);
        }

        if (error == '') {
            ChangeIndex(_index + 1);
        } else {
            console.log(error);
        }
    };
    const MindBodyWellnessAssessmentR = (
        <>
            {!resultsDataScreen ? (
                <div style={{ minHeight: '90vh', backgroundColor: '#f5f5f5' }}>
                    <section className="assesment__container">
                        <div className="assesment__wrapper">
                            <div
                                className="AssesmentHeader"
                                dangerouslySetInnerHTML={createMarkup(
                                    Assesments.title
                                )}
                            />
                            <div
                                className="AssesmenDescription"
                                dangerouslySetInnerHTML={createMarkup(
                                    Assesments.congratsMessage
                                )}
                            />
                        </div>
                    </section>
                    <section className="assesment__container MindBodyWellnessAssessment">
                        <h1>Stress assessment</h1>
                        <div
                            className={`${
                                _index < 14 &&
                                'assesment__wrapper_questions MindBodyWellnessAssessment'
                            } `}
                        >
                            {_index < 14 && (
                                <div className="hiddenMobileQuestion">
                                    <>
                                        <Typography
                                            id="vertical-slider"
                                            gutterBottom
                                        />
                                        <div className={classes.root}>
                                            <PrettoSlider
                                                orientation="vertical"
                                                aria-labelledby="vertical-slider"
                                                marks={marksMyPersonalizedPath()}
                                                step={1}
                                                min={1}
                                                max={
                                                    marksMyPersonalizedPath()
                                                        .length
                                                }
                                                value={[
                                                    marksMyPersonalizedPath()
                                                        .length,
                                                    marksMyPersonalizedPath()
                                                        .length - _index,
                                                ]}
                                                onChange={(input) =>
                                                    selectQuestion(input)
                                                }
                                                style={{
                                                    height: '65vh',
                                                    width: '0.5rem',
                                                }}
                                            />
                                        </div>
                                    </>
                                </div>
                            )}

                            <div className="MindBodyWellnessAssessmentQuestion">
                                {_index < 14 && (
                                    <h3 className="hiddenMobileQuestion">
                                        Question {_index + 1}/
                                        {Assesments.AssesmentsQuestions.length}
                                    </h3>
                                )}
                                {
                                    // ------------------------------------------------Mobile ------------------------------------------
                                }
                                {_index < 14 && (
                                    <div className={`visibleMobileQuestion`}>
                                        <div>
                                            <>
                                                <Typography
                                                    id="horizontal-slider"
                                                    gutterBottom
                                                    style={{
                                                        height: '10px',
                                                        width:
                                                            window.innerWidth *
                                                            0.8,
                                                        marginLeft:
                                                            window.innerWidth *
                                                            0.05,
                                                    }}
                                                />
                                                <div className="TabSlider___container MindBodyWellnessAssessmentQuestion">
                                                    <Tabs
                                                        value={_index}
                                                        onChange={handleChange}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"
                                                    >
                                                        {Assesments.AssesmentsQuestions.map(
                                                            (item, i) => {
                                                                let newIndex =
                                                                    i + 1;
                                                                return (
                                                                    <Tab
                                                                        key={`tab${newIndex}`}
                                                                        className={
                                                                            item.score ||
                                                                            i ==
                                                                                0
                                                                                ? 'questionTitleMobile__text'
                                                                                : 'questionTitleMobileDisable__text'
                                                                        }
                                                                        label={`Q${
                                                                            newIndex <
                                                                            10
                                                                                ? `0${newIndex}`
                                                                                : newIndex
                                                                        }`}
                                                                        disabled={
                                                                            !newIndex.score
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        {...a11yProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </Tabs>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                )}
                                <SwipeableViews
                                    className="SwipeableViews_Step12 MindBodyWellnessAssessment"
                                    index={_index}
                                    onChangeIndex={ChangeIndex}
                                    disabled
                                >
                                    {Assesments.AssesmentsQuestions.map(
                                        (item, i) => (
                                            <MindBodyWellnessAssessmentQuestion
                                                key={`MindBodyWellnessAssessmentQuestionItem${item.id}`}
                                                item={item}
                                                i={i}
                                                _index={_index}
                                                setValueQuestion={
                                                    setValueQuestion
                                                }
                                                ChangeIndex={ChangeIndex}
                                            />
                                        )
                                    )}
                                    <MindBodyWellnessAssessmentSubmit
                                        ChangeAssesments={ChangeAssesments}
                                        Assesments={Assesments}
                                        submitAssessment={submitAssessment}
                                    />
                                </SwipeableViews>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <WellnessAssessmentResults
                    assessmentResults={resultsDataScreen}
                />
            )}
        </>
    );
    return MindBodyWellnessAssessmentR;
};

export default MindBodyWellnessAssessment;
