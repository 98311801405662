import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../../Context/AppContext';
import configData from '../../../../config.json';

const Questions = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { checkUserSession } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const history = useHistory();
    const [enable, setEnable] = useState(true);
    const [Assesments, ChangeAssesments] = useState({
        AssesmentsQuestions: [],
    });

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const url = `${
            configData.SERVER_URL
        }ELearning/GetAssessmentQuestions/${urlParams.get('AssessmentType')}`;

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                ChangeAssesments({ AssesmentsQuestions: result.data.questions,...result.data });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [1]);

    function SelectOption(QuestionId, OptionId) {
        const selOption = props.OptionSelected.find(
            (x) => x.questionId === QuestionId
        );
        if (selOption == null) {
            props.OptionSelected.push({
                OptionId,
                questionId: QuestionId,
            });
        } else {
            selOption.OptionId = OptionId;
        }
        setEnable(false);
        setTimeout(() => {
            setEnable(true);
            props.Change_Index(props._index + 1);
        }, 500);
        props.ChangeOptionSelected(props.OptionSelected);
        forceUpdate();
    }

    function completeAssesment() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const url = `${configData.SERVER_URL}ELearning`;
        const PostObj = {
            userId,
            AssessmentType: Number(urlParams.get('AssessmentType')),
            AssesmentDetails: props.OptionSelected,
        };
        axios({
            method: 'post',
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: PostObj,
        })
            .then(() => {
                const urlCourse = `${configData.SERVER_URL}ELearning/CreateELearningModules`;
                const PostObjCourse = {
                    userId,
                    AssessmentType: Number(urlParams.get('AssessmentType')),
                };
                axios({
                    method: 'post',
                    url: urlCourse,
                    headers: { Authorization: `Bearer ${token}` },
                    data: PostObjCourse,
                })
                    .then(() => {
                        history.push('/phase2welcome');
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const QuestionsR = (
        <div
            style={{
                height: '100%',
                marginLeft: props.mobile ? window.innerWidth * 0.1 : '1rem',
                pointerEvents: !enable && 'none',
            }}
        >
            <SwipeableViews disabled index={props._index}>
                {Assesments.AssesmentsQuestions.map((item, i) => (
                    <div
                        className="QuestionContainer scrollMyGoals"
                        key={`QA${item.id}`}
                    >
                        <div className="QuestionAssesmentHeader">
                            {item.text}
                        </div>
                        <div
                            style={{ paddingTop: '0.5rem' }}
                            className="question_wrapper"
                        >
                            {item.options.map((itemx, ix) => (
                                <div
                                    style={{ paddingTop: '1rem' }}
                                    key={`QAO${itemx.id}`}
                                >
                                    <label className="containerOption">
                                        {itemx.text}
                                        <input
                                            type="radio"
                                            name={`Question${i}`}
                                            id={`Question${i}${ix}`}
                                            onClick={() =>
                                                SelectOption(item.id, itemx.id)
                                            }
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </SwipeableViews>
            <div className="btnChange BtnContainerAssesment">
                {props._index === 0 ? (
                    <div className="botons btnPrevious d-flex  " />
                ) : (
                    <button
                        type="button"
                        className="btnAssesments botons btnPrevious d-flex  "
                        onClick={() => {
                            props.Change_Index(props._index - 1);
                        }}
                    >
                        {'< PREVIOUS'}
                    </button>
                )}
                {props.OptionSelected[13] != null && props._index === 13 ? (
                    <button
                        type="button"
                        className="btnCompleteAssesments"
                        onClick={() => completeAssesment()}
                        style={{
                            cursor: 'pointer',
                            marginLeft: window.innerWidth * 0.2,
                        }}
                    >
                        COMPLETE
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btnAssesments botons btnNextAssesment text-right "
                        onClick={
                            props.CurrQ == null
                                ? null
                                : () => props.Change_Index(props._index + 1)
                        }
                        style={{
                            cursor: props.CurrQ == null ? 'initial' : 'pointer',
                            marginLeft: window.innerWidth * 0.4,
                        }}
                    >
                        {'NEXT >'}
                    </button>
                )}
            </div>
        </div>
    );
    return QuestionsR;
};

export default Questions;
