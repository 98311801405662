import React, { useState, useEffect } from 'react'
import Confett from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size'

const Confetti = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
function drawStar(ctx) {
  const min = Math.ceil(4);
  const max = Math.floor(6);
  const num = Math.floor(Math.random() * (max - min + 1)) + min;
  const numPoints = this.numPoints || num
  ctx.beginPath()

  this.numPoints = numPoints
  ctx.lineWidth = props.mobile===true?"5":"7"
  switch (this.numPoints) {
    case 4:
      ctx.arc(0, 10, props.mobile===true?25:65, 0, Math.PI * 2, false); // full

      break;
    case 5:
      ctx.arc(0, 10,  props.mobile===true?10:50, 0, Math.PI / 2, false);  // quarter
      break;
    case 6:
      ctx.arc(0, 10,  props.mobile===true?10:50, 0, Math.PI, false);  // middle
      break;
    default:
      ctx.quadraticCurveTo(1, 10, 15, 9);
      break;

  }
  ctx.stroke();
  ctx.closePath()
}

  const [width, height] = useWindowSize()
 
  const [show, changeShow] = useState(0);
 


  useEffect(() => {
       setTimeout(() => { 
              changeShow(1)
              setTimeout(() => { 
                changeShow(0)
       setTimeout(() => { 

                forceUpdate();
        },500)
      },500)
      },props.delay==null?2500:props.delay)
  },[10000]);


  return (
      <Confett
        width={width}
        height={height}
        numberOfPieces={show === 1 ? 700 : 0} 
        colors={["#78CCF6", "#2C414C"]}
        confettiSource={{
          w: 30,
          h: 30,
          x:props.mobile===true?width*.5:width*.73
          ,y:props.mobile===true?(height*.6):(height*.3)
        }}
        initialVelocityX={{ min: -50, max: 50 }}
        initialVelocityY={{ min: -50, max: 50 }}
        drawShape={drawStar}
        style={{zIndex:"201",inset: "auto"}}
      />
      
  )
}
export default Confetti