import React,{useState} from 'react';
import Wheel from './Wheel';
import RecomendationSection from './RecomendationSection';
import './SelectGoalSection.css';
import ModalError from '../../../Components/Step2/SelectGoals/TemplateGoals/ModalError'

const SelectGoalSection = ({ AllState,saveRecomendedGoals,mobile }) => {
    const [showModalError, showModalErrorChange] = useState(false);
    return(
    <section className="selectGoal__container">
        <div className="selectGoal__wrapper">
            <div>
                <Wheel AllState={AllState} hideNav={false} />
            </div>
            <div>
                <RecomendationSection AllState={AllState} hideNav={false}  setShowError={showModalErrorChange} show={showModalError} saveRecomendedGoals={saveRecomendedGoals} mobile={mobile}/>
            </div>
        </div>
        <ModalError ModalErrorTitle="Error connection"  ModalErrorDesc="Please verify your internet access, please try again"  setShowError={showModalErrorChange} show={showModalError}/>
    </section>)
};

export default SelectGoalSection;
