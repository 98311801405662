import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import CountUp from 'react-countup';
import CloseButton from '../../../../General/CloseButton/CloseButton';
import './WellnessAssessmentResults.css';

import Speedometer from '../../../../../Assets/img/Shopify/speedometer.png';

export default ({ assessmentResults }) => {
    const [totalResult, setTotalResult] = useState(0);

    useEffect(() => {
        const result = Math.round(
            (10 / 9) * (assessmentResults?.score - 50 / 3)
        );
        let resultDeg = ((result + 2) * 180) / 100;

        if (resultDeg > 180) resultDeg = 180;
        if (resultDeg < 0) resultDeg = 0;
        setTotalResult(resultDeg);
    }, []);

    const props = useSpring({
        from: { transform: `rotate(0deg)` },
        to: { transform: `rotate(${totalResult}deg)` },
        config: {
            duration: 3000,
        },
    });

    const buttonRedirectClick = () => {
        window.top.location.href = assessmentResults?.shopLink;
    };

    return (
        <section className="productTestEnd__container">
            <div className="productTestEnd__header" />
            <div className="productTestEnd_body_container">
                <h1 className="heading-1">Stress Report</h1>
                <figure className="productTestEnd_body_speedometer">
                    <img src={Speedometer} alt="speedometer" />
                    <animated.div className="needle" style={props} />
                    <h5>
                        <CountUp
                            className="countContainer__counter"
                            end={assessmentResults?.score}
                            duration={4}
                        />
                    </h5>
                </figure>
                <div className="productTestEnd_body_info">
                    <h5>RECOMMENDATIONS:</h5>
                    <br />
                    <p>
                        <div dangerouslySetInnerHTML={{ __html: assessmentResults?.recommendation1 }} />
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: assessmentResults?.recommendation2 }} />
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: assessmentResults?.recommendation3 }} />
                    </p>
                </div>
                <button
                    type="button"
                    className="button-full-small"
                    onClick={buttonRedirectClick}
                >
                    SHOP
                </button>
            </div>
        </section>
    );
};
