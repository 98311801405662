import React from 'react';
import './Stories.css';
import configData from '../../../../config.json';

const DescriptonModuleVip = ({ moduleInfo, buyCourse }) => {
    const ModuleInfo = moduleInfo[2] != null ? moduleInfo[2] : {};

    function createMarkup(value) {
        return {
            __html: `${ModuleInfo[value]}`,
        };
    }
    function createMarkupText(value) {
        return {
            __html: `${[value]}`,
        };
    }
    const DescriptonModuleVipR = moduleInfo != {} && (
        <section className="Stories__container">
            <div
                className="Stories__title"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup('htmlTitle')}
            />

            {ModuleInfo.testimonials != null &&
                ModuleInfo.testimonials.map((item) => (
                    <div
                        className="Stories__item"
                        key={`Story${item.photoUrl}`}
                    >
                        <div className="Stories__itemImage">
                            <div
                                className="StoriesItem__Image"
                                style={{
                                    backgroundImage: `url('${
                                        configData.SERVER_URL_IMG +
                                        item.photoUrl
                                    }')`,
                                }}
                            />
                        </div>
                        <div className="Stories__itemText">
                            <p
                                className="StoriesItem__Comment"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={createMarkupText(
                                    item.text
                                )}
                            />
                            <p className="StoriesItem__Name"> {item.name} </p>
                        </div>
                    </div>
                ))}
            <div className="btnEnrollStories__container">
                <button
                    className="button-full-mid "
                    type="button"
                    onClick={() => buyCourse()}
                >
                    ENROLL TODAY
                </button>
            </div>
        </section>
    );
    return DescriptonModuleVipR;
};

export default DescriptonModuleVip;
