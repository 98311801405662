import React, { useContext } from 'react';
import axios from 'axios';
import configData from '../../../../config.json';
import AppContext from '../../../../Context/AppContext';
import './ListFs.css';

const ListFs = ({ item, i, index, lastF }) => {
    const { checkFreeUserSession,updateFreeTestData } = useContext(AppContext);
    const FreeTestData=checkFreeUserSession();
    const changeIndex = (F) => {
        let newData = { ...FreeTestData };

        newData.index = F;
        newData.currentQuestion = 0;

        updateFreeTestData(newData);
    };
    function statIL(Actindex, i, obj) {
        // AD=Actual Done
        // AP=Actual In Progress
        // D=Done
        // P=Progress
        let status = '';
        if (Actindex === i) {
            const objcurr = item.questions.find(
                (x) => x.score === 0 || x.score == null || x.score === undefined
            );
            if (objcurr == null || objcurr === undefined) {
                status = 'AD';
            } else {
                status = 'AP';
            }
        } else if (i < Actindex) {
            status = 'D';
        } else {
            const objcurr = item.questions.find(
                (x) => x.score === 0 || x.score == null
            );
            if (objcurr == null) {
                status = 'D';
            } else {
                status = 'P';
            }
        }
        return status;
    }
    function localSlice(rotate, color) {
        return (
            <g transform={`rotate(${rotate},220,220)`}>
                <g mask="url(#wedge-mask)">
                    <circle
                        className="Slice"
                        cx="220"
                        cy="220"
                        r="100"
                        fill={color}
                        style={{ transform: 'scale(1)' }}
                    >
                        {' '}
                    </circle>
                </g>
            </g>
        );
    }

    function getILStyle() {
        return (
            <svg
                // ref={wheelRef}
                className="ChartPieChunk"
                // width="440px"
                // height="440px"
                viewBox="70 70 300 300"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    transform: 'rotate(-51.42deg) scale(.8)',
                }}
            >
                {index == item.id - 1 && (
                    <g>
                        <defs>
                            <g id="overlay">
                                <rect
                                    x="219"
                                    y="100"
                                    height="50"
                                    width="15"
                                    fill="#FFF"
                                />
                            </g>
                        </defs>
                        <mask id="wedge-mask" fill="white">
                            <path
                                transform="translate(20, 20)"
                                d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                                fillRule="nonzero"
                            />
                        </mask>
                        <circle
                            cx="220"
                            cy="220"
                            r="101"
                            fill="#8E8E8E"
                            strokeWidth="1"
                        />

                        <circle
                            cx="220"
                            cy="220"
                            r="101"
                            fill="#8E8E8E"
                            strokeWidth="1"
                        />

                        {item.id >= 1 ? localSlice('51.42', '#15C285') : ''}
                        {item.id >= 2 ? localSlice('102.84', '#ABD86D') : ''}
                        {item.id >= 3 ? localSlice('154.26', '#FBBF53') : ''}
                        {item.id >= 4 ? localSlice('205.68', '#F26636') : ''}
                        {item.id >= 5 ? localSlice('257.1', '#EC0F47') : ''}
                        {item.id >= 6 ? localSlice('308.52', '#700460') : ''}
                        {item.id >= 7 ? localSlice('359.94', '#022B7A') : ''}

                        <use
                            xlinkHref="#overlay"
                            transform="rotate(25.71, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(77.13, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(128.55, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(179.97, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(231.39, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(282.81, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlay"
                            transform="rotate(334.23, 220, 220)"
                        />

                        <circle
                            cx="220"
                            cy="220"
                            r="80"
                            fill="#FFF"
                            strokeWidth="1"
                        />
                    </g>
                )}
            </svg>
        );
    }

    const opacityElement = () => {
        let value = '';
        if (lastF < i) {
            value = '0.4';
        } else {
            value = '1';
        }
        return value;
    };

    const pointerElement = () => {
        let value = '';
        if (lastF < i) value = 'none';
        else value = '';
        return value;
    };

    return (
        <li
            // ref={i === 1 && i}
            key={i}
            className="element__list"
            xs={12}
            data-key={i}
            onClick={() => changeIndex(i)}
            // onTouchEndCapture={props.handleChange}
            style={{
                cursor: 'pointer',
                color: item.color,
                pointerEvents: pointerElement(i),
                opacity: opacityElement(i),
            }}
        >
            <div className="chartPieChunk__container">{getILStyle()}</div>
            {/* {i === props.AllState.index &&
                refs[i].scrollIntoView({ behavior: 'smooth' })} */}

            <div
                className={`listTitle__container ${
                    i === index && `borderBottom${index + 1}`
                }`}
            >
                <h3>{item.name.toUpperCase()}</h3>
            </div>
        </li>
    );
};

export default ListFs;
