import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import HeaderDashboard from '../Header/HeaderDashboard';
import VideoModuleVip from './sections/VideoModuleVip';
import Enroll from './sections/Enroll';
import DescriptonModuleVip from './sections/DescriptionModuleVip';
import Stories from './sections/Stories';
import FooterCopyright from '../../Common/FooterCopyright/FooterCopyright';
import requestAxios from '../../../util/requestAxios'; 


const ModuleVip = () => {
    const { checkUserSession } = useContext(AppContext);
    const { token ,userId,userTypeId} = checkUserSession();
    const [moduleInfo, setModuleInfo] = useState({});
    const [moduleInfoBuy, setModuleInfoBuy] = useState({});
    const urlParams = new URLSearchParams(window.location.search);
    const idparam = urlParams.get('id');
    function getModuleInfo() {
        const url = `${configData.SERVER_URL}AdditionalCourse/GetCourseSalesContent/${idparam}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                setModuleInfo(result.data);
            })
            .catch((error) => {
                console.log(error);
            });
        const urlBuy = `${configData.SERVER_URL}AdditionalCourse/GetCourses`;
        axios
            .get(urlBuy, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((result) => {
                const arrItems = result.data;
                setModuleInfoBuy(arrItems.find((x) => x.id == idparam));
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function buyCourse() {
        
        const { error, data } = await requestAxios({
            url: `Login/GetSilentToken/${userId}`,
            token,
        });
        window.location.replace(
            userTypeId===4?`${configData.SANDBOXSERVER}${moduleInfoBuy.exigoItemCode}`
            :`${configData.ExigoSilentToken}sso?token=${encodeURIComponent(data.silentToken)}&itemCode=${moduleInfoBuy.exigoItemCode}`,
            '_blank'
        );
    }

    useEffect(() => {
        getModuleInfo();
    }, [1]);

    const ModuleVipR = (
        <div style={{ height: '100%', backgroundColor: '#f5f5f5' }}>
            <div style={{ backgroundColor: 'white' }}>
                <div>
                    <HeaderDashboard />
                </div>
            </div>
            <VideoModuleVip moduleInfo={moduleInfo} />
            <Enroll moduleInfo={moduleInfo} buyCourse={buyCourse} />
            <DescriptonModuleVip moduleInfo={moduleInfo} />
            <Stories moduleInfo={moduleInfo} buyCourse={buyCourse} />
            <FooterCopyright moduleInfo={moduleInfo} />
        </div>
    );
    return ModuleVipR;
};

export default ModuleVip;
