import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';
import config from '../../../config.json';

import './ShareOptionsModal.css';

const ShareOptionsModal = ({ setActiveShare, imageUrl, text, webAlias }) => {
    const locationURL = webAlias
        ? `https://myoola.oolalife.com/${webAlias}`
        : 'https://www.oolalife.com';
    const configImageUrl = config.SERVER_URL_IMG;

    return (
        <div>
            <Modal
                show
                onHide={() => setActiveShare(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
                className="shareOptionsModal"
            >
                <Modal.Header className="shareOptionsModal__header">
                    <button type="button" onClick={() => setActiveShare(false)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="shareOptionsModal__body">
                        <div className="shareOptionsModal__bodyTitle">
                            <h3 className="heading-3">SHARE</h3>
                            <p className="paragraph-3">
                                Click to share on your favorite Social Network.
                            </p>
                        </div>
                        <div className="shareOptionsModal__socialButtons">
                            <FacebookShareButton
                                url={
                                    imageUrl
                                        ? configImageUrl + imageUrl
                                        : locationURL
                                }
                                quote={`${text} ${locationURL}`}
                                hashtag="#OolaLife"
                            >
                                <FacebookIcon round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={locationURL}
                                title={text}
                                hashtags={['OolaLife']}
                            >
                                <TwitterIcon round />
                            </TwitterShareButton>
                            <EmailShareButton url={locationURL} body={text}>
                                <EmailIcon round />
                            </EmailShareButton>
                            <WhatsappShareButton url={locationURL} title={text}>
                                <WhatsappIcon round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

ShareOptionsModal.propTypes = {
    setActiveShare: PropTypes.func.isRequired,
    imageUrl: PropTypes.string,
    text: PropTypes.string.isRequired,
};

ShareOptionsModal.defaultProps = {
    imageUrl: '',
};

export default ShareOptionsModal;
