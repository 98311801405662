import React, { useState } from 'react';
import VideoPop from 'react-video-pop';
import Scene from '../../../Assets/Videos/OolaLifeLearnMore.mp4';
import './VideoSection.css';

const VideoSection = (props) => {
    const [showVideo, setShowVideo] = useState(false);
    const [playFirst, playFirstChange] = useState(false);

    function changeShow(x) {
        setShowVideo(x);
        playFirstChange(true);
    }

    return (
        <section className="videoSection">
            <div
                className="video__container"
                style={{
                    display: showVideo ? 'block' : 'none',
                }}
            >
                {playFirst && (
                    <VideoPop
                        className="divx"
                        Src={showVideo ? Scene : ''}
                        mute={false}
                        autoplay
                        root="video-root"
                        ratio={{ w: 16, h: 9 }}
                    />
                )}
                <button onClick={() => changeShow(false)} type="button">
                    X
                </button>
            </div>

            <article className="videoSection__info">
                <div className="info__wrapper">
                    <h1 className="display-2 info__text">
                        <span className="heading-3">Start living your</span>
                        <br /> OOLA LIFE TODAY
                    </h1>
                    <div className="info__buttons">
                        <button
                            className="button-full-big"
                            onClick={() => props.goExigo()}
                            type="button"
                        >
                            ENROLL TODAY
                        </button>
                        <button
                            className="button-empty-big"
                            onClick={() => changeShow(true)}
                            type="button"
                        >
                            {showVideo ? 'STOP VIDEO' : 'PLAY VIDEO'}
                        </button>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default VideoSection;
