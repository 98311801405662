import React from 'react';
import { keyframes } from 'styled-components';

const SlicesStep2 = (props) => {
    function sum(total) {
        total *= 10;
        total =
            total === 0 || total == null
                ? 0
                : total * 0.005 + (total >= 70 ? 0.55 : 0.44);
        return String(isNaN(total) ? 0 : total);
    }
    function addZeroes(num, places) {
        const dec = String(num).split('.')[1];
        const len = dec && dec.length > places ? dec.length : places;
        return Number(num).toFixed(len);
    }
    const positionLables = [
        { x: '24.5%', y: '58%' },
        { x: '-30%', y: '56%' },
        { x: '-62%', y: '13%' },
        { x: '-49%', y: '-39.5%' },
        { x: '0%', y: '-61%' },
        { x: '48%', y: '-36%' },
        { x: '59%', y: '17%' },
    ];
    const spin = keyframes`
    0% { r:0; }
    40% { r:0; }
    100% { r: ${sum(props.Fs.score) * 170}; }
`;

    var styles = {
        animation: `${spin} 2s`,
    };
    const slices = (
        <g
            key={props.Fs.id}
            transform={
                props.Fs.id === 1
                    ? 'rotate(81.42,220,220)'
                    : `rotate(${132.84 + 51.42 * (props.index - 1)},220,220)`
            }
        >
            <g mask="url(#wedge-maskFreeTest)">
                <circle
                    className="Slice "
                    cx="220"
                    cy="220"
                    r="150"
                    fill={'#F8F8F8'}
                    style={{ transform: `scale(1.2)` }}
                />
                {/* {props.allColors === true ? ( */}
                <circle
                    className="Slice "
                    cx="220"
                    cy="220"
                    r={sum(props.Fs.score) * 170}
                    fill={props.Fs.color}
                    style={styles}
                    // style={{
                    //     transform: `scale(${sum(
                    //         props.Fs.score
                    //     )})`,
                    // }}
                >
                    {' '}
                </circle>
                {/* ) : (
                    <circle
                        className="Slice "
                        cx="220"
                        cy="220"
                        r="170"
                        fill={props.Fs.color}
                        style={{
                            transform: `scale(${sum(props.Fs.score)})`,
                        }}
                    ></circle>
                )} */}
                <text
                    className="titleQuestion"
                    x={positionLables[props.Fs.id - 1].x}
                    y={positionLables[props.Fs.id - 1].y}
                    fill="white"
                    fontSize="15"
                    strokeWidth="1px"
                    style={{
                        transform:
                            props.Fs.id === 1
                                ? 'rotate(-30deg)'
                                : `rotate(${329.42 - 51.42 * props.index}deg)`,
                    }}
                >
                    {props.Fs.score != null ? addZeroes(props.Fs.score, 1) : ''}
                </text>
            </g>
        </g>
    );
    return slices;
};
export default SlicesStep2;
