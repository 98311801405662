import React from 'react';
import BodyMind from '../../../Assets/img/Dashboard/BodyMind.png';
import './StartYourJourneySection.css';

const StartYourJourneySection = () => {
    return (
        <div className="familyBackground__container">
            <div className="familyBackgroundText__container">
                <div className="familyBackgroundText">
                    <h1>
                        Essential <br />
                        <span>mind</span>
                        {" "}And body <br />
                        nutrition
                        <hr />
                        <label>with Botanical Nootropics and Adaptogens</label>
                    </h1>
                    <button className="button-full-mid" onClick={()=>window.open("https://myoola.oolalife.com/corporphan/productDetail/805")}>SHOP NOW</button>
                </div>
            </div>
            <div className='familyBackgroundTextImg' />
        </div>
    );
};

export default StartYourJourneySection;
