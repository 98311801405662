import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Goals.css"
import "./PickedGoals.css"
import '../OolaSevenSection.css';

const SelectedSevenGoal = (props) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    
    const setStartDate = (e) => {
        
        const AllState = { ...props.AllState}
        const goalIndex = AllState.SelectedGoals.findIndex(x => x.areaId === props.AllState.CurrentF && x.recomendationIndex === props.GoalIndex)
        if (AllState.SelectedGoals[goalIndex] != null) {
            AllState.SelectedGoals[goalIndex].date = e;
            props.AllState.changeData(AllState)
        }
        else {
            //    setStartDate(new Date())
            const newGoal = {
                recomendationIndex: AllState.SelectedGoals.length,
                text: "",
                areaId: props.AllState.CurrentF,
                date: e
            }
            AllState.SelectedGoals.push(newGoal);
            props.showModalEvent(false)
            props.AllState.changeData(AllState)
        }

        return true;
         
    }
    let SelectedSevenGoalR =
    <div onClick={() => props.selectGoal(props.indexF, props.number, props.itemF.selected,props.itemF.idL)}  
        className={`Goal__containerTop7 ${props.itemF.selected===false?"NoPickedGoalTop7 ":"PickedGoalTop7"}`} 
        style={{ backgroundColor: props.AllState.SelectedGoalsGroup[props.number].Fs.color+(props.itemF.selected===false?"80":""), 
        marginLeft: "0px", marginRight: "0px", width: "100%", marginTop:"3px",cursor:"pointer" }}>
    
        <div xs={1} className={`${props.itemF.selected===false?"GoalNumberTop7":"GoalNumberSelectedTop7"}`}  
            style={{ height: "100%", paddingTop: "1.4rem", alignItems:'flex-start' , fontWeight:"bold" }}>
            {props.indexF + 1}
        </div>
        <div xs={7}>
            <input readOnly className={`${props.itemF.selected===false?"GoalNoSelected7Top":"GoalSelected7Top"}`} placeholder="Define your  goal" value={props.itemF.text}  style={{ fontSize: "1.5rem" }}/>
        </div>
        <div xs={1} style={{ height: "100%", paddingTop: " calc(.8vw)" }}>
            <DatePicker
                dateFormat="MMMM dd, yyyy"
                onChange={date => setStartDate(date)}
                withPortal 
                className={`datePickerGoal7Top ${props.itemF.selected===false?"NoPickedGoalTop":""}`}
                placeholderText="Complete by: mm/dd/yyyy"
                minDate={new Date()}
                selected={props.itemF.date}
                readOnly
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <div>
                        <div
                            className="calendarHeaderTop7"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%"
                            }}>
                            COMPLETE BY:
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingTop: "10px"
                            }}
                        >
                            <button type="button" className="btnCalendar" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                            </button>
                            <div className="lblMonth" style={{ fontSize: "2rem"}}>
                                <input readOnly className={`${props.itemF.selected===false?"GoalNoSelected7":"GoalSelected7"}`} placeholder="Define your  goal" value={months[new Date(date).getMonth()]}  style={{ fontSize: "2rem" }}/>
                                
                            </div>
                            <button type="button" className="btnCalendar" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    </div>

    return SelectedSevenGoalR;
}
export default SelectedSevenGoal;
