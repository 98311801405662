import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import AppContext from '../../../Context/AppContext';
import requestAxios from '../../../util/requestAxios';
import CoachQuestionary from '../CoachInformationModal/Components/CoachQuestionary/CoachQuestionary';
import './CoachEditProfileModal.css';
import CoachMyBioEdit from './components/CoachMyBioEdit/CoachMyBioEdit';
import CoachMyProfileEdit from './components/CoachMyProfileEdit/CoachMyProfileEdit';

export default () => {
    const { checkUserSession, setNewData, changeLoading, getData } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();
    const { ProfileCoach } = getData();
    const [profileInformation, setProfileInformation] = useState();
    const [showCoachingAssessment, setShowCoachingAssessment] = useState(false);
    const [saveQuestions, setSaveQuestions] = useState(false);

    const getCoachInformation = async () => {
        changeLoading(true);
const { error, data } = await requestAxios({
            url: `Coach/getProfileInfo/${userId}`,
            token,
        });
        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            setProfileInformation(data);
        }
    };

    useEffect(() => {
        getCoachInformation();
    }, []);

    const sendCoachEditedInformation = async (formData) => {
        changeLoading(true);

        if (showCoachingAssessment) setSaveQuestions(true);
        const objData = {
            userId,
            firstName: formData.firstName?.value || '',
            lastName: formData.lastName?.value || '',
            city: formData.city?.value || '',
            state: formData.state?.value || '',
            country: formData.country?.value || '',
            zipCode: formData.zipCode?.value || '',
            primaryLanguage: formData.primaryLanguage?.value || '',
            emailAddress: formData.emailAddress?.value || '',
            phoneNumber: formData.phoneNumber?.value.replaceAll("-","") || '',
            websiteUrl: formData.websiteUrl?.value || '',
            calendlyUrl: formData.calendlyUrl?.value || '',
            facebookUserName: formData.facebookUserName?.value || '',
            instagramUserName: formData.instagramUserName?.value || '',
            twitterUserName: formData.twitterUserName?.value || '',
            linkedInUserName: formData.linkedInUserName?.value || '',
            tikTokUserName: formData.tikTokUserName?.value || '',
            myOffer: formData.myOffer?.value || '',
            aboutMe: formData.aboutMe?.value || '',
            listingPageHeader: formData.listingPageHeader?.value || '',
            RateItemCode: formData.sessionCost?.value || '',
            testimonial: {
                fullName: formData.fullName?.value || '',
                text: formData.text?.value || '',
                location: formData.location?.value || '',
                clientPermission: formData.clientPermission?.value || true,
            },
        };

        const { error } = await requestAxios({
            url: 'Coach/EditProfile',
            method: 'put',
            token,
            data: objData,
        });

        if (error) {
            changeLoading(false);
            window.alert(error);
        } else {
            changeLoading(false);
            setNewData({
                ProfileCoach: { modalEditProfileShow: false },
            });
        }
    };

    function onSubmit(event) {
        event.preventDefault();
        sendCoachEditedInformation(event.target.elements);
    }

    const onExitModal = () => {
        setNewData({
            ProfileCoach: { modalEditProfileShow: false },
        });
    };

    return (
        <Modal
            show={ProfileCoach?.modalEditProfileShow}
            onHide={onExitModal}
            centered
            size="xl"
            scrollable
            className="CoachEditProfileModal"
        >
            <Modal.Header>
                <div>
                    <button
                        className="exit"
                        onClick={onExitModal}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#9B9B9B"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#9B9B9B"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#9B9B9B"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="OolaScroll editProfile-body">
                <Form
                    id="coach-edit-information"
                    style={{ width: '100%' }}
                    onSubmit={onSubmit}
                >
                    <CoachMyProfileEdit
                        profileInformation={profileInformation}
                    />
                    <div className="line-division" />
                    <CoachMyBioEdit profileInformation={profileInformation} />
                </Form>
                <div className="button-assessment_container">
                    <button
                        className="button-empty-mid"
                        style={{ borderRadius: '5px' }}
                        type="button"
                        onClick={() =>
                            setShowCoachingAssessment(!showCoachingAssessment)
                        }
                    >
                        COACHING ASSESSMENT
                    </button>
                </div>
                {showCoachingAssessment && (
                    <>
                        <div className="line-division" />
                        <CoachQuestionary
                            saveQuestions={saveQuestions}
                            showCoachingAssessment={showCoachingAssessment}
                        />
                    </>
                )}
                <div className="line-division" />
            </Modal.Body>
            <Modal.Footer className="footer-container">
                <button
                    className="button-empty-small"
                    style={{ borderRadius: '5px' }}
                    type="button"
                    onClick={onExitModal}
                >
                    CANCEL
                </button>

                <button
                    className="button-full-small"
                    style={{ borderRadius: '5px' }}
                    form="coach-edit-information"
                    type="submit"
                    onClick={() => console.log('On Click')}
                >
                    SAVE
                </button>
            </Modal.Footer>
        </Modal>
    );
};
