import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalAddGoal.css';

const ModalEditGoal = (props) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className="datePickerGoal btnDatePickerGoal btnDateDasboard"
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));

    const setStartDate = (e) => {
        const goal = { ...props.currentGoal };
        goal.date = e;
        const allState = { ...props.AllState };
        const index= allState.SelectedGoals.findIndex(x=>x.GoalId==goal.GoalId)
        allState.SelectedGoals[index]=goal;
        props.AllState.changeData(allState)
    };

    const GoalTextChange = (e) => {
        const goal = { ...props.currentGoal };
        goal.text =  String(e).trimLeft().replace('  ', ' ');;
        const allState = { ...props.AllState };
        const index= allState.SelectedGoals.findIndex(x=>x.GoalId==goal.GoalId)
        allState.SelectedGoals[index]=goal;
        props.AllState.changeData(allState)
    };
    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
                centered
            >
                <Modal.Header>
                    <button
                        className="modalAddGoal__exit"
                        onClick={() => props.setShow(false)}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <section className="modalAddGoal__section">
                        <h3 className="heading-3">
                            EDIT
                            <span
                                style={{
                                    color: props.FInfo.color,
                                }}
                            >
                                {props.FInfo.areaName}
                            </span>
                        </h3>

                        <div
                            className="modalAddGoal__goalContainer"
                            style={{
                                backgroundColor: props.FInfo.color,
                            }}
                        >
                            <input
                                value={props.currentGoal.text}
                                className="GoalSelected"
                                placeholder="Define your goal"
                                onChange={(e) => GoalTextChange(e.target.value)}
                            />

                            <DatePicker
                                dateFormat="MMMM dd, yyyy"
                                selected={props.currentGoal.date}
                                onChange={(completedByDate) =>
                                    setStartDate(completedByDate)
                                }
                                customInput={<CustomInput />}
                                withPortal
                                className="datePickerGoal"
                                placeholderText="Complete by: mm/dd/yyyy"
                                minDate={new Date()}
                                disabled=""
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div>
                                        <div
                                            className="calendarHeader"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                width: '100%',
                                            }}
                                        >
                                            COMPLETE BY:
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <button
                                                className="btnCalendar"
                                                onClick={decreaseMonth}
                                                disabled={
                                                    prevMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'<'}
                                            </button>
                                            <div className="lblMonth">
                                                {
                                                    months[
                                                        new Date(
                                                            date
                                                        ).getMonth()
                                                    ]
                                                }{' '}
                                                {new Date(date).getFullYear()}
                                            </div>
                                            <button
                                                className="btnCalendar"
                                                onClick={increaseMonth}
                                                disabled={
                                                    nextMonthButtonDisabled
                                                }
                                                type="button"
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        <div>
                            <button
                                onClick={() => props.SaveGoalsChange(props.currentGoal)}
                                className="button-full-mid"
                                type="button"
                            >
                                Save
                            </button>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ModalEditGoal;
