import React from 'react';
import Modal from 'react-bootstrap/Modal';
import RecomendationGoal from './RecomendationGoal';
// import '../RecomendationSection.css';
import './ModalRecommendation.css';

const ModalRecomendation = (props) => {
   function AddRecomendation(item){
    props.AddRecomendation(item)
    props.setShow(false)
   }

    const ModalRecomendationR = (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            dialogClassName="modalRecommendationsModal-90w "
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header>
                <button style={{ visibility: 'hidden' }} type="button">
                    &times;
                </button>
                <button
                    type="button"
                    onClick={() => props.setShow(false)}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        cursor: 'pointer',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="modalRecommendations__container">
                    <h3 className="heading-3">
                        RECOMMENDED GOALS:{" "}
                        <span style={{ color: props.currItem.color }}>
                            {String(props.currItem.name).toUpperCase()}
                        </span>
                    </h3>
                    <p className="paragraph-3">
                        Here are 6 personalized goals based upon what we’ve
                        learned about you in Step 1.
                        {/* Feel free to edit, accept as is, or write goals completely on your own. These are just here to help you through the goal setting process. */}
                    </p>
                    <div className="modalRecommendations__cards">
                        {props.currItem.recommendedGoals.map((item,i) => (
                            <RecomendationGoal
                                key={`RecomendationGoal${item.id}${i}`}
                                AllState={props.AllState}
                                item={item}
                                showModalEvent={props.setShow}
                                mobile={props.mobile}
                                setShowError={props.setShowError}
                                setErrorObj={props.setErrorObj}
                                AddRecomendation={props.AddRecomendation==null?null: AddRecomendation}
                            />
                        ))}
                    </div>

                    <div className="modalRecommendations__button">
                        <button
                            type="button"
                            onClick={() => props.setShow(false)}
                            className="button-full-mid"
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
    return ModalRecomendationR;
};
export default ModalRecomendation;
