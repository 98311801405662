import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './StepHeader.css';

const StepHeaders = ({ title, url, mobile, changePage }) => {
    const [activeButton, ChangeActiveButton] = useState(false);
    const [show, Changeshow] = useState(true);

    function changePlay() {
        changePage();
        setTimeout(function () {
            Changeshow(false);
        }, 1000);
    }
    const VideoComp = (
        <div className="header__container">
            <div className="video__container">
                <h1 className="header__title">{title}</h1>
                {show === true && (
                    <ReactPlayer
                        url={url}
                        className="video__player"
                        width="100%"
                        height="50vh"
                        controls
                        onPlay={() => ChangeActiveButton(true)}
                    />
                )}
                <div className="button__container">
                    <button
                        className={` ${
                            activeButton
                                ? ' button__next buttonVideoNext'
                                : 'btnDisableStep1'
                        }`}
                        onClick={() => changePlay()}
                        type="button"
                    >
                        NEXT
                    </button>
                </div>
            </div>
        </div>
    );
    return VideoComp;
};

export default StepHeaders;
