import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';
import './PartnerPage.css';
import ActionButtons from './components/ActionButtons';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import LoginHeader from '../Common/LoginHeader/LoginHeader';

import pathsDesktop from '../../Assets/img/PartnerPage/3_Paths_Desktop.jpg';
import pathsMobile from '../../Assets/img/PartnerPage/3_Paths_Mobile.jpg';
import icon1 from '../../Assets/img/PartnerPage/icon-1.png';
import icon2 from '../../Assets/img/PartnerPage/icon-2.png';
import icon3 from '../../Assets/img/PartnerPage/icon-3.png';
import affiliateImage from '../../Assets/img/PartnerPage/affiliate-image.jpg';
import coachImage from '../../Assets/img/PartnerPage/Coach.jpg';
import ambassadorImage from '../../Assets/img/PartnerPage/Ambassador-image.jpg';
import aimeeTestimonialImage from '../../Assets/img/PartnerPage/Aimee.jpeg';
import nicoleTestimonialImage from '../../Assets/img/PartnerPage/Nicole.jpeg';
import danielleTestimonialImage from '../../Assets/img/PartnerPage/Danielle.jpg';

export default () => {
    useEffect(async () => {
        window.scrollTo(0, 0);
    }, []);

    const testimonialsData = [
        {
            image: danielleTestimonialImage,
            title: 'Danielle M. | Oola Ambassador/Coach',
            description:
                'Two years ago, I was a stressed out mom of three little ones, working 60-70 hours a week as a hairstylist. Now, because of the opportunity Oola provides, I never miss a moment with my children, I work from where I want, when I want and I’m part of a community that supports me every step of the way.',
        },
        {
            image: aimeeTestimonialImage,
            title: 'Aimee Baudier  |  Oola Ambassador',
            description:
                'My added Oola income has opened up new opportunities for personal growth and self-improvement, while helping others to do the same. I’m building an additional route to financial freedom so I have options and a greater sense of fulfillment.',
        },
        {
            image: nicoleTestimonialImage,
            title: 'Nicole Morrison  |  Oola Ambassador',
            description:
                'In a year when we had a lot of extra expenses, we were able to breathe a lot easier due to our income from Oola. It allowed us the ability to focus on the exciting things happening around us rather than how we were going to afford them.  ',
        },
    ];

    const testimonialItems = testimonialsData.map((item) => (
        <article className="testimonial_conatiner" key={item.title}>
            <figure className="testimonial_image">
                <img src={item.image} alt="testimonial" />
            </figure>
            <div className="testimonial_description">
                <h5>{item.title}</h5>
                <p>{item.description}</p>
            </div>
        </article>
    ));

    return (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <div className="partnerPage__container">
                <section className="partnerPage__hero">
                    <h1>
                        <span>IMAGINE</span> CHANGING <br /> LIVES FOR A LIVING
                    </h1>
                </section>
                <section className="partnerPage__whyPartner">
                    <h3>
                        WHY PARTNER WITH <span>OOLA</span>
                    </h3>
                    <div className="whyPartner_cards_container">
                        <article className="whyPartner_card">
                            <div className="whyPrtner_card_icon">
                                <img src={icon1} alt="icon1" />
                            </div>
                            <h5>Proven</h5>
                            <p>
                                There are countless individuals experiencing
                                daily anxiety and stress due to an unbalanced
                                life. They are seeking a solution that can lead
                                them on the path to wellness. Oola has
                                science-based proprietary products, that include
                                nootropics, adaptogens, and probiotics, along
                                with a variety of personalized programs to help
                                people balance their mind, body, and life.
                            </p>
                        </article>
                        <article className="whyPartner_card">
                            <div className="whyPrtner_card_icon">
                                <img src={icon2} alt="icon2" />
                            </div>
                            <h5>Impact</h5>
                            <p>
                                This is where you come in – we’re on the lookout
                                for heart-centered individuals like you, who
                                have a desire to do work that matters and share
                                our burning passion for making a meaningful
                                impact. Whether you seek a fulfilling full-time
                                career, or a rewarding side-hustle, partnering
                                with Oola means reaching your goals while
                                empowering others.
                            </p>
                        </article>
                        <article className="whyPartner_card">
                            <div className="whyPrtner_card_icon">
                                <img src={icon3} alt="icon3" />
                            </div>
                            <h5>Movement</h5>
                            <p>
                                Oola has already made a positive impact on over
                                a million lives across 43 countries, and we’re
                                just getting started. Partner with Oola and be
                                part of this powerful movement that reshapes
                                lives and creates a world of harmony, one step
                                at a time. Together, we can unlock the immense
                                potential that lies within every person seeking
                                balance and well-being.
                            </p>
                        </article>
                    </div>
                </section>
                <section className="partnerPage__difference_container">
                    <div className="difference_header">
                        <h3>
                            ARE YOU READY TO MAKE A <span>DIFFERENCE</span>?
                        </h3>
                        <h5>WHAT OPPORTUNITY IS BEST FOR YOU?</h5>
                    </div>
                    <div className="difference_cards_container">
                        <article className="difference_card">
                            <div className="difference_card_description">
                                <h5>AFFILIATE</h5>
                                <p>
                                    Perfect for the influencer who wants to
                                    simply share products and programs they love
                                    and earn up to 30% commission plus referral
                                    bonuses.
                                </p>
                                <Link to="/affiliate">
                                    <button type="button">LEARN MORE</button>
                                </Link>
                            </div>
                            <div className="difference_card_image">
                                <img
                                    src={affiliateImage}
                                    alt="opportunity representation"
                                />
                            </div>
                        </article>
                        <div className="affiliate__line" />
                        <article className="difference_card reverse">
                            <div className="difference_card_description">
                                <h5>AMBASSADOR</h5>
                                <p>
                                    Perfect for the person who wants to simply
                                    share products and programs they love and
                                    lead a team to do the same. Many ways to
                                    earn, including affiliate commission,
                                    referral bonuses, leadership bonuses and
                                    ongoing long-term income.
                                </p>
                                <button
                                    type="button"
                                    onClick={() =>
                                        window.open(
                                            'https://myoola.oolalife.com/corporphan/enrollment/enrollmentconfigurationambassador',
                                            '_blank'
                                        )
                                    }
                                >
                                    LEARN MORE
                                </button>
                            </div>
                            <div className="difference_card_image">
                                <img
                                    src={ambassadorImage}
                                    alt="opportunity representation"
                                />
                            </div>
                        </article>
                        <div className="affiliate__line" />
                        <article className="difference_card">
                            <div className="difference_card_description">
                                <h5>COACH</h5>
                                <p>
                                    Perfect for the person who wants to simply
                                    share products and programs they love, lead
                                    a team to do the same and get paid to coach
                                    people through the Oola System. Ability to
                                    serve others as their coach and earn
                                    affiliate commissions, earn commissions on
                                    team performance, leadership bonuses and
                                    coaching fees.
                                </p>
                                <button
                                    type="button"
                                    onClick={() =>
                                        window.open(
                                            'https://myoola.oolalife.com/corporphan/product/355',
                                            '_blank'
                                        )
                                    }
                                >
                                    LEARN MORE
                                </button>
                            </div>
                            <div className="difference_card_image">
                                <img
                                    src={coachImage}
                                    alt="opportunity representation"
                                />
                            </div>
                        </article>
                        <div className="affiliate__line" />
                    </div>
                </section>
                <section className="paths_container">
                    <figure className="paths_imageContainer">
                        <img
                            src={pathsDesktop}
                            alt="3 paths"
                            className="paths_image_desktop"
                        />
                        <div className="paths_image_mobile">
                            <div className='paths_title_container'>
                                <h3>
                                    THREE PATHS TO <br /> EARN
                                    <span>WITH OOLA</span>
                                </h3>
                            </div>
                            <img
                                src={pathsMobile}
                                alt="3 paths"
                                className="paths_image_mobile"
                            />
                            <div className="paths_description_container">
                                <h6>GIVE $10, GET $10:</h6>
                                <p>
                                    $10 coupon for every new customer your
                                    refer.
                                </p>
                                <h6>REFERRAL BONUS:</h6>
                                <p>
                                    One-time bonus for a new customers initial
                                    qualifying order.*
                                </p>
                                <h6>AFFILIATE COMMISSION:</h6>
                                <p>
                                    15% to 30% on all your own customers orders.
                                </p>
                                <h6>TEAM BONUS:</h6>
                                <p>
                                    Earn from orders on up to seven levels
                                    within your team of customers, affiliates,
                                    and ambassadors.
                                </p>
                                <h6>INFINITY BONUS:</h6>
                                <p>
                                    Earnings from orders on ALL levels within
                                    your team of customers, affiliates, and
                                    ambassadors.
                                </p>
                                <h6>LEADERSHIP BONUS:</h6>
                                <p>
                                    A fixed monthly retainer commision for
                                    maintaining your current rank as a leader.
                                </p>
                                <h6>RANK ADVANCEMENT BONUS:</h6>
                                <p>
                                    One-time bonuses of $150 to $25k for
                                    achieving specific tiers as your
                                    organization grows.
                                </p>
                                <h6>ONE-ON-ONE AND GROUP COACHING:</h6>
                                <p>
                                    Offer your Oola Life Coach services to
                                    others.
                                </p>

                                <p>
                                    *A quilifying order is a new customer
                                    purchase of the Oola Total Rebalancing
                                    System, or the Oola Lifestyle Framework.
                                </p>
                            </div>
                        </div>
                    </figure>
                    <ActionButtons />
                </section>
                <section className="testimonials_container">
                    <div className="testimoials_insideContainerMobile">
                        <AliceCarousel
                            mouseTracking
                            items={testimonialItems}
                            // responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay
                            infinite
                            autoPlayInterval={5000}
                            animationDuration={1000}
                            renderPrevButton={() => <div />}
                            renderNextButton={() => <div />}
                        />
                    </div>
                    <div className="testimoials_insideContainerDesktop">
                        {testimonialItems}
                    </div>
                </section>
                <section className="sevenThings_container">
                    <h3>
                        <span>7 THINGS</span> YOU MAY WANT TO KNOW
                    </h3>
                    <div className="sevenThings_description">
                        <p>
                            <strong>Deep Roots:</strong> Oola was started by 2
                            natural health care providers Dr. Dave Braun and Dr.
                            Troy Amdahl in 2012 after years of teaching life
                            balance principles to their patients.
                        </p>
                        <p>
                            <strong>Foundation:</strong> Dr. Dave and Dr. Troy
                            (the Oola Guys) are International best selling
                            authors of 3 books on helping people find balance in
                            an unbalanced world..
                        </p>
                        <p>
                            <strong>Dedication:</strong> The Oola Guys have
                            traveled across the country in a 1970’s VW surf bus
                            with no air, no heat and a top speed of 50 mph
                            collecting people’s dreams in the form of
                            handwritten stickers and teaching about balancing
                            your mind, body and life.
                        </p>
                        <p>
                            <strong>Stability:</strong> Oola was acquired in
                            2022 by a 30 year old, $1.2 Billion, publicly traded
                            cellular nutrition company dedicated to helping Oola
                            fulfill its mission to positively impact one billion
                            lives by creating science based proprietary
                            nutritional products aimed at balancing the mind and
                            body.
                        </p>
                        <p>
                            <strong>Trust:</strong> Oola nutrition specializes
                            in Nootropics and Adaptogens and all our products
                            are Mind and Body Lab Certified and third party
                            tested for quality, potency and bioavailability and
                            are proprietary blends specifically formulated to
                            optimize mind and body wellness.
                        </p>
                        <p>
                            <strong>Customized:</strong> Oola has customized
                            personal development programs that guide people
                            towards a balanced life in the 7 keys areas of life.
                            Known as the 7 F’s of Oola: Fitness, Finance,
                            Family, Field (career), Faith, Friends and Fun.
                        </p>
                        <p>
                            <strong>Community:</strong> You are never alone in
                            Oola. We know that whether you decide to be an
                            affiliate, ambassador or go all in and become a
                            certified Oola Coach, you will want to also build
                            your life of balance, growth and purpose and our
                            community is here to help you do just that.
                        </p>
                    </div>
                    <ActionButtons />
                </section>
                <section className="getStarted_container">
                    <h3>
                        GET <span>STARTED</span>
                    </h3>
                    <h5>
                        Together, let’s create a world of Oola balance, one life
                        at a time. Are you ready to embrace the Oola movement
                        and make a lasting impact? Join us today and embark on
                        an extraordinary journey of personal growth and
                        purpose-driven success.
                    </h5>
                    <h6>Simply choose the option below and get started.</h6>
                    <p>
                        Important: If selecting the Coach option, you will be
                        directed to schedule an appointment with an advisory
                        coach to discuss this certification in detail.
                    </p>
                    <ActionButtons />
                </section>
            </div>
            <FooterSection />
        </>
    );
};
