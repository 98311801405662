import React, { useState, useEffect } from 'react';
import './ImageIcon.css';
import completeImage from '../../../../Assets/icons/ic_complete.svg';
import GoalBadge from './GoalBadge';

// BADGES
import ic1 from '../../../../Assets/achievements/badges/Blueprint badge.svg';
import ic2 from '../../../../Assets/achievements/badges/Breakthrough badge.svg';
import ic3 from '../../../../Assets/achievements/badges/My Path badge.svg';
import ic4 from '../../../../Assets/achievements/badges/milestone 77 badge.svg';
import ic5 from '../../../../Assets/achievements/badges/streak 77 badge.svg';
import ic6 from '../../../../Assets/achievements/badges/777 milestone badge.svg';
import ic7 from '../../../../Assets/achievements/badges/365 steak badge.svg';
import ic8 from '../../../../Assets/achievements/badges/goal 1.svg';
import ic9 from '../../../../Assets/achievements/badges/badge.svg';
import ic10 from '../../../../Assets/achievements/badges/level 1.svg';
import ic11 from '../../../../Assets/achievements/badges/level 2.svg';
import ic12 from '../../../../Assets/achievements/badges/level 3.svg';

// MILESTONES
import ic13 from '../../../../Assets/achievements/milestones/milestone 1.svg';
import ic14 from '../../../../Assets/achievements/milestones/milestone 7.svg';
import ic15 from '../../../../Assets/achievements/milestones/milestone 15.svg';
import ic16 from '../../../../Assets/achievements/milestones/milestone 30.svg';
import ic17 from '../../../../Assets/achievements/milestones/milestone 50.svg';
import ic18 from '../../../../Assets/achievements/milestones/milestone 75.svg';
import ic19 from '../../../../Assets/achievements/milestones/milestone 100.svg';
import ic20 from '../../../../Assets/achievements/milestones/milestone 150.svg';
import ic21 from '../../../../Assets/achievements/milestones/milestone 200.svg';
import ic22 from '../../../../Assets/achievements/milestones/milestone 250.svg';
import ic23 from '../../../../Assets/achievements/milestones/milestone 300.svg';
import ic24 from '../../../../Assets/achievements/milestones/milestone 350.svg';
import ic25 from '../../../../Assets/achievements/milestones/milestone 450.svg';
import ic26 from '../../../../Assets/achievements/milestones/milestone 500.svg';
import ic27 from '../../../../Assets/achievements/milestones/milestone 550.svg';
import ic28 from '../../../../Assets/achievements/milestones/milestone 600.svg';

import ic29 from '../../../../Assets/achievements/milestones/milestone 650.svg';
import ic30 from '../../../../Assets/achievements/milestones/milestone 700.svg';
import ic31 from '../../../../Assets/achievements/milestones/milestone 750.svg';

import ic32 from '../../../../Assets/achievements/milestones/milestone 800.svg';
import ic33 from '../../../../Assets/achievements/milestones/milestone 850.svg';
import ic34 from '../../../../Assets/achievements/milestones/milestone 900.svg';
import ic35 from '../../../../Assets/achievements/milestones/milestone 950.svg';
import ic36 from '../../../../Assets/achievements/milestones/milestone 1000.svg';
import ic37 from '../../../../Assets/achievements/milestones/milestone 1100.svg';

import ic38 from '../../../../Assets/achievements/milestones/milestone 1200.svg';
import ic39 from '../../../../Assets/achievements/milestones/milestone 1300.svg';
import ic40 from '../../../../Assets/achievements/milestones/milestone 1400.svg';
import ic41 from '../../../../Assets/achievements/milestones/milestone 1500.svg';
import ic42 from '../../../../Assets/achievements/milestones/milestone 1600.svg';
import ic43 from '../../../../Assets/achievements/milestones/milestone 1700.svg';
import ic44 from '../../../../Assets/achievements/milestones/milestone 1800.svg';

import ic45 from '../../../../Assets/achievements/milestones/milestone 1900.svg';
import ic46 from '../../../../Assets/achievements/milestones/milestone 2000.svg';
import ic47 from '../../../../Assets/achievements/milestones/milestone 2200.svg';
import ic48 from '../../../../Assets/achievements/milestones/milestone 2400.svg';
import ic49 from '../../../../Assets/achievements/milestones/milestone 2600.svg';
import ic50 from '../../../../Assets/achievements/milestones/milestone 2800.svg';
import ic51 from '../../../../Assets/achievements/milestones/milestone 3000.svg';
import ic52 from '../../../../Assets/achievements/milestones/milestone 3200.svg';

import ic53 from '../../../../Assets/achievements/milestones/milestone 3400.svg';
import ic54 from '../../../../Assets/achievements/milestones/milestone 3600.svg';
import ic55 from '../../../../Assets/achievements/milestones/milestone 3800.svg';
import ic56 from '../../../../Assets/achievements/milestones/milestone 4000.svg';
import ic57 from '../../../../Assets/achievements/milestones/milestone 4200.svg';
import ic58 from '../../../../Assets/achievements/milestones/milestone 4400.svg';
import ic59 from '../../../../Assets/achievements/milestones/milestone 4600.svg';
import ic60 from '../../../../Assets/achievements/milestones/milestone 4800.svg';
import ic61 from '../../../../Assets/achievements/milestones/milestone 5000.svg';

// STREAKS
import ic62 from '../../../../Assets/achievements/streaks/streaks 1.svg';
import ic63 from '../../../../Assets/achievements/streaks/streaks 3.svg';
import ic64 from '../../../../Assets/achievements/streaks/streaks 7.svg';
import ic65 from '../../../../Assets/achievements/streaks/streaks 10.svg';
import ic66 from '../../../../Assets/achievements/streaks/streaks 20.svg';
import ic67 from '../../../../Assets/achievements/streaks/streaks 30.svg';
import ic68 from '../../../../Assets/achievements/streaks/streaks 50.svg';
import ic69 from '../../../../Assets/achievements/streaks/streaks 100.svg';

import ic70 from '../../../../Assets/achievements/streaks/streaks 125.svg';
import ic71 from '../../../../Assets/achievements/streaks/streaks 150.svg';
import ic72 from '../../../../Assets/achievements/streaks/streaks 200.svg';
import ic73 from '../../../../Assets/achievements/streaks/streaks 250.svg';
import ic74 from '../../../../Assets/achievements/streaks/streaks 300.svg';
import ic75 from '../../../../Assets/achievements/streaks/streaks 350.svg';
import config from '../../../../config.json';

const badgesImg = {
    1: { image: ic1 },
    2: { image: ic2 },
    3: { image: ic3 },
    4: { image: ic4 },
    5: { image: ic5 },
    6: { image: ic6 },
    7: { image: ic7 },
    8: { image: ic8 },
    9: { image: ic9 },
    10: { image: ic10 },
    11: { image: ic11 },
    12: { image: ic12 },
    13: { image: ic13 },
    14: { image: ic14 },
    15: { image: ic15 },
    16: { image: ic16 },
    17: { image: ic17 },
    18: { image: ic18 },
    19: { image: ic19 },
    20: { image: ic20 },
    21: { image: ic21 },
    22: { image: ic22 },
    23: { image: ic23 },
    24: { image: ic24 },
    25: { image: ic25 },
    26: { image: ic26 },
    27: { image: ic27 },
    28: { image: ic28 },
    29: { image: ic29 },
    30: { image: ic30 },
    31: { image: ic31 },
    32: { image: ic32 },
    33: { image: ic33 },
    34: { image: ic34 },
    35: { image: ic35 },
    36: { image: ic36 },
    37: { image: ic37 },
    38: { image: ic38 },
    39: { image: ic39 },
    40: { image: ic40 },
    41: { image: ic41 },
    42: { image: ic42 },
    43: { image: ic43 },
    44: { image: ic44 },
    45: { image: ic45 },
    46: { image: ic46 },
    47: { image: ic47 },
    48: { image: ic48 },
    49: { image: ic49 },
    50: { image: ic50 },
    51: { image: ic51 },
    52: { image: ic52 },
    53: { image: ic53 },
    54: { image: ic54 },
    55: { image: ic55 },
    56: { image: ic56 },
    57: { image: ic57 },
    58: { image: ic58 },
    59: { image: ic59 },
    60: { image: ic60 },
    61: { image: ic61 },
    62: { image: ic62 },
    63: { image: ic63 },
    64: { image: ic64 },
    65: { image: ic65 },
    66: { image: ic66 },
    67: { image: ic67 },
    68: { image: ic68 },
    69: { image: ic69 },
    70: { image: ic70 },
    71: { image: ic71 },
    72: { image: ic72 },
    73: { image: ic73 },
    74: { image: ic74 },
    75: { image: ic75 },
};

const ImageIcon = (props) => {
    let ImageIconAchievement;
    if (props.name === 8) {
        ImageIconAchievement = (
            <div
                className={`${
                    props.achieved
                        ? 'image-achievements '
                        : 'image-not-achievements'
                }`}
                style={{ position: 'relative', zIndex: 7 - props.index }}
                alt="Achievement"
            >
                <GoalBadge key={`BadgetSVG${props.id}`} value={props.value} />
            </div>
        );
    } else {
        ImageIconAchievement = (
            <object
                data="http://stackoverflow.com/does-not-exist.png"
                type="image/svg"
            >
                <img
                    className={`${
                        props.achieved
                            ? 'image-achievements '
                            : 'image-not-achievements'
                    }`}
                    key={props.name}
                    src={
                        badgesImg[props.name]?.image
                            ? badgesImg[props.name]?.image
                            : `${config.SERVER_URL_IMG}media/svg/${String(props.item.fileName).replace(".svg","")}.svg`
                    }
                    style={{ position: 'relative', zIndex: 7 - props.index }}
                    alt="Achievement"
                />
            </object>
        );
    }

    if (props.type === 'achievements') {
        if (props.name === 8) {
            ImageIconAchievement = (
                <GoalBadge
                    key={`BadgetSVG${props.id}`}
                    value={props.value}
                    className={`${
                        props.type === 'achievements'
                            ? 'image-achievements-icon '
                            : 'image-achievements'
                    }`}
                />
            );
        } else {
            ImageIconAchievement = (
                <img
                    className={`${
                        props.type === 'achievements'
                            ? 'image-achievements-icon '
                            : 'image-achievements'
                    }`}
                    key={props.name}
                    src={props.item?.fileName!=null?`${config.SERVER_URL_IMG}media/svg/${String(props.item.fileName).replace(".svg","")}.svg`: badgesImg[props.name]?.image}
                    style={{ position: 'relative', zIndex: 7 - props.index }}
                    alt="Achievement"
                />
            );
        }
    }

    return ImageIconAchievement;
};
export default ImageIcon;
