import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AreasIcons } from '../../../util/AreaSVG/index';

import Slice from './SlicesFW';
import './Wheel.css';

const popover = (
    <Popover
        id="popover-basic"
        className="OolaScoraPopUp"
        style={{ width: '35%', maxWidth: '100%' }}
    >
        <Popover.Content
            className="descriptionQuestion"
            style={{ paddingTop: '20px' }}
        >
            <div className="TitlePop"> THIS IS YOUR OOLA SCORE </div>
            <div className="descriptionQuestion DescriptionPop">
                By itself, it holds no meaning.
                <br />
                It simply quantifies a starting point. <br /> <br />
                This will be used as a point of reference as you progress
                through the Oola Lifestyle Framework. The goal is to see your
                Oola Score improve over time. We will retest at month 6 and
                again at month 12 to make sure you are on track to a life of
                balance, growth and purpose.
            </div>
        </Popover.Content>
    </Popover>
);

function sumWheel(array) {
    let total = 0;
    const tempArray = JSON.parse(JSON.stringify(array));
    for (const num of tempArray.Fs) {
        // total += Math.round((Number(num2.score = num2.score || 0)+(Number(num2.score = num2.score || 0)*num.weight)),2);
        const totalF = num.questions.reduce(
            (pv, cv) => pv + (cv.score != null ? cv.score : 0),
            0
        );
        num.OolaF = totalF + totalF * num.weight;
        total += num.OolaF;
    }
    total = Math.round(total, 2);
    return String(total);
}

const Wheel = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [show, showChange] = useState(false);
    const positionIcons = [
        { x: '46%', y: '8%' },
        { x: '76%', y: '22%' },
        { x: '84%', y: '55%' },
        { x: '61%', y: '83%' },
        { x: '28%', y: '83%' },
        { x: '7%', y: '52%' },
        { x: '16%', y: '21%' },
    ];
    const positionLabelIcons = [
        { x: "1px", y: "-4px"},
        { x: "1px", y: "-3px"},
        { x: "3px", y: "40px"},
        { x: "7px", y: "41px"},
        { x: "7px", y: "41px"},
        { x: "1px", y: "41px"},
        { x: "7px", y: "-3px"}
    ];
    useEffect(() => {
        setTimeout(() => {
            showChange(true);
            forceUpdate();
        }, 2000);
    }, []);
    const wheelR = (
        <div
            className={
                props.state.hideNav === false ? 'ChartPie' : 'ChartPieMob'
            }
            style={{
                marginTop: props.state.hideNav === false ? '-2%' : '',
                width: '100%',
                zIndex: '200',
            }}
        >
            {show === false && (
                <svg
                    className="ChartPieChunk WheelAnimationRotate"
                    width="440px"
                    height="440px"
                    viewBox="0 0 440 440"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g className="FinalWheelSVG__content">
                        <defs>
                            <g id="overlayFW">
                                <rect
                                    x="219"
                                    y="41"
                                    height="200"
                                    width="10"
                                    fill="#F8F8F8"
                                />
                            </g>
                        </defs>
                        <mask id="wedge-maskFW" fill="#F8F8F8">
                            <path
                                transform="translate(20, 20)"
                                d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                                fillRule="nonzero"
                            />
                        </mask>
                        {props.state.Fs.map((item, i) => (
                            <Slice
                                key={`sliceFinal${item.id}`}
                                index={i}
                                Fs={item}
                                FullState={props.state}
                                currSlice={props.state.index + 1}
                                finalWheel={1}
                                animation={true}
                            />
                        ))}
                        <circle
                            cx="220"
                            cy="220"
                            r="38"
                            fill="white"
                            strokeWidth="1"
                        />
                        <circle
                            cx="220"
                            cy="220"
                            r="30"
                            fill="#C9C9C9"
                            strokeWidth="1"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(25.9, 226, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(76.8, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(129, 221, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(180, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(231.4, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(282.5, 219, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(334, 218, 220)"
                        />
                        <circle
                            cx="220"
                            cy="220"
                            r="24"
                            fill="white"
                            strokeWidth="1"
                        />
                    </g>
                </svg>
            )}
            {show && (
                <svg
                    className="ChartPieChunk WheelAnimationNoRotate"
                    width="440px"
                    height="440px"
                    viewBox="0 0 440 440"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g className="FinalWheelSVG__content  ">
                        <defs>
                            <g id="overlayFW">
                                <rect
                                    x="219"
                                    y="41"
                                    height="200"
                                    width="10"
                                    fill="#F8F8F8"
                                />
                            </g>
                        </defs>
                        <mask id="wedge-maskFW" fill="#F8F8F8">
                            <path
                                transform="translate(20, 20)"
                                d="M93 7.241a200.006 200.006 0 01200.551-.865L200.004 200 112.33 20.241z"
                                fillRule="nonzero"
                            />
                        </mask>
                        {props.state.Fs.map((item, i) => (
                            <Slice
                                key={`sliceFinal${item.id}`}
                                index={i}
                                Fs={item}
                                FullState={props.state}
                                currSlice={props.state.index + 1}
                                finalWheel={1}
                                showIcons={true}
                            />
                        ))}
                        <circle
                            cx="220"
                            cy="220"
                            r="38"
                            fill="#F8F8F8"
                            strokeWidth="1"
                        />
                        <circle
                            cx="220"
                            cy="220"
                            r="30"
                            fill="#C9C9C9"
                            strokeWidth="1"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(25.9, 226, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(76.8, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(129, 221, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(180, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(231.4, 220, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(282.5, 219, 220)"
                        />
                        <use
                            xlinkHref="#overlayFW"
                            transform=" rotate(334, 218, 220)"
                        />
                        <circle
                            cx="220"
                            cy="220"
                            r="24"
                            fill="white"
                            strokeWidth="1"
                        />

                        <OverlayTrigger placement="bottom" overlay={popover}>
                            <g
                                className="animationFade"
                                transform="rotate(51.02, 220, 220)"
                            >
                                <text
                                    className=" titleOolaWheel textFinalWheel"
                                    x="203.5"
                                    y="215.5"
                                    fill="black"
                                    fontSize="10"
                                >
                                    Oola Score
                                </text>
                                <text
                                    className="titleQuestion NumberOolaWheel textFinalWheel"
                                    x="214.5"
                                    y="227.5"
                                    fill="black"
                                    fontSize="30"
                                >
                                    {sumWheel(props.state)}
                                </text>
                                <g transform="translate(12.7 5.5)">
                                    <circle
                                        cx="208"
                                        cy="231"
                                        r="4.5"
                                        fill="#707070"
                                        strokeWidth="1"
                                    />
                                    <circle
                                        cx="208"
                                        cy="231"
                                        r="4"
                                        fill="white"
                                        strokeWidth="1"
                                    />
                                </g>
                                <text
                                    className=" "
                                    x="219.5"
                                    y="238.5"
                                    fill="#707070"
                                    fontSize="6px"
                                >
                                    ?
                                </text>
                            </g>
                        </OverlayTrigger>
                    </g>
                    <g>
                        {props.state.Fs.map((item, i) => {
                            return (
                                 <g
                                 className={`FWIcons FWPosition${i}`}
                                 key={'FIconWheelFreeTest' + item.id}
                                 style={{
                                     transform: `translate(${positionIcons[i].x}, ${positionIcons[i].y}) `,
                                 }}
                             >
                                 <text
                                     className="FWIcons"
                                     fill="#B2B2B2"
                                     fontSize="10"
                                     strokeWidth="1px"
                                     x={positionLabelIcons[i].x}
                                     y={positionLabelIcons[i].y}
                                 >
                                     {props.state.Fs[i].name}
                                 </text>
                                 <foreignObject width="50" height="50">
                                     <img
                                         className="imgIconWheelF"
                                         src={AreasIcons['Area' + (i + 1)]}
                                     />
                                 </foreignObject>
                             </g>
                            );
                        })}
                    </g>
                </svg>
            )}
        </div>
    );
    return wheelR;
};

export default Wheel;
