import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SelectedGoal7.css';

const SelectedGoal = (props) => {
    const SelectedGoal = (
        <section 
            className="ActionSelected Action__content"
            style={{
                backgroundColor: props.goal.color,
            }}
        >
            <section
                className="SelectActionNumber__content  SelectActionNumber "
            >
                {props.GoalIndex + 1}
            </section>
            <section className="SelectedGoal__Content "  >
                <div className=" GoalSelectedAction">
                    {props.goal.text}
                    {props.mobile && (
                        <p>
                            <DatePicker
                                dateFormat="MMMM dd, yyyy"
                                selected={props.goal.completedByDate}
                                withPortal 
                                className="datePickerGoal7"
                                placeholderText="Complete by: mm/dd/yyyy"
                                minDate={new Date()}
                                disabled="disabled"
                            />
                        </p>
                    )}
                </div>
            </section>
            {!props.mobile && ( <section
                className="SelectActionNumber__content"
            >
                <DatePicker
                    dateFormat="MMMM dd, yyyy"
                    selected={props.goal.completedByDate}
                    withPortal 
                    className="datePickerGoal7"
                    placeholderText="Complete by: mm/dd/yyyy"
                    minDate={new Date()}
                    disabled="disabled"
                />
            </section>)}
        </section>
    );
    return SelectedGoal;
};
export default SelectedGoal;
