import React, { useState, useEffect, useContext, useRef } from 'react';
import * as SignalR from '@microsoft/signalr';
import AppContext from '../../../../../Context/AppContext';
import configData from '../../../../../config.json';
import requestAxios from '../../../../../util/requestAxios';
import './OolaChat.css';

const OolaChat = () => {
    const elementRef = useRef();

    const { checkUserSession, getData, changeLoading, TimeToF12,changeReloadClients } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();
    const { ChatMessage } = getData();
    const [newMessage, setNewMessage] = useState('');
    const [conversation, setConversation] = useState([]);
    useEffect(() => {
        const hubConnection = new SignalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(`${configData.SERVER_URL}chathub`)
            .build();
        hubConnection.start().then(function () {
            hubConnection
                .invoke('AddToGroup', String(`${ChatMessage.GroupId}`))
                .catch((err) => console.log(err.toString()));
        });
        hubConnection.on('GetChat', function (x, y) {
            getChat();
        });
    }, []);
    useEffect(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [conversation]);
    async function getChat() {
        let url = `ChatConversation/getChatMessage/${
            ChatMessage.GroupId.split('-')[0]
        }/${ChatMessage.GroupId.split('-')[1]}`;
        const { error, data } = await requestAxios({
            url: url,
            token: token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);

        } else {
            const allConversation = data.conversation.map((item, i) => ({
                Date: item.date,
                Time: TimeToF12(item.time),
                Text: item.text,
                Name: !(item.fromUserId == userId)
                    ? item.fromUserId == item.coachId
                        ? item.coachName
                        : item.clientName
                    : 'Me',
                MyMessage: item.fromUserId == userId,
                FromSMS: item.fromSMS,
            }));
            setConversation(allConversation);
            elementRef.current?.scrollIntoView({ behavior: 'smooth' });
            changeReloadClients(true);
        }
    }
    async function sendMessage() {
        if (newMessage != '') {
            const objNewMessage = {
                CoachId: Number(ChatMessage.GroupId.split('-')[0]),
                ClientID: Number(ChatMessage.GroupId.split('-')[1]),
                Message: newMessage,
            };
            let newConversation = [...conversation];
            const today = new Date();
            newConversation.push({
                Date: `${today.getMonth()}/${today.getDate()}/${today.getFullYear()}`,
                Time: `${today.getHours()}/${today.getMinutes()}`,
                Text: newMessage,
                Name: 'Me',
                MyMessage: true,
            });
            setConversation(conversation);

            let url = `ChatConversation/InsertChatMessage`;
            const { error, data } = requestAxios({
                url: url,
                token: token,
                method: 'post',
                data: objNewMessage,
            });
            if (error) {
                console.log(error);
                changeLoading(false);
            } else {
                setNewMessage('');
            }
        }
    }
    const handleKeypress = (e) => {
        if (e.key === 'Enter' && newMessage != '') {
            sendMessage();
        }
    };

    let pastDate = '';
    return (
        <div className="OolaChat__container">
            <div
                className={`OolaChatNotes__container OolaScroll`}
                ref={elementRef}
            >
                {conversation?.map((item, i) => {
                    let titleDate = '';
                    if (pastDate != item.Date) {
                        titleDate = item.Date;
                        pastDate = item.Date;
                    }
                    return (
                        <div key={`OolaChatNoteItem__containerCoach${i}`}>
                            {titleDate && (
                                <div className="TitleDateChat__container">
                                    <h1 className="TitleDateChat">
                                        {item.Date}
                                    </h1>
                                </div>
                            )}
                            <div
                                className={`OolaChatNoteItem__container ${
                                    item.MyMessage && 'MyMessage'
                                }`}
                                ref={elementRef}
                            >
                                <div className="MessageText__container">
                                    <h2>
                                        {item.Text}
                                        <br />
                                    </h2>
                                    <h3>
                                        {item.FromSMS && (
                                            <i
                                                className="fa fa-mobile OolaChatSMSIcon"
                                                aria-hidden="true"
                                            />
                                        )}{' '}
                                        {item.Time}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="OolaChatFootter__container ">
                <textarea
                    placeholder="Write a new session note…"
                    value={newMessage}
                    onChange={(input) => setNewMessage(input.target.value)}
                    onKeyPress={handleKeypress}
                />
                <button onClick={() => sendMessage()}>
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
};
export default OolaChat;
