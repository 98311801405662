import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import AppContext from '../../Context/AppContext';
import requestAxios from '../../util/requestAxios';
import { initialState, initialFormAnswers } from './initialState';
import FormQuestions from './FormQuestions/FormQuestions';
import ModalTimeZone from './ModalTimeZone/ModalTimeZone';
import Header from '../Dashboard/Header/HeaderDashboard';
import FindCoachModal from '../Dashboard/MyOolaCoach/FindCoachModal/FindCoachModal';
import currentUserStep from '../../util/currentUserStep';
import oolaLogo from '../../Assets/oola_logo_blue.svg';
import './CustomizeQuestions.css';

const CustomizeQuestions = () => {
    const { checkUserSession, updateUserStepId } = useContext(AppContext);
    const [state, setState] = useState(initialState);
    const [formAnswers, setFormAnswers] = useState(initialFormAnswers);
    const [disabled, setDisabled] = useState(true);

    const [showModalTimezone, setShowModalTimezone] = useState(false);
    const [showModalCoach, setShowModalCoach] = useState(false);

    const { userId, token, firstName, lastName } = checkUserSession();
    const { infoSection, questionsSection } = state;
    const history = useHistory();

    // Get questions
    useEffect(async () => {
        const userStepCheck = await checkUserSession();
        const { userStepId, userTypeId } = userStepCheck;
        if (userStepId !== 0 && userTypeId !== 4) {
            history.push(currentUserStep(userStepCheck));
        } else {
            const { error, data } = await requestAxios({
                url: `CustomizeAssessment/GetCustomizeQuestions`,
                token,
            });
            if (error) window.alert('Sorry we had an error');
            else {
                setState({
                    ...state,
                    questionsSection: {
                        ...state.questionsSection,
                        questions: data,
                    },
                });
            }
        }
        await getCustomerProfileInformation();
    }, []);

    async function getCustomerProfileInformation() {
        const us = checkUserSession();
        const url = `Customer/GetCustomerProfileInfo/${us.userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token: us.token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            if (!data?.hasCoach) {
                setShowModalCoach(true);
            }
        }
    }
    const checkAnswersSelected = () =>
        formAnswers.CustomizeQuestions.some((item) => item.OptionId === 0);

    const onValueChange = (event, id) => {
        const filteredAnswers = formAnswers.CustomizeQuestions.filter(
            (item) => item.questionId !== id
        );

        setFormAnswers({
            ...formAnswers,
            CustomizeQuestions: [
                ...filteredAnswers,
                {
                    questionId: id,
                    OptionId: parseInt(event.target.value, 10),
                },
            ],
        });
    };

    // Check if answers are checked, to activate the button.
    useEffect(() => {
        setDisabled(checkAnswersSelected());
    }, [formAnswers]);

    const formSubmit = async (event) => {
        event.preventDefault();

        const dataAnswers = formAnswers;
        dataAnswers.userId = userId;

        const { error } = await requestAxios({
            url: `CustomizeAssessment/SaveCustomizeQuestions`,
            method: 'post',
            token,
            data: dataAnswers,
        });

        if (error) console.log('ERROR', error);
        else {
            history.push('/steps');
        }
    };

    return (
        <>
            <Header disableTimeModal={false} />
            <section className="customizeQuestions">
                <article className="customizeQuestions__info">
                    <div className="info__subTitle">
                        <h6 className="heading-6">
                            <span className="info__phase">PHASE 1</span>
                            <br />
                            {parse(infoSection.subTitle.toUpperCase())}
                        </h6>
                    </div>
                    <div className="info__text">
                        <figure>
                            <img src={oolaLogo} alt="Logo Oola" />
                        </figure>
                        <h1 className="display-2">
                            {parse(
                                infoSection.title
                                    .replace('#nameUser#', firstName)
                                    .toUpperCase()
                            )}
                        </h1>
                        <h5 className="paragraph-1">
                            {infoSection.description}
                        </h5>
                        {infoSection.stepsInfo.map((item) => (
                            <div key={item.title}>
                                <h6 className="paragraph-2">
                                    {parse(item.title)}
                                </h6>
                                <p className="paragraph-3">{item.text}</p>
                            </div>
                        ))}
                    </div>
                </article>

                <article className="customizeQuestions__questions">
                    <h3 className="heading-4">
                        {parse(questionsSection.title.toUpperCase())}
                    </h3>

                    <FormQuestions
                        formSubmit={formSubmit}
                        questionsSection={questionsSection}
                        onValueChange={onValueChange}
                        disabled={disabled}
                    />
                </article>

                <ModalTimeZone
                    showModalTimezone={showModalTimezone}
                    setshowModalTimezone={setShowModalTimezone}
                />
            </section>
            {showModalCoach && (
                <FindCoachModal
                    show={showModalCoach}
                    setShowModal={setShowModalCoach}
                    coachId={0}
                />
            )}
        </>
    );
};

export default CustomizeQuestions;
