import React from 'react';
import BodyMind from '../../../Assets/img/Dashboard/BodyMind.png';
import './StartYourJourneySection2.css';

const StartYourJourneySection = ({ refScroll }) => {
    const handleClick = () => {
        refScroll.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="familyBackground__container familyBackground__container2">
            <div className="familyBackgroundText__container">
                <div className="familyBackgroundText">
                    <h1>
                        Balance your <br />
                        Mind, Body and Life
                        <hr />
                    </h1>
                        <h2>WITH OUR TOTAL REBALANCING SYSTEM</h2>
                    <button
                        className="button-full-mid"
                        onClick={handleClick}
                        type="button"
                    >
                        START TODAY
                    </button>
                </div>
            </div>
            {/* <div className='familyBackgroundTextImg' /> */}
        </div>
    );
};

export default StartYourJourneySection;
