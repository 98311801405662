import React, { useEffect } from 'react';
import LoginHeader from '../Common/LoginHeader/LoginHeader';
import FooterSection from '../LearnMore/FooterSection/FooterSection';
import './OurLeadership.css';
import DaveImage from '../../Assets/img/OurLeadership/Dave.jpeg';
import BeccaImage from '../../Assets/img/OurLeadership/Becca.png';
import TimImage from '../../Assets/img/OurLeadership/Tim.jpg';
import TravisImage from '../../Assets/img/OurLeadership/Travis.jpeg';
import TroyImage from '../../Assets/img/OurLeadership/Troy.jpg';

const OurLeadership = () => {
    useEffect(async () => {
        window.scrollTo(0, 0);
    }, []);
    const OurLeadershipR = (
        <>
            <LoginHeader isDarkLogo enrollButtonText="ENROLL" />
            <div className="ourLeaderShip__container">
                <div className="ourLeaderShip_title">
                    <h1>
                        OUR <span>LEADERSHIP</span>
                    </h1>
                </div>
                <section className="ourLeaderShip-card__container">
                    <figure className="ourLeadership-img__container">
                        <img
                            className="ourLeadership_img "
                            alt="Our Leadership Founders"
                            src={DaveImage}
                        />
                    </figure>
                    <div className="ourLeaderShip_text__container">
                        <h3>
                            DR. DAVE BRAUN | <span>CO-FOUNDER & PRESIDENT</span>
                        </h3>
                        <p>
                            With a deep-rooted commitment to empowering
                            individuals to find balance and transform their
                            lives, Dr. Dave Braun has spent over a decade
                            guiding people towards holistic well-being. As
                            President, he plays a pivotal role in shaping Oola’s
                            strategic direction, leading the team in creating
                            science-based products and transformative programs
                            that promote life balance.
                        </p>
                    </div>
                </section>
                <section className="ourLeaderShip-card__container">
                    <figure className="ourLeadership-img__container">
                        <img
                            className="ourLeadership_img "
                            alt="Our Leadership Founders"
                            src={TroyImage}
                        />
                    </figure>
                    <div className="ourLeaderShip_text__container">
                        <h3>
                            DR. TROY AMDAHL | <span>CO-FOUNDER & CHAIRMAN</span>
                        </h3>
                        <p>
                            Dr. Troy Amdahl is an International best-selling
                            author, social media influencer, and a personal
                            growth expert. In addition to being Oola’s
                            Co-Founder, as Chairman, he is responsible for the
                            overall direction and strategy of the company.
                        </p>
                    </div>
                </section>
                <section className="ourLeaderShip-card__container">
                    <figure className="ourLeadership-img__container">
                        <img
                            className="ourLeadership_img "
                            alt="Our Leadership Founders"
                            src={TravisImage}
                        />
                    </figure>
                    <div className="ourLeaderShip_text__container">
                        <h3>
                            TRAVIS OGDEN | <span>CO-FOUNDER & CEO</span>
                        </h3>
                        <p>
                            Travis Ogden has spent the past 8 years of his
                            career leading two of the world’s largest network
                            marketing companies. His executive level experience
                            with these billion-dollar companies includes
                            positions as CEO, President, COO and CFO.
                        </p>
                    </div>
                </section>
                <section className="ourLeaderShip-card__container">
                    <figure className="ourLeadership-img__container">
                        <img
                            className="ourLeadership_img "
                            alt="Our Leadership Founders"
                            src={TimImage}
                        />
                    </figure>
                    <div className="ourLeaderShip_text__container">
                        <h3>
                            Tim Jones | <span>CHIEF FINANCIAL OFFICER</span>
                        </h3>
                        <p>
                            Tim comes to Oola with more than 25 years of
                            experience in the business world, starting his
                            career as a CPA at one of the largest global
                            accounting firms and spending the last 16 years as
                            CFO for two different direct selling companies. His
                            expertise and leadership will be instrumental as we
                            continue to grow and navigate the road ahead.
                        </p>
                    </div>
                </section>
                <section className="ourLeaderShip-card__container">
                    <figure className="ourLeadership-img__container">
                        <img
                            className="ourLeadership_img "
                            alt="Our Leadership Founders"
                            src={BeccaImage}
                        />
                    </figure>
                    <div className="ourLeaderShip_text__container">
                        <h3>
                            BECCA JOHNSON | <span>CHIEF GROWTH OFFICER</span>
                        </h3>
                        <p>
                        With over 15 years of experience as a small business
                        entrepreneur, direct sales leader, business
                        consultant, and coach for professionals and
                        corporate organizations, Becca Johnson brings a
                        wealth of knowledge, skills, and leadership to Oola.
                        A dynamic, driven, and caring leader, Becca’s role
                        will focus on expanding and supporting the success
                        and development of Oola’s affiliates and ambassador’s
                        while also overseeing global sales.
                        </p>
                    </div>
                </section>
            </div>

            <FooterSection />
        </>
    );

    return OurLeadershipR;
};

export default OurLeadership;
