import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AliceCarousel from 'react-alice-carousel';
import DashboardWheel from '../DashboardWheel/DashboardWheel';
import ArrowSvg from '../../../../Assets/icons/Arrow.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalRecords.css';

const ModalRecords = ({ show, setShow, OolaScore }) => {
    const responsive = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 3 },
    };
    const renderNextButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                style={{ transform: 'rotate(180deg)' }}
                className="ArrowDashboardCarousel Right"
            />
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return <img src={ArrowSvg} className="ArrowDashboardCarousel Left" />;
    };
    function createMarkup(item) {
        const html = String(item.completionDate).replace('className', 'class');
        return { __html: html };
    }
    const [items, setItems] = useState([]);
    useEffect(() => {
        const newItems = OolaScore.filter((x) => x.score > 0).map((item, i) => (
            <div className="OolaWheelsRecords_ItemContainer">
                <div className="miniWheel__container   ">
                    <DashboardWheel AllState={item} showColors={true} />
                </div>
                <h3 className="NumberMyOolaScore">{item.score}</h3>
                <h6
                    className="heading-6"
                    dangerouslySetInnerHTML={createMarkup(item)}
                />
            </div>
        ));
        setItems(newItems);
    }, []);
    const ModalRecordsR = (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                dialogClassName="ModalRecord__dialog"
            >
                <Modal.Header className="HeaderModalOolaWheel">
                    <button type="button" style={{ visibility: 'hidden' }}>
                        &times;
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setShow(false);
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            cursor: 'pointer',
                            zIndex:10
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Title className="titleModalOolaWheel">
                    OOLA WHEEL RECORDS
                </Modal.Title>
                <Modal.Body className="bodyModalOolaWheel">
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        autoPlay
                        infinite
                        autoPlayInterval={3000}
                        animationDuration={1000}
                        renderPrevButton={renderPrevButton}
                        renderNextButton={renderNextButton}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
    return ModalRecordsR;
};
export default ModalRecords;
