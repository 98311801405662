import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalOolaReviewReviseReaffirm = ({
    setShow,
    Show,
    ModalConfirmTitle,
    ModalConfirmDesc,
    ConfirmEvent,
    ConfirmText,
    CancelText,
}) => {
    let ModalOolaReviewReviseReaffirmR = (
        <Modal
            show={Show}
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
            id="ModalSevenConfirm"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
            size="lg"
            centered
        >
            <Modal.Header style={{ borderBottom: 'none' }}>
                <button style={{ visibility: 'hidden' }}>&times;</button>

                <button
                    onClick={() => setShow(false)}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        cursor: 'pointer',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                    >
                        <defs>
                            <clipPath id="b">
                                <rect width="40" height="40" />
                            </clipPath>
                        </defs>
                        <g id="a" clipPath="url(#b)">
                            <g transform="translate(-1269.135 820.365) rotate(-45)">
                                <g transform="translate(-16803 -3223)">
                                    <line
                                        x2="20"
                                        transform="translate(18270.5 3568.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                    <line
                                        y1="20"
                                        transform="translate(18280.5 3558.5)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    />
                                </g>
                                <g
                                    transform="translate(1462 330)"
                                    fill="none"
                                    stroke="#2c414c"
                                    strokeWidth="3"
                                >
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="14"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div
                        className="ModalConfirmTitleTop7"
                        style={{ width: '100%' }}
                    >
                        {ModalConfirmTitle}
                    </div>
                    <div className="ModalConfirmDescTop7">
                        {ModalConfirmDesc}
                    </div>
                </div>
                <center style={{ textAlign: 'center' }}>
                    <button
                        onClick={() => setShow(false)}
                        className="btnEditGoalsTop7"
                    >
                        {CancelText}
                    </button>
                    <button
                        onClick={() => ConfirmEvent()}
                        className="btnContinueGoalsTop7"
                    >
                        {ConfirmText}
                    </button>
                </center>
            </Modal.Body>
        </Modal>
    );

    return ModalOolaReviewReviseReaffirmR;
};
export default ModalOolaReviewReviseReaffirm;
