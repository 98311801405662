import { useState, useEffect, useContext } from 'react';
import AliceCarousel from 'react-alice-carousel';
import ArrowSvg from '../../../../Assets/icons/Arrow.svg';
import RecommendedCourses from '../../ELearningBoard/Board/NewModules/sections/RecommendedCourses/RecommendedCourses';
import RecommendedProducts from '../../ELearningBoard/Board/NewModules/sections/RecommendedCourses/RecommendedProducts';

const CarruselProduct = ({ items, saleType }) => {
    const renderNextButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                style={{ transform: 'rotate(180deg)' }}
                className={`ArrowDashboardCarousel Right`}
            />
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return <img src={ArrowSvg} className={`ArrowDashboardCarousel Left`} />;
    };

    let newItems = createItems();

    function createItems() {
        let newItems = items.map((item, i) => (
            <div className="Product_ItemContainer">
                {saleType == 1 ? (
                    <RecommendedCourses item={item} />
                ) : (
                    <RecommendedProducts item={item} />
                )}
            </div>
        ));
        if (newItems.length === 0 && saleType == 1) {
            const newItem = {
                id: 4,
                title: '',
                subTitle: '',
                creator: '',
                imageUrl: '/media/elearning/images/CSE.png',
                price: 0,
                exigoItemCode: '',
                purchased: false,
                completed: false,
                isEnabled: false,
                externalLink: '',
            };
            newItems.push(
                <div className="Product_ItemContainer">
                    <RecommendedCourses item={newItem} />
                </div>
            );
        }
        return newItems;
    }
    const responsive = {
        0: { items: 1 },
        767: { items:  2 },
        1024: { items:  3 },
    };
    return (
        <section className="CarruselProducts__container">
            <AliceCarousel
                mouseTracking
                items={newItems}
                responsive={responsive}
                controlsStrategy="alternate"
                autoPlay
                infinite={!(newItems.length < 4)}
                autoPlayInterval={3000}
                animationDuration={1000}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
            />
        </section>
    );
};
export default CarruselProduct;
