import React, { useState, useContext } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {
    PrevModuleButton,
    TakeQuizButton,
    CompleteButton,
    DownloadBookButton,
    OolaWheelButton,
    NextModuleButton,
    CompleteCertificationButton,
    ProscanButton,
    CreateActionSteps,
} from './DoButton';
import './Do.css';
import CertificationQuiz from './CertificationQuiz';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';

const DoObj = (props) => {
    const { changeLoading, checkUserSession } = useContext(AppContext);
    const history = useHistory();
    const [showCertificationQuiz, setShowCretificationQuiz] = useState(false);

    const nextOrBackModule = (value) => {
        changeLoading(true);
        setTimeout(() => {
            localStorage.setItem('ModuleId', value);
            localStorage.setItem('isCertificationModule', 1);
            history.go(0);
            window.scrollTo(0, 0);
        }, 2000);
    };

    const completeCertificationModule = async () => {
        changeLoading(true);
        const moduleId = localStorage.getItem('ModuleId');
        const userInfo = checkUserSession();

        const { error, data } = await requestAxios({
            url: `AdditionalCourse/CompleteCertificationModule/${moduleId}`,
            token: userInfo.token,
            method: 'post',
        });

        if (data) {
            nextOrBackModule(data.nextModuleId);
        } else {
            window.alert(
                'Sorry we had an error, please try to reload the page'
            );
        }
    };

    const Section = props.AllState.ModuleInfo.sections.find(
        (x) => x.name === 'Do'
    );
    // debugger;
    function createMarkup(value) {
        if (Section == null) {
            return { __html: '' };
        }
        return { __html: Section[value] };
    }
    const Do = (
        <div className="watchHeader_container">
            <Container fluid className="watch_container">
                <div className="lineBottomWatch" />
                {Section != null && (
                    <>
                        {Section?.contentText != '' && (
                            <>
                                <div className="watchHeader_container">
                                    <div className="watchHeader_wrapper">
                                        <div className="WatchTitle">
                                            <h5>
                                                <label>
                                                    {' '}
                                                    {Section != null &&
                                                        Section.title}
                                                </label>
                                                <br />
                                                PROGRESS{' '}
                                                <span
                                                    style={{ color: '#78ccf6' }}
                                                >
                                                    3/{props.lenghtModules}
                                                </span>
                                            </h5>
                                        </div>
                                        <div
                                            className="watch__modules"
                                            style={{ alignSelf: 'center' }}
                                        >
                                            <div
                                                className="WatchDesc"
                                                style={{ color: '#212529' }}
                                                dangerouslySetInnerHTML={createMarkup(
                                                    'contentText'
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!props.isModuleCertification && (
                                    <div className="lineBottomWatch" />
                                )}
                            </>
                        )}
                        <div className="watchHeader_container">
                            <div className="watch__modules">
                                {(props.AllState.ModuleInfo.nextStepId === 4 ||
                                    props.AllState.ModuleInfo.nextStepId ===
                                        0 ||
                                    props.AllState.ModuleInfo.nextStepId ===
                                        6) &&
                                    Section.pdfUrl != null && (
                                        <DownloadBookButton
                                            props={props}
                                            Section={Section}
                                        />
                                    )}
                                {props.isModuleCertification &&
                                    Section.pdfUrl !== '' && (
                                        <DownloadBookButton
                                            props={props}
                                            Section={Section}
                                        />
                                    )}
                                {props.AllState.ModuleInfo.nextStepId === 5 && (
                                    <OolaWheelButton {...props} />
                                )}
                                {Section?.doButton && (
                                    <ProscanButton
                                        doButton={Section?.doButton}
                                        history={history}
                                    />
                                )}
                                {/* {props.AllState.ModuleInfo.nextStepId === 6 && (
                                    <Level2Button {...props} />
                                )} */}
                                {/* {props.AllState.ModuleInfo.hasActionSteps ===
                                    6 && <Level2Button {...props} />} */}
                                {Section.hasActionSteps === true && (
                                    <CreateActionSteps
                                        {...props}
                                        Section={Section}
                                    />
                                )}
                            </div>

                            <div className="lineBottomWatch" />
                        </div>
                    </>
                )}
                <div
                    className="watchButtons_container"
                    style={{ paddingTop: '3rem', paddingBottom: '3rem' }}
                >
                    <div className="actionButtons_left">
                        <Link to="/dashboard">
                            <button type="button" className="btnBackModule">
                                BACK
                            </button>
                        </Link>
                    </div>
                    <div className="actionButtons_center">
                        {Section?.buttonOne?.displayButton && (
                            <PrevModuleButton
                                data={Section?.buttonOne}
                                nextOrBackModule={nextOrBackModule}
                            />
                        )}
                    </div>
                    <div className="actionButtons_right">
                        {(props.AllState.ModuleInfo.nextStepId === 4 ||
                            props.AllState.ModuleInfo.nextStepId === 0 ||
                            props.AllState.ModuleInfo.nextStepId === 6) &&
                            props.AllState.ModuleInfo.displayCompleteButton && (
                                <CompleteButton {...props} />
                            )}
                        {props.AllState.ModuleInfo.nextStepId === 5 && (
                            <OolaWheelButton {...props} />
                        )}
                        {/* {props.AllState.ModuleInfo.nextStepId === 6 && (
                            <Level2Button {...props} />
                        )} */}

                        {Section?.buttonTwo?.displayButton && (
                            <NextModuleButton
                                data={Section?.buttonTwo}
                                nextOrBackModule={nextOrBackModule}
                            />
                        )}
                        {Section?.buttonThree?.displayButton && (
                            <TakeQuizButton
                                data={Section?.buttonThree}
                                setShowCretificationQuiz={
                                    setShowCretificationQuiz
                                }
                            />
                        )}
                        {Section?.buttonFour?.displayButton && (
                            <CompleteCertificationButton
                                data={Section?.buttonFour}
                                completeCertificationModule={
                                    completeCertificationModule
                                }
                            />
                        )}
                    </div>
                </div>
            </Container>
            {showCertificationQuiz && (
                <CertificationQuiz
                    setShowCretificationQuiz={setShowCretificationQuiz}
                />
            )}
        </div>
    );

    return Do;
};
export default DoObj;
