import React, { useContext, useState } from 'react';
import AppContext from '../../../../../Context/AppContext';
import configData from '../../../../../config.json';
import iconUser from '../../../../../Assets/img/userIcon.jpg';
import './CoachingRoomInfoComponentCoach.css';

export default () => {
    const { setNewData, getData } = useContext(AppContext);
    const { currentClient } = getData();
    const [imageHash] = useState(Date.now());

    return (
        <article className="CoachingRoomInforComponentCoach__container">
            <div className="CoachingRoomInforComponentCoach-image__container">
                <div
                    alt="Profile"
                    className="CoachingRoomInforComponentCoach-image__image"
                    style={{
                        backgroundImage: `url(${
                            currentClient
                                ? `${configData.SERVER_URL_IMG}/media/profile/${currentClient?.profilePictureFileName}?${imageHash}`
                                : iconUser
                        })`,
                    }}
                />
            </div>

            <div className="CoachingRoomInforComponentCoach-name__container">
                <h5 className="heading-5 WhiteText ">
                    {currentClient?.customerType}
                </h5>
                <h3 className="heading-5 WhiteText">
                    {currentClient?.firstName} {currentClient?.lastName}
                </h3>
                <p className="paragraph-3 WhiteText">
                    Certified Oola Life Coach
                </p>
            </div>
            <div className="CoachingRoomInforComponentCoach-box__container">
                <div>
                    <h5>{currentClient?.stepsTaken}</h5>
                    <h6>
                        {currentClient?.stepsTaken === 1 ? 'ACTION' : 'ACTIONS'}
                        <br />
                        <span>STEP TAKEN</span>
                    </h6>
                </div>
                <div>
                    <h5>{currentClient?.currentStreak}</h5>
                    <h6>
                        {currentClient?.currentStreak === 1 ? 'DAY' : 'DAYS'}
                        <br />
                        <span>CURRENT STREAK</span>
                    </h6>
                </div>
                <div>
                    <h5>{currentClient?.longestStreak}</h5>
                    <h6>
                        {currentClient?.longestStreak === 1 ? 'DAY' : 'DAYS'}
                        <br />
                        <span>LONGEST STREAK</span>
                    </h6>
                </div>
                <div>
                    <h5>{currentClient?.goalsCompleted}</h5>
                    <h6>
                        MY
                        <br />
                        <span>GOALS COMPLETED</span>
                    </h6>
                </div>
            </div>
        </article>
    );
};
