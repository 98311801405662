import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';

import StepHeader from './StepHeader';
import Step12 from './Step12/Step12';
import configData from '../../config.json';
import './Step1Slices.css';
import './QuestionaryStep1.css';

const Step1Slices = ({
    mobile,
    indexStepChange,
    handleChangeHeader,
    userStatus,
    headerActive
}) => {
    const [change, setChange] = useState(false);
    const [transition, setTransition] = useState(false);
    const [, height] = useWindowSize();

    const handleChange = () => {
        setTransition(true);
        setTimeout(() => {
            setChange(true);
        }, 500);
    };
    const handleChangeDirect = () => {
        setChange(true);
    };

    return (
        <section className={headerActive==true?"step1slice__container":"step1slice__containerHiddenHeader"}>
            {change === false &&
                <div
                    className={`stepHeader__container ${
                        transition && 'hiddeVideo'
                    }`}
                >
                    <StepHeader
                        url="https://player.vimeo.com/video/539413178"
                        title="STEP 1: WHERE YOU ARE NOW"
                        description={
                            'We are on this planet to help you FIND BALANCE \n and a live of less stress and greater propuse - the OOLALIFE'
                        }
                        mobile={mobile}
                        changePage={handleChange}
                    />
                </div>}
                    <Step12
                        changeStepIndex={indexStepChange}
                        configData={configData}
                        mobile={mobile}
                        height={height}
                        handleChangeHeader={handleChangeHeader}
                        userStatus={userStatus}
                        handleChange={handleChangeDirect}
                    />
        </section>
    );
};
export default Step1Slices;
