import React, { useState, useEffect } from 'react';
import ModalRecords from './ModalRecords/ModalRecords';
import DashboardWheel from './DashboardWheel/DashboardWheel';
import ArrowSvg from '../../../Assets/icons/ArrowDarkBlue.svg';
import './MyOolaScore.css';
import AliceCarousel from 'react-alice-carousel';

const MyOolaScore = (props) => {
    const [showModalRecords, setShowModalRecords] = useState(false);
     
    const responsive = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 3 },
    };
    const renderNextButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                style={{ transform: 'rotate(180deg)' }}
                className={`ArrowMyOolaScoreCarousel Right ${
                    props.AllState.OolaScore?.filter((x) => x.score > 0)?.length < 4 && 'hideArrow'
                }`}
            />
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return (
            <img
                src={ArrowSvg}
                className={`ArrowMyOolaScoreCarousel Left ${
                    props.AllState.OolaScore?.filter((x) => x.score > 0)?.length < 4 && 'hideArrow'
                }`}
            />
        );
    };

    const newItems = props.AllState.OolaScore?.filter((x) => x.score > 0).map(
        (item, i) => (
            <div
                key={`DashboardWheel__item${i}`}
                className="myOolaScore__ItemContainer "
            >
                <div className="miniWheel__container  ">
                    <DashboardWheel
                        AllState={item}
                        currBadget={props.currBadget}
                    />
                </div>
                <h3 className=" NumberMyOolaScore">{item.score}</h3>

                <h6
                    className="heading-6"
                    
                >
                    <p>{String(item.completionDate).split(" ")[0]}</p>
                    {String(item.completionDate).split(" ")[1]}  {String(item.completionDate).split(" ")[2]}
                    </h6>
            </div>
        )
    );

    return (
        props.AllState.OolaScore != null && (
            <article className="myOolaScore">
                <div className="TitleDashboardContent">
                    <p className="heading-5 BlueDarkText">MY OOLA SCORE</p>
                </div>
                <div className="myOolaScore__container Carrousel">
                    {newItems.length > 0 && (
                        <AliceCarousel
                            mouseTracking
                            items={newItems}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                        />
                    )}

                    {/* <div>
                        <div className="miniWheel__container   ">
                            <DashboardWheel
                                AllState={props.AllState.OolaScore[1]}
                            />
                        </div>
                        <h3 className="NumberMyOolaScore">
                            {props.AllState.OolaScore[1].score}
                        </h3>
                        <h6
                            className="heading-6"
                            dangerouslySetInnerHTML={createMarkup(1)}
                        />
                    </div>
                    <div>
                        <div className="miniWheel__container  ">
                            <DashboardWheel
                                AllState={props.AllState.OolaScore[2]}
                            />
                        </div>
                        <h3 className="NumberMyOolaScore">
                            {props.AllState.OolaScore[2].score}
                        </h3>
                        <h6
                            className="heading-6"
                            dangerouslySetInnerHTML={createMarkup(2)}
                        />
                    </div> */}
                </div>
                {props.AllState.OolaScore.length > 0 &&
                    showModalRecords == true && (
                        <ModalRecords
                            OolaScore={props.AllState.OolaScore}
                            show={showModalRecords}
                            setShow={setShowModalRecords}
                        />
                    )}
            </article>
        )
    );
};
export default MyOolaScore;
