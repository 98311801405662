import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import './ExigoHeader.css';
import config from '../../../config.json';

const ExigoHeader = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const history = useHistory();
    const openLinks = (direction) => {
        window.location.href = config.EXIGOSERVER + direction;
    };
    const getLinks = (direction) => {
        return config.EXIGOSERVER + direction;
    };
    return (
        <>
            <Navbar
                collapseOnSelect
                expand="lg"
                className="ExigoHeader__NavbarContainer"
            >
                <Container className="ExigoHeader__Container">
                    <Navbar.Brand href="#home">
                        <img
                            className="ExigoHeader__IMGLogo"
                            src="https://dashboard.oolalife.com/static/media/oola_black_logo.b1173292.svg"
                            alt=""
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        className="ExigoHeader__NavbarButtonToggle"
                    >
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className="ExigoHeader__NavbarContainerCollapse"
                    >
                        <Nav className="me-auto ExigoHeader__NavContainer">
                            <Nav.Link
                                href={getLinks('/corporphan/account/login')}
                                className="ExigoHeader__LI"
                            >
                                LOGIN
                            </Nav.Link>
                            <Nav.Link
                                href={getLinks('/corporphan/products')}
                                className="ExigoHeader__LI"
                            >
                                SHOP
                            </Nav.Link>
                            <Nav.Link
                                href={getLinks('/corporphan/enrollment')}
                                className=" ExigoHeader__LIBtnShop"
                            >
                                ENROLL
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default ExigoHeader;
