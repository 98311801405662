import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { slide as Menu } from 'react-burger-menu';
import { useWindowSize } from '@react-hook/window-size';
import './VideoModuleVip.css';

const VideoModuleVip = ({ moduleInfo }) => {
    const _moduleInfo = moduleInfo[0] != null ? moduleInfo[0] : {};
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [width] = useWindowSize();
    function createMarkup(value) {
        return {
            __html: `${_moduleInfo[value]}`,
        };
    }
    const styles = {
        bmCrossButton: {
            height: '44px',
            width: '44px',
        },
        bmCross: {
            display: 'none',
        },
        bmMenuWrap: {
            height: '100%',
            zIndex: 21,
            width: '300px',
            marginTop: '-50px',
        },
        bmMenu: {
            background: '#FFF',
            padding: '0',
            fontSize: '1.15em',
            overflowY: 'none',
        },
        bmMorphShape: {
            fill: '#373a47',
        },
        bmItemList: {
            color: '#b8b7ad',
            textAlign: 'justify',
        },
        bmItem: {
            display: 'block',
            fontFamily: 'var(--us101-font)',
            fontSize: '1.2rem',
            color: '#2c414c',
            margin: '5px 10px',
            '&:active': {
                color: 'var(--blue-light)',
            },
            '&:hover': {
                color: 'var(--blue-light)',
            },
        },
        bmOverlay: {
            background: 'rgb(0,0,0,0.5',
            zIndex: '20',
            marginTop: '-50px',
        },
    };
    const VideoModuleVipR = (
        <>
            {width <= 768 && (
                <Menu
                    left
                    styles={styles}
                    burgerButtonClassName={'btnCategoriesMobile'}
                    isOpen={showSideMenu}
                    onClose={() => setShowSideMenu(!showSideMenu)}
                >
                    <div className="VideoModuleVipCategories__container">
                        <svg
                            viewBox="220 0 200 30"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <text
                                className="VideoModuleVipCategories__arrows"
                                x={390}
                                y={30}
                                fontSize="25"
                                strokeWidth="1px"
                            >
                                {'<<'}
                            </text>
                        </svg>

                        <ul className="VideoModuleVipCategoriesUL VideoModuleVipCategoriesULMobile">
                            <li
                                style={{}}
                                className="VideoModuleVipCategoriesLI VideoModuleVipCategoriesLITitles "
                            >
                                CATEGORIES
                            </li>
                            <li
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 1 &&
                                        'var(--FitnessColor)'
                                    }`,
                                }}
                                className="VideoModuleVipCategoriesLI"
                            >
                                FITNESS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 2 &&
                                        'var(--FinanceColor)'
                                    }`,
                                }}
                            >
                                FINANCE
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 3 &&
                                        'var(--FamilyColor)'
                                    }`,
                                }}
                            >
                                FAMILY
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 4 &&
                                        'var(--FieldColor)'
                                    }`,
                                }}
                            >
                                FIELDS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 5 &&
                                        'var(--FaithColor)'
                                    }`,
                                }}
                            >
                                FAITH
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 6 &&
                                        'var(--FriendColor)'
                                    }`,
                                }}
                            >
                                FRIENDS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 7 &&
                                        'var(--FunColor)'
                                    }`,
                                }}
                            >
                                FUN
                            </li>
                        </ul>
                    </div>
                </Menu>
            )}
            <section className="VideoModuleVip__container">
                <section className="VideoModuleVip__containerGrid">
                    <div
                        className="VideoModuleVipCategories__containerMobile"
                        onClick={() => setShowSideMenu(!showSideMenu)}
                    >
                        <svg
                            viewBox="220 0 30 120"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40px"
                        >
                            <text
                                className="VideoModuleVipCategories__svgText VideoModuleVipCategories__svgTextMobile"
                                fill="black"
                                strokeWidth="1px"
                            >
                                Categories
                            </text>
                            <text
                                className=" VideoModuleVipCategories__arrowsMobile"
                                strokeWidth="1px"
                            >
                                {'<<'}
                            </text>
                        </svg>
                    </div>
                    <div className="VideoModuleVipCategories__container">
                        <svg
                            viewBox="220 0 200 30"
                            xmlns="http://www.w3.org/2000/svg"
                            width="200px"
                        >
                            <text
                                className="VideoModuleVipCategories__svgText"
                                x={270}
                                y={30}
                                fill="black"
                                fontSize="30"
                                strokeWidth="1px"
                            >
                                Categories
                            </text>
                            <text
                                className="VideoModuleVipCategories__arrows"
                                x={390}
                                y={30}
                                fontSize="30"
                                strokeWidth="1px"
                            >
                                {'<<'}
                            </text>
                        </svg>

                        <ul className="VideoModuleVipCategoriesUL">
                            <li
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 1 &&
                                        'var(--FitnessColor)'
                                    }`,
                                }}
                                className="VideoModuleVipCategoriesLI"
                            >
                                FITNESS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 2 &&
                                        'var(--FinanceColor)'
                                    }`,
                                }}
                            >
                                FINANCE
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 3 &&
                                        'var(--FamilyColor)'
                                    }`,
                                }}
                            >
                                FAMILY
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 4 &&
                                        'var(--FieldColor)'
                                    }`,
                                }}
                            >
                                FIELDS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 5 &&
                                        'var(--FaithColor)'
                                    }`,
                                }}
                            >
                                FAITH
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 6 &&
                                        'var(--FriendColor)'
                                    }`,
                                }}
                            >
                                FRIENDS
                            </li>
                            <li
                                className="VideoModuleVipCategoriesLI"
                                style={{
                                    color: `${
                                        _moduleInfo.areaId === 7 &&
                                        'var(--FunColor)'
                                    }`,
                                }}
                            >
                                FUN
                            </li>
                        </ul>
                    </div>
                    <div className="VideoModuleVipVideo__container">
                        <section
                            className="VideoModuleVipVideo__Title"
                            dangerouslySetInnerHTML={createMarkup('htmlTitle')}
                        />

                        <section
                            className="VideoModuleVipVideo__Description"
                            dangerouslySetInnerHTML={createMarkup(
                                'htmlContent'
                            )}
                        />
                        <section className="VideoModuleVipVideo">
                            <ReactPlayer
                                url={_moduleInfo.videoUrl}
                                height="50vh"
                                width="100%"
                                controls
                            />
                        </section>
                    </div>
                </section>
            </section>
            <section className="VideoModuleVipVideoMobile">
                <ReactPlayer url={_moduleInfo.videoUrl} width="100%" controls />
            </section>
        </>
    );
    return VideoModuleVipR;
};

export default VideoModuleVip;
