import React, { useContext, useState, useEffect } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import { useShepherdTour } from "react-shepherd";

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../Context/AppContext';
import Sales from './Dashboard/Sales/Sales';
import Tracker from './Dashboard/Tracker/Tracker';
import HeaderDashboard from './Dashboard/Header/HeaderDashboard';
import UserInfo from './NonSubscriberDashboard/UserInfo/UserInfo';
import ELearnigBoard from './NonSubscriberDashboard/ELearningBoard/ELearningBoard';
import MyGoals from './NonSubscriberDashboard/MyGoals/MyGoals';
import MyProgress from './NonSubscriberDashboard/MyProgress/MyProgress';
import ModalBadget from './Dashboard/UserInfo/Modals/ModalBadget';
import Network from './Network/Network';
import configData from '../config.json';
import FooterCopyright from './Common/FooterCopyright/FooterCopyright';
import Tutorial from './NonSubscriberDashboard/Tutorial/Tutorial';
import requestAxios from '../util/requestAxios';
import MyCoachLock from '../Assets/img/Dashboard/MyCoachLock.png';
import WeeklyInspirationLock from '../Assets/img/Dashboard/WeeklyInspirationLock.png';
import './Dashboard.css';
import MyOolaCoach from './Dashboard/MyOolaCoach/MyOolaCoach';

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

const NonDashboard = () => {
    const {
        checkUserSession,
        changeLoading,
        setNewData,
        changeModalWarningUpgrade,
    } = useContext(AppContext);
    const { token, userId, isShopify, displayCoachCertificationTutorial } = checkUserSession();
    const [dashboardTutorial, setDashboardTutorial] = useState(false);
    const [init, setInit] = useState(false);
    const [myProgressLoading, setMyProgressLoading] = useState(true);
    const [achievementsLoading, setAchievementsLoading] = useState(true);
    const [showPopover, setShowPopover] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const onWindowResize = useGlobalEvent('resize');
    const [mobile, setMobile] = useState(window.innerWidth <= 760);
    const [mobileTablet, setMobileTablet] = useState(window.innerWidth <= 1200);
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(async () => {
        getAllInfo();
    }, []);

    const newSteps = [
        {
            id: 'intro',
            attachTo: { element: '.tour_target ', on: 'top' },
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                    setTimeout(function () {
                        window.scrollTo(0, 1);
                        resolve();
                    }, 500);
                });
            },
            buttons: [
                {
                    classes: 'button-full-small tour_button',
                    text: 'OK',
                    type: 'next',
                },
            ],
            classes: 'tour_container',
            highlightClass: 'tour_highlight',
            scrollTo: mobileTablet,
            cancelIcon: {
                enabled: false,
            },
            title: 'Start Coach Certification',
            text: [
                'Your Mind and Body Coach Certification is loaded in the Additional Programs section of your E-Learning. Start now'
            ],
            when: {
                show: () => {
                    console.log('show step');
                },
                hide: () => {
                    console.log('hide step');
                },
            },
        },
        // ...
    ];
    const tour = useShepherdTour({ tourOptions, steps: newSteps });


    const [currBadget, currBadgetChange] = useState(null);

    onWindowResize((event: React.SyntheticEvent) => {
        setMobileTablet(window.innerWidth <= 1200)
        setMobile(window.innerWidth <= 760);
        setWindowWidth(window.innerWidth);
        setNewData({ isMobile: { value: window.innerWidth <= 760 } });
    });

    const [state, setState] = useState({
        AllSteps: 0,
        DailySteps: 0,
        Actions: [],
        userProgress: {
            stepsTaken: 0,
            currentStreak: 0,
            longestStreak: 0,
            goalsCompleted: 0,
        },
        profile: {
            achievements: {
                badges: [],
                milestones: [],
                streaks: [],
            },
            latestAchievements: [],
        },
    });

    async function getCustomerProfileInformation() {
        const us = checkUserSession();
        const url = `Customer/GetCustomerProfileInfo/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            const today = new Date();
            data.customerLocalTime = new Date(
                data.customerLocalTime ? data.customerLocalTime : today
            );

            state.profile = data;
            setInit(true);
            setState(state);
            changeLoading(false);
            setNewData({
                ProfileCoach: { currentClient: data },
                CustomerProfileInfo: { ...data },
            });
            forceUpdate();
        }
    }

    async function getMyAchievements() {
        setAchievementsLoading(true);
        const url = `Customer/GetMyAchiements/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            setAchievementsLoading(false);
        } else {
            state.profile.latestAchievements = data;
            setState(state);
            const bagdet = state.profile.latestAchievements.filter(
                (x) => x.achieved === true && x.displayed === false
            );
            if (bagdet.length > 0) {
                bagdet[0].showModal = true;
                currBadgetChange(bagdet[0]);
            }
            forceUpdate();
            setAchievementsLoading(false);
        }
    }

    async function getMyProgress() {
        setMyProgressLoading(true);
        const url = `Dashboard/GetUserProgress/${userId}`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
            setMyProgressLoading(false);
        } else {
            state.userProgress = data;
            setState(state);
            forceUpdate();
            setMyProgressLoading(false);
        }
    }

    async function getMyOolaScore() {
        const url = `Dashboard/GetOolaScores`;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });
        if (error) {
            console.log(error);
        } else {
            state.OolaScore = data;
            setState(state);
            forceUpdate();
        }
    }
    async function getAllInfo() {
        await getCustomerProfileInformation();
        await getMyProgress();
        await getMyAchievements();
        await getMyOolaScore();
        
        if(displayCoachCertificationTutorial)
        tour.start();
    }

    function hideModalBadget(badgetInfo) {
        const url = `${configData.SERVER_URL}Customer/UpdateDisplayedBadge`;
        const objPost = {
            userid: userId,
            achievementid: badgetInfo.id,
        };
        axios
            .put(url, objPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(() => {
                currBadgetChange(null);
                getMyAchievements();
                getAllInfo();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const NonDashboardV2 = (

        <div className={`Dashboard__container ${isShopify&&"shopify"}` }>
            <HeaderDashboard profile={state.profile} />
            <section className="dashboard">
                <div className="DashboardSection1">
                    <div className="DashboardSection11">
                        <UserInfo
                            AllState={state}
                            AllStateChange={setState}
                            getCustomerProfileInformation={getAllInfo}
                            achievementsLoading={achievementsLoading}
                        />
                        <MyProgress
                            AllState={state}
                            AllStateChange={setState}
                            myProgressLoading={myProgressLoading}
                        />
                    </div>
                    <div className="DashboardSection12">
                        {state.profile.enableMyCoachSection ? (
                            <MyOolaCoach
                                AllState={state}
                                getCustomerProfileInformation={getAllInfo}
                            />
                        ) : (
                            <div className="LockSection MyCoachLock space">
                                <p className="heading-5 BlueDarkText">
                                    MY OOLA COACH
                                </p>
                                <img src={MyCoachLock} />
                            </div>
                        )}
                       
                    </div>
                </div>
                <div className="DashboardSection2">
                    <div className="DashboardSection21">
                        <Tracker
                            AllState={state}
                            AllStateChange={setState}
                            getMyProgress={getAllInfo}
                        />
                        <MyGoals
                            AllState={state}
                            AllStateChange={setState}
                            getMyProgress={getAllInfo}
                            getMyAchiements={getMyAchievements}
                            mobile={mobile}
                        />
                        <div className="MyOolaScoreCell__container"></div>
                    </div>
                    <div className="DashboardSection22 ">
                        {init && <ELearnigBoard AllState={state} />}
                    </div>
                </div>
                <div className="DashboardSection3">
                    <h1 className="BlueDarkText">MY RECOMMENDATIONS</h1>
                    <Sales />
                </div>
            </section>
            <FooterCopyright />

            {currBadget !== null && (
                <ModalBadget
                    currBadget={currBadget}
                    hideModalBadget={hideModalBadget}
                    setDashboardTutorial={setDashboardTutorial}
                />
            )}

            {dashboardTutorial && (
                <Tutorial setDashboardTutorial={setDashboardTutorial} />
            )}

            <Network />
        </div>

    );

    return NonDashboardV2;
};

export default NonDashboard;
