import React, { useEffect, useContext, useState } from 'react';
import AppContext from '../../../../../Context/AppContext';
import requestAxios from '../../../../../util/requestAxios';
import './CoachVideoCall.css';

export default ({ currentClient, currentCoach }) => {
    const { changeLoading, checkUserSession, setNewData } =
        useContext(AppContext);
    const [meetingData, setMeetingData] = useState();
    const [clientMeetingMessage, setClientMeetingMessage] = useState();

    const createMeeting = async () => {
        changeLoading(true);

        const us = checkUserSession();
        const url = `Coach/CreateMeeting/${currentClient?.userId}`;

        const { error, data } = await requestAxios({
            url,
            method: 'post',
            token: us.token,
        });

        if (error) {
            console.log(error);
            window.alert("Sorry, we couldn't start the meeting.");
            changeLoading(false);
        } else {
            setMeetingData(data);
            setNewData({
                ProfileCoach: { meetingData: data },
            });

            changeLoading(false);
        }
    };
    const joinMeeting = async () => {
        changeLoading(true);
        setClientMeetingMessage(false);

        const us = checkUserSession();
        const url = `Coach/GetMeeting/${us?.userId}`;

        const { error, data } = await requestAxios({
            url,
            token: us.token,
        });

        if (error) {
            console.log(error);
            setClientMeetingMessage(true);
            changeLoading(false);
        } else {
            setMeetingData(data);

            changeLoading(false);
        }
    };

    useEffect(() => {
        if (!meetingData) {
            if (currentClient) createMeeting();
            else if (currentCoach) joinMeeting();
        }
    }, []);

    return (
        <div className="CoachVideoCall_container">
            <button className="reload" onClick={() => joinMeeting()}>
                <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
            {meetingData && (
                <iframe
                    src={
                        currentClient
                            ? meetingData.coachLink
                            : meetingData.customerLink
                    }
                    width="100%"
                    height="100%"
                    scrolling="no"
                    allow="microphone; camera"
                    title="Meeting"
                />
            )}
            {clientMeetingMessage && (
                <div style={{ margin: '30px', color: 'white' }}>
                    Your coach has not started a meeting.
                </div>
            )}
        </div>
    );
};
