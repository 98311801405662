import { useContext, useRef, useState } from 'react';
import axios from 'axios';
import UserInfoCoach from '../../Dashboard/UserInfo/UserInfoCoach';
import DefaultPicture from '../../../Assets/img/DashboardCoach/Bus.png';
import AppContext from '../../../Context/AppContext';
import configData from '../../../config.json';
import './ProfilePicture.css';

export default ({ getProfileCoachInfo }) => {
    const inputRef = useRef(null);
    const { checkUserSession, getData, changeLoading } = useContext(AppContext);
    const { ProfileCoach } = getData();
    const [imageHash, imageHashChange] = useState(Date.now());
    const changeHandler = (event) => {
        changeLoading(true);
        const imageFile = event.target.files[0];
        const { token, userId } = checkUserSession();
        // Create an object of formData
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('image', imageFile);

        const url = `${configData.SERVER_URL}Coach/UploadCoverImage`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .post(url, formData, config)
            .then((response) => {
                if (response.status === 201) {
                    getProfileCoachInfo();
                    imageHashChange(Date.now());

                    alert('Image uploaded correctly');
                    changeLoading(false);
                }
            })
            .catch((error) => {
                    alert('The file image exceeds the maximum allowed size (3 MB).');
                    console.log(error);
                changeLoading(false);
            });
    };
    return (
        <>
            <div
                className="ProfilePictureCover__container"
                style={{
                    backgroundImage: `url(${
                        ProfileCoach?.coverFileName != ''
                            ? `${configData.SERVER_URL_IMG}/media/profile/${ProfileCoach?.coverFileName}?${imageHash}`
                            : DefaultPicture
                    })`,
                }}
            >
                <div className="ProfilePictureCover__buttonUpdateContainer ">
                    <div className="dashboard">
                        <button
                            className="ProfilePictureCover__buttonUpdate Desktop"
                            onClick={() => {
                                inputRef.current.click();
                            }}
                        >
                            <div>
                                <div />
                                Edit cover photo
                                <br />
                            </div>
                            <h2>1440×500px</h2>
                        </button>
                        <button
                            className="ProfilePictureCover__buttonUpdate Mobile"
                            onClick={() => {
                                inputRef.current.click();
                            }}
                        >
                            <div>
                                <div />
                                <br />
                            </div>
                        </button>
                        <input
                            type="file"
                            size="35"
                            onChange={changeHandler}
                            ref={inputRef}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
            </div>
            <div className="dashboard ProfilePictureSections__container">
                <UserInfoCoach getProfileCoachInfo={getProfileCoachInfo} />
            </div>
        </>
    );
};
