import React, { useState, useContext, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import requestAxios from '../../../../util/requestAxios';
import ImageIcon from './ImageIcon';
import AppContext from '../../../../Context/AppContext';
import './Achievements.css';

const AchievementsCoach = (props) => {
    const { checkUserSession, changeLoading } = useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [data, changeData] = useState({
        badgeSection: 'badges',
    });
    const [achievements, setAchievements] = useState(null);
    useEffect(() => {
        getCustomerProfileInformation();
    }, []);
    async function getCustomerProfileInformation() {
        changeLoading(true);
        let url = `Customer/GetCustomerProfileInformation/${userId}`;

        if (props.coachBadgets) url = `Coach/GetAchievements/${userId}`;

        url;
        const { error, data } = await requestAxios({
            url: url,
            token,
        });

        if (error) {
            console.log(error);
            changeLoading(false);
        } else {
            if (props.coachBadgets) {
                setAchievements({ ...data });
            } else {
                setAchievements(data.achievements);
            }
            changeLoading(false);
        }
    }

    const [modalVisible, showAchievents] = useState(false);
    const [textTitleModal, setTextTitleModal] = useState('');
    function hideSeeMore() {
        // props.SetShow(true);
        props.SetShowAchieventsModal(false);
    }

    function seeMore(section, title) {
        // props.SetShow(false);
        props.SetShowAchieventsModal(true);

        const AllProps = { ...data };
        AllProps.badgeSection = section;
        AllProps.changeData = changeData;
        setTextTitleModal(title);
        changeData(AllProps);
    }
    const numbers = [0, 1, 2, 3, 4, 5];

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.setShowError(false)}
                aria-labelledby="example-custom-modal-styling-title"
                id="modalAchieve"
                centered
            >
                <Modal.Header>
                    <button
                        className="modalAchievements__exit"
                        onClick={() => props.setShowError(false)}
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="4 4 32 32"
                        >
                            <defs>
                                <clipPath id="b">
                                    <rect width="40" height="40" />
                                </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                                <g transform="translate(-1269.135 820.365) rotate(-45)">
                                    <g transform="translate(-16803 -3223)">
                                        <line
                                            x2="20"
                                            transform="translate(18270.5 3568.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                        <line
                                            y1="20"
                                            transform="translate(18280.5 3558.5)"
                                            fill="none"
                                            stroke="#2c414c"
                                            strokeWidth="3"
                                        />
                                    </g>
                                    <g
                                        transform="translate(1462 330)"
                                        fill="none"
                                        stroke="#2c414c"
                                        strokeWidth="3"
                                    >
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="15.5"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="14"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <section className="modalAchievements__section">
                        <h4 className="heading-4">CERTIFICATIONS</h4>
                        <div className="section__achievements">
                            {achievements?.certificationBadges?.length > 0 &&
                                numbers
                                    .filter(
                                        (x) =>
                                            x <
                                            achievements?.certificationBadges
                                                ?.length
                                    )
                                    .map((number, index) => (
                                        <article key={index}>
                                            <figure>
                                                <ImageIcon
                                                    achieved={
                                                        achievements
                                                            .certificationBadges[
                                                            number
                                                        ]?.achieved
                                                    }
                                                    name={
                                                        achievements
                                                            .certificationBadges[
                                                            number
                                                        ]?.id
                                                    }
                                                    filename={
                                                        achievements
                                                            .certificationBadges[
                                                            number
                                                        ]?.fileName
                                                    }
                                                    item={
                                                        achievements
                                                            .certificationBadges[
                                                            number
                                                        ]
                                                    }
                                                />
                                            </figure>
                                            <p className="heading-6">
                                                {
                                                    achievements
                                                        .certificationBadges[
                                                        number
                                                    ]?.achievementName
                                                }
                                            </p>
                                        </article>
                                    ))}
                        </div>
                        <div className="section__button">
                            <button
                                onClick={() => seeMore('certificationBadges',"CERTIFICATIONS")}
                                className="button-full-mid"
                                type="button"
                            >
                                See more
                            </button>
                        </div>
                    </section>

                    <section className="modalAchievements__section">
                        <h4 className="heading-4">LIFETIME CLIENTS</h4>
                        <div className="section__achievements">
                            {achievements?.lifeTimeClientBadges?.length > 0 &&
                                numbers.map((number, index) => (
                                    <article key={index}>
                                        <figure>
                                            <ImageIcon
                                                achieved={
                                                    achievements
                                                        .lifeTimeClientBadges[
                                                        number
                                                    ]?.achieved
                                                }
                                                name={
                                                    achievements
                                                        .lifeTimeClientBadges[
                                                        number
                                                    ]?.id
                                                }
                                                item={
                                                    achievements
                                                        .lifeTimeClientBadges[
                                                        number
                                                    ]
                                                }
                                            />
                                        </figure>
                                        <p className="heading-6">
                                            {
                                                achievements
                                                    .lifeTimeClientBadges[
                                                    number
                                                ]?.achievementName
                                            }
                                        </p>
                                    </article>
                                ))}
                        </div>
                        <div className="section__button">
                            <button
                                onClick={() => seeMore('lifeTimeClientBadges',"LIFETIME CLIENTS")}
                                className="button-full-mid"
                                type="button"
                            >
                                See more
                            </button>
                        </div>
                    </section>

                    <section className="modalAchievements__section">
                        <h4 className="heading-4">CLIENT ACTION STEPS TAKEN</h4>
                        <div className="section__achievements">
                            {achievements?.clientActionStepsBadges?.length >
                                0 &&
                                numbers.map((number, index) => (
                                    <article key={index}>
                                        <figure>
                                            <ImageIcon
                                                achieved={
                                                    achievements
                                                        .clientActionStepsBadges[
                                                        number
                                                    ]?.achieved
                                                }
                                                name={
                                                    achievements
                                                        .clientActionStepsBadges[
                                                        number
                                                    ]?.id
                                                }
                                                item={
                                                    achievements
                                                        .clientActionStepsBadges[
                                                        number
                                                    ]
                                                }
                                            />
                                        </figure>
                                        <p className="heading-6">
                                            {
                                                achievements
                                                    .clientActionStepsBadges[
                                                    number
                                                ]?.achievementName
                                            }
                                        </p>
                                    </article>
                                ))}
                        </div>
                        <div className="section__button">
                            <button
                                onClick={() =>
                                    seeMore('clientActionStepsBadges',"CLIENT ACTION STEPS TAKEN")
                                }
                                className="button-full-mid"
                                type="button"
                            >
                                See more
                            </button>
                        </div>
                    </section>

                    <section className="modalAchievements__section">
                        <h4 className="heading-4">CLIENT GOALS ACHIEVED</h4>
                        <div className="section__achievements">
                            {achievements?.clientGoalsAchievedBadges?.length >
                                0 &&
                                numbers.map((number, index) => (
                                    <article key={index}>
                                        <figure>
                                            <ImageIcon
                                                achieved={
                                                    achievements
                                                        .clientGoalsAchievedBadges[
                                                        number
                                                    ]?.achieved
                                                }
                                                name={
                                                    achievements
                                                        .clientGoalsAchievedBadges[
                                                        number
                                                    ]?.id
                                                }
                                                item={
                                                    achievements
                                                        .clientGoalsAchievedBadges[
                                                        number
                                                    ]
                                                }
                                            />
                                        </figure>
                                        <p className="heading-6">
                                            {
                                                achievements
                                                    .clientGoalsAchievedBadges[
                                                    number
                                                ]?.achievementName
                                            }
                                        </p>
                                    </article>
                                ))}
                        </div>
                        <div className="section__button">
                            <button
                                onClick={() =>
                                    seeMore('clientGoalsAchievedBadges',"CLIENT GOALS ACHIEVED")
                                }
                                className="button-full-mid"
                                type="button"
                            >
                                See more
                            </button>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
            {showAchievents && (
                <Modal
                    show={props.showAchievents}
                    onHide={() => hideSeeMore()}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                    id="modalAchieve"
                >
                    <Modal.Header>
                        <button
                            onClick={() => hideSeeMore()}
                            className="modalAchievements__exit back"
                            type="button"
                        >
                            {'< BACK'}
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {achievements && (
                            <section className="modalAchievements__section">
                                <h4 className="heading-4">
                                    {textTitleModal}
                                </h4>
                                <div className="section__achievements">
                                    {achievements[data.badgeSection]?.map(
                                        (item, index) => (
                                            <article key={index}>
                                                <figure>
                                                    <ImageIcon
                                                        achieved={item.achieved}
                                                        name={item.id}
                                                        value={
                                                            item.achievementValue
                                                        }
                                                        fileName={item.fileName}
                                                        item={item}
                                                    />
                                                </figure>
                                                <p className="heading-6">
                                                    {item.achievementName}
                                                </p>
                                            </article>
                                        )
                                    )}
                                </div>
                            </section>
                        )}
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
export default AchievementsCoach;
