import React from 'react';
import { useHistory } from 'react-router-dom';
import ELearningCard from '../ELearningCard';

const MyBreakthrough = ({
    loaded,
    completeLength,
    uncompleteLength,
    unlockedLength,
    height,
    modules,
    typeModules,
    configData,
}) => {
    const history = useHistory();
    const MyBreakthroughR =
        loaded === true && unlockedLength === 0 ? (
            <article className="eLearningBoardCreate">
                <h5 className="heading-5">My Breakthrough Experience</h5>
                <button
                    className="eLearningBoard__createYourCourse"
                    onClick={() => history.push('/assessment?AssessmentType=1')}
                    type="button"
                >
                    CREATE YOUR
                    <br /> CUSTOM PROGRAM HERE!
                    <svg>
                        <g transform="translate(40 40)">
                            <g
                                className="pulseAnimation"
                                style={{ opacity: '0.5' }}
                            >
                                <circle r="30" fill="white" />
                                <circle r="28" fill="#d86b59" />
                                <circle r="22" fill="white" />
                            </g>
                        </g>
                    </svg>
                </button>
            </article>
        ) : (
            <div className='MyBreakThroughModules__container'>
                <section className="paragraph-3 ModuleDescription">
                    <p className="TitleContentElearning">
                        My Breakthrough Experience: {completeLength}/
                        {modules.myBreakthroughExperience.length}
                    </p>
                    <span>PHASE 2:</span> A new module unlocks every 7 days for
                    12-weeks
                </section>

                <div className="eLearningBoard__modules Oolascroll">
                    {modules.myBreakthroughExperience.map((item, i) => (
                        <ELearningCard
                            index={i}
                            height={height}
                            item={item}
                            configData={configData}
                            showLeftBorder={i % 2}
                            key={item.id}
                        />
                    ))}
                </div>
            </div>
        );

    return MyBreakthroughR;
};

export default MyBreakthrough;
