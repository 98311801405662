import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SelectedGoal.css';
import './Goals.css';

const SelectedGoal = props => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const currGoal = props.AllState.SelectedGoals.find(
        x =>
            x.areaId === props.AllState.CurrentF &&
            x.recomendationIndex === props.GoalIndex
    );
    // const [GoalText, GoalTextChange] = useState(currGoal!=null?currGoal.text:"");
    // const [startDate, setStartDate] = useState("");
    const currStep = props.AllState.Fs[props.AllState.CurrentF];

    const currRecomendation = () =>
        currGoal == null ? { text: '', date: '' } : currGoal;
    const GoalTextChange = (e, index) => {
        const AllState = { ...props.AllState };
        const goalIndex = AllState.SelectedGoals.findIndex(
            x =>
                x.areaId === props.AllState.CurrentF &&
                x.recomendationIndex === props.GoalIndex
        );
        if (AllState.SelectedGoals[goalIndex] != null) {
            AllState.SelectedGoals[goalIndex].text = String(e.value)
                .trimLeft()
                .replace('  ', ' ');
            props.AllState.changeData(AllState);
        } else {
            const newGoal = {
                recomendationIndex: index,
                text: String(e.value)
                    .trimLeft()
                    .replace('  ', ' '),
                areaId: props.AllState.CurrentF,
                date: new Date(),
                Id: AllState.SelectedGoals.length,
            };
            AllState.SelectedGoals.push(newGoal);
            props.showModalEvent(false);
            props.AllState.changeData(AllState);
        }

        return currGoal == null ? {} : currGoal;
    };

    const setStartDate = e => {
        const AllState = { ...props.AllState };
        const goalIndex = AllState.SelectedGoals.findIndex(
            x =>
                x.areaId === props.AllState.CurrentF &&
                x.recomendationIndex === props.GoalIndex
        );
        if (AllState.SelectedGoals[goalIndex] != null) {
            AllState.SelectedGoals[goalIndex].date = e;
            props.AllState.changeData(AllState);
        } else {
            //    setStartDate(new Date())
            const newGoal = {
                recomendationIndex: AllState.SelectedGoals.length,
                text: '',
                areaId: props.AllState.CurrentF,
                date: e,
            };
            AllState.SelectedGoals.push(newGoal);
            props.showModalEvent(false);
            props.AllState.changeData(AllState);
        }

        return currGoal == null ? {} : currGoal;
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            className={`datePickerGoal ${
                String(value).length === 0
                    ? 'btnDatePickerGoalUnselect'
                    : 'btnDatePickerGoal'
            }`}
            onClick={onClick}
            ref={ref}
        >
            {String(value).length === 0 ? 'Complete by: mm/dd/yyyy' : value}
        </button>
    ));

    return (
        <div
            className="selectedGoalCard__container"
            style={{
                backgroundColor: currStep.color,
            }}
        >
            <div>
                <h5 className="heading-5">{props.GoalIndex + 1}</h5>
            </div>
            <div>
                <input
                    className="GoalSelected"
                    placeholder={`Define your ${currStep.name} goal`}
                    value={currRecomendation().text}
                    onChange={e => GoalTextChange(e.target, props.GoalIndex)}
                />
            </div>
            <div className="datePïcker__container">
                <DatePicker
                    style={{ fontSize: '40px', zIndex: '10' }}
                    dateFormat="MMMM dd, yyyy"
                    selected={currRecomendation().date}
                    onChange={date => setStartDate(date)}
                    withPortal
                    className="datePickerGoal"
                    placeholderText="Complete by: mm/dd/yyyy"
                    minDate={new Date()}
                    disabled={
                        currRecomendation().text.length > 0 ? '' : 'disabled'
                    }
                    customInput={<CustomInput />}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className="datepicker__container">
                            <div
                                className="calendarHeader"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                COMPLETE BY:
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingTop: '10px',
                                }}
                            >
                                <button
                                    type="button"
                                    className="btnCalendar"
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                >
                                    {'<'}
                                </button>
                                <div className="lblMonth">
                                    {months[new Date(date).getMonth()]}{' '}
                                    {new Date(date).getFullYear()}
                                </div>
                                <button
                                    type="button"
                                    className="btnCalendar"
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                >
                                    {'>'}
                                </button>
                            </div>
                        </div>
                    )}
                    portalId="root-portal"
                />
            </div>
        </div>
    );
};
export default SelectedGoal;
