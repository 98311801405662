import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../../Context/AppContext';
import ShareOptionsModal from '../../../../Share/ShareOptionsModal/ShareOptionsModal';
import getUserDate from '../../../../../util/getUserDate';
import requestAxios from '../../../../../util/requestAxios';
import Wheel from '../../../Wheel/Wheel';
import './FinalStep1.css';

export default ({ state, handleChangeIndex, endStep1, myr, mobile }) => {
    const [activeShare, setActiveShare] = useState(false);
    const [shareData, setShareData] = useState({});
    const [loading, setLoading] = useState(false);
    const { checkUserSession } = useContext(AppContext);
    const currentUser = checkUserSession();
    const handleShare = async () => {
        setLoading(true);
        const { error, data } = await requestAxios({
            url: `Customer/GetShareContent/1`,
            token,
        });

        if (error)
            window.alert(
                'Sorry there was an error on sharing, please try again'
            );
        else {
            setShareData(data);
            setActiveShare(true);
            setLoading(false);
        }
    };
    const FinalStep1 = (
        <>
            <div className="finalStepData__container">
                <div className="titleFs__container">
                    <h3 className="titleFs__title">
                        {myr === 1 ? (
                            <>
                                <span style={{ color: '#78ccf6' }}>
                                    MID-YEAR
                                </span>{' '}
                                REVIEW COMPLETE
                            </>
                        ) : (
                            <>
                                <span style={{ color: '#78ccf6' }}>STEP 1</span>{' '}
                                COMPLETE
                            </>
                        )}
                    </h3>
                </div>
                <div
                    className={`dataFs__container ${myr === 1 &&
                        'dataFs__containerMidYead'}`}
                >
                    <div className="dataFs__description">
                        <h4>GREAT WORK</h4>
                        <h4>{`${currentUser.firstName}`}!</h4>
                        <h6>
                            {myr === 1 ? (
                                <div className="congratulationMsgMiYear__container">
                                    You completed your mid-year review. Go to
                                    your dashboard to compare your current
                                    results to when you first began your Oola
                                    journey.
                                </div>
                            ) : (
                                `You completed step one! Take a moment to share your
                        Oolascore on your social.`
                            )}
                        </h6>
                    </div>
                    {mobile == false &&
                        (myr === 1 ? (
                            <div className="dataFs__buttonsMYR">
                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        color: 'white',
                                    }}
                                    onClick={() => endStep1(state)}
                                    type="button"
                                >
                                    DASHBOARD
                                </button>
                            </div>
                        ) : (
                            <div className="dataFs__buttons ">
                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        backgroundColor: 'transparent',
                                        borderColor: '#D86B59',
                                        // color: '#D86B59',
                                        borderStyle: 'solid',
                                        fontWeight: '500',
                                    }}
                                    onClick={handleShare}
                                    type="button"
                                >
                                    {loading ? 'Loading...' : 'SHARE!'}
                                </button>

                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        color: 'white',
                                    }}
                                    onClick={() => endStep1(state)}
                                    type="button"
                                >
                                    START STEP 2
                                </button>
                            </div>
                        ))}
                </div>
                {activeShare && (
                    <ShareOptionsModal
                        webAlias={shareData.webAlias}
                        setActiveShare={setActiveShare}
                        imageUrl={shareData.socialMediaImageUrl}
                        text={`${shareData.socialMediaTitle.replace(
                            'date',
                            getUserDate()
                        )}. ${shareData.socialMediaMessage}`}
                    />
                )}
            </div>
            <Wheel state={state} />
            {mobile == true &&
             <div
             className={`  dataFs__containerMobile ${myr === 1 &&
                 'dataFs__containerMidYead'}`}
         >
                        {myr === 1 ? (
                            <div className="dataFs__buttonsMYR">
                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        color: 'white',
                                    }}
                                    onClick={() => endStep1(state)}
                                    type="button"
                                >
                                    DASHBOARD
                                </button>
                            </div>
                        ) : (
                            <div className="dataFs__buttons">
                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        backgroundColor: 'transparent',
                                        borderColor: '#D86B59',
                                        // color: '#D86B59',
                                        borderStyle: 'solid',
                                        fontWeight: '500',
                                    }}
                                    onClick={handleShare}
                                    type="button"
                                >
                                    {loading ? 'Loading...' : 'SHARE!'}
                                </button>

                                <button
                                    className="dataFs__btnStep1"
                                    style={{
                                        color: 'white',
                                    }}
                                    onClick={() => endStep1(state)}
                                    type="button"
                                >
                                    START STEP 2
                                </button>
                            </div>
                        )}</div>}
        </>
    );
    return FinalStep1;
};
