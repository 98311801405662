import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../../Context/AppContext';
import requestAxios from '../../../../util/requestAxios';
import FindMyCoach from './Components/FindMyCoach/FindMyCoach';
import CloseButton from '../../../General/CloseButton/CloseButton';
import FindCoachQuestionary from './Components/FindCoachQuestionary/FindCoachQuestionary';
import OurTopPick from './Components/OurTopPick/OurTopPick';
import './FindCoachModal.css';
import SelectedCoachProfile from './Components/SelectedCoachProfile/SelectedCoachProfile';
import ConfrimPayment from './Components/ConfirmPayment/ConfrimPayment';

const FindCoachModal = ({ showModal, setShowModal, coachId }) => {
    const path = window.location.pathname;
    const { checkUserSession, changeLoading, getData, setNewData } =
        useContext(AppContext);
    const { token, userId } = checkUserSession();
    const [way, setWay] = useState(0);
    const [modalFormName, setModalFormName] = useState('');
    const [profileInformation, setProfileInformation] = useState();
    const { FindCoach } = getData();
    const modalMaxStep = 3;

    const getCoachInformation = async () => {
        if (coachId == 0) {
            changeLoading(false);
            setNewData({ FindCoach: { way: 0, step: 0, showModal: true } });
            setShowModal(true);
        } else {
            changeLoading(true);
            const { error, data } = await requestAxios({
                url: `Coach/getProfileInfo/${coachId}`,
                token,
            });
            if (error) {
                changeLoading(false);
                window.alert(error);
            } else {
                changeLoading(false);
                setProfileInformation(data);
                setNewData({ FindCoach: { way: 0, step: 0, showModal: true } });
                if (data.displayCoachingModal) {
                    setShowModal(true);
                }
            }
        }
    };

    useEffect(() => {
        getCoachInformation();
    }, []);

    function modalStepSelectedCoachComponent() {
        switch (FindCoach.step) {
            case 1:
                return <FindMyCoach setNewData={setNewData} />;
            default:
                return <div />;
        }
    }
    function modalStepSelectedNoCoachComponent() {
        switch (FindCoach.step) {
            case 1:
                return <FindCoachQuestionary />;
            case 2:
                return <OurTopPick />;
            default:
                return <div />;
        }
    }

    function modalStepComponent() {
        switch (FindCoach?.way) {
            case -1:
                setShowModal(false);
                break;
            case 1:
                return modalStepSelectedCoachComponent();
            case 2:
                return modalStepSelectedNoCoachComponent();
            case 3:
                return <ConfrimPayment setShowModal={setShowModal} />;
            case 4:
                return <SelectedCoachProfile />;
            default:
                if (path == '/customizeQuestions')
                    return (
                        <div className="FindCoachSelectWay">
                            <div className="FindCoachSelectWayTitle">
                                BEFORE YOU BEGIN,
                                <br />
                                LET’S CHOOSE YOUR COACH
                                <h2>
                                    Receive additional support from one of our
                                    certified Oola Life Coaches for an
                                    additional $50 per month.
                                </h2>
                                <hr />
                            </div>
                            <div className="  scrollMyGoals CoachQuestionaryItem">
                                <div className="questionWay_wrapper">
                                    <div style={{ paddingTop: '1rem' }}>
                                        <label className="containerOption CoachQuestionaryItemText FindCoachWayText">
                                            I already know the name of the coach
                                            I’d like to work with
                                            <input
                                                type="radio"
                                                name={`QuestionWay`}
                                                onClick={() => setWay(1)}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <label className="containerOption CoachQuestionaryItemText FindCoachWayText">
                                            I’d like Oola to help me find a
                                            coach
                                            <input
                                                type="radio"
                                                name={`QuestionWay`}
                                                onClick={() => setWay(2)}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <label className="containerOption CoachQuestionaryItemText FindCoachWayText">
                                            I do not want help from an Oola coach
                                            at this time
                                            <input
                                                type="radio"
                                                name={`QuestionWay`}
                                                onClick={() => setWay(-1)}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="FindCoachModalNextButton__container">
                                <button
                                    className="button-full-small FindCoachModalNextButton"
                                    style={{ borderRadius: '5px' }}
                                    form={modalFormName}
                                    type="submit"
                                    onClick={() =>
                                        setNewData({
                                            FindCoach: { way, step: 1 },
                                        })
                                    }
                                >
                                    {FindCoach?.step === modalMaxStep
                                        ? 'SAVE'
                                        : 'NEXT'}
                                </button>
                            </div>
                        </div>
                    );
                else
                    return (
                        <div className="FindCoachSelectWay">
                            <div className="FindCoachSelectWayTitle">
                                GET STARTED
                                <hr />
                            </div>
                            <div className="  scrollMyGoals CoachQuestionaryItem">
                                <div className="questionWay_wrapper">
                                    <div style={{ paddingTop: '1rem' }}>
                                        <label className="containerOption CoachQuestionaryItemText FindCoachWayText">
                                            I already know the name of the coach
                                            I’d like to work with
                                            <input
                                                type="radio"
                                                name={`QuestionWay`}
                                                onClick={() => setWay(1)}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <label className="containerOption CoachQuestionaryItemText FindCoachWayText">
                                            I’d like Oola to help me find a
                                            coach
                                            <input
                                                type="radio"
                                                name={`QuestionWay`}
                                                onClick={() => setWay(2)}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="FindCoachModalNextButton__container">
                                <button
                                    className="button-full-small FindCoachModalNextButton"
                                    style={{ borderRadius: '5px' }}
                                    form={modalFormName}
                                    type="submit"
                                    onClick={() =>
                                        setNewData({
                                            FindCoach: { way, step: 1 },
                                        })
                                    }
                                >
                                    {FindCoach?.step === modalMaxStep
                                        ? 'SAVE'
                                        : 'NEXT'}
                                </button>
                            </div>
                        </div>
                    );
        }
    }

    return (
        <>
            <Modal
                show={FindCoach?.showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="example-custom-modal-styling-title"
                className="FindCoachModal__container"
                centered
                size="xl"
                style={{ width: '100%', minHeight: '100%' }}
                scrollable
                dialogClassName={`FindCoachModalDialogInitial__container ${
                    FindCoach?.way == 0
                        ? ''
                        : FindCoach?.way < 4
                        ? 'FindCoachModalDialog__container'
                        : 'FindCoachModalDialogSelected__container'
                } ${
                    FindCoach?.way === 3 &&
                    'FindCoachModalDialogInitial__container-payment'
                }`}
            >
                {FindCoach?.way !== 3 && (
                    <Modal.Header style={{ border: 'none' }}>
                        <CloseButton
                            onClick={() => {
                                setNewData({
                                    FindCoach: {
                                        way: 0,
                                        step: 0,
                                        showModal: false,
                                    },
                                });
                                setShowModal(false);
                            }}
                        />
                    </Modal.Header>
                )}

                <Modal.Body
                    className={`FindcoachModalbody-container ${
                        FindCoach?.way === 3 &&
                        'FindcoachModalbody-containera-payment'
                    } OolaScroll`}
                >
                    {modalStepComponent()}
                </Modal.Body>
            </Modal>
        </>
    );
};
export default FindCoachModal;
