import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DoneIcon from '../../../../Assets/img/DoneIcon.svg';
import LockIcon from '../../../../Assets/img/LockIcon.svg';
import './ELearningCard.css';

const ELearningCard = (props) => {
    const [isShown, setIsShown] = useState(false);
    const history = useHistory();

    function goELearning() {
        if (props.item.isUnlocked === true) {
            localStorage.setItem('ModuleId', props.item.id);
            if (props.isAditionalCourse === 1) {
                localStorage.setItem('isAditionalCourse', 1);
            } else {
                localStorage.removeItem('isAditionalCourse');
            }
            history.push('/ELearning');
        }
    }
    const urlImage = `url("${props.configData.SERVER_URL_IMG}${props.item.imagePath}")`;

    return (
        <article
            className="eLearningCard"
            style={{
                backgroundImage: urlImage,
            }}
            onClick={() => goELearning()}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            {!props.item.isUnlocked && (
                <div className="eLearningCard__dark">
                    {(props.item.isUnlocked || isShown) &&
                        !props.item.isUnlocked && (
                            <p className="paragraph-3 unlockDateContainer">
                              {props.item.unLockDateText!=""&&  <b>
                                    Unlocks On: <br />
                                    {props.item.unLockDateText}
                                </b>}
                            </p>
                        )}
                </div>
            )}

            {(props.item.isUnlocked || isShown) && (
                <div className="eLearningCard__cardText">
                    <p className="paragraph-3">{props.item.title}</p>
                </div>
            )}

            {props.item.isUnlocked === true ? (
                props.item.isCompleted && (
                    <img className="doneIcon" src={DoneIcon} alt="Done" />
                )
            ) : (
                <img className="lockIcon" src={LockIcon} alt="Lock" />
            )}
        </article>
    );
};

export default ELearningCard;
