import React from 'react';
import configData from '../../../../../config.json';
import './PurchaseFailed.css';

export default ({ setPurchaseFailed }) => {
    const updatePaymentClick = () => {
        window.open(configData.UPDATEPAYMENTLINK);
    };

    return (
        <div className="purchaseFailed__container">
            <h5>
                Whoops! It looks like your payment declined. Please update your
                payment information below or retry payment.
            </h5>
            <button
                className="button-full-small purchaseButton PurchaseSession"
                type="button"
                onClick={updatePaymentClick}
            >
                UPDATE PAYMENT
            </button>
            <button
                className="PurchaseFailed-retry_button"
                type="button"
                onClick={() => setPurchaseFailed(false)}
            >
                Retry payment
            </button>
            <p>or contact support@oolalife.com</p>
        </div>
    );
};
